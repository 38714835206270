import { FitToViewport } from 'react-fit-to-viewport';
import { motion } from 'framer-motion';
import QRCode from 'qrcode.react';
import { Box, Modal } from '@mui/material';
import { getValidClassSessionFromStore } from '../../../helpers/storeHelpers';
import { ViewportConstants } from '../../../constants/viewport-constants';
import { SimpleFadeAnimation } from '../../../constants/animation-configs';
import { utilConstants } from '../../../constants/utils.constants';
import { Fragment } from 'react';

interface MyClassQRCodeModalProps {
    isQRCodeModalOpen: boolean;
    closeModal: () => void;
}

const MyClassQRCodeModal = ({ isQRCodeModalOpen, closeModal }: MyClassQRCodeModalProps) => {
    const classSession = getValidClassSessionFromStore('MyClassQRCodeModal');

    return (
        <Modal
            open={isQRCodeModalOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="mymodal"
            onClose={() => closeModal()}
        >
            <Fragment>
                <FitToViewport
                    className="modal_viewport"
                    width={ViewportConstants.width}
                    height={ViewportConstants.height}
                    minZoom={ViewportConstants.minZoom}
                    maxZoom={ViewportConstants.maxZoom}
                >
                    <div className="close_modal" onClick={closeModal}></div>
                    <Box
                        component={motion.div}
                        initial="initial"
                        animate="animate"
                        variants={SimpleFadeAnimation}
                        className="modal_box myclass_qr_modal"
                    >
                        <div className="qrCode_single_modal">
                            <QRCode
                                size={460}
                                value={`https://${process.env.REACT_APP_PARTICIPANT}?code=${classSession.classCode}`}
                                imageSettings={{
                                    src: utilConstants.IMAGE_URLS.brand_logo,
                                    x: undefined,
                                    y: undefined,
                                    height: 72,
                                    width: 72,
                                    excavate: true,
                                }}
                            />
                        </div>
                    </Box>
                </FitToViewport>
            </Fragment>
        </Modal>
    );
};

export default MyClassQRCodeModal;
