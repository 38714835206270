import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { utilConstants } from '../../constants/utils.constants';
import { ViewportConstants, ViewportSavedClasses, authPagesConstants } from '../../constants/viewport-constants';

import CommonAlert from './CommonAlert';
import CommonButton from './CommonButton';
import { useLocation } from 'react-router-dom';

export default function ApiErrorAlert({ isShowApiError, onDismissApiError, history }: any) {
    const { t } = useTranslation();
    // const currentPath = history ? history.location.pathname : null;
    const location = useLocation();
    const currentPath = location ? location.pathname : null;

    let actualViewPortConfig;
    if (currentPath) {
        if (currentPath === '/savedClass') {
            actualViewPortConfig = ViewportSavedClasses;
        } else if (currentPath === '/settings') {
            actualViewPortConfig = ViewportSavedClasses;
        } else if (currentPath === '/signin') {
            actualViewPortConfig = authPagesConstants;
        } else if (currentPath === '/library') {
            actualViewPortConfig = ViewportSavedClasses;
        } else {
            actualViewPortConfig = ViewportConstants;
        }
    } else {
        actualViewPortConfig = ViewportConstants;
    }

    return (
        <CommonAlert
            showAlert={isShowApiError}
            closeAlert={onDismissApiError}
            content={
                <Fragment>
                    <div className="media">
                        <div className="media_icon">
                            <img src={utilConstants.IMAGE_URLS.errorcross} alt="CROSS" />
                        </div>
                        <div className="media_body">
                            <div className="alert_content">
                                <Typography variant="body1">{t('lang_common.txt_api_error')}</Typography>
                            </div>
                        </div>
                    </div>

                    <div className="button_sec">
                        <CommonButton
                            isLoading={false}
                            type="button"
                            text={t('lang_common.btn_ok')}
                            clickFunction={onDismissApiError}
                        />
                    </div>
                </Fragment>
            }
            viewportConfig={actualViewPortConfig}
        />
    );
}
