import { useEffect, useState } from 'react';

import './MyClassGroupView.scss';
import '../../PickNames/PickNames.scss';

import { getValidClassSessionFromStore, getValidUserFromStore } from '../../../helpers/storeHelpers';
import { calculateParticipantLevelAndBadge, getLevelsArray } from '../../../helpers/userhelper';
import { handleSortParticipants } from '../../../helpers/myClassSavedGroupHelpers';

import { SavedClassGroupInterface } from '../../../interfaces/savedClassGroup.interface';
import ParticipantInterface from '../../../interfaces/participant.interface';
import SavedClassParticipantInterface, {
    OrderProperties,
    Orders,
} from '../../../interfaces/saved-class-participant.interface';

import GroupView from '../../savedClass/components/views/GroupView';

interface GroupViewInterface {
    savedClassGroups: SavedClassGroupInterface[];
    sortedParticipants: ParticipantInterface[];
    isMyClassSidebarShown: boolean;
    refreshSavedClass: (actionType?: string, groupId?: number) => void;
}

export default function MyClassGroupView({
    savedClassGroups,
    sortedParticipants,
    isMyClassSidebarShown,
    refreshSavedClass,
}: GroupViewInterface) {
    const user = getValidUserFromStore('MyClassGroupView');
    const savedClassId = getValidClassSessionFromStore('myClassGroupView').savedClassId;

    const [updatedParticipants, setUpdatedParticipants] = useState<SavedClassParticipantInterface[]>([]);
    const [orderProperty, setOrderProperty] = useState(OrderProperties.ParticipantName);
    const [order, setOrder] = useState(Orders.Asc);

    const updateParticipantData = () => {
        const updatedParticipantList: SavedClassParticipantInterface[] = [];
        sortedParticipants.forEach((participant) => {
            updatedParticipantList.push({
                participantId: participant.participantId,
                participantName: participant.participantName,
                participantAvatar: participant.participantAvatar,
                participantTotalPoints: participant.participantTotalPoints,
                recentlyEarned: participant.participantPoints,
                participantLevelAndBadge: calculateParticipantLevelAndBadge(
                    participant.participantTotalPoints,
                    getLevelsArray(user),
                ),
                groupId: participant.groupId,
            });
        });

        const arrangedParticipants = handleSortParticipants(updatedParticipantList, order, orderProperty);
        setUpdatedParticipants(arrangedParticipants);
    };

    const updateParticipantOrder = () => {
        const arrangedParticipants = handleSortParticipants(updatedParticipants, order, orderProperty);
        setUpdatedParticipants(arrangedParticipants);
    };

    useEffect(() => {
        updateParticipantData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortedParticipants, savedClassGroups]);

    useEffect(() => {
        updatedParticipants.length > 0 && updateParticipantOrder();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderProperty, order]);

    return (
        <div className={`myClassGroupView ${!isMyClassSidebarShown && 'sidebarHidden'}`}>
            {savedClassId && (
                <GroupView
                    sortedParticipants={updatedParticipants}
                    participantsInMyClass={sortedParticipants}
                    savedClassId={savedClassId}
                    savedClassGroups={savedClassGroups}
                    refreshSavedClass={refreshSavedClass}
                    order={order}
                    setOrder={setOrder}
                    orderBy={orderProperty}
                    setOrderBy={setOrderProperty}
                    isViewingInMyClass={true}
                />
            )}
        </div>
    );
}
