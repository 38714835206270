import { useSelector } from 'react-redux';

import styles from './QuestionList.module.scss';

import StoreInterface from '../../../../interfaces/store.interface';
import { QnaInterface } from '../../../../interfaces/qna.interface';

import QuestionCard from '../QuestionCard/QuestionCard';
import QnaModal from '../QnaModal/QnaModal';

const QuestionList = ({ qnas }: { qnas: QnaInterface[] }) => {
    const { selectedQuestionIndex } = useSelector((state: StoreInterface) => state.qna);

    return (
        <div className={styles.questionList}>
            {selectedQuestionIndex >= 0 && <QnaModal qnasCount={qnas.length} />}

            <div className={styles.inner_question_list}>
                <div className={styles.inner_question_wrapper}>
                    {qnas.map((qna, index) => (
                        <QuestionCard key={index} currentQuestionIndex={index} qna={qna} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default QuestionList;
