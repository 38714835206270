import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Chip, Typography } from '@mui/material';
import LayersRoundedIcon from '@mui/icons-material/LayersRounded';

import webviewMessenger from '../../../services/webviewMessenger';
import { isUserOnPremium } from '../../../helpers/userhelper';
import { getValidUserFromStore } from '../../../helpers/storeHelpers';

import SavedClassForUserInterface from '../../../interfaces/saved-class-for-user.interface';

import CustomAvatar, { AvatarType } from '../../../components/Common/CustomAvatar';

import { UserGroup, UserOff } from '../../../Icon';

interface SavedClassCardInterface {
    dragId: string | undefined;
    savedClass: SavedClassForUserInterface;
    changeCurrentPage: (currentPage: string, savedClass?: SavedClassForUserInterface) => void;
    divId: string;
    handleDrag: (event: any) => void;
    handleDrop: (event: any) => void;
    dragStarted: boolean;
    isDisabled: boolean;
}

const SavedClassCard = ({
    dragId,
    savedClass,
    changeCurrentPage,
    divId,
    handleDrag,
    handleDrop,
    dragStarted,
    isDisabled,
}: SavedClassCardInterface) => {
    const { t } = useTranslation();
    const user = getValidUserFromStore('SavedClassCard');
    const isPremium = isUserOnPremium(user);

    return (
        <Fragment>
            <div
                className={`
                    ${
                        dragStarted && dragId === divId
                            ? 'participent_Grid_Card dragEnabled'
                            : savedClass.savedClassCode
                            ? 'participent_Grid_Card'
                            : 'participent_Grid_Card_demo big_card'
                    } ${isDisabled && 'disabled'}
                `}
                draggable={true}
                id={divId}
                onClick={() => {
                    webviewMessenger.sendUsageLog(`Clicked saved class card, class name: ${savedClass.savedClassName}`);
                    changeCurrentPage('ViewEditClass', savedClass);
                }}
                onDragOver={(ev: any) => ev.preventDefault()}
                onDragStart={handleDrag}
                onDrop={handleDrop}
            >
                <div className="participent_grid_top">
                    <div className="profile">
                        <div className="profile_text">
                            <Typography variant="h4">{savedClass.savedClassName}</Typography>
                            <div className="p_class_code">
                                <Chip
                                    label={
                                        savedClass.savedClassCode
                                            ? savedClass.savedClassCode
                                            : t('lang_myclass.opt_txt_random_code')
                                    }
                                />
                            </div>
                        </div>
                        <div className="profile_img">
                            <CustomAvatar
                                avatarName={savedClass.savedClassName}
                                avatarUrl={savedClass.savedClassAvatar}
                                type={AvatarType.SAVEDCLASS}
                            />
                        </div>
                    </div>
                </div>
                <div className="total_participent">
                    {savedClass.participantsCount > 0 ? <UserGroup /> : <UserOff />}
                    <Typography variant="body1">
                        {savedClass.participantsCount}{' '}
                        {savedClass.participantsCount === 1
                            ? t('lang_saved_classes.txt_participant')
                            : t('lang_saved_classes.txt_participants')}
                    </Typography>
                    {isPremium && (
                        <Typography className={`groupCount`} variant="body1">
                            <LayersRoundedIcon className={`groupIcon`} />
                            {savedClass.groupsCount || 0}{' '}
                            {savedClass.groupsCount === 1
                                ? t('lang_grouping.x_groups_singular')
                                : t('lang_grouping.x_groups_plural')}
                        </Typography>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default SavedClassCard;
