import { useDispatch } from 'react-redux';

import 'swiper/css';
import './TrialFlow.scss';

import { userActions } from '../../actions/user.actions';
import { getValidUserFromStore } from '../../helpers/storeHelpers';

import { ActivateTrial } from './ActivateTrial';
import ProPitchSlides from './ProPitchSlides';

interface TrialFlowInterface {
    setIsTrialFlowModalOpen?: (arg: boolean) => void;
    isShowMultipleSlides: boolean;
    startingSlideId?: string;
    isActivatingTrial: boolean;
    setIsActivatingTrial: (arg: boolean) => void;
    viewport?: {
        width: number;
        height: number;
        minZoom: number;
        maxZoom: number;
    };
    startingSlide?: string;
    isGamificationModal?: boolean;
    customClassNames?: string;
    onTrialActivated?: any;
    isWindowMode?: boolean;
}

export const TrialFlow = ({
    setIsTrialFlowModalOpen,
    isShowMultipleSlides,
    startingSlideId,
    isActivatingTrial,
    setIsActivatingTrial,
    viewport,
    isGamificationModal,
    customClassNames = 'default',
    onTrialActivated,
    isWindowMode,
}: TrialFlowInterface) => {
    const dispatch = useDispatch();
    const user = getValidUserFromStore('TrialFlow');
    const isEmailVerified = user.userStats.isEmailVerified;
    const trialActivation = (
        <>
            {isActivatingTrial && !isWindowMode ? (
                <div className={isEmailVerified ? `trialActivationMainContainer ${customClassNames} centerDiv` : ''}>
                    <ActivateTrial
                        viewport={viewport}
                        isGamificationModal={isGamificationModal}
                        onActivationFailed={() => {
                            setIsActivatingTrial(false);
                            dispatch(userActions.showApiError());
                        }}
                        onSuccessBtnClick={() => {
                            setIsActivatingTrial(false);
                            onTrialActivated();
                        }}
                        onDismissVerifyEmail={() => {
                            setIsActivatingTrial(false);
                            if (setIsTrialFlowModalOpen) setIsTrialFlowModalOpen(false);
                        }}
                    />
                </div>
            ) : (
                <ProPitchSlides
                    isShowMultipleSlides={isShowMultipleSlides}
                    startingSlideId={startingSlideId}
                    onStartTrial={() => setIsActivatingTrial(true)}
                    customClassNames={customClassNames}
                />
            )}
        </>
    );

    return trialActivation;
};
