import React, { Fragment } from 'react';

export const Userplus = () => {
    return (
        <Fragment>
            <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10 13.252V15.342C9.09492 15.022 8.12628 14.9239 7.1754 15.0558C6.22453 15.1877 5.3192 15.5459 4.53543 16.1002C3.75166 16.6545 3.11234 17.3888 2.67116 18.2414C2.22998 19.094 1.99982 20.04 2 21L2.58457e-07 20.999C-0.000310114 19.7779 0.278921 18.5729 0.816299 17.4764C1.35368 16.3799 2.13494 15.4209 3.10022 14.673C4.0655 13.9251 5.18918 13.4081 6.38515 13.1616C7.58113 12.9152 8.81766 12.9457 10 13.251V13.252ZM8 12C4.685 12 2 9.315 2 6C2 2.685 4.685 0 8 0C11.315 0 14 2.685 14 6C14 9.315 11.315 12 8 12ZM8 10C10.21 10 12 8.21 12 6C12 3.79 10.21 2 8 2C5.79 2 4 3.79 4 6C4 8.21 5.79 10 8 10ZM14 16V13H16V16H19V18H16V21H14V18H11V16H14Z"
                    fill="#6378FF"
                />
            </svg>
        </Fragment>
    );
};

export const UserNot = () => {
    return (
        <Fragment>
            <svg width="19" height="21" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10 15.342V13.251C8.81766 12.9457 7.58113 12.9152 6.38515 13.1616C5.18918 13.4081 4.0655 13.9251 3.10022 14.673C2.13494 15.4209 1.35368 16.3799 0.816299 17.4764C0.278921 18.5729 -0.000310258 19.7779 2.58696e-07 20.999L2 21C1.99982 20.04 2.22998 19.094 2.67116 18.2414C3.11234 17.3888 3.75166 16.6545 4.53543 16.1002C5.3192 15.5459 6.22453 15.1877 7.1754 15.0558C8.12628 14.9239 9.09492 15.022 10 15.342Z"
                    fill="#6378FF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2 6C2 9.315 4.685 12 8 12C11.315 12 14 9.315 14 6C14 2.685 11.315 0 8 0C4.685 0 2 2.685 2 6ZM12 6C12 8.21 10.21 10 8 10C5.79 10 4 8.21 4 6C4 3.79 5.79 2 8 2C10.21 2 12 3.79 12 6Z"
                    fill="#6378FF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15 22.2C17.3196 22.2 19.2 20.3196 19.2 18C19.2 15.6804 17.3196 13.8 15 13.8C12.6804 13.8 10.8 15.6804 10.8 18C10.8 20.3196 12.6804 22.2 15 22.2ZM15 20.6C16.4359 20.6 17.6 19.4359 17.6 18C17.6 17.6493 17.5306 17.3149 17.4047 17.0097L14.0097 20.4047C14.3149 20.5306 14.6493 20.6 15 20.6ZM15.9902 15.5952L12.5952 18.9902C12.4694 18.685 12.4 18.3506 12.4 18C12.4 16.5641 13.5641 15.4 15 15.4C15.3506 15.4 15.685 15.4694 15.9902 15.5952Z"
                    fill="#6378FF"
                />
            </svg>
        </Fragment>
    );
};

export const SortedBy = () => {
    return (
        <Fragment>
            <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.5 10H5.5V8.33333H0.5V10ZM0.5 0V1.66667H15.5V0H0.5ZM0.5 5.83333H10.5V4.16667H0.5V5.83333Z"
                    fill="#49454F"
                />
            </svg>
        </Fragment>
    );
};

export const LeaderCup = () => {
    return (
        <Fragment>
            <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.8333 12.115V13.8333H14.9999V15.5H4.99992V13.8333H9.16659V12.115C7.5555 11.9118 6.07394 11.1277 4.99992 9.90979C3.92591 8.69186 3.33328 7.12384 3.33325 5.5V0.5H16.6666V5.5C16.6666 7.12384 16.0739 8.69186 14.9999 9.90979C13.9259 11.1277 12.4443 11.9118 10.8333 12.115ZM4.99992 2.16667V5.5C4.99992 6.82608 5.5267 8.09785 6.46439 9.03553C7.40207 9.97322 8.67384 10.5 9.99992 10.5C11.326 10.5 12.5978 9.97322 13.5355 9.03553C14.4731 8.09785 14.9999 6.82608 14.9999 5.5V2.16667H4.99992ZM0.833252 2.16667H2.49992V5.5H0.833252V2.16667ZM17.4999 2.16667H19.1666V5.5H17.4999V2.16667Z"
                    fill="#6378FF"
                />
            </svg>
        </Fragment>
    );
};

export const QuickPoll = () => {
    return (
        <Fragment>
            <svg width="15" height="13" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8.33325 1.33341L8.33325 1.50008C8.33325 2.14175 7.80825 2.66675 7.16658 2.66675L1.33325 2.66675C0.691585 2.66675 0.166586 2.14175 0.166586 1.50008L0.166586 1.33341C0.166586 0.691748 0.691586 0.166748 1.33325 0.166748L7.16658 0.166748C7.80825 0.166748 8.33325 0.691748 8.33325 1.33341ZM11.8333 6.00008C11.8333 6.64175 11.3083 7.16675 10.6666 7.16675L1.33325 7.16675C0.691585 7.16675 0.166586 6.64175 0.166586 6.00008C0.166586 5.35841 0.691585 4.83341 1.33325 4.83341L10.6666 4.83341C11.3083 4.83342 11.8333 5.35841 11.8333 6.00008ZM5.16658 10.6667C5.16658 11.3084 4.64159 11.8334 3.99992 11.8334L1.33325 11.8334C0.691585 11.8334 0.166585 11.3084 0.166585 10.6667C0.166585 10.0251 0.691585 9.50008 1.33325 9.50008L3.99992 9.50008C4.64159 9.50008 5.16659 10.0251 5.16658 10.6667Z"
                    fill="#6378FF"
                />
            </svg>
        </Fragment>
    );
};

export const Pickname = () => {
    return (
        <Fragment>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M13.0411 11.3921C14.3348 10.4456 15.1744 8.92037 15.1744 7.2C15.1744 4.32812 12.8348 2 9.94862 2C7.06249 2 4.72282 4.32812 4.72282 7.2C4.72282 8.93682 5.57854 10.4748 6.89334 11.4191C4.23115 12.53 2.29681 15.0253 2 18H18C17.7008 15.001 15.7371 12.4891 13.0411 11.3921Z"
                    stroke="#6378FF"
                    strokeWidth="1.5"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.5119 9.8125H9.33623C9.33623 9.53338 9.35503 9.29032 9.39262 9.08333C9.43312 8.87321 9.49964 8.68504 9.59219 8.51882C9.68474 8.34946 9.80911 8.18795 9.96529 8.03427C10.1012 7.90569 10.2198 7.78495 10.321 7.67204C10.4223 7.556 10.5004 7.43683 10.5553 7.31452C10.6103 7.1922 10.6377 7.05892 10.6377 6.91465C10.6377 6.74216 10.6132 6.60103 10.564 6.49126C10.5177 6.37836 10.4483 6.29368 10.3557 6.23723C10.2661 6.17764 10.1533 6.14785 10.0174 6.14785C9.90456 6.14785 9.79899 6.17608 9.70065 6.23253C9.60231 6.28584 9.52278 6.37052 9.46204 6.48656C9.4013 6.6026 9.36804 6.7547 9.36226 6.94288H8C8.00868 6.4944 8.10268 6.12746 8.282 5.84207C8.46132 5.55354 8.70137 5.34185 9.00217 5.20699C9.30586 5.069 9.64425 5 10.0174 5C10.4309 5 10.7852 5.07056 11.0803 5.21169C11.3782 5.35282 11.6052 5.56138 11.7614 5.83737C11.9205 6.11335 12 6.45049 12 6.84879C12 7.12164 11.9523 7.36156 11.8568 7.56855C11.7614 7.7724 11.6341 7.96371 11.4751 8.14247C11.316 8.3181 11.1396 8.50157 10.9458 8.69288C10.7809 8.85282 10.6681 9.01904 10.6074 9.19153C10.5466 9.36089 10.5148 9.56788 10.5119 9.8125ZM9.18004 11.252C9.18004 11.0419 9.24946 10.8647 9.38829 10.7204C9.53001 10.5762 9.71367 10.504 9.93926 10.504C10.1649 10.504 10.3471 10.5762 10.4859 10.7204C10.6247 10.8647 10.6941 11.0419 10.6941 11.252C10.6941 11.4653 10.6247 11.644 10.4859 11.7883C10.3471 11.9294 10.1649 12 9.93926 12C9.71367 12 9.53001 11.9294 9.38829 11.7883C9.24946 11.644 9.18004 11.4653 9.18004 11.252Z"
                    fill="#6378FF"
                />
            </svg>
        </Fragment>
    );
};
