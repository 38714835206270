import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import apiPreferences from '../../../../services/apiPreferences';
import apiClassSessions from '../../../../services/apiClassSessions';
import { userActions } from '../../../../actions/user.actions';
import StoreInterface from '../../../../interfaces/store.interface';
import { UserActionTypes } from '../../../../constants/user-action-types';
import SwitchSectionHeader from '../../../../components/Common/SwitchSectionHeader';

interface IQnaFooter {
    isQnaEnabled: boolean;
    isQnaEnabledHandler: (value: boolean) => void;
}

function QnaFooter({ isQnaEnabled, isQnaEnabledHandler }: IQnaFooter) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const user = useSelector((state: StoreInterface) => state).user;

    const handleToggleQna = async (isEnable: boolean) => {
        isQnaEnabledHandler(!isEnable);
        if (user) {
            const updatedUser = await apiPreferences.toggleQna(user.email, !isEnable);
            if (updatedUser) {
                dispatch(userActions.updateUser(UserActionTypes.TOGGLE_QNA, updatedUser));
                // notify class session app
                if (user.userClassSession?.cpcsRegion)
                    apiClassSessions.refetchPresenter(user.userClassSession?.cpcsRegion, user.email);
            } else isQnaEnabledHandler(isEnable);
        }
    };

    return (
        <div className="footer_middle">
            <SwitchSectionHeader
                triggerFunction={() => handleToggleQna(isQnaEnabled)}
                label={{ inputProps: { 'aria-label': t('lang_qna.enable_qna') } }}
                checked={isQnaEnabled}
                switchText={t('lang_qna.enable_qna')}
                className={`switchtext`}
                switchClassName={`qnaFooter ${!isQnaEnabled && 'disabled'}`}
            />
        </div>
    );
}

export default QnaFooter;
