import { forwardRef, useImperativeHandle } from 'react';
import { motion, useAnimation } from 'framer-motion';

import { aiIconAnimation } from './animation/AiIconNew.animation';

interface AiIconInterface {
    actionType: string;
}

export const AiIcon = forwardRef(({ actionType }: AiIconInterface, ref) => {
    const bgHappySquare_1_Animation = useAnimation();
    const bgHappySquare_2_Animation = useAnimation();
    const rightEyeOpenAnimation = useAnimation();
    const rightEyeCloseAnimation = useAnimation();

    const aiIconAnimationConfig = aiIconAnimation(
        bgHappySquare_1_Animation,
        bgHappySquare_2_Animation,
        rightEyeOpenAnimation,
        rightEyeCloseAnimation,
    );

    const idleAnimation = () => {
        aiIconAnimationConfig.animateRightEyeOpen();
        aiIconAnimationConfig.animateRightEyeClose();
        aiIconAnimationConfig.animateBg_1();
        aiIconAnimationConfig.animateBg_2();

        setInterval(() => {
            aiIconAnimationConfig.animateRightEyeOpen();
            aiIconAnimationConfig.animateRightEyeClose();
        }, 3000);
        setInterval(() => aiIconAnimationConfig.animateBg_2(), 6000);
        setInterval(() => aiIconAnimationConfig.animateBg_1(), 12000);
    };

    useImperativeHandle(ref, () => ({
        idleAnimation,
    }));

    if (actionType === 'idle') {
        return (
            <div className="ai_Icon">
                {/* ========== Background ========== */}
                <motion.div
                    initial={aiIconAnimationConfig.animationConstants.bgHappySquare_1_Initial}
                    animate={bgHappySquare_1_Animation}
                    className="blueTriangle_one"
                >
                    <svg width="185" height="185" viewBox="0 0 185 185" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g style={{ mixBlendMode: 'multiply' }}>
                            <path
                                d="M103.603 182.588C96.4237 185.137 88.5763 185.137 81.3968 182.588C64.4142 176.558 47.7389 166.192 33.2735 151.727C18.8081 137.261 8.44221 120.586 2.41195 103.603C-0.137363 96.4237 -0.137334 88.5763 2.41202 81.3969C8.4423 64.4146 18.8081 47.7388 33.2735 33.2735C47.7388 18.8081 64.4146 8.44231 81.3969 2.41204C88.5763 -0.137319 96.4237 -0.137356 103.603 2.41195C120.586 8.44222 137.261 18.8081 151.727 33.2735C166.192 47.7389 176.558 64.4142 182.588 81.3968C185.137 88.5763 185.137 96.4237 182.588 103.603C176.558 120.585 166.192 137.261 151.727 151.727C137.261 166.192 120.585 176.558 103.603 182.588Z"
                                fill="url(#paint0_linear_14460_13141)"
                            />
                        </g>
                        <defs>
                            <linearGradient
                                id="paint0_linear_14460_13141"
                                x1="92.4985"
                                y1="17.9827"
                                x2="92.4985"
                                y2="167.017"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#7B61FF" />
                                <stop offset="1" stopColor="#6378FF" />
                            </linearGradient>
                        </defs>
                    </svg>
                </motion.div>

                <motion.div
                    initial={aiIconAnimationConfig.animationConstants.bgHappySquare_2_Initial}
                    animate={bgHappySquare_2_Animation}
                    className="happyTriangle_Two"
                >
                    <svg width="166" height="165" viewBox="0 0 166 165" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M109.688 0.0488257C140.472 0.048827 165.427 25.0038 165.427 55.7872L165.427 109.162C165.427 139.945 140.472 164.9 109.688 164.9L56.3126 164.9C25.5288 164.9 0.573541 139.945 0.573543 109.162L0.573545 55.7873C0.573546 25.0038 25.5288 0.048822 56.3126 0.0488234L109.688 0.0488257Z"
                            fill="url(#paint0_linear_14460_13277)"
                        />
                        <defs>
                            <linearGradient
                                id="paint0_linear_14460_13277"
                                x1="165.43"
                                y1="82.4745"
                                x2="0.576428"
                                y2="82.4745"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#FF7AFF" />
                                <stop offset="1" stopColor="#09DEFF" />
                            </linearGradient>
                        </defs>
                    </svg>
                </motion.div>

                {/* ========== Circle ========== */}
                <div className="round"></div>

                {/* ========== Eye ========== */}
                <div className="eye">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="9.06484" cy="8.88124" r="8.78944" fill="#6378FF" />
                    </svg>
                </div>

                <motion.div
                    initial={aiIconAnimationConfig.animationConstants.rightEyeOpenInitial}
                    animate={rightEyeOpenAnimation}
                    className="eye_two"
                >
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="9.06484" cy="8.88124" r="8.78944" fill="#6378FF" />
                    </svg>
                </motion.div>

                <motion.div
                    initial={aiIconAnimationConfig.animationConstants.rightEyeCloseInitial}
                    animate={rightEyeCloseAnimation}
                    className="eye_close"
                >
                    <svg width="28" height="12" viewBox="0 0 28 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.99316 4.15625C12.0616 9.02565 16.0245 8.46816 23.0923 4.15625"
                            stroke="#6378FF"
                            strokeWidth="8"
                            strokeLinecap="round"
                        />
                    </svg>
                </motion.div>

                {/* ========== Mouth ========== */}
                <div className="smile">
                    <svg width="46" height="19" viewBox="0 0 46 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M43.4622 2.05532C45.4268 3.83041 45.5805 6.86206 43.8054 8.82671C32.8347 20.969 13.843 21.1835 2.60094 9.29203L2.23621 8.90623C0.417211 6.98216 0.502384 3.94781 2.42645 2.12881C4.35051 0.309816 7.38486 0.394989 9.20386 2.31905L9.56859 2.70485C16.9685 10.5322 29.4695 10.391 36.6908 2.39857C38.4659 0.433916 41.4975 0.280241 43.4622 2.05532Z"
                            fill="url(#paint0_linear_14460_13065)"
                        />
                        <defs>
                            <linearGradient
                                id="paint0_linear_14460_13065"
                                x1="40.9743"
                                y1="5.61264"
                                x2="2.95646"
                                y2="5.61264"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#5BECFF" />
                                <stop offset="1" stopColor="#6376FF" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
            </div>
        );
    } else return null;
});
