import TimerStopwatchInteface from '../interfaces/timer-stopwatch.interface';
import webviewMessenger from '../services/webviewMessenger';
import { TimerStopwatchActionTypes } from './../constants/timer-stopwatch-action-types';
export const timerStopWatchActions = {
    startTimer,
    setTimeLimit,
    pauseTimer,
    resumeTimer,
    resetTimer,
    collapseTimerWindow,
    restoreTimerWindow,
    maximizeTimerWindow,
    minimizeTimerWindow,
    updateTimeupSoundId,
    updateTimerMode,
};

function resetTimer() {
    return async (dispatch: (arg0: { type: string; payload: Partial<TimerStopwatchInteface> }) => void) => {
        const payloadObj = {
            timerEndTime: 0,
            timerPauseTime: 0,
            isTimerPaused: false,
            isTimesup: false,
        };
        dispatch({
            type: TimerStopwatchActionTypes.RESET_TIMER,
            payload: payloadObj,
        });
    };
}

function startTimer(timerEndTime: number) {
    return async (dispatch: (arg0: { type: string; payload: Partial<TimerStopwatchInteface> }) => void) => {
        const payloadObj = {
            timerEndTime,
            isTimerPaused: false,
            isTimesup: false,
            timerPauseTime: 0,
            isWindowCollapsed: false,
            timeDataFromHost: 0,
        };
        dispatch({
            type: TimerStopwatchActionTypes.START_TIMER,
            payload: payloadObj,
        });
    };
}

function pauseTimer(timerPauseTime: number) {
    return async (dispatch: (arg0: { type: string; payload: Partial<TimerStopwatchInteface> }) => void) => {
        webviewMessenger.sendUsageLog(`[S] Paused timer`);
        const payloadObj = {
            timerPauseTime,
            isTimerPaused: true,
        };
        dispatch({
            type: TimerStopwatchActionTypes.PAUSE_TIMER,
            payload: payloadObj,
        });
    };
}

function resumeTimer(timerEndTime: number) {
    return async (dispatch: (arg0: { type: string; payload: Partial<TimerStopwatchInteface> }) => void) => {
        webviewMessenger.sendUsageLog(`[S] Resumed timer`);
        const payloadObj = {
            timerEndTime,
            timerPauseTime: 0,
            isTimerPaused: false,
        };
        dispatch({
            type: TimerStopwatchActionTypes.RESUME_TIMER,
            payload: payloadObj,
        });
    };
}

function setTimeLimit(timeLimit: number) {
    return async (dispatch: (arg0: { type: string; payload: Partial<TimerStopwatchInteface> }) => void) => {
        const payloadObj = {
            timerTime: timeLimit,
        };
        dispatch({
            type: TimerStopwatchActionTypes.SET_TIME_LIMIT,
            payload: payloadObj,
        });
    };
}

function collapseTimerWindow() {
    return async (dispatch: (arg0: { type: string; payload: Partial<TimerStopwatchInteface> }) => void) => {
        webviewMessenger.sendUsageLog(`[S] Minimized timer/stopwatch window`);
        dispatch({
            type: TimerStopwatchActionTypes.TIMER_WINDOW_COLLAPSE,
            payload: { isWindowCollapsed: true },
        });
    };
}

function restoreTimerWindow(data: { currentTime: number; isTimesUp: boolean }) {
    webviewMessenger.sendUsageLog(`[S] Restored timer/stopwatch window to default size`);
    if (data.isTimesUp) {
        return async (dispatch: (arg0: { type: string; payload: Partial<TimerStopwatchInteface> }) => void) => {
            dispatch({
                type: TimerStopwatchActionTypes.TIMER_TIMEUP,
                payload: { isTimesup: true },
            });
        };
    } else {
        return async (dispatch: (arg0: { type: string; payload: Partial<TimerStopwatchInteface> }) => void) => {
            dispatch({
                type: TimerStopwatchActionTypes.TIMER_WINDOW_RESTORE,
                payload: { isWindowCollapsed: false, timeDataFromHost: data.currentTime },
            });
        };
    }
}

function maximizeTimerWindow() {
    return async (dispatch: (arg0: { type: string; payload: Partial<TimerStopwatchInteface> }) => void) => {
        webviewMessenger.sendUsageLog(`[S] Entered timer/stopwatch fullscreen mode`);
        dispatch({
            type: TimerStopwatchActionTypes.MAXIMIZE_TIMER_WINDOW,
            payload: { isWindowMaximized: true },
        });
    };
}

function minimizeTimerWindow() {
    return async (dispatch: (arg0: { type: string; payload: Partial<TimerStopwatchInteface> }) => void) => {
        webviewMessenger.sendUsageLog(`[S] Minimized timer/stopwatch window`);
        dispatch({
            type: TimerStopwatchActionTypes.MAXIMIZE_TIMER_WINDOW,
            payload: { isWindowMaximized: false },
        });
    };
}

function updateTimeupSoundId(timeupSoundId: number) {
    return async (dispatch: (arg0: { type: string; payload: Partial<TimerStopwatchInteface> }) => void) => {
        dispatch({
            type: TimerStopwatchActionTypes.UPDATE_TIMEUP_SOUND_ID,
            payload: { timeupSoundId },
        });
    };
}

function updateTimerMode(isTimerMode: boolean) {
    return async (dispatch: (arg0: { type: string; payload: Partial<TimerStopwatchInteface> }) => void) => {
        webviewMessenger.sendUsageLog(`[S] Changed timer/stopwatch mode to: ${isTimerMode ? 'Timer' : 'Stopwatch'}`);
        dispatch({
            type: TimerStopwatchActionTypes.UPDATE_TIMER_MODE,
            payload: { isTimerMode },
        });
    };
}
