import { Fragment, useRef, useState, SyntheticEvent } from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { FitToViewport } from 'react-fit-to-viewport';
import Modal from '@mui/material/Modal';
import { ViewportConstants } from '../../constants/viewport-constants';
import CommonChip from '../../components/Common/CommonChip';
import _ from 'lodash';
import { getActivityFromStore } from '../../helpers/storeHelpers';
import ActivityResponseInterface from '../../interfaces/activity-response.interface';
import { useTranslation } from 'react-i18next';
import TabPanal from './TabPanal/TabPanal';
import { filterOnlineParticipants } from '../../helpers/classSessionHelpers';
import { useSelector } from 'react-redux';
import StoreInterface from '../../interfaces/store.interface';

interface LiveStatusInterface {
    openLiveStatusModal: boolean;
    handleClose: () => void;
}

const LiveStatus = ({ openLiveStatusModal, handleClose }: LiveStatusInterface) => {
    const activityResponses = getActivityFromStore().activityResponses;
    const classSession = useSelector((state: StoreInterface) => state.classSession);
    const participantList = classSession ? classSession.participantList : [];
    const { t } = useTranslation();
    const submittedRef: any = useRef(null);

    // useEffect(() => {
    // const scrollTop = scrollStore.get('scrollTop');
    // if ((scrollTop || scrollTop === 0) && containerEl.current) {
    //   containerEl.current.scrollTop = scrollTop;
    // }
    // return () => {
    //   const currentScrollTop = containerEl.current.scrollTop;
    //   if (currentScrollTop || currentScrollTop === 0) {
    //     scrollStore.set('scrollTop', currentScrollTop);
    //   }
    // };
    // if (submittedRef.current) submittedRef.current.addEventListener('scroll', setScrollPos);
    // return () => {
    //     if (submittedRef.current) submittedRef.current.removeEventListener('scroll', setScrollPos);
    // };
    // }, []);

    // const setScrollPos = (event: any) => {
    //     console.log('event', event);
    // };

    // function TabPanel(props: any) {
    //     const { children, value, index, ...other } = props;
    //     return (
    //         <div
    //             role="tabpanel"
    //             hidden={value !== index}
    //             id={`simple-tabpanel-${index}`}
    //             aria-labelledby={`simple-tab-${index}`}
    //             {...other}
    //         >
    //             {value === index && (
    //                 <Box sx={{ p: 3 }}>
    //                     <Typography variant="caption">{children}</Typography>
    //                 </Box>
    //             )}
    //         </div>
    //     );
    // }

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [tabValue, setTabValue] = useState(0);

    const handleChangeTab = (event: SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const pendingParticipants = participantList.filter(function (participant) {
        return !activityResponses.some(function (response) {
            return participant.participantName === response.participantName;
        });
    });

    const onlinePendingParticipants = filterOnlineParticipants(pendingParticipants);

    const uniqSubmittedParticipants = (activityResponses: ActivityResponseInterface[]) => {
        let participants: { participantName: string; participantAvatar: string }[] = [];
        activityResponses.forEach((response) => {
            participants.push({
                participantName: response.participantName,
                participantAvatar: response.participantAvatar,
            });
        });
        return _.uniqBy(participants, 'participantName');
    };

    return (
        <Fragment>
            <Modal
                open={openLiveStatusModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="dailog_modal"
            >
                <div>
                    <FitToViewport
                        className="modal_viewport"
                        width={ViewportConstants.width}
                        height={ViewportConstants.height}
                        minZoom={ViewportConstants.minZoom}
                        maxZoom={ViewportConstants.maxZoom}
                    >
                        <Fragment>
                            <div className="close_modal" onClick={handleClose}>
                                {/* <CloseRoundedIcon /> */}
                            </div>
                            <div className="box">
                                <Box className="submit_modal">
                                    <Box sx={{ borderColor: 'divider' }}>
                                        <Tabs
                                            value={tabValue}
                                            onChange={handleChangeTab}
                                            aria-label="basic tabs example"
                                        >
                                            <Tab
                                                label={`${t('lang_common.Submitted')} (${
                                                    _.uniq(activityResponses.map((item: any) => item.participantId))
                                                        .length
                                                })`}
                                                {...a11yProps(0)}
                                            />
                                            <Tab
                                                label={`${t('lang_common.Pending')} (${
                                                    onlinePendingParticipants.length
                                                })`}
                                                {...a11yProps(1)}
                                            />
                                        </Tabs>
                                    </Box>
                                    <TabPanal value={tabValue} index={0} className="pad_20">
                                        <div className="name_list_box">
                                            <div className="list_content" ref={submittedRef}>
                                                {uniqSubmittedParticipants(activityResponses).map(
                                                    (participant, index) => {
                                                        return (
                                                            <CommonChip
                                                                participantAvatar={participant.participantAvatar}
                                                                participantName={participant.participantName}
                                                                key={index}
                                                            />
                                                        );
                                                    },
                                                )}
                                            </div>
                                        </div>
                                    </TabPanal>
                                    <TabPanal value={tabValue} index={1} className="pad_20">
                                        <div className="name_list_box">
                                            <div className="list_content">
                                                {onlinePendingParticipants.map((participant, index) => (
                                                    <CommonChip
                                                        participantAvatar={participant.participantAvatar}
                                                        participantName={participant.participantName}
                                                        key={index}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </TabPanal>
                                </Box>
                            </div>
                        </Fragment>
                    </FitToViewport>
                </div>
            </Modal>
        </Fragment>
    );
};

export default LiveStatus;
