import { FormControl, FormGroup, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CommonButton from '../../../../components/Common/CommonButton';
import CommonCheckBox from '../../../../components/Common/CommonCheckBox';
import { utilConstants } from '../../../../constants/utils.constants';
import SubscriptionBackButton from './SubscriptionBackButton';

const CancelSubscription = ({ setCurrentPage, setCancelSubscriptionReason }: any) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'onSubmit',
        shouldFocusError: false,
    });

    const { t } = useTranslation();

    const cancelSubscription = (data: any) => {
        const optionArray: string[] = data.opt;
        if (optionArray && optionArray.length > 0) {
            const arrayString = optionArray.join(', ');
            setCancelSubscriptionReason(arrayString);
            setCurrentPage(utilConstants.SUBSCRIPTION_PAGE_TYPE.Cancel_Subscription_Confirm);
        }
    };

    return (
        <>
            <SubscriptionBackButton
                setCurrentPage={setCurrentPage}
                previousPage={utilConstants.SUBSCRIPTION_PAGE_TYPE.Landing_Page}
            />
            <form onSubmit={handleSubmit(cancelSubscription)}>
                <div className="change_product_key_sec">
                    <div className="product_key_text">
                        <Typography variant="h4">{t('lang_subscription.txt_cancel_subscription')}</Typography>
                        <Typography variant="body1">{t('lang_subscription.txt_reason_of_leaving')}</Typography>
                    </div>
                    <div className="profile_form">
                        <div className="subject_checkbox">
                            <FormControl sx={{ m: 2 }} component="fieldset" variant="standard">
                                <FormGroup>
                                    <div className="checkbox">
                                        <CommonCheckBox
                                            isHookRegistered={true}
                                            value="The software has bugs"
                                            label={t('lang_subscription.txt_software_has_bugs')}
                                            register={register}
                                            registerName="opt"
                                            validationObject={{
                                                required: true,
                                            }}
                                        />
                                    </div>
                                    <div className="checkbox">
                                        <CommonCheckBox
                                            isHookRegistered={true}
                                            value="It doesn’t have the features I want"
                                            label={t('lang_subscription.txt_doesnot_have_feature_need')}
                                            register={register}
                                            registerName="opt"
                                            validationObject={{
                                                required: true,
                                            }}
                                        />
                                    </div>
                                    <div className="checkbox">
                                        <CommonCheckBox
                                            isHookRegistered={true}
                                            value="Basic version is good enough"
                                            label={t('lang_subscription.txt_basic_version_is_enough')}
                                            register={register}
                                            registerName="opt"
                                            validationObject={{
                                                required: true,
                                            }}
                                        />
                                    </div>
                                    <div className="checkbox">
                                        <CommonCheckBox
                                            isHookRegistered={true}
                                            value="Price is too high"
                                            label={t('lang_subscription.txt_price_is_too_high')}
                                            register={register}
                                            registerName="opt"
                                            validationObject={{
                                                required: true,
                                            }}
                                        />
                                    </div>
                                    <div className="checkbox">
                                        <CommonCheckBox
                                            isHookRegistered={true}
                                            value="I only need ClassPoint temporarily"
                                            label={t('lang_subscription.txt_need_classpoint_temporarily')}
                                            register={register}
                                            registerName="opt"
                                            validationObject={{
                                                required: true,
                                            }}
                                        />
                                    </div>
                                    <div className="checkbox">
                                        <CommonCheckBox
                                            isHookRegistered={true}
                                            value="other"
                                            label={t('lang_subscription.txt_other_reason')}
                                            register={register}
                                            registerName="opt"
                                            validationObject={{
                                                required: true,
                                            }}
                                        />
                                    </div>
                                </FormGroup>
                                <div className="other_textfield">
                                    {errors.opt && (
                                        <span
                                            className="error_span"
                                            style={{
                                                paddingLeft: 0,
                                            }}
                                        >
                                            {t('lang_subscription.err_msg_please_choose_at_least_one')}
                                        </span>
                                    )}
                                </div>
                            </FormControl>
                        </div>
                    </div>
                    <div className="profile_update_btn">
                        <CommonButton
                            isLoading={false}
                            variant="contained"
                            type="submit"
                            color="primary"
                            text={t('lang_subscription.btn_next')}
                        />
                    </div>
                </div>
            </form>
        </>
    );
};

export default CancelSubscription;
