import { useState } from 'react';
import useSound from 'use-sound';
import { SoundLibrary } from '../../../constants/sound-library';

import { accuratesFontSize, generateWheelCssClass } from '../../../helpers/pickNamesHelpers';
import PickNamesParticipantInterface from '../../../interfaces/pick-names-participant.interface';

import './wheel.scss';

interface ParticipantsWheelInterface {
    participantsOnWheel: PickNamesParticipantInterface[];
    onWheelStopsOnParticipant: any;
}

const ParticipantsWheel = ({ participantsOnWheel, onWheelStopsOnParticipant }: ParticipantsWheelInterface) => {
    const [playSound] = useSound(SoundLibrary.SPIN_WHEEL);
    const [pickedIndex, setPickedIndex] = useState<number>(
        !isNaN(+localStorage['pickedIndex']) ? +localStorage['pickedIndex'] : -1,
    );
    const [isSpinning, setIsSpinning] = useState(false);

    const spinDuration = 3;
    const pi = 3.14159265358979;
    const angleValue = 200;
    const tanvalue = angleValue * Math.tan(pi / participantsOnWheel.length) + 'px';
    const totalRotDegree = 360 * 10;
    const rotationDegreeOffset = (-360 / participantsOnWheel.length) * pickedIndex;
    const rotationState = rotationDegreeOffset + totalRotDegree;
    let wheelStyles: any = {
        '--nb-item': participantsOnWheel.length,
        '--selected-item': pickedIndex,
        '--tan': tanvalue,
        '--spinning-duration': `${spinDuration}s`,
        '--rotationValue': `${rotationState}deg`,
    };

    const handleSpinWheel = () => {
        if (participantsOnWheel.length > 1) {
            setIsSpinning(true);
            playSound();
        }
        setPickedIndex(-1);
        setTimeout(() => selectItemHandler(), 100);
    };

    const selectItemHandler = () => {
        const selectedIndex = Math.floor(Math.random() * participantsOnWheel.length);
        setPickedIndex(selectedIndex);

        participantsOnWheel.length < 1
            ? localStorage.setItem('pickedIndex', '-1')
            : localStorage.setItem('pickedIndex', selectedIndex.toString());

        if (participantsOnWheel.length > 1) {
            setTimeout(() => {
                onWheelStopsOnParticipant(participantsOnWheel[selectedIndex]);
                setIsSpinning(false);
            }, spinDuration * 1000);
        } else {
            setTimeout(() => {
                onWheelStopsOnParticipant(participantsOnWheel[selectedIndex]);
                setIsSpinning(false);
            }, 0 * 1000);
        }
    };

    return (
        <div className="wheel-container">
            <div
                className={`wheel rotateState ${isSpinning ? 'spinning' : ''} `}
                style={wheelStyles}
                onClick={participantsOnWheel.length > 0 && !isSpinning ? () => handleSpinWheel() : () => {}}
            >
                {participantsOnWheel.map((participant, index: number) => {
                    let wheelPieceStyles: any = {
                        '--item-nb': index,
                        '--tan': tanvalue,
                        '--rotationState': rotationState,
                        '--dynamic-color': participant.colorHex,
                        fontSize: accuratesFontSize(participantsOnWheel, participant.participantName),
                    };
                    return (
                        <div
                            key={index}
                            className={`wheel-item ${generateWheelCssClass(participantsOnWheel.length)}`}
                            style={wheelPieceStyles}
                        >
                            <div
                                className="angle"
                                style={{
                                    borderRightColor: participant.colorHex,
                                }}
                            ></div>
                            <span className={participantsOnWheel.length === 1 ? 'center' : ''}>
                                {participant.participantName}
                            </span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ParticipantsWheel;
