import { useEffect, useState } from 'react';
import Lottie from 'react-lottie-player';
import { useTranslation } from 'react-i18next';
import { EmailShareButton } from 'react-share';

import { Button, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';

import './ShareActivityModal.scss';

import webviewMessenger from '../../../services/webviewMessenger';
import { SOCIAL_MEDIA_Types, getEncodedData } from './shareActivityHepler';

// import { ActivityPropsInterface } from '../../../interfaces/activity-props.interface';
import { utilConstants } from '../../../constants/utils.constants';
import { BIG_FACEBOOK, BIG_WHATSAPP, BIG_TWITTER } from '../../../Icon';

// import CommonCheckBox from '../../../components/Common/CommonCheckBox';

import loadingAnimation from '../../../assets/animation/87884-loader.json';
// import { webviewSettingsActions } from '../../../actions/webview-settings.actions';
// import { WebviewSettingsActionTypes } from '../../../constants/webview-settings-action-types';
import { useSelector } from 'react-redux';
import StoreInterface from '../../../interfaces/store.interface';
import ActivityInterface from '../../../interfaces/activity.interface';

interface ShareActivityModalInterface {
    activity: ActivityInterface;
}

const ShareActivityModal = ({ activity }: ShareActivityModalInterface) => {
    const { t } = useTranslation();
    const activityId = activity.activityProps?.activityId;
    const activityResponsesCount = activity.activityResponses.length;
    const webviewSettingsEditMode = useSelector((state: StoreInterface) => state.webviewSettingsEditMode);
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    const [isParticipantNameHidden, setIsParticipantNameHidden] = useState(
        webviewSettingsEditMode.isParticipantNamesHidden,
    );
    const [showLoading, setShowLoading] = useState(false);
    const [toggleCopiedBtn, setToggleCopiedBtn] = useState(false);

    const title = t('share_activity.title', {
        xResponses: activityResponsesCount > 1 ? `these ${activityResponsesCount} responses` : `this response`,
    });
    const hashtag = 'ClassPointShare';
    const hashtags = ['ClassPointShare', 'ClassPoint'];

    const stringifiedUrlQuery = JSON.stringify({ activityId: activityId, hideNames: isParticipantNameHidden });
    const encodedQuery = btoa(stringifiedUrlQuery);
    const uriEncodedData = encodeURIComponent(encodedQuery);

    // const shareUrl = `${process.env.REACT_APP_CP_SHARE}/activity/${uriEncodedData}`;
    const shareUrl = `${process.env.REACT_APP_WEBAPP}/activity/${activityId}`;
    const encodedDataObject = { shareUrl, title, hashtag, hashtags };

    const openLinkInBrowser = (type: SOCIAL_MEDIA_Types) => {
        webviewMessenger.sendOpenBrowser(getEncodedData(encodedDataObject, type));
    };

    const copiedUrlLink = () => {
        navigator.clipboard.writeText(shareUrl);
        setToggleCopiedBtn(true);
        setTimeout(() => setToggleCopiedBtn(false), 1000);
    };

    useEffect(() => {
        showLoading && setTimeout(() => setShowLoading(false), 1000);
    }, [showLoading]);

    return (
        <div className="shareModal_content">
            {showLoading && (
                <div className={`lottieAnimation`}>
                    <Lottie loop={true} animationData={loadingAnimation} play speed={1} />
                </div>
            )}

            <div className="modal_head">
                <Typography id="modal-modal-title" variant="h6" className="title" component="h2">
                    {t('share_activity.heading')}
                </Typography>
            </div>

            <div className="share_modal_content_body">
                <p className="text">{t('share_activity.subHeading')}</p>

                {/* <div className="share_check">
                    <CommonCheckBox
                        value={isParticipantNameHidden}
                        defaultChecked={isParticipantNameHidden}
                        changeFunction={(e: any) => {
                            setShowLoading(true);
                            setIsParticipantNameHidden(e.target.checked);
                            dispatch(
                                webviewSettingsActions.updateWebviewSettings(
                                    WebviewSettingsActionTypes.UPDATE_PARTICIPANT_NAMES_HIDDEN,
                                    {
                                        isParticipantNamesHidden: e.target.checked,
                                    },
                                ),
                            );
                        }}
                        label={t('share_activity.hide_participant_names')}
                    />
                </div> */}

                <div className="copy_url">
                    <input value={shareUrl} type="text" readOnly />
                    <Button
                        onClick={() => copiedUrlLink()}
                        className="btn"
                        variant="contained"
                        endIcon={<ContentCopyIcon />}
                    >
                        {t('lang_share_classpoint.btn_copy')}
                        {toggleCopiedBtn && (
                            <span className="copyurl_tooltip">
                                {t('lang_share_classpoint.tooltip_copied')} <DoneIcon />
                            </span>
                        )}
                    </Button>
                </div>

                <div className="icon_list">
                    <EmailShareButton url={shareUrl} subject={title} body={title} separator={'\n'} className="btn">
                        <img src={utilConstants.SHARE.EMAIL_BIG} alt={'emailShareIcon'} />
                    </EmailShareButton>
                    <Button onClick={() => openLinkInBrowser(SOCIAL_MEDIA_Types.WHATSAPP)}>
                        <i className="icon">
                            <BIG_WHATSAPP />
                        </i>
                    </Button>
                    <Button onClick={() => openLinkInBrowser(SOCIAL_MEDIA_Types.FACEBOOK)}>
                        <i className="icon">
                            <BIG_FACEBOOK />
                        </i>
                    </Button>
                    <Button onClick={() => openLinkInBrowser(SOCIAL_MEDIA_Types.TWITTER)}>
                        <i className="icon">
                            <BIG_TWITTER />
                        </i>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ShareActivityModal;
