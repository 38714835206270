import { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';

import styles from './QnaStatus.module.scss';
import { useTranslation } from 'react-i18next';

interface QnaModalInterface {
    isQnaEnabled: boolean;
}

const QnaStatus = ({ isQnaEnabled }: QnaModalInterface) => {
    const { t } = useTranslation();
    const statusAnimation = useAnimation();

    const [isFirstTime, setIsFirstTime] = useState(true);

    const showStatus = () => {
        statusAnimation.start({
            y: [66, 32, 32, 32],
            transition: {
                type: 'spring',
                stiffness: 1000,
                mass: 5,
                damping: 50,
            },
        });
    };

    const hideStatus = () => {
        statusAnimation.start({
            y: [32, 32, 32, 66],
            transition: {
                type: 'spring',
                stiffness: 1000,
                mass: 5,
                damping: 50,
            },
        });
    };

    useEffect(() => {
        if (isFirstTime) return setIsFirstTime(false);
        showStatus();
        setTimeout(() => hideStatus(), 2000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isQnaEnabled]);

    return (
        <motion.div
            initial={{ y: 66 }}
            animate={statusAnimation}
            className={`${styles.qnaStatus} ${isQnaEnabled ? styles.enabled : styles.disabled}`}
        >
            <p className={`${styles.text}`}>
                {isQnaEnabled ? t('lang_qna.qna_enabled_notice') : t('lang_qna.qna_disabled_notice')}
            </p>
        </motion.div>
    );
};

export default QnaStatus;
