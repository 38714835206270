import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FitToViewport } from 'react-fit-to-viewport';

import './SavedClasses.scss';

import { isUserOnTrialOrPro } from '../../helpers/userhelper';
import { getValidUserFromStore } from '../../helpers/storeHelpers';
import { localService } from '../../services/localStorageService';
import SavedClassForUserInterface from '../../interfaces/saved-class-for-user.interface';
import { ViewportSavedClasses } from '../../constants/viewport-constants';
import { QuotaConstants } from '../../constants/quota-constants';

import BasicUserCreateSavedClassLimitAlert from './components/alert/BasicUserCreateSavedClassLimitAlert';
import CommonAlert from '../../components/Common/CommonAlert';
import AddSavedClass from './AddSavedClass';
import SavedClassesList from './SavedClassesList';
import ViewEditSavedClass from './ViewEditSavedClass';
import EmptyModal from '../EmptyModal/EmptyModal';

function SavedClasses() {
    const location: any = useLocation();
    const user = getValidUserFromStore('SavedClasses');
    const savedClasses = user.userProfile.savedClasses;
    const savedClassCountWithoutDemo =
        savedClasses?.filter((classItem) => classItem.savedClassCode !== null).length || 0;

    const [currentPage, setCurrentPage] = useState(
        location.state?.params === 'addClass' ? 'AddClass' : 'SavedClassList',
    );
    const [savedClass, setSavedClass] = useState<SavedClassForUserInterface>();
    const [showEmptyModal, setShowEmptyModal] = useState(false);
    const [showProVsBasic, setShowProVsBasic] = useState(false);

    const changeCurrentPage = (currentPage: string, savedClass?: SavedClassForUserInterface) => {
        if (currentPage === 'AddClass') {
            if (!isUserOnTrialOrPro(user) && savedClassCountWithoutDemo >= QuotaConstants.BASIC_USER_SAVED_CLASS_QUOTA)
                return setShowProVsBasic(true);
            else {
                setCurrentPage(currentPage);
            }
        } else {
            setCurrentPage(currentPage);
            if (savedClass !== undefined) {
                setSavedClass(savedClass);
            }
        }
    };

    useEffect(() => {
        setShowEmptyModal(true);
        setTimeout(() => {
            setShowEmptyModal(false);
        }, 1);
    }, []);

    useEffect(() => {
        localService.removeTempAddedParticipants();
    }, []);

    let currentView;
    switch (currentPage) {
        case 'SavedClassList':
            currentView = <SavedClassesList changeCurrentPage={changeCurrentPage} />;
            break;
        case 'AddClass':
            currentView = (
                <AddSavedClass
                    changeCurrentPage={changeCurrentPage}
                    savedClass={savedClass}
                    setSavedClass={setSavedClass}
                />
            );
            break;
        case 'ViewEditClass':
            currentView = savedClass && (
                <ViewEditSavedClass
                    changeCurrentPage={changeCurrentPage}
                    savedClass={savedClass}
                    setSavedClass={setSavedClass}
                />
            );
            break;
        default:
            break;
    }

    return (
        <FitToViewport
            className="viewport"
            width={ViewportSavedClasses.width}
            height={ViewportSavedClasses.height}
            minZoom={ViewportSavedClasses.minZoom}
            maxZoom={ViewportSavedClasses.maxZoom}
        >
            {showEmptyModal && <EmptyModal viewportConfig={ViewportSavedClasses} />}

            <CommonAlert
                showAlert={showProVsBasic}
                closeAlert={() => {
                    setShowProVsBasic(false);
                }}
                content={<BasicUserCreateSavedClassLimitAlert onConfirm={() => setShowProVsBasic(false)} />}
                viewportConfig={ViewportSavedClasses}
                customModalClassNames="small"
            />

            <div className="addingClass">{currentView}</div>
        </FitToViewport>
    );
}

export default SavedClasses;
