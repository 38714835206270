import { Fragment, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, Typography } from '@mui/material';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import webviewMessenger from '../../../services/webviewMessenger';
import { localService } from '../../../services/localStorageService';
import { UpdateToolbarActionsType } from '../../../dtos/update-toolbar-actions.dto';
import { timerStopWatchActions } from '../../../actions/timer-stopwatch.action';
import { useDispatch } from 'react-redux';

interface StopwatchInterface {
    stopwatchValue: number;
    setStopwatchValue: any;
    isRunning: boolean;
    setIsRunning: (arg: boolean) => void;
    isFullScreen: boolean;
    showTab: boolean;
    setShowTab: (arg: boolean) => void;
    currentTimeRef: any;
    isPaused: boolean;
    setIsPaused: any;
}

const Stopwatch = ({
    stopwatchValue,
    setStopwatchValue,
    isRunning,
    setIsRunning,
    isFullScreen,
    showTab,
    setShowTab,
    currentTimeRef,
    isPaused,
    setIsPaused,
}: StopwatchInterface) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const fullScreenBtnRef = useRef<any>();

    const hourValue = Math.floor(stopwatchValue / 3600);
    const minuteValue = Math.floor((stopwatchValue % 3600) / 60);
    const secondValue = Math.floor((stopwatchValue % 3600) % 60);

    const handleStart = () => {
        setIsPaused(false);
        setIsRunning(true);
        setShowTab(false);
        localService.updateToolbarActionsFromWebview(UpdateToolbarActionsType.startTimer);
    };

    const handlePlayPause = () => {
        setIsPaused((prev: boolean) => !prev);
    };

    const handleReset = () => {
        setIsPaused(true);
        setIsRunning(false);
        if (!isFullScreen) setShowTab(true);
        setStopwatchValue(0);
        currentTimeRef.current = 0;
    };

    const enterFullScreen = () => {
        dispatch(timerStopWatchActions.maximizeTimerWindow());
        setShowTab(false);
        webviewMessenger.timerFullScreenSize();
    };

    const exitFullscreen = () => {
        dispatch(timerStopWatchActions.minimizeTimerWindow());
        if (!isRunning) setShowTab(true);
        webviewMessenger.timerDefaultSize();
    };

    useEffect(() => {
        let interval: any;

        if (isRunning && !isPaused) {
            interval = setInterval(() => {
                currentTimeRef.current = currentTimeRef.current + 1;
                setStopwatchValue((prev: number) => prev + 1);
            }, 1000);
        } else clearInterval(interval);

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRunning, isPaused]);

    useEffect(() => {
        if (isFullScreen) {
            const keyDownHandler = (event: any) => {
                if (event.key === 'Escape') fullScreenBtnRef.current.click();
            };
            document.addEventListener('keydown', keyDownHandler);
            return () => document.removeEventListener('keydown', keyDownHandler);
        }
    }, [isFullScreen]);

    return (
        <Fragment>
            <div className={`time_add_wraper ${!showTab && 'bigwrapper'}`}>
                <div className="timer_add_inner">
                    <div className="time_display">
                        <Typography variant="h4">
                            {stopwatchValue === 0 && (
                                <>
                                    0<span className="digits">s</span>
                                </>
                            )}

                            {hourValue > 0 && (
                                <>
                                    {hourValue}
                                    <span className="digits">h</span>
                                </>
                            )}
                            {minuteValue > 0 && (
                                <>
                                    {minuteValue}
                                    <span className="digits">m</span>
                                </>
                            )}
                            {secondValue > 0 && (
                                <>
                                    {secondValue}
                                    <span className="digits">s</span>
                                </>
                            )}
                        </Typography>
                    </div>
                </div>
            </div>

            <div className="timmer_btn">
                {isRunning ? (
                    <>
                        <Button onClick={() => handlePlayPause()} variant="contained">
                            {isPaused ? t('lang_timer.btn_resume') : t('lang_timer.btn_pause')}
                        </Button>
                        <Button onClick={() => handleReset()} variant="contained" className="light_btn">
                            {t('lang_timer.btn_reset')}
                        </Button>
                    </>
                ) : (
                    <Button onClick={() => handleStart()} variant="contained">
                        {t('lang_timer.btn_start')}
                    </Button>
                )}
                <IconButton
                    ref={fullScreenBtnRef}
                    onClick={() => (isFullScreen ? exitFullscreen() : enterFullScreen())}
                    color="primary"
                    className="defult_btn float"
                >
                    {isFullScreen ? <FullscreenExitIcon /> : <FullscreenOutlinedIcon />}
                </IconButton>
            </div>
        </Fragment>
    );
};

export default Stopwatch;
