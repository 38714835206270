import { Fragment, useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { Typography, Backdrop, Box, Modal, Fade } from '@mui/material';

import FilePresentIcon from '@mui/icons-material/FilePresent';
import CancelIcon from '@mui/icons-material/Cancel';

import './ImportCSV.scss';
import webviewMessenger from '../../../services/webviewMessenger';
import { useTranslation } from 'react-i18next';
import { localService } from '../../../services/localStorageService';
import { utilConstants } from '../../../constants/utils.constants';

interface ImportCSVInterface {
    csvUploadOpen: boolean;
    handleCSVClose: () => void;
    classLimit: number;
    setCsvData: (arg: any) => void;
    setResetData: (arg: boolean) => void;
    addRowOnImportCsv: (arg: number) => void;
}

const ImportCSV = ({
    csvUploadOpen,
    handleCSVClose,
    classLimit,
    setCsvData,
    setResetData,
    addRowOnImportCsv,
}: ImportCSVInterface) => {
    const [file, setFile] = useState<any>();
    const [fileName, setFileName] = useState('');
    const [error, setError] = useState('');
    const { t } = useTranslation();
    let newDataList: any[] = [];
    let validationSuccess = false;

    const handleDownloadCsvTemplate = () => {
        let csvContent = '';
        for (let index = 0; index < 5; index++) {
            csvContent += `${t('lang_saved_classes.csv_template_name')} ${index + 1}\n`;
        }
        const fileName = `Import-participants-csv-template.csv`;
        webviewMessenger.sendCsv(fileName, csvContent);
    };

    const handleFileInput = (fileInfo: any) => {
        setFileName(fileInfo.name);
        let fileExtension = fileInfo.name.slice(fileInfo.name.length - 4, fileInfo.name.length).toLowerCase();
        fileExtension === 'xlsx' || fileExtension === '.csv' || fileExtension === '.xls'
            ? setFile(fileInfo)
            : setError(t('lang_saved_classes.txt_error_only_csv_allowed'));
    };

    const parseCsv = () => {
        const reader = new FileReader();
        reader.onload = function (e: any) {
            let fileData = XLSX.read(e.target.result, {
                type: 'array',
            });

            const dataArray = fileData.Sheets.Sheet1;
            // console.log('dataArray', dataArray);
            if (!dataArray) {
                return setError(t('lang_saved_classes.txt_error_invalid_structure'));
            }

            const lastNameCount = Object.keys(dataArray)
                .filter((value) => /^A/.test(value))
                ?.pop()
                ?.replace(/[^0-9]/g, '');

            let csvFileData: any[] = [];
            const lastNameCountInNumber = typeof lastNameCount === 'string' ? parseInt(lastNameCount) : 0;

            for (let n = 0; n <= lastNameCountInNumber; n++) {
                // if (dataArray[`A1`]?.v !== 'Name' || dataArray[`B1`]?.v !== 'Stars') {
                //     return setError(t('lang_saved_classes.txt_error_invalid_structure'));
                // }
                // if (dataArray[`A${n}`]?.v?.trim() === 'Name' && dataArray[`B${n}`]?.v?.trim() === 'Stars') {
                //     continue;
                // }
                if (typeof dataArray[`B${n}`]?.v !== 'undefined') {
                    if (typeof dataArray[`B${n}`]?.v !== 'number') {
                        return setError(t('lang_saved_classes.err_points_should_be_number'));
                    }
                    if (dataArray[`B${n}`]?.v < 0) {
                        return setError(t('lang_saved_classes.txt_error_points_can_not_be_negative'));
                    }
                }

                if (typeof dataArray[`A${n}`]?.v === 'undefined' && typeof dataArray[`B${n}`]?.v === 'number') {
                    return setError(t('lang_saved_classes.txt_error_some_names_are_missing'));
                } else if (typeof dataArray[`A${n}`]?.v !== 'undefined') {
                    if (typeof dataArray[`B${n}`]?.v === 'number') {
                        csvFileData.push({
                            name: dataArray[`A${n}`]?.v.toString(),
                            points: dataArray[`B${n}`]?.v,
                        });
                    } else if (typeof dataArray[`B${n}`]?.v === 'undefined') {
                        csvFileData.push({
                            name: dataArray[`A${n}`]?.v.toString(),
                            points: 0,
                        });
                    }
                }
            }

            if (csvFileData.length > classLimit) {
                return setError(
                    t('lang_saved_classes.txt_error_max_participant_allowed', {
                        classLimit: classLimit,
                    }),
                );
            }

            csvFileData !== undefined &&
                csvFileData.forEach((data: { name: string; points: number }, index: number) => {
                    return newDataList.push({
                        index: index,
                        name: data.name,
                        points: data.points,
                        error: '',
                    });
                });

            for (let n = 0; n < newDataList.length; n++) {
                if (typeof newDataList[n].name === 'string' && typeof newDataList[n].points === 'number') {
                    validationSuccess = true;
                } else {
                    validationSuccess = false;
                }
            }
            handleImport();
        };
        reader.readAsArrayBuffer(file);
    };

    const handleImport = () => {
        if (validationSuccess) {
            localService.setTempAddedParticipants([]);
            setResetData(true);
            setCsvData(newDataList);
            addRowOnImportCsv(newDataList.length);
            handleCSVClose();
            webviewMessenger.sendUsageLog(`Imported CSV`);
        }
    };

    const deselectFile = () => {
        setFileName('');
        setError('');
        setCsvData([]);
    };

    useEffect(() => {
        if (file !== undefined) parseCsv();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file]);

    return (
        <Modal
            className="modal"
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={csvUploadOpen}
            onClose={handleCSVClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 300,
            }}
        >
            <Fade in={csvUploadOpen}>
                <Box className="popupBox">
                    {/* <Typography id="transition-modal-description" className="csvUploadDetails">
                        {t('lang_saved_classes.txt_you_can_first')}{' '}
                        <span
                            onClick={() => {
                                webviewMessenger.sendUsageLog(`Clicked "Download CSV template"`);
                                handleDownloadCsvTemplate();
                            }}
                        >
                            {t('lang_saved_classes.txt_csv_download')}
                        </span>{' '}
                        {t('lang_saved_classes.txt_csv_build_participant')}
                    </Typography>
                    <div className="csvInput">
                        {fileName ? (
                            <div className="uploadedContainer">
                                <FilePresentIcon style={{ color: '#49454f' }} />
                                <Typography className="fileName">{fileName}</Typography>
                                <CancelIcon onClick={deselectFile} style={{ color: '#ec928e' }} />
                            </div>
                        ) : (
                            <Fragment>
                                <div className="textContainer">
                                    <Typography className="text">
                                        {t('lang_saved_classes.txt_drag_drop')}
                                        <br />
                                        {t('lang_common.txt_or')}
                                    </Typography>
                                    <Typography className="upload">
                                        {t('lang_saved_classes.txt_upload_file')}
                                    </Typography>
                                </div>
                                <input
                                    type="file"
                                    className="react-csv-input"
                                    accept=".csv, .xls, .xlsx"
                                    onChange={(e) => handleFileInput(e.target.files && e.target.files[0])}
                                />
                            </Fragment>
                        )}
                    </div>
                    <div className="error">{fileName && error}</div>
                    <div className="btnContainer">
                        <Button
                            onClick={() => {
                                webviewMessenger.sendUsageLog(`Clicked "Import (CSV)"`);
                                handleImport();
                            }}
                            className="importBtn"
                        >
                            {t('lang_common.txt_Import')}
                        </Button>
                        <Button
                            className="cancelBtn"
                            onClick={() => {
                                webviewMessenger.sendUsageLog(`Clicked "Cancel (import CSV)"`);
                                handleCSVClose();
                            }}
                        >
                            {t('lang_common.btn_cancel')}
                        </Button>
                    </div> */}

                    <div className={`newDesign`}>
                        <div className="csvInput">
                            {fileName ? (
                                <div className="uploadedContainer">
                                    <FilePresentIcon style={{ color: '#49454f' }} />
                                    <Typography className="fileName">{fileName}</Typography>
                                    <CancelIcon onClick={deselectFile} style={{ color: '#ec928e' }} />
                                </div>
                            ) : (
                                <Fragment>
                                    <div className="textContainer">
                                        <img
                                            src={utilConstants.IMAGE_URLS.UPLOAD_DOCUMENT}
                                            alt="ClassPoint import CSV"
                                        />
                                        <input
                                            type="file"
                                            className="react-csv-input"
                                            accept=".csv, .xls, .xlsx"
                                            onChange={(e) => handleFileInput(e.target.files && e.target.files[0])}
                                        />
                                    </div>

                                    <Typography id="transition-modal-description" className="csvUploadDetails">
                                        {t('lang_saved_classes.need_template')} <br />
                                        <span
                                            onClick={() => {
                                                webviewMessenger.sendUsageLog(`Downloaded CSV template"`);
                                                handleDownloadCsvTemplate();
                                            }}
                                        >
                                            {t('lang_saved_classes.download_here')}
                                        </span>
                                    </Typography>
                                </Fragment>
                            )}
                        </div>
                        {fileName && error && <div className="error">Error - {error}</div>}
                    </div>
                </Box>
            </Fade>
        </Modal>
    );
};

export default ImportCSV;
