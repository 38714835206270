import './SingleCard.scss';

import { decodeGroupColorHash } from '../../../../helpers/savedClassGroupHelper';

import { SavedClassGroupInterface } from '../../../../interfaces/savedClassGroup.interface';
import SavedClassParticipantInterface from '../../../../interfaces/saved-class-participant.interface';
import { utilConstants } from '../../../../constants/utils.constants';

import { UserGroup } from '../../../../Icon';

import CustomAvatar, { AvatarType } from '../../../../components/Common/CustomAvatar';
import ParticipantInterface from '../../../../interfaces/participant.interface';

interface SingleCardInterface {
    isViewingInMyClass?: boolean;
    group: SavedClassGroupInterface;
    sortedParticipants: SavedClassParticipantInterface[];
    participantsInMyClass?: ParticipantInterface[];
    savedClassGroups: SavedClassGroupInterface[];
}

export default function SingleCard({
    isViewingInMyClass,
    group,
    sortedParticipants,
    participantsInMyClass,
    savedClassGroups,
}: SingleCardInterface) {
    const savedClassSelectedParticipants = sortedParticipants?.filter((data) => data.groupId === group.groupId);
    const myClassSelectedParticipants = participantsInMyClass?.filter((data) => data.groupId === group.groupId);

    return (
        <div className={`singleCard`}>
            <div className={`stripe`} style={{ background: decodeGroupColorHash(group.groupColor) }}></div>

            <div className={`topPart`}>
                <p className={`groupDetailsText`}>{group.groupName}</p>
                <p className={`totalStarsText`}>
                    <img src={utilConstants.IMAGE_URLS.starIcon} className={`starIcon`} alt="starIcon" />
                    {isViewingInMyClass && myClassSelectedParticipants
                        ? myClassSelectedParticipants.reduce(
                              (acc, curr) => acc + curr.participantTotalPoints + curr.participantPoints,
                              0,
                          )
                        : savedClassSelectedParticipants.reduce((acc, curr) => acc + curr.participantTotalPoints, 0)}
                </p>
            </div>

            <div className={`divider`}></div>

            <div className={`bottomPart`}>
                {savedClassSelectedParticipants.length > 0 &&
                    savedClassSelectedParticipants
                        .slice(0, Math.min(savedClassSelectedParticipants.length, 6))
                        .map((participant) => (
                            <CustomAvatar
                                key={participant.participantId}
                                avatarName={participant.participantName}
                                avatarUrl={participant.participantAvatar}
                                type={AvatarType.PARTICIPANT}
                                savedClassGroups={savedClassGroups}
                                groupId={participant.groupId}
                            />
                        ))}
                <p className={`participantCount`}>
                    <UserGroup />
                    {savedClassSelectedParticipants.length}
                </p>
            </div>
        </div>
    );
}
