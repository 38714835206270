import { useState, forwardRef } from 'react';
import { FitToViewport } from 'react-fit-to-viewport';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { userActions } from '../../../../actions/user.actions';
import apiSavedClasses from '../../../../services/apiSavedClasses';
import { calculateParticipantLevelAndBadge, getLevelsArray } from '../../../../helpers/userhelper';
import { getValidUserFromStore } from '../../../../helpers/storeHelpers';

import SavedClassForUserInterface from '../../../../interfaces/saved-class-for-user.interface';
import { SavedClassGroupInterface } from '../../../../interfaces/savedClassGroup.interface';
import SavedClassParticipantInterface from '../../../../interfaces/saved-class-participant.interface';
import { ViewportSavedClasses } from '../../../../constants/viewport-constants';
import { INPUT_LIMITS } from '../../../../constants/utils.constants';

import CommonButton from '../../../../components/Common/CommonButton';
import EditParticipantAvatar from './EditParticipantAvatar';

interface RenameParticipantModalInterface {
    closeAlert: any;
    savedClass: SavedClassForUserInterface;
    selectedParticipant: SavedClassParticipantInterface;
    sortedParticipants: SavedClassParticipantInterface[];
    onParticipantsUpdated: any;
    savedClassGroups: SavedClassGroupInterface[];
}

const RenameParticipantModal = (
    {
        closeAlert,
        savedClass,
        selectedParticipant,
        sortedParticipants,
        onParticipantsUpdated,
        savedClassGroups,
    }: RenameParticipantModalInterface,
    ref: any,
) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const user = getValidUserFromStore('RenameParticipantModal');

    const [newName, setNewName] = useState(selectedParticipant.participantName);
    const [error, setError] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);

    const validateAndUpdateNameOnChange = async (event: any) => {
        const newName = event.target.value;
        setNewName(newName);
        const trimmedName = newName.trim();

        if (trimmedName.length > INPUT_LIMITS.PARTICIPANT_NAME_MAX) {
            setError(t('lang_saved_classes.txt_name_too_long'));
            return;
        }
        if (
            sortedParticipants.some(
                (person: any) => person.participantName.trim().toUpperCase() === newName.trim().toUpperCase(),
            )
        ) {
            setError(t('lang_saved_classes.txt_name_taken'));
            return;
        }

        setError('');
    };

    const handleNameSpecialKeyPresses = (event: any) => {
        const key = event.keyCode || event.charCode;
        if (key === 8 || key === 46) {
            // Backspace or Delete key
            window.setTimeout(function () {
                checkIfNameIsTooShort(event.target.value);
            }, 1);
        }
    };

    const checkIfNameIsTooShort = (nameInput: string) => {
        const trimmedName = nameInput.trim();
        if (trimmedName.length > 0 && trimmedName.length < 2) {
            setError(t('lang_saved_classes.txt_name_too_short'));
        }
    };

    const submitRenameParticipant = async () => {
        if (!newName) return setError(t('lang_saved_classes.txt_name_cannot_be_empty'));
        if (error) return;

        setIsProcessing(true);

        const editSavedClassReply = await apiSavedClasses.renameParticipant(
            savedClass.savedClassId,
            selectedParticipant.participantId,
            { participantUsername: newName.trim(), participantName: newName.trim() },
        );

        setIsProcessing(false);
        closeAlert();

        if (!editSavedClassReply) return dispatch(userActions.showApiError());
        const participantsWithLevel = editSavedClassReply.participants.map((p) => ({
            ...p,
            participantLevelAndBadge: calculateParticipantLevelAndBadge(p.participantTotalPoints, getLevelsArray(user)),
        }));

        onParticipantsUpdated(participantsWithLevel);
    };

    return (
        <FitToViewport
            className="modal_viewport savedClassParticipantDetailsEdit"
            width={ViewportSavedClasses.width}
            height={ViewportSavedClasses.height}
            minZoom={ViewportSavedClasses.minZoom}
            maxZoom={ViewportSavedClasses.maxZoom}
        >
            <div className="close_modal" onClick={closeAlert}></div>
            <Box className="csvmodal_box wdth_400">
                <div className="modal_head">
                    <Typography variant="body1">{t('lang_saved_classes.edit_participant_name_and_avatar')}</Typography>
                    <CloseIcon onClick={closeAlert} />
                </div>

                <div className="addparticipent_modal_sec">
                    <div className={`participantDetails`}>
                        <div className="profile_img">
                            {/* <CustomAvatar
                                    avatarName={newName.trim()}
                                    avatarUrl={selectedParticipant.participantAvatar}
                                    type={AvatarType.PARTICIPANT}
                                    savedClassGroups={savedClassGroups}
                                    groupId={selectedParticipant.groupId}
                                /> */}

                            <EditParticipantAvatar
                                selectedParticipant={selectedParticipant}
                                savedClassId={savedClass.savedClassId}
                                savedClassGroups={savedClassGroups}
                                onParticipantsUpdated={onParticipantsUpdated}
                            />
                        </div>
                        <div className="participent_table_box">
                            <div className="left">
                                <div className="profile_name">
                                    <TextField
                                        autoComplete="none"
                                        id="standard-basic"
                                        placeholder={t('lang_saved_classes.txt_participant_name_validator', {
                                            inputLimit: INPUT_LIMITS.PARTICIPANT_NAME_MAX,
                                        })}
                                        inputProps={{ maxLength: INPUT_LIMITS.PARTICIPANT_NAME_MAX }}
                                        variant="standard"
                                        value={newName}
                                        onChange={(e) => validateAndUpdateNameOnChange(e)}
                                        onBlur={(e) => checkIfNameIsTooShort(e.target.value)}
                                        onKeyDown={(e) => handleNameSpecialKeyPresses(e)}
                                        error={true}
                                    />
                                </div>
                            </div>
                            <div className="right"></div>
                        </div>
                    </div>

                    <div className="participent_table_error">
                        <Typography>{error}</Typography>
                    </div>
                </div>
                <div className="button_sec justify_center">
                    {isProcessing ? (
                        <CommonButton
                            isLoading={true}
                            variant="contained"
                            type="button"
                            text={t('lang_common.btn_updating')}
                        />
                    ) : (
                        <CommonButton
                            isLoading={false}
                            variant="contained"
                            type="button"
                            text={t('lang_common.btn_save')}
                            clickFunction={() => submitRenameParticipant()}
                        />
                    )}
                </div>
            </Box>
        </FitToViewport>
    );
};

export default forwardRef(RenameParticipantModal);
