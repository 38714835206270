import { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

import { Typography } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

import { getValidUserFromStore } from '../../../helpers/storeHelpers';
import { getBase64 } from '../../../helpers/utils';
import apiSavedClasses from '../../../services/apiSavedClasses';
import { userActions } from '../../../actions/user.actions';

import SavedClassForUserInterface from '../../../interfaces/saved-class-for-user.interface';

import CustomAvatar, { AvatarType } from '../../../components/Common/CustomAvatar';
import ImagePickCropUpload from '../../../components/Common/ImagePickCropUpload';

interface SaveClassAvatarInterface {
    savedClass?: SavedClassForUserInterface;
    setSavedClass?: (arg: SavedClassForUserInterface) => void;
    savedClassAvatar?: string;
    setSavedClassAvatar?: (arg: string) => void;
}

const SavedClassAvatar = ({
    savedClass,
    setSavedClass,
    savedClassAvatar,
    setSavedClassAvatar,
}: SaveClassAvatarInterface) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const user = getValidUserFromStore('SavedClassAvatar');

    const inputfileref = useRef<any>(null);

    const [imageData, setImageData] = useState<any>();
    const [isImageEditModalOpen, setIsImageEditModalOpen] = useState(false);

    useEffect(() => {
        if (savedClassAvatar) loadImageDataFromUrl(savedClassAvatar);
        else if (savedClass && savedClass.savedClassAvatar) loadImageDataFromUrl(savedClass.savedClassAvatar);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedClassAvatar, savedClass?.savedClassAvatar]);

    const loadImageDataFromUrl = (url: string) => {
        getBase64(url).then((base64) => {
            setImageData(base64);
        });
    };

    const handleImageUpdated = async (imageSavedPayload: {
        imageUrl: string;
        originalImageUrl: string;
        cropCursor: string;
    }) => {
        if (savedClass !== undefined && setSavedClass !== undefined) {
            // editing scenario
            const updatedSavedClass = await apiSavedClasses.editSavedClassNameCodeAvatar(savedClass.savedClassId, {
                savedClassAvatar: imageSavedPayload.imageUrl,
            });
            setIsImageEditModalOpen(false);
            if (!updatedSavedClass) dispatch(userActions.showApiError());
            else {
                setSavedClass({
                    ...savedClass,
                    savedClassAvatar: imageSavedPayload.imageUrl,
                });
                dispatch(userActions.checkAndUpdateUser());
            }
            return;
        }
        if (savedClassAvatar !== undefined && setSavedClassAvatar !== undefined) {
            // creating scenario
            setSavedClassAvatar(imageSavedPayload.imageUrl);
            setIsImageEditModalOpen(false);
            return;
        }
    };

    const handleImageEditCanceled = async () => {
        if (savedClassAvatar) {
            getBase64(savedClassAvatar).then((base64) => {
                setImageData(base64);
            });
        } else {
            setImageData(null);
        }
        setIsImageEditModalOpen(false);
    };

    useEffect(() => {
        if (savedClassAvatar) loadImageDataFromUrl(savedClassAvatar);
        else if (savedClass && savedClass.savedClassAvatar) loadImageDataFromUrl(savedClass.savedClassAvatar);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedClassAvatar, savedClass?.savedClassAvatar]);

    return (
        <Fragment>
            <div className="profile_upload">
                {savedClassAvatar || savedClass?.savedClassAvatar ? (
                    <div className="upload_profile_images">
                        <label htmlFor="icon-button-file-saved-class" onClick={() => setIsImageEditModalOpen(true)}>
                            <CustomAvatar
                                avatarUrl={savedClass ? savedClass.savedClassAvatar : savedClassAvatar}
                                avatarName={savedClass ? savedClass.savedClassName : ''}
                                type={AvatarType.SAVEDCLASS}
                            />
                        </label>
                        {savedClass && savedClass.savedClassCode !== null && (
                            <div className="changes_profile_images">
                                <label htmlFor="icon-button-file">
                                    <CameraAltIcon onClick={() => setIsImageEditModalOpen(true)} />
                                    <ImagePickCropUpload
                                        inputfileref={inputfileref}
                                        isInputDisabled={true}
                                        initialImageData={imageData}
                                        setImageData={setImageData}
                                        outputImageName={`${user.email}-saved-class-${v4()}.jpg`}
                                        isImageEditModalOpen={isImageEditModalOpen}
                                        setIsImageEditModalOpen={setIsImageEditModalOpen}
                                        onUpdated={handleImageUpdated}
                                        handleImageEditCanceled={handleImageEditCanceled}
                                        canReset={true}
                                        setSavedCrop={() => {}}
                                    />
                                </label>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="upload_profile_images">
                        <label htmlFor="icon-button-file-saved-class">
                            {savedClass ? (
                                <CustomAvatar avatarName={savedClass.savedClassName} type={AvatarType.SAVEDCLASS} />
                            ) : (
                                <Typography variant="caption">{t('lang_common.btn_add_image')}</Typography>
                            )}
                        </label>
                        <div className="changes_profile_images">
                            <label htmlFor="icon-button-file">
                                <CameraAltIcon onClick={() => inputfileref.current.click()} />
                                <ImagePickCropUpload
                                    inputfileref={inputfileref}
                                    initialImageData={imageData}
                                    setImageData={setImageData}
                                    outputImageName={`${user.email}-saved-class-${v4()}.jpg`}
                                    isImageEditModalOpen={isImageEditModalOpen}
                                    setIsImageEditModalOpen={setIsImageEditModalOpen}
                                    onUpdated={handleImageUpdated}
                                    handleImageEditCanceled={handleImageEditCanceled}
                                    setSavedCrop={() => {}}
                                />
                            </label>
                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default SavedClassAvatar;
