import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';

import './MyClassViewToggle.scss';

interface MyClassViewToggleInterface {
    activeTabIndex: string;
    handleActiveTab: (event: SyntheticEvent, newValue: string) => void;
    sortedParticipantsLength: number;
    savedClassGroupsLength: number;
}

export default function MyClassViewToggle({
    activeTabIndex,
    handleActiveTab,
    sortedParticipantsLength,
    savedClassGroupsLength,
}: MyClassViewToggleInterface) {
    const { t } = useTranslation();

    return (
        <div className="myClassTabSwitch">
            <TabContext value={activeTabIndex}>
                <Box className={`savedClasses tabContainer ${activeTabIndex === '2' && 'groupTab'}`}>
                    <TabList onChange={handleActiveTab}>
                        <Tab
                            className="tabButton"
                            label={
                                <p className={`participantPanel`}>
                                    {t('lang_grouping.toggle_individual')}{' '}
                                    <span className={`count`}>{sortedParticipantsLength}</span>
                                </p>
                            }
                            value="1"
                        />
                        <Tab
                            className="tabButton"
                            label={
                                <p className={`participantPanel`}>
                                    {t('lang_grouping.toggle_groups')}{' '}
                                    <span className={`count`}>{savedClassGroupsLength}</span>
                                </p>
                            }
                            value="2"
                        />
                    </TabList>
                </Box>
            </TabContext>
        </div>
    );
}
