import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';

import './LeaderBoardGroups.scss';

import { decodeGroupColorHash } from '../../../../helpers/savedClassGroupHelper';

import { LeaderboardGroupRankInterface } from '../../../../interfaces/leaderboard.interface';
import { NO_RANK_DISPLAY, utilConstants } from '../../../../constants/utils.constants';

import emptyFace from '../../../../assets/animation/empty-face.json';

interface LeaderBoardOverallGroupsInterface {
    groupRankStats?: LeaderboardGroupRankInterface;
}

export default function LeaderBoardOverallGroups({ groupRankStats }: LeaderBoardOverallGroupsInterface) {
    const { t } = useTranslation();

    return (
        <div className={`leaderBoardOverallGroupsContent`}>
            <div className={`header`}>
                <p className={`text`}>{t('lang_grouping.group_rank')}</p>
            </div>

            <div className={`leaderBoardRankBody`}>
                {groupRankStats ? (
                    groupRankStats.groupStatsOrderByTotal.map((group, index) => (
                        <div key={index} className={`leaderboardGroupCard`}>
                            <div
                                style={{ background: decodeGroupColorHash(group.groupColor) }}
                                className={`bodyHeader`}
                            >
                                <p className={`text`}>{group.groupName}</p>
                            </div>
                            <div className={`content`}>
                                <img src={utilConstants.IMAGE_URLS.starIcon} className={`starIcon`} alt="starIcon" />
                                <p className={`starCount`}>{group.totalStarsCount}</p>

                                {Math.max(...groupRankStats.totalStarsArray) <= 0 ? (
                                    <p className={`rankCount`}>{NO_RANK_DISPLAY}</p>
                                ) : (
                                    <>
                                        {groupRankStats.totalStarsArray.indexOf(group.totalStarsCount) < 3 ? (
                                            <img
                                                src={
                                                    utilConstants.LEADER_BOARD_RANK_IMAGE[
                                                        `RANK_${
                                                            groupRankStats.totalStarsArray.indexOf(
                                                                group.totalStarsCount,
                                                            ) + 1
                                                        }`
                                                    ]
                                                }
                                                className={`medalIcon`}
                                                alt="starIcon"
                                            />
                                        ) : (
                                            <p className={`rankCount`}>
                                                {groupRankStats.totalStarsArray.indexOf(group.currentStarsCount) + 1}
                                            </p>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    ))
                ) : (
                    <div className={`leaderboardNoGroupView`}>
                        <div className={`lottieContainer`}>
                            <Lottie loop={true} animationData={emptyFace} play speed={1} />
                        </div>
                        <p className={`noGroupText`}>{t('lang_grouping.no_group')}</p>
                    </div>
                )}
            </div>
        </div>
    );
}
