import { Box, Modal, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React, { Fragment } from 'react';
import { FitToViewport } from 'react-fit-to-viewport';
import { useTranslation } from 'react-i18next';
import { FullScreenPointLottie } from '../../../../components/Common/AnimationModules';
import CommonChip from '../../../../components/Common/CommonChip';
import GivePointButton from '../../../../components/Common/GivePointButton';
import { SimpleFadeAnimation } from '../../../../constants/animation-configs';
import { ViewportConstants } from '../../../../constants/viewport-constants';
import ParticipantChipInterface from '../../../../interfaces/participant-chip.interface';
import webviewMessenger from '../../../../services/webviewMessenger';

function QuickPollModal(
    {
        isModalOpen,
        isAnimating,
        setIsModalOpen,
        givePointsConfettiRef,
        style,
        modalChoiceId,
        participantChips,
        givePointHandler,
    }: any,
    ref: any,
) {
    const { t } = useTranslation();

    return (
        <>
            <Modal
                open={isModalOpen}
                onClose={() => !isAnimating && setIsModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <>
                    <FitToViewport
                        width={ViewportConstants.width}
                        height={ViewportConstants.height}
                        minZoom={ViewportConstants.minZoom}
                        maxZoom={ViewportConstants.maxZoom}
                        className="modal_viewport"
                    >
                        <Fragment>
                            <FullScreenPointLottie ref={givePointsConfettiRef} />

                            <div className="close_modal" onClick={() => !isAnimating && setIsModalOpen(false)}></div>
                            <Box
                                component={motion.div}
                                variants={SimpleFadeAnimation}
                                initial="initial"
                                animate="animate"
                                sx={style}
                                className="word_cloud_modal new_word_cloud"
                            >
                                <div className="word_cloudhead">
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        {t('lang_activity.option_dialog', {
                                            option: t(`lang_activity.qpchoice_${modalChoiceId}`),
                                        })}
                                    </Typography>
                                </div>
                                <div className="word_cloud_modal_body">
                                    <div className="WC_body_inner quickPollSparkleLottie">
                                        {participantChips.map((participant: ParticipantChipInterface) => (
                                            <Typography
                                                id="modal-modal-description"
                                                variant="caption"
                                                key={participant.participantId}
                                            >
                                                <CommonChip
                                                    participantName={participant.participantName}
                                                    participantAvatar={participant.participantAvatar}
                                                />
                                            </Typography>
                                        ))}
                                    </div>
                                </div>
                                <div className="modal_footer">
                                    <GivePointButton
                                        label={t('lang_common.btn_give_points_to_all')}
                                        onClick={() => {
                                            webviewMessenger.sendUsageLog(
                                                `[S] Clicked "Award stars to all" for selected participants`,
                                            );
                                            givePointHandler();
                                        }}
                                        activity="modifyButton"
                                    />
                                </div>
                            </Box>
                        </Fragment>
                    </FitToViewport>
                </>
            </Modal>
        </>
    );
}

export default React.forwardRef(QuickPollModal);
