import { useState } from 'react';
import { FitToViewport } from 'react-fit-to-viewport';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import QRCode from 'qrcode.react';

import { Box, Modal, Typography } from '@mui/material';
import { Divider } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';

import { getValidClassSessionFromStore, getValidUserFromStore } from '../../helpers/storeHelpers';
import { findUserClassLimit } from '../../helpers/userhelper';

import { ViewportConstants } from '../../constants/viewport-constants';
import { SimpleFadeAnimation } from '../../constants/animation-configs';
import { utilConstants } from '../../constants/utils.constants';
import webviewMessenger from '../../services/webviewMessenger';

interface NoParticipantModalInterface {
    isQRModalOpen: boolean;
    closeModal: () => void;
}

const NoParticipantModal = ({ isQRModalOpen, closeModal }: NoParticipantModalInterface) => {
    const { t } = useTranslation();
    const user = getValidUserFromStore('MyClassQRModal');
    const classLimit = findUserClassLimit(user);
    const classSession = getValidClassSessionFromStore('MyClassQRModal');

    const isClassFull = classSession.participantList.length >= classLimit ? true : false;

    const [toggleCopiedBtn, setToggleCopiedBtn] = useState(false);

    const copyUrl = (url: string) => navigator.clipboard.writeText(url);

    return (
        <Modal
            open={isQRModalOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="mymodal"
            onClose={() => closeModal()}
        >
            <>
                <FitToViewport
                    className="modal_viewport"
                    width={ViewportConstants.width}
                    height={ViewportConstants.height}
                    minZoom={ViewportConstants.minZoom}
                    maxZoom={ViewportConstants.maxZoom}
                >
                    <div className="close_modal" onClick={closeModal}></div>
                    <Box
                        component={motion.div}
                        initial="initial"
                        animate="animate"
                        variants={SimpleFadeAnimation}
                        className="modal_box myclass_qr_modal"
                    >
                        <div
                            className={`${
                                classSession.classSessionName ? 'qr_popup joinActivity' : 'qr_popup joinActivity open'
                            } ${(isClassFull || classSession.isLocked) && 'trialActivation'}`}
                        >
                            <div className="qr_tiltle">
                                <Typography variant="h4">{t('lang_activity.invite_participants')}</Typography>
                            </div>

                            <div className="qr_pop_sec">
                                <div className="qr_pop_left">
                                    <QRCode
                                        size={512}
                                        value={`https://${process.env.REACT_APP_PARTICIPANT}?code=${classSession.classCode}`}
                                        imageSettings={{
                                            src: utilConstants.IMAGE_URLS.brand_logo,
                                            x: undefined,
                                            y: undefined,
                                            height: 88,
                                            width: 88,
                                            excavate: true,
                                        }}
                                    />
                                </div>

                                <div className="qr_pop_right">
                                    <div className="url_sec">
                                        <div className="url_content">
                                            <Typography variant="caption">{t('lang_myclass.txt_url')}</Typography>
                                            <Typography variant="h4">
                                                {process.env.REACT_APP_PARTICIPANT}
                                                <span className={`copyIconContainer`}>
                                                    <ContentCopyIcon
                                                        onClick={() => {
                                                            webviewMessenger.sendUsageLog(`[S] Copied join class URL`);
                                                            copyUrl(
                                                                `https://${process.env.REACT_APP_PARTICIPANT}?code=${classSession.classCode}`,
                                                            );
                                                            setToggleCopiedBtn(true);
                                                            setTimeout(() => setToggleCopiedBtn(false), 1000);
                                                        }}
                                                        className="icon"
                                                    />
                                                    {toggleCopiedBtn && (
                                                        <span className="copyurl_tooltip">
                                                            {t('lang_share_classpoint.tooltip_copied')}
                                                            <DoneIcon />
                                                        </span>
                                                    )}
                                                </span>
                                            </Typography>
                                            <Divider className="divider" />
                                            <Typography variant="caption">{t('lang_common.txt_class_code')}</Typography>
                                            <Typography variant="h3">{classSession.classCode}</Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </FitToViewport>
            </>
        </Modal>
    );
};

export default NoParticipantModal;
