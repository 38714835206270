import { Chip, Typography } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import CustomAvatar, { AvatarType } from '../../../components/Common/CustomAvatar';
import { UserGroup, UserOff } from '../../../Icon';
import SavedClassForUserInterface from '../../../interfaces/saved-class-for-user.interface';
import webviewMessenger from '../../../services/webviewMessenger';

interface DemoClassInterface {
    changeCurrentPage: (currentPage: string, demoClass: SavedClassForUserInterface) => void;
    demoClass: SavedClassForUserInterface;
}

function DemoClass({ demoClass, changeCurrentPage }: DemoClassInterface) {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div
                className={`participent_Grid_Card_demo`}
                onClick={() => {
                    webviewMessenger.sendUsageLog(`Clicked demo class card`);
                    changeCurrentPage('ViewEditClass', demoClass);
                }}
            >
                <div className="participent_grid_top">
                    <div className="profile">
                        <div className="profile_text">
                            <Typography variant="h4">{demoClass.savedClassName}</Typography>
                            <div className="p_class_code">
                                <Chip label={t('lang_myclass.opt_txt_random_code')} />
                            </div>
                        </div>
                        <div className="profile_img">
                            <CustomAvatar
                                avatarUrl={demoClass.savedClassAvatar}
                                avatarName={demoClass.savedClassName}
                                type={AvatarType.SAVEDCLASS}
                            />
                        </div>
                    </div>
                </div>
                <div className="total_participent">
                    {demoClass.participantsCount > 0 ? <UserGroup /> : <UserOff />}
                    <Typography variant="body1">
                        {demoClass.participantsCount}{' '}
                        {demoClass.participantsCount === 1
                            ? t('lang_saved_classes.txt_participant')
                            : t('lang_saved_classes.txt_participants')}
                    </Typography>
                </div>
            </div>
        </Fragment>
    );
}

export default DemoClass;
