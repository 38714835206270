import { Typography } from '@mui/material';

import { SavedClassGroupInterface } from '../../../interfaces/savedClassGroup.interface';
import { NO_RANK_DISPLAY, utilConstants } from '../../../constants/utils.constants';

import CustomAvatar, { AvatarType } from '../../../components/Common/CustomAvatar';

const LeaderBoardCurrentSingle = ({
    index,
    savedClassGroups,
    participant,
    barWidthArray,
}: {
    index: number;
    savedClassGroups: SavedClassGroupInterface[];
    participant: any;
    barWidthArray: number[];
}) => {
    return (
        <>
            <div
                className={`bargraph ${participant.participantPoints === 0 && 'min_width'} ${
                    participant.participantRank === 1
                        ? 'first'
                        : participant.participantRank === 2
                        ? 'second'
                        : participant.participantRank === 3
                        ? 'third'
                        : ''
                }`}
                style={{ width: barWidthArray[index] }}
            >
                <div className={`leader_board_number ${participant?.participantPoints === 0 && 'no_background'}`}>
                    <span className="typeA">
                        <Typography variant="body1">{participant?.participantRank || NO_RANK_DISPLAY}</Typography>
                    </span>
                </div>
                <div className="Lbprofile_img">
                    <CustomAvatar
                        avatarName={participant?.participantName}
                        avatarUrl={participant?.participantAvatar}
                        type={AvatarType.PARTICIPANT}
                        savedClassGroups={savedClassGroups}
                        groupId={participant.groupId}
                    />
                </div>
                <Typography className="profile_name resize" variant="body1">
                    {participant?.participantName}
                </Typography>
            </div>
            <div className="tooltip_wrap">
                <Typography className="profile_name" variant="body1">
                    {participant?.participantName}
                </Typography>
                <span className="chart_tooltip">
                    <img src={utilConstants.IMAGE_URLS.quizSummaryIconStar} alt="point_icon" />
                    {participant?.participantPoints}
                </span>
            </div>
        </>
    );
};

export default LeaderBoardCurrentSingle;
