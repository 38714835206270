import { Typography, Avatar, Button } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { getValidUserFromStore } from '../../../helpers/storeHelpers';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { userActions } from '../../../actions/user.actions';
import { UserActionTypes } from '../../../constants/user-action-types';
import CommonButton from '../../../components/Common/CommonButton';
import { InterfaceLanguages } from '../../../constants/languages';
import apiPreferences from '../../../services/apiPreferences';
import webviewMessenger from '../../../services/webviewMessenger';

export default function LanguageSetting() {
    const dispatch = useDispatch();
    const user = getValidUserFromStore('LanguageSetting');
    const currentLanguage = user.userPreferences?.language || 'en';
    const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        webviewMessenger.sendUsageLog(`Navigated to Settings -> Language`);
    }, []);

    const { t } = useTranslation();

    const changeLanguage = async () => {
        setIsLoading(true);
        const updatedUser = await apiPreferences.saveLanguage(user.email, selectedLanguage);
        if (!updatedUser) return dispatch(userActions.showApiError());

        dispatch(userActions.updateUser(UserActionTypes.CHANGE_LANGUAGE, updatedUser));
        setIsLoading(false);
    };

    const currentLanguageDetails = InterfaceLanguages.filter((result: any) => {
        return result.code === currentLanguage;
    });

    const languagesExceptCurrent = InterfaceLanguages.filter((result: any) => {
        return result.code !== currentLanguage;
    }).sort(function (a: any, b: any) {
        if (a.nameInEnglish < b.nameInEnglish) {
            return -1;
        }
        if (a.nameInEnglish > b.nameInEnglish) {
            return 1;
        }
        return 0;
    });

    const chooseLanguage = (lang: string) => {
        setSelectedLanguage(lang);
    };

    return (
        <Fragment>
            <div className="body_head">
                <Typography variant="h2">{t('lang_settings.language_title')}</Typography>
            </div>
            <div className="language_sec">
                <div className="language_top">
                    <div className="language_display">
                        {currentLanguageDetails.length > 0 ? (
                            <div className="planbox">
                                <div className="plan_head">
                                    <Typography variant="h3">
                                        {t('lang_settings.txt_your_current_language')}:
                                    </Typography>
                                </div>
                                <div className="plan_body">
                                    <Typography variant="h4">
                                        {currentLanguageDetails[0].name} -{' '}
                                        <Typography variant="caption">
                                            {currentLanguageDetails[0].nameInEnglish}
                                        </Typography>
                                    </Typography>
                                </div>
                                <div className="language_flag">
                                    <Avatar alt="country" src={currentLanguageDetails[0].country_flag} />
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="language_area">
                    <div className="language_wrap">
                        {languagesExceptCurrent.map((language: any, index: number) => (
                            <Fragment key={index}>
                                <Button
                                    className={
                                        selectedLanguage === language.code ? 'language_box active' : 'language_box'
                                    }
                                    onClick={() => chooseLanguage(language.code)}
                                >
                                    <Avatar alt="country" src={language.country_flag} />
                                    <div className="language_name">
                                        <Typography variant="h4">{language.name}</Typography>
                                        <Typography variant="h4" className="sub">
                                            {language.nameInEnglish}
                                        </Typography>
                                    </div>
                                </Button>
                            </Fragment>
                        ))}
                    </div>
                    <div className="profile_update_btn">
                        {isLoading ? (
                            <CommonButton
                                isLoading={true}
                                variant="contained"
                                className="Edit profile"
                                type="button"
                                color="primary"
                                text={t('lang_common.btn_changing_language')}
                            />
                        ) : (
                            <CommonButton
                                isLoading={false}
                                variant="contained"
                                className="Edit profile"
                                type="button"
                                color="primary"
                                text={t('lang_settings.btn_change_language')}
                                clickFunction={() => changeLanguage()}
                            />
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
