import { useEffect, useRef, useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { useDispatch, useSelector } from 'react-redux';

import { getRandomMusic } from '../../helpers/utils';
import { webviewSettingsActions } from '../../actions/webview-settings.actions';

import StoreInterface from '../../interfaces/store.interface';
import { WebviewSettingsActionTypes } from '../../constants/webview-settings-action-types';

const Music = () => {
    const dispatch = useDispatch();
    const audioref: any = useRef();
    const webviewSettingsSlideshow = useSelector((state: StoreInterface) => state.webviewSettingsSlideshow);

    useEffect(() => {
        document.body.addEventListener('click', playAudio);
        return () => document.body.removeEventListener('click', playAudio);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioref]);

    const [hasFinished, setHasFinished] = useState(false);

    const playAudio = () => {
        if (audioref.current && audioref.current.audioEl) {
            audioref.current.audioEl.current
                .play()
                .then(() => {
                    // console.log('audio is playing');
                    document.body.removeEventListener('click', playAudio);
                })
                .catch((err: any) => {
                    //console.log('err', err);
                    var audio = document.getElementsByTagName('audio')[0];
                    if (audio) audio.play();
                });
        }
    };

    useEffect(() => {
        let randomUrl = getRandomMusic();
        dispatch(
            webviewSettingsActions.updateWebviewSettings(WebviewSettingsActionTypes.UPDATE_ACTIVITY_MUSIC_URL, {
                ...{ activityMusicUrl: randomUrl },
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasFinished]);

    return (
        <ReactAudioPlayer
            src={webviewSettingsSlideshow.activityMusicUrl}
            autoPlay={true}
            loop={false}
            muted={!webviewSettingsSlideshow.isActivityMusicOn}
            controls={false}
            volume={webviewSettingsSlideshow.activityMusicVolume / 100}
            onEnded={(e: any) => {
                setHasFinished(!hasFinished);
            }}
            ref={audioref}
        />
    );
};

export default Music;
