import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { getValidUserFromStore } from '../../../../helpers/storeHelpers';
import { isUserOnTrialOrPro } from '../../../../helpers/userhelper';
import UserInterface from '../../../../interfaces/user-interface';
import webviewMessenger from '../../../../services/webviewMessenger';

const LeaderHeader = ({ handleLeaderBoardView, isShowLeadersOverall, canInsertSlide }: any) => {
    const { t } = useTranslation();

    const user: UserInterface = getValidUserFromStore('LeaderHeader');
    const isTrialOrPro = isUserOnTrialOrPro(user);

    const insertSnapshot = async () => {
        const payload = {
            activityType: null,
            title: t(`lang_leaderboard.title`),
            snapshotCropper: [0, 0.11, 0, 0.14],
        };
        webviewMessenger.sendInsertAsSlide(JSON.stringify(payload));
    };

    return (
        <Fragment>
            <div className={`class_code ${!isTrialOrPro && 'basicUser'}`}>
                <div className={`switches-container ${!isShowLeadersOverall && 'switch_box'} `}>
                    <label htmlFor="switchMonthly" onClick={() => handleLeaderBoardView()}>
                        {t('lang_leaderboard.toggle_total_stars_rank')}
                        {!isTrialOrPro && (
                            <Typography className="pro" variant="caption">
                                PRO
                            </Typography>
                        )}
                    </label>
                    <label htmlFor="switchYearly" onClick={() => handleLeaderBoardView()}>
                        {t('lang_leaderboard.toggle_current_class_rank')}
                    </label>
                    <div className="switch-wrapper">
                        <div className="switch">
                            <div>
                                {t('lang_leaderboard.toggle_total_stars_rank')}
                                {!isTrialOrPro && (
                                    <Typography className="pro" variant="caption">
                                        PRO
                                    </Typography>
                                )}
                            </div>
                            <div>{t('lang_leaderboard.toggle_current_class_rank')}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="live_status">
                {canInsertSlide && (
                    <h2 onClick={insertSnapshot}>
                        <b>{t('lang_common.btn_insert_as_slide')}</b>
                    </h2>
                )}
            </div>
        </Fragment>
    );
};

export default LeaderHeader;
