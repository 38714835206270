import _ from 'lodash';
import { Fragment, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingResponses from './LoadingResponses';
import NoResponse from './NoResponse';
import './activity.scss';
import CommonAlert from '../../components/Common/CommonAlert';
import { utilConstants } from '../../constants/utils.constants';
import EndingActivityAlert from './AlertComponents/EndingActivityAlert';
import { activityActions } from '../../actions/activity.action';
import { webviewSettingsActions } from '../../actions/webview-settings.actions';
import { classSessionActions } from '../../actions/class-session.action';
import { WebviewSettingsActionTypes } from '../../constants/webview-settings-action-types';
import VotingModal from './Modals/VotingModal';
import VotingFooter from './ShortAnswer/VotingFooter';
import webviewMessenger from '../../services/webviewMessenger';
import EndingVoteAlert from './AlertComponents/EndingVoteAlert';
import { FitToViewport } from 'react-fit-to-viewport';
import { activityResponseLoaders } from '../../constants/activity-response-loaders';
import { ViewportConstants } from '../../constants/viewport-constants';
import ActivityContent from './ActivityContent';
import LiveStatus from './LiveStatus';
import ActivityResponseInterface from '../../interfaces/activity-response.interface';
import { getActivityFromStore } from '../../helpers/storeHelpers';
import Loading from './Loading/Loading';
import { processHtmlForInsertSlide } from '../../helpers/activityHelpers';
import StoreInterface from '../../interfaces/store.interface';
import Insert50ResponsesAlert from './AlertComponents/Insert50ResponsesAlert';
import ResponseAndPointsDto from '../../dtos/response-and-points.dto';
import { AlertTypes } from '../../constants/alert-types-enum';
import RestartActivityAlert from './AlertComponents/RestartActivityAlert';
import DeleteResponsesAlert from './AlertComponents/DeleteResponsesAlert';
import ActivityFooter from './ActivityFooter';
import { useTranslation } from 'react-i18next';
import { FullScreenPointLottie } from '../../components/Common/AnimationModules';
import EmptyModal from '../EmptyModal/EmptyModal';
import { generateAsterisksFromName } from '../../helpers/utils';
import ActivityHeader from './ActivityHeader';
import Header from '../../components/layout/Header/Header';
import ProPitchSlides from '../activateTrial/ProPitchSlides';
import ShareActivityModal from './AlertComponents/ShareActivityModal';
import { filterOnlineParticipants } from '../../helpers/classSessionHelpers';
import NoParticipantModal from './NoParticipantModal';
import AskCpAi from '../../components/webAppComponents/alert/AskCpAi';

const Activity = () => {
    const dispatch = useDispatch();
    const givePointsConfettiRef = useRef<any>();
    const activity = getActivityFromStore();
    const activityProps = activity.activityProps;
    const activityType = activityProps?.activityType || '';
    const isNamesHidden = activityProps?.isNamesHidden || false;
    const webviewSettingsEditMode = useSelector((state: StoreInterface) => state.webviewSettingsEditMode);
    const webviewSettingsSlideshow = useSelector((state: StoreInterface) => state.webviewSettingsSlideshow);
    const participantList = useSelector((state: StoreInterface) => state.classSession)?.participantList;

    const [showEmptyModal, setShowEmptyModal] = useState(false);
    const [isLiveStatusOpen, setIsLiveStatusOpen] = useState(false);
    const [isShowStartVotingModal, setIsShowStartVotingModal] = useState(false);
    const [isVoting, setIsVoting] = useState(false);
    const [isShowCorrectAnswer, setIsShowCorrectAnswer] = useState(false);
    const [isHighlightTopAnswers, setIsHighlightTopAnswers] = useState(false);
    const [isShowStarred, setIsShowStarred] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [selectedResponses, setSelectedResponses] = useState<ActivityResponseInterface[]>([]);
    const [isVotingNamesHidden, setIsVotingNamesHidden] = useState(false);
    const [votingNumber, setVotingNumber] = useState(0);
    const [activityLoaderIndex, setActivityLoaderIndex] = useState(0);
    const [alertType, setAlertType] = useState<AlertTypes>();
    const [isNoParticipantModalOpen, setIsNoParticipantModalOpen] = useState(false);

    const { t } = useTranslation();
    const workerRef: any = useRef<any>();

    const [isGivePointsToAll, setIsGivePointsToAll] = useState(false);

    useEffect(() => {
        setShowEmptyModal(true);
        setTimeout(() => {
            setShowEmptyModal(false);
        }, 1);
    }, []);

    let alertComponent;
    switch (alertType) {
        case AlertTypes.RESTART_ACTIVITY:
            alertComponent = (
                <RestartActivityAlert
                    onConfirmRestartActivity={() => confirmRestartActivity()}
                    onCancelAlert={() => setAlertType(undefined)}
                />
            );
            break;
        case AlertTypes.DELETE_RESPONSES:
            alertComponent = (
                <DeleteResponsesAlert
                    onConfirmDeleteResponses={() => {
                        confirmDeleteResponses();
                    }}
                    onCancelAlert={() => setAlertType(undefined)}
                />
            );
            break;
        case AlertTypes.INSERT_SLIDES_MORE_THAN_50:
            alertComponent = (
                <Insert50ResponsesAlert
                    onConfirmInsertSlides={() => confirmInsertSlideShow()}
                    onCancelAlert={() => setAlertType(undefined)}
                />
            );
            break;
        case AlertTypes.END_ACTIVITY:
            alertComponent = (
                <EndingActivityAlert
                    confirmEndActivity={() => dispatch(activityActions.endActivity())}
                    closeEndingAlert={() => dispatch(activityActions.dismissEndActivityWarningModal())}
                />
            );
            break;
        case AlertTypes.END_VOTING:
            alertComponent = (
                <EndingVoteAlert
                    confirmEndVote={() => confirmEndVote()}
                    closeEndingVoteModel={() => dispatch(activityActions.dismissEndVotingWarningModal())}
                />
            );
            break;
        case AlertTypes.QUIZ_MODE_PRO:
            alertComponent = (
                <div className={`trialFlowContentContainer`}>
                    <ProPitchSlides
                        isShowMultipleSlides={false}
                        startingSlideId={'pro_activity_quiz_mode'}
                        customClassNames={'default '}
                    />
                </div>
            );
            break;
        case AlertTypes.SHARE_ACTIVITY:
            alertComponent = (
                <div className={`shareContainer`}>
                    {activity.activityProps && <ShareActivityModal activity={activity} />}
                </div>
            );
            break;
        case AlertTypes.ASK_CP_AI:
            alertComponent = <AskCpAi />;
            break;
        default:
            break;
    }

    useEffect(() => {
        if (activity.isShowingEndActivityWarningModal) return setAlertType(AlertTypes.END_ACTIVITY);
        if (activity.isShowingEndVotingWarningModal) return setAlertType(AlertTypes.END_VOTING);
        return setAlertType(undefined);
    }, [activity.isShowingEndActivityWarningModal, activity.isShowingEndVotingWarningModal]);

    useEffect(() => {
        setIsVoting(false);
        setIsShowStarred(false);
    }, [activity.activityMode]);

    useEffect(() => {
        setIsShowCorrectAnswer(false);
        setIsHighlightTopAnswers(false);
        setActivityLoaderIndex(Math.floor(Math.random() * activityResponseLoaders.length));
        setSearchKeyword('');
        setIsNoParticipantModalOpen(false);
    }, [activity.activityProps]);

    useEffect(() => {
        workerRef.current = new window.Worker('./web-worker.js');
        workerRef.current.onerror = (err: any) => err;
        workerRef.current.onmessage = (e: any) => {
            // console.log('data', e.data);
            const { updatedSelectedResponses } = e.data;
            setSelectedResponses(updatedSelectedResponses);
        };
        return () => {
            workerRef.current.terminate();
        };
    }, []);

    // useEffect(() => {
    //     const searchNameOnlyActivities = [
    //         utilConstants.ACTIVITY_TYPE.SLIDE_DRAWING,
    //         utilConstants.ACTIVITY_TYPE.AUDIO_RECORD,
    //     ];
    //     // const updatedSelectedResponses = processResponses(
    //     //     activity.activityResponses,
    //     //     searchKeyword,
    //     //     searchNameOnlyActivities.includes(activityType),
    //     //     isNamesHidden,
    //     //     isShowStarred,
    //     //     [utilConstants.ACTIVITY_TYPE.IMAGE_UPLOAD, utilConstants.ACTIVITY_TYPE.VIDEO_UPLOAD].includes(activityType),
    //     // );
    //     // setSelectedResponses(updatedSelectedResponses);
    // }, [activity.activityResponses, isShowStarred, searchKeyword]);

    useEffect(() => {
        const searchNameOnlyActivities = [
            utilConstants.ACTIVITY_TYPE.SLIDE_DRAWING,
            utilConstants.ACTIVITY_TYPE.AUDIO_RECORD,
        ];
        if (workerRef.current)
            workerRef.current.postMessage({
                allResponses: activity.activityResponses,
                searchKeyword,
                isSearchNameOnly: searchNameOnlyActivities.includes(activityType),
                isNamesHidden,
                isShowStarred,
                searchCaption: [
                    utilConstants.ACTIVITY_TYPE.IMAGE_UPLOAD,
                    utilConstants.ACTIVITY_TYPE.VIDEO_UPLOAD,
                ].includes(activityType),
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activity.activityResponses, isShowStarred, searchKeyword]);

    const toggleShowActivityResponses = (isShow: boolean) => {
        dispatch(
            webviewSettingsActions.updateWebviewSettings(WebviewSettingsActionTypes.UPDATE_SHOW_RESPONSE, {
                ...{ isShowActivityResponses: isShow },
            }),
        );
    };

    const toggleActivityMusic = (isOn: boolean) => {
        dispatch(
            webviewSettingsActions.updateWebviewSettings(WebviewSettingsActionTypes.UPDATE_PLAY_ACTIVITY_MUSIC, {
                ...{ isActivityMusicOn: isOn },
            }),
        );
    };

    const adjustActivityMusicVolume = (volume: number) => {
        dispatch(
            webviewSettingsActions.updateWebviewSettings(WebviewSettingsActionTypes.ADJUST_ACTIVITY_MUSIC_VOLUME, {
                ...{ activityMusicVolume: volume },
            }),
        );
    };

    const suffleActivityMusic = (musicUrl: string) => {
        dispatch(
            webviewSettingsActions.updateWebviewSettings(WebviewSettingsActionTypes.UPDATE_ACTIVITY_MUSIC_URL, {
                ...{ activityMusicUrl: musicUrl },
            }),
        );
    };

    const toggleCorrectAnswer = (isShow: boolean) => {
        setIsShowCorrectAnswer(isShow);
        if (!activity.activityUser) classSessionActions.showCorrectAnswer(isShow);
    };

    const toggleHighlightTopAnswers = (isShow: boolean) => {
        setIsHighlightTopAnswers(isShow);
    };

    const toggleShowStarred = (isShow: boolean) => {
        setIsShowStarred(isShow);
    };

    const handleGivePointsToAll = (points: number) => {
        const responsesAndPoints: ResponseAndPointsDto[] = selectedResponses.map((response) => ({
            response,
            points: points,
        }));
        dispatch(activityActions.handleGivePointsToResponses(responsesAndPoints));
    };

    const handleRestartActivity = () => {
        if (!webviewSettingsSlideshow.isShowRestartActivityAlert) {
            confirmRestartActivity();
            return;
        } else {
            setAlertType(AlertTypes.RESTART_ACTIVITY);
        }
    };

    const confirmRestartActivity = () => {
        dispatch(activityActions.restartActivity());
        setAlertType(undefined);
    };

    const handleDeleteResponses = () => {
        if (!webviewSettingsEditMode.isShowDeleteResponsesAlert) {
            confirmDeleteResponses();
            return;
        }
        setAlertType(AlertTypes.DELETE_RESPONSES);
    };

    const confirmDeleteResponses = () => {
        dispatch(activityActions.deleteResponses(activity.activityProps?.activityId || ''));
        setAlertType(undefined);
    };

    const confirmInsertSlideShow = () => {
        const outputResponses = isNamesHidden
            ? selectedResponses.map((res) => ({
                  ...res,
                  participantName: generateAsterisksFromName(res.participantName),
              }))
            : selectedResponses;
        const lodashResponses = outputResponses.map((res: ActivityResponseInterface) =>
            _.pick(res, ['participantName', 'responseData']),
        );
        const payload = {
            activityType,
            title: t(`lang_activity.insert_slide_title_multiple`, { activityType: t(`lang_activity.${activityType}`) }),
            isInsertAsTable: true,
            headerColumns: [t('lang_myclass.txt_name'), t('lang_activity.insert_slide_response')],
            responses:
                activityType === utilConstants.ACTIVITY_TYPE.SHORT_ANSWER
                    ? lodashResponses.map((response: any) => ({
                          ...response,
                          responseData: processHtmlForInsertSlide(response.responseData),
                      }))
                    : lodashResponses,
        };
        // console.log(payload);
        webviewMessenger.sendInsertAsSlide(JSON.stringify(payload));
        setAlertType(undefined);
    };

    const confirmEndVote = () => {
        dispatch(activityActions.stopVoting());
        dispatch(activityActions.endActivity());
    };

    const getCustomBoxClassName = () => {
        if (alertType === AlertTypes.QUIZ_MODE_PRO) return 'quizModePro';
        else if (alertType === AlertTypes.SHARE_ACTIVITY) return 'shareActivityModal';
        else if (alertType === AlertTypes.ASK_CP_AI) return 'askCpAi';
        else return '';
    };

    return (
        <Fragment>
            <div className="black">
                <FitToViewport
                    className="viewport"
                    width={ViewportConstants.width}
                    height={ViewportConstants.height}
                    minZoom={ViewportConstants.minZoom}
                    maxZoom={ViewportConstants.maxZoom}
                >
                    {showEmptyModal && <EmptyModal viewportConfig={ViewportConstants} />}

                    {!activity.activityMode && <Loading />}

                    {activity.activityProps && activity.activityMode && (
                        <div className={`Classpoint_activity fullScreenPointContainer ${isGivePointsToAll && 'show'}`}>
                            <FullScreenPointLottie ref={givePointsConfettiRef} />
                            <div className="top_header">
                                <Header appBarTitle={t(`lang_activity.${activityType}`)}>
                                    <Fragment>
                                        {activity.activityProps && (
                                            <ActivityHeader
                                                activity={activity}
                                                selectedResponses={selectedResponses}
                                                openLiveStatModal={() => {
                                                    webviewMessenger.sendUsageLog(`[S] Opened Live status modal`);
                                                    setIsLiveStatusOpen(true);
                                                }}
                                                onToggleCorrectAnswer={toggleCorrectAnswer}
                                                onToggleShowStarred={toggleShowStarred}
                                                isShowCorrectAnswer={isShowCorrectAnswer}
                                                onToggleHighlightTopAnswers={toggleHighlightTopAnswers}
                                                isVoting={isVoting}
                                                isHighlightTopAnswers={isHighlightTopAnswers}
                                                isShowStarred={isShowStarred}
                                                set50ResponseAlert={() => {
                                                    setAlertType(AlertTypes.INSERT_SLIDES_MORE_THAN_50);
                                                }}
                                                setIsNoParticipantModalOpen={setIsNoParticipantModalOpen}
                                            />
                                        )}
                                    </Fragment>
                                </Header>
                            </div>

                            {activity.activityMode === utilConstants.ACTIVITY_MODE.START_ACTIVITY && (
                                <>
                                    <LiveStatus
                                        openLiveStatusModal={isLiveStatusOpen}
                                        handleClose={() => {
                                            webviewMessenger.sendUsageLog(`[S] Closed Live status modal`);
                                            setIsLiveStatusOpen(false);
                                        }}
                                    />
                                    <NoParticipantModal
                                        isQRModalOpen={isNoParticipantModalOpen}
                                        closeModal={() => {
                                            webviewMessenger.sendUsageLog(`[S] Closed join instruction modal`);
                                            setIsNoParticipantModalOpen(false);
                                        }}
                                    />
                                </>
                            )}
                            {activity.activityResponses.length > 0 &&
                            (activity.activityMode !== utilConstants.ACTIVITY_MODE.START_ACTIVITY ||
                                webviewSettingsSlideshow.isShowActivityResponses) ? (
                                <ActivityContent
                                    isShowCorrectAnswer={isShowCorrectAnswer}
                                    isHighlightTopAnswers={isHighlightTopAnswers}
                                    selectedResponses={selectedResponses}
                                    searchKeyword={searchKeyword}
                                    onSearch={setSearchKeyword}
                                    isVoting={isVoting}
                                    isVotingNamesHidden={isVotingNamesHidden}
                                    isGivePointsToAll={isGivePointsToAll}
                                />
                            ) : (
                                <Fragment>
                                    {activity.activityMode === utilConstants.ACTIVITY_MODE.START_ACTIVITY ? (
                                        <LoadingResponses
                                            hasParticipantsJoined={
                                                participantList && filterOnlineParticipants(participantList).length > 0
                                                    ? true
                                                    : false
                                            }
                                            numberOfResponses={
                                                activity.activityResponses
                                                    .map((response) => response.participantId)
                                                    .filter((element, index, array) => array.indexOf(element) === index)
                                                    .length
                                            }
                                            activityLoaderIndex={activityLoaderIndex}
                                            setIsNoParticipantModalOpen={setIsNoParticipantModalOpen}
                                        />
                                    ) : (
                                        <NoResponse />
                                    )}
                                </Fragment>
                            )}
                            {isVoting ? (
                                <VotingFooter onStopVoting={() => setIsVoting(false)} />
                            ) : (
                                <footer className="footer_sec participant_sec_foot">
                                    <ActivityFooter
                                        selectedResponsesCount={selectedResponses.length}
                                        onToggleShowActivityResponses={toggleShowActivityResponses}
                                        onToggleActivityMusic={toggleActivityMusic}
                                        onAdjustActivityMusicVolume={adjustActivityMusicVolume}
                                        onShuffleActivityMusic={suffleActivityMusic}
                                        closeSubmission={() => dispatch(activityActions.closeSubmission())}
                                        handleCloseWindow={() => dispatch(activityActions.endActivity())}
                                        onGivePointsToAll={handleGivePointsToAll}
                                        onRestartActivity={handleRestartActivity}
                                        onDeleteResponses={handleDeleteResponses}
                                        onToggleCorrectAnswer={toggleCorrectAnswer}
                                        openStartVoting={() => setIsShowStartVotingModal(true)}
                                        setIsGivePointsToAll={setIsGivePointsToAll}
                                        givePointsConfettiRef={givePointsConfettiRef}
                                        setAlertType={setAlertType}
                                    />
                                </footer>
                            )}
                            <CommonAlert
                                showAlert={alertType !== undefined}
                                content={alertComponent}
                                viewportConfig={ViewportConstants}
                                customBoxClassNames={getCustomBoxClassName()}
                                closeAlert={() => {
                                    if (
                                        alertType === AlertTypes.QUIZ_MODE_PRO ||
                                        alertType === AlertTypes.SHARE_ACTIVITY ||
                                        alertType === AlertTypes.ASK_CP_AI
                                    )
                                        setAlertType(undefined);
                                }}
                            />
                            <VotingModal
                                selectedResponses={selectedResponses}
                                showAlert={isShowStartVotingModal}
                                closeAlert={() => setIsShowStartVotingModal(false)}
                                onStartVoting={() => {
                                    setIsShowStartVotingModal(false);
                                    setIsVoting(true);
                                }}
                                isVotingNamesHidden={isVotingNamesHidden}
                                setIsVotingNamesHidden={setIsVotingNamesHidden}
                                votingNumber={votingNumber}
                                setVotingNumber={setVotingNumber}
                            />
                        </div>
                    )}
                </FitToViewport>
            </div>
        </Fragment>
    );
};

export default Activity;
