import { FormEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { t } from 'i18next';
import * as yup from 'yup';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { IconButton, InputAdornment, TextField } from '@mui/material';

import { userActions } from '../../../actions/user.actions';
import apiUser from '../../../services/apiUser';
import { ResetPasswordInterface, ForgotPasswordStepType } from '../../../interfaces/signInSignUp.interface';
import { INPUT_LIMITS, utilConstants } from '../../../constants/utils.constants';

import CommonButton from '../../../components/Common/CommonButton';

interface ForgotPasswordResetInterface {
    forgotPasswordDetails: ResetPasswordInterface;
    setForgotPasswordDetails: any;
    setForgotPasswordStep: (arg: ForgotPasswordStepType) => void;
}

export const ForgotPasswordReset = ({
    forgotPasswordDetails,
    setForgotPasswordDetails,
    setForgotPasswordStep,
}: ForgotPasswordResetInterface) => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordGuideline, setPasswordGuideline] = useState({
        passwordHasLetter: false,
        passwordHasNumber: false,
        passwordCharLength: false,
    });
    const [showPassword, setShowPassword] = useState({
        password: false,
        confirmPassword: false,
    });
    const [passwordError, setPasswordError] = useState({
        passwordError: '',
        confirmPasswordError: '',
    });

    const passwordValidation = yup.object({
        password: yup
            .string()
            .required('passwordError:error_password_required')
            .matches(utilConstants.REGEX.PASSWORD, 'passwordError:error_password_pattern')
            .min(INPUT_LIMITS.PASSWORD_MIN, 'passwordError:error_password_length')
            .max(INPUT_LIMITS.PASSWORD_MAX, 'passwordError:error_password_length'),
    });

    const onChangeHandler = (fieldName: string, value: string) => {
        const letterRegex = /^.*[a-zA-Z]+.*$/;
        const numberRegex = /^.*[0-9]+.*$/;

        if (fieldName === 'password') {
            setForgotPasswordDetails((prevState: ResetPasswordInterface) => ({
                ...prevState,
                [fieldName]: value,
            }));
            if (value.match(letterRegex))
                setPasswordGuideline((prevState) => ({ ...prevState, passwordHasLetter: true }));
            else setPasswordGuideline((prevState) => ({ ...prevState, passwordHasLetter: false }));

            if (value.match(numberRegex))
                setPasswordGuideline((prevState) => ({ ...prevState, passwordHasNumber: true }));
            else setPasswordGuideline((prevState) => ({ ...prevState, passwordHasNumber: false }));

            if (value.length >= INPUT_LIMITS.PASSWORD_MIN && value.length <= INPUT_LIMITS.PASSWORD_MAX)
                setPasswordGuideline((prevState) => ({ ...prevState, passwordCharLength: true }));
            else setPasswordGuideline((prevState) => ({ ...prevState, passwordCharLength: false }));
        } else setConfirmPassword(value);
        setPasswordError((prevState) => ({ ...prevState, [`${fieldName}Error`]: '' }));
    };

    const submitFormHandler = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (forgotPasswordDetails.password === confirmPassword) {
            const isValidPassword = await passwordValidation.isValid({ password: forgotPasswordDetails.password });
            if (isValidPassword) {
                setIsLoading(true);
                const isPasswordReset = await apiUser.resetPassword(forgotPasswordDetails);
                setIsLoading(false);

                if (!isPasswordReset) return dispatch(userActions.showApiError());
                setForgotPasswordStep(ForgotPasswordStepType.Success);
            } else {
                passwordValidation.validate({ password: forgotPasswordDetails.password }).catch((data) => {
                    const valuePair = data.errors[0].split(':');
                    setPasswordError((prevState) => ({ ...prevState, [valuePair[0]]: `lang_auth.${valuePair[1]}` }));
                });
            }
        } else
            setPasswordError((prevState) => ({
                ...prevState,
                confirmPasswordError: `lang_auth.error_passwordMismatch`,
            }));
    };

    return (
        <>
            <div className="intro">
                <h4>{t(`lang_auth.heading_btn_reset_password`)}</h4>
            </div>

            <form className="form" onSubmit={submitFormHandler}>
                <div className="profile_left">
                    <div className="pf_form passwordField">
                        <TextField
                            onChange={(e) => onChangeHandler('password', e.target.value.trim())}
                            value={forgotPasswordDetails.password}
                            error={passwordError.passwordError ? true : false}
                            helperText={t(`${passwordError.passwordError}`)}
                            inputProps={{ maxLength: INPUT_LIMITS.PASSWORD_MAX }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            tabIndex={-1}
                                            onClick={() =>
                                                setShowPassword((prevState) => ({
                                                    ...prevState,
                                                    password: !prevState.password,
                                                }))
                                            }
                                        >
                                            {showPassword.password ? (
                                                <VisibilityOutlinedIcon />
                                            ) : (
                                                <VisibilityOffOutlinedIcon />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            type={showPassword.password ? 'text' : 'password'}
                            label={t(`lang_auth.input_password`)}
                            autoComplete="none"
                            variant="standard"
                        />
                    </div>
                    <div className={`passwordGuideline`}>
                        <p className={`title`}>{t(`lang_auth.input_password_guideline_title`)}</p>
                        <p className={`letter ${passwordGuideline.passwordHasLetter && 'active'}`}>
                            • <span>{t(`lang_auth.hint_a_letter`)}</span>
                        </p>
                        <p className={`number ${passwordGuideline.passwordHasNumber && 'active'}`}>
                            • <span>{t(`lang_auth.hint_a_number`)}</span>
                        </p>
                        <p className={`minimumChar ${passwordGuideline.passwordCharLength && 'active'}`}>
                            • <span>{t(`lang_auth.six_to_fifteen_chars`)}</span>
                        </p>
                    </div>
                    <div className="pf_form">
                        <TextField
                            onChange={(e) => onChangeHandler('confirmPassword', e.target.value.trim())}
                            value={confirmPassword}
                            error={passwordError.confirmPasswordError ? true : false}
                            helperText={t(`${passwordError.confirmPasswordError}`)}
                            inputProps={{ maxLength: INPUT_LIMITS.PASSWORD_MAX }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            tabIndex={-1}
                                            onClick={() =>
                                                setShowPassword((prevState) => ({
                                                    ...prevState,
                                                    confirmPassword: !prevState.confirmPassword,
                                                }))
                                            }
                                        >
                                            {showPassword.confirmPassword ? (
                                                <VisibilityOutlinedIcon />
                                            ) : (
                                                <VisibilityOffOutlinedIcon />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            type={showPassword.confirmPassword ? 'text' : 'password'}
                            label={t(`lang_auth.input_confirmPassword`)}
                            autoComplete="none"
                            variant="standard"
                        />
                    </div>

                    <div className="profile_update_btn">
                        {/* <CommonButton
                            variant="contained"
                            className="backBtn"
                            type="button"
                            color="primary"
                            text={<ArrowBackIcon />}
                            clickFunction={() => setForgotPasswordStep(ForgotPasswordStepType.Email)}
                        /> */}
                        <CommonButton
                            isLoading={isLoading}
                            variant="contained"
                            type="submit"
                            color="primary"
                            text={t(`lang_auth.heading_btn_reset_password`)}
                        />
                    </div>
                </div>
            </form>
        </>
    );
};
