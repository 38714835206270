import { Fragment } from 'react';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import StoreInterface from '../../../interfaces/store.interface';
import CommonButton from '../../../components/Common/CommonButton';
import webviewMessenger from '../../../services/webviewMessenger';

const QrCodeGenerated = ({ activePageHandler }: any) => {
    const state = useSelector((state: StoreInterface) => state);
    const exportPdf = state.exportPdf;
    const { t } = useTranslation();
    return (
        <Fragment>
            <div className="qrcodeGenerated">
                <div className="qrcodebbox">
                    <QRCode
                        size={244}
                        value={exportPdf.pdfUrl ? exportPdf.pdfUrl : ''}
                        imageSettings={{
                            src: 'assets/pdf-icon.png',
                            x: undefined,
                            y: undefined,
                            height: 45,
                            width: 45,
                            excavate: true,
                        }}
                    />
                </div>
                {/* <Typography variant="body1">
                    <Link>{exportPdf.pdfUrl}</Link>
                </Typography> */}
                <div className="profile_update_btn">
                    <CommonButton
                        clickFunction={() => webviewMessenger.sendOpenBrowser(exportPdf.pdfUrl)}
                        isLoading={false}
                        variant="contained"
                        color="primary"
                        text={t('lang_share_pdf.btn_open_pdf')}
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default QrCodeGenerated;
