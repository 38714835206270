export enum ActivityActionTypes {
    START_ACTIVITY = 'START_ACTIVITY',
    EXTEND_COUNTDOWN = 'EXTEND_COUNTDOWN',
    CLOSE_SUBMISSION = 'CLOSE_SUBMISSION',
    END_ACTIVITY = 'END_ACTIVITY',
    RESPONSES_RECEIVED = 'RESPONSES_RECEIVED',
    SYNC_RESPONSES_ON_REJOIN = 'SYNC_RESPONSES_ON_REJOIN',
    RESTART_ACTIVITY = 'RESTART_ACTIVITY',
    DELETE_ACTIVITY = 'DELETE_ACTIVITY',
    LOAD_ACTIVITY = 'LOAD_ACTIVITY',
    DELETE_INDIVIDUAL_RESPONSES = 'DELETE_INDIVIDUAL_RESPONSES',
    START_VOTING = 'START_VOTING',
    STOP_VOTING = 'STOP_VOTING',
    RESPONSE_VOTED = 'RESPONSE_VOTED',
    TOGGLE_END_ACTIVITY_WARNING_MODAL = 'TOGGLE_END_ACTIVITY_WARNING_MODAL',
    TOGGLE_END_VOTING_WARNING_MODAL = 'TOGGLE_END_VOTING_WARNING_MODAL',
    UPDATE_REMIND_ME = 'UPDATE_REMIND_ME',
    GIVE_POINTS_TO_RESPONSE = 'GIVE_POINTS_TO_RESPONSE',
    GIVE_POINTS_TO_RESPONSES = 'GIVE_POINTS_TO_RESPONSES',
    RESPONSE_GOT_POINTS = 'RESPONSE_GOT_POINTS',
    RESPONSES_GOT_POINTS = 'RESPONSES_GOT_POINTS',
    CLEAR_ACTIVITY = 'CLEAR_ACTIVITY',
    STOP_AUDIO_MUSIC = 'STOP_AUDIO_MUSIC',
}
