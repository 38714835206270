import axios from 'axios';

import { logger } from './logger';

export const microsoftSsoApi = {
    getPersonalDetails: async (options: any): Promise<any | null> => {
        try {
            const personalDetailsResponse = await axios.get('https://graph.microsoft.com/v1.0/me', options);
            return personalDetailsResponse.data;
        } catch (error: any) {
            logger.error('microsoftSsoApi.getPersonalDetails() error: ', error.response || error);
            return null;
        }
    },

    getOrganizationName: async (options: any): Promise<string> => {
        try {
            const organizationResponse = await axios.get('https://graph.microsoft.com/v1.0/organization', options);
            return organizationResponse.data?.value[0]?.displayName;
        } catch (error: any) {
            logger.error('microsoftSsoApi.getOrganizationDetails() error: ', error.response || error);
            return '';
        }
    },

    getImageDetails: async (options: any): Promise<any | null> => {
        return await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', options)
            .then((response) => {
                if (response.ok) return response;
                else return null;
            })
            .catch((error) => {
                logger.error('microsoftSsoApi.getImageDetails() error: ', error.response || error);
                return null;
            });
    },
};
