import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import Lottie from 'react-lottie-player';

import { userActions } from '../../../actions/user.actions';
import { generateYupErrors, profileValidation } from '../../../helpers/authHelpers';
import { mapUserToSyncWithHost } from '../../../helpers/userhelper';
import { isCpVerAtLeast } from '../../../helpers/utils';
import apiUser from '../../../services/apiUser';
import { GtmService } from '../../../services/googleTagManager.service';
import apiPreferences from '../../../services/apiPreferences';
import { localService } from '../../../services/localStorageService';
import webviewMessenger from '../../../services/webviewMessenger';

import { CurrentPageType, SignUpStatus, SignUpUserInterface } from '../../../interfaces/signInSignUp.interface';
import { SsoSignUpInterface } from '../../../interfaces/sso-signup.interface';
import UserInterface from '../../../interfaces/user-interface';
import { INPUT_LIMITS, utilConstants } from '../../../constants/utils.constants';
import { UserActionTypes } from '../../../constants/user-action-types';
import { generateInterestsProfile } from '../../../constants/signup-interests';
import { HostEventTypes } from '../../../constants/host-event-types';
import { authPagesConstants } from '../../../constants/viewport-constants';

import CommonButton from '../../../components/Common/CommonButton';
import DropdownInput from './DropdownInput';
import DropdownAutoComplete from './DropdownAutoComplete';
import AccountAlreadyExistsAlert from './AccountAlreadyExistsAlert';
import CommonAlert from '../../../components/Common/CommonAlert';

import trialFlowActivating from '../../../assets/animation/trialFlowActivating.json';
import trialFlowCheckSuccess from '../../../assets/animation/8193-unlocked2.json';
import SignupOrganization from '../../../interfaces/signup-organization.interface';

interface SignUpProfileInfoInterface {
    userDetails: SignUpUserInterface;
    setUserDetails: any;
    // setSignUpStep: (arg: SignUpStepType) => void;
    setCurrentPage: (arg: CurrentPageType) => void;
    showMobileInputField: boolean;
    // showJobRole: boolean;
    ssoData: SsoSignUpInterface | undefined;
    orgList: SignupOrganization[];
}

export const SignUpProfileInfo = ({
    userDetails,
    setUserDetails,
    // setSignUpStep,
    setCurrentPage,
    showMobileInputField,
    // showJobRole,
    ssoData,
    orgList,
}: SignUpProfileInfoInterface) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentLanguage = localService.getLanguage();

    const [isLoading, setIsLoading] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [status, setStatus] = useState(SignUpStatus.BEFORE_SUBMIT);
    const [showProfileAlreadyExistsModal, setShowProfileAlreadyExistsModal] = useState(false);
    const [userDetailsError, setUserDetailsError] = useState({
        firstNameError: '',
        lastNameError: '',
        emailError: '',
        passwordError: '',
        leadSourceError: '',
        jobRoleError: '',
        phoneError: '',
        organizationError: '',
    });

    const onChangeHandler = (fieldName: string, value: string) => {
        const alphaNumericRegex = /^[a-zA-Z0-9_ ]*$/;
        const mobileNumberRegex = /^[0-9+]*$/;
        // const organizationRegex = /^[a-zA-Z0-9_\-()' ]*$/;

        if (fieldName === 'firstName' || fieldName === 'lastName') {
            if (value.match(alphaNumericRegex)) {
                setUserDetails((prevState: SignUpUserInterface) => ({
                    ...prevState,
                    [fieldName]: value.trimStart(),
                }));
            }
        } else if (fieldName === 'organization') {
            // if (value.match(organizationRegex)) {
            setUserDetails((prevState: SignUpUserInterface) => ({
                ...prevState,
                [fieldName]: value.trimStart(),
            }));
            // }
        } else if (fieldName === 'phone') {
            // } else if (fieldName === 'phone' || fieldName === 'phoneCode') {
            if (value.match(mobileNumberRegex)) {
                setUserDetails((prevState: SignUpUserInterface) => ({
                    ...prevState,
                    [fieldName]: value.trim(),
                }));
            }
        } else {
            setUserDetails((prevState: SignUpUserInterface) => ({
                ...prevState,
                [fieldName]: value.trim(),
            }));
        }

        setUserDetailsError((prevState) => ({ ...prevState, [`${fieldName}Error`]: '' }));
    };

    const submitFormHandler = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);

        const errors: { [key: string]: string } = {};

        // let finalUserDetails: any = {};
        // const objectKeys = userDetails.phone.trim()
        //     ? Object.keys(userDetails)
        //     : Object.keys(userDetails).filter((item) => item !== 'phone' && item !== 'phoneCode');

        // for (let i = 0; i < objectKeys.length; i++) {
        //     if (objectKeys[i] === 'phone' || objectKeys[i] === 'phoneCode') {
        //         finalUserDetails = { ...finalUserDetails, phone: userDetails.phoneCode + userDetails.phone };
        //     } else {
        //         if (userDetails[objectKeys[i]]) {
        //             finalUserDetails = { ...finalUserDetails, [objectKeys[i]]: userDetails[objectKeys[i]] };
        //         }
        //     }
        // }

        const isValidProfile = await profileValidation.isValid(userDetails);
        let hasError = false;

        // if ((showJobRole && !finalUserDetails.jobRole) || (!showJobRole && !finalUserDetails.leadSource)) {
        if (!userDetails.leadSource) {
            hasError = true;
            errors.leadSourceError = 'lang_auth.error_dropdown_required';

            // setUserDetailsError((prevState) => ({
            //     ...prevState,
            //     //jobRoleError: finalUserDetails.jobRole ? '' : 'lang_auth.error_dropdown_required',
            //     leadSourceError: finalUserDetails.leadSource ? '' : 'lang_auth.error_dropdown_required',
            // }));
        }

        if (
            userDetails.phone.trim() &&
            (userDetails.phone.length < INPUT_LIMITS.MOBILE_MIN || userDetails.phone.length > INPUT_LIMITS.MOBILE_MAX)
        ) {
            hasError = true;
            errors.phoneError = 'lang_auth.error_phone';

            // setUserDetailsError((prevState) => ({
            //     ...prevState,
            //     phoneError: 'lang_auth.error_phone',
            // }));
        }

        if (isValidProfile && !hasError) {
            // const userCreated = await apiUser.createUser(finalUserDetails);
            // setIsLoading(false);
            // if (userCreated === null) return dispatch(userActions.showApiError());
            // if (!userCreated)
            //     return setUserDetailsError((prevState) => ({
            //         ...prevState,
            //         emailError: 'lang_auth.error_api_sign_up_409',
            //     }));
            // setSignUpStep(SignUpStepType.Interest);

            const signUpCheckPassed = await apiUser.signUpCheckEmail(userDetails.email);
            setIsLoading(false);
            if (signUpCheckPassed === null) return dispatch(userActions.showApiError());

            // if (!signUpCheckPassed) {
            //     return setUserDetailsError((prevState) => ({
            //         ...prevState,
            //         emailError: 'lang_auth.error_api_sign_up_409',
            //     }));
            // }
            // setSignUpStep(SignUpStepType.Interest);

            if (!signUpCheckPassed) return setShowProfileAlreadyExistsModal(true);
            signUpHandler();
        } else {
            const yupError = await profileValidation.validate(userDetails, { abortEarly: false }).catch((data) => {
                return generateYupErrors(data.errors);
            });
            setUserDetailsError((prevState) => ({ ...prevState, ...yupError, ...errors }));
        }

        setIsLoading(false);
    };

    const signUpHandler = async () => {
        const dto: any = {
            firstName: userDetails.firstName.trim(),
            lastName: userDetails.lastName.trim(),
            email: userDetails.email.trim(),
            organization: userDetails.organization.trim(),
        };
        if (userDetails.ssoType) dto.ssoType = userDetails.ssoType;
        else dto.password = userDetails.password;
        if (ssoData?.avatarUrl) dto.avatarUrl = ssoData.avatarUrl;

        if (userDetails.leadSource) dto.leadSource = userDetails.leadSource;
        if (userDetails.jobRole) dto.jobRole = userDetails.jobRole;
        if (userDetails.phone) dto.phone = `+${userDetails.phoneCode}${userDetails.phone}`;
        if (userDetails.interests.length > 0) {
            dto.interests = userDetails.interests.join(',');
            dto.interestsProfile = generateInterestsProfile(userDetails.interests);
        }

        setStatus(SignUpStatus.SIGNING_UP);
        const newUserId = await apiUser.createUser(dto);
        if (!newUserId) {
            setStatus(SignUpStatus.BEFORE_SUBMIT);
            return dispatch(userActions.showApiError());
        }

        setTimeout(() => {
            setStatus(SignUpStatus.SIGNED_UP);
            GtmService.sendSignUpEvent(newUserId, dto.ssoType || 'email');
            (window as any).fbq('trackCustom', 'SignUp');
        }, 1500);
        setTimeout(() => signInHandler(), 3000);
    };

    const signInHandler = async () => {
        const statsFromHost = localService.getStatsFromHost();
        const password = userDetails.password;
        let repliedUser: UserInterface | number | null;
        if (ssoData) repliedUser = await apiUser.signInWithSso(ssoData, { ...statsFromHost });
        else {
            repliedUser = await apiUser.signInWithPassword(
                userDetails.email,
                { password, ...statsFromHost },
                'signUpCompleted',
            );
        }

        if (!repliedUser || typeof repliedUser === 'number') return dispatch(userActions.showApiError());
        dispatch({
            type: UserActionTypes.SIGN_IN_AFTER_SIGN_UP_SUCCESS,
            payload: repliedUser,
        });

        if (!repliedUser.userPreferences?.language) apiPreferences.saveLanguage(repliedUser.email, currentLanguage);
        else dispatch(userActions.updateUser(UserActionTypes.LOAD_USER_LANGUAGE_PREFERENCE, repliedUser));
        webviewMessenger.sendUserLoaded(HostEventTypes.MANUAL_SIGN_IN_SUCCESS, mapUserToSyncWithHost(repliedUser));
        GtmService.sendFirstLoginEvent(repliedUser.userId, ssoData?.provider || 'email');
        (window as any).fbq('trackCustom', 'FirstSignIn');
        if (isCpVerAtLeast('2.0.37')) {
            navigate('/welcome');
            // webviewMessenger.sendSimpleMessage('showNewWelcome');
        } else {
            navigate('/hello');
            webviewMessenger.sendSimpleMessage('resizeWebviewWindow');
        }
        webviewMessenger.sendUpdatePassword(password);
    };

    const dropDownClickHandler = (value?: boolean) => setIsDropdownOpen(value || !isDropdownOpen);

    const determineLottieContent = () => {
        if (status === SignUpStatus.SIGNING_UP) {
            return { text: t('lang_auth.txt_signing_you_up'), lottie: trialFlowActivating };
        } else {
            return { text: t('lang_auth.txt_sign_up_complete'), lottie: trialFlowCheckSuccess };
        }
    };

    return status === SignUpStatus.BEFORE_SUBMIT ? (
        <>
            <CommonAlert
                showAlert={showProfileAlreadyExistsModal}
                closeAlert={() => {
                    setShowProfileAlreadyExistsModal(false);
                }}
                content={
                    <AccountAlreadyExistsAlert
                        onSignIn={() => setCurrentPage(CurrentPageType.SignIn)}
                        onBack={() => setShowProfileAlreadyExistsModal(false)}
                    />
                }
                viewportConfig={authPagesConstants}
                customModalClassNames="small"
            />

            <div className="intro compact">
                <h4>{t(`lang_auth.txt_signUp_title`)}</h4>
            </div>

            <form className="form" id="form" onSubmit={submitFormHandler}>
                <div className="profile_left signUpDetails">
                    <div className={`signUpName`}>
                        <div className={`pf_form compact`}>
                            <TextField
                                onChange={(e) => onChangeHandler('firstName', e.target.value)}
                                value={userDetails.firstName}
                                error={userDetailsError.firstNameError ? true : false}
                                helperText={t(`${userDetailsError.firstNameError}`)}
                                inputProps={{ maxLength: INPUT_LIMITS.FIRST_LAST_NAME_MAX }}
                                label={t(`lang_auth.input_firstName`)}
                                autoComplete="none"
                                variant="standard"
                            />
                        </div>
                        <div className={`pf_form compact`}>
                            <TextField
                                onChange={(e) => onChangeHandler('lastName', e.target.value)}
                                value={userDetails.lastName}
                                error={userDetailsError.lastNameError ? true : false}
                                helperText={t(`${userDetailsError.lastNameError}`)}
                                inputProps={{ maxLength: INPUT_LIMITS.FIRST_LAST_NAME_MAX }}
                                label={t(`lang_auth.input_lastName`)}
                                autoComplete="none"
                                variant="standard"
                            />
                        </div>
                    </div>

                    {!ssoData && (
                        <>
                            <div className={`pf_form compact`}>
                                <TextField
                                    onChange={(e) => onChangeHandler('email', e.target.value)}
                                    value={userDetails.email}
                                    error={userDetailsError.emailError ? true : false}
                                    helperText={t(`${userDetailsError.emailError}`)}
                                    inputProps={{ maxLength: INPUT_LIMITS.EMAIL_MAX }}
                                    label={t(`lang_auth.input_email`)}
                                    variant="standard"
                                />
                            </div>
                            <div className={`pf_form compact`}>
                                <TextField
                                    onChange={(e) => onChangeHandler('password', e.target.value)}
                                    value={userDetails.password}
                                    error={userDetailsError.passwordError ? true : false}
                                    helperText={t(`${userDetailsError.passwordError}`)}
                                    inputProps={{ maxLength: INPUT_LIMITS.PASSWORD_MAX }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    tabIndex={-1}
                                                    onClick={() => setShowPassword((prevState) => !prevState)}
                                                >
                                                    {showPassword ? (
                                                        <img
                                                            src={utilConstants.IMAGE_URLS.PASSWORD_VISIBLE}
                                                            alt="ClassPoint"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={utilConstants.IMAGE_URLS.PASSWORD_HIDE}
                                                            alt="ClassPoint"
                                                        />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    type={showPassword ? 'text' : 'password'}
                                    label={t(`lang_auth.input_password`)}
                                    autoComplete="none"
                                    variant="standard"
                                />
                            </div>
                        </>
                    )}

                    {orgList.length > 0 ? (
                        <div className="orgList compact">
                            <DropdownAutoComplete
                                orgList={orgList}
                                organization={userDetails.organization}
                                organizationError={userDetailsError.organizationError}
                                onChangeHandler={onChangeHandler}
                                dropDownClickHandler={dropDownClickHandler}
                            />
                        </div>
                    ) : (
                        <div className={`pf_form compact`}>
                            <TextField
                                onChange={(e) => onChangeHandler('organization', e.target.value)}
                                value={userDetails.organization}
                                error={userDetailsError.organizationError ? true : false}
                                helperText={t(`${userDetailsError.organizationError}`)}
                                inputProps={{ maxLength: INPUT_LIMITS.ORGANIZATION_MAX }}
                                label={t(`lang_auth.txt_schoolOrganization`)}
                                onKeyDown={(event) => event.key === 'Tab' && dropDownClickHandler()}
                                autoComplete="none"
                                variant="standard"
                            />
                        </div>
                    )}

                    <div className="job_List_profile compact">
                        {/* <DropdownInput
                            type="jobRole"
                            onValueChanged={onChangeHandler}
                            inputError={userDetailsError.jobRoleError}
                            dropDownClickHandler={dropDownClickHandler}
                        /> */}

                        <DropdownInput
                            type="leadSource"
                            onValueChanged={onChangeHandler}
                            inputError={userDetailsError.leadSourceError}
                            isDropdownOpen={isDropdownOpen}
                            dropDownClickHandler={dropDownClickHandler}
                        />
                    </div>

                    {showMobileInputField && (
                        <div className={`pf_form compact inputMobile`}>
                            <TextField
                                autoComplete="off"
                                // onChange={(e) => onChangeHandler('phoneCode', e.target.value.slice(1))}
                                value={`+${userDetails.phoneCode}`}
                                // error={userDetailsError.phoneError ? true : false}
                                // helperText={userDetailsError.phoneError && ' '}
                                // inputProps={{ maxLength: INPUT_LIMITS.MOBILE_COUNTRY_CODE_MAX }}
                                InputProps={{ readOnly: true }}
                                label=" "
                                variant="standard"
                                className={`mobileCountryCode`}
                            />
                            <TextField
                                autoComplete="off"
                                onChange={(e) => onChangeHandler('phone', e.target.value)}
                                value={userDetails.phone}
                                error={userDetailsError.phoneError ? true : false}
                                helperText={t(`${userDetailsError.phoneError}`)}
                                inputProps={{ maxLength: INPUT_LIMITS.MOBILE_MAX }}
                                label={t(`lang_auth.input_phone_wa`)}
                                variant="standard"
                                className={`mobileNumber`}
                            />
                        </div>
                    )}

                    <div className="profile_update_btn compact">
                        <CommonButton
                            isLoading={isLoading}
                            variant="contained"
                            type="submit"
                            color="primary"
                            text={t(`lang_auth.btn_signUp`)}
                        />
                    </div>
                </div>
            </form>

            <div className="signIn_link compact">
                <p>
                    {t(`lang_auth.txt_already_have_Account`)}{' '}
                    <span onClick={() => setCurrentPage(CurrentPageType.SignIn)}>{t(`lang_auth.txt_login_now`)}</span>
                </p>
            </div>
        </>
    ) : (
        <div className={`signUpAnimationContainer`}>
            <div className={`signUpLottieContainer`}>
                <Lottie
                    animationData={determineLottieContent().lottie}
                    play
                    loop={status === SignUpStatus.SIGNING_UP ? true : false}
                    speed={status === SignUpStatus.SIGNING_UP ? 1 : 2}
                    goTo={80}
                />
            </div>
            <div className="textContainer">
                <p className="text">{determineLottieContent().text}</p>
            </div>
        </div>
    );
};
