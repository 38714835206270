import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Box from '@mui/material/Box';

import WatchTutorialButton from './WatchTutorialButton';
import VideoModal from '../../VideoTutorial/Components/VideoModal';
import webviewMessenger from '../../../services/webviewMessenger';
import { ViewportSavedClasses } from '../../../constants/viewport-constants';

const AddSavedClassStepProgress = ({ currentStep }: { currentStep: number }) => {
    const { t } = useTranslation();
    let steps = [t('lang_saved_classes.set_up_class'), t('lang_saved_classes.add_participants')];

    const [tutorialVideoId, setTutorialVideoId] = useState('');

    return (
        <Fragment>
            {tutorialVideoId && (
                <VideoModal
                    tutorialVideoId={tutorialVideoId}
                    setTutorialVideoId={setTutorialVideoId}
                    viewportConstant={ViewportSavedClasses}
                />
            )}

            <div className="steps_sec">
                <Box className="stepsContent" sx={{ width: '100%' }}>
                    <Stepper activeStep={currentStep - 1}>
                        {steps.map((label, index) => {
                            const stepProps =
                                index < currentStep - 1
                                    ? {
                                          completed: true,
                                      }
                                    : {};
                            const labelProps: {
                                optional?: React.ReactNode;
                            } = {};

                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <WatchTutorialButton
                        onClickHandler={() => {
                            webviewMessenger.sendUsageLog(`Clicked "Watch tutorial" (create_class)`);
                            setTutorialVideoId('create_class');
                        }}
                    />
                </Box>
            </div>
        </Fragment>
    );
};

export default AddSavedClassStepProgress;
