export const BasicPlanPoints = [
    'txt_class_size',
    'txt_max_saved_class',
    'txt_max_questions_type',
    'txt_basic_gamification',
];

export const ProPlanPoints = [
    'txt_class_size_limit',
    'txt_unlimited_saved_class',
    'txt_unlimited_draggable_objects',
    'txt_all_questions_type',
    'txt_advance_gamification',
];

export const PremiumPlanPoints = [
    'premium_class_size',
    'premium_ai_credits',
    'premium_grouping',
    'premium_qna',
    'premium_branding',
    'premium_report_webhook',
];
