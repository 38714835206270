import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import './AutoCreateGroup.scss';

import apiSavedClasses from '../../../../services/apiSavedClasses';
import { userActions } from '../../../../actions/user.actions';

import SavedClassParticipantInterface from '../../../../interfaces/saved-class-participant.interface';
import { IAutoGroupData } from '../../../../interfaces/savedClassGroup.interface';
import { utilConstants } from '../../../../constants/utils.constants';

import CommonButton from '../../../../components/Common/CommonButton';
import AutoPickCounter from '../../../PickNames/modals/AutoPickCounter';

interface IAutoCreateGroup {
    savedClassId: string;
    sortedParticipants: SavedClassParticipantInterface[];
    refreshSavedClass: (actionType?: string, groupId?: number) => void;
    closeAutoGroupModalHandler: () => void;
}

export default function AutoCreateGroup({
    savedClassId,
    sortedParticipants,
    refreshSavedClass,
    closeAutoGroupModalHandler,
}: IAutoCreateGroup) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const maxNumberOfGroups = utilConstants.GROUP_COLORS.length;
    const minGroupSize = Math.ceil(sortedParticipants.length / maxNumberOfGroups);
    const [memberPerGroup, setMemberPerGroup] = useState(sortedParticipants.length < 4 ? 1 : Math.max(minGroupSize, 2));
    const [isGroupCreateLoading, setIsGroupCreateLoading] = useState(false);
    const [autoGroupMessage, setAutoGroupMessage] = useState('');

    const autoCreateGroupHandler = async () => {
        setIsGroupCreateLoading(true);

        const isAllEqualGroup = sortedParticipants.length % memberPerGroup === 0;
        let equalGroupCount = Math.floor(sortedParticipants.length / memberPerGroup);

        const autoGroupData: IAutoGroupData[] = [];
        let participants = [...sortedParticipants];
        let groupDetailsObject = [...utilConstants.GROUP_COLORS];

        while (equalGroupCount > 0) {
            const randomGroup = groupDetailsObject[Math.floor(Math.random() * groupDetailsObject.length)];
            const selectedParticipants: SavedClassParticipantInterface[] = [];

            for (let i = 0; i < memberPerGroup; i++) {
                const randomParticipant = participants[Math.floor(Math.random() * participants.length)];
                selectedParticipants.push(randomParticipant);
                participants = participants.filter(
                    (participant) => participant.participantId !== randomParticipant.participantId,
                );
            }

            autoGroupData.push({
                groupName: t(`lang_grouping.group_color_${randomGroup.name}`),
                groupColor: randomGroup.color,
                participantIds: selectedParticipants.map((participant) => participant.participantId),
            });
            --equalGroupCount;
            groupDetailsObject = groupDetailsObject.filter((group) => group.name !== randomGroup.name);
        }

        if (!isAllEqualGroup) {
            autoGroupData.push({
                groupName: t(`lang_grouping.group_color_${groupDetailsObject[0].name}`),
                groupColor: groupDetailsObject[0].color,
                participantIds: participants.map((participant) => participant.participantId),
            });
        }

        const success = await apiSavedClasses.autoGroup(savedClassId, autoGroupData);
        if (!success) dispatch(userActions.showApiError());

        refreshSavedClass();
        setIsGroupCreateLoading(false);
        closeAutoGroupModalHandler();
    };

    useEffect(() => {
        const totalParticipantLength = sortedParticipants.length;
        const lastGroupCount = totalParticipantLength % memberPerGroup;
        if (lastGroupCount !== 0) {
            setAutoGroupMessage(
                lastGroupCount > 1
                    ? t('lang_grouping.group_auto_create_uneven_message_plural', { lastGroupCount: lastGroupCount })
                    : t('lang_grouping.group_auto_create_uneven_message_singular', { lastGroupCount: lastGroupCount }),
            );
        } else setAutoGroupMessage('');
    }, [memberPerGroup]);

    return (
        <Modal open={true} onClose={() => closeAutoGroupModalHandler()} className="addOrEditGroupModal">
            <div className={`autoCreateGroup`}>
                <div className="auto_pick_modal">
                    <div className="modal_head">
                        <Typography variant="h4">{t('lang_grouping.how_many_members')}</Typography>
                    </div>

                    <div className={`bodyContent`}>
                        <div className="auto_pick_modal_body">
                            <AutoPickCounter
                                count={memberPerGroup}
                                canIncrease={memberPerGroup < sortedParticipants.length}
                                canDecrease={memberPerGroup > minGroupSize}
                                onIncrease={() => setMemberPerGroup((prev) => prev + 1)}
                                onDecrease={() => setMemberPerGroup((prev) => prev - 1)}
                            />
                        </div>

                        <p className={`autoGroupMessage`}>{autoGroupMessage}</p>

                        <div className="auto_pick_modal_foot">
                            <CommonButton
                                text={t('lang_common.btn_save')}
                                clickFunction={() => autoCreateGroupHandler()}
                                isLoading={isGroupCreateLoading}
                                className="auto_pick_foot_btn"
                                variant="contained"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
