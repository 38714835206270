import { Button } from '@mui/material';
import welcomeAnimation from '../../assets/animation/redGift.json';
import Lottie from 'react-lottie-player';
import { useTranslation } from 'react-i18next';
import { getValidUserFromStore } from '../../helpers/storeHelpers';
import { getNewUserDiscount } from '../../helpers/userhelper';
import webviewMessenger from '../../services/webviewMessenger';

const NewDiscountGiftBox = () => {
    const { t } = useTranslation();
    const user = getValidUserFromStore('NewDiscountBtn');
    const discount = getNewUserDiscount(user);

    if (!discount) return null;

    return (
        <Button
            className="activited_pro_btn"
            onClick={() => {
                webviewMessenger.sendOpenBrowser(`${process.env.REACT_APP_WEBAPP}/cp/account`);
            }}
        >
            <Lottie loop={true} animationData={welcomeAnimation} className="redBox" play speed={0.8} />
            {discount.daysLeft > 1
                ? t('lang_new_discount.txt_giftbox_days', {
                      percentage: discount.discountPercentage,
                      days: discount.daysLeft,
                  })
                : t('lang_new_discount.txt_giftbox_day', {
                      percentage: discount.discountPercentage,
                      days: discount.daysLeft,
                  })}
        </Button>
    );
};

export default NewDiscountGiftBox;
