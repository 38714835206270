import { Typography } from '@mui/material';

import { determineChipClassName, determinePlanDisplay } from '../../../../helpers/userhelper';

import UserInterface from '../../../../interfaces/user-interface';
import { utilConstants } from '../../../../constants/utils.constants';

export default function PlanTypeBadge({ user }: { user: UserInterface }) {
    return (
        <div className={`chip_user ${determineChipClassName(user)}`}>
            {user.userSubscription.isPremium && (
                <img src={utilConstants.SETTING.PREMIUM_CROWN} className={`image`} alt="Premium" />
            )}
            <Typography className={`text`} variant="body1">
                {determinePlanDisplay(user)}
            </Typography>
        </div>
    );
}
