import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { activityActions } from '../../../actions/activity.action';
import CommonButton from '../../../components/Common/CommonButton';

const VotingFooter = ({ onStopVoting }: any) => {
    const dispatch = useDispatch();

    const stopVoting = () => {
        dispatch(activityActions.stopVoting());
        onStopVoting();
    };

    return (
        <Fragment>
            <Fragment>
                <footer className="footer_sec participant_sec_foot">
                    <div className="footer_middle">
                        <CommonButton
                            isLoading={false}
                            variant="contained"
                            className="foot_btn"
                            type="button"
                            clickFunction={() => stopVoting()}
                            text="Stop voting"
                        />
                    </div>
                </footer>
            </Fragment>
        </Fragment>
    );
};

export default VotingFooter;
