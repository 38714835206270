import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Typography } from '@mui/material';

import { getValidUserFromStore, getValidClassSessionFromStore } from '../../helpers/storeHelpers';
import StoreInterface from '../../interfaces/store.interface';
import webviewMessenger from '../../services/webviewMessenger';

const ParticipantCountSection = ({ onShowMyClassQRModal }: { onShowMyClassQRModal: () => void }) => {
    const { t } = useTranslation();
    const classLimit = getValidUserFromStore('ParticipantCountSection').classLimit;
    const classSession = getValidClassSessionFromStore('ParticipantCountSection');
    const webviewSettingsSlideshow = useSelector((state: StoreInterface) => state.webviewSettingsSlideshow);
    const isShowingOnlineParticipantsOnly = webviewSettingsSlideshow.isMyClassShowOnlineParticipant;
    const isSavedClass = !!classSession.classSessionName;
    const totalParticipantCount = classSession.participantList.length;
    const onlineParticipantCount = classSession.participantList.filter((p) => p.joined && !p.left).length;

    const generateHeading = () => {
        let heading = '';
        if (!isSavedClass) {
            heading =
                onlineParticipantCount > 0
                    ? t('lang_myclass.txt_participant_summary_v1', {
                          online_participants: singularOrPlural(onlineParticipantCount),
                      })
                    : '';
        } else {
            if (onlineParticipantCount === 0 && !isShowingOnlineParticipantsOnly)
                heading = `${singularOrPlural(totalParticipantCount)}`;
            else
                heading = t('lang_myclass.txt_participant_summary_v2', {
                    total_participants: singularOrPlural(totalParticipantCount),
                    num_online: onlineParticipantCount,
                });
        }
        return heading;
    };

    const singularOrPlural = (participantCount: number) => {
        return participantCount > 1
            ? `${participantCount} ` + t('lang_common.txt_participant_plural')
            : `${participantCount} ` + t('lang_common.txt_participant_singular');
    };

    return (
        <div className="participant_joined_wrapper">
            <Typography variant="h2">
                {generateHeading()}{' '}
                {onlineParticipantCount >= classLimit ? (
                    <strong
                        onClick={() => {
                            webviewMessenger.sendUsageLog(`[S] Clicked "Max reached" red text`);
                            onShowMyClassQRModal();
                        }}
                        className="red classFull"
                    >
                        {t('lang_myclass.txt_max_reached')}
                    </strong>
                ) : null}
            </Typography>
        </div>
    );
};

export default ParticipantCountSection;
