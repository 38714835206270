import { getCachedReducer } from './../helpers/storeHelpers';
import { ClassSessionActionTypes } from '../constants/class-session-action-types';
import { UserActionTypes } from '../constants/user-action-types';
import ClassSessionInterface from '../interfaces/classSessionInterface';
import { localService } from '../services/localStorageService';
import { ReducerName } from '../constants/reducer-name.enum';

export default function classSessionReducer(
    state = getCachedClassSessionState(),
    action: { type: string; payload: any },
): ClassSessionInterface | null {
    switch (action.type) {
        case ClassSessionActionTypes.CLASSSESSION_UPDATED:
        case ClassSessionActionTypes.NEW_PARTICIPANT_JOINED:
        case ClassSessionActionTypes.EXISTING_PARTICIPANT_REFRESHED:
        case ClassSessionActionTypes.OFFLINE_PARTICIPANT_JOINED:
        case ClassSessionActionTypes.PARTICIPANT_GOES_OFFLINE:
        case ClassSessionActionTypes.PRESENTER_REMOVED_PARTICIPANT:
        case ClassSessionActionTypes.PRESENTER_LOCKS_CLASS:
        case ClassSessionActionTypes.PRESENTER_UNLOCKS_CLASS:
        case ClassSessionActionTypes.PRESENTER_ALLOWS_GUESTS:
        case ClassSessionActionTypes.PRESENTER_DISALLOWS_GUESTS:
        case ClassSessionActionTypes.PRESENTER_TOGGLES_ON_SAVE_GUESTS:
        case ClassSessionActionTypes.PRESENTER_TOGGLES_OFF_SAVE_GUESTS:
        case ClassSessionActionTypes.GIVE_POINTS_TO_PARTICIPANTS:
        case ClassSessionActionTypes.PARTICIPANTS_GOT_POINTS:
        case ClassSessionActionTypes.QNA_DATA_UPDATED:
            return action.payload;
        case ClassSessionActionTypes.END_SLIDESHOW:
        case UserActionTypes.HOST_AUTO_UNLOAD_USER:
        case UserActionTypes.AUTO_SIGN_IN_WITH_PASSWORD_FAILED:
        case UserActionTypes.USER_MANUALLY_SIGNED_OUT:
        case UserActionTypes.DEVICE_ID_CHANGED:
        case UserActionTypes.SIGN_OUT_ON_ERROR_BOUNDARY:
            return noClassSessionState;
        default:
            return state;
    }
}

const getCachedClassSessionState = () => {
    const classSessionCached: ClassSessionInterface | null = getCachedReducer(ReducerName.classSession);
    if (classSessionCached) return classSessionCached;

    const storeCached = localService.getStoreData();
    if (storeCached && storeCached.classSession) return storeCached.classSession;

    return noClassSessionState;
};

const noClassSessionState = null;
