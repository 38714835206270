import { Typography } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import CommonButton from '../../../../components/Common/CommonButton';

const WhiteBoardFooter = ({ onInsertWhiteboard, isAddWhiteboardButtonDisabled }: any) => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <div className="footer_left">
                <Typography variant="h4">
                    <span> </span>
                </Typography>
            </div>

            <div className="footer_middle">
                <CommonButton
                    isLoading={false}
                    variant="contained"
                    className="foot_btn_blue"
                    type="button"
                    disabled={isAddWhiteboardButtonDisabled}
                    clickFunction={onInsertWhiteboard}
                    text={t('lang_insert_whiteboard.btn_insert_whiteboard')}
                />
            </div>
            <div className="footer_right"></div>
        </Fragment>
    );
};

export default WhiteBoardFooter;
