import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormControl, InputLabel, Typography } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { utilConstants } from '../../../constants/utils.constants';
import { shuffleArray } from '../../../helpers/utils';

interface DropdownInputInterface {
    type: string;
    inputError: string;
    onValueChanged: (arg1: string, arg2: string) => void;
    isDropdownOpen: boolean;
    dropDownClickHandler: (arg?: boolean) => void;
}

function DropdownInput({
    type,
    onValueChanged,
    inputError,
    isDropdownOpen,
    dropDownClickHandler,
}: DropdownInputInterface) {
    const { t } = useTranslation();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [leadSourceArray, setLeadSourceArray] = useState(shuffleArray(utilConstants.LEAD_SOURCES));
    const [selectedValue, setSelectedValue] = useState('');

    const handleSelectValue = (value: string) => {
        setSelectedValue(value);
        onValueChanged(type, value);
        dropDownClickHandler();
    };

    return (
        <>
            <div
                onClick={() => dropDownClickHandler(false)}
                className={`${isDropdownOpen && 'show'} jobRoleOverlay`}
            ></div>
            <div className={`pf_form compact`}>
                <div className="pf_form">
                    <FormControl
                        variant="standard"
                        className={isDropdownOpen ? `select_box show_focus` : `select_box ${selectedValue && 'focus'}`}
                    >
                        <InputLabel> {t(`lang_auth.dropdown_${type}`)}</InputLabel>

                        <Typography variant="body1" className="select" onClick={() => dropDownClickHandler()}>
                            {selectedValue ? t(`lang_auth.txt_${selectedValue}`) : ''}
                            <ExpandLessIcon />
                        </Typography>
                    </FormControl>
                    <div className={isDropdownOpen ? 'listItemContainer show' : 'listItemContainer'}>
                        {leadSourceArray.map((value, index) => (
                            <Fragment key={index}>
                                <Button
                                    variant="text"
                                    className={`${value === selectedValue ? 'active' : ''} ${
                                        index === leadSourceArray.length - 1 && 'lastElement'
                                    }`}
                                    onClick={() => {
                                        handleSelectValue(value);
                                    }}
                                    onKeyDown={(event) => {
                                        event.key === 'Tab' &&
                                            index === leadSourceArray.length - 1 &&
                                            dropDownClickHandler();
                                    }}
                                >
                                    <Typography variant="body1">{t(`lang_auth.txt_${value}`)}</Typography>
                                </Button>
                            </Fragment>
                        ))}
                    </div>

                    {inputError && (
                        <div className="jobError">
                            <p>{t(`${inputError}`)}</p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default DropdownInput;
