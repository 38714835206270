import axios from 'axios';
import UserInterface from '../interfaces/user-interface';
import { logger } from './logger';
import { sentryLogApiError } from './sentryService';
import PlayOptionsInterface from '../interfaces/play-options.interface';
import webviewMessenger from './webviewMessenger';
import axiosWithLog from './axios-with-log';
import AiQuizSettingsInterface from '../interfaces/ai-quiz-settings.interface';

const apiPreferences = {
    saveLanguage: async (email: string, language: string): Promise<UserInterface | null> => {
        // update .net language immediately so it doesn't need to wait until user refresh
        webviewMessenger.sendUpdateLanguage(language);
        try {
            const url = `${process.env.REACT_APP_API}/users/preferences/language?email=${email}&language=${language}`;
            const response: any = await axiosWithLog.post(url);
            webviewMessenger.sendUsageLog(`Changed language to: ${language}, took ${response.duration} ms`);
            return response.data;
        } catch (error: any) {
            logger.error('saveLanguage() error: ', error.response || error);
            sentryLogApiError(error, 'saveLanguage', {
                query: { email, language },
            });
            return null;
        }
    },

    savePlayOptions: async (email: string, playOptions: PlayOptionsInterface): Promise<UserInterface | null> => {
        try {
            const url = `${process.env.REACT_APP_API}/users/preferences/play-options?email=${email}`;
            const response: any = await axiosWithLog.post(url, playOptions);
            webviewMessenger.sendUsageLog(`Saved Play Options, took ${response.duration} ms`);
            return response.data;
        } catch (error: any) {
            logger.error('savePlayOptions() error: ', error.response || error);
            sentryLogApiError(error, 'savePlayOptions', {
                query: { email },
                body: playOptions,
            });
            return null;
        }
    },

    deletePlayOptions: async (email: string): Promise<UserInterface | null> => {
        try {
            const url = `${process.env.REACT_APP_API}/users/preferences/play-options?email=${email}`;
            const response: any = await axiosWithLog.delete(url);
            webviewMessenger.sendUsageLog(`Deleted Play Options, took ${response.duration} ms`);
            return response.data;
        } catch (error: any) {
            logger.error('deletePlayOptions() error: ', error.response || error);
            sentryLogApiError(error, 'deletePlayOptions', {
                query: { email },
            });
            return null;
        }
    },

    addWhiteboard: async (email: string, name: string, imageUrl: string): Promise<UserInterface | null> => {
        try {
            const url = `${process.env.REACT_APP_API}/users/preferences/whiteboard-background?email=${email}`;
            const response = await axios.post(url, {
                name,
                url: imageUrl,
            });
            return response.data;
        } catch (error: any) {
            logger.error('addWhiteboard() error: ', error.response || error);
            sentryLogApiError(error, 'addWhiteboard', {
                query: { email },
                body: { name, imageUrl },
            });
            return null;
        }
    },

    editWhiteboard: async (
        email: string,
        id: string,
        name: string,
        imageUrl: string,
    ): Promise<UserInterface | null> => {
        try {
            const url = `${process.env.REACT_APP_API}/users/preferences/whiteboard-background?email=${email}`;
            const response = await axios.patch(url, {
                id,
                name,
                url: imageUrl,
            });
            return response.data;
        } catch (error: any) {
            logger.error('editWhiteboard() error: ', error.response || error);
            sentryLogApiError(error, 'editWhiteboard', {
                query: { email },
                body: { id, name, imageUrl },
            });
            return null;
        }
    },

    deleteWhiteboard: async (email: string, id: string): Promise<UserInterface | null> => {
        try {
            const url = `${process.env.REACT_APP_API}/users/preferences/whiteboard-background?email=${email}&id=${id}`;
            const response = await axios.delete(url);
            return response.data;
        } catch (error: any) {
            logger.error('deleteWhiteboard() error: ', error.response || error);
            sentryLogApiError(error, 'deleteWhiteboard', {
                query: { email, id },
            });
            return null;
        }
    },

    saveCustomizedLevels: async (email: string, levels: string): Promise<UserInterface | null> => {
        try {
            const url = `${process.env.REACT_APP_API}/users/preferences/customized-levels?email=${email}&levels=${levels}`;
            const response: any = await axiosWithLog.post(url);
            webviewMessenger.sendUsageLog(`Gamification levels edited, took ${response.duration} ms`);
            return response.data;
        } catch (error: any) {
            logger.error('saveCustomizedLevels() error: ', error.response || error);
            sentryLogApiError(error, 'saveCustomizedLevels', {
                query: { email, levels },
            });
            return null;
        }
    },

    resetCustomizedLevels: async (email: string): Promise<UserInterface | null> => {
        try {
            const url = `${process.env.REACT_APP_API}/users/preferences/customized-levels?email=${email}`;
            const response: any = await axiosWithLog.delete(url);
            webviewMessenger.sendUsageLog(`Gamification levels reset, took ${response.duration} ms`);
            return response.data;
        } catch (error: any) {
            logger.error('resetCustomizedLevels() error: ', error.response || error);
            sentryLogApiError(error, 'resetCustomizedLevels', {
                query: { email },
            });
            return null;
        }
    },

    // addMovableObjectsFolder: async (email: string, folderName: string): Promise<UserInterface | null> => {
    //     try {
    //         const url = `${process.env.REACT_APP_API}/users/preferences/movable/folder?email=${email}&folderName=${folderName}`;
    //         const response = await axios.post(url);
    //         return response.data;
    //     } catch (error: any) {
    //         logger.error('addMovableObjectsFolder() error: ', error.response || error);
    //         sentryLogApiError(error, 'addMovableObjectsFolder', {
    //             query: { email, folderName },
    //         });
    //         return null;
    //     }
    // },

    // renameMovableObjectsFolder: async (
    //     email: string,
    //     folderId: string,
    //     newFolderName: string,
    // ): Promise<UserInterface | null> => {
    //     try {
    //         const url = `${process.env.REACT_APP_API}/users/preferences/movable/folder?email=${email}&folderId=${folderId}&newFolderName=${newFolderName}`;
    //         const response = await axios.patch(url);
    //         return response.data;
    //     } catch (error: any) {
    //         logger.error('renameMovableObjectsFolder() error: ', error.response || error);
    //         sentryLogApiError(error, 'renameMovableObjectsFolder', {
    //             query: { email, folderId, newFolderName },
    //         });
    //         return null;
    //     }
    // },

    // addMovableObject: async (email: string, folderId: string, objectUrl: string): Promise<UserInterface | null> => {
    //     try {
    //         const url = `${process.env.REACT_APP_API}/users/preferences/movable?email=${email}`;
    //         const response = await axios.post(url, {
    //             folderId,
    //             objectUrl,
    //         });
    //         return response.data;
    //     } catch (error: any) {
    //         logger.error('addMovableObject() error: ', error.response || error);
    //         sentryLogApiError(error, 'addMovableObject', {
    //             query: { email },
    //             body: { folderId, objectUrl },
    //         });
    //         return null;
    //     }
    // },

    // moveMovableObject: async (
    //     email: string,
    //     objectId: string,
    //     originalFolderId: string,
    //     targetFolderId: string,
    // ): Promise<UserInterface | null> => {
    //     try {
    //         const url = `${process.env.REACT_APP_API}/users/preferences/movable?email=${email}&objectId=${objectId}&originalFolderId=${originalFolderId}&targetFolderId=${targetFolderId}`;
    //         const response = await axios.patch(url);
    //         return response.data;
    //     } catch (error: any) {
    //         logger.error('moveMovableObject() error: ', error.response || error);
    //         sentryLogApiError(error, 'moveMovableObject', {
    //             query: { email, objectId, originalFolderId, targetFolderId },
    //         });
    //         return null;
    //     }
    // },

    // deleteMovableObject: async (email: string, folderId: string, objectId: string): Promise<UserInterface | null> => {
    //     try {
    //         const url = `${process.env.REACT_APP_API}/users/preferences/movable?email=${email}&folderId=${folderId}&objectId=${objectId}`;
    //         const response = await axios.delete(url);
    //         return response.data;
    //     } catch (error: any) {
    //         logger.error('deleteMovableObject() error: ', error.response || error);
    //         sentryLogApiError(error, 'deleteMovableObject', {
    //             query: { email, folderId, objectId },
    //         });
    //         return null;
    //     }
    // },

    // deleteMovableObjectsFolder: async (email: string, folderId: string): Promise<UserInterface | null> => {
    //     try {
    //         const url = `${process.env.REACT_APP_API}/users/preferences/movable/folder?email=${email}&folderId=${folderId}`;
    //         const response = await axios.delete(url);
    //         return response.data;
    //     } catch (error: any) {
    //         logger.error('deleteMovableObjectsFolder() error: ', error.response || error);
    //         sentryLogApiError(error, 'deleteMovableObjectsFolder', {
    //             query: { email, folderId },
    //         });
    //         return null;
    //     }
    // },

    addBrowserBookmark: async (
        email: string,
        name: string,
        pageUrl: string,
        favicon: string,
    ): Promise<UserInterface | null> => {
        try {
            const url = `${process.env.REACT_APP_API}/users/preferences/bookmarks?email=${email}`;
            const response = await axios.post(url, {
                name,
                pageUrl,
                favicon,
            });
            return response.data;
        } catch (error: any) {
            logger.error('addBrowserBookmark() error: ', error.response || error);
            sentryLogApiError(error, 'addBrowserBookmark', {
                query: { email },
                body: { name, pageUrl, favicon },
            });
            return null;
        }
    },

    moveBrowserBookmark: async (
        email: string,
        bookmarkId: string,
        beforeId: string | null,
    ): Promise<UserInterface | null> => {
        // if moving a bookmark to end of the list, pass 'null' as beforeId
        try {
            const url = `${process.env.REACT_APP_API}/users/preferences/bookmarks?email=${email}&bookmarkId=${bookmarkId}&beforeId=${beforeId}`;
            const response = await axios.patch(url);
            return response.data;
        } catch (error: any) {
            logger.error('moveBrowserBookmark() error: ', error.response || error);
            sentryLogApiError(error, 'moveBrowserBookmark', {
                query: { email, bookmarkId, beforeId },
            });
            return null;
        }
    },

    updateBrowserBookmarks: async (email: string, updatedBookmarkIds: string): Promise<UserInterface | null> => {
        try {
            const url = `${process.env.REACT_APP_API}/users/preferences/bookmarks?email=${email}`;
            const response = await axios.patch(url, { updatedBookmarkIds });
            return response.data;
        } catch (error: any) {
            logger.error('updateBrowserBookmarks() error: ', error.response || error);
            sentryLogApiError(error, 'updateBrowserBookmarks', {
                query: { email },
                body: { updatedBookmarkIds },
            });
            return null;
        }
    },

    pinUnpinBrowserBookmark: async (
        email: string,
        bookmarkId: string,
        isPinned: boolean,
    ): Promise<UserInterface | null> => {
        try {
            const url = `${process.env.REACT_APP_API}/users/preferences/bookmarks?email=${email}`;
            const response = await axios.patch(url, { bookmarkId, isPinned });
            return response.data;
        } catch (error: any) {
            logger.error('pinUnpinBrowserBookmark() error: ', error.response || error);
            sentryLogApiError(error, 'pinUnpinBrowserBookmark', {
                query: { email },
                body: { bookmarkId, isPinned },
            });
            return null;
        }
    },

    deleteBrowserBookmark: async (email: string, bookmarkId: string): Promise<UserInterface | null> => {
        try {
            const url = `${process.env.REACT_APP_API}/users/preferences/bookmarks?email=${email}&bookmarkId=${bookmarkId}`;
            const response = await axios.delete(url);
            return response.data;
        } catch (error: any) {
            logger.error('deleteBrowserBookmark() error: ', error.response || error);
            sentryLogApiError(error, 'deleteBrowserBookmark', {
                query: { email, bookmarkId },
            });
            return null;
        }
    },

    updateAiQuizSettings: async (
        email: string,
        dto: Partial<AiQuizSettingsInterface>,
    ): Promise<UserInterface | null> => {
        try {
            const url = `${process.env.REACT_APP_API}/users/preferences/aiquiz?email=${email}`;
            const response = await axios.patch(url, { ...dto });
            return response.data;
        } catch (error: any) {
            logger.error('updateAiQuizSettings() error: ', error.response || error);
            sentryLogApiError(error, 'updateAiQuizSettings', {
                query: { email },
                body: { ...dto },
            });
            return null;
        }
    },

    toggleQna: async (email: string, isEnable: boolean): Promise<UserInterface | null> => {
        try {
            const url = `${process.env.REACT_APP_API}/users/preferences/toggle-qna?email=${email}&isEnable=${isEnable}`;
            const response = await axios.patch(url);
            return response.data;
        } catch (error: any) {
            logger.error('toggleQna() error: ', error.response || error);
            sentryLogApiError(error, 'toggleQna', {
                query: { email, isEnable },
            });
            return null;
        }
    },
};

export default apiPreferences;
