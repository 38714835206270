import { AnimationControls } from 'framer-motion';

export const aiQuestionAnimation = (
    isRtl: boolean,
    aiIconAnimation: AnimationControls,
    textBoxAnimation: AnimationControls,
    sideIconAnimation: AnimationControls,
    btnTextAnimation: AnimationControls,
    btnOneAnimation: AnimationControls,
    btnTwoAnimation: AnimationControls,
    btnThreeAnimation: AnimationControls,
    isQuestionGenerating: boolean,
) => {
    const btnInitial = {
        opacity: isQuestionGenerating ? 0 : 1,
        y: isQuestionGenerating ? 100 : 0,
    };

    const animationConstantArray = {
        iconInitial: {
            scale: isQuestionGenerating ? 1.5 : 1,
            x: isQuestionGenerating ? 320 : -40,
            y: isQuestionGenerating ? 57 : 30,
            // y: isQuestionGenerating ? 87 : 30,
            // y: 46,
        },
        textBoxInitial: {
            opacity: isQuestionGenerating ? 0 : 1,
        },
        sideIconInitial: {
            opacity: isQuestionGenerating ? 0 : 1,
        },
        btnTextInitial: {
            opacity: isQuestionGenerating ? 0 : 1,
        },
        allBtnInitial: btnInitial,
        oneBtnInitial: btnInitial,
        saveBtnInitial: btnInitial,
    };

    const animationTransition = {
        duration: 0.2,
        ease: 'linear',
    };

    const startAnimation = () => {
        aiIconAnimation.start({
            scale: 1,
            x: isRtl ? 678 : -40,
            y: 30,
            // y: 0,
            transition: {
                duration: 0.3,
                ease: 'linear',
            },
        });
        textBoxAnimation.start({
            opacity: 1,
            transition: {
                delay: 0.2,
                duration: 0.5,
                ease: 'linear',
            },
        });
    };

    const fadeOutAnimation = () => {
        sideIconAnimation.start({ opacity: 0, transition: { duration: 0 } });
        btnTextAnimation.start({ opacity: 0, ...animationTransition });
        btnOneAnimation.start({ opacity: 0, y: 100, ...animationTransition });
        btnTwoAnimation.start({ opacity: 0, y: 100, ...animationTransition });
        btnThreeAnimation.start({ opacity: 0, y: 100, ...animationTransition });
    };

    const buttonAnimation = (delay: number) => {
        return {
            opacity: 1,
            y: 0,
            transition: {
                delay: delay,
                type: 'spring',
                stiffness: 900,
                mass: 2.8,
                damping: 40,
            },
        };
    };

    const animationAfterQuestionTyped = () => {
        sideIconAnimation.start({
            opacity: 1,
            transition: {
                delay: 0.5,
                ...animationTransition,
            },
        });
        btnTextAnimation.start({
            opacity: 1,
            transition: {
                delay: 0.75,
                duration: 0.2,
                ease: 'linear',
            },
        });
        btnOneAnimation.start(buttonAnimation(0.1));
        btnTwoAnimation.start(buttonAnimation(0.2));
        btnThreeAnimation.start(buttonAnimation(0.3));
    };

    return {
        animationConstants: animationConstantArray,
        startAnimation: startAnimation,
        animationAfterQuestionTyped: animationAfterQuestionTyped,
        fadeOutAnimation: fadeOutAnimation,
    };
};
