import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import { Link, Typography } from '@mui/material';

import { classSessionActions } from '../../../actions/class-session.action';
import { getValidClassSessionFromStore } from '../../../helpers/storeHelpers';
import webviewMessenger from '../../../services/webviewMessenger';

function MyClassLocked() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classSession = getValidClassSessionFromStore('MyClassLocked');

    const toggleLockUnlockClass = () => {
        webviewMessenger.sendUsageLog(`[S] Toggled ${classSession.isLocked ? 'OFF' : 'ON'} lock class`);
        dispatch(classSessionActions.lockUnlockClass(!classSession.isLocked));
    };

    return (
        <div className={classSession.classSessionName ? '' : 'openClass classFull'}>
            <div className="qr_pop_sec">
                <div className="class_locked_sec blue">
                    <LockTwoToneIcon />
                    <Typography variant="h3">{t('lang_myclass.heading_class_locked')}</Typography>
                    <Typography variant="body1">
                        {t('lang_myclass.txt_class_locked_no_participant_can_join')}
                    </Typography>
                </div>
            </div>
            <div className="qr_footer">
                <div className="qr_learmore">
                    <Link onClick={toggleLockUnlockClass}>{t('lang_myclass.btn_unlock_class')}</Link>
                </div>
            </div>
        </div>
    );
}

export default MyClassLocked;
