import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CommonButton from '../../../components/Common/CommonButton';
import StoreInterface from '../../../interfaces/store.interface';
import webviewMessenger from '../../../services/webviewMessenger';

export default function ResetActivityButton({ activityId }: any) {
    const { t } = useTranslation();
    const classSession = useSelector((state: StoreInterface) => state.classSession);

    return (
        <CommonButton
            isLoading={false}
            type="button"
            className="default_btn reset_btn"
            text={t('lang_activity.reset_activity')}
            clickFunction={() => {
                webviewMessenger.sendUsageLog(`${classSession ? '[S] ' : ''}Clicked "Reset activity"`);
                webviewMessenger.sendResetActivity(activityId);
            }}
        />
    );
}
