import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { utilConstants } from '../../../constants/utils.constants';
import SwitchSectionHeader from '../../../components/Common/SwitchSectionHeader';
import webviewMessenger from '../../../services/webviewMessenger';
import _ from 'lodash';
import StoreInterface from '../../../interfaces/store.interface';
import ActivityResponseInterface from '../../../interfaces/activity-response.interface';
import {
    checkIfSingleBlankIsCorrect,
    generateActivityFileIndexesForDownload,
    processHtmlForInsertSlide,
} from '../../../helpers/activityHelpers';
import { useTranslation } from 'react-i18next';
import { generateAsterisksFromName } from '../../../helpers/utils';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const ActivityHeader = ({
    activity,
    selectedResponses,
    openLiveStatModal,
    onToggleCorrectAnswer,
    isShowCorrectAnswer,
    onToggleShowStarred,
    onToggleHighlightTopAnswers,
    isVoting,
    isHighlightTopAnswers,
    isShowStarred,
    set50ResponseAlert,
    setIsNoParticipantModalOpen,
}: any) => {
    const classSession = useSelector((state: StoreInterface) => state.classSession);
    const activityType = activity.activityProps.activityType;
    const { t } = useTranslation();

    const MultimediaActivities = [
        utilConstants.ACTIVITY_TYPE.SHORT_ANSWER,
        utilConstants.ACTIVITY_TYPE.SLIDE_DRAWING,
        utilConstants.ACTIVITY_TYPE.IMAGE_UPLOAD,
        utilConstants.ACTIVITY_TYPE.AUDIO_RECORD,
        utilConstants.ACTIVITY_TYPE.VIDEO_UPLOAD,
    ];

    const insertAsSlide = async () => {
        const isNamesHidden = activity.activityProps.isNamesHidden;
        const headerColumns = [
            t('lang_myclass.txt_name'),
            activityType === utilConstants.ACTIVITY_TYPE.SHORT_ANSWER
                ? t('lang_activity.insert_slide_response')
                : t('lang_activity.fill_blanks'),
        ];
        const outputResponses = isNamesHidden
            ? selectedResponses.map((res: ActivityResponseInterface) => ({
                  ...res,
                  participantName: generateAsterisksFromName(res.participantName),
              }))
            : selectedResponses;
        const lodashResponses = outputResponses.map((res: ActivityResponseInterface) =>
            _.pick(res, ['participantName', 'responseData']),
        );
        if (lodashResponses.length > 50) {
            set50ResponseAlert(true);
            return;
        }
        const payload = {
            activityType,
            title: t(`lang_activity.insert_slide_title_multiple`, { activityType: t(`lang_activity.${activityType}`) }),
            isInsertAsTable: true,
            headerColumns,
            responses:
                activityType === utilConstants.ACTIVITY_TYPE.SHORT_ANSWER
                    ? lodashResponses.map((response: any) => ({
                          ...response,
                          responseData: processHtmlForInsertSlide(response.responseData),
                      }))
                    : lodashResponses,
        };
        // console.log('payload', payload);
        webviewMessenger.sendInsertAsSlide(JSON.stringify(payload));
    };

    const insertFibCorrectAnswerAsSlide = () => {
        const fbCorrectAnswers = activity.activityProps.fbCorrectAnswers as string[];
        let fibCorrectAnswerStats: { correctAnswers: string; correctCount: string; correctRate: string }[] = [];
        fbCorrectAnswers.forEach((possibleCorrectAnswersString: string, index: number) => {
            let correctAnsCount = 0;
            activity.activityResponses.forEach((response: ActivityResponseInterface) => {
                if (JSON.parse(response.responseData)[index]) {
                    const participantAnswered = JSON.parse(response.responseData)[index];
                    if (checkIfSingleBlankIsCorrect(possibleCorrectAnswersString, participantAnswered)) {
                        correctAnsCount = correctAnsCount + 1;
                    }
                }
            });
            const correctRate = Math.round((correctAnsCount / activity.activityResponses.length) * 100);
            fibCorrectAnswerStats.push({
                correctAnswers: JSON.parse(possibleCorrectAnswersString).join(', '),
                correctCount: correctAnsCount.toString(),
                correctRate: correctRate.toString() + '%',
            });
        });
        const payload = {
            title: t(`lang_activity.fib_correct_answers`),
            activityType,
            headerColumns: [
                t('lang_activity.simple_blank'),
                t('lang_activity.correct_answer'),
                t('lang_activity.correct_count'),
                t('lang_activity.correct_rate'),
            ],
            fibCorrectAnswerStats,
        };
        // console.log('payload', payload);
        webviewMessenger.sendInsertAsSlide(JSON.stringify(payload));
    };

    const insertSnapshot = async () => {
        const payload = {
            activityType,
            title: t(`lang_activity.insert_slide_title_multiple`, { activityType: t(`lang_activity.${activityType}`) }),
            snapshotCropper:
                activityType === utilConstants.ACTIVITY_TYPE.WORD_CLOUD ? [0, 0.19, 0, 0.14] : [0, 0.11, 0, 0.14], //left, top, right, bottom
        };
        webviewMessenger.sendInsertAsSlide(JSON.stringify(payload));
    };

    const downloadAll = () => {
        const indexes = generateActivityFileIndexesForDownload(selectedResponses, activity.activityResponses);
        const payload = JSON.stringify({
            zipName: t(`lang_activity.${activityType}`),
            activityId: activity.activityProps.activityId,
            indexes,
        });

        const encodeValue = window.btoa(encodeURIComponent(payload));
        const downloadZipUrl = `${process.env.REACT_APP_CPDOWNLOAD}/activity-files/${encodeValue}`;
        webviewMessenger.sendOpenBrowser(downloadZipUrl);
    };

    return (
        <Fragment>
            {classSession && activity.activityMode === utilConstants.ACTIVITY_MODE.START_ACTIVITY ? (
                <Fragment>
                    <div className="class_code">
                        <h1>
                            <div
                                onClick={() => {
                                    webviewMessenger.sendUsageLog(`[S] Clicked join instruction on header`);
                                    setIsNoParticipantModalOpen(true);
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: t('lang_common.txt_visit_classpoint_use_code', {
                                        interpolation: { escapeValue: false },
                                        url: process.env.REACT_APP_PARTICIPANT,
                                        classCode: classSession.classCode,
                                    }),
                                }}
                            />
                        </h1>
                    </div>
                    <div className="live_status">
                        <h2
                            onClick={() => {
                                openLiveStatModal();
                            }}
                        >
                            <b>{t('lang_common.live_status')}</b>
                        </h2>
                    </div>
                </Fragment>
            ) : (
                <Fragment>
                    {activity.activityProps &&
                        activity.activityProps.activityType === utilConstants.ACTIVITY_TYPE.MULTIPLE_CHOICE && (
                            <SwitchSectionHeader
                                triggerFunction={() => {
                                    webviewMessenger.sendUsageLog(
                                        `${classSession ? '[S] ' : ''}Toggled ${
                                            isShowCorrectAnswer ? 'OFF' : 'ON'
                                        } MCQ correct answer`,
                                    );
                                    onToggleCorrectAnswer(!isShowCorrectAnswer);
                                }}
                                label={label}
                                disabled={
                                    activity.activityResponses.length > 0 &&
                                    activity.activityProps.mcCorrectAnswers.length > 0
                                        ? false
                                        : true
                                }
                                disableOnClick={
                                    activity.activityResponses.length > 0 &&
                                    activity.activityProps.mcCorrectAnswers.length > 0
                                        ? false
                                        : true
                                }
                                checked={isShowCorrectAnswer}
                                className={
                                    activity.activityResponses.length > 0 &&
                                    activity.activityProps.mcCorrectAnswers.length > 0
                                        ? 'switchtext'
                                        : 'switchtextDisabled'
                                }
                                switchText={t('lang_activity.toggle_show_correct_answer')}
                            />
                        )}

                    {activity.activityProps && MultimediaActivities.includes(activity.activityProps.activityType) && (
                        <SwitchSectionHeader
                            triggerFunction={() => {
                                webviewMessenger.sendUsageLog(
                                    `${classSession ? '[S] ' : ''}Toggled ${
                                        isShowStarred ? 'OFF' : 'ON'
                                    } show responses with stars`,
                                );
                                onToggleShowStarred(!isShowStarred);
                            }}
                            label={label}
                            disabled={activity.activityResponses.length > 0 && !isVoting ? false : true}
                            checked={isShowStarred}
                            className={
                                activity.activityResponses.length > 0 && !isVoting ? 'switchtext' : 'switchtextDisabled'
                            }
                            switchText={t('lang_activity.txt_show_response_with_points')}
                        />
                    )}

                    {activity.activityProps &&
                        activity.activityProps.activityType === utilConstants.ACTIVITY_TYPE.WORD_CLOUD && (
                            <SwitchSectionHeader
                                triggerFunction={() => {
                                    webviewMessenger.sendUsageLog(
                                        `${classSession ? '[S] ' : ''}Toggled ${
                                            isHighlightTopAnswers ? 'OFF' : 'ON'
                                        } highlight top answers`,
                                    );
                                    onToggleHighlightTopAnswers(!isHighlightTopAnswers);
                                }}
                                label={label}
                                disabled={activity.activityResponses.length > 0 ? false : true}
                                checked={isHighlightTopAnswers}
                                className={activity.activityResponses.length > 0 ? 'switchtext' : 'switchtextDisabled'}
                                switchText={t('lang_common.txt_highlight_top_answers')}
                            />
                        )}
                    {activity.activityProps &&
                        activity.activityProps.activityType === utilConstants.ACTIVITY_TYPE.FILL_IN_THE_BLANKS && (
                            <SwitchSectionHeader
                                triggerFunction={() => {
                                    webviewMessenger.sendUsageLog(
                                        `${classSession ? '[S] ' : ''}Toggled ${
                                            isShowCorrectAnswer ? 'OFF' : 'ON'
                                        } show FIB correct answers`,
                                    );
                                    onToggleCorrectAnswer(!isShowCorrectAnswer);
                                }}
                                label={label}
                                disabled={
                                    activity.activityResponses.length > 0 &&
                                    activity.activityProps.fbCorrectAnswers.length > 0
                                        ? false
                                        : true
                                }
                                disableOnClick={
                                    activity.activityResponses.length > 0 &&
                                    activity.activityProps.fbCorrectAnswers.length > 0
                                        ? false
                                        : true
                                }
                                checked={isShowCorrectAnswer}
                                className={
                                    activity.activityResponses.length > 0 &&
                                    activity.activityProps.fbCorrectAnswers.length > 0
                                        ? 'switchtext'
                                        : 'switchtextDisabled'
                                }
                                switchText={t('lang_activity.toggle_show_correct_answer')}
                            />
                        )}
                    <div className={activity.activityResponses.length > 0 ? 'live_status' : 'live_status_Disabled'}>
                        {(activityType === utilConstants.ACTIVITY_TYPE.SLIDE_DRAWING ||
                            activityType === utilConstants.ACTIVITY_TYPE.IMAGE_UPLOAD ||
                            activityType === utilConstants.ACTIVITY_TYPE.VIDEO_UPLOAD ||
                            activityType === utilConstants.ACTIVITY_TYPE.AUDIO_RECORD) && (
                            <h2
                                onClick={() => {
                                    webviewMessenger.sendUsageLog(
                                        `${classSession ? '[S] ' : ''}Clicked "Download all"`,
                                    );
                                    downloadAll();
                                }}
                            >
                                <b>{t('lang_activity.txt_download_all')} </b>
                            </h2>
                        )}
                        {activityType === utilConstants.ACTIVITY_TYPE.SHORT_ANSWER && (
                            <h2
                                onClick={() => {
                                    webviewMessenger.sendUsageLog(
                                        `${classSession ? '[S] ' : ''}Inserted responses as slide`,
                                    );
                                    insertAsSlide();
                                }}
                            >
                                <b>{t('lang_common.btn_insert_as_slide')}</b>
                            </h2>
                        )}
                        {activityType === utilConstants.ACTIVITY_TYPE.FILL_IN_THE_BLANKS && (
                            <h2
                                onClick={() => {
                                    if (isShowCorrectAnswer) {
                                        webviewMessenger.sendUsageLog(
                                            `${classSession ? '[S] ' : ''}Inserted FIB correct answers as slide`,
                                        );
                                        insertFibCorrectAnswerAsSlide();
                                    } else {
                                        webviewMessenger.sendUsageLog(
                                            `${classSession ? '[S] ' : ''}Inserted FIB responses as slide`,
                                        );
                                        insertAsSlide();
                                    }
                                }}
                            >
                                <b>{t('lang_common.btn_insert_as_slide')}</b>
                            </h2>
                        )}
                        {(activityType === utilConstants.ACTIVITY_TYPE.MULTIPLE_CHOICE ||
                            activityType === utilConstants.ACTIVITY_TYPE.WORD_CLOUD ||
                            activityType === utilConstants.ACTIVITY_TYPE.QUICK_POLL) && (
                            <h2
                                onClick={() => {
                                    webviewMessenger.sendUsageLog(
                                        `${classSession ? '[S] ' : ''}Inserted as slide (snapshot)`,
                                    );
                                    insertSnapshot();
                                }}
                            >
                                <b>{t('lang_common.btn_insert_as_slide')}</b>
                            </h2>
                        )}
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};

export default ActivityHeader;
