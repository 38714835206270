import WebviewSettingsEditModeInterface from '../interfaces/webview-settings-edit-mode.interface';
import WebviewSettingsSlideshowInterface from '../interfaces/webview-settings-slideshow-interface';
export const webviewSettingsActions = {
    updateWebviewSettings,
};

function updateWebviewSettings(
    type: string,
    payload: Partial<WebviewSettingsEditModeInterface | WebviewSettingsSlideshowInterface>,
) {
    return async (
        dispatch: (arg0: {
            type: string;
            payload: Partial<WebviewSettingsEditModeInterface | WebviewSettingsSlideshowInterface>;
        }) => void,
    ) => {
        dispatch({
            type,
            payload,
        });
    };
}
