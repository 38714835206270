import { ReducerName } from '../constants/reducer-name.enum';
import { UserActionTypes } from '../constants/user-action-types';
import { getCachedReducer } from '../helpers/storeHelpers';
import { localService } from '../services/localStorageService';
import { ClassSessionActionTypes } from './../constants/class-session-action-types';

export default function connnectionReducer(state = getCachedConnectionState(), action: { type: string; payload: any }) {
    switch (action.type) {
        case ClassSessionActionTypes.START_SLIDESHOW:
        case ClassSessionActionTypes.SIGNALR_RECONNECTED:
            return action.payload;
        case ClassSessionActionTypes.END_SLIDESHOW:
        case UserActionTypes.HOST_AUTO_UNLOAD_USER:
        case UserActionTypes.AUTO_SIGN_IN_WITH_PASSWORD_FAILED:
        case UserActionTypes.USER_MANUALLY_SIGNED_OUT:
        case UserActionTypes.DEVICE_ID_CHANGED:
        case UserActionTypes.SIGN_OUT_ON_ERROR_BOUNDARY:
            return null;
        default:
            return state;
    }
}

const getCachedConnectionState = () => {
    const connectionCached: any = getCachedReducer(ReducerName.connection);
    if (connectionCached) return connectionCached;

    const storeCached = localService.getStoreData();
    if (storeCached && storeCached.connection) return storeCached.connection;

    return null;
};
