import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash';

import TableContainer from '@mui/material/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import './GroupMembers.scss';

import { createSavedClassGroupProps } from '../../../../helpers/savedClassGroupHelper';

import SavedClassParticipantInterface from '../../../../interfaces/saved-class-participant.interface';
import { CreateOrEditGroupInterface, SavedClassGroupInterface } from '../../../../interfaces/savedClassGroup.interface';

import CustomAvatar, { AvatarType } from '../../../../components/Common/CustomAvatar';

interface GroupMembersInterface {
    isEditMode: boolean;
    sortedParticipants: SavedClassParticipantInterface[];
    currentGroupDetails: CreateOrEditGroupInterface;
    groupDetailsHandler: (value: string, fieldName: keyof CreateOrEditGroupInterface) => void;
    savedClassGroups: SavedClassGroupInterface[];
}

export default function GroupMembers({
    isEditMode,
    sortedParticipants,
    currentGroupDetails,
    groupDetailsHandler,
    savedClassGroups,
}: GroupMembersInterface) {
    const { t } = useTranslation();

    const [isShowingAllParticipants, setIsShowingAllParticipants] = useState(false);

    const groupedParticipants = orderBy(
        sortedParticipants
            .filter((participant) => participant.groupId?.toString())
            .map((participant) => {
                const group = savedClassGroups.find((group) => group.groupId === participant.groupId);
                return { ...participant, groupName: group?.groupName };
            }),
        'groupName',
        'asc',
    );

    const participantsInCurrentGroup =
        groupedParticipants.filter((participant) => participant.groupId === currentGroupDetails.groupId) || [];

    const participantsOutsideCurrentGroup =
        groupedParticipants.filter((participant) => participant.groupId !== currentGroupDetails.groupId) || [];

    const ungroupedParticipants = sortedParticipants.filter((participant) => !participant.groupId?.toString()) || [];

    const getGroupName = (participantId: string, groupId?: number) => {
        const name = createSavedClassGroupProps(
            'groupName',
            groupId,
            participantId,
            currentGroupDetails,
            savedClassGroups,
        );
        if (name === 'cp_ungrouped') return t('lang_grouping.ungrouped');
        return name;
    };

    const generateTable = (participantsArray: SavedClassParticipantInterface[]) => (
        <TableContainer className={`tableContainer`}>
            <Table size="small" className={`table`}>
                <TableBody className={`tableBody`}>
                    {participantsArray.map((participant) => (
                        <TableRow
                            key={participant.participantId}
                            onClick={() => groupDetailsHandler(participant.participantId, 'participantIds')}
                            className={`tableRow`}
                        >
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={currentGroupDetails.participantIds.includes(participant.participantId)}
                                    checkedIcon={<CheckBoxOutlinedIcon />}
                                    className={`checkbox`}
                                />
                            </TableCell>
                            <TableCell>
                                <div className={`participant`}>
                                    <CustomAvatar
                                        avatarName={participant.participantName}
                                        avatarUrl={participant.participantAvatar}
                                        type={AvatarType.PARTICIPANT}
                                        savedClassGroups={savedClassGroups}
                                        groupId={participant.groupId}
                                        style={{
                                            background: createSavedClassGroupProps(
                                                'groupColor',
                                                participant.groupId,
                                                participant.participantId,
                                                currentGroupDetails,
                                                savedClassGroups,
                                            ),
                                        }}
                                    />
                                    <p className={`participantName`}>{participant.participantName}</p>
                                </div>
                            </TableCell>
                            <TableCell align="right">
                                <p
                                    style={{
                                        background: createSavedClassGroupProps(
                                            'groupColor',
                                            participant.groupId,
                                            participant.participantId,
                                            currentGroupDetails,
                                            savedClassGroups,
                                        ),
                                    }}
                                    className={`groupName`}
                                >
                                    {getGroupName(participant.participantId, participant.groupId)}
                                </p>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    return (
        <div className={`groupMembers`}>
            {[...participantsInCurrentGroup, ...ungroupedParticipants].length <= 0 && (
                <p className={`noFreeMembers`}>{t('lang_grouping.all_are_grouped')}</p>
            )}

            {isEditMode
                ? generateTable([...participantsInCurrentGroup, ...ungroupedParticipants])
                : generateTable(ungroupedParticipants)}

            {participantsOutsideCurrentGroup.length > 0 && (
                <div onClick={() => setIsShowingAllParticipants((prev) => !prev)} className={`showAllToggle`}>
                    <p className={`text`}>{t('lang_grouping.show_all')}</p>
                    <ArrowDropDownIcon className={`icon ${isShowingAllParticipants && 'inverted'}`} />
                </div>
            )}

            {isShowingAllParticipants &&
                (isEditMode ? generateTable(participantsOutsideCurrentGroup) : generateTable(groupedParticipants))}
        </div>
    );
}
