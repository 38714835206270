import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

import CommonButton from '../../../components/Common/CommonButton';
import webviewMessenger from '../../../services/webviewMessenger';
import { utilConstants } from '../../../constants/utils.constants';
import { QuotaConstants } from '../../../constants/quota-constants';

const BasicUserCreateWhiteboardLimitAlert = ({ onConfirm }: any) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div className="media">
                <div className="media_icon">
                    <ReportProblemIcon className="warning" />
                </div>
                <div className="media_body">
                    <div className="alert_content">
                        <Typography variant="body1">
                            {t('lang_my_whiteboard.warning_basic_user_add_whiteboard_background_quota_reached', {
                                basicUserWhiteboardBackgroundQuota:
                                    QuotaConstants.BASIC_USER_WHITEBOARD_BACKGROUND_QUOTA,
                            })}
                        </Typography>
                    </div>
                </div>
            </div>

            <div className="button_sec">
                <CommonButton
                    isLoading={false}
                    variant="contained"
                    type="button"
                    text={t('lang_common.btn_learn_more')}
                    clickFunction={() => {
                        webviewMessenger.sendOpenBrowser(utilConstants.ADVANCED_FEATURE_URL);
                    }}
                />
                <CommonButton
                    isLoading={false}
                    variant="text"
                    type="button"
                    text={t('lang_common.btn_close')}
                    clickFunction={onConfirm}
                />
            </div>
        </Fragment>
    );
};

export default BasicUserCreateWhiteboardLimitAlert;
