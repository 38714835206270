import React, { Fragment } from 'react';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Typography } from '@mui/material';
import CommonButton from '../../../components/Common/CommonButton';
import { useTranslation } from 'react-i18next';

const EndingActivityAlert = ({ confirmEndActivity, closeEndingAlert }: any) => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <div className="media">
                <div className="media_icon">
                    <ReportProblemIcon className="warning" />
                </div>
                <div className="media_body">
                    <div className="alert_content">
                        <Typography variant="body1">{t('lang_activity.submission_not_closed')}</Typography>
                    </div>
                </div>
            </div>

            <div className="button_sec">
                <CommonButton
                    isLoading={false}
                    variant="contained"
                    type="button"
                    text={t('lang_common.btn_yes')}
                    clickFunction={() => confirmEndActivity()}
                />

                <CommonButton
                    isLoading={false}
                    variant="text"
                    type="button"
                    text={t('lang_common.btn_no')}
                    clickFunction={() => closeEndingAlert()}
                />
            </div>
        </Fragment>
    );
};

export default EndingActivityAlert;
