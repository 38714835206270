import { useMemo } from 'react';
import PickNamesParticipantInterface from '../../../interfaces/pick-names-participant.interface';
import PickNamesFlipCard from './PickNamesFlipCard';

interface PickNamesFlipCardInterface {
    participant: PickNamesParticipantInterface;
    isAutoPicking: boolean;
    flipOrderIndex: number;
    onClickHandler: any;
    givePointsConfettiRef: any;
}

function CachePickNameFlipCard({
    participant,
    isAutoPicking,
    flipOrderIndex,
    onClickHandler,
    givePointsConfettiRef,
}: PickNamesFlipCardInterface) {
    const participantCardIndex = JSON.stringify(participant.cardIndex);
    return useMemo(
        () => (
            <PickNamesFlipCard
                participant={participant}
                isAutoPicking={isAutoPicking}
                flipOrderIndex={flipOrderIndex}
                onClickHandler={onClickHandler}
                givePointsConfettiRef={givePointsConfettiRef}
            />
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [participant.isPicked, flipOrderIndex, participantCardIndex],
    );
}

export default CachePickNameFlipCard;
