export enum UserActionTypes {
    PAGE_REFRESHED_LOAD_CACHED_USER = 'PAGE_REFRESHED_LOAD_CACHED_USER',
    AUTO_SIGN_IN_WITH_PASSWORD_SUCCESS = 'AUTO_SIGN_IN_WITH_PASSWORD_SUCCESS',
    AUTO_SIGN_IN_WITH_PASSWORD_FAILED = 'AUTO_SIGN_IN_WITH_PASSWORD_FAILED',
    HOST_AUTO_UNLOAD_USER = 'HOST_AUTO_UNLOAD_USER',
    MANUAL_SIGN_IN_WITH_PASSWORD_SUCCESS = 'MANUAL_SIGN_IN_WITH_PASSWORD_SUCCESS',
    MANUAL_SIGN_IN_WITH_SSO_SUCCESS = 'MANUAL_SIGN_IN_WITH_SSO_SUCCESS',
    SIGN_IN_AFTER_SIGN_UP_SUCCESS = 'SIGN_IN_AFTER_SIGN_UP_SUCCESS',
    SIGN_IN_AFTER_PASSWORD_RESET_SUCCESS = 'SIGN_IN_AFTER_PASSWORD_RESET_SUCCESS',
    PERIODIC_CHECK_USER_CHANGED = 'PERIODIC_CHECK_USER_CHANGED',
    USER_PROFILE_EDITED = 'USER_PROFILE_EDITED',
    UPLOADED_AVATAR_IMAGES = 'UPLOADED_AVATAR_IMAGES',
    PRODUCT_KEY_APPLIED = 'PRODUCT_KEY_APPLIED',
    PRODUCT_KEY_CHANGED = 'PRODUCT_KEY_CHANGED',
    PRODUCT_KEY_REMOVED = 'PRODUCT_KEY_REMOVED',
    PLAN_CHANGE_SCHEDULED = 'PLAN_CHANGE_SCHEDULED',
    CANCELLATION_SCHEDULED = 'CANCELLATION_SCHEDULED',
    CANCELLATION_REVOKED = 'CANCELLATION_REVOKED',
    DEVICE_ID_CHANGED = 'DEVICE_ID_CHANGED',
    USER_MANUALLY_SIGNED_OUT = 'USER_MANUALLY_SIGNED_OUT',
    ADDED_SAVED_CLASS = 'ADDED_SAVED_CLASS',
    ADDED_SAVED_CLASS_NO_PARTICIPANT = 'ADDED_SAVED_CLASS_NO_PARTICIPANT',
    CHANGED_SAVED_CLASS_NAME_OR_CODE = 'CHANGED_SAVED_CLASS_NAME_OR_CODE',
    DELETE_SAVED_CLASS = 'DELETE_SAVED_CLASS',
    REORDER_SAVED_CLASSES = 'REORDER_SAVED_CLASSES',
    SIGN_OUT_ON_ERROR_BOUNDARY = 'SIGN_OUT_ON_ERROR_BOUNDARY',
    SHOW_API_ERROR = 'SHOW_API_ERROR',
    DISMISS_API_ERROR = 'DISMISS_API_ERROR',
    ADDED_WHITEBOARD = 'ADDED_WHITEBOARD',
    UPDATED_WHITEBOARD = 'UPDATED_WHITEBOARD',
    DELETED_WHITEBOARD = 'DELETED_WHITEBOARD',
    CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
    LOAD_USER_LANGUAGE_PREFERENCE = 'LOAD_USER_LANGUAGE_PREFERENCE',
    TRIAL_STARTED = 'TRIAL_STARTED',
    TRIAL_EXTENDED = 'TRIAL_EXTENDED',
    SAVE_CUSTOMIZED_LEVELS = 'SAVE_CUSTOMIZED_LEVELS',
    RESET_CUSTOMIZED_LEVELS = 'RESET_CUSTOMIZED_LEVELS',
    CREATE_MOVABLE_FOLDER = 'CREATE_MOVABLE_FOLDER',
    RENAME_MOVABLE_FOLDER = 'RENAME_MOVABLE_FOLDER',
    DELETE_MOVABLE_FOLDER = 'DELETE_MOVABLE_FOLDER',
    UPLOAD_MOVABLE_FILE = 'UPLOAD_MOVABLE_FILE',
    MOVE_MOVABLE_FILE = 'MOVE_MOVABLE_FILE',
    DELETE_MOVABLE_FILE = 'DELETE_MOVABLE_FILE',
    ADDED_BOOKMARK = 'ADDED_BOOKMARK',
    REARRANGED_BOOKMARKS = 'REARRANGED_BOOKMARKS',
    REMOVED_BOOKMARK = 'REMOVED_BOOKMARK',
    SYNC_SAVED_CLASSES = 'SYNC_SAVED_CLASSES',
    RELOAD_USER_REDUCER = 'RELOAD_USER_REDUCER',
    UPDATE_USER_CLASS_SESSION = 'UPDATE_USER_CLASS_SESSION',
    ADDED_ONBOARDING_EVENT = 'ADDED_ONBOARDING_EVENT',
    TOGGLE_QNA = 'TOGGLE_QNA',
}
