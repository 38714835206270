export const PickNamesCardThemes = [
    [
        '/assets/PickName/sports/Basketball.svg',
        '/assets/PickName/sports/football.svg',
        '/assets/PickName/sports/pool.svg',
        '/assets/PickName/sports/tabletennis.svg',
        '/assets/PickName/sports/vollyball.svg',
        '/assets/PickName/foods/burger.svg',
        '/assets/PickName/foods/cookie.svg',
        '/assets/PickName/foods/donut.svg',
        '/assets/PickName/foods/lalipop.svg',
        '/assets/PickName/foods/pizza.svg',
        '/assets/PickName/foods/popcorn.svg',
        '/assets/PickName/fruits/apple.svg',
        '/assets/PickName/fruits/avocado.svg',
        '/assets/PickName/fruits/cherry.svg',
        '/assets/PickName/fruits/grapes.svg',
        '/assets/PickName/fruits/Kiwi.svg',
        '/assets/PickName/fruits/Watermelon.svg',
    ],
    [
        '/assets/PickName/foods/burger.svg',
        '/assets/PickName/foods/cookie.svg',
        '/assets/PickName/foods/donut.svg',
        '/assets/PickName/foods/lalipop.svg',
        '/assets/PickName/foods/pizza.svg',
        '/assets/PickName/foods/popcorn.svg',
    ],
    [
        '/assets/PickName/fruits/apple.svg',
        '/assets/PickName/fruits/avocado.svg',
        '/assets/PickName/fruits/cherry.svg',
        '/assets/PickName/fruits/grapes.svg',
        '/assets/PickName/fruits/Kiwi.svg',
        '/assets/PickName/fruits/Watermelon.svg',
    ],
    [
        '/assets/PickName/transport/bike.svg',
        '/assets/PickName/transport/car.svg',
        '/assets/PickName/transport/plan.svg',
        '/assets/PickName/transport/ship.svg',
        '/assets/PickName/transport/trucks.svg',
    ],
    [
        '/assets/PickName/cap/cap1.svg',
        '/assets/PickName/cap/cap2.svg',
        '/assets/PickName/cap/cap3.svg',
        '/assets/PickName/cap/cap4.svg',
        '/assets/PickName/cap/cap5.svg',
    ],
];
