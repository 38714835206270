import { getCachedReducer } from './../helpers/storeHelpers';
import { ActivityActionTypes } from '../constants/activity-action-types';
import { UserActionTypes } from '../constants/user-action-types';
import { utilConstants } from '../constants/utils.constants';
import ActivityInterface from '../interfaces/activity.interface';
import { localService } from '../services/localStorageService';
import { ReducerName } from '../constants/reducer-name.enum';

export default function activityReducer(
    state = getCachedActivityState(),
    action: { type: string; payload: any },
): ActivityInterface {
    switch (action.type) {
        case ActivityActionTypes.START_ACTIVITY:
        case ActivityActionTypes.RESTART_ACTIVITY:
            return {
                ...noActivityState,
                activityProps: action.payload,
                activityMode: utilConstants.ACTIVITY_MODE.START_ACTIVITY,
            };
        case ActivityActionTypes.RESPONSES_RECEIVED:
        case ActivityActionTypes.SYNC_RESPONSES_ON_REJOIN:
            return {
                ...state,
                activityResponses: action.payload,
            };
        case ActivityActionTypes.EXTEND_COUNTDOWN:
            return {
                ...state,
                activityProps: state.activityProps
                    ? {
                          ...state.activityProps,
                          activityEndTime: action.payload,
                      }
                    : null,
            };
        case ActivityActionTypes.CLOSE_SUBMISSION:
            return {
                ...state,
                activityMode: utilConstants.ACTIVITY_MODE.VIEW_ACTIVITY_IN_SLIDESHOW,
            };
        case ActivityActionTypes.END_ACTIVITY:
        case ActivityActionTypes.DELETE_ACTIVITY:
        case ActivityActionTypes.CLEAR_ACTIVITY:
            return noActivityState;
        case ActivityActionTypes.LOAD_ACTIVITY:
            return {
                ...state,
                activityProps: action.payload.activityProps,
                activityMode: action.payload.activityMode,
                activityResponses: action.payload.activityResponses,
                isShowingEndActivityWarningModal: false,
                isVoting: false,
                isShowingEndVotingWarningModal: false,
                activityUser: action.payload.activityUser,
                activityVersion: action.payload.activityVersion,
                classSessionId: action.payload.classSessionId,
                savedClassId: action.payload.savedClassId,
                cachedBy: action.payload.cachedBy,
            };
        case ActivityActionTypes.DELETE_INDIVIDUAL_RESPONSES:
        case ActivityActionTypes.GIVE_POINTS_TO_RESPONSE:
        case ActivityActionTypes.GIVE_POINTS_TO_RESPONSES:
        case ActivityActionTypes.RESPONSE_GOT_POINTS:
        case ActivityActionTypes.RESPONSES_GOT_POINTS:
        case ActivityActionTypes.RESPONSE_VOTED:
            return {
                ...state,
                activityResponses: action.payload,
            };
        case ActivityActionTypes.START_VOTING:
            return {
                ...state,
                activityResponses: action.payload,
                isVoting: true,
            };
        case ActivityActionTypes.STOP_VOTING:
            return {
                ...state,
                isVoting: false,
            };
        case ActivityActionTypes.TOGGLE_END_ACTIVITY_WARNING_MODAL:
            return {
                ...state,
                isShowingEndActivityWarningModal: action.payload,
            };

        case ActivityActionTypes.TOGGLE_END_VOTING_WARNING_MODAL:
            return {
                ...state,
                isShowingEndVotingWarningModal: action.payload,
            };
        case UserActionTypes.HOST_AUTO_UNLOAD_USER:
        case UserActionTypes.AUTO_SIGN_IN_WITH_PASSWORD_FAILED:
        case UserActionTypes.USER_MANUALLY_SIGNED_OUT:
        case UserActionTypes.DEVICE_ID_CHANGED:
        case UserActionTypes.SIGN_OUT_ON_ERROR_BOUNDARY:
            return noActivityState;
        default:
            return state;
    }
}

const noActivityState: ActivityInterface = {
    activityProps: null,
    activityMode: null,
    activityResponses: [],
};

const getCachedActivityState = () => {
    const activityCached: ActivityInterface | null = getCachedReducer(ReducerName.activity);
    if (activityCached && activityCached.activityProps) return activityCached;

    const storeCached = localService.getStoreData();
    if (storeCached && storeCached.activity && storeCached.activity.activityProps) return storeCached.activity;

    return noActivityState;
};
