import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FitToViewport } from 'react-fit-to-viewport';
import { useTranslation } from 'react-i18next';

import Modal from '@mui/material/Modal';

import './LeaderBoard.scss';
import './components/groupView/LeaderBoardGroups.scss';

import { webviewSettingsActions } from '../../actions/webview-settings.actions';
import { UpdateToolbarActionsType } from '../../dtos/update-toolbar-actions.dto';
import { leaderBoardActions } from '../../actions/leaderboard.action';
import { getValidUserFromStore } from '../../helpers/storeHelpers';
import { isUserOnPremium, isUserOnTrialOrPro } from '../../helpers/userhelper';
import { localService } from '../../services/localStorageService';

import StoreInterface from '../../interfaces/store.interface';
import ParticipantInterface from '../../interfaces/participant.interface';
import { LeaderboardGroupRankInterface, LeaderboardGroupStatsInterface } from '../../interfaces/leaderboard.interface';
import { ViewportConstants } from '../../constants/viewport-constants';
import { WebviewSettingsActionTypes } from '../../constants/webview-settings-action-types';

import Footer from '../../components/layout/Footer';
import LeaderBoardOverall from './components/LeaderBoardOverall';
import LeaderBoardCurrentClass from './components/LeaderBoardCurrentClass';
import NoParticipantInClass from '../../components/Common/NoParticipantInClass';
import EmptyModal from '../EmptyModal/EmptyModal';
import LeaderHeader from './components/common/LeaderHeader';
import Header from '../../components/layout/Header/Header';
import LeaderBoardOverallGroups from './components/groupView/LeaderBoardOverallGroups';
import LeaderBoardCurrentGroups from './components/groupView/LeaderBoardCurrentGroups';
import { TrialFlow } from '../activateTrial/TrialFlow';

export default function LeaderBoard() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const webviewSettingsSlideshow = useSelector((state: StoreInterface) => state.webviewSettingsSlideshow);
    const classSession = useSelector((state: StoreInterface) => state.classSession);
    const savedClassGroups = classSession?.groups || [];
    const user = getValidUserFromStore('LeaderBoard');
    const isTrialOrPro = isUserOnTrialOrPro(user);
    const isPremiumUser = isUserOnPremium(user);

    const [isShowLeadersOverall, setIsShowLeadersOverall] = useState(
        !isTrialOrPro ? false : webviewSettingsSlideshow.isShowLeadersOverall,
    );
    const [hasShownLeadersOverall, setHasShownLeadersOverall] = useState(false);
    const participantList: ParticipantInterface[] = classSession
        ? JSON.parse(JSON.stringify(classSession.participantList))
        : [];
    const leadersOverall = useSelector((state: StoreInterface) => state.leaderboard.leadersOverall);
    const leadersCurrentClass = useSelector((state: StoreInterface) => state.leaderboard.leadersCurrentClass);

    const [numOfElements, setNumOfElements] = useState(7);
    const [numOfElementsCurrent, setNumOfElementsCurrent] = useState(7);
    const [showEmptyModal, setShowEmptyModal] = useState(false);
    const [isTrialFlowModalOpen, setIsTrialFlowModalOpen] = useState(false);
    const [isActivatingTrial, setIsActivatingTrial] = useState(false);
    const [showGroupRankModal, setShowGroupRankModal] = useState(false);
    const [groupRankStats, setGroupRankStats] = useState<LeaderboardGroupRankInterface>();

    useEffect(() => {
        setShowEmptyModal(true);
        setTimeout(() => {
            setShowEmptyModal(false);
        }, 1);
        localService.updateToolbarActionsFromWebview(UpdateToolbarActionsType.openLeaderboard);
    }, []);

    useEffect(() => {
        if (isTrialOrPro && leadersOverall.length > 0 && !hasShownLeadersOverall && isShowLeadersOverall) {
            const topTen = leadersOverall.filter(
                (participant) => participant.participantRank > 0 && participant.participantRank <= 10,
            );
            dispatch(leaderBoardActions.signalrShowLeaderboard(topTen));
            setHasShownLeadersOverall(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShowLeadersOverall, leadersOverall]);

    const showMoreOverallView = () => {
        setNumOfElements((prev) => prev + 5);
        const element: any = document.getElementById('scrolldiv');
        element.scrollTop = element.scrollHeight;
    };

    const showMoreCurrentClassView = () => {
        setNumOfElementsCurrent((prev) => prev + 5);
        const element: any = document.getElementById('scrolldiv1');
        element.scrollTop = element.scrollHeight;
    };

    const changeBackgroundForLoading = () => {
        const showLoadingBgInCurrentClassView = !isShowLeadersOverall && leadersCurrentClass.length === 0;
        const showLoadingBgInOverall = isShowLeadersOverall && leadersOverall.length === 0;
        if (showLoadingBgInCurrentClassView || showLoadingBgInOverall) return 'leaderloading';
        return '';
    };

    const handleLeaderBoardView = () => {
        if (isTrialOrPro) {
            dispatch(
                webviewSettingsActions.updateWebviewSettings(
                    WebviewSettingsActionTypes.UPDATE_IS_SHOW_LEADERS_OVERALL_VIEW,
                    {
                        isShowLeadersOverall: !isShowLeadersOverall,
                    },
                ),
            );
        }
        setIsShowLeadersOverall((prevState) => !prevState);
        if (!isShowLeadersOverall && !isTrialOrPro) setIsTrialFlowModalOpen(true);
        else setIsTrialFlowModalOpen(false);
    };

    const showGroupRankModalHandler = (value: boolean) => setShowGroupRankModal(value);

    const generateLeaderBoardGroupRank = () => {
        if (!classSession) return;
        const groupsArray = classSession.groups;
        if (!groupsArray?.length) return;

        const groupRankStatsArray: LeaderboardGroupStatsInterface[] = [];
        groupsArray?.forEach((group) => {
            const groupParticipants = participantList.filter(
                (data) => data.groupId?.toString() === group.groupId.toString(),
            );

            const currentStarsCount = groupParticipants.reduce((acc, data) => acc + data.participantPoints, 0);
            const totalStarsCount = groupParticipants.reduce(
                (acc, data) => acc + data.participantPoints + data.participantTotalPoints,
                0,
            );

            groupRankStatsArray.push({
                groupId: group.groupId,
                groupName: group.groupName,
                groupColor: group.groupColor,
                currentStarsCount: currentStarsCount,
                totalStarsCount: totalStarsCount,
            });
        });

        const sortedCurrentStars = [...groupRankStatsArray].sort((a, b) => b.currentStarsCount - a.currentStarsCount);
        const sortedTotalStars = [...groupRankStatsArray].sort((a, b) => b.totalStarsCount - a.totalStarsCount);

        const currentStarsArray = new Set(sortedCurrentStars.map((group) => group.currentStarsCount));
        const totalStarsArray = new Set(sortedTotalStars.map((group) => group.totalStarsCount));

        setGroupRankStats({
            groupStatsOrderByCurrent: sortedCurrentStars,
            groupStatsOrderByTotal: sortedTotalStars,
            currentStarsArray: [...currentStarsArray],
            totalStarsArray: [...totalStarsArray],
        });
    };

    useEffect(() => {
        generateLeaderBoardGroupRank();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <div className="black">
                <FitToViewport
                    className="viewport"
                    width={ViewportConstants.width}
                    height={ViewportConstants.height}
                    minZoom={ViewportConstants.minZoom}
                    maxZoom={ViewportConstants.maxZoom}
                >
                    {isTrialFlowModalOpen && (
                        <div className="modal update_top_pro">
                            <div className="back_drop"></div>
                            <div className={`trialFlowContentContainer`}>
                                <TrialFlow
                                    isShowMultipleSlides={false}
                                    startingSlideId={'total_leaderboard'}
                                    isActivatingTrial={isActivatingTrial}
                                    setIsActivatingTrial={setIsActivatingTrial}
                                    viewport={ViewportConstants}
                                    customClassNames="leaderBoardTrialActivation verticalText"
                                    onTrialActivated={() => setIsTrialFlowModalOpen(false)}
                                />
                            </div>
                        </div>
                    )}

                    <div className={`activity_leaderboard_main ${changeBackgroundForLoading()}`}>
                        {showEmptyModal && <EmptyModal viewportConfig={ViewportConstants} />}
                        <Header appBarTitle={t('lang_leaderboard.title')}>
                            <LeaderHeader
                                isShowLeadersOverall={isShowLeadersOverall}
                                handleLeaderBoardView={handleLeaderBoardView}
                                canInsertSlide={participantList.length > 0}
                            />
                        </Header>
                        {participantList.length > 0 ? (
                            <div className="leaderBoardDefault_sec ">
                                <div className="leader_board_card_body">
                                    <div
                                        className={
                                            participantList.length > 0
                                                ? 'leaderBoardContainer'
                                                : 'leaderBoardContainer empty'
                                        }
                                    >
                                        <Fragment>
                                            {isShowLeadersOverall ? (
                                                <div className="leader_board_inner" id="scrolldiv">
                                                    <LeaderBoardOverall
                                                        participantList={participantList}
                                                        numOfElements={numOfElements}
                                                        savedClassGroups={savedClassGroups}
                                                    />
                                                </div>
                                            ) : (
                                                <div className="leader_board_inner current" id="scrolldiv1">
                                                    <LeaderBoardCurrentClass
                                                        savedClassGroups={savedClassGroups}
                                                        numOfElements={numOfElementsCurrent}
                                                        participantList={participantList}
                                                    />
                                                </div>
                                            )}
                                        </Fragment>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <NoParticipantInClass />
                        )}
                        <Footer
                            layout="leaderboard"
                            isCurrentClassLeaderView={!isShowLeadersOverall}
                            showMoreDefaultView={showMoreOverallView}
                            showMoreCurrentClassLeader={showMoreCurrentClassView}
                            numOfElementsDefaultView={numOfElements}
                            numOfElementsCurrentView={numOfElementsCurrent}
                            hideBackBtn={isTrialFlowModalOpen}
                            isPremiumUser={isPremiumUser}
                            showGroupRankModalHandler={showGroupRankModalHandler}
                        />
                    </div>
                </FitToViewport>
            </div>

            <Modal
                open={showGroupRankModal}
                onClose={() => showGroupRankModalHandler(false)}
                className="leaderboardGroupRankModal"
            >
                <FitToViewport
                    className="leaderboardGroupRankModalViewport"
                    width={ViewportConstants.width}
                    height={ViewportConstants.height}
                    minZoom={ViewportConstants.minZoom}
                    maxZoom={ViewportConstants.maxZoom}
                >
                    {isShowLeadersOverall ? (
                        <div className={`overallGroupsContainer`}>
                            {<LeaderBoardOverallGroups groupRankStats={groupRankStats} />}
                        </div>
                    ) : (
                        <div className={`currentGroupsContainer`}>
                            {<LeaderBoardCurrentGroups groupRankStats={groupRankStats} />}
                        </div>
                    )}
                </FitToViewport>
            </Modal>
        </Fragment>
    );
}
