import { useTranslation } from 'react-i18next';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import webviewMessenger from '../../../../services/webviewMessenger';

import { PremiumPlanPoints, ProPlanPoints } from '../../../../constants/plan-bullet-points';

export default function FeatureList({
    caller,
    onClickAndMore,
    isPremiumUser,
}: {
    caller: string;
    onClickAndMore: () => void;
    isPremiumUser?: boolean;
}) {
    const { t } = useTranslation();

    const planFeatures = isPremiumUser ? PremiumPlanPoints : ProPlanPoints;
    return (
        <>
            {planFeatures.map((point, index) => (
                <div className={`items ${isPremiumUser && 'premiumList'}`} key={index}>
                    <CheckCircleIcon />
                    <p>{!!isPremiumUser ? t(`lang_premium.${point}`) : t(`lang_new_trial.${point}`)}</p>
                </div>
            ))}
            {!isPremiumUser && (
                <div className="items">
                    <CheckCircleIcon />
                    <p
                        onClick={() => {
                            webviewMessenger.sendUsageLog(`[C] Clicked "And more" on page ${caller}`);
                            onClickAndMore();
                        }}
                    >
                        {t(`lang_new_trial.txt_and_more`)}
                    </p>
                </div>
            )}
        </>
    );
}
