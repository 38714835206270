import { Box, Modal, TextField } from '@mui/material';
import React, { Fragment, useEffect } from 'react';
import { FitToViewport } from 'react-fit-to-viewport';
import { useTranslation } from 'react-i18next';
import CommonButton from '../../../components/Common/CommonButton';
import CloseIcon from '@mui/icons-material/Close';
import apiUser from '../../../services/apiUser';
import { getValidUserFromStore } from '../../../helpers/storeHelpers';
import { useDispatch } from 'react-redux';
import { userActions } from '../../../actions/user.actions';
import { UserActionTypes } from '../../../constants/user-action-types';
import { localService } from '../../../services/localStorageService';
import { whiteBoardConstants } from '../../../constants/viewport-constants';
import apiPreferences from '../../../services/apiPreferences';

const WhiteboardPreviewModal = (
    { isThisModalOpen, onCloseThisModal, isUpdating, selectedImage, imageValue }: any,
    ref: any,
) => {
    const dispatch = useDispatch();
    const user = getValidUserFromStore('WhiteboardPreviewModal');
    const { t } = useTranslation();
    const [whiteboardName, setWhiteboardName] = React.useState<any>({
        value: '',
        error: '',
    });
    const [isProcessing, setIsProcessing] = React.useState(false);

    useEffect(() => {
        if (isUpdating) {
            setWhiteboardName({ value: selectedImage.name, error: '' });
        } else {
            setWhiteboardName({
                value: '',
                error: '',
            });
        }

        return () => {
            setWhiteboardName({
                value: '',
                error: '',
            });
            setIsProcessing(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedImage]);

    const addWhiteBoardHandler = async () => {
        setIsProcessing(true);
        const whiteboardNameError = validateWhiteboardName(whiteboardName.value);
        if (whiteboardNameError) {
            setIsProcessing(false);
            return setWhiteboardName({ value: whiteboardName.value, error: whiteboardNameError });
        }

        const { blobData, imageName } = imageValue;
        const whiteboardUrl = await apiUser.uploadToStorage(blobData, imageName, user.apiServer, false, 'user');
        if (!whiteboardUrl) {
            setIsProcessing(false);
            return dispatch(userActions.showApiError());
        }
        if (!isUpdating) {
            const updatedUser = await apiPreferences.addWhiteboard(
                user.email,
                whiteboardName.value.trim(),
                whiteboardUrl,
            );
            if (!updatedUser) {
                dispatch(userActions.showApiError());
                return;
            }
            dispatch(userActions.updateUser(UserActionTypes.ADDED_WHITEBOARD, updatedUser));
        } else {
            const updatedUser = await apiPreferences.editWhiteboard(
                user.email,
                selectedImage.id,
                whiteboardName.value.trim(),
                whiteboardUrl,
            );
            if (!updatedUser) {
                dispatch(userActions.showApiError());
                return;
            }
            dispatch(userActions.updateUser(UserActionTypes.UPDATED_WHITEBOARD, updatedUser));
            apiUser.deleteFromStorage(selectedImage.images);
        }
        localService.removeTempWhiteboard();
        setIsProcessing(false);
        onCloseThisModal();
        setWhiteboardName({
            value: '',
            error: '',
        });
    };

    const validateWhiteboardName = (whiteboardNameValue: string) => {
        if (!whiteboardNameValue.trim()) return t('lang_my_whiteboard.err_msg_whiteboard_name_required');
        if (whiteboardNameValue.trim().length > 20) return t('lang_my_whiteboard.err_msg_whiteboard_name_long');
        return '';
    };

    const handleCloseThisModal = () => {
        setIsProcessing(false);
        onCloseThisModal();
    };

    return (
        <Fragment>
            <Modal
                open={isThisModalOpen}
                onClose={handleCloseThisModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Fragment>
                    <FitToViewport
                        className="modal_viewport"
                        width={whiteBoardConstants.width}
                        height={whiteBoardConstants.height}
                        minZoom={whiteBoardConstants.minZoom}
                        maxZoom={whiteBoardConstants.maxZoom}
                    >
                        <div className="close_modal" onClick={handleCloseThisModal}></div>

                        <Box className="whiteboardupload_modal">
                            <div className="upload_image_modal">
                                <div className="closebtn">
                                    <CloseIcon onClick={handleCloseThisModal} />
                                </div>
                                <div className="img_upload_warp">
                                    <img src={imageValue?.imageData} alt="staricon" />
                                </div>
                                <div className="whiteboard_field">
                                    <TextField
                                        autoComplete="none"
                                        id="standard-basic"
                                        fullWidth
                                        value={whiteboardName.value}
                                        label={t('lang_my_whiteboard.field_label_whiteboard_name')}
                                        variant="standard"
                                        onChange={(event) =>
                                            setWhiteboardName({
                                                value: event.target.value,
                                                error: '',
                                            })
                                        }
                                        helperText={whiteboardName.error}
                                        error={whiteboardName.error !== ''}
                                    />
                                </div>
                                <div className="button_sec justify_center">
                                    <CommonButton
                                        isLoading={isProcessing}
                                        variant="contained"
                                        type="button"
                                        clickFunction={() => addWhiteBoardHandler()}
                                        text={isUpdating ? t('lang_common.btn_save') : t('lang_common.btn_add')}
                                    />
                                </div>
                            </div>
                        </Box>
                    </FitToViewport>
                </Fragment>
            </Modal>
        </Fragment>
    );
};

export default React.forwardRef(WhiteboardPreviewModal);
