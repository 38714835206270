import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { getValidUserFromStore } from '../../../helpers/storeHelpers';
import CommonButton from '../../../components/Common/CommonButton';

interface VerifyEmailWarningModalInterface {
    isLoading: boolean;
    isCodeSent: boolean;
    sendVerificationCodeHandler: () => void;
    closeAlert: () => void;
}

const VerifyEmailWarningModal = ({
    isLoading,
    isCodeSent,
    sendVerificationCodeHandler,
    closeAlert,
}: VerifyEmailWarningModalInterface) => {
    const { t } = useTranslation();
    const user = getValidUserFromStore('VerifyEmailWarningModal');

    return isLoading || isCodeSent ? (
        <div className={`sendCodeText`}>
            {isLoading ? (
                <LoadingButton loading className={`loadingIcon`} variant="outlined" />
            ) : (
                <CheckCircleOutlineIcon className={`tickIcon`} />
            )}
            <Typography variant="body1" className={`text`}>
                {isLoading ? (
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('lang_auth.txt_sending_verification_code', {
                                interpolation: { escapeValue: false },
                                email: user.email,
                            }),
                        }}
                    />
                ) : (
                    t('lang_auth.txt_verification_code_sent')
                )}
            </Typography>
        </div>
    ) : (
        <>
            <div className="media">
                <div className="media_icon">
                    <ReportProblemIcon className="warning" />
                </div>
                <div className="media_body">
                    <div className="alert_content">
                        <Typography variant="body1">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t('lang_auth.txt_verify_email_warning', {
                                        interpolation: { escapeValue: false },
                                        email: user.email,
                                    }),
                                }}
                            />
                        </Typography>
                    </div>
                </div>
            </div>

            <div className="button_sec">
                <CommonButton
                    isLoading={false}
                    variant="contained"
                    type="button"
                    text={t('lang_common.btn_yes')}
                    clickFunction={() => sendVerificationCodeHandler()}
                />
                <CommonButton
                    isLoading={false}
                    variant="text"
                    type="button"
                    text={t('lang_common.btn_no')}
                    clickFunction={() => closeAlert()}
                />
            </div>
        </>
    );
};

export default VerifyEmailWarningModal;
