import { Typography } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { localService } from '../../../services/localStorageService';
import useActiveVideoInfo from '../Common/useActiveVideoInfo';
import { getLocalizedVideoInfo } from '../videoTutorialHelpers';
import { useDispatch } from 'react-redux';
import UserInterface from '../../../interfaces/user-interface';
import { tutorialSettingsActions } from '../../../actions/tutorial-settings.action';
import webviewMessenger from '../../../services/webviewMessenger';

interface VideoListItemInterface {
    user: UserInterface;
    videoId: string;
    activeVideoId: string;
}

function VideoListItem({ user, videoId, activeVideoId }: VideoListItemInterface) {
    const dispatch = useDispatch();
    const selectedLanguage = localService.getLanguage();
    const [videoInfo, videoDuration] = useActiveVideoInfo(videoId);

    const getselectedClassName = () => {
        let classNameList = '';
        if (videoId === activeVideoId) classNameList = 'active';
        else if (user.userStats.watchedTutorials?.includes(videoId)) classNameList = 'complete';
        return classNameList;
    };

    return (
        <li
            className={getselectedClassName()}
            key={videoId}
            onClick={() => {
                webviewMessenger.sendUsageLog(`Clicked video tutorial: ${videoId}`);
                dispatch(tutorialSettingsActions.updateActiveTutorialVideo(videoId));
            }}
        >
            <div className="single_video_box">
                <div className="title">
                    <div className="icon">
                        {getselectedClassName() === 'active' ? <PlayCircleOutlineIcon /> : <CheckCircleOutlineIcon />}
                    </div>
                    <Typography variant="body1"> {getLocalizedVideoInfo(videoInfo, selectedLanguage).title}</Typography>
                </div>
                <div className="time">
                    <Typography variant="caption">
                        {new Date(videoDuration * 1000).toISOString().substring(14, 19)}
                    </Typography>
                </div>
            </div>
        </li>
    );
}

export default VideoListItem;
