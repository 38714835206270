import { useMemo } from 'react';
import ParticipantInterface from '../../interfaces/participant.interface';
import ParticipantCard from './ParticipantCard';
import { SavedClassGroupInterface } from '../../interfaces/savedClassGroup.interface';

interface ParticipantCardInterface {
    savedClassGroups: SavedClassGroupInterface[];
    participant: ParticipantInterface;
    removeParticipant?: (arg: ParticipantInterface) => void;
    searchTextHighlight?: string;
    isOrderChanged?: boolean;
}

function CacheParticipantCard({
    savedClassGroups,
    participant,
    removeParticipant,
    searchTextHighlight,
    isOrderChanged,
}: ParticipantCardInterface) {
    return useMemo(
        () => (
            <ParticipantCard
                savedClassGroups={savedClassGroups}
                participant={participant}
                removeParticipant={removeParticipant}
                searchTextHighlight={searchTextHighlight}
                isOrderChanged={isOrderChanged}
            />
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            searchTextHighlight,
            isOrderChanged,
            participant.participantPoints,
            participant.participantTotalPoints,
            participant.participantId,
            participant.joined,
            participant.left,
            participant.participantAvatar,
            participant.participantName,
            participant.isFromSavedClass,
            savedClassGroups,
        ],
    );
}

export default CacheParticipantCard;
