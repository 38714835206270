export const AiLanguages = [
    { code: 'ar', label: 'Arabic (العربية)', value: 'العربية' },
    { code: 'bn', label: 'Bengali (বাংলা)', value: 'বাংলা' },
    { code: 'cs', label: 'Czech (Čeština)', value: 'Čeština' },
    { code: 'da', label: 'Danish (Dansk)', value: 'Dansk' },
    { code: 'de', label: 'German (Deutsch)', value: 'Deutsch' },
    { code: 'el', label: 'Greek (Ελληνικά)', value: 'Ελληνικά' },
    { code: 'en', label: 'English', value: 'English' },
    { code: 'es', label: 'Spanish (Español)', value: 'Español' },
    { code: 'fa', label: 'Persian (فارسی)', value: 'فارسی' },
    { code: 'fi', label: 'Finnish (Suomi)', value: 'Suomi' },
    { code: 'fil', label: 'Filipino', value: 'Filipino' },
    { code: 'fr', label: 'French (Français)', value: 'Français' },
    { code: 'he', label: 'Hebrew (עִבְרִית)', value: 'עִבְרִית' },
    { code: 'hi', label: 'Hindi (हिन्दी)', value: 'हिन्दी' },
    { code: 'hu', label: 'Hungarian (Magyar)', value: 'Magyar' },
    { code: 'id', label: 'Indonesian (Bahasa Indonesia)', value: 'Bahasa Indonesia' },
    { code: 'it', label: 'Italian (Italiano)', value: 'Italiano' },
    { code: 'ja', label: 'Japanese (日本語)', value: '日本語' },
    { code: 'ko', label: 'Korean (한국어)', value: '한국어' },
    { code: 'ms', label: 'Malay (Bahasa Melayu)', value: 'Bahasa Melayu' },
    { code: 'nl', label: 'Dutch (Nederlands)', value: 'Nederlands' },
    { code: 'no', label: 'Norwegian (Norsk)', value: 'Norsk' },
    { code: 'pl', label: 'Polish (Polski)', value: 'Polski' },
    { code: 'pt', label: 'Portuguese (Português)', value: 'Português' },
    { code: 'ro', label: 'Romanian (Română)', value: 'Română' },
    { code: 'ru', label: 'Russian (Русский)', value: 'Русский' },
    { code: 'sv', label: 'Swedish (Svenska)', value: 'Svenska' },
    { code: 'ta', label: 'Tamil (தமிழ்)', value: 'தமிழ்' },
    { code: 'th', label: 'Thai (ไทย)', value: 'ไทย' },
    { code: 'tr', label: 'Turkish (Türkçe)', value: 'Türkçe' },
    { code: 'uk', label: 'Ukrainian (Українська)', value: 'Українська' },
    { code: 'vi', label: 'Vietnamese (Tiếng Việt)', value: 'Tiếng Việt' },
    { code: 'zh', label: 'Simplified Chinese (简体中文)', value: '简体中文' },
    { code: 'tw', label: 'Traditional Chinese (繁體中文)', value: '繁體中文' },
];
