import React from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Typography } from '@mui/material';

function VotingCount({ response }: any) {
    return null;
    return (
        <div className="like_count">
            <FavoriteIcon />
            <Typography variant="caption">{response.voterParticipantIds?.length}</Typography>
        </div>
    );
}

export default VotingCount;
