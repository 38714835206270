import { ClassSessionActionTypes } from '../constants/class-session-action-types';
import { ReducerName } from '../constants/reducer-name.enum';
import { WebviewSettingsActionTypes } from '../constants/webview-settings-action-types';
import { getCachedReducer } from '../helpers/storeHelpers';
import WebviewSettingsSlideshowInterface from '../interfaces/webview-settings-slideshow-interface';
import { localService } from '../services/localStorageService';

export default function webviewSettingsSlideshowReducer(
    state = getCachedWebviewSettingsSlideshowState(),
    action: { type: string; payload: Partial<WebviewSettingsSlideshowInterface> },
): WebviewSettingsSlideshowInterface {
    switch (action.type) {
        case WebviewSettingsActionTypes.UPDATE_SHOW_LOCK_ALERT:
        case WebviewSettingsActionTypes.UPDATE_SHOW_REMOVE_PARTICIPANT_ALERT:
        case WebviewSettingsActionTypes.UPDATE_SHOW_RESTART_ACTIVITY_ALERT:
        case WebviewSettingsActionTypes.UPDATE_SHOW_RESPONSE:
        case WebviewSettingsActionTypes.UPDATE_PLAY_ACTIVITY_MUSIC:
        case WebviewSettingsActionTypes.UPDATE_SHOW_END_ACTIVITY_ALERT:
        case WebviewSettingsActionTypes.UPDATE_PARTICIPANT_SORT_ORDER:
        case WebviewSettingsActionTypes.TURN_OFF_PICK_NAMES_RESET_REMINDER:
        case WebviewSettingsActionTypes.UPDATE_PICK_NAMES_MODE:
        case WebviewSettingsActionTypes.TOGGLE_ADD_WHITEBOARD_VIEW:
        case WebviewSettingsActionTypes.TOGGLE_SHOW_50_MORE_RESPONSES:
        case WebviewSettingsActionTypes.ADJUST_ACTIVITY_MUSIC_VOLUME:
        case WebviewSettingsActionTypes.UPDATE_ACTIVITY_MUSIC_URL:
        case WebviewSettingsActionTypes.UPDATE_PICKED_NAMES_PANEL_VIEW:
        case WebviewSettingsActionTypes.UPDATE_IS_SHOW_LEADERS_OVERALL_VIEW:
        case WebviewSettingsActionTypes.UPDATE_IS_MY_CLASS_SIDEBAR_VIEW:
        case WebviewSettingsActionTypes.TOGGLE_PICK_NAME_SHOW_ONLINE_PARTICIPANT:
        case WebviewSettingsActionTypes.TOGGLE_MY_CLASS_SHOW_ONLINE_PARTICIPANT:
        case WebviewSettingsActionTypes.SHOW_RESET_STAR_ALERT:
            return {
                ...state,
                ...action.payload,
            };
        case ClassSessionActionTypes.TRIGGER_RESTART_CLASS:
            return {
                ...state,
                isMyClassShowOnlineParticipant: false,
                isPickNameShowOnlineParticipant: false,
            };
        default:
            return state;
    }
}

const getCachedWebviewSettingsSlideshowState = (): WebviewSettingsSlideshowInterface => {
    const cachedWebviewSettings: WebviewSettingsSlideshowInterface | null = getCachedReducer(
        ReducerName.webviewSettingsSlideshow,
    );
    if (cachedWebviewSettings) return { ...defaultWebviewSettingsSlideshow, ...cachedWebviewSettings };

    const storeCached = localService.getStoreData();
    if (storeCached && storeCached.webviewSettingsSlideshow)
        return { ...defaultWebviewSettingsSlideshow, ...storeCached.webviewSettingsSlideshow };

    return defaultWebviewSettingsSlideshow;
};

const defaultWebviewSettingsSlideshow: WebviewSettingsSlideshowInterface = {
    isShowLockAlert: true,
    isShowParticipantRemoveAlert: true,
    isShowRestartActivityAlert: true,
    isShowActivityResponses: true,
    isActivityMusicOn: true,
    isShowPickNamesResetReminder: true,
    isPickNamesModeWheel: true,
    isAddWhiteboardOnPresetView: true,
    activityMusicVolume: 30,
    activityMusicUrl: '',
    isPickedNamesPanelShown: false,
    isMyClassSidebarShown: true,
    isShowLeadersOverall: true,
    isPickNameShowOnlineParticipant: false,
    isMyClassShowOnlineParticipant: false,
    isShowResetStarAlert: true,
};
