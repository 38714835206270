import { Fragment, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import UserInterface from '../../../interfaces/user-interface';
import useActiveVideoInfo from '../Common/useActiveVideoInfo';
import TutorialVideoPlayer from './TutorialVideoPlayer';
import VideoTitleDescription from './VideoTitleDescription';

interface DisplayVideoInterface {
    user: UserInterface;
    activeVideoId: string;
    isAutoPlay: boolean;
    noDescription?: boolean;
}

const DisplayVideo = ({ user, activeVideoId, isAutoPlay, noDescription }: DisplayVideoInterface) => {
    // const history = useHistory();
    // const historyState: any = history.location.state;
    const historyState = useLocation().state;
    const playInFullscreen: boolean = historyState ? historyState.playInFullscreen : false;
    const [videoInfo, videoDuration] = useActiveVideoInfo(activeVideoId);
    const [isFullscreen, setIsFullscreen] = useState(playInFullscreen);

    return (
        <Fragment>
            {videoInfo && (
                <TutorialVideoPlayer
                    user={user}
                    activeVideoId={activeVideoId}
                    videoInfo={videoInfo}
                    videoDuration={videoDuration}
                    isFullscreen={isFullscreen}
                    setIsFullscreen={setIsFullscreen}
                    isAutoPlay={isAutoPlay}
                />
            )}
            {!noDescription && <VideoTitleDescription activeVideoId={activeVideoId} videoInfo={videoInfo} />}
        </Fragment>
    );
};

export default DisplayVideo;
