import { useTranslation } from 'react-i18next';

import { Link, Typography } from '@mui/material';

import { getValidUserFromStore } from '../../../../helpers/storeHelpers';
import { displayLocaleDate } from '../../../../helpers/languageHelpers';
import { getRemainingProDays } from '../../../../helpers/userhelper';

import { utilConstants } from '../../../../constants/utils.constants';

import PlanTypeBadge from '../components/PlanTypeBadge';
import { UpgradeToProButton } from '../../../../components/Common/UpgradeToProButton';
import FeatureList from '../components/FeatureList';
import webviewMessenger from '../../../../services/webviewMessenger';

interface SubscriptionTrialInterface {
    setCurrentPage: (arg: string) => void;
    onClickAndMore: () => void;
}

const SubscriptionTrial = ({ setCurrentPage, onClickAndMore }: SubscriptionTrialInterface) => {
    const { t } = useTranslation();
    const user = getValidUserFromStore('SubscriptionTrial');
    const userSubscription = user.userSubscription;
    const planType = userSubscription.planType;
    const planExpiryDate = new Date(userSubscription.planExpiryDate || '0000-01-01');
    const differenceInDays = getRemainingProDays(userSubscription.planExpiryDate);
    const formattedDate = displayLocaleDate(planExpiryDate);
    const alertDaysThreshold = 10;

    return (
        <>
            <div className="inner_subscription">
                <div className="body_head">
                    <Typography variant="h2">{t('lang_subscription.title')}</Typography>
                </div>
                <div className="big_card">
                    <div className="subcription_display">
                        <div className="card_icon">
                            <img src={utilConstants.SETTING.CROWN} alt="crown" />
                        </div>
                        <div className="plan_box_wrapper">
                            <div className="planbox">
                                <div className="plan_head">
                                    <Typography variant="h3">{t(`lang_subscription.txt_plan`)}</Typography>
                                </div>
                                <div className="plan_body">
                                    <Typography variant="h4" className={`title`}>
                                        {t(`lang_new_trial.classpoint_pro`)}
                                    </Typography>
                                    {planType === utilConstants.PLAN_TYPE.ORGANIZATION && (
                                        <Typography variant="body1">
                                            {t(`lang_subscription.txt_organization_plan_summary`, {
                                                organization: user.userProfile.organization,
                                            })}
                                        </Typography>
                                    )}
                                </div>
                                <PlanTypeBadge user={user} />
                            </div>

                            <div className="planbox">
                                <div className="plan_head">
                                    <Typography variant="h3">{t(`lang_subscription.txt_expires_in`)}</Typography>
                                </div>
                                <div className="plan_body">
                                    <Typography
                                        variant="h4"
                                        className={differenceInDays <= alertDaysThreshold ? 'red' : ''}
                                    >
                                        {t(`lang_new_trial.x_days_left_in_trial`, { daysLeft: differenceInDays })}{' '}
                                        <span className={`expiryDate`}>({formattedDate})</span>
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="feature_list">
                    <h5 className="list_head">{t(`lang_new_trial.txt_in_trial_can_upgrade`)}</h5>
                    <FeatureList caller="SubscriptionTrial" onClickAndMore={onClickAndMore} />
                </div>

                <div className={`btn_group pro row`}>
                    <UpgradeToProButton caller="subscriptionTrial" email={user.email} />
                    <Link
                        onClick={() => {
                            webviewMessenger.sendUsageLog(`[C] Clicked "Enter product key" from: SubscriptionTrial`);
                            setCurrentPage(utilConstants.SUBSCRIPTION_PAGE_TYPE.Add_Key);
                        }}
                        className="text_link"
                    >
                        {t('lang_subscription.txt_enter_product_key')}
                    </Link>
                </div>
            </div>
        </>
    );
};

export default SubscriptionTrial;
