import { Fragment } from 'react';
import Lottie from 'react-lottie-player';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { activityResponseLoaders } from '../../../constants/activity-response-loaders';
import webviewMessenger from '../../../services/webviewMessenger';

interface LoadingResponsesInterface {
    hasParticipantsJoined: boolean;
    numberOfResponses: number;
    activityLoaderIndex: number;
    setIsNoParticipantModalOpen: (value: boolean) => void;
}

function LoadingResponses({
    hasParticipantsJoined,
    numberOfResponses,
    activityLoaderIndex,
    setIsNoParticipantModalOpen,
}: LoadingResponsesInterface) {
    const { t } = useTranslation();

    const generateText = () => {
        if (numberOfResponses === 0) return `${t('lang_activity.Collecting_Responses')}`;
        if (numberOfResponses === 1)
            return t('lang_common.txt_num_of_participant_responded', {
                numOfParticipant: numberOfResponses,
            });
        return t('lang_common.txt_num_of_participants_responded', {
            numOfParticipant: numberOfResponses,
        });
    };

    return (
        <Fragment>
            <div className="waiting_animation2">
                <Lottie loop={true} animationData={activityResponseLoaders[activityLoaderIndex]} play speed={1} />
                <Typography variant="h5">{generateText()}</Typography>
                {!hasParticipantsJoined && (
                    <p className={`noParticipant`}>
                        {t('lang_activity.no_participants_part1')}{' '}
                        <span
                            onClick={() => {
                                webviewMessenger.sendUsageLog(`[S] Clicked "Here's how they can join"`);
                                setIsNoParticipantModalOpen(true);
                            }}
                            className={`highlight`}
                        >
                            {t('lang_activity.no_participants_part2')}
                        </span>
                    </p>
                )}
            </div>
        </Fragment>
    );
}

export default LoadingResponses;
