import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import 'swiper/css';
import './TrialFlow.scss';

import { getValidUserFromStore } from '../../helpers/storeHelpers';
import { getNewUserDiscount, isUserOnTrialOrPro } from '../../helpers/userhelper';
import webviewMessenger from '../../services/webviewMessenger';

import { ProPitchSlidesData } from '../../constants/pro-pitch-slides';

import { UpgradeToProButton } from '../../components/Common/UpgradeToProButton';
import NewUserDiscountLabel from '../../components/Common/NewUserDiscount/NewUserDiscountLabel';

interface ProPitchSlidesProps {
    isShowMultipleSlides: boolean;
    startingSlideId?: string;
    onStartTrial?: () => void;
    customClassNames?: string;
}

export default function ProPitchSlides({
    isShowMultipleSlides,
    startingSlideId,
    onStartTrial,
    customClassNames,
}: ProPitchSlidesProps) {
    const { t } = useTranslation();
    const user = getValidUserFromStore(`ProPitchSlides`);
    const isTrialOrPro = isUserOnTrialOrPro(user);
    const newUserDiscount = getNewUserDiscount(user);
    const navigationPrevRef = useRef<any>(null);
    const navigationNextRef = useRef<any>(null);

    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    const [rerender, setRerender] = useState(false);

    useEffect(() => {
        setRerender(true);
    }, []);

    useEffect(() => {
        webviewMessenger.sendUsageLog(`[C] Moved to GoPro slide: ${allSlides[activeSlideIndex].id}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSlideIndex]);

    const allSlides = isShowMultipleSlides
        ? ProPitchSlidesData.filter((item) => item.isInSlider)
        : ProPitchSlidesData.filter((item) => item.id === startingSlideId);

    const startingSlideIndex = allSlides.findIndex((item) => {
        return item.id === startingSlideId;
    });

    return (
        <Fragment>
            <div className={`trialActivationMainContainer ${isTrialOrPro && 'centerNavigation'} ${customClassNames}`}>
                <div className={'imageContainer'}>
                    <img src={allSlides[activeSlideIndex].banner} className={'image'} alt="ClassPoint Trial" />
                    {newUserDiscount && <NewUserDiscountLabel className="noClickable" />}
                </div>
                <div className={'textContainer'}>
                    <Swiper
                        navigation={{
                            prevEl: navigationPrevRef.current,
                            nextEl: navigationNextRef.current,
                        }}
                        grabCursor={true}
                        modules={[Navigation]}
                        initialSlide={startingSlideIndex}
                        onActiveIndexChange={(e) => setActiveSlideIndex(e.realIndex)}
                        loop={true}
                        className={'sliderContainer'}
                    >
                        {allSlides.map((item) => {
                            return (
                                <SwiperSlide key={item.id} className={`slideItem`}>
                                    <div className={'slideContent'}>
                                        <h3 className={`title`}>{t(`lang_pro_slider.${item.id}_title`)}</h3>
                                        <p className={`description`}>{t(`lang_pro_slider.${item.id}_description`)}</p>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>

                    <div className={'btnContainer'}>
                        <div className={'navigationContainer'}>
                            {allSlides.length > 1 && (
                                <>
                                    <div ref={navigationPrevRef} className={`previous`}>
                                        <KeyboardArrowLeftIcon className="icon" />
                                    </div>
                                    <p className={'slideCount'}>{`${activeSlideIndex + 1} / ${allSlides.length}`}</p>
                                    <div ref={navigationNextRef} className={`forward`}>
                                        <KeyboardArrowRightIcon className="icon" />
                                    </div>
                                </>
                            )}
                        </div>
                        {!isTrialOrPro && (
                            <Fragment>
                                <UpgradeToProButton
                                    caller={`proSlides_${allSlides[activeSlideIndex].id}`}
                                    email={user.email}
                                />
                                {/* {isAfterTrial ? (
                                    <UpgradeToProButton
                                        caller={`ProPitchSlides: ${allSlides[activeSlideIndex].id}`}
                                        email={user.email}
                                    />
                                ) : (
                                    <StartTrialButton
                                        caller={`ProPitchSlides: ${allSlides[activeSlideIndex].id}`}
                                        onClickHandler={onStartTrial}
                                    />
                                )} */}
                            </Fragment>
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
