import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Lottie from 'react-lottie-player';

import { Button } from '@mui/material';

import './ActivateTrial.scss';

import { userActions } from '../../actions/user.actions';
import apiUser from '../../services/apiUser';
import { getValidUserFromStore } from '../../helpers/storeHelpers';

import { UserActionTypes } from '../../constants/user-action-types';
import { utilConstants } from '../../constants/utils.constants';

import trialFlowActivating from '../../assets/animation/trialFlowActivating.json';
import trialFlowCheckSuccess from '../../assets/animation/8193-unlocked2.json';
import { VerifyEmail } from '../setting/verifyEmail/VerifyEmail';
import webviewMessenger from '../../services/webviewMessenger';

interface ActivateTrialProps {
    viewport?: any;
    isGamificationModal?: boolean;
    onActivationFailed: () => void;
    onSuccessBtnClick: () => void;
    onDismissVerifyEmail: () => void;
}

export const ActivateTrial = ({
    viewport,
    isGamificationModal,
    onActivationFailed,
    onSuccessBtnClick,
    onDismissVerifyEmail,
}: ActivateTrialProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = getValidUserFromStore('ActivateTrial');
    const isEmailVerified = user.userStats.isEmailVerified;
    const hasActivated = !!user.userSubscription.trialStarted;

    const activateTrial = async () => {
        setTimeout(async () => {
            const updatedUser = await apiUser.startTrial(user.email);

            if (!updatedUser) {
                onActivationFailed();
                return;
            }
            dispatch(userActions.updateUser(UserActionTypes.TRIAL_STARTED, updatedUser));
        }, utilConstants.SUBSCRIPTION_VARIABLES.Start_Trial_Delay);
    };

    const determineContent = () => {
        if (hasActivated) {
            return {
                id: 'activated',
                lottie: trialFlowCheckSuccess,
            };
        } else {
            return {
                id: 'activating',
                lottie: trialFlowActivating,
            };
        }
    };

    useEffect(() => {
        if (isEmailVerified && !hasActivated) activateTrial();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return !isEmailVerified ? (
        <VerifyEmail
            userEmail={user.email}
            isShowWarningModal={true}
            viewport={viewport}
            onCloseModal={onDismissVerifyEmail}
            customModalClassNames="noBackDrop"
            isGamificationModal={isGamificationModal}
        />
    ) : (
        <div className={`trialActivationModalContent default`}>
            <div className={`lottieContainer`}>
                <Lottie
                    animationData={determineContent().lottie}
                    play
                    loop={hasActivated ? false : true}
                    speed={hasActivated ? 2 : 1}
                />
            </div>
            <div className={`content`}>
                <h2 className={`heading`}>{t(`lang_new_trial.${determineContent().id}_heading`)}</h2>
                <p className={`description`}>{t(`lang_new_trial.${determineContent().id}_description`)}</p>
            </div>

            {hasActivated && (
                <div className={`btnContainer`}>
                    <Button
                        onClick={() => {
                            webviewMessenger.sendUsageLog(`[C] Clicked "Got it"`);
                            onSuccessBtnClick();
                        }}
                        className="btn"
                    >
                        {t(`lang_new_trial.activated_got_it`)}
                    </Button>
                </div>
            )}
        </div>
    );
};
