import './EditGroup.scss';

import SavedClassParticipantInterface, {
    OrderProperties,
    Orders,
} from '../../../../interfaces/saved-class-participant.interface';
import { SavedClassGroupInterface } from '../../../../interfaces/savedClassGroup.interface';

import ParticipantView from '../views/ParticipantView';

interface EditGroupInterface {
    selectedParticipants: SavedClassParticipantInterface[];
    savedClassGroups: SavedClassGroupInterface[];
    orderBy: OrderProperties;
    setOrderBy: (arg: OrderProperties) => void;
    order: Orders;
    setOrder: (arg: Orders) => void;
}

export default function EditGroup({
    selectedParticipants,
    savedClassGroups,
    orderBy,
    setOrderBy,
    order,
    setOrder,
}: EditGroupInterface) {
    return (
        <div className={`editSavedClassGroup`}>
            <ParticipantView
                sortedParticipants={selectedParticipants}
                savedClassGroups={savedClassGroups}
                order={order}
                setOrder={setOrder}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                isViewingGroup={true}
            />
        </div>
    );
}
