import { AES, enc } from 'crypto-js';
import { isJson } from '../helpers/utils';

export interface GeoInfoInterface {
    ip: string;
    countryCode: string;
    country: string;
    city: string | null;
}

export const getSavedGeoInfo = (): GeoInfoInterface | null => {
    try {
        const savedGeoInfoString = localStorage.getItem('session');
        if (!savedGeoInfoString) return null;

        const decrypted =
            process.env.REACT_APP_ENV === 'production'
                ? AES.decrypt(savedGeoInfoString, 'cp2').toString(enc.Utf8)
                : savedGeoInfoString;
        if (!isJson(decrypted)) {
            localStorage.removeItem('session');
            return null;
        }

        const savedGeoInfo: GeoInfoInterface = JSON.parse(decrypted);
        return savedGeoInfo;
    } catch (error) {
        localStorage.removeItem('session');
        return null;
    }
};

export const setSavedGeoInfo = (geoInfo: GeoInfoInterface) => {
    const encrypted =
        process.env.REACT_APP_ENV === 'production'
            ? AES.encrypt(JSON.stringify(geoInfo), 'cp2')
            : JSON.stringify(geoInfo);
    localStorage.setItem('session', encrypted.toString());
};
