import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button } from '@mui/material';

import { getValidUserFromStore } from '../../../../helpers/storeHelpers';
import { numberWithThousandSeparator } from '../../../../helpers/utils';
import { displayNewUserPrice, getNewUserDiscount } from '../../../../helpers/userhelper';

import { ViewportSavedClasses } from '../../../../constants/viewport-constants';
import { utilConstants } from '../../../../constants/utils.constants';
import { BasicPlanPoints } from '../../../../constants/plan-bullet-points';

import { UpgradeToProButton } from '../../../../components/Common/UpgradeToProButton';
import { VerifyEmail } from '../../verifyEmail/VerifyEmail';
import FeatureList from '../components/FeatureList';
import webviewMessenger from '../../../../services/webviewMessenger';
import NewUserDiscountLabel from '../../../../components/Common/NewUserDiscount/NewUserDiscountLabel';
import { KEY } from '../../../../Icon';

interface SubscriptionBasicInterface {
    addProductKey: () => void;
    onOpenTrialFlow: () => void;
    onActivateTrial: () => void;
    onShowDiscountModal: () => void;
}

export default function SubscriptionBasic({
    addProductKey,
    onOpenTrialFlow,
    onActivateTrial,
    onShowDiscountModal,
}: SubscriptionBasicInterface) {
    const { t } = useTranslation();
    const user = getValidUserFromStore('SubscriptionBasic');
    const userSubscribePrice = user.userSubscription.userSubscribePrice;

    const newUserDiscount = getNewUserDiscount(user);
    const isEmailVerified = user.userStats.isEmailVerified;
    const hasDiscount = newUserDiscount || userSubscribePrice.priceAnnual < userSubscribePrice.priceAnnualDisplay;
    const realPrice = newUserDiscount ? displayNewUserPrice(user) : userSubscribePrice.priceAnnual;

    const [openVerifyEmailModal, setOpenVerifyEmailModal] = useState(false);

    return (
        <>
            {openVerifyEmailModal && (
                <VerifyEmail
                    userEmail={user.email}
                    isShowWarningModal={true}
                    viewport={ViewportSavedClasses}
                    onCloseModal={() => setOpenVerifyEmailModal(false)}
                />
            )}

            <div className={`new_sb_card_sec ${!isEmailVerified && 'showVerifyEmailText'}`}>
                <div className="new_card small">
                    <div className="inner_card">
                        <div className="top_box">
                            <div className="title">
                                <div className="card_icon">
                                    <img src={utilConstants.SETTING.FINISH} alt="flag" />
                                </div>
                                <h4>{t('lang_subscription.txt_plan_basic')}</h4>
                            </div>
                            <div className="btn_group basic">
                                <Button variant="outlined" startIcon={<CheckIcon />}>
                                    {t('lang_subscription.txt_plan')}
                                </Button>
                            </div>
                        </div>
                        <div className="feature_list">
                            {BasicPlanPoints.map((point, index) => (
                                <div className="items" key={index}>
                                    <CheckCircleIcon />
                                    <p> {t(`lang_new_trial.${point}`)}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="new_card large">
                    <div className="inner_card">
                        {newUserDiscount && (
                            <NewUserDiscountLabel className="top" onShowDiscountModal={onShowDiscountModal} />
                        )}
                        <div className={`pro_title top_box discountTop ${!newUserDiscount && 'reduceTopSpace'}`}>
                            <div className="title">
                                <div className="card_icon">
                                    <img src={utilConstants.SETTING.CROWN} alt="crown" />
                                </div>
                                <h4>{t('lang_subscription.txt_plan_pro')}</h4>
                            </div>
                            <h5 className={`big_sub ${realPrice.toString().length > 2 && 'smallFont'}`}>
                                <span className="sup">{userSubscribePrice.currencyCode}</span>
                                {numberWithThousandSeparator(realPrice, userSubscribePrice.currencyCode)}
                                <span className="sub">
                                    {t('lang_new_trial.per_month')} ({t('lang_new_trial.billed_annually')})
                                </span>
                            </h5>
                            {hasDiscount && (
                                <p className="small_sub">
                                    {userSubscribePrice.currencyCode}{' '}
                                    <b>
                                        {numberWithThousandSeparator(
                                            newUserDiscount
                                                ? userSubscribePrice.priceAnnual
                                                : userSubscribePrice.priceAnnualDisplay,
                                            userSubscribePrice.currencyCode,
                                        )}
                                    </b>
                                    {/* {t('lang_new_trial.per_month')} ({t('lang_new_trial.billed_monthly')}) */}
                                    {t('lang_new_trial.per_month')}
                                </p>
                            )}
                            <div className="btn_group pro upgradeCta">
                                <UpgradeToProButton caller="subscriptionBasic" email={user.email} />
                            </div>
                            {/* <div className="have_product_key">
                                <div className="link">
                                    <p
                                        onClick={() => {
                                            webviewMessenger.sendUsageLog(`[C] Clicked "I have a product key"`);
                                            user.userStats.isEmailVerified
                                                ? addProductKey()
                                                : setOpenVerifyEmailModal(true);
                                        }}
                                        className="link"
                                    >
                                        {t(`lang_new_trial.have_product_key`)}
                                    </p>
                                </div>
                            </div> */}
                        </div>
                        <div className="feature_list">
                            <h5 className="list_head">{t('lang_new_trial.everything_in_basic_plus')}</h5>
                            <FeatureList caller="SubscriptionBasic" onClickAndMore={onOpenTrialFlow} />
                        </div>
                    </div>
                </div>

                <div className="bottom_fixed">
                    <div className="content_bottom first_subscription">
                        <div className="link">
                            <p
                                onClick={() => {
                                    webviewMessenger.sendUsageLog(`[C] Clicked "I have a product key"`);
                                    user.userStats.isEmailVerified ? addProductKey() : setOpenVerifyEmailModal(true);
                                }}
                                className="link"
                            >
                                <KEY />
                                {t(`lang_new_trial.have_product_key`)}
                            </p>
                        </div>
                        {/* <Typography variant="body1">
                            {t('lang_subscription.txt_not_ready_to_purchase_summary1')}{' '}
                            <Link
                                onClick={() => {
                                    webviewMessenger.sendUsageLog(
                                        `[C] Clicked "Not ready to purchase? Start free ${QuotaConstants.TRIAL_DURATION}-day trial`,
                                    );
                                    onActivateTrial();
                                }}
                            >
                                {t('lang_new_trial.start_14_day_free_trial', {
                                    trialDuration: QuotaConstants.TRIAL_DURATION,
                                })}
                            </Link>{' '}
                        </Typography> */}
                    </div>
                </div>
            </div>
        </>
    );
}
