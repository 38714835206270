import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { useGoogleLogin } from '@react-oauth/google';

import { Button, IconButton, InputAdornment, TextField } from '@mui/material';

import './Auth.scss';

import { userActions } from '../../actions/user.actions';
import { mapUserToSyncWithHost } from '../../helpers/userhelper';
import { generateYupErrors } from '../../helpers/authHelpers';
import { localService } from '../../services/localStorageService';
import apiUser from '../../services/apiUser';
import webviewMessenger from '../../services/webviewMessenger';
import apiPreferences from '../../services/apiPreferences';
import { GtmService } from '../../services/googleTagManager.service';

import { CurrentPageType } from '../../interfaces/signInSignUp.interface';
import { SsoSignUpInterface } from '../../interfaces/sso-signup.interface';
import { UserActionTypes } from '../../constants/user-action-types';
import { HostEventTypes } from '../../constants/host-event-types';
import { INPUT_LIMITS, utilConstants } from '../../constants/utils.constants';
import { FACEBOOK_CLIENT_ID } from '../../constants/sso-constants';

import CommonButton from '../../components/Common/CommonButton';
import { LoginWithMicrosoft } from './components/MicrosoftLogin';

declare global {
    interface Window {
        fbAsyncInit: any;
        FB: any;
    }
}

interface SignInInterface {
    setCurrentPage: (arg: CurrentPageType) => void;
    setSsoData: (arg: SsoSignUpInterface) => void;
}

export const SignIn = ({ setCurrentPage, setSsoData }: SignInInterface) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const currentLanguage = localService.getLanguage();

    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [loginDetails, setLoginDetails] = useState({ email: '', password: '' });
    const [loginError, setLoginError] = useState({
        emailError: '',
        passwordError: '',
    });

    const signUpUrl = `${process.env.REACT_APP_WEBAPP}/auth/signup?addin=true`;

    useEffect(() => {
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: FACEBOOK_CLIENT_ID,
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v17.0',
            });
        };

        (function (d, s, id) {
            let js: any,
                fjs: any = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
    }, []);

    const loginValidation = yup.object({
        email: yup
            .string()
            .required('emailError:error_email_required')
            .matches(utilConstants.REGEX.EMAIL, 'emailError:error_email_is_invalid'),
        password: yup
            .string()
            .required('passwordError:error_password_required')
            .min(6, 'passwordError:error_password_length'),
    });

    const onChangeHandler = (fieldName: string, value: string) => {
        setLoginDetails((prevState) => ({ ...prevState, [fieldName]: value.trim() }));
        setLoginError((prevState) => ({ ...prevState, [`${fieldName}Error`]: '' }));
    };

    const checkAndSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const isValidDetails = await loginValidation.isValid(loginDetails);
        if (isValidDetails) {
            const statsFromHost = localService.getStatsFromHost();
            const password = loginDetails.password;

            setIsLoading(true);
            const repliedUser = await apiUser.signInWithPassword(
                loginDetails.email.trim().toLowerCase(),
                { password, ...statsFromHost },
                'signInDialog',
            );

            if (!repliedUser) {
                setIsLoading(false);
                dispatch(userActions.showApiError());
                return;
            }
            if (typeof repliedUser === 'number') {
                setIsLoading(false);
                if (repliedUser === 409)
                    return setLoginError({
                        ...loginError,
                        passwordError: t(`lang_auth.error_api_sign_in_${repliedUser}`),
                    });
                else
                    return setLoginError({
                        ...loginError,
                        emailError: t(`lang_auth.error_api_sign_in_${repliedUser}`),
                    });
            }

            dispatch({
                type: UserActionTypes.MANUAL_SIGN_IN_WITH_PASSWORD_SUCCESS,
                payload: repliedUser,
            });
            // setIsLoading(false);

            if (!repliedUser.userPreferences?.language) apiPreferences.saveLanguage(repliedUser.email, currentLanguage);
            else dispatch(userActions.updateUser(UserActionTypes.LOAD_USER_LANGUAGE_PREFERENCE, repliedUser));
            webviewMessenger.sendUserLoaded(HostEventTypes.MANUAL_SIGN_IN_SUCCESS, mapUserToSyncWithHost(repliedUser));
            if (repliedUser.userStats.lastSignIn === repliedUser.userStats.firstCp2SignIn) {
                GtmService.sendFirstLoginEvent(repliedUser.userId, 'email');
                (window as any).fbq('trackCustom', 'FirstSignIn');
            } else {
                GtmService.sendLoginEvent(repliedUser.userId, 'manual-email');
                (window as any).fbq('trackCustom', 'SignIn');
            }

            webviewMessenger.sendHideWebview();
            webviewMessenger.sendUpdatePassword(password);
        } else {
            loginValidation
                .validate({ email: loginDetails.email, password: loginDetails.password.trim() }, { abortEarly: false })
                .catch((data) => {
                    const updatedErrors = generateYupErrors(data.errors);
                    setLoginError((prevState) => ({ ...prevState, ...updatedErrors }));
                });
        }
    };

    const loginGoogleSso = useGoogleLogin({
        onSuccess: (tokenResponse) => {
            loginWithGoogle(tokenResponse.access_token);
        },
    });

    const loginWithGoogle = async (accessToken: any) => {
        const profileInfo = await apiUser.getGoogleProfileInfo(accessToken);
        if (!profileInfo) return dispatch(userActions.showApiError());
        const data: SsoSignUpInterface = {
            accessToken,
            email: profileInfo.email,
            firstName: profileInfo.given_name,
            lastName: profileInfo.family_name,
            avatarUrl: profileInfo.picture,
            provider: 'google',
        };
        loginWithSocial(data);
    };

    const loginWithSocial = async (data: SsoSignUpInterface) => {
        const statsFromHost = localService.getStatsFromHost();
        const repliedUser = await apiUser.signInWithSso(data, { ...statsFromHost });
        if (!repliedUser) return dispatch(userActions.showApiError());

        if (typeof repliedUser === 'number') {
            if (repliedUser === 404) {
                setSsoData(data);
                setCurrentPage(CurrentPageType.SignUp);
                return;
            } else return dispatch(userActions.showApiError());
        }

        dispatch({
            type: UserActionTypes.MANUAL_SIGN_IN_WITH_SSO_SUCCESS,
            payload: repliedUser,
        });

        if (!repliedUser.userPreferences?.language) apiPreferences.saveLanguage(repliedUser.email, currentLanguage);
        else dispatch(userActions.updateUser(UserActionTypes.LOAD_USER_LANGUAGE_PREFERENCE, repliedUser));
        webviewMessenger.sendUserLoaded(HostEventTypes.MANUAL_SIGN_IN_SUCCESS, mapUserToSyncWithHost(repliedUser));
        if (repliedUser.userStats.lastSignIn === repliedUser.userStats.firstCp2SignIn) {
            GtmService.sendFirstLoginEvent(repliedUser.userId, `manual-${data.provider}`);
            (window as any).fbq('trackCustom', 'FirstSignIn');
        } else {
            GtmService.sendLoginEvent(repliedUser.userId, `manual-${data.provider}`);
            (window as any).fbq('trackCustom', 'SignIn');
        }

        webviewMessenger.sendHideWebview();
        webviewMessenger.sendUpdatePassword('sso');
    };

    const getUserMicrosoftProfileInfo = (response: { isSuccessful: boolean; data: any }) => {
        if (response.isSuccessful) return loginWithSocial(response.data);
        else if (!response.isSuccessful && response.data !== 'cancelled') return dispatch(userActions.showApiError());
    };

    return (
        <div className="innerContainer newLogin">
            {/* <div className="logo">
                <img src={utilConstants.IMAGE_URLS.cpCircular} className="image" alt="ClassPoint" />
            </div> */}
            <h2 className="heading">{t(`lang_auth.heading_btn_login`)}</h2>

            <div className="socialButtons">
                <Button fullWidth onClick={() => loginGoogleSso()} className="button">
                    <img src={utilConstants.SOCIAL.GOOGLE} alt="Google" className="image google" />
                    {t(`lang_auth.continue_with_google`)}
                </Button>
                <LoginWithMicrosoft loginHandler={getUserMicrosoftProfileInfo}>
                    <Button fullWidth className="button">
                        <img src={utilConstants.SOCIAL.MICROSOFT} alt="Microsoft" className="image" />
                        {t(`lang_auth.continue_with_microsoft`)}
                    </Button>
                </LoginWithMicrosoft>
            </div>

            <div className={'divider'}>
                <div className="line" />
                <p className="text">{t(`lang_common.txt_or`)}</p>
            </div>

            <div className="form newLogin">
                <form onSubmit={checkAndSubmit}>
                    <div className="profile_left">
                        <div className="pf_form">
                            <TextField
                                onChange={(e) => onChangeHandler('email', e.target.value)}
                                value={loginDetails.email}
                                error={loginError.emailError ? true : false}
                                helperText={t(`${loginError.emailError}`)}
                                inputProps={{ maxLength: INPUT_LIMITS.EMAIL_MAX }}
                                label={t(`lang_auth.input_email`)}
                                variant="standard"
                            />
                        </div>
                        <div className="pf_form">
                            <TextField
                                onChange={(e) => onChangeHandler('password', e.target.value)}
                                value={loginDetails.password}
                                error={loginError.passwordError ? true : false}
                                helperText={t(`${loginError.passwordError}`)}
                                inputProps={{ maxLength: INPUT_LIMITS.PASSWORD_MAX }}
                                type={showPassword ? 'text' : 'password'}
                                label={t(`lang_auth.input_password`)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword((show) => !show)}>
                                                {showPassword ? (
                                                    <img
                                                        src={utilConstants.IMAGE_URLS.PASSWORD_VISIBLE}
                                                        alt="ClassPoint"
                                                    />
                                                ) : (
                                                    <img
                                                        src={utilConstants.IMAGE_URLS.PASSWORD_HIDE}
                                                        alt="ClassPoint"
                                                    />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                autoComplete="none"
                                variant="standard"
                            />

                            <div className="forgetPassword">
                                <p onClick={() => setCurrentPage(CurrentPageType.ForgotPassword)}>
                                    {t('lang_auth.txt_forgot_password')}
                                </p>
                            </div>
                        </div>

                        <div className="profile_update_btn">
                            <CommonButton
                                isLoading={isLoading}
                                variant="contained"
                                type="submit"
                                color="primary"
                                text={t(`lang_auth.continue_with_email`)}
                            />
                        </div>
                    </div>

                    <div className="signUp_link">
                        <p>
                            {t(`lang_auth.txt_dont_have_account`)}{' '}
                            <span onClick={() => webviewMessenger.sendOpenBrowser(signUpUrl)}>
                                {t(`lang_auth.txt_sign_up_now`)}
                            </span>
                        </p>
                    </div>
                </form>
            </div>
        </div>
    );
};
