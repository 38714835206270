import { Fragment, useEffect, useState } from 'react';
import './FillBlanks.scss';
import FillBlanksCorrectAnswers from './FillBlanksCorrectAnswers';
import SearchBox from '../components/SearchBox';
import { getActivityFromStore, getValidUserFromStore } from '../../../helpers/storeHelpers';
import { useTranslation } from 'react-i18next';
import ActivityResponseInterface from '../../../interfaces/activity-response.interface';
import { ActivityPropsInterface } from '../../../interfaces/activity-props.interface';
import FillBlanksRow from './FillBlanksRow';
import ShowingResponsesCount from '../../../components/Common/ShowingResponsesCount';
import { canEditResponses } from '../../../helpers/activityHelpers';
import { utilConstants } from '../../../constants/utils.constants';

const FillBlanksContent = ({ selectedResponses, searchKeyword, onSearch, isShowCorrectAnswer }: any) => {
    const user = getValidUserFromStore('FillBlanksContent');
    const activity = getActivityFromStore();
    const activityProps = activity.activityProps as ActivityPropsInterface;
    const fbNumOfBlanks = activityProps.fbNumOfBlanks as number;
    const isNamesHidden = activityProps.isNamesHidden || false;
    const canEditResponsesInView = canEditResponses(activity, user.email);
    const [hasShownCorrectAnswers, setHasShownCorrectAnswers] = useState(true);
    const [anyResponseHasStars, setAnyResponseHasStars] = useState(false);

    const { t } = useTranslation();
    const showStarColumn = canEditResponsesInView || (!canEditResponsesInView && anyResponseHasStars);

    let totalWrapperWidth: string;
    let totalTheadWidth: string;
    let totalbodyWidth: string;
    let trowWidth: string;
    let tbodywidth: string;
    let button: string;
    switch (fbNumOfBlanks) {
        case 1:
            totalWrapperWidth = showStarColumn ? '640px' : '562px';
            tbodywidth = showStarColumn ? '796px' : '776px';
            totalTheadWidth = showStarColumn ? '314.90px' : '321.90px';
            totalbodyWidth = showStarColumn ? '315px' : '322px';
            trowWidth = '240px';
            button = '85px';
            break;
        case 2:
            totalWrapperWidth = showStarColumn ? '702px' : '624px';
            tbodywidth = showStarColumn ? '828px' : '806px';
            totalTheadWidth = showStarColumn ? '216.80px' : '223.80px';
            totalbodyWidth = showStarColumn ? '217px' : '224px';
            trowWidth = '200px';
            button = '85px';
            break;
        case 3:
            totalWrapperWidth = showStarColumn ? '870px' : '792px';
            tbodywidth = showStarColumn ? '914px' : '890px';
            totalTheadWidth = showStarColumn ? '213.70px' : '220.70px';
            totalbodyWidth = showStarColumn ? '214px' : '221px';
            trowWidth = '190px';
            button = '85px';
            break;
        case 4:
            totalWrapperWidth = showStarColumn ? '908px' : '840px';
            tbodywidth = showStarColumn ? '938px' : '915px';
            totalTheadWidth = showStarColumn ? '213.70px' : '220.70px';
            totalbodyWidth = showStarColumn ? '214px' : '221px';
            trowWidth = showStarColumn ? '152px' : '155px';
            button = '85px';
            break;
        case 5:
            totalWrapperWidth = showStarColumn ? '908px' : '888px';
            tbodywidth = showStarColumn ? '938px' : '938px';
            totalTheadWidth = showStarColumn ? '212.80px' : '220.70px';
            totalbodyWidth = showStarColumn ? '212px' : '221px';
            trowWidth = showStarColumn ? '122px' : '134px';
            button = '85px';
            break;
        default:
            totalWrapperWidth = '610px';
            tbodywidth = '892px';
            totalTheadWidth = '322px';
            totalbodyWidth = '322px';
            trowWidth = '240px';
            button = '85px';
            break;
    }

    useEffect(() => {
        if (isShowCorrectAnswer) setHasShownCorrectAnswers(true);
    }, [isShowCorrectAnswer]);

    useEffect(() => {
        const doesAnyResponseHaveStars = selectedResponses.some(function (response: ActivityResponseInterface) {
            return response.responsePoints;
        });
        setAnyResponseHasStars(doesAnyResponseHaveStars);
    }, [selectedResponses]);

    const fillBlanksTable = (
        <Fragment>
            <div className="fill_search">
                <div className="search_area">
                    <SearchBox onSearch={onSearch} />
                    <ShowingResponsesCount count={selectedResponses.length} />
                </div>
            </div>
            <div className="total-wrapper" style={{ width: totalWrapperWidth }}>
                <table className="rest-columns">
                    <thead>
                        <tr className="thead">
                            <th style={{ width: totalTheadWidth }}>{t('lang_myclass.txt_name')}</th>
                            {Array.from(Array(fbNumOfBlanks).keys()).map((index) => (
                                <th style={{ width: trowWidth }} key={index}>
                                    {t('lang_activity.fill_blanks', { blankNumber: index + 1 })}
                                </th>
                            ))}
                            {showStarColumn ? <th style={{ width: button }}></th> : null}
                        </tr>
                    </thead>
                    <tbody className="tbody" style={{ width: tbodywidth }}>
                        {selectedResponses &&
                            selectedResponses.map((response: ActivityResponseInterface, index: number) => (
                                <FillBlanksRow
                                    key={response.responseId}
                                    index={index}
                                    totalbodyWidth={totalbodyWidth}
                                    isNamesHidden={isNamesHidden}
                                    response={response}
                                    searchKeyword={searchKeyword}
                                    showStarColumn={showStarColumn}
                                    hasShownCorrectAnswers={hasShownCorrectAnswers}
                                    trowWidth={trowWidth}
                                    button={button}
                                    canEditResponsesInView={canEditResponsesInView}
                                    anyResponseHasStars={anyResponseHasStars}
                                />
                            ))}
                    </tbody>
                </table>
            </div>
        </Fragment>
    );

    if (activityProps.activityType !== utilConstants.ACTIVITY_TYPE.FILL_IN_THE_BLANKS) return null;
    return (
        <Fragment>
            <div className={isShowCorrectAnswer === true ? 'fill_blank_sec show_ans' : 'fill_blank_sec'}>
                <div className="fillBlankContainer">
                    <div className="inner_fillblank">
                        {isShowCorrectAnswer === true ? <FillBlanksCorrectAnswers /> : fillBlanksTable}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default FillBlanksContent;
