import ExportPdfInterface from '../interfaces/export-pdf.interface';
import { ExportPdfActionTypes } from './../constants/export-pdf-action-types';
export const exportPdfActions = {
    openExportPdf,
    updateSelectedSlidesCount,
    includeAnnotationWhiteboard,
    startExportPdf,
    pdfExported,
    cancelExportPdf,
};

function openExportPdf(selectedSlidesCount: number) {
    return async (dispatch: (arg0: { type: string; payload: Partial<ExportPdfInterface> }) => void) => {
        dispatch({
            type: ExportPdfActionTypes.OPEN_EXPORT_PDF,
            payload: { selectedSlidesCount },
        });
    };
}

function updateSelectedSlidesCount(selectedSlidesCount: number) {
    return async (dispatch: (arg0: { type: string; payload: Partial<ExportPdfInterface> }) => void) => {
        dispatch({
            type: ExportPdfActionTypes.UPDATE_SELECTED_SLIDES_COUNT,
            payload: { selectedSlidesCount },
        });
    };
}

function includeAnnotationWhiteboard(isIncludeAnnotation: boolean, isIncludeWhiteboard: boolean) {
    return async (dispatch: (arg0: { type: string; payload: Partial<ExportPdfInterface> }) => void) => {
        dispatch({
            type: ExportPdfActionTypes.UPDATE_INCLUDE_ANNOTATIONS_WHITEBOARD,
            payload: { isIncludeAnnotation, isIncludeWhiteboard },
        });
    };
}

function startExportPdf() {
    return async (dispatch: (arg0: { type: string; payload: Partial<ExportPdfInterface> }) => void) => {
        dispatch({
            type: ExportPdfActionTypes.START_EXPORT_PDF,
            payload: { isExporting: true, pdfUrl: '' },
        });
    };
}

function pdfExported(pdfUrl: string) {
    return async (dispatch: (arg0: { type: string; payload: Partial<ExportPdfInterface> }) => void) => {
        dispatch({
            type: ExportPdfActionTypes.PDF_EXPORTED,
            payload: { isExporting: false, pdfUrl },
        });
    };
}

function cancelExportPdf() {
    return async (dispatch: (arg0: { type: string }) => void) => {
        dispatch({
            type: ExportPdfActionTypes.CANCEL_EXPORT_PDF,
        });
    };
}
