import { TabContext, TabPanel } from '@mui/lab';

import '../views/ParticipantView.scss';

import { isUserOnTrialOrPro } from '../../../../helpers/userhelper';
import { getValidUserFromStore } from '../../../../helpers/storeHelpers';

import SavedClassForUserInterface from '../../../../interfaces/saved-class-for-user.interface';
import SavedClassParticipantInterface, {
    OrderProperties,
    Orders,
} from '../../../../interfaces/saved-class-participant.interface';
import { SavedClassGroupInterface } from '../../../../interfaces/savedClassGroup.interface';

import ParticipantView from '../views/ParticipantView';
import GroupView from '../views/GroupView';

interface ParticipantTablePropsInterface {
    savedClass: SavedClassForUserInterface;
    setSavedClass: (arg: SavedClassForUserInterface) => void;
    sortedParticipants: SavedClassParticipantInterface[];
    savedClassGroups: SavedClassGroupInterface[];
    refreshSavedClass: (actionType?: string, groupId?: number) => void;
    orderBy: OrderProperties;
    setOrderBy: (arg: OrderProperties) => void;
    order: Orders;
    setOrder: (arg: Orders) => void;
    onParticipantsUpdated: (participants: SavedClassParticipantInterface[]) => void;
    onDeleteSavedClass: () => void;
    activeTabIndex: string;
}

const SavedClassParticipantsTable = ({
    savedClass,
    setSavedClass,
    sortedParticipants,
    savedClassGroups,
    refreshSavedClass,
    orderBy,
    setOrderBy,
    order,
    setOrder,
    onParticipantsUpdated,
    onDeleteSavedClass,
    activeTabIndex,
}: ParticipantTablePropsInterface) => {
    const user = getValidUserFromStore('SavedClassParticipantsTable');
    const isTrialOrPro = isUserOnTrialOrPro(user);

    return (
        <div className={`savedClass participent_table ${!isTrialOrPro && 'basicUser'}`}>
            <div className="inner_table" id="inner_table">
                <TabContext value={activeTabIndex}>
                    <TabPanel value="1" className="savedClasses participantTab">
                        <ParticipantView
                            sortedParticipants={sortedParticipants}
                            savedClassGroups={savedClassGroups}
                            order={order}
                            setOrder={setOrder}
                            orderBy={orderBy}
                            setOrderBy={setOrderBy}
                            savedClass={savedClass}
                            setSavedClass={setSavedClass}
                            onParticipantsUpdated={onParticipantsUpdated}
                            onDeleteSavedClass={onDeleteSavedClass}
                        />
                    </TabPanel>

                    <TabPanel value="2" className="savedClasses groupTab">
                        <GroupView
                            sortedParticipants={sortedParticipants}
                            savedClassId={savedClass.savedClassId}
                            savedClassGroups={savedClassGroups}
                            refreshSavedClass={refreshSavedClass}
                            order={order}
                            setOrder={setOrder}
                            orderBy={orderBy}
                            setOrderBy={setOrderBy}
                        />
                    </TabPanel>
                </TabContext>
            </div>
        </div>
    );
};

export default SavedClassParticipantsTable;
