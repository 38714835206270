export interface AiQuizQuestionReceived {
    question: string;
    options?: string[];
    correct?: number;
    correctAnswer?: string;
    answer?: string;
    bloomTaxonomyLevel: string;
}

export interface AiQuizQuestionInterface {
    question: string;
    options?: string[];
    correct?: number;
    correctAnswer?: string;
    answer?: string;
    bloomTaxonomyLevel: string;
    viewed: boolean;
    showAnswer: boolean;
}

export interface AiQuizSlideInterface {
    questionType: string;
    question: string;
    options?: string[];
    answer: string;
}

export interface AiQuizInterface {
    [slideId: number]: {
        slideText: string;
        slideNote: string;
        questionList: AiQuizQuestionInterface[];
        currentQuestionIndex: number;
        latestQuestionTimestamp: number;
        hasGenerationDone: boolean;
    };
    props: {
        activeSlideId: number;
        activeSlideText: string;
        activeSlideNote: string;
    };
}

export interface SlideIdAndTextInterface {
    currentSlideId: number;
    currentSlideText: string;
    currentSlideNote: string;
}

export enum AiDisplayMode {
    READING_SLIDE = 'READING_SLIDE',
    THINKING = 'THINKING',
    GENERATING = 'GENERATING',
    SHOWING_QUESTION = 'SHOWING_QUESTION',
    LIMIT_REACHED = 'LIMIT_REACHED',
    NOT_ENOUGH_TEXT = 'NOT_ENOUGH_TEXT',
    ERROR_DISPLAY = 'ERROR_DISPLAY',
}
