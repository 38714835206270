import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Grid, Typography } from '@mui/material';

import './ProfileDetails.scss';
import '../Setting.scss';

import { getValidUserFromStore } from '../../../helpers/storeHelpers';
import apiUser from '../../../services/apiUser';
import webviewMessenger from '../../../services/webviewMessenger';
import { userActions } from '../../../actions/user.actions';

import { INPUT_LIMITS, utilConstants } from '../../../constants/utils.constants';
import { UserActionTypes } from '../../../constants/user-action-types';

import CommonButton from '../../../components/Common/CommonButton';
import TextFieldType from '../Profile/Components/TextFieldType';
import JobListType from '../Profile/Components/JobListType';
import SubjectListType from '../Profile/Components/SubjectListType';

const ProfileSchema = yup.object({
    firstName: yup.string().required().max(INPUT_LIMITS.FIRST_LAST_NAME_MAX),
    lastName: yup.string().required().max(INPUT_LIMITS.FIRST_LAST_NAME_MAX),
    organization: yup.string().max(INPUT_LIMITS.ORGANIZATION_MAX),
});

const ProfileDetails = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = getValidUserFromStore('Profile');

    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        setValue,
    } = useForm({
        resolver: yupResolver(ProfileSchema),
        mode: 'onSubmit',
        shouldFocusError: false,
    });

    const [isViewMode, setIsViewMode] = useState(true);
    const [selectedJobRole, setSelectedJobRole] = useState('');
    const [isJobRoleDropdownOpen, setIsJobRoleDropdownOpen] = useState(false);
    const [selectedSubjects, setSelectedSubjects] = useState<string[]>([]);
    const [subjectError, setSubjectError] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        webviewMessenger.sendUsageLog(`Navigated to Settings -> Profile`);
    }, []);

    const loadProfile = () => {
        setSubjectError('');

        const userProfile = user.userProfile;
        const firstName = userProfile.firstName;
        const lastName = userProfile.lastName;
        const organization = userProfile.organization;
        const subjects = userProfile.subjects;

        hookSetValue('firstName', firstName ? firstName : isViewMode ? '-' : '');
        hookSetValue('lastName', lastName ? lastName : isViewMode ? '-' : '');
        hookSetValue('organization', organization ? organization : isViewMode ? '-' : '');
        hookSetValue(
            'subjects',
            subjects && subjects.length > 0 ? getTranslatedSubjects(subjects) : isViewMode ? '-' : '',
        );

        setSelectedJobRole(userProfile.jobRole || '');
        setSelectedSubjects(subjects);
    };

    const getTranslatedSubjects = (subjects: string[]) => {
        return subjects.map((subject) => t(`lang_profile.txt_subject_${subject}`)).join(', ');
    };

    const dropDownClickHandler = () => {
        setIsJobRoleDropdownOpen(!isJobRoleDropdownOpen);
    };

    const handleSelectJobRole = (role: string) => {
        setSelectedJobRole(role);
        dropDownClickHandler();
    };

    const handleCheckUncheckSubject = (subject: string, e: any) => {
        setSubjectError('');
        if (e.target.checked) {
            setSelectedSubjects((subjects) => [...subjects, subject]);
        } else {
            setSelectedSubjects(selectedSubjects.filter((item) => item !== subject));
        }
    };

    const hookSetValue = (inputType: string, inputData: string | string[]) => {
        return setValue(inputType, inputData);
    };

    const checkAndSave = async (data: any) => {
        setIsProcessing(true);
        const userInfo: any = {
            firstName: data.firstName,
            lastName: data.lastName,
            organization: data.organization,
            jobRole: selectedJobRole,
            subjects: selectedSubjects,
        };

        const updatedUser = await apiUser.updateUserProfile(user.email, userInfo);
        setIsProcessing(false);

        if (updatedUser) {
            dispatch(userActions.updateUser(UserActionTypes.USER_PROFILE_EDITED, updatedUser));
            setIsViewMode(true);
        } else {
            return dispatch(userActions.showApiError());
        }
    };

    const profileProgressCalculator = () => {
        const firstName = user.userProfile.firstName || undefined;
        const lastName = user.userProfile.lastName || undefined;
        const organization = user.userProfile.organization || undefined;
        const jobRole = user.userProfile.jobRole || undefined;
        const subjects =
            user.userProfile.subjects && user.userProfile.subjects.length > 0 ? user.userProfile.subjects : undefined;

        const propsArray = [firstName, lastName, organization, jobRole, subjects];
        const count = propsArray.filter((data) => data !== undefined).length;
        return [`${(count / 5) * 100}%`, `done${count}`];
    };

    useEffect(() => {
        loadProfile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, isViewMode]);

    return (
        <form onSubmit={handleSubmit(checkAndSave)} className={`settingsUserProfileDetails`}>
            <div className="modalHead">
                <Typography className={`text`} variant="h2">
                    {t('lang_profile.title')}
                </Typography>
            </div>

            <div className="profile_form">
                <div className="profile_left user_profile_box">
                    <div className="row">
                        <div className="col6">
                            <div className="pf_form">
                                <TextFieldType
                                    label={t('lang_profile.field_label_first_name')}
                                    isViewMode={isViewMode}
                                    register={register}
                                    fieldName="firstName"
                                    inputMaxLength={INPUT_LIMITS.FIRST_LAST_NAME_MAX}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <div className="col6">
                            <div className="pf_form">
                                <TextFieldType
                                    label={t('lang_profile.field_label_last_name')}
                                    isViewMode={isViewMode}
                                    register={register}
                                    fieldName="lastName"
                                    inputMaxLength={INPUT_LIMITS.FIRST_LAST_NAME_MAX}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <div className="col12">
                            <div className="pf_form">
                                <TextFieldType
                                    label={t('lang_profile.field_label_organization')}
                                    isViewMode={isViewMode}
                                    register={register}
                                    fieldName="organization"
                                    inputMaxLength={INPUT_LIMITS.ORGANIZATION_MAX}
                                    errors={errors}
                                    isLocked={user.userSubscription.planType === utilConstants.PLAN_TYPE.ORGANIZATION}
                                    lockedValueDisplay={user.userProfile.organization}
                                />
                            </div>
                        </div>
                        <div className="col12 profile jobRoleContainer">
                            <div
                                onClick={() => {
                                    setIsJobRoleDropdownOpen(false);
                                }}
                                className={`${isJobRoleDropdownOpen && 'show'} jobRoleOverlay`}
                            ></div>
                            <div className="pf_form">
                                <JobListType
                                    isViewMode={isViewMode}
                                    dropDownOpen={isJobRoleDropdownOpen}
                                    selectedJobRole={selectedJobRole}
                                    dropDownClickHandler={dropDownClickHandler}
                                    handleSelectJobRole={handleSelectJobRole}
                                />
                            </div>
                        </div>
                        {isViewMode ? (
                            <div className="col12">
                                <div className="pf_form">
                                    <TextFieldType
                                        label={t('lang_profile.field_label_subjects')}
                                        isViewMode={true}
                                        register={register}
                                        fieldName="subjects"
                                        errors={errors}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="subject_sec">
                                <Grid item xs={12}>
                                    <SubjectListType
                                        isViewMode={isViewMode}
                                        selectedSubjects={selectedSubjects}
                                        onCheckUncheckSubject={handleCheckUncheckSubject}
                                    />
                                </Grid>
                                {subjectError && <Typography className="error_span">{subjectError}</Typography>}
                            </div>
                        )}
                    </div>

                    {isViewMode && (
                        <div className={`progressContainer ${profileProgressCalculator()[1]}`}>
                            <p className={`label`}>
                                {t('lang_profile.txt_profile_completeness', {
                                    percentage: profileProgressCalculator()[0],
                                })}
                            </p>
                            <div className={`progressBar`}>
                                <div className={`progressPart`}></div>
                                <div className={`progressPart`}></div>
                                <div className={`progressPart`}></div>
                                <div className={`progressPart`}></div>
                                <div className={`progressPart`}></div>
                            </div>
                        </div>
                    )}

                    <div
                        className={`profile_update_btn flex ${profileProgressCalculator()[1]} ${
                            !isViewMode && 'editMode'
                        }`}
                    >
                        {isViewMode ? (
                            <CommonButton
                                isLoading={false}
                                variant="contained"
                                className="Edit profile"
                                clickFunction={(e: any) => {
                                    e.preventDefault();
                                    setIsViewMode(false);
                                }}
                                color="primary"
                                text={t('lang_profile.btn_edit_profile')}
                            />
                        ) : (
                            <CommonButton
                                isLoading={isProcessing}
                                variant="contained"
                                className="Edit profile"
                                type="submit"
                                color="primary"
                                text={isProcessing ? t('lang_common.btn_saving') : t('lang_common.btn_save')}
                            />
                        )}
                        {!isViewMode && (
                            <CommonButton
                                isLoading={false}
                                variant="contained"
                                className="cancel"
                                color="primary"
                                text={t('lang_common.btn_cancel')}
                                clickFunction={() => {
                                    setIsViewMode(true);
                                    loadProfile();
                                    clearErrors();
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ProfileDetails;
