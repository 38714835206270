import * as Sentry from '@sentry/react';
import { HostLogType } from '../constants/host-log-types.enum';
import { ReducerName } from '../constants/reducer-name.enum';
import { getCpVer, createLogMessage } from '../helpers/utils';
import webviewMessenger from './webviewMessenger';

export enum SentryTypes {
    API_ERROR = 'API_ERROR',
    SIGNALR_ERROR = 'SIGNALR_ERROR',
    COMPONENT_ERROR = 'COMPONENT_ERROR',
}

export const sentryLogApiError = (
    error: any,
    transactionName: string,
    context?: { query?: any; params?: any; body?: any },
) => {
    Sentry.configureScope((scope) => scope.setTransactionName(transactionName));
    const tags = {
        cpVer: getCpVer(),
        category: SentryTypes.API_ERROR,
        transactionName,
        statusCode: error.response?.status || undefined,
    };
    Sentry.setTags(tags);
    Sentry.setContext('query', context?.query);
    Sentry.setContext('params', context?.params);
    Sentry.setContext('body', context?.body);
    if (error.response) Sentry.setContext('responseData', error.response.data);
    Sentry.captureException(error);
    const payload: any = {};
    if (context?.query) payload.query = context.query;
    if (context?.params) payload.params = context.params;
    if (context?.body) payload.body = context.body;
    if (error.response) payload.responseData = error.response.data;
    try {
        webviewMessenger.sendHostLog(
            HostLogType.ERROR,
            `[${tags.cpVer}][${tags.category}][${tags.transactionName}][${tags.statusCode}] payload: ${createLogMessage(
                payload,
            )}, error: ${error}`,
        );
    } catch (ex) {
        webviewMessenger.sendHostLog(
            HostLogType.DEBUG,
            `webviewMessenger.sendHostLog: sentryLogApiError message exception`,
        );
    }
};

export const sentryLogSignalrError = (error: any, transactionName: string, payload: any) => {
    Sentry.configureScope((scope) => scope.setTransactionName(transactionName));
    const tags = {
        cpVer: getCpVer(),
        category: SentryTypes.SIGNALR_ERROR,
        transactionName,
    };
    Sentry.setTags(tags);
    Sentry.setContext('payload', payload);
    Sentry.captureException(error);
    const cachedClassSession = localStorage.getItem(ReducerName.classSession);
    const isCachedConnectionNull = !!localStorage.getItem(ReducerName.connection);
    try {
        webviewMessenger.sendHostLog(
            HostLogType.ERROR,
            `[${tags.cpVer}][${tags.category}][${tags.transactionName}] payload: ${createLogMessage(
                payload,
            )}, cachedClassSession: ${createLogMessage(
                cachedClassSession,
            )}, isCachedConnectionNull: ${isCachedConnectionNull.toString()}, error: ${error}`,
        );
    } catch (ex) {
        webviewMessenger.sendHostLog(
            HostLogType.DEBUG,
            `webviewMessenger.sendHostLog: sentryLogSignalrError message exception`,
        );
    }
};

export const sentryLogComponentError = (error: any, transactionName: string, payload: any) => {
    Sentry.configureScope((scope) => scope.setTransactionName(transactionName));
    const tags = {
        cpVer: getCpVer(),
        category: SentryTypes.COMPONENT_ERROR,
        transactionName,
    };
    Sentry.setTags(tags);
    Sentry.setContext('payload', payload);
    Sentry.captureException(error);
    try {
        webviewMessenger.sendHostLog(
            HostLogType.ERROR,
            `[${tags.cpVer}][${tags.category}][${tags.transactionName}] payload: ${createLogMessage(payload)}`,
        );
    } catch (ex) {
        webviewMessenger.sendHostLog(
            HostLogType.DEBUG,
            `webviewMessenger.sendHostLog: sentryLogComponentError message exception`,
        );
    }
};
