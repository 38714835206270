import { Fragment } from 'react';
import { motion } from 'framer-motion';
import { FitToViewport } from 'react-fit-to-viewport';
import { Box, Modal } from '@mui/material';
import { SimpleFadeAnimation } from '../../constants/animation-configs';

interface CommonAlertProps {
    showAlert: boolean;
    closeAlert?: () => void;
    content: any;
    viewportConfig: any;
    customModalClassNames?: string;
    customBoxClassNames?: string;
}

const CommonAlert = ({
    showAlert,
    closeAlert,
    content,
    viewportConfig,
    customModalClassNames,
    customBoxClassNames,
}: CommonAlertProps) => {
    return (
        <Fragment>
            <Modal
                open={showAlert}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={`mymodal ${customModalClassNames}`}
                onClose={() => closeAlert && closeAlert()}
            >
                <Fragment>
                    <FitToViewport
                        className="modal_viewport"
                        width={viewportConfig.width}
                        height={viewportConfig.height}
                        minZoom={viewportConfig.minZoom}
                        maxZoom={viewportConfig.maxZoom}
                    >
                        <Fragment>
                            <div className="close_modal" onClick={closeAlert}></div>
                            <Box
                                component={motion.div}
                                variants={SimpleFadeAnimation}
                                initial="initial"
                                animate="animate"
                                className={`modal_box myclass_alert ${customBoxClassNames}`}
                            >
                                {content}
                            </Box>
                        </Fragment>
                    </FitToViewport>
                </Fragment>
            </Modal>
        </Fragment>
    );
};

export default CommonAlert;
