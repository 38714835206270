import { CloseSubmissionDto } from './../dtos/close-submission.dto';
import { localService } from './../services/localStorageService';
import { sentryLogSignalrError, sentryLogApiError } from './../services/sentryService';
import { convertHostMsgToActivityProps } from './../helpers/activityHelpers';
import { getActivityFromStore, getValidUserFromStore } from './../helpers/storeHelpers';
import { classSessionActions, invokeHubWithRetry } from './class-session.action';
import webviewMessenger from '../services/webviewMessenger';
// import _ from 'lodash';
import { store } from '../helpers/store';
import { ActivityActionTypes } from '../constants/activity-action-types';
import {
    calculateParticipantsAndPointsForQuiz,
    calculateQuizResponseAndPointsDto,
    generateActivityId,
    generateParticipantsAndPoints,
} from '../helpers/activityHelpers';
import { utilConstants } from '../constants/utils.constants';
import ActivityResponseInterface from '../interfaces/activity-response.interface';
import apiActivities from '../services/apiActivities';
import GivePointsToResponsesDto from '../dtos/give-points-to-responses.dto';
import ResponseAndPointsDto from '../dtos/response-and-points.dto';
import { ActivityPropsInterface } from '../interfaces/activity-props.interface';
import { UserActionTypes } from '../constants/user-action-types';
import ActivityInterface from '../interfaces/activity.interface';
import { logger } from '../services/logger';
import { UpdateToolbarActionsType } from '../dtos/update-toolbar-actions.dto';
import apiClassSessions from '../services/apiClassSessions';
import { HostLogType } from '../constants/host-log-types.enum';
import { generateHubBaseUrl, getCpcsRegion } from '../helpers/classSessionHelpers';

export const activityActions = {
    handleCloseAndEndOngoingActivity,
    startActivity,
    closeSubmission,
    endActivity,
    restartActivity,
    deleteResponses,
    viewActivityInSlideshow,
    viewActivityInEdit,
    deleteIndividualResponses,
    startVoting,
    stopVoting,
    dismissEndActivityWarningModal,
    dismissEndVotingWarningModal,
    handleGivePointsToResponses,
    clearActivity,
};

function startActivity(msgData: any, restartActivityId: string | null = null) {
    return async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
        await handleCloseAndEndOngoingActivity(dispatch);
        localService.removeCachedResponses();
        const user = getValidUserFromStore('startActivity');

        if (restartActivityId !== null) msgData.activityId = restartActivityId;
        const activityProps = await convertHostMsgToActivityProps({ ...msgData, email: user.email });
        const payload = { ...activityProps, email: user.email };

        logger.warn('signalrStartActivity', payload);
        const invokeHub = async () => {
            const connection = store.getState().connection;
            await connection.invoke('PresenterStartActivity', payload);
            if (restartActivityId) {
                webviewMessenger.sendUsageLog(
                    `[S] Activity restarted successfully: ${payload.activityType} ${
                        payload.isQuizMode ? '(Quiz mode)' : ''
                    }`,
                );
                dispatch({
                    type: ActivityActionTypes.RESTART_ACTIVITY,
                    payload: activityProps,
                });
            } else {
                webviewMessenger.sendUsageLog(
                    `[S] Activity started successfully: ${payload.activityType} ${
                        payload.isQuizMode ? '(Quiz mode)' : ''
                    } (activityId: ${payload.activityId})`,
                );
                dispatch({
                    type: ActivityActionTypes.START_ACTIVITY,
                    payload: activityProps,
                });
            }
        };
        const invokeHubOnError = (error: any) => {
            logger.error('signalrStartActivity error', error);
            sentryLogSignalrError(error, 'PresenterStartActivity', payload);
            webviewMessenger.sendResetActivityV2(activityProps.activityId, true);
            dispatch({ type: UserActionTypes.SHOW_API_ERROR });
        };
        await invokeHubWithRetry(invokeHub, invokeHubOnError);
    };
}

function handleCloseAndEndOngoingActivity(dispatch: any) {
    const { activityProps, activityMode } = getActivityFromStore();
    if (activityProps && activityMode === utilConstants.ACTIVITY_MODE.START_ACTIVITY) {
        console.log('Closing and ending ongoing activity...');
        return new Promise((resolve, reject) => {
            dispatch(closeSubmission());
            dispatch(endActivity(true, true));
            resolve(true);
        });
    }
}

function handleGivePointsToResponses(responsesAndPoints: ResponseAndPointsDto[]) {
    return async (dispatch: any) => {
        localService.updateToolbarActionsFromWebview(UpdateToolbarActionsType.awardStar);
        const user = getValidUserFromStore('handleGivePointsToResponses');
        const classSession = store.getState().classSession;
        const activity = getActivityFromStore();

        if (!activity.activityProps) return;

        if (activity.activityMode === utilConstants.ACTIVITY_MODE.START_ACTIVITY) {
            // console.log('activity submission not closed');
            const participantsAndPoints = generateParticipantsAndPoints(responsesAndPoints);
            dispatch(classSessionActions.givePointsToParticipants(participantsAndPoints, false));
            givePointsToResponses(responsesAndPoints, dispatch);
            const dto: GivePointsToResponsesDto = {
                email: user.email,
                responsesAndPoints,
            };
            const invokeHub = async () => {
                const connection = store.getState().connection;
                await connection.invoke('GivePointsToResponses', dto);
            };
            const invokeHubOnError = (error: any) => {
                sentryLogSignalrError(error, 'GivePointsToResponses', dto);
            };
            await invokeHubWithRetry(invokeHub, invokeHubOnError);
        } else {
            // if there's signalr, and the participant belongs to the current class, we add point
            const connection = store.getState().connection;
            if (connection && classSession) {
                const responsesInCurrentClass = responsesAndPoints.filter((item) =>
                    classSession.participantList.map((p) => p.participantId).includes(item.response.participantId),
                );
                // console.log('responsesInCurrentClass', responsesInCurrentClass);

                if (responsesInCurrentClass.length > 0) {
                    const participantsAndPoints = generateParticipantsAndPoints(responsesAndPoints);
                    dispatch(classSessionActions.givePointsToParticipants(participantsAndPoints, true));
                }
                givePointsToResponses(responsesAndPoints, dispatch);
                apiActivities.givePointsToResponses(
                    activity.activityProps.activityId,
                    user.email,
                    responsesAndPoints,
                    false,
                    classSession.classSessionId,
                    classSession.savedClassId || undefined,
                    activity.cachedBy || [],
                );
            } else {
                givePointsToResponses(responsesAndPoints, dispatch);
                apiActivities.givePointsToResponses(
                    activity.activityProps.activityId,
                    user.email,
                    responsesAndPoints,
                    true,
                    activity.classSessionId,
                    activity.savedClassId || undefined,
                    activity.cachedBy || [],
                );
            }
        }
    };
}

function closeSubmission() {
    return async (dispatch: any) => {
        localService.removeCachedResponses();
        const user = getValidUserFromStore('closeSubmission');
        const { activityProps, activityResponses } = getActivityFromStore();
        if (!activityProps) return;

        const payload: CloseSubmissionDto = { email: user.email };
        if (activityProps.activityType === utilConstants.ACTIVITY_TYPE.MULTIPLE_CHOICE && activityProps.isQuizMode) {
            const participantsAndPoints = calculateParticipantsAndPointsForQuiz(activityProps, activityResponses);
            if (participantsAndPoints.length > 0) {
                const quizResponsesAndPoints = calculateQuizResponseAndPointsDto(activityProps, activityResponses);
                payload.quizResponsesAndPoints = quizResponsesAndPoints;
            }
        }

        // APPROACH A: first we try to close submission through signalr. If failed, close submission through api then try to reconnect signalr
        try {
            const connection = store.getState().connection;
            await connection.invoke('PresenterCloseSubmission', payload);
            webviewMessenger.sendHostLog(
                HostLogType.DEBUG,
                `PresenterCloseSubmission through signalr successful: ${activityProps.activityId}`,
            );
            dispatch({ type: ActivityActionTypes.CLOSE_SUBMISSION });
            // Host will change button to green and upload the activity slide to backend if there's at least one submission
            if (activityProps && activityProps.activityType !== utilConstants.ACTIVITY_TYPE.QUICK_POLL)
                webviewMessenger.sendCloseSubmission(activityProps.activityId, activityResponses.length > 0);
        } catch (error) {
            try {
                const cpcsRegion = getCpcsRegion(user);
                if (!cpcsRegion) return;
                const hubBaseUrl = generateHubBaseUrl(cpcsRegion);
                await apiClassSessions.closeSubmissionThroughApi(hubBaseUrl, payload);
                webviewMessenger.sendHostLog(
                    HostLogType.DEBUG,
                    `PresenterCloseSubmission through api successful: ${activityProps.activityId}`,
                );
                dispatch({ type: ActivityActionTypes.CLOSE_SUBMISSION });
                if (activityProps && activityProps.activityType !== utilConstants.ACTIVITY_TYPE.QUICK_POLL)
                    webviewMessenger.sendCloseSubmission(activityProps.activityId, activityResponses.length > 0);
            } catch (error: any) {
                logger.error('closeSubmissionThroughApi() error: ', error.response || error);
                sentryLogApiError(error, 'closeSubmissionThroughApi', {
                    body: { payload },
                });
                webviewMessenger.sendHostLog(
                    HostLogType.ERROR,
                    `PresenterCloseSubmission through api failed: ${activityProps.activityId}`,
                );
                dispatch({ type: ActivityActionTypes.CLOSE_SUBMISSION });
                webviewMessenger.sendResetActivityV2(activityProps.activityId, true);
            }
        }

        // APPROACH B: Close submission through api
        // try {
        //     const cpcsRegion = getCpcsRegion(user);
        //     if (!cpcsRegion) return;
        //     const hubBaseUrl = generateHubBaseUrl(cpcsRegion);
        //     await apiClassSessions.closeSubmissionThroughApi(hubBaseUrl, payload);
        //     dispatch({ type: ActivityActionTypes.CLOSE_SUBMISSION });
        //     // Host will change button to green and upload the activity slide to backend if there's at least one submission
        //     if (activityProps && activityProps.activityType !== utilConstants.ACTIVITY_TYPE.QUICK_POLL)
        //         webviewMessenger.sendCloseSubmission(activityProps.activityId, activityResponses.length > 0);
        // } catch (error) {
        //     logger.error('closeSubmission error', error);
        //     sentryLogApiError(error, 'PresenterCloseSubmission', { body: payload });
        //     dispatch({ type: ActivityActionTypes.CLOSE_SUBMISSION });
        //     webviewMessenger.sendResetActivityV2(activityProps.activityId, true);
        // }
    };
}

function endActivity(isSubmissionClosed = false, isKeepOpen = false, isClosingDialog = false) {
    return async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
        const user = getValidUserFromStore('endActivity');
        const activity = getActivityFromStore();

        if (
            !isSubmissionClosed &&
            activity.activityMode === utilConstants.ACTIVITY_MODE.START_ACTIVITY &&
            activity.activityProps
        ) {
            if (activity.activityResponses.length > 0) {
                if (isClosingDialog) {
                    dispatch({
                        type: ActivityActionTypes.TOGGLE_END_ACTIVITY_WARNING_MODAL,
                        payload: true,
                    });
                    return;
                }
            }
            if (activity.activityProps.activityType !== utilConstants.ACTIVITY_TYPE.QUICK_POLL)
                webviewMessenger.sendResetActivity(activity.activityProps.activityId);
        } else if (activity.isVoting) {
            if (isClosingDialog) {
                dispatch({
                    type: ActivityActionTypes.TOGGLE_END_VOTING_WARNING_MODAL,
                    payload: true,
                });
                return;
            }
            stopVoting();
        }

        localService.removeCachedResponses();
        dispatch({
            type: ActivityActionTypes.END_ACTIVITY,
        });
        if (!isKeepOpen) webviewMessenger.sendHideWebview();

        const payload = { email: user.email };
        const invokeHub = async () => {
            const connection = store.getState().connection;
            if (!connection) return;
            await connection.invoke('PresenterEndActivity', payload);
        };
        const invokeHubOnError = (error: any) => {
            logger.error(error);
            sentryLogSignalrError(error, 'PresenterEndActivity', payload);
        };
        await invokeHubWithRetry(invokeHub, invokeHubOnError);
    };
}

function restartActivity() {
    return async (dispatch: ((arg0: { type: string; payload: any }) => void) | any) => {
        const user = getValidUserFromStore('restartActivity');
        const activityUser = getActivityFromStore().activityUser;
        const activity = store.getState().activity;
        const activityProps = activity.activityProps as ActivityPropsInterface;
        const oldActivityId = activityProps.activityId;
        const restartActivityId = generateActivityId(activityProps.activityType, activityProps.isQuizMode || false);
        webviewMessenger.sendRestartActivity(oldActivityId, restartActivityId);
        dispatch(startActivity(activityProps, restartActivityId));
        // if activityUser doesn't exist, this is a new activity. when restarting the activity, delete the old activity saved in DB
        if (!activityUser && activity.activityResponses.length > 0)
            apiActivities.httpDeleteActivity(oldActivityId, user.email);
    };
}

function deleteResponses(activityId: string, manualDeleteThroughButton = true) {
    return async (dispatch: ((arg0: { type: string; payload: any }) => void) | any) => {
        if (!activityId) return;

        webviewMessenger.sendResetActivity(activityId);
        const user = getValidUserFromStore('deleteResponses');
        const activityUser = getActivityFromStore().activityUser;
        if (manualDeleteThroughButton) dispatch(endActivity(false));
        dispatch({
            type: ActivityActionTypes.DELETE_ACTIVITY,
        });
        // if activityUser doesn't exist, this is a new activity. directly perform delete;
        if (!activityUser) apiActivities.httpDeleteActivity(activityId, user.email);
    };
}

function viewActivityInSlideshow(msgData: any) {
    return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
        const user = getValidUserFromStore('viewActivityInSlideshow');
        await handleCloseAndEndOngoingActivity(dispatch);
        const activityData = await apiActivities.getActivity(msgData.activityId, user.email);
        const activityProps = await convertHostMsgToActivityProps({ ...msgData });
        let payload: ActivityInterface = {
            activityResponses: [],
            activityProps,
            activityMode: utilConstants.ACTIVITY_MODE.VIEW_ACTIVITY_IN_SLIDESHOW,
        };
        if (activityData && activityData.activityResponses)
            payload = {
                ...payload,
                activityResponses: activityData.activityResponses,
                activityUser: activityData.user,
                activityVersion: activityData.activityVersion,
                classSessionId: activityData.classSessionId,
                savedClassId: activityData.savedClassId,
                cachedBy: activityData.cachedBy,
            };
        webviewMessenger.sendUsageLog(`[S] Viewed activity in PPT slideshow: ${payload.activityProps?.activityType}`);
        dispatch({
            type: ActivityActionTypes.LOAD_ACTIVITY,
            payload,
        });
    };
}

function viewActivityInEdit(msgData: any) {
    return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
        const user = getValidUserFromStore('viewActivityInEdit');
        const activityData = await apiActivities.getActivity(msgData.activityId, user.email);
        const activityProps = await convertHostMsgToActivityProps({ ...msgData });
        let payload: ActivityInterface = {
            activityResponses: [],
            activityProps,
            activityMode: utilConstants.ACTIVITY_MODE.VIEW_ACTIVITY_IN_EDIT,
        };
        if (activityData && activityData.activityResponses)
            payload = {
                ...payload,
                activityResponses: activityData.activityResponses,
                activityUser: activityData.user,
                activityVersion: activityData.activityVersion,
                classSessionId: activityData.classSessionId,
                savedClassId: activityData.savedClassId,
                cachedBy: activityData.cachedBy,
            };
        webviewMessenger.sendUsageLog(`[S] Viewed activity in PPT edit mode: ${payload.activityProps?.activityType}`);
        dispatch({
            type: ActivityActionTypes.LOAD_ACTIVITY,
            payload,
        });
    };
}

function deleteIndividualResponses(responses: ActivityResponseInterface[]) {
    // console.log('responses to delete', responses);
    return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
        const user = getValidUserFromStore('deleteIndividualResponses');
        const activity: any = store.getState().activity;
        if (activity.activityMode === utilConstants.ACTIVITY_MODE.START_ACTIVITY)
            await signalrDeleteIndividualResponses(responses);
        else {
            if (activity.activityResponses.length === responses.length)
                apiActivities.httpDeleteActivity(activity.activityProps.activityId, user.email);
            else
                apiActivities.httpDeleteIndividualResponses(
                    activity.activityProps.activityId,
                    user.email,
                    responses.map((r) => r.responseId),
                    activity.cachedBy || [],
                );
        }

        const updatedActivityResponses = activity.activityResponses.filter(
            (r: any) => !responses.map((res) => res.responseId).includes(r.responseId),
        );
        if (activity.activityMode === utilConstants.ACTIVITY_MODE.START_ACTIVITY) {
            const responseParticipantIds = updatedActivityResponses.map((response: any) => response.participantId);
            calculateUniqueParticipantCountAndNotifyHost(responseParticipantIds);
        }
        dispatch({
            type: ActivityActionTypes.DELETE_INDIVIDUAL_RESPONSES,
            payload: updatedActivityResponses,
        });
    };
}

function startVoting(numOfVotesToGive: any, selectedResponses: any, isVotingNamesHidden: boolean) {
    return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
        const user: any = store.getState().user;
        const activity: any = store.getState().activity;
        const connection: any = store.getState().connection;
        try {
            const payload = {
                email: user.email,
                activityId: activity.activityProps.activityId,
                numOfVotesToGive: parseInt(numOfVotesToGive),
                selectedResponseIds: selectedResponses.map((r: any) => r.responseId),
                isNamesHidden: true,
            };

            await connection.invoke('PresenterStartVoting', payload);

            const updatedActivityResponses = [...activity.activityResponses];
            updatedActivityResponses.forEach((r) => (r.voterParticipantIds = []));

            dispatch({
                type: ActivityActionTypes.START_VOTING,
                payload: updatedActivityResponses,
            });
            webviewMessenger.sendVotingAction('start');
        } catch (e) {
            console.log(e);
        }
    };
}

function stopVoting() {
    return async (dispatch: (arg0: { type: string }) => void) => {
        const connection: any = store.getState().connection;
        const user: any = store.getState().user;
        try {
            dispatch({
                type: ActivityActionTypes.STOP_VOTING,
            });
            await connection.invoke('PresenterStopVoting', {
                email: user.email,
            });
            webviewMessenger.sendVotingAction('stop');
        } catch (e) {
            console.log(e);
        }
    };
}

function dismissEndActivityWarningModal() {
    return async (dispatch: ((arg0: { type: string; payload: any }) => void) | any) => {
        dispatch({
            type: ActivityActionTypes.TOGGLE_END_ACTIVITY_WARNING_MODAL,
            payload: false,
        });
    };
}

const calculateUniqueParticipantCountAndNotifyHost = (participantIdsWithDuplicates: any) => {
    const uniqueParticipantCount = participantIdsWithDuplicates.filter(
        (element: any, index: any, array: any) => array.indexOf(element) === index,
    ).length;
    webviewMessenger.sendActivityOrVotingParticipantCount(uniqueParticipantCount);
};

const signalrDeleteIndividualResponses = async (responses: ActivityResponseInterface[]) => {
    const user = getValidUserFromStore('signalrDeleteIndividualResponses');
    const payload = {
        email: user.email,
        responses: responses.map((r) => ({
            participantId: r.participantId,
            responseId: r.responseId,
        })),
    };
    const invokeHub = async () => {
        const connection = store.getState().connection;
        await connection.invoke('PresenterDeletesIndividualResponses', payload);
    };
    const invokeHubOnError = (error: any) => {
        sentryLogSignalrError(error, 'PresenterDeletesIndividualResponses', payload);
    };
    await invokeHubWithRetry(invokeHub, invokeHubOnError);
};

function dismissEndVotingWarningModal() {
    return async (dispatch: ((arg0: { type: string; payload: any }) => void) | any) => {
        dispatch({
            type: ActivityActionTypes.TOGGLE_END_VOTING_WARNING_MODAL,
            payload: false,
        });
    };
}

const givePointsToResponses = (responsesAndPoints: ResponseAndPointsDto[], dispatch: any) => {
    const activity = getActivityFromStore();
    const updatedActivityResponses: ActivityResponseInterface[] = JSON.parse(
        JSON.stringify(activity.activityResponses),
    );
    responsesAndPoints.forEach((item) => {
        const targetIndex = updatedActivityResponses.findIndex((r) => r.responseId === item.response.responseId);
        if (targetIndex >= 0) updatedActivityResponses[targetIndex].responsePoints += item.points;
    });
    // console.log('updatedActivityResponses', updatedActivityResponses);
    dispatch({
        type: ActivityActionTypes.GIVE_POINTS_TO_RESPONSES,
        payload: updatedActivityResponses,
    });
};

function clearActivity() {
    return async (dispatch: ((arg0: { type: string; payload: any }) => void) | any) => {
        dispatch({
            type: ActivityActionTypes.CLEAR_ACTIVITY,
            payload: null,
        });
    };
}
