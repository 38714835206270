import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import CommonButton from '../../components/Common/CommonButton';
import { utilConstants } from '../../constants/utils.constants';
import './ErrorPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import StoreInterface from '../../interfaces/store.interface';
import { useNavigate } from 'react-router-dom';
import { userActions } from '../../actions/user.actions';
import { UserActionTypes } from '../../constants/user-action-types';
import { HostEventTypes } from '../../constants/host-event-types';
import { isCpVerAtLeast } from '../../helpers/utils';

const ErrorPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const store = useSelector((state: StoreInterface) => state);

    const handleReloadOnError = () => {
        const user = store.user;
        if (user) return window.location.reload();

        let shouldSignOutFirst = true;
        const above17Ver = isCpVerAtLeast('2.0.18');
        if (above17Ver) shouldSignOutFirst = false;
        if (shouldSignOutFirst)
            dispatch(userActions.unloadUser(UserActionTypes.USER_MANUALLY_SIGNED_OUT, HostEventTypes.SIGNED_OUT));
        localStorage.setItem('nullUser', 'true');
        navigate('/loading');
        window.location.reload();
    };

    return (
        <div className="profile_sec2">
            <div className="errorBoundary_sec">
                <div className="errorBound_img">
                    <img src={utilConstants.IMAGE_URLS.error_blundary_image} alt="brandlogo" />
                </div>

                <div className="error_content">
                    <Typography variant="h2" className="red">
                        {t('lang_common.heading_err_boundary')}
                    </Typography>

                    <Typography variant="h3">{t('lang_common.txt_err_boundary')}</Typography>
                    <CommonButton
                        isLoading={false}
                        variant="contained"
                        type="button"
                        clickFunction={handleReloadOnError}
                        disabled={false}
                        text={t('lang_common.btn_reload')}
                    />
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;
