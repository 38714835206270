import Lottie from 'react-lottie-player';
import { utilConstants } from '../../../constants/utils.constants';
import shareClassPointAnimation from '../../../assets/animation/shareClassPointAnimation.json';
import { UpgradeToProButton } from '../UpgradeToProButton';
import { useTranslation } from 'react-i18next';
import { getValidUserFromStore } from '../../../helpers/storeHelpers';
import { getNewUserDiscount } from '../../../helpers/userhelper';
// import NewUserDiscountModalIndonesia from './NewUserDiscountModalIndonesia';

interface DiscountModalInterface {
    onHideDiscountModal: () => void;
    userEmail: string;
}

function NewUserDiscountModal({ onHideDiscountModal, userEmail }: DiscountModalInterface) {
    const { t } = useTranslation();
    const user = getValidUserFromStore('NewUserDiscountModal');
    const discount = getNewUserDiscount(user);

    if (!discount) return null;

    // if (user.userProfile.countryCode === 'ID')
    //     return <NewUserDiscountModalIndonesia discount={discount} onHideDiscountModal={onHideDiscountModal} />;

    return (
        <div className={`modal discount_modal`}>
            <div className="back_drop" onClick={onHideDiscountModal}></div>
            <div className="modal_box">
                <div className="modal_body">
                    <div className={`discount top`}>
                        <img src={utilConstants.SETTING.DISCOUNT} alt="icon" />
                        <p className="text">{t('lang_new_discount.new_user_discount_modal_title').toUpperCase()}</p>
                    </div>
                    <div className="discount_link">
                        <Lottie
                            className="purlePapperPlan"
                            loop={true}
                            animationData={shareClassPointAnimation}
                            play
                            speed={0.8}
                        />
                        <div className="inner_content">
                            <div className="info">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            discount.daysLeft > 1
                                                ? t('lang_new_discount.new_user_discount_modal_message_days', {
                                                      interpolation: { escapeValue: false },
                                                      percentage: discount.discountPercentage,
                                                      days: discount.daysLeft,
                                                  })
                                                : t('lang_new_discount.new_user_discount_modal_message_day', {
                                                      interpolation: { escapeValue: false },
                                                      percentage: discount.discountPercentage,
                                                      days: discount.daysLeft,
                                                  }),
                                    }}
                                />
                            </div>
                            <UpgradeToProButton caller={`NewUserDiscountModal`} email={userEmail} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewUserDiscountModal;
