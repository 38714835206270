import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import styles from './QuestionCardFooter.module.scss';

import { userActions } from '../../../../actions/user.actions';
import { classSessionActions } from '../../../../actions/class-session.action';
import { updateQnaState } from '../../../../reducers/qna.reducer';
import { getCpcsRegion } from '../../../../helpers/classSessionHelpers';
import { getValidUserFromStore } from '../../../../helpers/storeHelpers';
import { localService } from '../../../../services/localStorageService';
import apiClassSessions from '../../../../services/apiClassSessions';

import ParticipantAndPointsDto from '../../../../dtos/participant-and-points.dto';
import { UpdateToolbarActionsType } from '../../../../dtos/update-toolbar-actions.dto';
import StoreInterface from '../../../../interfaces/store.interface';
import { QnaActionType, QnaInterface } from '../../../../interfaces/qna.interface';
import { AnimationConfig } from '../../../../constants/animation-configs';

import GivePointButton from '../../../../components/Common/GivePointButton';
import CustomAvatar, { AvatarType } from '../../../../components/Common/CustomAvatar';
import { FullScreenPointLottie } from '../../../../components/Common/AnimationModules';
import Loading from '../../../activity/Loading/Loading';

interface QuestionCardFooterInterface {
    qna: QnaInterface;
    isModalView?: boolean;
}

const QuestionCardFooter = ({ qna, isModalView }: QuestionCardFooterInterface) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const user = getValidUserFromStore('QuestionCardFooter');
    const cpcsRegion = getCpcsRegion(user);
    const { activeTab, newQnas, answeredQnas, selectedQuestionIndex, savedClassGroups } = useSelector(
        (state: StoreInterface) => state.qna,
    );

    const timeOutPointUpdate = useRef<any>();
    const givePointsConfettiRef = useRef<any>();

    const [isAnimating, setIsAnimating] = useState(false);
    const [pointsAdded, setPointsAdded] = useState(0);
    const [markAnswerLoading, setMarkAnswerLoading] = useState<number[]>([]);
    const [dismissLoading, setDismissLoading] = useState<number[]>([]);

    const activeTabData = activeTab === 'new' ? newQnas : answeredQnas;

    const decideVisibleQuestion = () => {
        if (activeTabData.length > 1) {
            // if there are more than one question in the list, go to the next one unless this is the last one
            if (selectedQuestionIndex !== activeTabData.length - 1) {
                return updateQnaState(QnaActionType.DELETE_QNA, { selectedQuestionIndex: selectedQuestionIndex });
            } else {
                return updateQnaState(QnaActionType.DELETE_QNA, {
                    selectedQuestionIndex: selectedQuestionIndex - 1,
                });
            }
        } else return updateQnaState(QnaActionType.DELETE_QNA, { selectedQuestionIndex: -1 });
    };

    const markAnsweredHandler = async (questionId: number) => {
        setMarkAnswerLoading((prev) => [...prev, questionId]);
        const success = await apiClassSessions.markAnsweredQna(cpcsRegion, user.email, questionId);
        setMarkAnswerLoading((prev) => prev.filter((id) => id !== questionId));
        if (!success) return dispatch(userActions.showApiError());
    };

    const deleteQuestionHandler = async (questionId: number) => {
        setDismissLoading((prev) => [...prev, questionId]);
        if (isModalView) decideVisibleQuestion();
        const success = await apiClassSessions.deleteQna(cpcsRegion, user.email, questionId);
        setDismissLoading((prev) => prev.filter((id) => id !== questionId));
        if (!success) return dispatch(userActions.showApiError());
    };

    const givePoint = (points: number) => {
        if (!qna) return;
        const participantsAndPoints: ParticipantAndPointsDto[] = [{ participantId: qna.participantId, points: points }];
        localService.updateToolbarActionsFromWebview(UpdateToolbarActionsType.awardStar);
        dispatch(classSessionActions.givePointsToParticipants(participantsAndPoints));
    };

    const givePointButtonClickHandler = () => {
        clearTimeout(timeOutPointUpdate.current);
        setIsAnimating(true);
        givePointsConfettiRef.current.playLottieAnimation(true);
        setPointsAdded(pointsAdded + 1);
    };

    useEffect(() => {
        if (pointsAdded > 0) {
            timeOutPointUpdate.current = setTimeout(() => {
                givePoint(pointsAdded);
                setPointsAdded(0);
                setTimeout(() => {
                    setIsAnimating(false);
                    if (givePointsConfettiRef.current) givePointsConfettiRef.current.playLottieAnimation(false);
                }, AnimationConfig.CONFETTI_LOTTIE_DURATION - AnimationConfig.GIVE_POINT_DELAY);
            }, AnimationConfig.GIVE_POINT_DELAY);
        }
        return () => clearTimeout(timeOutPointUpdate.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pointsAdded]);

    useEffect(() => {
        return () => setIsAnimating(false);
    }, []);

    if (!qna) return <Loading />;
    return (
        <div className={`${styles.questionCardFooter} ${isModalView && styles.modalView}`}>
            {createPortal(
                <div className={`${styles.qnaPointsOverlay} ${isAnimating && !isModalView && styles.show}`}>
                    <FullScreenPointLottie ref={givePointsConfettiRef} />
                </div>,
                document.body,
            )}

            <div className={styles.inner_questionCardFooter}>
                {!isModalView && (
                    <div className={styles.profile_foot}>
                        <div className={styles.avater_box}>
                            <CustomAvatar
                                avatarName={qna.participantName}
                                avatarUrl={qna.participantAvatar}
                                type={AvatarType.PARTICIPANT}
                                savedClassGroups={savedClassGroups}
                                groupId={qna.groupId}
                            />
                        </div>
                        <Typography className={styles.text} variant="body1">
                            {qna.participantName}
                        </Typography>
                    </div>
                )}

                <div className={`${styles.right_side} ${isModalView && styles.modalView}`}>
                    <div className={`${styles.pointsBtn}`}>
                        <GivePointButton
                            activity="questionAndAnswer"
                            number={null}
                            onClick={() => givePointButtonClickHandler()}
                        />
                    </div>

                    <LoadingButton
                        loading={markAnswerLoading.includes(qna.id)}
                        onClick={() => markAnsweredHandler(qna.id)}
                        className={`${styles.answer_mark} ${qna.isAnswered && styles.answered}`}
                        variant="outlined"
                    >
                        {qna.isAnswered && (
                            <i className={styles.icon}>
                                <CheckCircleIcon />
                            </i>
                        )}
                        {markAnswerLoading.includes(qna.id)
                            ? ''
                            : qna.isAnswered
                            ? t(`lang_qna.answered`)
                            : t(`lang_qna.mark_answered`)}
                    </LoadingButton>

                    <LoadingButton
                        loading={dismissLoading.includes(qna.id)}
                        onClick={() => deleteQuestionHandler(qna.id)}
                        className={styles.dismiss}
                        variant="outlined"
                    >
                        {dismissLoading.includes(qna.id) ? '' : t(`lang_qna.dismiss`)}
                    </LoadingButton>
                </div>
            </div>
        </div>
    );
};

export default QuestionCardFooter;
