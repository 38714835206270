interface GetEncodedDataInterface {
    shareUrl: string;
    title: string;
    message?: string;
    hashtag: string;
    hashtags: string[];
    source?: string;
}

export enum SOCIAL_MEDIA_Types {
    WHATSAPP = 'whatsapp',
    FACEBOOK = 'facebook',
    TWITTER = 'twitter',
    LINKEDIN = 'linkedin',
}

export interface SOCIAL_MEDIA_Props {
    type: SOCIAL_MEDIA_Types;
}

export const getEncodedData = (data: GetEncodedDataInterface, type: SOCIAL_MEDIA_Types) => {
    const { shareUrl, title, message, hashtag, hashtags, source } = data;

    const encodedUrl = encodeURIComponent(shareUrl);
    const encodedTitle =
        type === SOCIAL_MEDIA_Types.TWITTER
            ? encodeURIComponent(title + ' @classpointio\n')
            : encodeURIComponent(title + '\n');
    const encodedMessage = message ? encodeURIComponent(message + '\n') : '';
    const encodedHashtags = hashtags.map((tag) => encodeURIComponent(tag)).join(',');
    const encodedSource = source && encodeURIComponent(source);

    const urlObject = {
        [SOCIAL_MEDIA_Types.WHATSAPP]: `https://wa.me/?text=${encodedTitle}%0A${encodedUrl}%0A${encodedMessage}`,
        [SOCIAL_MEDIA_Types.FACEBOOK]: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            shareUrl,
        )}&title=${encodeURIComponent(title)}&hashtag=%23${encodeURIComponent(hashtag)}`,
        [SOCIAL_MEDIA_Types.TWITTER]: `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}&hashtags=${encodedHashtags}`,
        [SOCIAL_MEDIA_Types.LINKEDIN]: `https://www.linkedin.com/shareArticle?url=${encodedUrl}&title=${encodedTitle}&summary=${encodedMessage}&source=${encodedSource}`,
    };

    return urlObject[type];
};
