import Lottie from 'react-lottie-player';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import emptyface from '../../assets/animation/empty-face.json';
import { Fragment } from 'react';

interface NoParticipantInClassInterface {
    content?: string;
    children?: React.ReactNode;
}

const NoParticipantInClass = ({ content, children }: NoParticipantInClassInterface) => {
    const { t } = useTranslation();

    const lottieDecider = () => {
        switch (content) {
            case 'pickNamesAllOffline':
                return <Lottie loop={true} animationData={emptyface} play speed={1} />;
            default:
                return <Lottie loop={true} animationData={emptyface} play speed={1} />;
        }
    };

    const textDecider = () => {
        switch (content) {
            case 'pickNamesAllOffline':
                return <Typography variant="h5">{t('lang_pick_names.txt_all_participants_are_offline')}</Typography>;
            case 'quizSummary':
                return <Typography variant="h5">{t('lang_activity.no_quiz_activities_found')}</Typography>;
            default:
                return <Typography variant="h5">{t('lang_leaderboard.err_no_participant_in_class')}</Typography>;
        }
    };

    return (
        <Fragment>
            <div className="leaderboard_wating">
                {lottieDecider()}
                {textDecider()}
                {children}
            </div>
        </Fragment>
    );
};

export default NoParticipantInClass;
