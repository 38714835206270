export const VideoTutorials = [
    {
        sectionId: 'gamification',
        videoIds: [
            'overview_gamification',
            'create_class',
            'use_class',
            'join_class',
            'manage_class',
            'award_stars',
            'customize_levels',
            'leaderboard',
        ],
    },
    {
        sectionId: 'presentation_tools',
        videoIds: [
            'overview_presentation_tools',
            'annotations',
            'whiteboard',
            'draggable_objects',
            'timer',
            'embedded_browser',
            'name_picker',
            'share_pdf',
        ],
    },
    {
        sectionId: 'interactive_quizzes',
        videoIds: [
            'overview_interactive_quiz_female',
            'multiple_choice',
            'quiz_mode',
            'word_cloud',
            'short_answer',
            'slide_drawing',
            'image_upload',
            'fill_blanks',
            'audio_record',
            'video_upload',
            'quick_poll',
        ],
    },
];
