import { Fragment, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StoreInterface from '../../../interfaces/store.interface';
import { timerStopWatchActions } from '../../../actions/timer-stopwatch.action';
import { getCurrentTime } from '../../../helpers/timerHelpers';
import webviewMessenger from '../../../services/webviewMessenger';
import TimerSetter from './TimerSetter';
import TimerFullscreenReset from './TimerFullscreenReset';
import TimerRunning from './TimerRunning';
import { localService } from '../../../services/localStorageService';
import { UpdateToolbarActionsType } from '../../../dtos/update-toolbar-actions.dto';

interface SetTimerInterface {
    timerValue: number;
    setTimerValue: any;
    isRunning: boolean;
    setIsRunning: (arg: boolean) => void;
    setShowSettings: (arg: boolean) => void;
    setShowTab: (args: boolean) => void;
    currentTimeRef: any;
    setIsPaused: (arg: boolean) => void;
}

const Timer = ({
    timerValue,
    setTimerValue,
    isRunning,
    setIsRunning,
    setShowSettings,
    setShowTab,
    currentTimeRef,
    setIsPaused,
}: SetTimerInterface) => {
    const dispatch = useDispatch();
    const timerStopwatch = useSelector((state: StoreInterface) => state.timerStopwatch);
    const fullScreenBtnRef = useRef<any>();

    const [isTimesup, setIsTimesup] = useState(false);
    const [isPlaySound, setIsPlaySound] = useState(false);

    const startTimer = () => {
        if (timerValue <= 0) return;
        else {
            const now = new Date();
            now.setSeconds(now.getSeconds() + timerValue + 1);
            const timerEndTime = now.getTime();
            setIsPaused(false);
            setIsRunning(true);
            setShowTab(false);
            dispatch(timerStopWatchActions.startTimer(timerEndTime));
            localService.updateToolbarActionsFromWebview(UpdateToolbarActionsType.startTimer);
            webviewMessenger.sendUsageLog(`[S] Started timer from: ${timerValue}s`);
        }
    };

    const playPauseTimer = () => {
        const currentTime = getCurrentTime();
        if (!timerStopwatch.isTimerPaused) {
            setIsPaused(true);
            return dispatch(timerStopWatchActions.pauseTimer(currentTime));
        } else if (timerStopwatch.isTimerPaused) {
            const resumeTime = timerStopwatch.timerEndTime + (currentTime - timerStopwatch.timerPauseTime);
            setIsPaused(false);
            return dispatch(timerStopWatchActions.resumeTimer(resumeTime));
        }
    };

    const increaseTime = () => {
        if (timerValue < 60) return setTimerValue((prev: number) => prev + 1);
        else if (timerValue % 30 === 0) return setTimerValue((prev: number) => Math.min(5999, prev + 30));
        else {
            const nextRequiredValue = (Math.floor(timerValue / 30) + 1) * 30;
            return setTimerValue((prev: number) => prev + (nextRequiredValue - timerValue));
        }
    };

    const decreaseTime = () => {
        if (timerValue <= 60) return setTimerValue((prev: number) => prev - 1);
        else if (timerValue % 30 === 0) return setTimerValue((prev: number) => prev - 30);
        else {
            const previoudRequiredValue = Math.floor(timerValue / 30) * 30;
            return setTimerValue((prev: number) => prev - (timerValue - previoudRequiredValue));
        }
    };

    const resetTimer = () => {
        webviewMessenger.sendUsageLog(`[S] Reset timer`);
        setIsPaused(true);
        setIsRunning(false);
        dispatch(timerStopWatchActions.resetTimer());
    };

    const enterFullScreen = () => {
        dispatch(timerStopWatchActions.maximizeTimerWindow());
        webviewMessenger.timerFullScreenSize();
    };

    const exitFullScreen = () => {
        dispatch(timerStopWatchActions.minimizeTimerWindow());
        webviewMessenger.timerDefaultSize();
    };

    useEffect(() => {
        if (timerStopwatch.isTimesup) {
            setIsPlaySound(true);
        }
    }, [timerStopwatch.isTimesup]);

    useEffect(() => {
        dispatch(timerStopWatchActions.setTimeLimit(timerValue));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timerValue]);

    useEffect(() => {
        if (timerStopwatch.isWindowMaximized) {
            const keyDownHandler = (event: any) => {
                if (event.key === 'Escape') fullScreenBtnRef.current.click();
            };
            document.addEventListener('keydown', keyDownHandler);
            return () => document.removeEventListener('keydown', keyDownHandler);
        }
    }, [timerStopwatch.isWindowMaximized]);

    useEffect(() => {
        if (isTimesup) {
            setIsPaused(true);
            setIsRunning(false);
            setShowTab(!timerStopwatch.isWindowMaximized);
            setIsTimesup(false);
            setIsPlaySound(false);
            dispatch(timerStopWatchActions.resetTimer());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTimesup]);

    return (
        <Fragment>
            {!isRunning ? (
                timerStopwatch.isWindowMaximized ? (
                    <TimerFullscreenReset
                        isFullScreen={timerStopwatch.isWindowMaximized}
                        startTimer={startTimer}
                        showDefaultScreenSize={exitFullScreen}
                        showFullScreenSize={enterFullScreen}
                        setIsTimesup={setIsTimesup}
                        setShowTab={setShowTab}
                        fullScreenBtnRef={fullScreenBtnRef}
                        isPlaySound={isPlaySound}
                        setIsPlaySound={setIsPlaySound}
                    />
                ) : (
                    <TimerSetter
                        timerValue={timerValue}
                        setTimerValue={setTimerValue}
                        startTimer={startTimer}
                        increaseTime={increaseTime}
                        decreaseTime={decreaseTime}
                        setShowSettings={setShowSettings}
                    />
                )
            ) : isTimesup ? (
                <TimerSetter
                    timerValue={timerValue}
                    setTimerValue={setTimerValue}
                    startTimer={startTimer}
                    increaseTime={increaseTime}
                    decreaseTime={decreaseTime}
                    setShowSettings={setShowSettings}
                />
            ) : (
                <TimerRunning
                    isFullScreen={timerStopwatch.isWindowMaximized}
                    playPauseTimer={playPauseTimer}
                    resetTimer={resetTimer}
                    exitFullScreen={exitFullScreen}
                    enterFullScreen={enterFullScreen}
                    setIsTimesup={setIsTimesup}
                    setShowTab={setShowTab}
                    fullScreenBtnRef={fullScreenBtnRef}
                    currentTimeRef={currentTimeRef}
                    isPlaySound={isPlaySound}
                    setIsPlaySound={setIsPlaySound}
                />
            )}
        </Fragment>
    );
};

export default Timer;
