import { useEffect, useState } from 'react';
import { Reorder, AnimatePresence } from 'framer-motion';
import { Tab } from './Tab';
import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';
import { BrowserTabsActionTypes } from '../../../../constants/browser-tabs-action-types';
import { useDispatch } from 'react-redux';
import { BrowserTabInterface } from '../../../../interfaces/browser-tabs-interface';

export default function TabReorder({ browserTabs, closeTab, openNewTab, switchToTab }: any) {
    const dispatch = useDispatch();
    const [tabs, setTabs] = useState<BrowserTabInterface[]>([]);
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    const [selectedTabId, setSelectedTabId] = useState(0);

    useEffect(() => {
        setTabs([...browserTabs]);
    }, [browserTabs]);

    const remove = (id: number) => {
        closeTab(id);
    };

    const reorderTab = (tabs: BrowserTabInterface[]) => {
        dispatch({
            type: BrowserTabsActionTypes.SWITCH_BROWSER_TAB,
            payload: tabs,
        });
        setTabs([...tabs]);
    };

    // console.log('tabs', tabs);
    return (
        <div className="control_tab_section">
            {/* <nav className='control_tab_sec'> */}
            <Reorder.Group
                as="ul"
                axis="x"
                onReorder={(newReorderItems) => {
                    reorderTab(newReorderItems);
                }}
                className="tab-sections"
                values={tabs}
            >
                <AnimatePresence initial={false}>
                    {tabs.map((tab) => (
                        <Tab
                            key={tab.tabId}
                            item={tab}
                            isSelected={selectedTabId === tab.tabId}
                            onClick={() => switchToTab(tab)}
                            onRemove={() => remove(tab.tabId)}
                        />
                    ))}
                </AnimatePresence>
                <IconButton color="primary" size="small" onClick={() => openNewTab('/browser/start')}>
                    <AddIcon fontSize="inherit" />
                </IconButton>
            </Reorder.Group>
        </div>
    );
}
