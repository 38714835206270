import { Slider, Typography, Button, Link } from '@mui/material';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseIcon from '@mui/icons-material/Pause';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FullscreenTwoToneIcon from '@mui/icons-material/FullscreenTwoTone';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { useEffect, useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import SubtitlesOffOutlinedIcon from '@mui/icons-material/SubtitlesOffOutlined';
import { useDispatch, useSelector } from 'react-redux';
import StoreInterface from '../../../interfaces/store.interface';
import apiUser from '../../../services/apiUser';
import { InterfaceLanguages } from '../../../constants/languages';
import { useTranslation } from 'react-i18next';
import DoneIcon from '@mui/icons-material/Done';
import UserInterface from '../../../interfaces/user-interface';
import { CP_CONTENT_URL } from '../../../constants/utils.constants';
import { tutorialSettingsActions } from '../../../actions/tutorial-settings.action';
import loaderAnimation from '../../../assets/animation/87884-loader.json';
import Lottie from 'react-lottie-player';
import webviewMessenger from '../../../services/webviewMessenger';

interface TutorialVideoPlayerInterface {
    user: UserInterface;
    activeVideoId: string;
    videoInfo: any;
    videoDuration: number;
    isFullscreen: boolean;
    setIsFullscreen: (arg: boolean) => void;
    isFullScreenHidden?: boolean;
    isAutoPlay?: boolean;
    isSubtitlesHidden?: boolean;
}

interface VideoStateInterface {
    isPlaying: boolean;
    playedSeconds: number;
    duration: number;
    volume: number;
    muted: boolean;
    showVolumeSlider: boolean;
    isInFullScreen: boolean;
}

function TutorialVideoPlayer({
    user,
    activeVideoId,
    videoInfo,
    videoDuration,
    isFullscreen,
    setIsFullscreen,
    isFullScreenHidden,
    isAutoPlay,
    isSubtitlesHidden,
}: TutorialVideoPlayerInterface) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const tutorialSettings = useSelector((state: StoreInterface) => state.tutorialSettings);

    const boxSizeRef = useRef<any>();
    const playerRef = useRef<any>();
    const textTracksRef = useRef<any>();
    const playBtnRef = useRef<any>();
    const slideRef = useRef<any>();

    const [videoState, setVideoState] = useState<VideoStateInterface>({
        isPlaying: false,
        playedSeconds: 0,
        duration: 0,
        volume: 0.35,
        muted: false,
        showVolumeSlider: false,
        isInFullScreen: false,
    });
    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const [isVideoLoading, setIsVideoLoading] = useState(false);
    const [play, setPlay] = useState(false);
    const [markWatchedApiCalled, setMarkWatchedApiCalled] = useState(false);

    const captionLang = tutorialSettings.tutorialCaptionLang;
    const currentLang = user.userPreferences?.language || 'en';

    let timeout: ReturnType<typeof setTimeout>;
    const showCenterIcon = () => {
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            setPlay(false);
        }, 500);
    };

    const playPauseVideo = () => {
        const selectedElement: any = document.getElementsByClassName('react-player__preview');
        if (selectedElement[0] && !isVideoLoaded) {
            selectedElement[0].click();
            setIsVideoLoaded(true);
        }

        if (!videoState.isPlaying) {
            webviewMessenger.sendUsageLog(`Played video at ${videoState.playedSeconds}s`);
        } else {
            if (videoState.playedSeconds >= videoState.duration)
                webviewMessenger.sendUsageLog(`Finished video tutorial`);
            else webviewMessenger.sendUsageLog(`Paused video at ${videoState.playedSeconds}s`);
        }

        setVideoState((prevState) => {
            return { ...prevState, isPlaying: !prevState.isPlaying };
        });

        if (!videoState.isPlaying && videoState.playedSeconds >= videoState.duration) {
            setVideoState((prevState) => {
                return { ...prevState, playedSeconds: 0 };
            });
        }
    };

    const muteUnmute = () => {
        muteUnmuteVideoVolume(!videoState.muted);
        setVideoState((prevState) => {
            return { ...prevState, muted: !prevState.muted };
        });
    };

    const showVolume = () => {
        setVideoState((prevState) => {
            return { ...prevState, showVolumeSlider: true };
        });
    };

    const hideVolume = () => {
        setTimeout(() => {
            setVideoState((prevState) => {
                return { ...prevState, showVolumeSlider: false };
            });
        }, 300);
    };

    const changeVolume = (value: number) => {
        if (typeof value === 'number') {
            setVideoState((prevState) => {
                return { ...prevState, volume: value / 100 };
            });
            dispatch(tutorialSettingsActions.adjustTutorialVolume(value / 100));
        }
        if (value > 0) {
            setVideoState((prevState) => {
                return { ...prevState, muted: false };
            });
            muteUnmuteVideoVolume(false);
        } else {
            setVideoState((prevState) => {
                return { ...prevState, muted: true };
            });
            muteUnmuteVideoVolume(true);
        }
    };

    const muteUnmuteVideoVolume = (isMuted: boolean) => {
        dispatch(tutorialSettingsActions.muteUnmuteVideoVolume(isMuted));
    };

    const updateVideoProgress = (state: any) => {
        setVideoState((prevState) => {
            return {
                ...prevState,
                playedSeconds: state.playedSeconds,
            };
        });
    };

    const playCurrent = (value: number | number[]) => {
        const selectedElement: any = document.getElementsByClassName('react-player__preview');
        if (selectedElement[0] && !isVideoLoaded) {
            selectedElement[0].click();
            setTimeout(() => {
                setIsVideoLoaded(true);
                playerRef.current.seekTo(value);
                slideRef.current.click();
            }, 300);
        }
        typeof value === 'number' &&
            setVideoState((prevState) => {
                return {
                    ...prevState,
                    playedSeconds: value,
                };
            });
        playerRef.current.seekTo(value);
        webviewMessenger.sendUsageLog(`Seeked to progress: ${value}s`);
    };

    const chooseSubtitle = (lang: string) => {
        dispatch(tutorialSettingsActions.updateTutorialCaptionLang(lang));
        setIsContextMenuOpen(!isContextMenuOpen);
    };

    const renderSubtitleOptions = () => {
        return (
            <ul id="listId">
                <li onClick={() => chooseSubtitle('')}>
                    <Link>
                        {!captionLang && (
                            <i className="tick">
                                <DoneIcon />
                            </i>
                        )}{' '}
                        {t('lang_video_tutorial.subtitle_off')}
                    </Link>
                </li>
                {currentLang !== 'en' && (
                    <li onClick={() => chooseSubtitle('en')}>
                        <Link>
                            {captionLang === 'en' && (
                                <i className="tick">
                                    <DoneIcon />
                                </i>
                            )}{' '}
                            English (EN)
                        </Link>
                    </li>
                )}
                {showCurrentLanguageOption()}
            </ul>
        );
    };

    const showCurrentLanguageOption = () => {
        const languageFound = InterfaceLanguages.find((language) => language.code === currentLang);
        const captionFound = videoInfo[`lang_${currentLang}`] !== undefined;
        if (languageFound && captionFound) {
            return (
                <li onClick={() => chooseSubtitle(languageFound.code)}>
                    <Link>
                        {languageFound.code === captionLang && (
                            <i className="tick">
                                <DoneIcon />
                            </i>
                        )}{' '}
                        {languageFound.name} ({languageFound.code.toUpperCase()})
                    </Link>
                </li>
            );
        }
    };

    const getSubtitleTracks = () => {
        const trackProps: any = [];
        Object.keys(videoInfo).forEach((key) => {
            if (key.startsWith('lang_')) {
                const lang = key.slice(5);
                trackProps.push({
                    kind: 'subtitles',
                    src: `./assets/videos/${activeVideoId}/${activeVideoId}_${lang}.vtt`,
                    srcLang: lang,
                    default: false,
                    label: lang,
                });
            }
        });
        return trackProps;
    };

    const setVideoAsWatched = async () => {
        const watchedTutorials = user.userStats.watchedTutorials;
        if (!watchedTutorials?.includes(activeVideoId) && !markWatchedApiCalled) {
            await apiUser.addWatchedTutorial(user.email, activeVideoId);
            setMarkWatchedApiCalled(true);
        }
    };

    useEffect(() => {
        setVideoState({
            isPlaying: false,
            playedSeconds: 0,
            duration: 0,
            volume: tutorialSettings.videoTutorialVolume,
            muted: tutorialSettings.isTutorialVideoMuted,
            showVolumeSlider: false,
            isInFullScreen: false,
        });
        setIsVideoLoaded(true);
        setIsVideoLoading(false);
        setTimeout(() => {
            const selectedElement: any = document.getElementsByClassName('react-player__preview');
            if (selectedElement[0]) {
                selectedElement[0].addEventListener('click', function () {
                    setIsVideoLoaded(true);
                });
            }
        }, 100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeVideoId]);

    useEffect(() => {
        setVideoState((prevState) => ({ ...prevState, duration: videoDuration }));
    }, [videoDuration]);

    useEffect(() => {
        if (playerRef.current) {
            const textTracks = playerRef.current.getInternalPlayer()?.textTracks;
            for (var i = 0; textTracks?.length && i < textTracks.length; i++) {
                // For the 'subtitles-off' button, the first condition will never match so all will subtitles be turned off
                if (textTracks[i].language === captionLang) {
                    textTracks[i].mode = 'showing';
                } else {
                    textTracks[i].mode = 'hidden';
                }
            }
            textTracksRef.current = textTracks;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playerRef.current, captionLang]);

    useEffect(() => {
        // if language is changed, update caption language unless no subtitle is selected (fallback to English)
        if (!captionLang) return;
        dispatch(
            tutorialSettingsActions.updateTutorialCaptionLang(
                videoInfo[`lang_${currentLang}`] === undefined ? 'en' : currentLang,
            ),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLang]);

    useEffect(() => {
        isAutoPlay && playPauseVideo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div
                className={isFullscreen ? 'video_backdrop fullScreenVideo' : 'video_backdrop'}
                onClick={() => setIsFullscreen(!isFullscreen)}
            ></div>

            <div className={isFullscreen ? 'display_section fullScreenVideo' : 'display_section'}>
                <div ref={boxSizeRef} className="big_sh_content">
                    <div
                        className="sh_content_video"
                        onContextMenu={(e) => e.preventDefault()}
                        onClick={() => {
                            if (isVideoLoaded) {
                                setPlay(true);
                                showCenterIcon();
                                playBtnRef.current.click();
                                setIsVideoLoaded(true);
                            }
                        }}
                    >
                        {isVideoLoading && (
                            <div className="video_loader">
                                <Lottie loop={true} animationData={loaderAnimation} play speed={1} />
                            </div>
                        )}

                        <ReactPlayer
                            url={`${CP_CONTENT_URL}/tutorial/${activeVideoId}.mp4`}
                            key={activeVideoId}
                            className={
                                videoState.isInFullScreen
                                    ? 'react-player video_upload videoFullView'
                                    : `react-player video_upload`
                            }
                            controls={false}
                            playing={videoState.isPlaying}
                            volume={videoState.volume}
                            muted={videoState.muted}
                            progressInterval={100}
                            onProgress={(state) => {
                                if (state.playedSeconds >= videoState.playedSeconds) updateVideoProgress(state);
                                if (state.playedSeconds >= videoDuration - 10) setVideoAsWatched();
                            }}
                            onEnded={playPauseVideo}
                            ref={(el) => (playerRef.current = el)}
                            onReady={() => setIsVideoLoading(false)}
                            config={{ file: { tracks: getSubtitleTracks() } }}
                            onBuffer={() => setIsVideoLoading(true)}
                            onBufferEnd={() => setIsVideoLoading(false)}
                            light={!isAutoPlay && `${CP_CONTENT_URL}/tutorial/${activeVideoId}_thumb.webp`}
                        />

                        <div
                            className={
                                videoState.isPlaying
                                    ? play
                                        ? 'center_play show'
                                        : 'center_play'
                                    : play
                                    ? 'center_play show'
                                    : 'center_play'
                            }
                        >
                            {videoState.isPlaying ? <PlayArrowRoundedIcon /> : <PauseIcon />}
                        </div>
                    </div>

                    <div className={`videoControls`}>
                        <div onClick={playPauseVideo} className="centerIcon show" ref={playBtnRef}>
                            {videoState.isPlaying ? <PauseIcon /> : <PlayArrowRoundedIcon />}
                        </div>

                        <Slider
                            className="slider"
                            onChange={(e, value) => playCurrent(value)}
                            min={0}
                            max={videoState.duration}
                            value={videoState.playedSeconds}
                            ref={slideRef}
                        />

                        <Typography className="time">
                            {new Date((videoState.duration - videoState.playedSeconds) * 1000)
                                .toISOString()
                                .substring(14, 19)}
                        </Typography>

                        <div className="volumeSlider">
                            <Slider
                                orientation="vertical"
                                className={`slider`}
                                value={videoState.volume * 100}
                                onChange={(e, value) => typeof value === 'number' && changeVolume(value)}
                            />
                            <div onMouseOver={showVolume} onMouseLeave={hideVolume} className="controlIcon">
                                {videoState.muted ? (
                                    <VolumeOffIcon onClick={muteUnmute} />
                                ) : (
                                    <VolumeUpIcon onClick={muteUnmute} />
                                )}
                            </div>
                        </div>

                        {!isSubtitlesHidden && (
                            <div className="option">
                                <Button
                                    id="basic-button"
                                    aria-controls="basic-menu"
                                    aria-haspopup="true"
                                    aria-expanded="true"
                                    onClick={() => setIsContextMenuOpen(!isContextMenuOpen)}
                                    className={captionLang ? 'textCaption' : ''}
                                >
                                    {captionLang ? (
                                        <span>{captionLang.toUpperCase()}</span>
                                    ) : (
                                        <SubtitlesOffOutlinedIcon />
                                    )}
                                </Button>
                                <div className="menu_modal">
                                    <div
                                        className={`menu_overlay ${isContextMenuOpen ? 'active' : ''}`}
                                        onClick={() => setIsContextMenuOpen(!isContextMenuOpen)}
                                    ></div>
                                    <div className={`menu ${isContextMenuOpen ? 'active' : ''}`}>
                                        {renderSubtitleOptions()}
                                    </div>
                                </div>
                            </div>
                        )}

                        {!isFullScreenHidden && (
                            <div className="fullScreen">
                                {!isFullscreen ? (
                                    <FullscreenTwoToneIcon
                                        onClick={() => {
                                            setIsFullscreen(!isFullscreen);
                                        }}
                                        className="controlIcon"
                                    />
                                ) : (
                                    <FullscreenExitIcon
                                        onClick={() => {
                                            setIsFullscreen(!isFullscreen);
                                        }}
                                        className="controlIcon"
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default TutorialVideoPlayer;
