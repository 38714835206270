import { Typography } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import SavedClassForUserInterface from '../../../interfaces/saved-class-for-user.interface';
import SavedClassAvatar from './SavedClassAvatar';

interface SavedClassInfoSummaryInterface {
    savedClass: SavedClassForUserInterface;
    setSavedClass: (arg: SavedClassForUserInterface) => void;
}

const SavedClassInfoSummary = ({ savedClass, setSavedClass }: SavedClassInfoSummaryInterface) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div className="edit_profile_wrapper">
                <div className="edit_profile_info">
                    <div className="upload_profile_images">
                        <label htmlFor="icon-button-file">
                            <SavedClassAvatar savedClass={savedClass} setSavedClass={setSavedClass} />
                        </label>
                    </div>
                </div>
                <div className="profile_info_content">
                    <Typography variant="body1">{t('lang_common.txt_class_name')}</Typography>
                    <Typography variant="h4">{savedClass.savedClassName}</Typography>
                    <Typography variant="body1">{t('lang_common.txt_class_code')}</Typography>

                    <div className="chip">
                        {savedClass.savedClassCode ? savedClass.savedClassCode : t('lang_myclass.opt_txt_random_code')}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SavedClassInfoSummary;
