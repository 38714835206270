import { ClassSessionActionTypes } from '../constants/class-session-action-types';
import { ReducerName } from '../constants/reducer-name.enum';
import { store } from '../helpers/store';
import { getCachedReducer } from '../helpers/storeHelpers';
import { QnaActionType, QnaStateInterface } from '../interfaces/qna.interface';
import { localService } from '../services/localStorageService';

const initialQnaState: QnaStateInterface = {
    activeTab: 'new',
    newQnas: [],
    answeredQnas: [],
    selectedQuestionIndex: -1,
    savedClassGroups: [],
};

export default function qna(
    state = getCachedQnaState(),
    action: {
        type: ClassSessionActionTypes | QnaActionType;
        payload: Partial<QnaStateInterface>;
    },
): QnaStateInterface {
    switch (action.type) {
        case QnaActionType.REFRESH_QNA:
        case QnaActionType.QNA_UPDATED:
        case QnaActionType.CHANGE_TAB:
        case QnaActionType.OPEN_MODAL:
        case QnaActionType.NAVIGATE_IN_MODAL:
        case QnaActionType.CLOSE_MODAL:
        case QnaActionType.DELETE_QNA:
            return { ...state, ...action.payload };
        case ClassSessionActionTypes.TRIGGER_RESTART_CLASS:
        case ClassSessionActionTypes.END_SLIDESHOW:
            return initialQnaState;
        default:
            return state;
    }
}

export function updateQnaState(type: QnaActionType, payload: Partial<QnaStateInterface>) {
    return store.dispatch({ type, payload });
}

const getCachedQnaState = (): QnaStateInterface => {
    const qnaCached: QnaStateInterface | null = getCachedReducer(ReducerName.qna);
    if (qnaCached) return { ...initialQnaState, ...qnaCached };

    const storeCached = localService.getStoreData();
    if (storeCached && storeCached.qna) return { ...initialQnaState, ...storeCached.qna };

    return initialQnaState;
};
