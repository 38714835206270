import { Fragment } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import webviewMessenger from '../../../services/webviewMessenger';

const AddNewSavedClassCard = ({ changeCurrentPage }: { changeCurrentPage: (arg: string) => void }) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div
                className="addClass_box"
                onClick={() => {
                    changeCurrentPage('AddClass');
                    webviewMessenger.sendUsageLog('Clicked "Add new class" card');
                }}
            >
                <div className="addClass_icon">
                    <AddIcon />
                </div>
                <div className="addClass_text">
                    <Typography variant="h5">{t('lang_saved_classes.txt_add_new_class')}</Typography>
                </div>
            </div>
        </Fragment>
    );
};

export default AddNewSavedClassCard;
