import { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Highlighter from 'react-highlight-words';

import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';

import { searchKeywordToArray } from '../../helpers/activityHelpers';
import { getValidUserFromStore } from '../../helpers/storeHelpers';
import { getLevelsArray, calculateParticipantLevelAndBadge } from '../../helpers/userhelper';
import { localService } from '../../services/localStorageService';
import webviewMessenger from '../../services/webviewMessenger';
import { classSessionActions } from '../../actions/class-session.action';

import { UpdateToolbarActionsType } from '../../dtos/update-toolbar-actions.dto';
import ParticipantAndPointsDto from '../../dtos/participant-and-points.dto';
import ParticipantInterface from '../../interfaces/participant.interface';
import { AnimationConfig } from '../../constants/animation-configs';
import { utilConstants } from '../../constants/utils.constants';

import CustomAvatar, { AvatarType } from '../../components/Common/CustomAvatar';
import { MyClassGivePointsLottie } from '../../components/Common/AnimationModules';
import AddDeductStarButton from './AddDeductStarButton';
import { LevelUpAnimation } from './LevelUpAnimation';

import { SavedClassGroupInterface } from '../../interfaces/savedClassGroup.interface';

interface ParticipantCardInterface {
    savedClassGroups: SavedClassGroupInterface[];
    participant: ParticipantInterface;
    removeParticipant?: (arg: ParticipantInterface) => void;
    searchTextHighlight?: string;
    isOrderChanged?: boolean;
}

const ParticipantCard = ({
    savedClassGroups,
    participant,
    removeParticipant,
    searchTextHighlight,
    isOrderChanged,
}: ParticipantCardInterface) => {
    const dispatch = useDispatch();
    const user = getValidUserFromStore('ParticipantCard');

    const sparkleRef = useRef<any>();
    const timeOutPointUpdate = useRef<any>();
    const timeOutLevelUpAnimation = useRef<any>();
    const firstTimeLevelUpAnimation = useRef(true);

    const getCurrentLevel = calculateParticipantLevelAndBadge(
        participant.participantTotalPoints + participant.participantPoints,
        getLevelsArray(user),
    ).level;

    const [pointsAdded, setPointsAdded] = useState(0);
    const [isPointDeducting, setIsPointDeducting] = useState(false);
    const [isPointAdding, setIsPointAdding] = useState(false);
    const [currentLevel, setCurrentLevel] = useState(getCurrentLevel);
    const [lastLevel, setLastLevel] = useState(getCurrentLevel);
    const [isLevellingUp, setIsLevellingUp] = useState(false);
    const [showAnimation, setShowAnimation] = useState(false);

    const updateParticipantPoint = (points: number) => {
        const participantsAndPoints: ParticipantAndPointsDto[] = [
            { participantId: participant.participantId, points: points },
        ];
        localService.updateToolbarActionsFromWebview(
            points > 0 ? UpdateToolbarActionsType.awardStar : UpdateToolbarActionsType.deductStar,
        );
        dispatch(classSessionActions.givePointsToParticipants(participantsAndPoints));
    };

    const updatePointHandler = (isAdding: boolean) => {
        clearTimeout(timeOutPointUpdate.current);
        !isAdding ? setIsPointDeducting(true) : setIsPointAdding(true);
        if (sparkleRef.current && isAdding) sparkleRef.current.playLottieAnimation(true);
        setPointsAdded(pointsAdded + (isAdding ? 1 : -1));
    };

    useEffect(() => {
        if (pointsAdded !== 0) {
            timeOutPointUpdate.current = setTimeout(() => {
                updateParticipantPoint(pointsAdded);
                setShowAnimation(true);
                setPointsAdded(0);
                setTimeout(() => {
                    if (sparkleRef.current) sparkleRef.current.playLottieAnimation(false);
                    setIsPointDeducting(false);
                    setIsPointAdding(false);
                }, AnimationConfig.MYCLASS_SPARKLE_LOTTIE_DURATION - AnimationConfig.GIVE_POINT_DELAY);
            }, AnimationConfig.GIVE_POINT_DELAY);
        }

        return () => {
            clearTimeout(timeOutPointUpdate.current);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            clearTimeout(timeOutLevelUpAnimation.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pointsAdded]);

    useEffect(() => {
        if (!firstTimeLevelUpAnimation.current && showAnimation) {
            setCurrentLevel(getCurrentLevel);
            setIsLevellingUp(true);
            setTimeout(() => {
                setIsLevellingUp(false);
                setLastLevel(getCurrentLevel);
                setShowAnimation(false);
            }, AnimationConfig.LEVEL_UP_ANIMATION_DURATION);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCurrentLevel]);

    useEffect(() => {
        if (isOrderChanged && !showAnimation) {
            setCurrentLevel(getCurrentLevel);
            setLastLevel(getCurrentLevel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOrderChanged]);

    useEffect(() => {
        firstTimeLevelUpAnimation.current = false;
    }, []);

    return (
        <div className="myClassParentContainer">
            <LevelUpAnimation lastLevel={lastLevel} currentLevel={currentLevel} isLevellingUp={isLevellingUp} />

            <div
                className={`card_wraper myClassParticipantCardLottie ${
                    isPointDeducting ? 'myClassRemovePointAnimation' : ''
                } ${isPointAdding ? 'myClassAddPointAnimation' : ''}`}
            >
                <MyClassGivePointsLottie ref={sparkleRef} />

                {participant.joined && !participant.left && removeParticipant && (
                    <div className="close">
                        <CloseIcon
                            onClick={() => {
                                webviewMessenger.sendUsageLog(`[S] Clicked remove participant icon`);
                                removeParticipant(participant);
                            }}
                        />
                    </div>
                )}

                <div className="profile">
                    <div className="avatar_image">
                        <CustomAvatar
                            avatarName={participant.participantName}
                            avatarUrl={participant.participantAvatar}
                            type={AvatarType.PARTICIPANT}
                            savedClassGroups={savedClassGroups}
                            groupId={participant.groupId}
                        />
                    </div>
                    <div className={participant.joined && !participant.left ? 'status online' : 'status '}></div>
                </div>

                <div className="small_card_box">
                    <Highlighter
                        highlightClassName="YourHighlightClass"
                        searchWords={searchKeywordToArray(searchTextHighlight || '')}
                        autoEscape={true}
                        textToHighlight={participant.participantName}
                        className="profile_name"
                    />

                    <div className="point_show_box">
                        <div className="point_count_box">
                            <img src={utilConstants.IMAGE_URLS.starIcon} alt="Lightning" />
                            <Typography variant="body1">
                                {participant.participantTotalPoints + participant.participantPoints + pointsAdded}
                            </Typography>
                        </div>
                    </div>
                    <AddDeductStarButton
                        onClick={updatePointHandler}
                        number={participant.participantTotalPoints + participant.participantPoints + pointsAdded}
                    />
                </div>
            </div>
        </div>
    );
};

export default ParticipantCard;
