import { Fragment, useEffect, useRef, useState } from 'react';

// import EditIcon from '@mui/icons-material/Edit';
import { v4 } from 'uuid';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { getValidUserFromStore } from '../../../../helpers/storeHelpers';
import { useDispatch } from 'react-redux';
import { mapUserToSyncWithHost } from '../../../../helpers/userhelper';
import apiUser from '../../../../services/apiUser';
import { userActions } from '../../../../actions/user.actions';
import { UserActionTypes } from '../../../../constants/user-action-types';
import webviewMessenger from '../../../../services/webviewMessenger';
import { HostEventTypes } from '../../../../constants/host-event-types';
import CustomAvatar, { AvatarType } from '../../../../components/Common/CustomAvatar';
import ImagePickCropUpload from '../../../../components/Common/ImagePickCropUpload';
import { getBase64 } from '../../../../helpers/utils';
import { checkUrlExistInFileApp } from '../../../../helpers/activityHelpers';

const ProfileImage = () => {
    const dispatch = useDispatch();
    const user = getValidUserFromStore('ProfileImage');
    const userDisplayName = `${user.userProfile.firstName} ${user.userProfile.lastName}`;
    const avatarUrl = user.userProfile.avatarUrl;

    const avatarOriginalUrl = user.userProfile.avatarOriginalUrl;
    const avatarBoundingBox = user.userProfile.avatarBoundingBox;
    const [imageData, setImageData] = useState<any>();
    const [avatarImage, setAvatarImage] = useState<string>();
    const [savedCrop, setSavedCrop] = useState<string>();
    const [isImageEditModalOpen, setIsImageEditModalOpen] = useState(false);
    const inputfileref = useRef<any>(null);

    useEffect(() => {
        const fileAppBaseUrl = process.env.REACT_APP_CPFILE_BASE_URL;
        if (avatarUrl && fileAppBaseUrl && checkUrlExistInFileApp(avatarUrl, fileAppBaseUrl)) {
            tryLoadImageDataFromUrl();
            return setAvatarImage(avatarUrl);
        }
        if (!avatarUrl) return setAvatarImage(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [avatarUrl, avatarOriginalUrl]);

    const tryLoadImageDataFromUrl = () => {
        const imageDataFrom = avatarOriginalUrl ? avatarOriginalUrl : avatarUrl ? avatarUrl : null;
        if (imageDataFrom)
            getBase64(imageDataFrom).then((base64) => {
                setImageData(base64);
                setSavedCrop(avatarBoundingBox);
            });
        else {
            setImageData(null);
            setSavedCrop(undefined);
        }
    };

    const handleImageUpdated = async (imageSavedPayload: {
        imageUrl: string;
        originalImageUrl: string;
        cropCursor: string;
    }) => {
        setSavedCrop(imageSavedPayload.cropCursor);
        const updatedUser = await apiUser.updateUserProfile(user.email, {
            avatarUrl: imageSavedPayload.imageUrl,
            avatarOriginalUrl: imageSavedPayload.originalImageUrl,
            avatarBoundingBox: imageSavedPayload.cropCursor,
        });
        if (!updatedUser) {
            return dispatch(userActions.showApiError());
        }
        setIsImageEditModalOpen(false);
        dispatch(userActions.updateUser(UserActionTypes.UPLOADED_AVATAR_IMAGES, updatedUser));
        if (avatarUrl) webviewMessenger.sendUserLoaded(HostEventTypes.USER_UPDATED, mapUserToSyncWithHost(updatedUser));
        if (!imageSavedPayload.imageUrl) setImageData(null);
    };

    const handleImageEditCanceled = async () => {
        setIsImageEditModalOpen(false);
        tryLoadImageDataFromUrl();
    };

    return (
        <Fragment>
            <div className="profile_upload">
                {avatarImage ? (
                    <div className="upload_profile_images">
                        <label htmlFor="icon-button-file-saved-class" onClick={() => setIsImageEditModalOpen(true)}>
                            <CustomAvatar avatarUrl={avatarImage} avatarName={userDisplayName} type={AvatarType.USER} />
                        </label>
                        <div className="changes_profile_images">
                            <label htmlFor="icon-button-file">
                                <CameraAltIcon
                                    onClick={() => {
                                        setIsImageEditModalOpen(true);
                                    }}
                                />
                                <ImagePickCropUpload
                                    inputfileref={inputfileref}
                                    isInputDisabled={true}
                                    initialImageData={imageData}
                                    setImageData={setImageData}
                                    outputImageName={`${user.email}-avatar-${v4()}.jpg`}
                                    outputOriginalImageName={`${user.email}-avatar-original-${v4()}.jpg`}
                                    savedCrop={savedCrop}
                                    setSavedCrop={setSavedCrop}
                                    isImageEditModalOpen={isImageEditModalOpen}
                                    setIsImageEditModalOpen={setIsImageEditModalOpen}
                                    onUpdated={handleImageUpdated}
                                    handleImageEditCanceled={handleImageEditCanceled}
                                    canReset={true}
                                />
                            </label>
                        </div>
                    </div>
                ) : (
                    <div className="upload_profile_images">
                        <label htmlFor="icon-button-file-saved-class" onClick={() => setIsImageEditModalOpen(true)}>
                            <CustomAvatar avatarName={userDisplayName} avatarUrl={avatarUrl} type={AvatarType.USER} />
                        </label>
                        <div className="changes_profile_images">
                            <label htmlFor="icon-button-file">
                                <CameraAltIcon onClick={() => inputfileref.current.click()} />
                                <ImagePickCropUpload
                                    inputfileref={inputfileref}
                                    initialImageData={imageData}
                                    setImageData={setImageData}
                                    outputImageName={`${user.email}-avatar-Image-${v4()}.jpg`}
                                    outputOriginalImageName={`${user.email}-avatar-Image-original-${v4()}.jpg`}
                                    savedCrop={savedCrop}
                                    setSavedCrop={setSavedCrop}
                                    isImageEditModalOpen={isImageEditModalOpen}
                                    setIsImageEditModalOpen={setIsImageEditModalOpen}
                                    onUpdated={handleImageUpdated}
                                    handleImageEditCanceled={handleImageEditCanceled}
                                />
                            </label>
                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default ProfileImage;
