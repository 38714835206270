import { Fragment, useState } from 'react';
import HelpdeskError from './HelpdeskError';
import HelpdeskForm from './HelpdeskForm';
import HelpdeskHead from './HelpdeskHead';
import HelpdeskSuccess from './HelpdeskSuccess';
import './Helpdesk.scss';
import { useTranslation } from 'react-i18next';

export interface AttachedImageInterface {
    imageData: any;
    imageUrl: string;
}

const Helpdesk = () => {
    const [currentCommponent, setCurrentComponent] = useState('form');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [attachedImages, setAttachedImages] = useState<AttachedImageInterface[]>([]);

    const { t } = useTranslation();

    const inputHandler = (e: any) => {
        e.preventDefault();
        setMessage(e.target.value);
        error && setError('');
    };

    const validateForm = (): string => {
        let error = '';
        if (!subject.trim()) {
            error = t('lang_new.err_subject_required');
        } else if (!message.trim()) {
            error = t('lang_help_desk.err_msg_should_not_empty');
        } else if (message.length < 8) {
            error = t('lang_help_desk.err_msg_too_short');
        } else if (message.length > 600) {
            error = t('lang_help_desk.err_msg_too_long');
        }
        setError(error);
        return error;
    };

    const handleHelpdeskSuccess = () => {
        setSubject('');
        setMessage('');
        setAttachedImages([]);
    };

    const renderHelpDeskComponent = () => {
        switch (currentCommponent) {
            case 'form':
                return (
                    <HelpdeskForm
                        onComponentChange={setCurrentComponent}
                        inputHandler={inputHandler}
                        validateForm={validateForm}
                        subject={subject}
                        setSubject={setSubject}
                        message={message}
                        error={error}
                        attachedImages={attachedImages}
                        onUpdateAttachedImages={setAttachedImages}
                        onImageValidationError={setError}
                    />
                );
            case 'success':
                return (
                    <HelpdeskSuccess
                        onComponentChange={setCurrentComponent}
                        onHelpdeskSuccess={handleHelpdeskSuccess}
                    />
                );
            case 'error':
                return <HelpdeskError onComponentChange={setCurrentComponent} />;
        }
    };

    return (
        <Fragment>
            <div className="helpdeskBody">
                <HelpdeskHead />
                {renderHelpDeskComponent()}
            </div>
        </Fragment>
    );
};

export default Helpdesk;
