import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import WhiteboardPreviewModal from './components/WhiteboardPreviewModal';
import WhiteboardSelectFrom from './components/WhiteboardSelectFrom';
import ImageFileTypeWrongAlert from '../../components/Common/ImageFileTypeWrongAlert';

const WhiteboardLanding = () => {
    const { t } = useTranslation();

    const [isWhiteboardPreviewOpen, setIsWhiteboardPreviewOpen] = useState(false);
    const [isShowImageTypeWrongModal, setIsShowImageTypeWrongModal] = useState(false);
    const [imageValue, setImageValue] = useState({
        imageData: '',
        blobData: '',
        imageName: '',
    });

    useEffect(() => {
        return () => {
            setIsWhiteboardPreviewOpen(false);
            setIsShowImageTypeWrongModal(false);
            setImageValue({
                imageData: '',
                blobData: '',
                imageName: '',
            });
        };
    }, []);

    return (
        <Fragment>
            <div className="whiteBoard_landingpage">
                <div className="whiteboardContainer">
                    <div className="inner_whiteBoard_landingpage">
                        <div className="info_content">
                            <Typography variant="h3" className="title">
                                {t('lang_my_whiteboard.txt_let_ideas_flow_with_whiteboard')}
                            </Typography>
                            <Typography variant="body1">
                                {t('lang_my_whiteboard.txt_unlimited_whiteboards_can_be_added')}
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>

            <WhiteboardSelectFrom
                imageDataCallBack={setImageValue}
                onOpenPreviewModal={() => setIsWhiteboardPreviewOpen(true)}
                onCloseThisModal={() => {}}
                onImageTypeError={() => setIsShowImageTypeWrongModal(true)}
            />

            <WhiteboardPreviewModal
                isThisModalOpen={isWhiteboardPreviewOpen}
                onCloseThisModal={() => setIsWhiteboardPreviewOpen(false)}
                imageValue={imageValue}
            />

            <ImageFileTypeWrongAlert
                isAlertOpen={isShowImageTypeWrongModal}
                onCloseAlert={() => setIsShowImageTypeWrongModal(false)}
            />
        </Fragment>
    );
};

export default WhiteboardLanding;
