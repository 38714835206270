import { Typography } from '@mui/material';
import { NO_RANK_DISPLAY, utilConstants } from '../../../constants/utils.constants';
import CustomAvatar, { AvatarType } from '../../../components/Common/CustomAvatar';
import { calculateParticipantLevelAndBadge, getLevelsArray } from '../../../helpers/userhelper';
import { getValidUserFromStore } from '../../../helpers/storeHelpers';
import { SavedClassGroupInterface } from '../../../interfaces/savedClassGroup.interface';
import LeaderboardParticipantInterface from '../../../interfaces/leaderboard-participant.interface';

type LeaderBoardOverallSingleProps = {
    leaderboardParticipant: LeaderboardParticipantInterface;
    savedClassGroups: SavedClassGroupInterface[];
};

const LeaderBoardOverallSingle = ({ leaderboardParticipant, savedClassGroups }: LeaderBoardOverallSingleProps) => {
    const user = getValidUserFromStore('LeaderBoardOverallSingle');

    return (
        <div key={leaderboardParticipant.participantId} className="card_warp">
            <div className="card_number">
                <div className="number_box">
                    <Typography variant="h1">{leaderboardParticipant.participantRank || NO_RANK_DISPLAY}</Typography>
                </div>
            </div>
            <div className="card_body">
                <div className="profileimg">
                    <CustomAvatar
                        avatarName={leaderboardParticipant.participantName}
                        avatarUrl={leaderboardParticipant.participantAvatar}
                        type={AvatarType.PARTICIPANT}
                        savedClassGroups={savedClassGroups}
                        groupId={leaderboardParticipant.groupId}
                    />
                </div>
                <div className="body_box">
                    <Typography className="body_box_text" variant="h4">
                        {leaderboardParticipant.participantName}
                    </Typography>
                </div>
            </div>
            <div className="card_level_point">
                <div className="points">
                    <div className="point_icon">
                        <img src={utilConstants.IMAGE_URLS.quizSummaryIconStar} alt="point_icon" />
                    </div>
                    <Typography variant="body1">{leaderboardParticipant.participantSumPoints}</Typography>
                </div>
                <div className="level">
                    <div className="level_icon">
                        <img
                            src={
                                calculateParticipantLevelAndBadge(
                                    leaderboardParticipant.participantSumPoints,
                                    getLevelsArray(user),
                                ).badgeUrl
                            }
                            alt="level_icon"
                        />
                    </div>
                    {/* <Typography variant="body1">
                        Lv.{' '}
                        {
                            calculateParticipantLevelAndBadge(
                                leaderboardParticipant.participantSumPoints,
                                getLevelsArray(user),
                            ).level
                        }
                    </Typography> */}
                </div>
            </div>
        </div>
    );
};

export default LeaderBoardOverallSingle;
