import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

import CommonButton from '../../../components/Common/CommonButton';

interface AccountAlreadyExistsAlertInterface {
    onSignIn: () => void;
    onBack: () => void;
}

const AccountAlreadyExistsAlert = ({ onSignIn, onBack }: AccountAlreadyExistsAlertInterface) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="media">
                <div className="media_icon">
                    <ReportProblemIcon className="warning" />
                </div>
                <div className="media_body">
                    <div className="alert_content">
                        <Typography variant="body1">{t('lang_auth.already_have_account_sign_in')}</Typography>
                    </div>
                </div>
            </div>
            <div className="button_sec">
                <CommonButton
                    variant="contained"
                    type="button"
                    text={t('lang_auth.heading_btn_login')}
                    clickFunction={onSignIn}
                />
                <CommonButton variant="text" type="button" text={t('lang_common.btn_back')} clickFunction={onBack} />
            </div>
        </>
    );
};

export default AccountAlreadyExistsAlert;
