export enum AlertTypes {
    RESTART_ACTIVITY,
    DELETE_RESPONSES,
    INSERT_SLIDES_MORE_THAN_50,
    END_ACTIVITY,
    END_VOTING,
    LOCK_CLASS,
    REMOVE_PARTICIPANT,
    RESTART_CLASS,
    QUIZ_MODE_PRO,
    RESET_STARS,
    SHARE_ACTIVITY,
    ASK_CP_AI,
}
