import './GroupPointsModal.scss';

import ParticipantInterface from '../../../../interfaces/participant.interface';
import { SavedClassGroupInterface } from '../../../../interfaces/savedClassGroup.interface';

import CacheParticipantCard from '../../../myClass/CacheParticipantCard';

interface IGroupPointsModal {
    selectedGroupParticipants: ParticipantInterface[];
    savedClassGroups: SavedClassGroupInterface[];
}

export default function GroupPointsModal({ selectedGroupParticipants, savedClassGroups }: IGroupPointsModal) {
    return (
        <div className={`myClassViewGroupModal`}>
            <div className="myClassGroupViewBody participant_content_sec">
                <div className={`participant_card_container`}>
                    <div className={`participent_card`}>
                        {selectedGroupParticipants.map((participant, index: number) => (
                            <CacheParticipantCard
                                key={index}
                                savedClassGroups={savedClassGroups}
                                participant={participant}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
