import { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AnimatePresence } from 'framer-motion';

import Stack from '@mui/material/Stack';

import { findCenter } from '../../../helpers/animationHelpers';
import { base64TextToImage, stringToNumber } from '../../../helpers/utils';
import { activityActions } from '../../../actions/activity.action';

import ActivityResponseInterface from '../../../interfaces/activity-response.interface';
import { AnimationConfig } from '../../../constants/animation-configs';

import VotingCount from '../../../components/Common/VotingCount';
import MultiMediaActivityResponseFooter from '../MultiMediaActivityResponseFooter';
import { TickLottie } from '../../../components/Common/AnimationModules';
import Loading from '../Loading/Loading';

interface SlideDrawingSingleResponseInterface {
    index: number;
    response: ActivityResponseInterface;
    searchKeyword: string;
    isVoting: boolean;
    onThumbClicked: any;
}

function SlideDrawingSingleResponse({
    index,
    response,
    isVoting,
    onThumbClicked,
    searchKeyword,
}: SlideDrawingSingleResponseInterface) {
    const dispatch = useDispatch();

    const [imageSrc, setImageSrc] = useState(response.responseData);
    useEffect(() => {
        getImageUrlWithLegacyCompatible(response.responseData);
    }, [response.responseData]);

    const getImageUrlWithLegacyCompatible = async (url: string) => {
        if (url.endsWith('txt')) {
            const base64 = (await base64TextToImage(url)) as string;
            setImageSrc(`data:image/png;base64, ${base64}`);
        }
    };

    const boxSizeRef = useRef<any>();
    const timeOutPointUpdate = useRef<any>();
    const [pointsAdded, setPointsAdded] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const givePoint = (response: ActivityResponseInterface, points: number) => {
        dispatch(activityActions.handleGivePointsToResponses([{ response, points }]));
    };

    const handleGivePointButtonClick = () => {
        clearTimeout(timeOutPointUpdate.current);
        setIsAnimating(true);
        setPointsAdded(pointsAdded + 1);
    };

    useEffect(() => {
        if (pointsAdded > 0) {
            timeOutPointUpdate.current = setTimeout(() => {
                givePoint(response, pointsAdded);
                setPointsAdded(0);
                setTimeout(() => {
                    setIsAnimating(false);
                }, AnimationConfig.TICK_LOTTIE_DURATION - AnimationConfig.GIVE_POINT_DELAY);
            }, AnimationConfig.GIVE_POINT_DELAY);
        }
        return () => clearTimeout(timeOutPointUpdate.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pointsAdded]);

    return (
        <>
            <Stack key={response.responseId} className="slidewrap">
                <div className="sh_box">
                    <div ref={boxSizeRef} className="content_box" onClick={() => onThumbClicked(response, index)}>
                        {isAnimating && <div className="overlayAnimationGlobal show"></div>}
                        {isProcessing && <Loading />}
                        <AnimatePresence>
                            {isAnimating && (
                                <TickLottie
                                    key={response.responseId}
                                    index={stringToNumber(response.responseId)}
                                    xPosition={() => findCenter(boxSizeRef).x}
                                    yPosition={() => findCenter(boxSizeRef).y}
                                />
                            )}
                        </AnimatePresence>
                        {!isVoting && response.voterParticipantIds && <VotingCount response={response} />}
                        <img alt="image1" src={imageSrc} />
                    </div>

                    <MultiMediaActivityResponseFooter
                        response={response}
                        responseIndex={index}
                        onResponseIndexDeleted={() => {}}
                        searchKeyword={searchKeyword}
                        isVoting={isVoting}
                        pointsAdded={pointsAdded}
                        onGivePointButtonClick={handleGivePointButtonClick}
                        setIsProcessing={setIsProcessing}
                    />
                </div>
            </Stack>
        </>
    );
}

export default SlideDrawingSingleResponse;
