import { useTranslation } from 'react-i18next';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Typography } from '@mui/material';

import CommonButton from '../../../components/Common/CommonButton';
import CommonCheckBox from '../../../components/Common/CommonCheckBox';
import CommonAlert from '../../../components/Common/CommonAlert';
import { ViewportConstants } from '../../../constants/viewport-constants';

interface PickNamesResetAlertInterface {
    isResetModalOpen: boolean;
    setIsResetModalOpen: (arg: boolean) => void;
    confirmResetPickNames: (arg: string) => void;
    setIsDismissResetReminderChecked: (arg: boolean) => void;
}

const PickNamesResetAlert = ({
    isResetModalOpen,
    setIsResetModalOpen,
    confirmResetPickNames,
    setIsDismissResetReminderChecked,
}: PickNamesResetAlertInterface) => {
    const { t } = useTranslation();

    return (
        <CommonAlert
            showAlert={isResetModalOpen}
            closeAlert={() => setIsResetModalOpen(false)}
            viewportConfig={ViewportConstants}
            content={
                <>
                    <div className="media">
                        <div className="media_icon">
                            <ReportProblemIcon className="warning" />
                        </div>
                        <div className="media_body">
                            <div className="alert_content">
                                <Typography variant="body1">
                                    {t('lang_common.txt_confirm_participants_reshuffled')}
                                </Typography>
                                <div className="alert_check">
                                    <CommonCheckBox
                                        isHookRegistered={false}
                                        label={t('lang_common.txt_dont_remind_me_again')}
                                        changeFunction={(e: any) => setIsDismissResetReminderChecked(e.target.checked)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="button_sec">
                        <CommonButton
                            isLoading={false}
                            variant="contained"
                            type="button"
                            text={t('lang_common.btn_yes')}
                            clickFunction={() => confirmResetPickNames('PickNamesResetAlert')}
                        />

                        <CommonButton
                            isLoading={false}
                            variant="text"
                            type="button"
                            text={t('lang_common.btn_no')}
                            clickFunction={() => setIsResetModalOpen(false)}
                        />
                    </div>
                </>
            }
        />
    );
};

export default PickNamesResetAlert;
