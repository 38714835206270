import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { getValidClassSessionFromStore, getValidUserFromStore } from '../../../helpers/storeHelpers';
import { findUserClassLimit, isUserOnTrialOrPro } from '../../../helpers/userhelper';
import { utilConstants } from '../../../constants/utils.constants';
import { UpgradeToProButton } from '../../../components/Common/UpgradeToProButton';

const MyClassFull = ({ onStartActivateTrial }: { onStartActivateTrial: () => void }) => {
    const { t } = useTranslation();
    const user = getValidUserFromStore('MyClassFull');
    const isTrialOrPro = isUserOnTrialOrPro(user);
    const classSession = getValidClassSessionFromStore('MyClassFull');

    return (
        <div className={`${classSession.classSessionName ? '' : 'openClass'} classFull`}>
            <div className="qr_pop_sec">
                <div className="class_locked_sec red">
                    {/* <PersonOffOutlinedIcon /> */}
                    <img src={utilConstants.IMAGE_URLS.classfull} alt="staricon" />
                    <Typography variant="h3">{t('lang_myclass.heading_class_full')}</Typography>
                    {!isTrialOrPro ? (
                        <Typography variant="body1">
                            {t('lang_myclass.txt_class_full_basic', {
                                classLimit: findUserClassLimit(user),
                            })}
                        </Typography>
                    ) : (
                        <Typography variant="body1">
                            {t('lang_myclass.txt_class_full_pro', {
                                participantCount: classSession.participantList.length,
                            })}
                        </Typography>
                    )}
                </div>
            </div>
            {!isTrialOrPro && (
                <div className="qr_footer">
                    <div className="qr_learmore">
                        <UpgradeToProButton caller="myclassFull" email={user.email} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default MyClassFull;
