import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, IconButton, Input, InputAdornment, InputLabel, TextField } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

function TextFieldType({
    label,
    fieldName,
    isViewMode,
    register,
    errors,
    isLocked,
    lockedValueDisplay,
    inputMaxLength,
}: any) {
    const { t } = useTranslation();
    const [isInputActive, setIsInputActive] = useState(false);

    return (
        <>
            {!isLocked ? (
                <TextField
                    autoComplete="none"
                    id="standard-basic"
                    disabled={isViewMode}
                    inputProps={{ maxLength: inputMaxLength }}
                    InputLabelProps={{ shrink: true }}
                    label={label}
                    variant="standard"
                    {...register(fieldName)}
                    error={errors[fieldName] ? true : false}
                    helperText={
                        errors[fieldName]?.type === 'required'
                            ? t('lang_profile.err_required', { field: label })
                            : errors[fieldName]?.type === 'min'
                            ? t('lang_profile.err_min', { field: label })
                            : errors[fieldName]?.type === 'max'
                            ? t('lang_profile.err_max', { field: label })
                            : ''
                    }
                    onFocus={() => setIsInputActive(true)}
                    onBlur={() => setIsInputActive(false)}
                    className={isInputActive ? '' : 'inactive'}
                />
            ) : (
                <FormControl
                    sx={{
                        m: 1,
                        width: '25ch',
                    }}
                    variant="standard"
                >
                    <InputLabel htmlFor="standard-basic-org">{label}</InputLabel>
                    <Input
                        id="standard-basic-org"
                        type="text"
                        disabled={true}
                        value={lockedValueDisplay}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton disabled={true} aria-label="locked">
                                    <LockIcon className="lock" />
                                </IconButton>
                            </InputAdornment>
                        }
                        onFocus={() => setIsInputActive(true)}
                        onBlur={() => setIsInputActive(false)}
                        className={`${!isViewMode ? 'editableInputBox' : ''} ${isInputActive ? '' : 'inactive'}`}
                    />
                </FormControl>
            )}
        </>
    );
}

export default TextFieldType;
