import './NewUserDiscountLabel.scss';
import { utilConstants } from '../../../constants/utils.constants';
import { getValidUserFromStore } from '../../../helpers/storeHelpers';
import { getNewUserDiscount } from '../../../helpers/userhelper';
import { useTranslation } from 'react-i18next';

interface DiscountInterface {
    className?: string;
    onShowDiscountModal?: () => void;
}

const NewUserDiscountLabel = ({ className, onShowDiscountModal }: DiscountInterface) => {
    const { t } = useTranslation();
    const user = getValidUserFromStore('NewUserDiscountLabel');
    const discount = getNewUserDiscount(user);

    if (!discount) return null;
    return (
        <div className={`discount ${className}`} onClick={onShowDiscountModal}>
            <img src={utilConstants.SETTING.DISCOUNT} alt="icon" />
            <p className="text">
                {`${t('lang_new_discount.percentage_off', {
                    percentage: discount.discountPercentage,
                })} - ${
                    discount.daysLeft > 1
                        ? t('lang_new_discount.days_left', {
                              days: discount.daysLeft,
                          })
                        : t('lang_new_discount.day_left', {
                              days: discount.daysLeft,
                          })
                }`.toUpperCase()}
            </p>
        </div>
    );
};

export default NewUserDiscountLabel;
