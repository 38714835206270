import { orderBy } from 'lodash';

import { processParticipantNamesForSorting } from '../pages/savedClass/savedClassHelpers';

import SavedClassParticipantInterface, {
    OrderProperties,
    Orders,
} from '../interfaces/saved-class-participant.interface';

export const handleSortParticipants = (
    participants: SavedClassParticipantInterface[],
    order: Orders,
    orderProperty: OrderProperties,
) => {
    let arrangedParticipants: SavedClassParticipantInterface[];

    if (orderProperty === OrderProperties.ParticipantName) {
        arrangedParticipants = orderBy(
            participants,
            [(participant) => processParticipantNamesForSorting(participant.participantName)],
            [order],
        );
    } else {
        arrangedParticipants = orderBy(
            participants,
            [
                orderProperty === OrderProperties.ParticipantLevel
                    ? OrderProperties.ParticipantTotalPoints
                    : orderProperty,
                OrderProperties.ParticipantTotalPoints,
            ],
            [order, Orders.Asc],
        );
    }

    return arrangedParticipants;
};
