import { useEffect, useRef, useState } from 'react';
import webviewBrowserCommands from '../../../../services/webviewBrowserCommands';

export default function useAddressBar() {
    const [searchKeyword, setSearchKeyword] = useState<string>('');

    const searchFieldRef: any = useRef<any>();
    // eslint-disable-next-line no-useless-escape
    const VALID_URI_REGEX = /^[-:.&#+()[\]$'*;@~!,?%=\/\w]+$/; // Will check that only RFC3986 allowed characters are included
    const WORD_REGEX = /^[^//][^.]*$/;
    const SCHEMED_URI_REGEX = /^\w+:.+$/;

    const detectSearchEnter = (e: any) => {
        var key = e.which || e.keyCode;
        if (key === 13) {
            // 13 is enter
            e.preventDefault();
            // console.log('input box entered', searchKeyword);
            tryNavigate(searchKeyword);
        }
    };

    function getSearchURI(query: string) {
        return `https://www.google.com/search?q=${encodeURIComponent(query)}`;
    }

    function containsIlegalCharacters(query: string) {
        return !VALID_URI_REGEX.test(query);
    }

    function hasScheme(query: any) {
        return SCHEMED_URI_REGEX.test(query);
    }

    function tryNavigate(text: string) {
        setSearchKeyword('');
        try {
            var uriParser = new URL(text);
            // console.log('uriParser', uriParser.href);
            // URL creation succeeded, verify protocol is allowed
            switch (uriParser.protocol) {
                case 'http:':
                case 'https:':
                case 'file:':
                case 'ftp:':
                    // Allowed protocol, navigate
                    webviewBrowserCommands.navigateTo(uriParser.href, false);
                    break;
                default:
                    // Protocol not allowed, search Gogole
                    webviewBrowserCommands.navigateTo(getSearchURI(text), true);
                    break;
            }
        } catch (e) {
            // URL creation failed, check for invalid characters
            if (containsIlegalCharacters(text) || isSingleWord(text)) {
                // Search Google
                webviewBrowserCommands.navigateTo(getSearchURI(text), true);
            } else {
                // Try with HTTP
                if (!hasScheme(text)) {
                    tryNavigate(`http:${text}`);
                } else {
                    webviewBrowserCommands.navigateTo(getSearchURI(text), true);
                }
            }
        }
    }

    function isSingleWord(query: string) {
        return WORD_REGEX.test(query);
    }

    useEffect(() => {
        if (searchFieldRef.current) searchFieldRef.current.addEventListener('keypress', detectSearchEnter);
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            if (searchFieldRef.current) searchFieldRef.current.removeEventListener('keypress');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchKeyword]);

    const setSearchInput: any = (value: string) => {
        setSearchKeyword(value);
    };

    return [searchKeyword, setSearchInput, searchFieldRef];
}
