import styles from './QuestionCard.module.scss';

import { updateQnaState } from '../../../../reducers/qna.reducer';

import { QnaActionType, QnaInterface } from '../../../../interfaces/qna.interface';

import QuestionCardFooter from '../QuestionCardFooter/QuestionCardFooter';

import { THUMBS_UP_OUTLINED, THUMBS_UP_SOLID } from '../../../../Icon';

import filter from '../../../../services/badwords.service';

interface QuestionCardInterface {
    currentQuestionIndex: number;
    qna: QnaInterface;
}

const QuestionCard = ({ currentQuestionIndex, qna }: QuestionCardInterface) => {
    return (
        <div className={styles.questionCard}>
            <div className={styles.inner_questionCard}>
                <div
                    className={styles.question_area}
                    onClick={() =>
                        updateQnaState(QnaActionType.OPEN_MODAL, { selectedQuestionIndex: currentQuestionIndex })
                    }
                >
                    {/* The library needs the string to have at least one English alphabet */}
                    <p className={styles.text}>{filter.clean(`a${qna.question}`).slice(1)}</p>
                </div>

                <div className={styles.upgrade_ans}>
                    <i className={styles.icon}>{qna.votes ? <THUMBS_UP_SOLID /> : <THUMBS_UP_OUTLINED />}</i>
                    <p className={styles.number}>{qna.votes}</p>
                </div>

                <QuestionCardFooter qna={qna} />
            </div>
        </div>
    );
};

export default QuestionCard;
