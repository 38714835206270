import { useState } from 'react';
import { useDispatch } from 'react-redux';

import './VerifyEmail.scss';

import { userActions } from '../../../actions/user.actions';
import apiUser from '../../../services/apiUser';
import { ViewportSavedClasses } from '../../../constants/viewport-constants';

import VerifyEmailWarningModal from './VerifyEmailWarningModal';
import CommonAlert from '../../../components/Common/CommonAlert';

interface VerifyEmailInterface {
    userEmail: string;
    isShowWarningModal: boolean;
    isGamificationModal?: boolean;
    viewport?: {
        width: number;
        height: number;
        minZoom: number;
        maxZoom: number;
    };
    onCloseModal: () => void;
    customModalClassNames?: string;
}

export const VerifyEmail = ({
    userEmail,
    isShowWarningModal,
    isGamificationModal,
    viewport,
    onCloseModal,
    customModalClassNames,
}: VerifyEmailInterface) => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [canCloseModal, setCanCloseModal] = useState(true);

    const sendVerificationCodeHandler = async () => {
        setCanCloseModal(false);
        setIsLoading(true);
        const sendVerificationCodeSuccess = await apiUser.sendVerificationEmail(userEmail);

        if (!sendVerificationCodeSuccess) {
            onCloseModal();
            setIsLoading(false);
            return dispatch(userActions.showApiError());
        }

        setTimeout(() => {
            setIsLoading(false);
            setIsCodeSent(true);
            setTimeout(() => {
                setCanCloseModal(true);
            }, 1000);
        }, 2000);
    };

    const closeVerificationCodeModal = () => {
        if (canCloseModal) onCloseModal();
    };

    return (
        <div className={`verifyEmailWarning`}>
            <CommonAlert
                showAlert={isShowWarningModal}
                closeAlert={closeVerificationCodeModal}
                content={
                    <VerifyEmailWarningModal
                        isLoading={isLoading}
                        isCodeSent={isCodeSent}
                        sendVerificationCodeHandler={sendVerificationCodeHandler}
                        closeAlert={closeVerificationCodeModal}
                    />
                }
                viewportConfig={viewport || ViewportSavedClasses}
                customModalClassNames={`${customModalClassNames} small`}
                customBoxClassNames={isGamificationModal ? 'isGamificationModal' : ''}
            />
        </div>
    );
};
