import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FitToViewport } from 'react-fit-to-viewport';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { webviewSettingsActions } from '../../actions/webview-settings.actions';
import Footer from '../../components/layout/Footer/Footer';
import { ViewportConstants } from '../../constants/viewport-constants';
import { WebviewSettingsActionTypes } from '../../constants/webview-settings-action-types';
import { WhiteboardPresets } from '../../constants/whiteboard-presets';
import { getValidUserFromStore } from '../../helpers/storeHelpers';
import StoreInterface from '../../interfaces/store.interface';
import webviewMessenger from '../../services/webviewMessenger';
import { getBase64 } from '../../helpers/utils';
import EmptyModal from '../EmptyModal/EmptyModal';
import { localService } from '../../services/localStorageService';
import { UpdateToolbarActionsType } from '../../dtos/update-toolbar-actions.dto';
import './WhiteBoard.scss';
import WhiteBoardHeader from './components/header';
import Header from '../../components/layout/Header/Header';

const WhiteBoard = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const user = getValidUserFromStore('WhiteBoard');

    const webviewSettingsSlideshow = useSelector((state: StoreInterface) => state.webviewSettingsSlideshow);
    const customizedWhiteboards = user.userPreferences?.whiteboardBackgrounds || [];

    const [errorMessage, setErrorMessage] = useState<string>();
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [showEmptyModal, setShowEmptyModal] = useState(false);

    useEffect(() => {
        setShowEmptyModal(true);
        setTimeout(() => {
            setShowEmptyModal(false);
        }, 1);
    }, []);

    const handleAddWhiteboardViewToggle = () => {
        webviewMessenger.sendUsageLog(
            `[S] Toggled ${webviewSettingsSlideshow.isAddWhiteboardOnPresetView ? 'ON' : 'OFF'} custom whiteboard`,
        );
        dispatch(
            webviewSettingsActions.updateWebviewSettings(WebviewSettingsActionTypes.TOGGLE_ADD_WHITEBOARD_VIEW, {
                isAddWhiteboardOnPresetView: !webviewSettingsSlideshow.isAddWhiteboardOnPresetView,
            }),
        );
        setCurrentIndex(-1);
    };

    const handleInsertWhiteboard = () => {
        if (currentIndex === -1) {
            setErrorMessage(t('lang_insert_whiteboard.err_choose_before_insert'));
            return;
        }
        webviewMessenger.sendUsageLog(`[S] Inserted whiteboard as slide`);
        const selectedWhiteboard = webviewSettingsSlideshow.isAddWhiteboardOnPresetView
            ? WhiteboardPresets[currentIndex]
            : customizedWhiteboards[currentIndex];
        getBase64(selectedWhiteboard.url).then((imageData: any) => {
            webviewMessenger.sendInsertWhiteboard(imageData.split(',')[1]);
        });
        localService.updateToolbarActionsFromWebview(UpdateToolbarActionsType.addWhiteboard);
    };

    return (
        <Fragment>
            <div className="black">
                <FitToViewport
                    className="viewport"
                    width={ViewportConstants.width}
                    height={ViewportConstants.height}
                    minZoom={ViewportConstants.minZoom}
                    maxZoom={ViewportConstants.maxZoom}
                >
                    <div className="whiteboard_sec">
                        {showEmptyModal && <EmptyModal viewportConfig={ViewportConstants} />}
                        <Header appBarTitle={t('lang_insert_whiteboard.title')}>
                            <WhiteBoardHeader
                                isAddWhiteboardOnPresetView={webviewSettingsSlideshow.isAddWhiteboardOnPresetView}
                                onToggleCustomizedWhiteBoards={handleAddWhiteboardViewToggle}
                            />
                        </Header>
                        <div className="whiteboardcard_body">
                            <div className="whiteboardContainer">
                                <Fragment>
                                    {!webviewSettingsSlideshow.isAddWhiteboardOnPresetView &&
                                    customizedWhiteboards.length === 0 ? (
                                        <div className="noCustomWhiteBoardMsg">
                                            <Typography variant="body1">
                                                {t('lang_insert_whiteboard.txt_no_custom_whiteboard')}
                                            </Typography>
                                        </div>
                                    ) : (
                                        <div className="whiteboard_inner">
                                            <div className="whiteboard_card">
                                                {(webviewSettingsSlideshow.isAddWhiteboardOnPresetView
                                                    ? WhiteboardPresets
                                                    : customizedWhiteboards
                                                ).map((item: any, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className="whiteboard_card_box"
                                                            onClick={() => {
                                                                setErrorMessage('');
                                                                setCurrentIndex(index);
                                                            }}
                                                            onDoubleClick={() => {
                                                                handleInsertWhiteboard();
                                                            }}
                                                        >
                                                            <div
                                                                className={
                                                                    currentIndex === index
                                                                        ? 'whiteboard_img active'
                                                                        : 'whiteboard_img'
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        webviewSettingsSlideshow.isAddWhiteboardOnPresetView
                                                                            ? item.thumbnail
                                                                            : item.url
                                                                    }
                                                                    alt={
                                                                        webviewSettingsSlideshow.isAddWhiteboardOnPresetView
                                                                            ? t(`lang_insert_whiteboard.${item.name}`)
                                                                            : item.name
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="whiteboard_title">
                                                                <Typography variant="h4">
                                                                    {webviewSettingsSlideshow.isAddWhiteboardOnPresetView
                                                                        ? t(`lang_insert_whiteboard.${item.name}`)
                                                                        : item.name}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>

                                            {errorMessage ? (
                                                <div className="whiteboarderror">
                                                    <Typography variant="body1">{errorMessage}</Typography>
                                                </div>
                                            ) : null}
                                        </div>
                                    )}
                                </Fragment>
                            </div>
                        </div>

                        <Footer
                            onInsertWhiteboard={handleInsertWhiteboard}
                            layout="whiteboard"
                            isAddWhiteboardButtonDisabled={
                                webviewSettingsSlideshow.isAddWhiteboardOnPresetView
                                    ? false
                                    : customizedWhiteboards.length === 0
                            }
                        />
                    </div>
                </FitToViewport>
            </div>
        </Fragment>
    );
};

export default WhiteBoard;
