import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DoneIcon from '@mui/icons-material/Done';

import './MyClassSidebar.scss';

import { webviewSettingsActions } from '../../../actions/webview-settings.actions';
import { getValidClassSessionFromStore } from '../../../helpers/storeHelpers';
import webviewMessenger from '../../../services/webviewMessenger';

import { WebviewSettingsActionTypes } from '../../../constants/webview-settings-action-types';
import { utilConstants } from '../../../constants/utils.constants';

interface MyClassSidebarInterface {
    isSidebarShown: boolean;
    toggleSidebar: () => void;
    openQRCodeModal: () => void;
}

const MyClassSidebar = ({ isSidebarShown, toggleSidebar, openQRCodeModal }: MyClassSidebarInterface) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const classSession = getValidClassSessionFromStore('MyClassSidebar');

    const [toggleCopiedBtn, setToggleCopiedBtn] = useState(false);

    const handleMyClassSidebarView = () => {
        dispatch(
            webviewSettingsActions.updateWebviewSettings(WebviewSettingsActionTypes.UPDATE_IS_MY_CLASS_SIDEBAR_VIEW, {
                ...{
                    isMyClassSidebarShown: !isSidebarShown,
                },
            }),
        );
        toggleSidebar();
    };

    const copyUrl = (url: string) => {
        navigator.clipboard.writeText(url);
    };

    return (
        <div className={isSidebarShown ? 'sidebar' : 'sidebar close'}>
            <div className="content">
                <div className="innerBody">
                    <div onClick={() => openQRCodeModal()} className={`qrCode`}>
                        <QRCode
                            size={220}
                            value={`https://${process.env.REACT_APP_PARTICIPANT}?code=${classSession.classCode}`}
                            imageSettings={{
                                src: utilConstants.IMAGE_URLS.brand_logo,
                                x: undefined,
                                y: undefined,
                                height: 35,
                                width: 35,
                                excavate: true,
                            }}
                        />
                    </div>
                    <div className={`classDetails`}>
                        <div className={`link`}>
                            <p className={`tag`}>
                                {t('lang_myclass.txt_url')}{' '}
                                <ContentCopyIcon
                                    className={`copyIcon`}
                                    onClick={() => {
                                        webviewMessenger.sendUsageLog(`[S] Copied join class URL`);
                                        copyUrl(
                                            `https://${process.env.REACT_APP_PARTICIPANT}?code=${classSession.classCode}`,
                                        );
                                        setToggleCopiedBtn(true);
                                        setTimeout(() => setToggleCopiedBtn(false), 1000);
                                    }}
                                />
                                {toggleCopiedBtn && (
                                    <span className="copyurl_tooltip">
                                        {t('lang_share_classpoint.tooltip_copied')}
                                        <DoneIcon />
                                    </span>
                                )}
                            </p>
                            <p className={`value`}>classpoint.app</p>
                        </div>
                        <div className={`code`}>
                            <p className={`tag`}>{t('lang_common.txt_class_code')}</p>
                            <p className={`value`}>{classSession.classCode}</p>
                        </div>
                    </div>
                </div>
                <div className="arrow" onClick={() => handleMyClassSidebarView()}>
                    <ArrowForwardIosIcon />
                </div>
            </div>
        </div>
    );
};

export default MyClassSidebar;
