import { Typography } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CommonButton from '../../../../components/Common/CommonButton';
import StoreInterface from '../../../../interfaces/store.interface';
import webviewMessenger from '../../../../services/webviewMessenger';
import CloseButton from '../../../activity/activityButtons/CloseButton';

function QuizSummaryFooter({ isFromRibbon }: { isFromRibbon: boolean }) {
    const { t } = useTranslation();
    const classSession = useSelector((state: StoreInterface) => state.classSession);

    return (
        <Fragment>
            <div className="footer_left">
                <Typography variant="h4">
                    <span> </span>
                </Typography>
            </div>

            <div className="footer_middle">
                {isFromRibbon ? (
                    <CloseButton
                        onClose={() => {
                            webviewMessenger.sendUsageLog(`${classSession ? '[S] ' : ''}Closed Quiz summary window`);
                            webviewMessenger.sendHideWebview();
                        }}
                    />
                ) : (
                    <CommonButton
                        clickFunction={() => {
                            webviewMessenger.sendUsageLog(`${classSession ? '[S] ' : ''}Clicked "Back to activity"`);
                            webviewMessenger.sendSimpleMessage('backToActivity');
                        }}
                        isLoading={false}
                        className="default_btn"
                        type="button"
                        text={t('lang_activity.txt_back_activity')}
                    />
                )}
            </div>
            <div className="footer_right"></div>
        </Fragment>
    );
}

export default QuizSummaryFooter;
