import { Typography } from '@mui/material';
import { Fragment } from 'react';
import Lottie from 'react-lottie-player';
import { useTranslation } from 'react-i18next';
import welcomeAnimation from '../../assets/animation/welcome.json';
import CommonButton from '../../components/Common/CommonButton';
import { useSelector } from 'react-redux';
import Loading from '../activity/Loading/Loading';
import StoreInterface from '../../interfaces/store.interface';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';

const WelcomeUser = ({ onGotoDashboard }: { onGotoDashboard: any }) => {
    const { t } = useTranslation();
    const user = useSelector((state: StoreInterface) => state.user);

    return (
        <>
            {!user ? (
                <Loading />
            ) : (
                <Fragment>
                    <div className="welcomeComeUser">
                        <div className="welcomeComeUser_inner">
                            <div className="welcome_img">
                                <Lottie loop={true} animationData={welcomeAnimation} play speed={0.8} />
                            </div>

                            <div className="welcomeComeUser_content">
                                <Typography variant="h2"> {t('lang_common.txt_welcome_to_classPoint')} </Typography>
                                <Typography variant="body1" display="block">
                                    {t('lang_common.txt_welcome_message')}
                                </Typography>
                                <div>
                                    <CommonButton
                                        isLoading={false}
                                        variant="contained"
                                        startIcon={<InsertChartOutlinedIcon />}
                                        text={t('lang_common.txt_see_my_dashboard')}
                                        clickFunction={() => {
                                            onGotoDashboard();
                                        }}
                                    />
                                    {/* <CommonButton
                                        isLoading={false}
                                        variant="contained"
                                        text={t('lang_common.txt_watch_video_tutorials')}
                                        clickFunction={() => {
                                            webviewMessenger.sendSimpleMessage('dashboardToTutorial');
                                            history.replace('/tutorial');
                                        }}
                                    /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
        </>
    );
};

export default WelcomeUser;
