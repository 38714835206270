import axios from 'axios';
import ResponseAndPointsDto from '../dtos/response-and-points.dto';
import apiUser from './apiUser';
import { dataURLtoFile } from '../helpers/utils';
import UserInterface from '../interfaces/user-interface';
import { logger } from './logger';
import { sentryLogApiError } from './sentryService';
import webviewMessenger from './webviewMessenger';

const apiActivities = {
    saveActivitySlide: async (activityId: string, slideImage: string, user: UserInterface | null) => {
        // console.log('saveActivitySlide');
        const fullImageDataBase64 = `data:image/png;base64,${slideImage}`;
        const slideName = `${activityId}.jpg`;
        const slideFile = dataURLtoFile(fullImageDataBase64, slideName);

        let activitySlideSavedUrl = '';
        for (let i = 0; i < 3; i++) {
            activitySlideSavedUrl = await apiUser.uploadToStorage(
                slideFile,
                slideName,
                user?.apiServer || '',
                false,
                'user',
            );
            if (activitySlideSavedUrl) break;
        }

        if (!activitySlideSavedUrl) return;

        try {
            setTimeout(async () => {
                const url = `${process.env.REACT_APP_APITWO}/activities/slide?email=${user?.email}`;
                await axios.post(url, {
                    activityId,
                    activitySlideSavedUrl,
                });
            }, 1000);
        } catch (error: any) {
            logger.error('saveActivitySlide() error: ', error.response || error);
            sentryLogApiError(error, 'saveActivitySlide', {
                body: { activityId, activitySlideSavedUrl },
            });
        }
    },

    getActivity: async (activityId: string, email: string) => {
        try {
            const url = `${process.env.REACT_APP_APITWO}/activities?activityId=${activityId}&email=${email}`;
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            logger.error('getActivity() error: ', error.response || error);
            if (error.response && error.response.status === 404) {
                // console.log('Activity not found, reset button');
                webviewMessenger.sendResetActivityV2(activityId, true);
                return;
            }
            sentryLogApiError(error, 'getActivity', {
                query: { activityId },
            });
            return {};
        }
    },

    getMultipleActivities: async (activityIds: string, email: string) => {
        try {
            const url = `${process.env.REACT_APP_APITWO}/activities/multiple?activityIds=${activityIds}&email=${email}`;
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            logger.error('getMultipleActivities() error: ', error.response || error);
            sentryLogApiError(error, 'getMultipleActivities', {
                query: { activityIds },
            });
            return null;
        }
    },

    updateMcqCorrectPointsAndAnswers: async (messageId: number) => {
        const payloadString = localStorage.getItem('updateMcqCorrectPointsAndAnswers');
        if (!payloadString) return;

        const payload = JSON.parse(payloadString);
        if (payload.messageId !== messageId) return;

        const { email, activityId, mcCorrectAnswers } = payload;
        let correctPoints = payload.correctPoints || 0;

        try {
            const url = `${process.env.REACT_APP_APITWO}/activities/mcq?activityId=${activityId}&email=${email}`;
            await axios.patch(url, { correctPoints, mcCorrectAnswers });
            localStorage.removeItem('updateMcqCorrectPointsAndAnswers');
        } catch (error: any) {
            logger.error('updateMcqCorrectPointsAndAnswers() error: ', error.response || error);
            sentryLogApiError(error, 'updateMcqCorrectPointsAndAnswers', {
                query: { activityId, email },
                body: { correctPoints, mcCorrectAnswers },
            });
        }
    },

    updateFbCorrectAnswers: async (fbMessageId: number) => {
        const payloadString = localStorage.getItem('updateFbCorrectAnswers');
        if (!payloadString) return;

        const payload = JSON.parse(payloadString);
        if (payload.fbMessageId !== fbMessageId) return;

        const { email, activityId, fbCorrectAnswers } = payload;

        try {
            const url = `${process.env.REACT_APP_APITWO}/activities/fb?activityId=${activityId}&email=${email}`;
            await axios.patch(url, { fbCorrectAnswers });
            localStorage.removeItem('updateFbCorrectAnswers');
        } catch (error: any) {
            logger.error('updateFbCorrectAnswers() error: ', error.response || error);
            sentryLogApiError(error, 'updateFbCorrectAnswers', {
                query: { activityId, email },
                body: { fbCorrectAnswers },
            });
        }
    },

    givePointsToResponses: async (
        activityId: string | null,
        email: string,
        responsesAndPoints: ResponseAndPointsDto[],
        updateParticipant: boolean,
        classSessionId?: string,
        savedClassId?: string,
        cachedBy?: string[],
    ): Promise<boolean> => {
        // console.log('givePointsToResponse', points);
        if (!activityId) return false;

        const responsesAndPointsDto = responsesAndPoints.map((item) => ({
            responseId: item.response.responseId,
            participantId: item.response.participantId,
            points: item.points,
        }));
        try {
            const url = `${process.env.REACT_APP_APITWO}/activities/responses/update-points?activityId=${activityId}&email=${email}`;
            await axios.patch(url, {
                responsesAndPoints: responsesAndPointsDto,
                updateParticipant,
                classSessionId,
                savedClassId,
                cachedBy,
            });
            return true;
        } catch (error: any) {
            logger.error('givePointsToResponses() error: ', error.response || error);
            sentryLogApiError(error, 'givePointsToResponses', {
                query: { activityId },
                body: { responsesAndPoints, updateParticipant },
            });
            return false;
        }
    },

    httpDeleteIndividualResponses: async (
        activityId: string,
        email: string,
        responseIds: string[],
        cachedBy: string[],
    ): Promise<void> => {
        try {
            const url = `${process.env.REACT_APP_APITWO}/activities/responses/delete?activityId=${activityId}&email=${email}`;
            await axios.patch(url, {
                responseIds,
                cachedBy,
            });
        } catch (error: any) {
            logger.error('httpDeleteIndividualResponses() error: ', error.response || error);
            sentryLogApiError(error, 'httpDeleteIndividualResponses', {
                query: { activityId },
                body: { responseIds },
            });
        }
    },

    httpDeleteActivity: async (activityId: string, email: string) => {
        console.log(`httpDeleteActivity: ${activityId}`);
        try {
            const url = `${process.env.REACT_APP_APITWO}/activities?activityId=${activityId}&email=${email}`;
            await axios.delete(url);
        } catch (error: any) {
            logger.error('httpDeleteActivity() error: ', error.response || error);
            // sentryLogApiError(error, 'httpDeleteActivity', {
            //     query: { activityId },
            // });
        }
    },
};

export default apiActivities;
