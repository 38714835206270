import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FitToViewport } from 'react-fit-to-viewport';

import { userActions } from '../../actions/user.actions';
import { getValidUserFromStore } from '../../helpers/storeHelpers';

import { TrialFlowViewport } from '../../constants/viewport-constants';

import CommonAlert from '../../components/Common/CommonAlert';
import { ActivateTrial } from '../activateTrial/ActivateTrial';
import { TrialFlow } from '../activateTrial/TrialFlow';
import EmptyModal from '../EmptyModal/EmptyModal';
import { useLocation } from 'react-router-dom';
import { ProPitchSlidesData } from '../../constants/pro-pitch-slides';

export default function GoPro() {
    const dispatch = useDispatch();
    const historyState = useLocation().state;
    const startingSlideId = historyState?.startingSlideId || 'summary_go_pro';
    const isStartingSlideIdValid = ProPitchSlidesData.find((slide) => slide.id === startingSlideId);
    const isInSlider = ProPitchSlidesData.find((slide) => slide.id === startingSlideId)?.isInSlider || false;
    const isShowMultipleSlides = isStartingSlideIdValid ? isInSlider : true;

    const user = getValidUserFromStore('GoPro');
    const isEmailVerified = user.userStats.isEmailVerified;

    const [isActivatingTrial, setIsActivatingTrial] = useState(false);
    const [showEmptyModal, setShowEmptyModal] = useState(false);

    useEffect(() => {
        setShowEmptyModal(true);
        setTimeout(() => {
            setShowEmptyModal(false);
        }, 1);
    }, []);

    return (
        <FitToViewport
            className="viewport"
            width={TrialFlowViewport.width}
            height={TrialFlowViewport.height}
            minZoom={TrialFlowViewport.minZoom}
            maxZoom={TrialFlowViewport.maxZoom}
        >
            {showEmptyModal && <EmptyModal viewportConfig={TrialFlowViewport} />}
            <CommonAlert
                showAlert={isActivatingTrial}
                closeAlert={() => {
                    if (!isActivatingTrial) setIsActivatingTrial(false);
                }}
                content={
                    <div className={isEmailVerified ? `trialActivationMainContainer default centerDiv` : ''}>
                        <ActivateTrial
                            viewport={TrialFlowViewport}
                            isGamificationModal={false}
                            onActivationFailed={() => {
                                setIsActivatingTrial(false);
                                dispatch(userActions.showApiError());
                            }}
                            onSuccessBtnClick={() => {
                                setIsActivatingTrial(false);
                            }}
                            onDismissVerifyEmail={() => {
                                setIsActivatingTrial(false);
                            }}
                        />
                    </div>
                }
                viewportConfig={TrialFlowViewport}
                customModalClassNames="small"
                customBoxClassNames={
                    isActivatingTrial
                        ? isEmailVerified
                            ? `subscriptionTrialFlowModal smallModal`
                            : ''
                        : `savedClassTrialFlowModal`
                }
            />
            <TrialFlow
                isWindowMode={true}
                isShowMultipleSlides={isShowMultipleSlides}
                startingSlideId={startingSlideId}
                isActivatingTrial={isActivatingTrial}
                setIsActivatingTrial={setIsActivatingTrial}
                onTrialActivated={() => setIsActivatingTrial(false)}
                customClassNames="default no_Radius"
            />
        </FitToViewport>
    );
}
