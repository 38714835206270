import { Fragment, useRef } from 'react';
import AudioPlayer from './AudioPlayer';
import LazyLoadingInProgress from '../components/LazyLoadingInProgress';
import ActivityResponseInterface from '../../../interfaces/activity-response.interface';
import { utilConstants } from '../../../constants/utils.constants';

interface AudioRecordContentInterface {
    selectedResponses: ActivityResponseInterface[];
    searchKeyword: string;
    isVoting: boolean;
    sortSelectedResponses: (arg: ActivityResponseInterface[]) => ActivityResponseInterface[];
    isFetchingMore: boolean;
}

function AudioRecordContent({
    selectedResponses,
    searchKeyword,
    isVoting,
    sortSelectedResponses,
    isFetchingMore,
}: AudioRecordContentInterface) {
    const audioPlayerRef = useRef<any>(null);

    const handlePauseOthers = (playingId: string) => {
        audioPlayerRef.current.pauseIfNotCurrent(playingId);
    };

    return (
        <div className="flexslide_audio">
            {sortSelectedResponses(selectedResponses).length > 0 && (
                <div className="inner_short_answer audio_upload">
                    {sortSelectedResponses(selectedResponses).map((response, index) => {
                        return (
                            <Fragment key={response.responseId}>
                                <AudioPlayer
                                    index={index}
                                    dataReseponse={response}
                                    color={
                                        utilConstants.AUDIO_RECORD_COLORS[
                                            index % utilConstants.AUDIO_RECORD_COLORS.length
                                        ]
                                    }
                                    searchKeyword={searchKeyword}
                                    id={response.responseId}
                                    isVoting={isVoting}
                                    // ref={audioPlayerRef}
                                    pauseOthers={handlePauseOthers}
                                />
                            </Fragment>
                        );
                    })}
                </div>
            )}
            {isFetchingMore ? <LazyLoadingInProgress /> : null}
        </div>
    );
}

export default AudioRecordContent;
