import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FitToViewport } from 'react-fit-to-viewport';
import { useTranslation } from 'react-i18next';

import styles from './Qna.module.scss';

import { updateQnaState } from '../../reducers/qna.reducer';
import { refreshQnaAndSort, updateQnasFromClassSession } from '../../helpers/qnaHelpers';

import StoreInterface from '../../interfaces/store.interface';
import { QnaActionType, QnaDataInterface } from '../../interfaces/qna.interface';
import { ViewportConstants } from '../../constants/viewport-constants';

import EmptyModal from '../EmptyModal/EmptyModal';
import Loading from '../activity/Loading/Loading';
import Header from '../../components/layout/Header/Header';
import QnaHeader from './components/common/QnaHeader';
import Footer from '../../components/layout/Footer';
import QuestionList from './components/QuestionList/QuestionList';
import QnaStatus from './components/QnaStatus/QnaStatus';
import NoQuestion from './components/NoQuestion/NoQuestion';

const Qna = () => {
    const { t } = useTranslation();
    const user = useSelector((state: StoreInterface) => state).user;
    const classSession = useSelector((state: StoreInterface) => state.classSession);
    const { activeTab, newQnas, answeredQnas } = useSelector((state: StoreInterface) => state.qna);

    const [showEmptyModal, setShowEmptyModal] = useState(false);
    const [isQnaEnabled, setIsQnaEnabled] = useState(
        !user ? false : user.userPreferences?.enableQna === false ? false : true,
    );

    const classSessionQnaData = classSession?.qnaData as QnaDataInterface;
    const qnasInView = activeTab === 'new' ? newQnas : answeredQnas;

    const isQnaEnabledHandler = (value: boolean) => {
        setIsQnaEnabled(value);
    };

    const refreshQnaState = () => {
        if (!classSession) return;
        const { newQnas, answeredQnas, savedClassGroups } = refreshQnaAndSort(classSession);
        updateQnaState(QnaActionType.REFRESH_QNA, { newQnas, answeredQnas, savedClassGroups });
        if (newQnas.length > 0) updateQnaState(QnaActionType.CHANGE_TAB, { activeTab: 'new' });
    };

    const updateQuestions = () => {
        if (!classSession) return;
        const result = updateQnasFromClassSession(newQnas, answeredQnas, classSessionQnaData);
        updateQnaState(QnaActionType.QNA_UPDATED, {
            newQnas: result.newQnas,
            answeredQnas: result.answeredQnas,
        });
    };

    useEffect(() => {
        updateQuestions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classSession?.qnaData.qnas]);

    useEffect(() => {
        setShowEmptyModal(true);
        setTimeout(() => setShowEmptyModal(false), 1);

        refreshQnaState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!classSession) return <Loading />;
    return (
        <>
            <FitToViewport
                className="viewport qnaViewport"
                width={ViewportConstants.width}
                height={ViewportConstants.height}
                minZoom={ViewportConstants.minZoom}
                maxZoom={ViewportConstants.maxZoom}
            >
                {showEmptyModal && <EmptyModal viewportConfig={ViewportConstants} />}

                <div className={styles.qna}>
                    <div className={styles.head}>
                        <Header appBarTitle={t('lang_qna.qna')}>
                            <QnaHeader />
                        </Header>
                    </div>
                    <div className={styles.body_content}>
                        {qnasInView.length ? (
                            <QuestionList qnas={qnasInView} />
                        ) : (
                            <NoQuestion isQnaEnabled={isQnaEnabled} />
                        )}
                    </div>
                    <QnaStatus isQnaEnabled={isQnaEnabled} />
                    <div className={styles.footer}>
                        <Footer layout="qna" isQnaEnabled={isQnaEnabled} isQnaEnabledHandler={isQnaEnabledHandler} />
                    </div>
                </div>
            </FitToViewport>
        </>
    );
};

export default Qna;
