import { Fragment, ReactNode } from 'react';

import { Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

import './CommonButton.scss';

interface ICommonButton {
    text?: ReactNode;
    type?: 'button' | 'submit' | 'reset' | undefined;
    clickFunction?: any;
    isLoading?: boolean;
    disabled?: boolean;
    onMouseEnter?: any;
    onMouseLeave?: any;
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    variant?: 'text' | 'outlined' | 'contained';
    className?: string;
}

const CommonButton = ({
    text,
    type,
    clickFunction,
    isLoading,
    disabled,
    onMouseEnter,
    onMouseLeave,
    color,
    startIcon,
    endIcon,
    variant,
    className,
}: ICommonButton) => {
    return (
        <Fragment>
            {isLoading ? (
                <LoadingButton
                    loading
                    loadingPosition="start"
                    disabled
                    color={color}
                    startIcon={<SaveIcon />}
                    variant={variant}
                    className={className}
                    disableElevation
                >
                    {text}
                </LoadingButton>
            ) : (
                <Button
                    variant={variant}
                    type={type}
                    color={color}
                    startIcon={startIcon ? startIcon : null}
                    endIcon={endIcon ? endIcon : null}
                    className={className}
                    onClick={clickFunction}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    disabled={disabled}
                    disableElevation
                >
                    {text}
                </Button>
            )}
        </Fragment>
    );
};

export default CommonButton;
