import { useRef } from 'react';
import Textarea from 'react-textarea-autosize';
import { UseChatHelpers } from 'ai/react';

import { CornerDownLeft } from 'lucide-react';
import { useEnterSubmit } from '../hooks/useEnterSubmit';
import { Button } from './button';

export interface PromptProps extends Pick<UseChatHelpers, 'input' | 'setInput'> {
    onSubmit: (value: string) => void;
    isLoading: boolean;
}

export function PromptForm({ onSubmit, input, setInput, isLoading }: PromptProps) {
    const { formRef, onKeyDown } = useEnterSubmit();
    const inputRef = useRef<HTMLTextAreaElement>(null);

    return (
        <form
            onSubmit={async (e) => {
                e.preventDefault();
                if (isLoading) return;
                if (!input?.trim()) {
                    return;
                }
                setInput('');
                await onSubmit(input);
            }}
            ref={formRef}
        >
            <div className="relative flex max-h-60 w-full grow flex-col overflow-hidden rounded-md bg-background">
                <Textarea
                    ref={inputRef}
                    tabIndex={0}
                    onKeyDown={onKeyDown}
                    rows={1}
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder="Send a message"
                    spellCheck={false}
                    className="w-full resize-none rounded-md border border-border text-sm bg-transparent py-2 pl-2 pr-20 text-foreground placeholder:text-ring focus-within:outline-none focus:border-transparent focus:ring-2 focus:ring-inset focus:ring-primary disabled:cursor-not-allowed disabled:bg-muted disabled:text-midway"
                />
                <div className="absolute bottom-[3px] right-1.5">
                    <Button type="submit" size="icon" className="h-7 w-7" disabled={isLoading || input === ''}>
                        <CornerDownLeft size={18} />
                        <span className="sr-only">Send message</span>
                    </Button>
                </div>
            </div>
        </form>
    );
}
