import { getCachedReducer } from './../helpers/storeHelpers';
import { ClassSessionActionTypes } from '../constants/class-session-action-types';
import { PickNamesActionTypes } from '../constants/pick-names-action-types';
import { UserActionTypes } from '../constants/user-action-types';
import PickNamesParticipantInterface from '../interfaces/pick-names-participant.interface';
import { localService } from '../services/localStorageService';
import { ReducerName } from '../constants/reducer-name.enum';

export default function pickNamesReducer(
    state = getCachedPickNamesState(),
    action: { type: string; payload: any },
): PickNamesParticipantInterface[] {
    switch (action.type) {
        case PickNamesActionTypes.INITIALIZE_PICK_NAMES:
        case PickNamesActionTypes.UPDATE_PICK_NAMES:
        case PickNamesActionTypes.PICK_ONE_NAME:
        case PickNamesActionTypes.RESET_PICK_NAMES:
            return action.payload;
        case ClassSessionActionTypes.TRIGGER_RESTART_CLASS:
        case UserActionTypes.HOST_AUTO_UNLOAD_USER:
        case UserActionTypes.AUTO_SIGN_IN_WITH_PASSWORD_FAILED:
        case UserActionTypes.USER_MANUALLY_SIGNED_OUT:
        case UserActionTypes.DEVICE_ID_CHANGED:
        case UserActionTypes.SIGN_OUT_ON_ERROR_BOUNDARY:
            return noPickNamesState;
        default:
            return state;
    }
}

const getCachedPickNamesState = () => {
    const pickNamesCached: PickNamesParticipantInterface[] | null = getCachedReducer(ReducerName.pickNamesList);
    if (pickNamesCached) return pickNamesCached;

    const storeCached = localService.getStoreData();
    if (storeCached && storeCached.pickNamesList) return storeCached.pickNamesList;

    return noPickNamesState;
};

const noPickNamesState: PickNamesParticipantInterface[] = [];
