import { AiQuizInterface, SlideIdAndTextInterface } from '../interfaces/aiQuiz.interface';
import { AiQuizActionTypes } from '../constants/ai-quiz-action-types';
import { store } from '../helpers/store';

const updateActiveSlideIdAndText = (value: SlideIdAndTextInterface) => {
    return async (dispatch: (arg0: { type: string; payload: AiQuizInterface }) => void) => {
        const aiQuizState = store.getState().aiQuiz;
        const payload = { ...aiQuizState };
        payload.props = {
            activeSlideId: value.currentSlideId,
            activeSlideText: value.currentSlideText,
            activeSlideNote: value.currentSlideNote,
        };
        dispatch({ type: AiQuizActionTypes.UPDATE_ACTIVE_SLIDE_ID_AND_TEXT, payload });
    };
};

function initializeAiQuizForCurrentSlide(currentSlideId: number, currentSlideText: string, currentSlideNote: string) {
    return async (dispatch: (arg0: { type: string; payload: AiQuizInterface }) => void) => {
        const aiQuizState = store.getState().aiQuiz;
        const payload = { ...aiQuizState };
        payload[currentSlideId] = {
            slideText: currentSlideText,
            slideNote: currentSlideNote,
            questionList: [],
            currentQuestionIndex: 0,
            latestQuestionTimestamp: 0,
            hasGenerationDone: false,
        };

        dispatch({ type: AiQuizActionTypes.INITIALIZE_AI_QUIZ_FOR_CURRENT_SLIDE, payload });
    };
}

const addNewQuestion = (payload: AiQuizInterface) => ({ type: AiQuizActionTypes.NEW_QUESTION, payload });

const quizGenerationDone = (payload: AiQuizInterface) => ({ type: AiQuizActionTypes.QUIZ_GENERATION_DONE, payload });

function markViewed(currentSlideId: number, currentQuestionIndex: number) {
    return async (dispatch: (arg0: { type: string; payload: AiQuizInterface }) => void) => {
        const aiQuizState = store.getState().aiQuiz;
        const payload = {
            ...aiQuizState,
            [currentSlideId]: {
                ...aiQuizState[currentSlideId],
                questionList: aiQuizState[currentSlideId].questionList.map((question, index) => {
                    if (index === currentQuestionIndex) question.viewed = true;
                    return question;
                }),
            },
        };

        dispatch({ type: AiQuizActionTypes.MARK_VIEWED, payload });
    };
}

function changeCurrentQuestionIndex(currentSlideId: number, newIndex: number) {
    return async (dispatch: (arg0: { type: string; payload: AiQuizInterface }) => void) => {
        const aiQuizState = store.getState().aiQuiz;
        const payload = { ...aiQuizState };
        payload[currentSlideId].currentQuestionIndex = newIndex;
        dispatch({ type: AiQuizActionTypes.CHANGE_CURRENT_QUESTION_INDEX, payload });
    };
}

function showQuestionAnswer(currentSlideId: number, currentQuestionIndex: number) {
    return async (dispatch: (arg0: { type: string; payload: AiQuizInterface }) => void) => {
        const aiQuizState = store.getState().aiQuiz;
        const payload = {
            ...aiQuizState,
            [currentSlideId]: {
                ...aiQuizState[currentSlideId],
                questionList: aiQuizState[currentSlideId].questionList.map((question, index) => {
                    if (index === currentQuestionIndex) question.showAnswer = true;
                    return question;
                }),
            },
        };

        dispatch({ type: AiQuizActionTypes.SHOW_QUESTION_ANSWER, payload });
    };
}

function resetAiQuizForCurrentSlide(currentSlideId: number) {
    return async (dispatch: (arg0: { type: string; payload: AiQuizInterface }) => void) => {
        const aiQuizState = store.getState().aiQuiz;
        const payload = { ...aiQuizState };
        delete payload[currentSlideId];
        dispatch({ type: AiQuizActionTypes.RESET_AI_QUIZ_FOR_CURRENT_SLIDE, payload });
    };
}

function resetAiQuiz() {
    return async (dispatch: (arg0: { type: string }) => void) => {
        dispatch({ type: AiQuizActionTypes.RESET_AI_QUIZ });
    };
}

export const aiQuizActions = {
    updateActiveSlideIdAndText,
    initializeAiQuizForCurrentSlide,
    addNewQuestion,
    quizGenerationDone,
    markViewed,
    changeCurrentQuestionIndex,
    showQuestionAnswer,
    resetAiQuizForCurrentSlide,
    resetAiQuiz,
};
