import { AnimationControls } from 'framer-motion';

export const aiIconAnimation = (
    bgHappySquare_1_Animation: AnimationControls,
    bgHappySquare_2_Animation: AnimationControls,
    rightEyeOpenAnimation: AnimationControls,
    rightEyeCloseAnimation: AnimationControls,
) => {
    // ===== Initial States =====
    const animationConstantArray = {
        bgHappySquare_1_Initial: {
            rotate: 0,
        },

        bgHappySquare_2_Initial: {
            rotate: 0,
        },

        rightEyeOpenInitial: {
            opacity: 1,
        },

        rightEyeCloseInitial: {
            opacity: 0,
        },
    };

    const idleAnimationTransition = {
        duration: 3,
        ease: 'linear',
    };

    // ===== Animation on Idle =====
    const animateBg_1 = () => {
        bgHappySquare_1_Animation.start({
            rotate: [
                0, 0, 0, 0, 0, 0, 0, 0, 0, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 90, 180, 180, 180,
                180, 180, 180, 180, 180, 180, 180, 180, 180, 180, 180, 180, 270, 270, 270, 270, 270, 270, 270, 270, 270,
                270, 270, 270, 270, 270, 270, 360, 360, 360, 360, 360, 360,
            ],
            transition: {
                duration: 12,
                ease: 'linear',
            },
        });
    };

    const animateBg_2 = () => {
        bgHappySquare_2_Animation.start({
            rotate: [
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 180, 180, 180, 180, 180, 180, 180, 180, 180, 180, 180, 180, 180, 180,
                180, 360, 360, 360, 360,
            ],
            transition: {
                duration: 6,
                ease: 'linear',
            },
        });
    };

    const animateRightEyeOpen = () => {
        rightEyeOpenAnimation.start({
            opacity: [1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 1, 1],
            transition: idleAnimationTransition,
        });
    };

    const animateRightEyeClose = () => {
        rightEyeCloseAnimation.start({
            opacity: [0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 0, 0],
            transition: idleAnimationTransition,
        });
    };

    return {
        animationConstants: animationConstantArray,
        animateBg_1: animateBg_1,
        animateBg_2: animateBg_2,
        animateRightEyeOpen: animateRightEyeOpen,
        animateRightEyeClose: animateRightEyeClose,
    };
};
