import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import webviewMessenger from '../../services/webviewMessenger';
import WatchTutorialButton from './components/WatchTutorialButton';
import VideoModal from '../VideoTutorial/Components/VideoModal';
import { ViewportSavedClasses } from '../../constants/viewport-constants';

const SavedClassesWelcome = ({ changeCurrentPage }: { changeCurrentPage: (arg: string) => void }) => {
    const { t } = useTranslation();

    const [tutorialVideoId, setTutorialVideoId] = useState('');

    return (
        <Fragment>
            {tutorialVideoId && (
                <VideoModal
                    tutorialVideoId={tutorialVideoId}
                    setTutorialVideoId={setTutorialVideoId}
                    viewportConstant={ViewportSavedClasses}
                />
            )}
            <div className="addingClass_inner">
                <div className="manageClass_src">
                    <div className="manageText">
                        <Typography variant="h2">{t('lang_saved_classes.manage_classes')}</Typography>
                        <Typography variant="body1">{t('lang_saved_classes.manage_classes_detalis')}</Typography>
                    </div>
                    <div
                        className="addClass_box"
                        onClick={() => {
                            changeCurrentPage('AddClass');
                            webviewMessenger.sendUsageLog('Clicked "Add first class"');
                        }}
                    >
                        <div className="addClass_icon">
                            <AddIcon />
                        </div>
                        <div className="addClass_text">
                            <Typography variant="h5">{t('lang_saved_classes.add_first_class')}</Typography>
                        </div>
                    </div>
                    <WatchTutorialButton
                        onClickHandler={() => {
                            webviewMessenger.sendUsageLog(`Clicked "Watch tutorial" (overview_gamification)`);
                            setTutorialVideoId('overview_gamification');
                        }}
                        isWhiteIcon={true}
                        className="savedClassWelcome"
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default SavedClassesWelcome;
