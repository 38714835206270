import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';

import './LeaderBoardGroups.scss';

import { decodeGroupColorHash } from '../../../../helpers/savedClassGroupHelper';

import { LeaderboardGroupRankInterface } from '../../../../interfaces/leaderboard.interface';
import { NO_RANK_DISPLAY, utilConstants } from '../../../../constants/utils.constants';

import emptyFace from '../../../../assets/animation/empty-face.json';

interface LeaderBoardCurrentGroupsInterface {
    groupRankStats?: LeaderboardGroupRankInterface;
}

export default function LeaderBoardCurrentGroups({ groupRankStats }: LeaderBoardCurrentGroupsInterface) {
    const { t } = useTranslation();

    const barWidthCalculator = (groupStarsCount: number) => {
        if (!groupRankStats) return;
        const maxWidth = 490;
        const maxStarCount = groupRankStats.currentStarsArray[0];
        const width = (maxWidth / maxStarCount) * groupStarsCount;
        const minWidth = Math.max(width, 160);
        return groupStarsCount ? `${minWidth}px` : 40;
    };

    return (
        <div className={`leaderBoardCurrentGroupsContent`}>
            <div className={`header`}>
                <p className={`text`}>{t('lang_grouping.group_rank')}</p>
            </div>

            <div className={`leaderBoardRankBody`}>
                <div className={`currentRankContent`}>
                    {groupRankStats ? (
                        groupRankStats.groupStatsOrderByCurrent.map((group, index) => (
                            <div
                                key={index}
                                className={`leaderboardGroupRankBar ${!group.currentStarsCount && 'outside'}`}
                            >
                                <div className={`positionCount`}>
                                    {Math.max(...groupRankStats.currentStarsArray) <= 0
                                        ? NO_RANK_DISPLAY
                                        : groupRankStats.currentStarsArray.indexOf(group.currentStarsCount) + 1}
                                </div>
                                <div
                                    style={{
                                        width: barWidthCalculator(group.currentStarsCount),
                                        background: decodeGroupColorHash(group.groupColor),
                                    }}
                                    className={`bar`}
                                >
                                    <p className={`groupName`}>{group.groupName}</p>
                                </div>

                                <div className={`currentClassGroupDetails`}>
                                    <img
                                        src={utilConstants.IMAGE_URLS.starIcon}
                                        className={`starIcon`}
                                        alt="starIcon"
                                    />
                                    <p className={`starCount`}>{group.currentStarsCount}</p>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className={`leaderboardNoGroupView`}>
                            <Lottie loop={true} animationData={emptyFace} play speed={1} />{' '}
                            <p className={`noGroupText`}>{t('lang_grouping.no_group')}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
