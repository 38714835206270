import { useState, useEffect, useRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import CustomAvatar, { AvatarType } from '../../../components/Common/CustomAvatar';
import { SparkleLottie } from '../../../components/Common/AnimationModules';
import GivePointButton from '../../../components/Common/GivePointButton';
import ParticipantAndPointsDto from '../../../dtos/participant-and-points.dto';
import { classSessionActions } from '../../../actions/class-session.action';
import { AnimationConfig, PickNamesCardFlipAnimation } from '../../../constants/animation-configs';
import PickNamesParticipantInterface from '../../../interfaces/pick-names-participant.interface';
import { pickNamesActions } from '../../../actions/pickNames.action';
import { getCardImageUrl } from '../../../helpers/pickNamesHelpers';
import useSound from 'use-sound';
import { SoundLibrary } from '../../../constants/sound-library';
import { UpdateToolbarActionsType } from '../../../dtos/update-toolbar-actions.dto';
import { localService } from '../../../services/localStorageService';
import StoreInterface from '../../../interfaces/store.interface';

interface PickNamesFlipCardInterface {
    participant: PickNamesParticipantInterface;
    isAutoPicking: boolean;
    flipOrderIndex: number;
    onClickHandler: any;
    givePointsConfettiRef: any;
}

function PickNamesFlipCard({
    participant,
    isAutoPicking,
    flipOrderIndex,
    onClickHandler,
    givePointsConfettiRef,
}: PickNamesFlipCardInterface) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const classSession = useSelector((state: StoreInterface) => state.classSession);

    const [playCardFlip] = useSound(SoundLibrary.CARD_FLIP);
    const timeOutPointUpdate = useRef<any>();
    const sparkleRef = useRef<any>();

    const [pointsAdded, setPointsAdded] = useState(0);
    const [isFlipping, setIsFlipping] = useState(isAutoPicking);

    const givePoint = (points: number) => {
        const participantsAndPoints: ParticipantAndPointsDto[] = [
            { participantId: participant.participantId, points: points },
        ];
        localService.updateToolbarActionsFromWebview(UpdateToolbarActionsType.awardStar);
        dispatch(classSessionActions.givePointsToParticipants(participantsAndPoints));
    };

    const givePointHandler = () => {
        clearTimeout(timeOutPointUpdate.current);
        // sparkleRef.current.playLottieAnimation(true);
        givePointsConfettiRef.current.playLottieAnimation(true);
        setPointsAdded(pointsAdded + 1);
    };

    useEffect(() => {
        if (pointsAdded > 0) {
            timeOutPointUpdate.current = setTimeout(() => {
                givePoint(pointsAdded);
                setPointsAdded(0);
                setTimeout(() => {
                    // if (sparkleRef.current) sparkleRef.current.playLottieAnimation(false);
                    if (givePointsConfettiRef.current) givePointsConfettiRef.current.playLottieAnimation(false);
                    // }, AnimationConfig.SPARKLE_LOTTIE_DURATION - AnimationConfig.GIVE_POINT_DELAY);
                }, AnimationConfig.CONFETTI_LOTTIE_DURATION - AnimationConfig.GIVE_POINT_DELAY);
            }, AnimationConfig.GIVE_POINT_DELAY);
        }
        return () => clearTimeout(timeOutPointUpdate.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pointsAdded]);

    useEffect(() => {
        const timeoutID = setTimeout(() => {
            if (isAutoPicking && !participant.isPicked) {
                dispatch(pickNamesActions.pickOneName(participant.participantId, true));
            }
        }, 50);
        return () => window.clearTimeout(timeoutID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            {isFlipping ? (
                <div className="pickNumber_service">
                    <div className="flip-box">
                        <motion.div
                            className={`flip-box-inner`}
                            animate={
                                participant.isPicked
                                    ? PickNamesCardFlipAnimation(participant.isPicked, flipOrderIndex, 'flipped')
                                    : PickNamesCardFlipAnimation(participant.isPicked, flipOrderIndex, 'notFlipped')
                            }
                            onAnimationStart={() => {
                                if (participant.isPicked) playCardFlip();
                            }}
                            onAnimationComplete={() => {
                                setTimeout(() => {
                                    setIsFlipping(false);
                                }, 400);
                            }}
                        >
                            <div className={`flip-box-front`}>
                                <img src={getCardImageUrl(participant.cardIndex)} alt="object" />
                            </div>

                            <div
                                className={`flip-box-back autoPickNameSparkleLottie`}
                                style={{ border: `4px solid ${participant.colorHex}` }}
                            >
                                <CustomAvatar
                                    avatarName={participant.participantName}
                                    avatarUrl={participant.participantAvatar}
                                    type={AvatarType.PARTICIPANT}
                                    savedClassGroups={classSession?.groups}
                                    groupId={participant.groupId}
                                />
                                <p className={`profile_name ${isAutoPicking ? 'autoPickModal' : 'pickNamesCardView'}`}>
                                    {participant.participantName}
                                </p>

                                <SparkleLottie ref={sparkleRef} />

                                <div className="single_point_btn">
                                    <GivePointButton
                                        label={t('lang_activity.give_points')}
                                        activity="autoPickNames"
                                        onClick={givePointHandler}
                                    />
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </div>
            ) : (
                <div
                    className="pickNumber_service"
                    onClick={() => {
                        if (participant.isPicked) return onClickHandler();
                        setIsFlipping(true);
                        onClickHandler();
                    }}
                >
                    <div className="flip-box">
                        <div className={`flip-box-inner no_transform`}>
                            {participant.isPicked ? (
                                <div
                                    className={`flip-box-back autoPickNameSparkleLottie no_transform`}
                                    style={{ border: `4px solid ${participant.colorHex}` }}
                                >
                                    <CustomAvatar
                                        avatarName={participant.participantName}
                                        avatarUrl={participant.participantAvatar}
                                        type={AvatarType.PARTICIPANT}
                                        savedClassGroups={classSession?.groups}
                                        groupId={participant.groupId}
                                    />
                                    <p
                                        className={`profile_name ${
                                            isAutoPicking ? 'autoPickModal' : 'pickNamesCardView'
                                        }`}
                                    >
                                        {participant.participantName}
                                    </p>

                                    <SparkleLottie ref={sparkleRef} />

                                    <div className="single_point_btn">
                                        <GivePointButton
                                            label={t('lang_activity.give_points')}
                                            activity="autoPickNames"
                                            onClick={givePointHandler}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className={`flip-box-front no_transform`}>
                                    <img src={getCardImageUrl(participant.cardIndex)} alt="object" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
}

export default PickNamesFlipCard;
