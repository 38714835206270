import { useDispatch, useSelector } from 'react-redux';
import { motion, useAnimation } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { aiQuizActions } from '../../../actions/aiQuiz.action';
import { getValidUserFromStore } from '../../../helpers/storeHelpers';
import { BT, generateQuestionsFromSlide, QT, calculateTotalAiQuestionsGenerated } from '../../../helpers/aiQuiz.helper';

import StoreInterface from '../../../interfaces/store.interface';
import { utilConstants } from '../../../constants/utils.constants';

import CommonButton from '../../../components/Common/CommonButton';
import { aiWelcomeAnimation } from './animation/Welcome.animation';
import OptionModal from './OptionModal';
import FreeCreditsModal from './FreeCredit';
import { AiIcon } from './AiIcon';
import { UpdateToolbarActionsType } from '../../../dtos/update-toolbar-actions.dto';
import { localService } from '../../../services/localStorageService';
import { QuotaConstants } from '../../../constants/quota-constants';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MonthlyQuotaModal from './MonthlyQuotaModal';
import { isUserOnPremium, isUserOnTrialOrPro } from '../../../helpers/userhelper';

interface SubscriptionLandingInterface {
    setCurrentPage: (arg: string) => void;
    questionTypes: QT[];
    setQuestionTypes: (arg: QT[]) => void;
    btLevels: BT[];
    setBtLevels: (arg: BT[]) => void;
    questionLanguage: string;
    setQuestionLanguage: (arg: string) => void;
    setErrorMessage: (arg: string) => void;
}

export default function Welcome({
    setCurrentPage,
    questionTypes,
    setQuestionTypes,
    btLevels,
    setBtLevels,
    questionLanguage,
    setQuestionLanguage,
    setErrorMessage,
}: SubscriptionLandingInterface) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = getValidUserFromStore('AiQuizWelcome');
    const aiQuizStateProps = useSelector((state: StoreInterface) => state.aiQuiz).props;
    const isUserTrialOrPro = isUserOnTrialOrPro(user);
    const isPremium = isUserOnPremium(user);
    const aiQuotaMonthlyCount = localService.getCurrentMonthAiConsumption(user.email);

    const AiIconAnimationRef = useRef<any>(null);

    const textAnimation = useAnimation();
    const btnAnimation = useAnimation();

    const [isOpenOptionModal, setIsOpenOptionModal] = useState(false);
    const [isFreeCreditsModalOpen, setIsFreeCreditsModalOpen] = useState(false);
    const [isMonthlyQuotaModalOpen, setIsMonthlyQuotaModalOpen] = useState(false);

    const aiWelcomeAnimationConfig = aiWelcomeAnimation(textAnimation, btnAnimation);

    useEffect(() => {
        setTimeout(() => AiIconAnimationRef.current?.idleAnimation());
    }, []);

    const generateQuestionHandler = () => {
        // const isLimitReached =
        //     localService.getCurrentDayAiConsumption(user.email) >= QuotaConstants.PRO_USER_AI_DAILY_LIMIT;
        // if (isLimitReached) {
        //     setErrorMessage(
        //         t(`lang_ai_quiz.welcome.pro_daily_limit_reached`, {
        //             dailyLimit: QuotaConstants.PRO_USER_AI_DAILY_LIMIT,
        //         }),
        //     );
        // }

        if (!aiQuizStateProps.activeSlideText || aiQuizStateProps.activeSlideText.length < 2) {
            setErrorMessage(t(`lang_ai_quiz.welcome.not_enough_text`));
        } else {
            dispatch(
                aiQuizActions.initializeAiQuizForCurrentSlide(
                    aiQuizStateProps.activeSlideId,
                    aiQuizStateProps.activeSlideText,
                    aiQuizStateProps.activeSlideNote,
                ),
            );
            generateQuestionsFromSlide(
                dispatch,
                {
                    currentSlideId: aiQuizStateProps.activeSlideId,
                    currentSlideText: aiQuizStateProps.activeSlideText,
                    currentSlideNote: aiQuizStateProps.activeSlideNote,
                },
                btLevels,
                questionTypes,
                questionLanguage,
            );
            localService.updateToolbarActionsFromWebview(UpdateToolbarActionsType.aiProcessSlide);
            localService.updateToolbarActionsFromWebview(UpdateToolbarActionsType.generateAiQuestion);
            localService.incrementCurrentDayAiConsumption(user.email);
        }
        aiWelcomeAnimationConfig.startAnimation();
        setTimeout(() => setCurrentPage(utilConstants.AI_QUIZ_PAGE_TYPE.QUESTION), 500);
    };

    return (
        <div className="chat_bot_Welcome_sec">
            <div className="new_Welcome_inner_sec">
                <div className="new_welcome_content">
                    <div className="icon">
                        <div className="staticAiIcon">
                            <AiIcon ref={AiIconAnimationRef} actionType="idle" />
                        </div>
                    </div>

                    <motion.div
                        initial={aiWelcomeAnimationConfig.animationConstants.textInitial}
                        animate={textAnimation}
                        className="head_sec"
                    >
                        <h2 className="title">{t(`lang_ai_quiz.welcome.title`)}</h2>
                        <p className="description">{t(`lang_ai_quiz.welcome.description`)}</p>
                    </motion.div>

                    <motion.div
                        initial={aiWelcomeAnimationConfig.animationConstants.buttonInitial}
                        animate={btnAnimation}
                        className="navigation_grid_box"
                    >
                        <div className="ai_btn_group">
                            <CommonButton
                                isLoading={false}
                                type="button"
                                className="blue_btn"
                                variant="contained"
                                clickFunction={() => {
                                    if (
                                        !isUserTrialOrPro &&
                                        calculateTotalAiQuestionsGenerated(user) >= QuotaConstants.BASIC_USER_AI_CREDITS
                                    ) {
                                        setIsFreeCreditsModalOpen(true);
                                    } else if (
                                        isUserTrialOrPro &&
                                        !isPremium &&
                                        aiQuotaMonthlyCount >= QuotaConstants.PRO_USER_AI_MONTHLY_LIMIT
                                    ) {
                                        setIsMonthlyQuotaModalOpen(true);
                                    } else generateQuestionHandler();
                                }}
                                text={t(`lang_ai_quiz.welcome.generate_question`)}
                            />
                            <button className="option_btn" onClick={() => setIsOpenOptionModal(true)}>
                                {t(`lang_ai_quiz.welcome.options`)}
                            </button>
                        </div>
                    </motion.div>

                    <OptionModal
                        openLiveStatusModal={isOpenOptionModal}
                        questionTypes={questionTypes}
                        setQuestionTypes={setQuestionTypes}
                        btLevels={btLevels}
                        setBtLevels={setBtLevels}
                        questionLanguage={questionLanguage}
                        setQuestionLanguage={setQuestionLanguage}
                        handleClose={() => setIsOpenOptionModal(false)}
                    />
                </div>

                {isUserTrialOrPro && !isPremium && (
                    <div onClick={() => setIsMonthlyQuotaModalOpen(true)} className="info_icon">
                        <InfoOutlinedIcon />
                    </div>
                )}
            </div>

            {!isUserTrialOrPro && (
                <div className="free_credits" onClick={() => setIsFreeCreditsModalOpen(true)}>
                    <p className="text">
                        <img src={utilConstants.CHATBOT.BIRTHDAY_CONFETTI} alt="BIRTHDAY_CONFETTI" />
                        {t(`lang_ai_quiz.welcome.free_credits_used`)} : {calculateTotalAiQuestionsGenerated(user)}/
                        {QuotaConstants.BASIC_USER_AI_CREDITS}
                    </p>
                </div>
            )}

            <FreeCreditsModal isOpen={isFreeCreditsModalOpen} handleClose={() => setIsFreeCreditsModalOpen(false)} />

            <MonthlyQuotaModal isOpen={isMonthlyQuotaModalOpen} handleClose={() => setIsMonthlyQuotaModalOpen(false)} />
        </div>
    );
}
