import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactAudioPlayer from 'react-audio-player';
import { Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

import StoreInterface from '../../../interfaces/store.interface';
import { getRemainingSeconds, getPercentageValue, getProgressColors } from '../../../helpers/timerHelpers';
import { SoundLibrary } from '../../../constants/sound-library';

interface TimerCountDownInterface {
    isRunning: boolean;
    setIsTimesup: (arg: boolean) => void;
    currentTimeRef?: any;
    isPlaySound: boolean;
    setIsPlaySound: (arg: boolean) => void;
}

export default function TimerCountDown({
    isRunning,
    setIsTimesup,
    currentTimeRef,
    isPlaySound,
    setIsPlaySound,
}: TimerCountDownInterface) {
    const timerStopwatch = useSelector((state: StoreInterface) => state.timerStopwatch);
    const totalSeconds = timerStopwatch.timerTime;
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    const [renderTimer, setRenderTimer] = useState(new Date());

    const remainingSeconds = isRunning
        ? timerStopwatch.isTimerPaused
            ? Math.floor((timerStopwatch.timerEndTime - timerStopwatch.timerPauseTime) / 1000)
            : getRemainingSeconds(timerStopwatch.timerEndTime)
        : timerStopwatch.timerTime;

    const remainingTimeDisplay = new Date(Math.max(0, remainingSeconds) * 1000).toISOString().slice(11, 19);

    const hourDisplay = parseInt(remainingTimeDisplay.slice(0, 2));
    const minuteDisplay = parseInt(remainingTimeDisplay.slice(3, 5));
    const secondDisplay = parseInt(remainingTimeDisplay.slice(6, 8));

    const progressBarValue = {
        percent: getPercentageValue(remainingSeconds, totalSeconds),
        className: getProgressColors(remainingSeconds).className,
    };

    useEffect(() => {
        if (remainingSeconds <= 0 && !timerStopwatch.isWindowCollapsed) setIsPlaySound(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remainingSeconds]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!timerStopwatch.isTimerPaused) {
                setRenderTimer(new Date());
                if (currentTimeRef) {
                    currentTimeRef.current = timerStopwatch.isTimerPaused
                        ? Math.floor((timerStopwatch.timerEndTime - timerStopwatch.timerPauseTime) / 1000)
                        : getRemainingSeconds(timerStopwatch.timerEndTime);
                }
            }
        }, 1000);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timerStopwatch.isTimerPaused]);

    return (
        <Fragment>
            {isPlaySound && (
                <ReactAudioPlayer
                    src={SoundLibrary.TIMER_TIMEUP_SOUNDS[timerStopwatch.timeupSoundId]}
                    autoPlay={true}
                    onEnded={() => setIsTimesup(true)}
                />
            )}

            <div className={`number ${progressBarValue.className}`}>
                <Typography variant="h4">
                    {hourDisplay > 0 && (
                        <>
                            {hourDisplay}
                            <span>h</span>
                        </>
                    )}
                    {minuteDisplay > 0 && (
                        <>
                            {minuteDisplay}
                            <span>m</span>
                        </>
                    )}
                    {secondDisplay > 0 && (
                        <>
                            {secondDisplay}
                            <span>s</span>
                        </>
                    )}

                    {remainingSeconds <= 0 && (
                        <>
                            0<span>s</span>
                        </>
                    )}
                </Typography>
            </div>
            <div className="line_progress">
                <LinearProgress
                    className={progressBarValue.className}
                    value={progressBarValue.percent}
                    variant="determinate"
                />
            </div>
        </Fragment>
    );
}
