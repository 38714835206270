import { Typography } from '@mui/material';
import { Fragment, useState } from 'react';
import CommonButton from '../../../../components/Common/CommonButton';
import apiUser from '../../../../services/apiUser';
import { getValidUserFromStore } from '../../../../helpers/storeHelpers';
import { getRemainingProDays, validateDelay } from '../../../../helpers/userhelper';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { userActions } from '../../../../actions/user.actions';
import { UserActionTypes } from '../../../../constants/user-action-types';

import { utilConstants } from '../../../../constants/utils.constants';
import SubscriptionBackButton from './SubscriptionBackButton';
import VerifyKeyOutDto from '../../../../dtos/verify-key-out.dto';
import { displayLocaleDate } from '../../../../helpers/languageHelpers';

const ConfirmProductKey = ({ verifiedKey, setCurrentPage }: { verifiedKey: VerifyKeyOutDto; setCurrentPage: any }) => {
    const dispatch = useDispatch();
    const user = getValidUserFromStore('ConfirmProductKey');
    const [isProcessing, setIsProcessing] = useState(false);

    const { t } = useTranslation();

    const confirmVerifyKey = async () => {
        setIsProcessing(true);
        const applyKeyReply = await apiUser.applyKey(user.email, verifiedKey.key || '');
        if (!applyKeyReply) {
            setIsProcessing(false);
            dispatch(userActions.showApiError());
            return;
        }
        setTimeout(() => {
            setIsProcessing(false);
            dispatch(userActions.updateUser(UserActionTypes.PRODUCT_KEY_APPLIED, applyKeyReply.updatedUser));
            setCurrentPage(utilConstants.SUBSCRIPTION_PAGE_TYPE.Page_Acknologement);
        }, validateDelay(applyKeyReply.duration));
    };

    return (
        <Fragment>
            <SubscriptionBackButton
                setCurrentPage={setCurrentPage}
                previousPage={utilConstants.SUBSCRIPTION_PAGE_TYPE.Add_Key}
            />
            <div className="change_product_key_sec">
                <div className="product_key_text">
                    <Fragment>
                        <Typography variant="h4">{t('lang_subscription.txt_confirm_product_key')}</Typography>
                        <Typography variant="h5">{verifiedKey.key}</Typography>
                        <Typography variant="body1">
                            {verifiedKey.organization ? (
                                <Fragment>
                                    {verifiedKey.isTrial
                                        ? t('lang_subscription.txt_valid_trial_organization_key_msg', {
                                              organization: verifiedKey.organization,
                                              planExpiryDate: displayLocaleDate(
                                                  new Date(verifiedKey.planExpiryDate || '0000-01-01'),
                                              ),
                                          })
                                        : t('lang_subscription.txt_valid_organization_key_msg', {
                                              organization: verifiedKey.organization,
                                              planExpiryDate: displayLocaleDate(
                                                  new Date(verifiedKey.planExpiryDate || '0000-01-01'),
                                              ),
                                          })}
                                </Fragment>
                            ) : (
                                <Fragment>
                                    {verifiedKey.isTrial
                                        ? t('lang_subscription.txt_valid_standard_trial_key_msg', {
                                              validity: getRemainingProDays(verifiedKey.planExpiryDate),
                                          })
                                        : t('lang_subscription.txt_valid_standard_key_msg', {
                                              validity: getRemainingProDays(verifiedKey.planExpiryDate),
                                          })}
                                </Fragment>
                            )}
                        </Typography>
                    </Fragment>
                </div>

                <div className="profile_update_btn">
                    <CommonButton
                        isLoading={isProcessing}
                        variant="contained"
                        type="button"
                        color="primary"
                        // className="danger_btn"
                        text={
                            isProcessing ? t('lang_subscription.btn_activating') : t('lang_subscription.btn_activate')
                        }
                        clickFunction={() => {
                            confirmVerifyKey();
                        }}
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default ConfirmProductKey;
