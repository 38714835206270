import { useRef, useState, useEffect, forwardRef } from 'react';
import ReactWordcloud from 'react-wordcloud';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FitToViewport } from 'react-fit-to-viewport';
import _ from 'lodash';
import { motion } from 'framer-motion';

import { Button, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import './WordCloud.scss';

import { canEditResponses, getWordCloudTopAnswerMinimumCount } from '../../../helpers/activityHelpers';
import { getActivityFromStore, getValidUserFromStore } from '../../../helpers/storeHelpers';
import webviewMessenger from '../../../services/webviewMessenger';
import { activityActions } from '../../../actions/activity.action';

import ActivityResponseInterface from '../../../interfaces/activity-response.interface';
import ParticipantChipInterface from '../../../interfaces/participant-chip.interface';
import StoreInterface from '../../../interfaces/store.interface';
import { utilConstants } from '../../../constants/utils.constants';
import { ViewportConstants } from '../../../constants/viewport-constants';
import { AnimationConfig, SimpleFadeAnimation } from '../../../constants/animation-configs';

import GivePointButton from '../../../components/Common/GivePointButton';
import CommonChip from '../../../components/Common/CommonChip';
import { FullScreenPointLottie } from '../../../components/Common/AnimationModules';
import SearchBox from '../components/SearchBox';
import ShowingResponsesCount from '../../../components/Common/ShowingResponsesCount';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const options1: any = {
    colors: utilConstants.COLORS,
    enableTooltip: true,
    deterministic: true,
    fontFamily: 'poppins',
    fontSizes: [30, 80],
    fontStyle: 'normal',
    fontWeight: 'bold',
    padding: 4,
    rotations: 1,
    rotationAngles: [0, 90],
    scale: 'n',
    spiral: 'archimedean',
    transitionDuration: 0,
    tooltipOptions: {
        allowHTML: true,
        theme: 'translucent',
        offset: [0, -10],
    },
};

const options2: any = {
    colors: utilConstants.COLORS,
    enableTooltip: true,
    deterministic: true,
    fontFamily: 'poppins',
    fontSizes: [15, 80],
    fontStyle: 'normal',
    fontWeight: 'bold',
    padding: 2,
    rotations: 1,
    rotationAngles: [0, 90],
    scale: 'n',
    spiral: 'archimedean',
    transitionDuration: 0,
    tooltipOptions: {
        allowHTML: true,
        theme: 'translucent',
        offset: [0, -10],
    },
};

interface WordInterface {
    text: string;
    count: number;
    value: number;
    participants: ParticipantChipInterface[];
}

const WordCloudContent = ({ isHighlightTopAnswers }: any, ref: any) => {
    const dispatch = useDispatch();
    const user = getValidUserFromStore('WordCloudContent');
    const classSession = useSelector((state: StoreInterface) => state.classSession);
    const activity = getActivityFromStore();
    const activityResponses = activity.activityResponses;
    const canEditResponsesInView = canEditResponses(activity, user.email);
    const [wordCloudList, setWordCloudList] = useState<WordInterface[]>([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [answerInModal, setAnswerInModal] = useState('');
    const [participantChips, setParticipantChips] = useState<ParticipantChipInterface[]>([]);
    const [topAnswerCount, setTopAnswerCount] = useState(0);

    const { t } = useTranslation();

    const timeOutPointUpdate = useRef<any>();
    const givePointsConfettiRef = useRef<any>();
    const [pointsAdded, setPointsAdded] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        generateWordsList(activityResponses);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activityResponses]);

    const generateWordsList = (activityResponses: ActivityResponseInterface[]) => {
        // const startTime = new Date();
        const uniqueWords = _.uniq(activityResponses.map((response) => response.responseData.trim().toLowerCase()));
        let wcList: WordInterface[] = uniqueWords.map((word: string) => ({
            text: word,
            count: 0,
            value: 5,
            participants: [],
        }));
        for (let response of activityResponses) {
            const wordIndex = wcList.findIndex((item) => item.text === response.responseData.trim().toLowerCase());
            wcList[wordIndex].count++;
            wcList[wordIndex].participants.push({
                participantId: response.participantId,
                participantName: response.participantName,
                participantAvatar: response.participantAvatar,
            });
            if (wcList[wordIndex].count > 1) wcList[wordIndex].value *= 2;
        }
        wcList = wcList.sort((a: any, b: any) => b.count - a.count);
        setWordCloudList(wcList);
        highestCountDetect(wcList);
        // const duration = Math.floor(new Date().getTime() - startTime.getTime());
    };

    const highestCountDetect = (wcList: WordInterface[]) => {
        const occurredNumbers = wcList.map((word) => word.count);
        setTopAnswerCount(getWordCloudTopAnswerMinimumCount(occurredNumbers, 3));
    };

    const handleOpenModal = (participants: ParticipantChipInterface[], text: string) => {
        webviewMessenger.sendUsageLog(`${classSession ? '[S] ' : ''}Opened participant chips modal for text: ${text}`);
        setAnswerInModal(text);
        setParticipantChips(participants);
        setIsModalOpen(true);
    };

    const callbacks: any = {
        onWordClick: (word: WordInterface, data: any) => {
            return handleOpenModal(word.participants, word.text);
        },
        getWordTooltip: (word: WordInterface) => `${word.count}`,
    };

    const callbacksWithFilter: any = {
        onWordClick: (word: WordInterface, data: any) => {
            return handleOpenModal(word.participants, word.text);
        },
        getWordTooltip: (word: WordInterface, data: any) => `${word.count}`,
        getWordColor: (word: WordInterface, data: any) => generateWordColors(word),
    };

    const generateWordColors = (word: WordInterface) => {
        if (searchKeyword) {
            const keywords = searchKeyword.split(',').filter((keyword) => keyword.trim());
            if (keywords.filter((keyword) => word.text.includes(keyword)).length > 0) return '#6378FF';
            else {
                if (isHighlightTopAnswers && word.count >= topAnswerCount) return '#6378FF';
                else return '#E6E1E5';
            }
        } else {
            if (isHighlightTopAnswers) {
                return word.count >= topAnswerCount ? '#6378FF' : '#E6E1E5';
            }
        }
    };

    const givePoint = (answer: string, points: number) => {
        const matchedResponses = activityResponses.filter((response: ActivityResponseInterface) =>
            response.responseData.includes(answer),
        );
        const responsesAndPoints = matchedResponses.map((response: ActivityResponseInterface) => ({
            response,
            points: points,
        }));
        dispatch(activityActions.handleGivePointsToResponses(responsesAndPoints));
    };

    const handleDeleteWord = () => {
        const responsesToDelete = activityResponses.filter(
            (r: any) => r.responseData.trim().toLowerCase() === answerInModal.trim().toLowerCase(),
        );
        dispatch(activityActions.deleteIndividualResponses(responsesToDelete));
        setIsModalOpen(false);
    };

    const givePointHandler = () => {
        clearTimeout(timeOutPointUpdate.current);
        setIsAnimating(true);
        givePointsConfettiRef.current.playLottieAnimation(true);
        setPointsAdded(pointsAdded + 1);
    };

    useEffect(() => {
        if (pointsAdded > 0) {
            timeOutPointUpdate.current = setTimeout(() => {
                answerInModal && givePoint(answerInModal, pointsAdded);
                setPointsAdded(0);
                setTimeout(() => {
                    setIsAnimating(false);
                    if (givePointsConfettiRef.current) givePointsConfettiRef.current.playLottieAnimation(false);
                }, AnimationConfig.CONFETTI_LOTTIE_DURATION - AnimationConfig.GIVE_POINT_DELAY);
            }, AnimationConfig.GIVE_POINT_DELAY);
        }
        return () => clearTimeout(timeOutPointUpdate.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pointsAdded]);

    useEffect(() => {
        return () => {
            setIsAnimating(false);
        };
    }, []);

    const handleModalClose = () => {
        if (!isAnimating) {
            webviewMessenger.sendUsageLog(`${classSession ? '[S] ' : ''}Closed participant chips modal`);
            setIsModalOpen(false);
        }
    };

    return (
        <div className="word_cloud_main">
            <div className="search_area">
                <SearchBox onSearch={setSearchKeyword} />
                <ShowingResponsesCount count={activityResponses.length} />
            </div>
            <div className="word_cloud">
                <Modal
                    open={isModalOpen}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <>
                        <FitToViewport
                            width={ViewportConstants.width}
                            height={ViewportConstants.height}
                            minZoom={ViewportConstants.minZoom}
                            maxZoom={ViewportConstants.maxZoom}
                            className="modal_viewport wordCloudFullScreenLottie"
                        >
                            <FullScreenPointLottie ref={givePointsConfettiRef} />

                            <div className="close_modal" onClick={handleModalClose}>
                                {/* <CloseRoundedIcon /> */}
                            </div>
                            <Box
                                component={motion.div}
                                variants={SimpleFadeAnimation}
                                initial="initial"
                                animate="animate"
                                sx={style}
                                className="word_cloud_modal new_word_cloud"
                            >
                                {canEditResponsesInView && (
                                    <div className="deleteButtonContainer">
                                        <Button className="deleteButton">
                                            <div className="delete_btn_bg"></div>
                                            <div className="delete">
                                                <DeleteForeverOutlinedIcon
                                                    onClick={() => {
                                                        webviewMessenger.sendUsageLog(
                                                            `${
                                                                classSession ? '[S] ' : ''
                                                            }Deleted a Word Cloud response`,
                                                        );
                                                        handleDeleteWord();
                                                    }}
                                                />
                                            </div>
                                        </Button>
                                    </div>
                                )}
                                <div className="word_cloudhead">
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        "{answerInModal}"
                                    </Typography>
                                </div>
                                <div className="word_cloud_modal_body">
                                    <div className="WC_body_inner wordCloudSparkleLottie">
                                        {participantChips.map((participant, index) => (
                                            <CommonChip
                                                participantAvatar={participant.participantAvatar}
                                                participantName={participant.participantName}
                                                key={index}
                                            />
                                        ))}
                                    </div>
                                </div>
                                {canEditResponsesInView && (
                                    <div className="modal_footer">
                                        <GivePointButton
                                            label={t('lang_common.btn_give_points_to_all')}
                                            onClick={() => {
                                                webviewMessenger.sendUsageLog(
                                                    `${
                                                        classSession ? '[S] ' : ''
                                                    }Clicked "Award stars to all" for selected participants`,
                                                );
                                                givePointHandler();
                                            }}
                                            activity="modifyButton"
                                        />
                                    </div>
                                )}
                            </Box>
                        </FitToViewport>
                    </>
                </Modal>

                <ReactWordcloud
                    words={wordCloudList}
                    callbacks={isHighlightTopAnswers || searchKeyword.trim() ? callbacksWithFilter : callbacks}
                    options={wordCloudList.length < 40 ? options1 : options2}
                />
            </div>
        </div>
    );
};

export default forwardRef(WordCloudContent);
