import { useState } from 'react';
import { utilConstants } from '../../../constants/utils.constants';
import QRCode from 'qrcode.react';
import { Divider, FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { classSessionActions } from '../../../actions/class-session.action';
import { getValidClassSessionFromStore } from '../../../helpers/storeHelpers';
import webviewMessenger from '../../../services/webviewMessenger';

function MyClassQRContent() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classSession = getValidClassSessionFromStore('MyClassQRContent');

    const [toggleCopiedBtn, setToggleCopiedBtn] = useState(false);

    const copyUrl = (url: string) => {
        navigator.clipboard.writeText(url);
    };

    return (
        <>
            <div className="qr_pop_sec">
                <div className="qr_pop_left">
                    <QRCode
                        size={512}
                        value={`https://${process.env.REACT_APP_PARTICIPANT}?code=${classSession.classCode}`}
                        imageSettings={{
                            src: utilConstants.IMAGE_URLS.brand_logo,
                            x: undefined,
                            y: undefined,
                            height: 88,
                            width: 88,
                            excavate: true,
                        }}
                    />
                </div>
                <div className="qr_pop_right">
                    <div className="url_sec">
                        <div className="url_content">
                            <Typography variant="caption">{t('lang_myclass.txt_url')}</Typography>
                            <Typography variant="h4">
                                {process.env.REACT_APP_PARTICIPANT}
                                <span className={`copyIconContainer`}>
                                    <ContentCopyIcon
                                        onClick={() => {
                                            webviewMessenger.sendUsageLog(`[S] Copied join class URL`);
                                            copyUrl(
                                                `https://${process.env.REACT_APP_PARTICIPANT}?code=${classSession.classCode}`,
                                            );
                                            setToggleCopiedBtn(true);
                                            setTimeout(() => setToggleCopiedBtn(false), 1000);
                                        }}
                                        className="icon"
                                    />
                                    {toggleCopiedBtn && (
                                        <span className="copyurl_tooltip">
                                            {t('lang_share_classpoint.tooltip_copied')}
                                            <DoneIcon />
                                        </span>
                                    )}
                                </span>
                            </Typography>
                            <Divider className="divider" />
                            <Typography variant="caption">{t('lang_common.txt_class_code')}</Typography>
                            <Typography variant="h3">{classSession.classCode}</Typography>
                        </div>
                    </div>
                </div>
            </div>
            {classSession.classSessionName && (
                <div className="qr_footer">
                    <div className="qr_foot_left">
                        <div className="switch_sec">
                            <FormGroup className="switchinner">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            onChange={() => {
                                                webviewMessenger.sendUsageLog(
                                                    `[S] Toggled ${
                                                        classSession.isAllowGuests ? 'OFF' : 'ON'
                                                    } allow guests`,
                                                );
                                                dispatch(
                                                    classSessionActions.toggleAllowGuests(!classSession.isAllowGuests),
                                                );
                                                if (classSession.isAllowGuests)
                                                    dispatch(classSessionActions.toggleSaveGuests(false));
                                            }}
                                            checked={classSession.isAllowGuests}
                                        />
                                    }
                                    label={t('lang_myclass.toggle_allow_guests')}
                                />
                                {/* <FormControlLabel
                                    disabled={!classSession.isAllowGuests}
                                    control={
                                        <Switch
                                            onChange={() => {
                                                webviewMessenger.sendUsageLog(
                                                    `[S] Toggled ${
                                                        classSession.isSaveGuests ? 'OFF' : 'ON'
                                                    } save guests`,
                                                );
                                                dispatch(
                                                    classSessionActions.toggleSaveGuests(!classSession.isSaveGuests),
                                                );
                                            }}
                                            checked={classSession.isAllowGuests && classSession.isSaveGuests}
                                        />
                                    }
                                    label={t('lang_myclass.toggle_save_guests')}
                                /> */}
                            </FormGroup>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default MyClassQRContent;
