export enum HostEventTypes {
    AUTO_SIGN_IN_SUCCESS = 'autoSignInSuccess',
    AUTO_SIGN_IN_FAILED = 'autoSignInFailed',
    MANUAL_SIGN_IN_SUCCESS = 'manualSignInSuccess',
    MANUAL_SIGN_IN_FAILED = 'manualSignInFailed',
    USER_UPDATED = 'userUpdated',
    SIGNED_OUT = 'signedOut',
    KICKED_OUT = 'kickedOut',
    RE_SIGN_IN = 'reSignIn',
}
