import { HostBrowserCommands } from '../constants/host-browser-commands';

const webviewBrowserCommands = {
    createTab: (tabId: number, uri: string, active: boolean) => {
        window.chrome.webview.postMessage({
            message: HostBrowserCommands.MG_CREATE_TAB,
            args: {
                tabId,
                uri,
                active,
            },
        });
    },
    openBookmarkInTab: (uri: string) => {
        window.chrome.webview.postMessage({
            message: HostBrowserCommands.MG_CREATE_TAB,
            args: {
                uri,
            },
        });
    },
    navigateTo: (uri: string, isSearch: boolean) => {
        window.chrome.webview.postMessage({
            message: HostBrowserCommands.MG_NAVIGATE,
            args: {
                uri,
                encodedSearchURI: isSearch ? uri : `https://www.google.com/search?q=${encodeURIComponent(uri)}`,
            },
        });
    },

    goForward: () => {
        window.chrome.webview.postMessage({
            message: HostBrowserCommands.MG_GO_FORWARD,
            args: {},
        });
    },

    goBackward: () => {
        window.chrome.webview.postMessage({
            message: HostBrowserCommands.MG_GO_BACK,
            args: {},
        });
    },

    reloadPage: () => {
        window.chrome.webview.postMessage({
            message: HostBrowserCommands.MG_RELOAD,
            args: {},
        });
    },

    cancelReloadPage: () => {
        window.chrome.webview.postMessage({
            message: HostBrowserCommands.MG_CANCEL,
            args: {},
        });
    },

    switchTab: (tabId: number, uri: string) => {
        window.chrome.webview.postMessage({
            message: HostBrowserCommands.MG_SWITCH_TAB,
            args: {
                tabId,
                uri,
            },
        });
    },

    insertAsSlide: () => {
        window.chrome.webview.postMessage({
            message: HostBrowserCommands.MG_INSERT_AS_SLIDE,
            args: {},
        });
    },

    closeTab: (tabId: number) => {
        window.chrome.webview.postMessage({
            message: HostBrowserCommands.MG_CLOSE_TAB,
            args: {
                tabId,
            },
        });
    },

    closeWindow: () => {
        window.chrome.webview.postMessage({
            message: HostBrowserCommands.MG_CLOSE_WINDOW,
            args: {},
        });
    },
};

export default webviewBrowserCommands;
