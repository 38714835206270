import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Lottie from 'react-lottie-player';
import { Reorder, motion } from 'framer-motion';

import { generateLeaders } from '../../../helpers/leaderboardHelpers';
import { leaderBoardActions } from '../../../actions/leaderboard.action';

import StoreInterface from '../../../interfaces/store.interface';
import { SavedClassGroupInterface } from '../../../interfaces/savedClassGroup.interface';
import ParticipantInterface from '../../../interfaces/participant.interface';

import Loading from '../../activity/Loading/Loading';
import LeaderBoardTopPlace from './LeaderBoardTopPlace';
import LeaderBoardOverallSingle from './LeaderBoardOverallSingle';

import confetti from '../../../assets/animation/confetti-day.json';

type LeaderBoardOverallProps = {
    participantList: ParticipantInterface[];
    numOfElements: number;
    savedClassGroups: SavedClassGroupInterface[];
};

const LeaderBoardOverall = ({ participantList, numOfElements, savedClassGroups }: LeaderBoardOverallProps) => {
    const dispatch = useDispatch();

    const leadersOverall = useSelector((state: StoreInterface) => state.leaderboard.leadersOverall);
    const [isShowConfetti, setIsShowConfetti] = useState<boolean>(false);

    const refreshLeaders = async () => {
        const sortedLeadersList = generateLeaders(participantList, 'participantSumPoints');
        setTimeout(() => {
            dispatch(leaderBoardActions.refreshLeadersOverall(JSON.parse(JSON.stringify(sortedLeadersList))));
        }, 1000);
        const hasRankChanged =
            JSON.stringify(leadersOverall.map((p) => p.participantId)) !==
            JSON.stringify(sortedLeadersList.map((p) => p.participantId));
        if (hasRankChanged) {
            setTimeout(() => {
                setIsShowConfetti(true);
            }, 1200);
            setTimeout(() => {
                setIsShowConfetti(false);
            }, 3200);
        }
    };

    useEffect(() => {
        refreshLeaders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            {leadersOverall.length === 0 ? (
                <Loading />
            ) : (
                <Fragment>
                    {isShowConfetti ? (
                        <div className="confetti">
                            <Lottie loop={true} animationData={confetti} play speed={0.8} />
                        </div>
                    ) : null}
                    <div className="leaderboard_card">
                        <div className="leaderboard_left">
                            <LeaderBoardTopPlace leadersOverall={leadersOverall} savedClassGroups={savedClassGroups} />
                        </div>
                        <div className="leaderboard_right">
                            <Reorder.Group values={leadersOverall} onReorder={() => {}}>
                                {leadersOverall.slice(0, numOfElements).map((leaderboardParticipant, index: number) => (
                                    <Reorder.Item
                                        key={leaderboardParticipant.participantId}
                                        value={leaderboardParticipant}
                                    >
                                        <motion.span
                                            initial={{
                                                opacity: 0,
                                                y: 60,
                                                scale: 0.3,
                                            }}
                                            animate={{
                                                opacity: 1,
                                                y: 0,
                                                scale: 1,
                                                transition: {
                                                    type: 'spring',
                                                    stiffness: 300,
                                                    delay: index / 10,
                                                },
                                            }}
                                        >
                                            <LeaderBoardOverallSingle
                                                leaderboardParticipant={leaderboardParticipant}
                                                savedClassGroups={savedClassGroups}
                                            />
                                        </motion.span>
                                    </Reorder.Item>
                                ))}
                            </Reorder.Group>
                        </div>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};

export default LeaderBoardOverall;
