import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { store } from '../../../helpers/store';
import ParticipantsWheel from '../components/ParticipantsWheel';
import StoreInterface from '../../../interfaces/store.interface';
import WheelViewSideList from '../components/WheelViewSideList';
import PickedParticipantModal from '../modals/PickedParticipantModal';
import PickNamesParticipantInterface from '../../../interfaces/pick-names-participant.interface';
import {
    findParticipantPickNamesListIndex,
    findPicked,
    findUnPicked,
    getSingleParticipantInModal,
} from '../../../helpers/pickNamesHelpers';
import { pickNamesActions } from '../../../actions/pickNames.action';
import { localService } from '../../../services/localStorageService';
import { useTranslation } from 'react-i18next';
import { UpdateToolbarActionsType } from '../../../dtos/update-toolbar-actions.dto';

interface PickNamesWheelViewInterface {
    isAnimating: boolean;
    setIsAnimating: (arg: boolean) => void;
    givePointsConfettiRef: any;
}

const PickNamesWheelView = ({ isAnimating, setIsAnimating, givePointsConfettiRef }: PickNamesWheelViewInterface) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const webviewSettingsSlideshow = useSelector((state: StoreInterface) => state.webviewSettingsSlideshow);
    const pickNamesList: PickNamesParticipantInterface[] = store.getState().pickNamesList;

    const pickedParticipantIdsInModal = localService.getPickedNamesInModal();
    const isPickNameShowOnlineParticipant = webviewSettingsSlideshow.isPickNameShowOnlineParticipant;

    const pickNamesListWithOnlineStatus = isPickNameShowOnlineParticipant
        ? pickNamesList.filter((data) => data.isOnline === true)
        : pickNamesList;

    const [isPickedModalOpen, setIsPickedModalOpen] = useState(pickedParticipantIdsInModal.length === 1 ? true : false);
    const [isPickedNamesShown, setIsPickedNamesShown] = useState(webviewSettingsSlideshow.isPickedNamesPanelShown);
    const [pickedParticipant, setPickedParticipant] = useState<PickNamesParticipantInterface | undefined>(
        getSingleParticipantInModal(pickNamesList, pickedParticipantIdsInModal),
    );

    const handleWheelStopsOnParticipant = (participant: PickNamesParticipantInterface) => {
        setPickedParticipant(participant);
        dispatch(pickNamesActions.pickOneName(participant.participantId, true));
        localService.updateToolbarActionsFromWebview(UpdateToolbarActionsType.pickName);
        setIsPickedModalOpen(true);
        findPicked(pickNamesList).length === 0 && setIsPickedNamesShown(true);
        localService.setPickedNamesInModal([participant.participantId]);
    };

    useEffect(() => {
        if (pickedParticipant) {
            const pickedIndex = findParticipantPickNamesListIndex(pickedParticipant);
            setPickedParticipant(pickNamesList[pickedIndex]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pickNamesList]);

    return (
        <>
            {pickedParticipant && (
                <PickedParticipantModal
                    isPickedModalOpen={isPickedModalOpen}
                    setIsPickedModalOpen={setIsPickedModalOpen}
                    pickedParticipant={pickedParticipant}
                    isAnimating={isAnimating}
                    setIsAnimating={setIsAnimating}
                    givePointsConfettiRef={givePointsConfettiRef}
                />
            )}

            <div className="pickWheel_sec">
                <div className="pickwheel_left">
                    <div
                        className={`wheel_name ${
                            findUnPicked(pickNamesListWithOnlineStatus).length <= 1 ? 'single' : ''
                        }`}
                    >
                        {pickNamesListWithOnlineStatus.length !== 0 &&
                            findUnPicked(pickNamesListWithOnlineStatus).length <= 0 && (
                                <Typography variant="body1">{t('lang_pick_names.txt_all_names_are_picked')}</Typography>
                            )}

                        {pickNamesListWithOnlineStatus.length > 0 && (
                            <ParticipantsWheel
                                participantsOnWheel={findUnPicked(pickNamesListWithOnlineStatus)}
                                onWheelStopsOnParticipant={handleWheelStopsOnParticipant}
                            />
                        )}
                    </div>
                </div>

                <WheelViewSideList
                    pickNamesList={pickNamesListWithOnlineStatus}
                    isPickedNamesShown={isPickedNamesShown}
                    setIsPickedNamesShown={setIsPickedNamesShown}
                    setIsPickedModalOpen={setIsPickedModalOpen}
                    setPickedParticipant={setPickedParticipant}
                />
            </div>
        </>
    );
};
export default PickNamesWheelView;
