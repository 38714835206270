import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import * as React from 'react';
import { utilConstants } from '../../../constants/utils.constants';
import './Header.scss';
export interface HeaderProps {
    logo?: string;
    children: React.ReactNode;
    appBarTitle: string;
}

const Header = (props: HeaderProps) => {
    return (
        <div className="top_header">
            <AppBar position="static">
                <Toolbar>
                    <Box className="box_align " sx={{ display: 'flex', width: '100%' }}>
                        <div className="header_logo">
                            <img src={props.logo || utilConstants.IMAGE_URLS.brand_logo} alt="brandlogo" />
                            <Typography variant="h3">{props.appBarTitle}</Typography>
                        </div>
                        {props.children}
                    </Box>
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default Header;
