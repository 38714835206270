import { useTranslation } from 'react-i18next';
import { FitToViewport } from 'react-fit-to-viewport';
import { motion } from 'framer-motion';

import { Box, Modal, Typography } from '@mui/material';

import { getValidClassSessionFromStore, getValidUserFromStore } from '../../../helpers/storeHelpers';
import { findUserClassLimit } from '../../../helpers/userhelper';
import { ViewportConstants } from '../../../constants/viewport-constants';
import { SimpleFadeAnimation } from '../../../constants/animation-configs';

import MyClassQRContent from './MyClassQRContent';
import MyClassFull from './MyClassFull';
import MyClassLocked from './MyClassLocked';
import { Fragment } from 'react';

interface MyClassQRModalInterface {
    isQRModalOpen: boolean;
    closeModal: () => void;
    onStartActivateTrial: () => void;
}

const MyClassQRModal = ({ isQRModalOpen, closeModal, onStartActivateTrial }: MyClassQRModalInterface) => {
    const { t } = useTranslation();
    const user = getValidUserFromStore('MyClassQRModal');
    const classLimit = findUserClassLimit(user);
    const classSession = getValidClassSessionFromStore('MyClassQRModal');
    const isClassFull = classSession.participantList.length >= classLimit ? true : false;

    let modalContent;

    if (isClassFull) {
        modalContent = <MyClassFull onStartActivateTrial={onStartActivateTrial} />;
    } else if (classSession.isLocked) {
        modalContent = <MyClassLocked />;
    } else {
        modalContent = <MyClassQRContent />;
    }

    return (
        <Fragment>
            <Modal
                open={isQRModalOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="mymodal"
                onClose={() => closeModal()}
            >
                <Fragment>
                    <FitToViewport
                        className="modal_viewport"
                        width={ViewportConstants.width}
                        height={ViewportConstants.height}
                        minZoom={ViewportConstants.minZoom}
                        maxZoom={ViewportConstants.maxZoom}
                    >
                        <div className="close_modal" onClick={closeModal}></div>
                        <Box
                            component={motion.div}
                            initial="initial"
                            animate="animate"
                            variants={SimpleFadeAnimation}
                            className="modal_box myclass_qr_modal"
                        >
                            <div
                                className={`${classSession.classSessionName ? 'qr_popup' : 'qr_popup open'} ${
                                    (isClassFull || classSession.isLocked) && 'trialActivation'
                                }`}
                            >
                                <div className="qr_tiltle">
                                    <Typography variant="h4">
                                        {classSession.classSessionName ||
                                            t('lang_myclass.txt_default_class_title', {
                                                user_first_name: user.userProfile.firstName,
                                            })}
                                    </Typography>
                                </div>
                                {modalContent}
                            </div>
                        </Box>
                    </FitToViewport>
                </Fragment>
            </Modal>
        </Fragment>
    );
};

export default MyClassQRModal;
