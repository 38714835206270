import { IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface AutoPickCounterInterface {
    count: number;
    canIncrease: boolean;
    canDecrease?: boolean;
    onIncrease: () => void;
    onDecrease: () => void;
}

const AutoPickCounter = ({ count, canIncrease, canDecrease, onIncrease, onDecrease }: AutoPickCounterInterface) => {
    return (
        <div className="auto_pick_number">
            <>
                <IconButton aria-label="Example" disabled={!canDecrease || count <= 1} onClick={onDecrease}>
                    <RemoveIcon />
                </IconButton>
                <div className="number">
                    <Typography variant="h3">{count}</Typography>
                </div>
                <IconButton aria-label="Example" disabled={!canIncrease} onClick={onIncrease}>
                    <AddIcon />
                </IconButton>
            </>
        </div>
    );
};

export default AutoPickCounter;
