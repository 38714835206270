import { AnimationControls } from 'framer-motion';

export const aiWelcomeAnimation = (textAnimation: AnimationControls, btnAnimation: AnimationControls) => {
    const animationConstantArray = {
        textInitial: {
            opacity: 1,
            y: 0,
        },
        buttonInitial: {
            opacity: 1,
            y: 0,
        },
    };

    const animationTransition = {
        duration: 0.2,
        ease: 'linear',
    };

    const onClickAnimation = () => {
        textAnimation.start({
            opacity: 0,
            y: 100,
            transition: {
                delay: 0.1,
                ...animationTransition,
            },
        });
        btnAnimation.start({
            opacity: 0,
            y: 100,
            transition: animationTransition,
        });
    };

    return {
        animationConstants: animationConstantArray,
        startAnimation: onClickAnimation,
    };
};
