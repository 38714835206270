import { useState, Fragment, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { FitToViewport } from 'react-fit-to-viewport';
import { useTranslation } from 'react-i18next';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Modal, Typography } from '@mui/material';

import { getActivityFromStore } from '../../../helpers/storeHelpers';
import { checkIfSingleBlankIsCorrect } from '../../../helpers/activityHelpers';
import { activityActions } from '../../../actions/activity.action';

import ActivityResponseInterface from '../../../interfaces/activity-response.interface';
import { ActivityPropsInterface } from '../../../interfaces/activity-props.interface';
import ParticipantChipInterface from '../../../interfaces/participant-chip.interface';
import { ViewportConstants } from '../../../constants/viewport-constants';
import { AnimationConfig } from '../../../constants/animation-configs';

import CommonChip from '../../../components/Common/CommonChip';
import GivePointButton from '../../../components/Common/GivePointButton';
import { FullScreenPointLottie } from '../../../components/Common/AnimationModules';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    quickpoll_noresponse: '',
};

interface CorrectAnswersTableRowInterface {
    possibleCorrectAnswers: string[];
    correctCount: number;
    correctRate: number;
    textColor: string;
    participants: ParticipantChipInterface[];
}

const FillBlanksCorrectAnswers = () => {
    const dispatch = useDispatch();
    const activity = getActivityFromStore();
    const activityProps = activity.activityProps as ActivityPropsInterface;
    const [tableData, setTableData] = useState<CorrectAnswersTableRowInterface[]>([]);
    const [selectedBlankIndex, setSelectedBlankIndex] = useState<number>(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t } = useTranslation();

    const timeOutPointUpdate = useRef<any>();
    const givePointsConfettiRef = useRef<any>();
    const [pointsAdded, setPointsAdded] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        const tableData: CorrectAnswersTableRowInterface[] = [];
        if (!activityProps || !activityProps.fbCorrectAnswers) return;

        activityProps.fbCorrectAnswers.forEach((possibleCorrectAnswersString: string, index: number) => {
            let correctAnsCount = 0;
            let participants: ParticipantChipInterface[] = [];
            activity.activityResponses.forEach((response) => {
                if (JSON.parse(response.responseData)[index]) {
                    const participantAnswered = JSON.parse(response.responseData)[index];
                    if (checkIfSingleBlankIsCorrect(possibleCorrectAnswersString, participantAnswered)) {
                        correctAnsCount = correctAnsCount + 1;
                        participants.push({
                            participantId: response.participantId,
                            participantAvatar: response.participantAvatar,
                            participantName: response.participantName,
                        });
                    }
                }
            });
            const correctRate = Math.round((correctAnsCount / activity.activityResponses.length) * 100);
            tableData.push({
                possibleCorrectAnswers: JSON.parse(possibleCorrectAnswersString),
                correctCount: correctAnsCount,
                correctRate,
                textColor: getColorByCorrectRate(correctRate),
                participants,
            });
        });

        setTableData(tableData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getColorByCorrectRate = (correctRate: number) => {
        if (correctRate <= 39) {
            return 'red';
        } else if (correctRate > 39 && correctRate <= 69) {
            return 'orange';
        } else {
            return 'green';
        }
    };

    const givePoint = (points: number) => {
        const matchedResponses = activity.activityResponses.filter((response: ActivityResponseInterface) =>
            tableData[selectedBlankIndex].participants.map((p) => p.participantId).includes(response.participantId),
        );
        const responsesAndPoints = matchedResponses.map((response: ActivityResponseInterface) => ({
            response,
            points: points,
        }));
        dispatch(activityActions.handleGivePointsToResponses(responsesAndPoints));
    };

    const givePointHandler = () => {
        clearTimeout(timeOutPointUpdate.current);
        setIsAnimating(true);
        givePointsConfettiRef.current.playLottieAnimation(true);
        setPointsAdded(pointsAdded + 1);
    };

    useEffect(() => {
        if (pointsAdded > 0) {
            timeOutPointUpdate.current = setTimeout(() => {
                givePoint(pointsAdded);
                setPointsAdded(0);
                setTimeout(() => {
                    setIsAnimating(false);
                    if (givePointsConfettiRef.current) givePointsConfettiRef.current.playLottieAnimation(false);
                }, AnimationConfig.CONFETTI_LOTTIE_DURATION - AnimationConfig.GIVE_POINT_DELAY);
            }, AnimationConfig.GIVE_POINT_DELAY);
        }
        return () => clearTimeout(timeOutPointUpdate.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pointsAdded]);

    useEffect(() => {
        return () => {
            setIsAnimating(false);
        };
    }, []);

    return (
        <Fragment>
            <Modal
                open={isModalOpen}
                onClose={() => !isAnimating && setIsModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <>
                    <FitToViewport
                        width={ViewportConstants.width}
                        height={ViewportConstants.height}
                        minZoom={ViewportConstants.minZoom}
                        maxZoom={ViewportConstants.maxZoom}
                        className="modal_viewport filleBlanksFullScreenLottie"
                    >
                        <FullScreenPointLottie ref={givePointsConfettiRef} />

                        <div className="close_modal" onClick={() => !isAnimating && setIsModalOpen(false)}>
                            {/* <CloseRoundedIcon /> */}
                        </div>
                        <Box sx={style} className="word_cloud_modal new_word_cloud">
                            <div className="word_cloudhead">
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    {t('lang_activity.correct_participants')}
                                </Typography>
                            </div>
                            <div className="word_cloud_modal_body">
                                <div className="WC_body_inner fillBlanksSparkleLottie">
                                    {tableData[selectedBlankIndex]?.participants?.map((participant, index) => (
                                        <Typography key={index} id="modal-modal-description" variant="caption">
                                            <CommonChip
                                                participantName={participant.participantName}
                                                participantAvatar={participant.participantAvatar}
                                            />
                                        </Typography>
                                    ))}
                                </div>
                            </div>

                            <div className="modal_footer">
                                <GivePointButton
                                    label={t('lang_common.btn_give_points_to_all')}
                                    onClick={givePointHandler}
                                    activity="modifyButton"
                                />
                            </div>
                        </Box>
                    </FitToViewport>
                </>
            </Modal>
            <div className="ans_table_sec">
                <div className="ans_table_inner">
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 710 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">{t('lang_activity.simple_blank')}</TableCell>
                                    <TableCell align="left">{t('lang_activity.correct_answer')}</TableCell>
                                    <TableCell align="center">{t('lang_activity.correct_count')}</TableCell>
                                    <TableCell align="center">{t('lang_activity.correct_rate')}</TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData.map((row, index: number) => (
                                    <TableRow key={index}>
                                        <TableCell align="center" scope="row">
                                            <Typography variant="caption" className="number">
                                                {index + 1}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">{row.possibleCorrectAnswers.join('; ')}</TableCell>
                                        <TableCell align="center">{row.correctCount}</TableCell>
                                        <TableCell align="center">
                                            <Typography
                                                variant="caption"
                                                className="low"
                                                style={{
                                                    color: `${row.textColor}`,
                                                }}
                                            >
                                                {row.correctRate}%
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.correctRate > 0 && (
                                                <Button
                                                    onClick={() => {
                                                        if (row.correctRate === 0) return;
                                                        setSelectedBlankIndex(index);
                                                        setIsModalOpen(true);
                                                    }}
                                                    variant="contained"
                                                    className="view_btn"
                                                >
                                                    {t('lang_activity.fib_view_correct_participants')}
                                                </Button>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </Fragment>
    );
};

export default FillBlanksCorrectAnswers;
