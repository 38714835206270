import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

import { ViewportSavedClasses } from '../../../constants/viewport-constants';

import CommonAlert from '../../../components/Common/CommonAlert';
import CommonButton from '../../../components/Common/CommonButton';

export default function ResetAlert({ showAlert, onResetLevels, onClose }: any) {
    const { t } = useTranslation();

    return (
        <CommonAlert
            showAlert={showAlert}
            closeAlert={onClose}
            viewportConfig={ViewportSavedClasses}
            customModalClassNames="small"
            content={
                <Fragment>
                    <div className="media">
                        <div className="media_icon">
                            <ReportProblemIcon className="warning" />
                        </div>
                        <div className="media_body">
                            <div className="alert_content">
                                <Typography variant="body1">
                                    {t('lang_customize_levels.txt_confirm_reset_levels')}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div className="button_sec">
                        <CommonButton
                            isLoading={false}
                            variant="contained"
                            type="button"
                            text={t('lang_common.btn_yes')}
                            clickFunction={onResetLevels}
                        />
                        <CommonButton
                            isLoading={false}
                            variant="text"
                            type="button"
                            text={t('lang_common.btn_no')}
                            clickFunction={onClose}
                        />
                    </div>
                </Fragment>
            }
        />
    );
}
