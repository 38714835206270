export enum ClassSessionActionTypes {
    START_SLIDESHOW = 'START_SLIDESHOW',
    SIGNALR_RECONNECTED = 'SIGNALR_RECONNECTED',
    CLASSSESSION_UPDATED = 'CLASSSESSION_UPDATED',
    NEW_PARTICIPANT_JOINED = 'NEW_PARTICIPANT_JOINED',
    EXISTING_PARTICIPANT_REFRESHED = 'EXISTING_PARTICIPANT_REFRESHED',
    OFFLINE_PARTICIPANT_JOINED = 'OFFLINE_PARTICIPANT_JOINED',
    PARTICIPANT_GOES_OFFLINE = 'PARTICIPANT_GOES_OFFLINE',
    PRESENTER_REMOVED_PARTICIPANT = 'PRESENTER_REMOVED_PARTICIPANT',
    PRESENTER_LOCKS_CLASS = 'PRESENTER_LOCKS_CLASS',
    PRESENTER_UNLOCKS_CLASS = 'PRESENTER_UNLOCKS_CLASS',
    PRESENTER_ALLOWS_GUESTS = 'PRESENTER_ALLOWS_GUESTS',
    PRESENTER_DISALLOWS_GUESTS = 'PRESENTER_DISALLOWS_GUESTS',
    PRESENTER_TOGGLES_ON_SAVE_GUESTS = 'PRESENTER_TOGGLES_ON_SAVE_GUESTS',
    PRESENTER_TOGGLES_OFF_SAVE_GUESTS = 'PRESENTER_TOGGLES_OFF_SAVE_GUESTS',
    GIVE_POINTS_TO_PARTICIPANTS = 'GIVE_POINTS_TO_PARTICIPANTS',
    PARTICIPANTS_GOT_POINTS = 'PARTICIPANTS_GOT_POINTS',
    UPDATE_ACTIVITY_RESPONSE = 'UPDATE_ACTIVITY_RESPONSE',
    UPDATE_ACTIVITY_PROPS = 'UPDATE_ACTIVITY_PROPS',
    UPDATE_ACTIVITY_USER = 'UPDATE_ACTIVITY_USER',
    UPDATE_ACTIVITY_MODE = 'UPDATE_ACTIVITY_MODE',
    TRIGGER_RESTART_CLASS = 'TRIGGER_RESTART_CLASS',
    END_SLIDESHOW = 'END_SLIDESHOW',
    QNA_DATA_UPDATED = 'QNA_DATA_UPDATED',
}
