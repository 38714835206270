import * as yup from 'yup';

import { INPUT_LIMITS, utilConstants } from '../constants/utils.constants';

export const generateYupErrors = (errors: string[]) => {
    const updatedErrors: any = {};
    errors.reverse().forEach((error) => {
        updatedErrors[error.split(':')[0]] = `lang_auth.${error.split(':')[1]}`;
    });
    return updatedErrors;
};

export const profileValidation = yup.object({
    firstName: yup
        .string()
        .required('firstNameError:error_firstName_required')
        .max(INPUT_LIMITS.FIRST_LAST_NAME_MAX, 'firstNameError:error_firstName_length'),
    lastName: yup
        .string()
        .required('lastNameError:error_lastName_required')
        .max(INPUT_LIMITS.FIRST_LAST_NAME_MAX, 'lastNameError:error_lastName_length'),
    email: yup
        .string()
        .required('emailError:error_email_required')
        .matches(utilConstants.REGEX.EMAIL, 'emailError:error_email_is_invalid'),
    ssoType: yup.string().notRequired(),
    password: yup.string().when('ssoType', {
        is: (ssoType: string) => (!ssoType ? true : false),
        then: yup
            .string()
            .required('passwordError:error_password_required')
            .matches(utilConstants.REGEX.PASSWORD, 'passwordError:error_password_pattern')
            .min(INPUT_LIMITS.PASSWORD_MIN, 'passwordError:error_password_length')
            .max(INPUT_LIMITS.PASSWORD_MAX, 'passwordError:error_password_length'),
        otherwise: yup.string().notRequired(),
    }),
    organization: yup
        .string()
        .required('organizationError:error_organization_required')
        .max(INPUT_LIMITS.ORGANIZATION_MAX, 'organizationError:error_organizationLength'),
});
