import { SavedClassGroupInterface } from './savedClassGroup.interface';

export interface QnaDataInterface {
    qnas: QnaInterface[];
    qnaVoters: { [key: string]: number[] };
}

export interface QnaInterface {
    created: string;
    groupId: number | undefined;
    id: number;
    isAnswered: boolean;
    participantAvatar: string;
    participantId: string;
    participantName: string;
    question: string;
    votes: number;
    voters: string[];
}

export interface QnaStateInterface {
    activeTab: 'new' | 'answered';
    newQnas: QnaInterface[];
    answeredQnas: QnaInterface[];
    selectedQuestionIndex: number;
    savedClassGroups: SavedClassGroupInterface[];
}

export enum QnaActionType {
    REFRESH_QNA = 'REFRESH_QNA',
    QNA_UPDATED = 'QNA_UPDATED',
    CHANGE_TAB = 'CHANGE_TAB',
    OPEN_MODAL = 'OPEN_MODAL',
    NAVIGATE_IN_MODAL = 'NAVIGATE_IN_MODAL',
    CLOSE_MODAL = 'CLOSE_MODAL',
    DELETE_QNA = 'DELETE_QNA',
}
