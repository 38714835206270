export default interface SavedClassParticipantInterface {
    participantId: string;
    participantUsername?: string;
    participantName: string;
    participantAvatar: string;
    participantTotalPoints: number;
    recentlyEarned: number;
    participantLevelAndBadge?: { level: number; badgeUrl: string };
    groupId?: number;
}

export enum OrderProperties {
    ParticipantName = 'participantName',
    // RecentlyEarned = 'recentlyEarned',
    Group = 'group',
    ParticipantTotalPoints = 'participantTotalPoints',
    ParticipantLevel = 'participantLevel',
}

export enum Orders {
    Asc = 'asc',
    Desc = 'desc',
}
