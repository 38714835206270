import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Typography } from '@mui/material';

import { userActions } from '../../actions/user.actions';
import { getValidUserFromStore } from '../../helpers/storeHelpers';
import { isUserOnTrialOrPro } from '../../helpers/userhelper';
import apiSavedClasses from '../../services/apiSavedClasses';

import SavedClassForUserInterface from '../../interfaces/saved-class-for-user.interface';
import { UserActionTypes } from '../../constants/user-action-types';
import { QuotaConstants } from '../../constants/quota-constants';
import { ViewportSavedClasses } from '../../constants/viewport-constants';

import AddNewSavedClassCard from './components/AddNewSavedClassCard';
import SavedClassCard from './components/SavedClassCard';
import SavedClassesWelcome from './SavedClassesWelcome';
import CommonAlert from '../../components/Common/CommonAlert';
import { TrialFlow } from '../activateTrial/TrialFlow';
import { OpenProPitchButton } from '../../components/Common/OpenProPitchButton';
import webviewMessenger from '../../services/webviewMessenger';
import LandingSavedClasses from './LandingSavedClasses';
import VideoModal from '../VideoTutorial/Components/VideoModal';
import WatchTutorialButton from './components/WatchTutorialButton';

interface SavedClassesListInterface {
    changeCurrentPage: (currentPage: string, savedClass?: SavedClassForUserInterface) => void;
}

const SavedClassesList = ({ changeCurrentPage }: SavedClassesListInterface) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const user = getValidUserFromStore('SavedClassesList');
    const isEmailVerified = user.userStats.isEmailVerified;
    // const isAfterTrial = isBasicUserAfterTrial(user);
    const isAfterTrial = true;
    const isTrialOrPro = isUserOnTrialOrPro(user);

    const [savedClasses, setSavedClasses] = useState<SavedClassForUserInterface[]>(user.userProfile.savedClasses || []);
    const demoClass = savedClasses.find((classItem) => classItem.savedClassCode === null);
    const savedClassCountWithoutDemo =
        savedClasses?.filter((classItem) => classItem.savedClassCode !== null).length || 0;
    const [dragId, setDragId] = useState();
    const [dragStarted, setDragStarted] = useState(false);

    const [isTrialFlowModalOpen, setIsTrialFlowModalOpen] = useState(false);
    const [isActivatingTrial, setIsActivatingTrial] = useState(false);
    const [tutorialVideoId, setTutorialVideoId] = useState('');

    useEffect(() => {
        webviewMessenger.sendUsageLog(`Navigated to: Class list (${savedClasses.length} classes in view)`);
        syncSavedClasses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const syncSavedClasses = async () => {
        const updatedUser = await apiSavedClasses.syncSavedClasses(user.email, user.userId);
        if (updatedUser) dispatch(userActions.updateUser(UserActionTypes.SYNC_SAVED_CLASSES, updatedUser));
    };

    useEffect(() => {
        setSavedClasses(user.userProfile.savedClasses || []);
    }, [user]);

    const handleDrag = (ev: any) => {
        setDragId(ev.currentTarget.id);
        setDragStarted(true);
    };

    const handleDrop = async (ev: any) => {
        setDragStarted(false);
        let newSavedClasses = savedClasses.map((savedClass) => {
            return savedClass;
        });
        const dragBox = newSavedClasses.find((savedClass) => savedClass.savedClassId === dragId);

        let DragIndex = newSavedClasses.findIndex((x) => x.savedClassId === dragId);
        let DropIndex = newSavedClasses.findIndex((x) => x.savedClassId === ev.currentTarget.id);

        if (dragBox) newSavedClasses[DropIndex] = dragBox;

        if (DropIndex > DragIndex) {
            shiftToLeft(DropIndex, DragIndex, newSavedClasses);
        } else {
            shiftToRight(DropIndex, DragIndex, newSavedClasses);
        }
        setSavedClasses(newSavedClasses);

        const updatedUser = await apiSavedClasses.reorderSavedClasses(
            user.email,
            newSavedClasses.map((c) => c.savedClassId).join(','),
        );
        if (updatedUser) dispatch(userActions.updateUser(UserActionTypes.REORDER_SAVED_CLASSES, updatedUser));
    };

    const shiftToLeft = (DropIndex: number, DragIndex: number, newSavedClasses: any) => {
        for (let i = DropIndex; i > DragIndex; i--) {
            newSavedClasses[i - 1] = savedClasses[i];
        }
    };

    const shiftToRight = (DropIndex: number, DragIndex: number, newSavedClasses: any) => {
        for (let i = DropIndex; i < DragIndex; i++) {
            newSavedClasses[i + 1] = savedClasses[i];
        }
    };

    return (
        <>
            {tutorialVideoId && (
                <VideoModal
                    tutorialVideoId={tutorialVideoId}
                    setTutorialVideoId={setTutorialVideoId}
                    viewportConstant={ViewportSavedClasses}
                />
            )}
            <CommonAlert
                showAlert={isTrialFlowModalOpen}
                closeAlert={() => {
                    if (!isActivatingTrial) setIsTrialFlowModalOpen(false);
                }}
                content={
                    <TrialFlow
                        isShowMultipleSlides={true}
                        startingSlideId={'saved_class'}
                        setIsTrialFlowModalOpen={setIsTrialFlowModalOpen}
                        isActivatingTrial={isActivatingTrial}
                        setIsActivatingTrial={setIsActivatingTrial}
                        viewport={ViewportSavedClasses}
                        onTrialActivated={() => setIsTrialFlowModalOpen(false)}
                    />
                }
                viewportConfig={ViewportSavedClasses}
                customModalClassNames="small"
                customBoxClassNames={
                    isActivatingTrial
                        ? isEmailVerified
                            ? `subscriptionTrialFlowModal smallModal`
                            : ''
                        : `savedClassTrialFlowModal`
                }
            />

            {savedClasses && savedClasses.length > 0 ? (
                <>
                    {savedClasses.length === 1 && demoClass ? (
                        <LandingSavedClasses changeCurrentPage={changeCurrentPage} demoClass={demoClass} />
                    ) : (
                        <div className="stepPanel">
                            <div className="savedClassContainer">
                                <div className="steps_sec height_52">
                                    <Typography variant="body1">{t('lang_saved_classes.txt_my_classes')}</Typography>
                                    <WatchTutorialButton
                                        onClickHandler={() => {
                                            webviewMessenger.sendUsageLog(`Clicked "Watch tutorial" (manage_class)`);
                                            setTutorialVideoId('manage_class');
                                        }}
                                        isWhiteIcon={false}
                                        className="white_btn"
                                    />
                                </div>
                                <div
                                    className={`participent_grid ${isTrialOrPro && 'fullHeight'}`}
                                    id={savedClasses.length > 8 ? 'scrollDiv' : ''}
                                >
                                    <div className="participent_grid_inner">
                                        {savedClasses.map((savedClass, index) => {
                                            const basicLimitIndex = demoClass ? index - 1 : index;
                                            return (
                                                <SavedClassCard
                                                    key={index}
                                                    divId={savedClass.savedClassId}
                                                    dragId={dragId}
                                                    dragStarted={dragStarted}
                                                    savedClass={savedClass}
                                                    changeCurrentPage={changeCurrentPage}
                                                    handleDrag={handleDrag}
                                                    handleDrop={handleDrop}
                                                    isDisabled={
                                                        !isTrialOrPro &&
                                                        basicLimitIndex >= QuotaConstants.BASIC_USER_SAVED_CLASS_QUOTA
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            );
                                        })}
                                        {(isTrialOrPro ||
                                            savedClassCountWithoutDemo <
                                                QuotaConstants.BASIC_USER_SAVED_CLASS_QUOTA) && (
                                            <AddNewSavedClassCard changeCurrentPage={changeCurrentPage} />
                                        )}
                                    </div>
                                </div>
                                {!isTrialOrPro && savedClassCountWithoutDemo >= 2 && (
                                    <div className={`trialFlowBanner`}>
                                        <p className={`text`}>
                                            {t('lang_new_trial.max_3_classes', {
                                                quota: QuotaConstants.BASIC_USER_SAVED_CLASS_QUOTA,
                                            })}
                                        </p>
                                        <OpenProPitchButton
                                            btnLabel={
                                                !isAfterTrial
                                                    ? t('lang_new_trial.try_pro')
                                                    : t('lang_common.btn_learn_more')
                                            }
                                            onClickHandler={() => setIsTrialFlowModalOpen(true)}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <SavedClassesWelcome changeCurrentPage={changeCurrentPage} />
            )}
        </>
    );
};

export default SavedClassesList;
