import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { utilConstants } from '../../../constants/utils.constants';

interface WatchTutorialButtonInterface {
    onClickHandler: any;
    isWhiteIcon?: boolean;
    className?: string;
}

const WatchTutorialButton = ({ onClickHandler, isWhiteIcon, className }: WatchTutorialButtonInterface) => {
    const { t } = useTranslation();

    return (
        <div className={`watchTutorialContainer ${className}`}>
            <Button onClick={() => onClickHandler()} className="watchTutorialBtn" variant="contained" disableElevation>
                <img
                    src={
                        isWhiteIcon
                            ? utilConstants.IMAGE_URLS.WATCH_TUTORIAL_PLAY_ICON_WHITE
                            : utilConstants.IMAGE_URLS.WATCH_TUTORIAL_PLAY_ICON
                    }
                    alt="ClassPoint"
                />
                {t('lang_saved_classes.watch_tutorial')}
            </Button>
        </div>
    );
};

export default WatchTutorialButton;
