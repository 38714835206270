import { Typography } from '@mui/material';
import { Fragment } from 'react';

const MultimediaActivityModalPageDisplay = ({ index, total }: any) => {
    return (
        <Fragment>
            <div className="modalpagination">
                <Typography variant="body1">
                    {index + 1} / {total}
                </Typography>
            </div>
        </Fragment>
    );
};

export default MultimediaActivityModalPageDisplay;
