import { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { CP_CONTENT_URL } from '../../../constants/utils.constants';

export default function useActiveVideoInfo(videoId: string) {
    const [videoInfo, setVideoInfo] = useState<any>();
    const [videoDuration, setVideoDuration] = useState(0);

    useEffect(() => {
        const videoInfoPath = `./assets/videos/${videoId}/info.json`;
        fetchJSONDataFrom(videoInfoPath);

        loadVideo(`${CP_CONTENT_URL}/tutorial/${videoId}.mp4`)
            .then((result: any) => {
                setVideoDuration(result.duration);
            })
            .catch((error) => {
                console.log('error', error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoId]);

    const fetchJSONDataFrom = useCallback(async (path) => {
        const response = await fetch(path, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        const data = await response.json();
        setVideoInfo(data);
    }, []);

    const loadVideo = (url: string) =>
        new Promise((resolve, reject) => {
            try {
                let video = document.createElement('video');
                video.preload = 'metadata';

                video.onloadedmetadata = function () {
                    resolve(this);
                };

                video.onerror = function () {
                    reject();
                };

                video.src = url;
            } catch (e) {
                reject(e);
            }
        });

    return [videoInfo, videoDuration];
}
