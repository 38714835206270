import { TextField, Typography } from '@mui/material';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomAvatar, { AvatarType } from '../../../components/Common/CustomAvatar';
import { INPUT_LIMITS } from '../../../constants/utils.constants';
import SavedClassAddedParticipantInterface from '../../../interfaces/saved-class-added-participant.interface';
import { localService } from '../../../services/localStorageService';
import { checkIfNameIsTaken, updateAddedParticipants } from '../savedClassHelpers';

function AddParticipantInput({
    index,
    onCheckAndAddRows,
    isEditingClass,
    name,
    points,
    handleInputChange,
    resetData,
    setResetData,
    dataLength,
    sortedParticipants,
}: any) {
    const { t } = useTranslation();

    const pointRef = useRef<any>(null);
    const lastInputIndex = useRef(-1);

    const [isInputInFocus, setIsInputInFocus] = useState(index === 0 ? true : false);
    const [participantInput, setParticipantInput] = useState<{ name: string; points: number }>({
        name: '',
        points: 0,
    });
    const [inlineError, setInlineError] = useState('');

    let updateError: any;

    useEffect(() => {
        tryLoadCachedThisParticipant();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const tryLoadCachedThisParticipant = () => {
        const addedParticipants = localService.getTempAddedParticipants();
        if (!addedParticipants) return;
        const cachedThisParticipant = addedParticipants.find((p) => p.index === index);
        if (!cachedThisParticipant) return;
        setParticipantInput({
            name: cachedThisParticipant.name,
            points: cachedThisParticipant.points,
        });
        setInlineError(cachedThisParticipant.error);
    };

    const handleAddParticipantInputUpdate = (index: number, name: string, points: number, error: string) => {
        const thisParticipant: SavedClassAddedParticipantInterface = {
            index,
            name,
            points,
            error,
        };
        updateAddedParticipants(thisParticipant);
    };

    const validateAndUpdateNameOnChange = async (index: number, event: any) => {
        let name: any;
        typeof event === 'string' ? (name = event) : (name = event.target.value);

        const trimmedName = name.trimStart();
        let newError = '';
        if (trimmedName.length > INPUT_LIMITS.PARTICIPANT_NAME_MAX) {
            newError = t('lang_saved_classes.txt_name_too_long');
        } else if (checkIfNameIsTaken(index, trimmedName, sortedParticipants)) {
            newError = t('lang_saved_classes.txt_name_taken');
        }

        setInlineError(newError);
        updateError = newError;
        setParticipantInput({
            name: trimmedName,
            points: trimmedName && participantInput.points,
        });
        typeof event !== 'string' &&
            handleAddParticipantInputUpdate(index, trimmedName, participantInput.points, newError);

        if (lastInputIndex.current !== index) {
            if (trimmedName.length > 0 && !isEditingClass) onCheckAndAddRows(index);
            lastInputIndex.current = index;
        }
    };

    const handleNameSpecialKeyPresses = (index: number, event: any) => {
        const key = event.keyCode || event.charCode;
        // Enter key or Down arrow
        if (key === 13 || key === 40) {
            setTimeout(() => {
                document.getElementById('mui-inp' + (index + 1))?.focus();
            }, 1);
        }
        // Up arrow
        else if (key === 38) {
            if (index > 0)
                setTimeout(() => {
                    document.getElementById('mui-inp' + (index - 1))?.focus();
                }, 1);
        }
        // Backspace or Delete key
        else if (key === 8 || key === 46) {
            window.setTimeout(function () {
                const name = event.target.value;
                const trimmedName = name.trimStart();
                if (trimmedName.length > 0 && trimmedName.length < 2) {
                    const newError = t('lang_saved_classes.txt_name_too_short');
                    setInlineError(newError);
                    handleAddParticipantInputUpdate(index, name, participantInput.points, newError);
                }
            }, 1);
        }
    };

    const validateNameOnBlur = (index: number, event: any) => {
        let name: any;
        typeof event === 'string' ? (name = event) : (name = event.target.value);

        const trimmedName = name.trimStart();
        if (trimmedName.length > 0 && trimmedName.length < 2) {
            const newError = t('lang_saved_classes.txt_name_too_short');
            setInlineError(newError);
            updateError = newError;
            typeof event !== 'string' &&
                handleAddParticipantInputUpdate(index, trimmedName, participantInput.points, newError);
        }
    };

    const updatePointsOnChange = (index: number, event: any) => {
        let points = parseInt(event.target.value.replace(/\D/g, '')) || 0;
        setParticipantInput({ ...participantInput, points });
        handleAddParticipantInputUpdate(index, participantInput.name, points, inlineError);
    };

    const handlePointsSpecialKeyPresses = (index: number, event: any) => {
        // Enter key
        if (event.keyCode === 13) {
            setTimeout(() => {
                document.getElementById('mui-inp' + (index + 1))?.focus();
            }, 1);
        }
    };

    const focusPointField = (index: number) => {
        const input: any = document.getElementById('mui-inp-p' + index);
        input.focus();
        input.select();
    };

    useEffect(() => {
        resetData &&
            setParticipantInput({
                name: '',
                points: 0,
            });

        if (name !== undefined) {
            validateAndUpdateNameOnChange(index, name);
            validateNameOnBlur(index, name);
            if (points !== undefined) {
                handleAddParticipantInputUpdate(index, name, points, updateError);
            } else {
                handleAddParticipantInputUpdate(index, name, 0, updateError);
            }
        }

        index === dataLength && setResetData(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, points, resetData]);

    useEffect(() => {
        if (participantInput?.name === '') {
            pointRef.current.children[0].children[0].value = '';
        }
    }, [participantInput]);

    return (
        <div className="participent_table_box" key={index}>
            <div className="left">
                {!isEditingClass && (
                    <div className="p_number">
                        <Typography variant="body1">{index + 1}</Typography>
                    </div>
                )}

                <div className="profile_img">
                    {name !== undefined ? (
                        <CustomAvatar avatarName={name.substring(0, 1).trim()} type={AvatarType.PARTICIPANT} />
                    ) : participantInput.name.trim() ? (
                        <CustomAvatar
                            avatarName={participantInput.name.substring(0, 1).trim()}
                            type={AvatarType.PARTICIPANT}
                        />
                    ) : null}
                </div>
                <div className="profile_name">
                    {name !== undefined ? (
                        <TextField
                            placeholder={
                                isInputInFocus
                                    ? t('lang_saved_classes.txt_participant_name_validator', {
                                          inputLimit: INPUT_LIMITS.PARTICIPANT_NAME_MAX,
                                      })
                                    : ''
                            }
                            inputProps={{ maxLength: INPUT_LIMITS.PARTICIPANT_NAME_MAX }}
                            value={name}
                            variant="standard"
                            autoComplete="off"
                            onClick={() => {
                                document.getElementById(`mui-inp${index}`)?.focus();
                                setIsInputInFocus(true);
                            }}
                            onChange={(e) => {
                                validateAndUpdateNameOnChange(index, e);
                                handleInputChange(index, e.target.value);
                            }}
                            onKeyDown={(e) => handleNameSpecialKeyPresses(index, e)}
                            onBlur={(e) => {
                                validateNameOnBlur(index, e);
                                setIsInputInFocus(false);
                            }}
                            error={inlineError ? true : false}
                            id={`mui-inp${index}`}
                        />
                    ) : (
                        <TextField
                            placeholder={
                                isInputInFocus
                                    ? t('lang_saved_classes.txt_participant_name_validator', {
                                          inputLimit: INPUT_LIMITS.PARTICIPANT_NAME_MAX,
                                      })
                                    : ''
                            }
                            inputProps={{ maxLength: INPUT_LIMITS.PARTICIPANT_NAME_MAX }}
                            value={participantInput.name}
                            variant="standard"
                            autoComplete="off"
                            onClick={() => {
                                document.getElementById(`mui-inp${index}`)?.focus();
                                setIsInputInFocus(true);
                            }}
                            onChange={(e) => {
                                validateAndUpdateNameOnChange(index, e);
                            }}
                            onKeyDown={(e) => handleNameSpecialKeyPresses(index, e)}
                            onBlur={(e) => {
                                validateNameOnBlur(index, e);
                                setIsInputInFocus(false);
                            }}
                            error={inlineError ? true : false}
                            id={`mui-inp${index}`}
                        />
                    )}
                </div>
            </div>
            <div className="right">
                <div className="points">
                    {inlineError ? (
                        <Typography variant="body1" className="danges">
                            {inlineError}
                        </Typography>
                    ) : (
                        <Fragment>
                            {points !== undefined ? (
                                <TextField
                                    ref={pointRef}
                                    value={points > 0 ? points : name ? '0' : ''}
                                    inputProps={{ maxLength: 5 }}
                                    variant="standard"
                                    onChange={(e) => {
                                        updatePointsOnChange(index, e);
                                        handleInputChange(index, parseInt(e.target.value));
                                    }}
                                    autoComplete="none"
                                    onKeyDown={(e) => {
                                        handlePointsSpecialKeyPresses(index, e);
                                    }}
                                    disabled={!participantInput.name}
                                    id={`mui-inp-p${index}`}
                                />
                            ) : (
                                <TextField
                                    ref={pointRef}
                                    value={
                                        participantInput.points > 0
                                            ? participantInput.points
                                            : participantInput.name
                                            ? '0'
                                            : ''
                                    }
                                    inputProps={{ maxLength: 5 }}
                                    variant="standard"
                                    onChange={(e) => updatePointsOnChange(index, e)}
                                    autoComplete="none"
                                    onKeyDown={(e) => {
                                        handlePointsSpecialKeyPresses(index, e);
                                    }}
                                    disabled={!participantInput.name}
                                    id={`mui-inp-p${index}`}
                                />
                            )}
                            {points !== undefined ? (
                                <Typography variant="body1" onClick={() => focusPointField(index)}>
                                    {points > 1
                                        ? t('lang_saved_classes.txt_points')
                                        : t('lang_saved_classes.txt_point')}
                                </Typography>
                            ) : participantInput.name ? (
                                <Typography variant="body1" onClick={() => focusPointField(index)}>
                                    {participantInput.points > 1
                                        ? t('lang_saved_classes.txt_points')
                                        : t('lang_saved_classes.txt_point')}
                                </Typography>
                            ) : null}
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AddParticipantInput;
