const addedWords = [];

const removedWords = ['god', 'hell', 'suka'];

const Filter = require('bad-words'),
    filter = new Filter();

// Add custom words
filter.addWords(...addedWords);

// Remove specific words
filter.removeWords(...removedWords);

export default filter;
