import { useEffect, useState } from 'react';

import './Auth.scss';

import { CurrentPageType, SignUpUserInterface } from '../../interfaces/signInSignUp.interface';

// import { showJobRoleInSignUp } from '../../helpers/userhelper';
import { getSavedGeoInfo, setSavedGeoInfo } from '../../services/locationService';

import { SsoSignUpInterface } from '../../interfaces/sso-signup.interface';

import { SignUpProfileInfo } from './components/SignUpProfileInfo';
import { SingaporeOrgList } from '../../constants/singapore-org-list';
import SignupOrganization from '../../interfaces/signup-organization.interface';
import apiUser from '../../services/apiUser';
// import { SignUpSuccess } from './components/SignUpSuccess';
// import { SignUpInterest } from './components/SignUpInterest';

interface SignUpInterface {
    setCurrentPage: (arg: CurrentPageType) => void;
    setShowFullPageInterest: (arg: boolean) => void;
    ssoData: SsoSignUpInterface | undefined;
}

export const SignUp = ({ setCurrentPage, setShowFullPageInterest, ssoData }: SignUpInterface) => {
    // const [signUpStep, setSignUpStep] = useState<SignUpStepType>(SignUpStepType.ProfileInfo);
    // const [showJobRole, setShowJobRole] = useState(false);
    const [showMobileInputField, setShowMobileInputField] = useState(false);
    const [orgList, setOrgList] = useState<SignupOrganization[]>([]);
    const [userDetails, setUserDetails] = useState<SignUpUserInterface>({
        firstName: ssoData?.firstName || '',
        lastName: ssoData?.lastName || '',
        email: ssoData?.email || '',
        password: '',
        leadSource: '',
        jobRole: '',
        phone: '',
        phoneCode: '',
        interests: [],
        ssoType: ssoData?.provider || '',
        organization: ssoData?.organization || '',
    });

    // useEffect(() => {
    //     signUpStep === SignUpStepType.Interest ? setShowFullPageInterest(true) : setShowFullPageInterest(false);
    // }, [signUpStep]);

    const geoConditionalRender = async () => {
        let savedGeoInfo = getSavedGeoInfo();
        const updatedGeoInfo = await apiUser.getGeoInfoChange(savedGeoInfo?.ip || '');
        if (updatedGeoInfo) {
            setSavedGeoInfo(updatedGeoInfo);
            savedGeoInfo = updatedGeoInfo;
        }
        if (savedGeoInfo?.countryCode === 'ID') {
            setUserDetails({ ...userDetails, phoneCode: '62' });
            setShowMobileInputField(true);
        }
        if (savedGeoInfo?.countryCode === 'SG') {
            setOrgList(SingaporeOrgList);
        }
    };

    useEffect(() => {
        geoConditionalRender();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="innerContainer">
            {/* {signUpStep === SignUpStepType.ProfileInfo && ( */}
            <SignUpProfileInfo
                userDetails={userDetails}
                setUserDetails={setUserDetails}
                // setSignUpStep={setSignUpStep}
                setCurrentPage={setCurrentPage}
                showMobileInputField={showMobileInputField}
                // showJobRole={showJobRole}
                ssoData={ssoData}
                orgList={Array.from(new Set(orgList.map((org) => org.value.trim()))).map((value) => {
                    return orgList.find((obj) => obj.value.trim() === value.trim()) || { value: '' };
                })} // remove duplicate orgs
            />
            {/* )} */}
            {/* {signUpStep === SignUpStepType.Interest && (
                <SignUpInterest userDetails={userDetails} setUserDetails={setUserDetails} ssoData={ssoData} />
            )} */}
            {/* {signUpStep === SignUpStepType.Success && <SignUpSuccess userDetails={userDetails} />} */}
        </div>
    );
};
