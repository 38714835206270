import { useEffect, useRef, useState } from 'react';
import { FitToViewport } from 'react-fit-to-viewport';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

import { Button, LinearProgress } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import 'swiper/css';
import './ContextualTutorial.scss';

import { getValidUserFromStore } from '../../helpers/storeHelpers';
import apiUser from '../../services/apiUser';
import webviewMessenger from '../../services/webviewMessenger';

import { ContextualTutorialViewport } from '../../constants/viewport-constants';
import { contextualTutorialData } from '../../constants/contextual-tutorials';

import Loading from '../activity/Loading/Loading';
import EmptyModal from '../EmptyModal/EmptyModal';
import { useDispatch } from 'react-redux';
import { userActions } from '../../actions/user.actions';
import { UserActionTypes } from '../../constants/user-action-types';

const interactiveQuizTypes = [
    'multiple_choice',
    'word_cloud',
    'short_answer',
    'slide_drawing',
    'image_upload',
    'fill_blanks',
    'audio_record',
    'video_upload',
];

export default function ContextualTutorial() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const historyState = useLocation().state;
    const type = historyState?.type;
    // console.log('type', type);

    const content = contextualTutorialData[type];
    const mediaSlides = content ? content.slides : [];
    const textSlide = interactiveQuizTypes.includes(type) ? 'quiz' : type;
    const onBoardedEvent = content ? content.events[0] : '';
    const onboardingEventSkipped = content ? content.events[1] : '';

    const user = getValidUserFromStore(`ContextualTutorial`);

    const swiperRefImage = useRef<any>(null);
    const swiperRefDetails = useRef<any>(null);
    const navigationPrevRef = useRef<any>(null);
    const navigationNextRef = useRef<any>(null);

    const [isLoading, setIsLoading] = useState(true);
    const [showEmptyModal, setShowEmptyModal] = useState(false);
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    useEffect(() => {
        setShowEmptyModal(true);
        setTimeout(() => setShowEmptyModal(false), 1);
        webviewMessenger.sendUsageLog(`[O] Contextual tutorial pops up: ${type}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!content) return;
        const video = document.getElementById(`slideVideo_${activeSlideIndex}`) as HTMLVideoElement;
        if (video) {
            video.currentTime = 0;
            video.play();
        }
        if (activeSlideIndex === mediaSlides.length - 1) addOnBoardingEvent(onBoardedEvent);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSlideIndex]);

    const addOnBoardingEvent = async (event: string, closeWebview = false) => {
        if (closeWebview) closeWebviewWindow();
        const updatedUser = await apiUser.addUserOnBoarding(user.email, event);
        if (updatedUser) dispatch(userActions.updateUser(UserActionTypes.ADDED_ONBOARDING_EVENT, updatedUser));
    };

    const closeWebviewWindow = () => {
        webviewMessenger.sendHideWebview();
        navigate('/loading');
    };

    const handleModalClose = () => {
        if (activeSlideIndex === mediaSlides.length - 1) {
            webviewMessenger.sendUsageLog(`[O] Clicked "Got it!" button`);
            closeWebviewWindow();
        }
    };

    return (
        <FitToViewport
            className="viewport"
            width={ContextualTutorialViewport.width}
            height={ContextualTutorialViewport.height}
            minZoom={ContextualTutorialViewport.minZoom}
            maxZoom={ContextualTutorialViewport.maxZoom}
        >
            {showEmptyModal && <EmptyModal viewportConfig={ContextualTutorialViewport} />}

            <div className={`contextualTutorial`}>
                <div className={`imageContainer`}>
                    {mediaSlides.length > 0 && (
                        <Swiper
                            ref={swiperRefImage}
                            onActiveIndexChange={(e) => {
                                setActiveSlideIndex(e.realIndex);
                                swiperRefDetails.current.swiper.slideTo(e.realIndex);
                            }}
                            modules={[Navigation]}
                        >
                            {mediaSlides.map((data, index) => {
                                return (
                                    <SwiperSlide key={index} className={`slideItem`}>
                                        {isLoading && <Loading />}
                                        <video
                                            id={`slideVideo_${index}`}
                                            src={data}
                                            className={'image'}
                                            onLoadedData={() => {
                                                if (index === 0) setIsLoading(false);
                                            }}
                                        />
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    )}
                </div>

                <div className="line_progress">
                    <LinearProgress
                        className={'regular'}
                        value={((activeSlideIndex + 1) / mediaSlides.length) * 100}
                        variant="determinate"
                    />
                </div>

                <div className={`textContainer ${activeSlideIndex > 0 && 'shrinkButtonContainer'}`}>
                    <Swiper
                        ref={swiperRefDetails}
                        navigation={{
                            prevEl: navigationPrevRef.current,
                            nextEl: navigationNextRef.current,
                        }}
                        grabCursor={true}
                        onActiveIndexChange={(e) => {
                            const previousIndex = e.previousIndex;
                            const realIndex = e.realIndex;
                            if (realIndex > previousIndex)
                                webviewMessenger.sendUsageLog(
                                    `[O] Contextual tutorial (${type}) forwarded to slide #${realIndex + 1}`,
                                );
                            else if (realIndex < previousIndex)
                                webviewMessenger.sendUsageLog(
                                    `[O] Contextual tutorial (${type}) backwarded to slide #${realIndex + 1}`,
                                );
                            setActiveSlideIndex(e.realIndex);
                            swiperRefImage.current.swiper.slideTo(e.realIndex);
                        }}
                        loop={false}
                        modules={[Navigation]}
                        className={'sliderContainer'}
                    >
                        {Array.from({ length: mediaSlides.length }).map((slide, index) => {
                            return (
                                <SwiperSlide key={index} className={`slideItem`}>
                                    <div className={'slideContent'}>
                                        <h3 className={`title`}>
                                            {t(`lang_contextual_tutorial.${textSlide}_slide_${activeSlideIndex}_title`)}
                                        </h3>
                                        <p className={`description`}>
                                            {t(
                                                `lang_contextual_tutorial.${textSlide}_slide_${activeSlideIndex}_details`,
                                            )}
                                        </p>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>

                    <div className={'btnContainer'}>
                        {activeSlideIndex === 0 && (
                            <Button
                                className="nav_previous"
                                onClick={() => {
                                    webviewMessenger.sendUsageLog(`[O] Clicked "Skip tutorial" button`);
                                    addOnBoardingEvent(onboardingEventSkipped, true);
                                }}
                            >
                                <p className="text">{t('lang_contextual_tutorial.skip_tutorial')}</p>
                            </Button>
                        )}

                        {activeSlideIndex >= 0 && (
                            <Button
                                ref={navigationPrevRef}
                                className={`nav_previous ${activeSlideIndex === 0 && 'hideBackBtn'}`}
                            >
                                <ArrowBackIcon className="icon" />
                                <p className="text">{t('lang_common.btn_back')}</p>
                            </Button>
                        )}

                        <Button
                            ref={navigationNextRef}
                            className={`nav_forward ${activeSlideIndex === mediaSlides.length - 1 && 'hideNextBtn'}`}
                        >
                            <>
                                <p className="text">{t('lang_contextual_tutorial.next')}</p>
                                <ArrowForwardIcon className="icon" />
                            </>
                        </Button>

                        {activeSlideIndex === mediaSlides.length - 1 && (
                            <Button onClick={() => handleModalClose()} className={`nav_forward`}>
                                <p>{t('lang_contextual_tutorial.got_it')}</p>
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </FitToViewport>
    );
}
