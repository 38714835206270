import { useTranslation } from 'react-i18next';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Typography } from '@mui/material';

import CommonButton from '../../../components/Common/CommonButton';
import CommonAlert from '../../../components/Common/CommonAlert';
import { ViewportConstants } from '../../../constants/viewport-constants';

interface AllPickedResetAlertInterface {
    allPickedResetModalOpen: boolean;
    setAllPickedResetModalOpen: (arg: boolean) => void;
    confirmResetPickNames: (arg: string) => void;
}

const AllPickedResetAlert = ({
    allPickedResetModalOpen,
    setAllPickedResetModalOpen,
    confirmResetPickNames,
}: AllPickedResetAlertInterface) => {
    const { t } = useTranslation();

    return (
        <CommonAlert
            showAlert={allPickedResetModalOpen}
            closeAlert={() => setAllPickedResetModalOpen(false)}
            viewportConfig={ViewportConstants}
            content={
                <>
                    <div className="media">
                        <div className="media_icon">
                            <ReportProblemIcon className="warning" />
                        </div>
                        <div className="media_body">
                            <div className="alert_content">
                                <Typography variant="body1">{t('lang_pick_names.txt_confirm_reset')}</Typography>
                            </div>
                        </div>
                    </div>

                    <div className="button_sec">
                        <CommonButton
                            isLoading={false}
                            variant="contained"
                            type="button"
                            text={t('lang_common.btn_yes')}
                            clickFunction={() => confirmResetPickNames('AllPickedResetAlert')}
                        />

                        <CommonButton
                            isLoading={false}
                            variant="text"
                            type="button"
                            text={t('lang_common.btn_no')}
                            clickFunction={() => setAllPickedResetModalOpen(false)}
                        />
                    </div>
                </>
            }
        />
    );
};

export default AllPickedResetAlert;
