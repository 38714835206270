export enum UpdateToolbarActionsType {
    addWhiteboard = 'addWhiteboard',
    quickPoll = 'quickPoll',
    startTimer = 'startTimer',
    openBrowserTab = 'openBrowserTab',
    pickName = 'pickName',
    openLeaderboard = 'openLeaderboard',
    awardStar = 'awardStar',
    deductStar = 'deductStar',
    aiProcessSlide = 'aiProcessSlide',
    generateAiQuestion = 'generateAiQuestion',
    saveAiQuestionSlide = 'saveAiQuestionSlide',
}
