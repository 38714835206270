import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Typography } from '@mui/material';

import CommonCheckBox from '../../../components/Common/CommonCheckBox';
import CommonButton from '../../../components/Common/CommonButton';
import { webviewSettingsActions } from '../../../actions/webview-settings.actions';
import { WebviewSettingsActionTypes } from '../../../constants/webview-settings-action-types';

const DeleteResponsesAlert = ({ onConfirmDeleteResponses, onCancelAlert }: any) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [dismissReminder, setDismissReminder] = useState(false);

    const handleConfirm = () => {
        if (dismissReminder) handleDismissReminder();
        onConfirmDeleteResponses();
    };

    const handleCancel = () => {
        if (dismissReminder) handleDismissReminder();
        onCancelAlert();
    };

    const handleDismissReminder = () => {
        dispatch(
            webviewSettingsActions.updateWebviewSettings(WebviewSettingsActionTypes.UPDATE_SHOW_DELETE_RESPONSE_ALERT, {
                isShowDeleteResponsesAlert: false,
            }),
        );
    };

    return (
        <Fragment>
            <div className="media">
                <div className="media_icon">
                    <ReportProblemIcon className="warning" />
                </div>
                <div className="media_body">
                    <div className="alert_content">
                        <Typography variant="body1">{t('lang_activity.txt_confirm_delete_responses')}</Typography>
                        <div className="alert_check">
                            <CommonCheckBox
                                isHookRegistered={false}
                                label={t('lang_common.txt_dont_remind_me_again')}
                                changeFunction={(e: any) => setDismissReminder(e.target.checked)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="button_sec">
                <CommonButton
                    isLoading={false}
                    variant="contained"
                    type="button"
                    text={t('lang_common.btn_yes')}
                    clickFunction={handleConfirm}
                />
                <CommonButton
                    isLoading={false}
                    variant="text"
                    type="button"
                    text={t('lang_common.btn_no')}
                    clickFunction={handleCancel}
                />
            </div>
        </Fragment>
    );
};

export default DeleteResponsesAlert;
