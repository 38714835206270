import { forwardRef, Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FitToViewport } from 'react-fit-to-viewport';
import Lottie from 'react-lottie-player';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { Box, Modal, Typography } from '@mui/material';

import './MultipleChoice.scss';

import { getActivityFromStore, getValidUserFromStore } from '../../../helpers/storeHelpers';
import { canEditResponses, populateParticipantChips } from '../../../helpers/activityHelpers';
import webviewMessenger from '../../../services/webviewMessenger';

import ActivityResponseInterface from '../../../interfaces/activity-response.interface';
import ParticipantChipInterface from '../../../interfaces/participant-chip.interface';
import StoreInterface from '../../../interfaces/store.interface';
import { ActivityPropsInterface } from '../../../interfaces/activity-props.interface';
import { utilConstants } from '../../../constants/utils.constants';
import { ViewportConstants } from '../../../constants/viewport-constants';
import { AnimationConfig, MultipleChoiceTickLottie, SimpleFadeAnimation } from '../../../constants/animation-configs';

import CommonChip from '../../../components/Common/CommonChip';
import GivePointButton from '../../../components/Common/GivePointButton';
import { FullScreenPointLottie } from '../../../components/Common/AnimationModules';

import greenTick from '../../../assets/animation/green_tick.json';
import pinkTick from '../../../assets/animation/pink_tick.json';
import blueTick from '../../../assets/animation/blue_tick.json';
import yellowTick from '../../../assets/animation/yellow_tick.json';
import purpleTick from '../../../assets/animation/violet_tick.json';
import orangeTick from '../../../assets/animation/orange_tick.json';
import greenTickInner from '../../../assets/animation/green_tick_inner.json';
import pinkTickInner from '../../../assets/animation/pink_tick_inner.json';
import blueTickInner from '../../../assets/animation/blue_tick_inner.json';
import yellowTickInner from '../../../assets/animation/yellow_tick_inner.json';
import purpleTickInner from '../../../assets/animation/violet_tick_inner.json';
import orangeTickInner from '../../../assets/animation/orange_tick_inner.json';
import { activityActions } from '../../../actions/activity.action';

const tickArray = [greenTick, pinkTick, blueTick, yellowTick, purpleTick, orangeTick, greenTick, pinkTick];

const tickArrayInner = [
    greenTickInner,
    pinkTickInner,
    blueTickInner,
    yellowTickInner,
    purpleTickInner,
    orangeTickInner,
    greenTickInner,
    pinkTickInner,
];

interface ColumnDataInterface {
    choice: string;
    count: number;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const MultipleChoiceContent = forwardRef(({ isShowCorrectAnswer }: any, ref: any) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const user = getValidUserFromStore('MultipleChoiceContent');
    const classSession = useSelector((state: StoreInterface) => state.classSession);
    const activity = getActivityFromStore();

    const contentnewref = useRef<any>(null);
    const timeOutPointUpdate = useRef<any>();
    const givePointsConfettiRef = useRef<any>();

    const activityProps = activity.activityProps as ActivityPropsInterface;
    const mcChoices = activityProps.mcChoices as string[];
    const mcCorrectAnswers = activityProps.mcCorrectAnswers as string[];
    const activityResponses = activity.activityResponses;
    const canEditResponsesInView = canEditResponses(activity, user.email);

    const [chartData, setChartData] = useState<ColumnDataInterface[]>([]);
    const [highestResponseCount, setHighestResponseCount] = useState<ColumnDataInterface[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [choiceInModal, setChoiceInModal] = useState('');
    const [participantChips, setParticipantChips] = useState<ParticipantChipInterface[]>([]);
    const [stars, setStars] = useState<any>([]);
    const [animationStart, setAnimationStart] = useState(false);
    const [pointsAdded, setPointsAdded] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);

    const generateChartData = () => {
        let chartData: ColumnDataInterface[] = [];
        let count = 0;

        mcChoices.forEach((choice) => {
            count = activityResponses.filter((response) => response.responseData.includes(choice)).length;
            chartData.push({ choice, count });
        });

        const highestCount = Math.max(...chartData.map((data) => data.count));

        setChartData(chartData);
        setHighestResponseCount(chartData.filter((data) => data.count === highestCount));
    };

    const showPercentage = (choice: string) => {
        const column = chartData.find((column) => column.choice === choice);
        if (!column) return 0;
        return activityResponses.length !== 0 ? Math.round((column.count / activityResponses.length) * 100) : 0;
    };

    const handleOpenModal = (choice: string) => {
        webviewMessenger.sendUsageLog(
            `${classSession ? '[S] ' : ''}Opened participant chips modal for Option: ${choice}`,
        );
        setChoiceInModal(choice);
        const activityFilter = activityResponses.filter((response: ActivityResponseInterface) =>
            response.responseData.includes(choice),
        );
        const participants = populateParticipantChips(activityFilter);
        setParticipantChips(participants);
        setIsModalOpen(true);
    };

    const givePoint = (choice: string, points: number) => {
        const matchedResponses = activityResponses.filter((response: ActivityResponseInterface) =>
            response.responseData.includes(choice),
        );
        const responsesAndPoints = matchedResponses.map((response: ActivityResponseInterface) => ({
            response,
            points: points,
        }));
        dispatch(activityActions.handleGivePointsToResponses(responsesAndPoints));
    };

    const givePointHandler = () => {
        clearTimeout(timeOutPointUpdate.current);
        setIsAnimating(true);
        givePointsConfettiRef.current.playLottieAnimation(true);
        setPointsAdded(pointsAdded + 1);
    };

    const calculateBarHeight = (choice: string) => {
        const isHighestValue = highestResponseCount.find((data) => data.choice === choice);
        const selectedChoiceCount = chartData.find((data) => data.choice === choice)?.count;

        if (isHighestValue) return 100;
        else if (showPercentage(choice) < 1) return 2;
        else if (selectedChoiceCount) return (100 / highestResponseCount[0].count) * selectedChoiceCount;
        else return 0;
    };

    useEffect(() => {
        if (pointsAdded > 0) {
            timeOutPointUpdate.current = setTimeout(() => {
                choiceInModal && givePoint(choiceInModal, pointsAdded);
                setPointsAdded(0);
                setTimeout(() => {
                    setIsAnimating(false);
                    if (givePointsConfettiRef.current) givePointsConfettiRef.current.playLottieAnimation(false);
                }, AnimationConfig.CONFETTI_LOTTIE_DURATION - AnimationConfig.GIVE_POINT_DELAY);
            }, AnimationConfig.GIVE_POINT_DELAY);
        }
        return () => clearTimeout(timeOutPointUpdate.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pointsAdded]);

    useEffect(() => {
        return () => setIsAnimating(false);
    }, []);

    useEffect(() => {
        generateChartData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activity]);

    const handleModalClose = () => {
        if (!isAnimating) {
            webviewMessenger.sendUsageLog(`${classSession ? '[S] ' : ''}Closed participant chips modal`);
            setIsModalOpen(false);
        }
    };

    return (
        <Fragment>
            <Modal
                open={isModalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Fragment>
                    <FitToViewport
                        width={ViewportConstants.width}
                        height={ViewportConstants.height}
                        minZoom={ViewportConstants.minZoom}
                        maxZoom={ViewportConstants.maxZoom}
                        className="modal_viewport multipleChoiceFullScreenLottie"
                    >
                        <FullScreenPointLottie ref={givePointsConfettiRef} />

                        <div className="close_modal" onClick={handleModalClose}></div>
                        <Box
                            component={motion.div}
                            variants={SimpleFadeAnimation}
                            initial="initial"
                            animate="animate"
                            sx={style}
                            className="word_cloud_modal new_word_cloud"
                        >
                            <div className="word_cloudhead">
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    {t('lang_activity.option_dialog', { option: choiceInModal })}
                                </Typography>
                            </div>
                            <div className="word_cloud_modal_body">
                                <div className="WC_body_inner multipleChoiceSparkleLottie">
                                    {participantChips.map((item, index) => (
                                        <CommonChip
                                            participantAvatar={item.participantAvatar}
                                            participantName={item.participantName}
                                            key={index}
                                            isModalOpen={isModalOpen}
                                            stars={stars}
                                            setStars={setStars}
                                            animationStart={animationStart}
                                            setAnimationStart={setAnimationStart}
                                        />
                                    ))}
                                </div>
                            </div>
                            {canEditResponsesInView && (
                                <div className="modal_footer">
                                    <GivePointButton
                                        label={t('lang_common.btn_give_points_to_all')}
                                        onClick={() => {
                                            webviewMessenger.sendUsageLog(
                                                `${
                                                    classSession ? '[S] ' : ''
                                                }Clicked "Award stars to all" for selected participants`,
                                            );
                                            givePointHandler();
                                        }}
                                        activity="modifyButton"
                                    />
                                </div>
                            )}
                        </Box>
                    </FitToViewport>
                </Fragment>
            </Modal>

            <div className="barchart_sec" ref={contentnewref}>
                <div className="multipleChoiceContainer">
                    <div className="inner_barchart">
                        <ul className={`chart ${isShowCorrectAnswer ? 'checking' : ''}`}>
                            <AnimatePresence>
                                {mcChoices.map((choice, index) => {
                                    const barHeight = calculateBarHeight(choice);
                                    const barColorHandler = () => {
                                        if (!isShowCorrectAnswer || mcCorrectAnswers.includes(choice))
                                            return `${utilConstants.MCQ_COLOR_ARRAY[index]}`;
                                        else return `${utilConstants.MCQ_COLOR_FADE_ARRAY[index]}`;
                                    };

                                    return (
                                        <Fragment key={index}>
                                            <li
                                                className={`${
                                                    isShowCorrectAnswer && mcCorrectAnswers.includes(choice)
                                                        ? ''
                                                        : 'wrong'
                                                }`}
                                                style={
                                                    showPercentage(choice) > 0
                                                        ? {
                                                              cursor: 'default',
                                                          }
                                                        : {
                                                              cursor: 'default',
                                                          }
                                                }
                                            >
                                                <motion.div
                                                    initial={
                                                        activity.activityMode ===
                                                            utilConstants.ACTIVITY_MODE.VIEW_ACTIVITY_IN_SLIDESHOW ||
                                                        activity.activityMode ===
                                                            utilConstants.ACTIVITY_MODE.VIEW_ACTIVITY_IN_EDIT
                                                            ? false
                                                            : {
                                                                  height: '2%',
                                                              }
                                                    }
                                                    transition={{
                                                        type: 'spring',
                                                        stiffness: 1000,
                                                        mass: 2.8,
                                                        damping: 40,
                                                    }}
                                                    animate={
                                                        activity.activityMode ===
                                                            utilConstants.ACTIVITY_MODE.VIEW_ACTIVITY_IN_SLIDESHOW ||
                                                        activity.activityMode ===
                                                            utilConstants.ACTIVITY_MODE.VIEW_ACTIVITY_IN_EDIT
                                                            ? false
                                                            : {
                                                                  height: `${barHeight}%`,
                                                              }
                                                    }
                                                    style={
                                                        showPercentage(choice) > 0
                                                            ? {
                                                                  background: barColorHandler(),
                                                                  cursor: 'pointer',
                                                                  height:
                                                                      activity.activityMode ===
                                                                          utilConstants.ACTIVITY_MODE
                                                                              .VIEW_ACTIVITY_IN_SLIDESHOW ||
                                                                      activity.activityMode ===
                                                                          utilConstants.ACTIVITY_MODE
                                                                              .VIEW_ACTIVITY_IN_EDIT
                                                                          ? `${barHeight}%`
                                                                          : '',
                                                              }
                                                            : {
                                                                  background: barColorHandler(),
                                                                  cursor: 'default',
                                                                  height:
                                                                      activity.activityMode ===
                                                                          utilConstants.ACTIVITY_MODE
                                                                              .VIEW_ACTIVITY_IN_SLIDESHOW ||
                                                                      activity.activityMode ===
                                                                          utilConstants.ACTIVITY_MODE
                                                                              .VIEW_ACTIVITY_IN_EDIT
                                                                          ? `${barHeight}%`
                                                                          : '',
                                                              }
                                                    }
                                                    title={choice}
                                                    onClick={() => {
                                                        if (barHeight !== 2) handleOpenModal(choice);
                                                    }}
                                                >
                                                    {isShowCorrectAnswer && mcCorrectAnswers.includes(choice) && (
                                                        <div className={barHeight < 25 ? 'no_height' : ''}>
                                                            <motion.span
                                                                variants={MultipleChoiceTickLottie}
                                                                initial="initial"
                                                                animate="animate"
                                                            >
                                                                <Lottie
                                                                    loop={false}
                                                                    animationData={
                                                                        showPercentage(choice) === 0
                                                                            ? tickArray[index]
                                                                            : tickArrayInner[index]
                                                                    }
                                                                    play
                                                                    speed={0.5}
                                                                />
                                                            </motion.span>
                                                        </div>
                                                    )}

                                                    {(chartData[index]?.count > 0 ||
                                                        activity.activityMode !==
                                                            utilConstants.ACTIVITY_MODE.START_ACTIVITY) && (
                                                        <span
                                                            className="chart_tooltip"
                                                            style={
                                                                showPercentage(choice) > 0
                                                                    ? {
                                                                          cursor: 'pointer',
                                                                      }
                                                                    : {
                                                                          cursor: 'default',
                                                                      }
                                                            }
                                                        >
                                                            {chartData[index]?.count} ({showPercentage(choice)}
                                                            %)
                                                        </span>
                                                    )}
                                                </motion.div>
                                            </li>
                                        </Fragment>
                                    );
                                })}
                            </AnimatePresence>
                        </ul>
                    </div>
                </div>
            </div>
        </Fragment>
    );
});

export default MultipleChoiceContent;
