import _ from 'lodash';
import { PickNamesCardThemes } from '../constants/pickNameFlipCardImageConstant';
import { utilConstants } from '../constants/utils.constants';
import ParticipantInterface from '../interfaces/participant.interface';
import PickNamesParticipantInterface from '../interfaces/pick-names-participant.interface';
import { store } from './store';

export const generateNewPickNamesList = (participants: ParticipantInterface[]): PickNamesParticipantInterface[] => {
    const randomParticipants = participants.sort(() => Math.random() - 0.5);
    // try Tony's idea of mixing all themes together
    const cardThemeIndex = 0;

    let pickNamesList: PickNamesParticipantInterface[] = [];
    for (let index = 0; index < randomParticipants.length; index++) {
        const p = randomParticipants[index];
        const cardImageIndex = Math.floor(PickNamesCardThemes[cardThemeIndex].length * Math.random());
        const pickNamesParticipant: PickNamesParticipantInterface = {
            classSessionId: store.getState().classSession?.classSessionId || '',
            participantId: p.participantId,
            participantName: p.participantName,
            participantAvatar: p.participantAvatar,
            participantSumPoints: p.participantPoints + p.participantTotalPoints,
            isOnline: p.joined && !p.left ? true : false,
            colorHex: utilConstants.PICK_NAME_COLORS[index % utilConstants.PICK_NAME_COLORS.length],
            cardIndex: [cardThemeIndex, cardImageIndex],
            isPicked: false,
            groupId: p.groupId,
        };
        pickNamesList.push(pickNamesParticipant);
    }
    return pickNamesList;
};

export const findPicked = (list: PickNamesParticipantInterface[]) => {
    return _.orderBy(
        list.filter((item) => item.isPicked),
        'pickedTime',
        'desc',
    );
};

export const getCardImageUrl = (cardIndex: number[]): string => {
    const currentThemeIndex = cardIndex[0];
    const currentImageIndex = cardIndex[1];
    return PickNamesCardThemes[currentThemeIndex][currentImageIndex];
};

export const findUnPicked = (list: PickNamesParticipantInterface[]) => {
    return list.filter((item) => !item.isPicked);
};

export const findParticipantPickNamesListIndex = (participant: PickNamesParticipantInterface): number => {
    const pickNamesList: PickNamesParticipantInterface[] = store.getState().pickNamesList;
    const participantIndex = pickNamesList.findIndex((p) => p.participantId === participant.participantId);
    return participantIndex;
};

export const accuratesFontSize = (participantList: any, participantName: any) => {
    const participantCount = participantList.length;
    let nameLength = participantName.length;

    if (participantCount <= 10) {
        if (nameLength < 2) return '30px';
        else if (nameLength < 4) return '28px';
        else if (nameLength < 6) return '26px';
        else if (nameLength < 8) return '24px';
        else if (nameLength < 10) return '22px';
        else if (nameLength < 12) return '20px';
        else if (nameLength < 14) return '18px';
        else return '16px';
    } else if (participantCount <= 20) {
        if (nameLength < 2) return '26px';
        else if (nameLength < 4) return '24px';
        else if (nameLength < 6) return '22px';
        else if (nameLength < 8) return '20px';
        else if (nameLength < 10) return '18px';
        else if (nameLength < 12) return '16px';
        else if (nameLength < 14) return '14px';
        else return '12px';
    } else if (participantCount <= 30) {
        if (nameLength < 2) return '24px';
        else if (nameLength < 4) return '22px';
        else if (nameLength < 6) return '20px';
        else if (nameLength < 8) return '18px';
        else if (nameLength < 10) return '16px';
        else if (nameLength < 12) return '14px';
        else if (nameLength < 14) return '12px';
        else return '10px';
    } else {
        const size = 600 / participantCount;
        return `${size}px`;
    }
};

export const generateWheelCssClass = (participantCount: number) => {
    let wheelclass = '';
    if (participantCount === 1) wheelclass = 'fullzone';
    else if (participantCount === 2) wheelclass = 'halfzone';
    else wheelclass = 'multizone';
    return wheelclass;
};

export const getSingleParticipantInModal = (
    participantList: PickNamesParticipantInterface[],
    participantIds: string[],
) => {
    const participant = participantList.find((item) => item.participantId === participantIds[0]);
    return participant;
};

export const getMultipleParticipantsInModal = (
    participantList: PickNamesParticipantInterface[],
    participantIds: string[],
) => {
    let resultArray: PickNamesParticipantInterface[] = [];
    participantIds.forEach((id) => {
        const participant = participantList.find((item) => item.participantId === id);
        participant && resultArray.push(participant);
    });
    return resultArray;
};
