import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

import CommonCheckBox from '../../../components/Common/CommonCheckBox';
import CommonButton from '../../../components/Common/CommonButton';

const DeleteAlert = ({ confirmDelete, closeAlert, toggleShowWhiteBoardDeleteAlert }: any) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div className="media">
                <div className="media_icon">
                    <ReportProblemIcon className="warning" />
                </div>
                <div className="media_body">
                    <div className="alert_content">
                        <Typography variant="body1">
                            {t('lang_my_whiteboard.txt_delete_whiteboard_confirmation')}
                        </Typography>
                        <div className="alert_check">
                            <CommonCheckBox
                                isHookRegistered={false}
                                label={t('lang_common.txt_dont_remind_me_again')}
                                changeFunction={(e: any) => toggleShowWhiteBoardDeleteAlert(e.target.checked)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="button_sec">
                <CommonButton
                    isLoading={false}
                    variant="contained"
                    type="button"
                    text={t('lang_common.btn_yes')}
                    clickFunction={(e: any) => confirmDelete(e)}
                />

                <CommonButton
                    isLoading={false}
                    variant="text"
                    type="button"
                    text={t('lang_common.btn_no')}
                    clickFunction={() => closeAlert()}
                />
            </div>
        </Fragment>
    );
};

export default DeleteAlert;
