import { useEffect, useState, useRef, forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import { AnimatePresence } from 'framer-motion';

import styles from './QuickPoll.module.scss';

import { populateParticipantChips } from '../../../helpers/activityHelpers';
import { getActivityFromStore } from '../../../helpers/storeHelpers';
import { localService } from '../../../services/localStorageService';
import { classSessionActions } from '../../../actions/class-session.action';

import { UpdateToolbarActionsType } from '../../../dtos/update-toolbar-actions.dto';
import ParticipantChipInterface from '../../../interfaces/participant-chip.interface';
import { ActivityPropsInterface } from '../../../interfaces/activity-props.interface';
import { AnimationConfig } from '../../../constants/animation-configs';

import QuickPollChoiceItem from './QuickPollChoiceItem';
import QuickPollModal from './modal/QuickPollModal';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    quickpoll_noresponse: '',
};

interface QpItemInterface {
    id: string;
    iconUrl: string;
    color: string;
    count: number;
}

function QuickPollContent(props: any, ref: any) {
    const dispatch = useDispatch();
    const activity = getActivityFromStore();
    const activityProps = activity.activityProps as ActivityPropsInterface;
    const qpType = activityProps.qpType as string;
    const qpChoices = activityProps.qpChoices as string[];
    const [qpItems, setQpItems] = useState<QpItemInterface[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalChoiceId, setModalChoiceId] = useState<string>();
    const [participantChips, setParticipantChips] = useState<ParticipantChipInterface[]>([]);

    const timeOutPointUpdate = useRef<any>();
    const givePointsConfettiRef = useRef<any>();
    const [pointsAdded, setPointsAdded] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        const qpItems: QpItemInterface[] = qpChoices.map((qpChoice) => {
            const parsed = JSON.parse(qpChoice);
            const count = activity.activityResponses.filter((response) => response.responseData === parsed.id).length;
            return { id: parsed.id, iconUrl: parsed.iconUrl, color: parsed.color, count };
        });
        setQpItems(qpItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activity.activityResponses, activity.activityProps]);

    const determineQuickPollLabelStyle = () => {
        switch (qpType) {
            case 'qpTrueFalse':
                return styles.qpTrueFalse;
            case 'qpYesNoUnsure':
                return styles.qpYesNoUnsure;
            case 'qpRating':
                return styles.qpRating;
            case 'qpMultiple':
                if (qpChoices.length === 1) return styles.qpMultiple + ' ' + styles.qpMultipleOne;
                else if (qpChoices.length === 2) return styles.qpMultiple + ' ' + styles.qpMultipleTwo;
                else if (qpChoices.length === 3) return styles.qpMultiple + ' ' + styles.qpMultipleThree;
                else if (qpChoices.length === 4) return styles.qpMultiple + ' ' + styles.qpMultipleFour;
                else if (qpChoices.length === 5) return styles.qpMultiple + ' ' + styles.qpMultipleFive;
                else if (qpChoices.length === 7) return styles.qpMultiple + ' ' + styles.qpMultipleSeven;
                else return styles.qpMultiple + ' ' + styles.qpMultipleEight;
            default:
                return '';
        }
    };

    const handleModalOpen = (id: string) => {
        if (activity.activityResponses.length <= 0) return;
        const filterData = activity.activityResponses.filter((item) => item.responseData === id);
        const participants = populateParticipantChips(filterData);
        setParticipantChips(participants);
        setModalChoiceId(id);
        setIsModalOpen(true);
    };

    const givePoint = (points: number) => {
        const participantsAndPoints = participantChips.map((p) => ({
            participantId: p.participantId,
            points: points,
        }));
        localService.updateToolbarActionsFromWebview(UpdateToolbarActionsType.awardStar);
        dispatch(classSessionActions.givePointsToParticipants(participantsAndPoints));
    };

    const givePointHandler = () => {
        clearTimeout(timeOutPointUpdate.current);
        setIsAnimating(true);
        givePointsConfettiRef.current.playLottieAnimation(true);
        setPointsAdded(pointsAdded + 1);
    };

    useEffect(() => {
        if (pointsAdded > 0) {
            timeOutPointUpdate.current = setTimeout(() => {
                givePoint(pointsAdded);
                setPointsAdded(0);
                setTimeout(() => {
                    setIsAnimating(false);
                    if (givePointsConfettiRef.current) givePointsConfettiRef.current.playLottieAnimation(false);
                }, AnimationConfig.CONFETTI_LOTTIE_DURATION - AnimationConfig.GIVE_POINT_DELAY);
            }, AnimationConfig.GIVE_POINT_DELAY);
        }
        return () => clearTimeout(timeOutPointUpdate.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pointsAdded]);

    useEffect(() => {
        return () => setIsAnimating(false);
    }, []);

    return (
        <>
            <QuickPollModal
                isModalOpen={isModalOpen}
                isAnimating={isAnimating}
                setIsModalOpen={setIsModalOpen}
                givePointsConfettiRef={givePointsConfettiRef}
                style={style}
                modalChoiceId={modalChoiceId}
                participantChips={participantChips}
                givePointHandler={givePointHandler}
            />

            <div className={`${styles.quickPoll_sec} quick_poll_content`}>
                <div className={styles.quickPool_container}>
                    <div className={styles.inner_barchart}>
                        <ul className={`${styles.chart} ${determineQuickPollLabelStyle()}`}>
                            <AnimatePresence>
                                {qpItems.map((qpItem) => {
                                    return (
                                        <QuickPollChoiceItem
                                            key={qpItem.id}
                                            styles={styles}
                                            qpItem={qpItem}
                                            maxCount={Math.max(...qpItems.map((qpItem) => qpItem.count))}
                                            qpType={qpType}
                                            qpChoicesCount={qpChoices.length}
                                            handleOpen={handleModalOpen}
                                        />
                                    );
                                })}
                            </AnimatePresence>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default forwardRef(QuickPollContent);
