import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FitToViewport } from 'react-fit-to-viewport';
import { useTranslation } from 'react-i18next';

import { pickNamesActions } from '../../actions/pickNames.action';
import { webviewSettingsActions } from '../../actions/webview-settings.actions';
import { WebviewSettingsActionTypes } from '../../constants/webview-settings-action-types';
import { ViewportConstants } from '../../constants/viewport-constants';
import { findUnPicked } from '../../helpers/pickNamesHelpers';
import NoParticipantInClass from '../../components/Common/NoParticipantInClass';
import AutoPickModal from './modals/AutoPickModal';
import PickNamesResetAlert from './modals/PickNamesResetAlert';
import StoreInterface from '../../interfaces/store.interface';
import AllPickedResetAlert from './modals/AllPickedResetAlert';
import EmptyModal from '../EmptyModal/EmptyModal';
import PickNamesWheelView from './views/PickNamesWheelView';
import PickNamesCardView from './views/PickNamesCardView';

import './PickNames.scss';
import { filterOnlineParticipants } from '../../helpers/classSessionHelpers';
import PickNamesFooter from './components/PickNamesFooter';
import PickNamesHeader from './components/PickNamesHeader';
import Header from '../../components/layout/Header/Header';

const PickNames = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classSession = useSelector((state: StoreInterface) => state.classSession);
    const webviewSettingsSlideshow = useSelector((state: StoreInterface) => state.webviewSettingsSlideshow);
    const isPickNameShowOnlineParticipant = webviewSettingsSlideshow.isPickNameShowOnlineParticipant;
    const participantList = classSession ? classSession.participantList : [];
    const participantListWithOnlineStatus = isPickNameShowOnlineParticipant
        ? filterOnlineParticipants(participantList)
        : participantList;
    const pickNamesList = useSelector((state: StoreInterface) => state.pickNamesList);

    const givePointsConfettiRef = useRef<any>();
    const isPickNamesModeWheel = webviewSettingsSlideshow.isPickNamesModeWheel;

    const pickNamesListWithOnlineStatus = isPickNameShowOnlineParticipant
        ? pickNamesList.filter((data) => data.isOnline === true)
        : pickNamesList;

    const [showEmptyModal, setShowEmptyModal] = useState(false);
    const [isResetModalOpen, setIsResetModalOpen] = useState(false);
    const [allPickedResetModalOpen, setAllPickedResetModalOpen] = useState(false);
    const [isDismissResetReminderChecked, setIsDismissResetReminderChecked] = useState(false);
    const [isAutoPickModalOpen, setIsAutoPickModalOpen] = useState(false);
    const [autoPickCount, setAutoPickCount] = useState(1);
    const [isAnimating, setIsAnimating] = useState(false);

    const handleResetPickNames = () => {
        if (!classSession) return;
        dispatch(pickNamesActions.unPickAll());
        setTimeout(() => {
            dispatch(pickNamesActions.startPickNames(true));
            dispatch(pickNamesActions.updatePickNamesList());
        }, 500);
    };

    const handleStartAutoPick = () => {
        const unpickedParticipantCount = findUnPicked(pickNamesListWithOnlineStatus).length;
        if (unpickedParticipantCount <= 0) return setAllPickedResetModalOpen(true);

        const maxAllowed = Math.min(10, unpickedParticipantCount);
        setAutoPickCount(Math.min(maxAllowed, Math.max(autoPickCount, 1)));
        setIsAutoPickModalOpen(true);
    };

    const increaseAutoPickCount = () => {
        if (autoPickCount < pickNamesList.filter((p) => !p.isPicked).length) {
            setAutoPickCount(autoPickCount + 1);
        }
    };

    const decreaseAutoPickCount = () => {
        if (autoPickCount > 1) {
            setAutoPickCount(autoPickCount - 1);
        }
    };

    const confirmResetPickNames = (caller: string) => {
        if (isDismissResetReminderChecked)
            dispatch(
                webviewSettingsActions.updateWebviewSettings(
                    WebviewSettingsActionTypes.TURN_OFF_PICK_NAMES_RESET_REMINDER,
                    {
                        ...{
                            isShowPickNamesResetReminder: false,
                        },
                    },
                ),
            );

        handleResetPickNames();

        switch (caller) {
            case 'PickNamesResetAlert':
                return setIsResetModalOpen(false);
            case 'AllPickedResetAlert':
                return setAllPickedResetModalOpen(false);
        }
    };

    useEffect(() => {
        if (classSession && participantList.length > 0) dispatch(pickNamesActions.startPickNames());
        setShowEmptyModal(true);
        setTimeout(() => {
            setShowEmptyModal(false);
        }, 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (classSession) dispatch(pickNamesActions.updatePickNamesList());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classSession]);

    return (
        <>
            <FitToViewport
                className="viewport pickNameFullScreenLottie"
                width={ViewportConstants.width}
                height={ViewportConstants.height}
                minZoom={ViewportConstants.minZoom}
                maxZoom={ViewportConstants.maxZoom}
            >
                {showEmptyModal && <EmptyModal viewportConfig={ViewportConstants} />}

                <PickNamesResetAlert
                    isResetModalOpen={isResetModalOpen}
                    setIsResetModalOpen={setIsResetModalOpen}
                    confirmResetPickNames={confirmResetPickNames}
                    setIsDismissResetReminderChecked={setIsDismissResetReminderChecked}
                />

                <AllPickedResetAlert
                    allPickedResetModalOpen={allPickedResetModalOpen}
                    setAllPickedResetModalOpen={setAllPickedResetModalOpen}
                    confirmResetPickNames={confirmResetPickNames}
                />
                <Header appBarTitle={t('lang_pick_names.title')}>
                    <PickNamesHeader />
                </Header>
                <AutoPickModal
                    isAnimating={isAnimating}
                    isAutoPickModalOpen={isAutoPickModalOpen}
                    setIsAutoPickModalOpen={setIsAutoPickModalOpen}
                    autoPickCount={autoPickCount}
                    onIncrease={() => increaseAutoPickCount()}
                    onDecrease={() => decreaseAutoPickCount()}
                    setAllPickedResetModalOpen={setAllPickedResetModalOpen}
                    setIsAnimating={setIsAnimating}
                    givePointsConfettiRef={givePointsConfettiRef}
                />

                {participantList.length > 0 ? (
                    <>
                        {isPickNameShowOnlineParticipant && participantListWithOnlineStatus.length === 0 ? (
                            <NoParticipantInClass content="pickNamesAllOffline" />
                        ) : (
                            <div className="pickname_main">
                                <div className="pickNameContainer">
                                    {isPickNamesModeWheel ? (
                                        <PickNamesWheelView
                                            isAnimating={isAnimating}
                                            setIsAnimating={setIsAnimating}
                                            givePointsConfettiRef={givePointsConfettiRef}
                                        />
                                    ) : (
                                        <PickNamesCardView
                                            isAnimating={isAnimating}
                                            setIsAnimating={setIsAnimating}
                                            givePointsConfettiRef={givePointsConfettiRef}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    <NoParticipantInClass />
                )}

                <PickNamesFooter
                    pickNamesListWithOnlineStatus={pickNamesListWithOnlineStatus}
                    handleStartAutoPick={handleStartAutoPick}
                    setIsResetModalOpen={setIsResetModalOpen}
                    handleResetPickNames={handleResetPickNames}
                />
            </FitToViewport>
        </>
    );
};

export default PickNames;
