import { Fragment, useRef, useState, useEffect } from 'react';
import { FitToViewport } from 'react-fit-to-viewport';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '@mui/material/Modal';
import { Box, Button } from '@mui/material';

import { getActivityFromStore } from '../../../helpers/storeHelpers';
import webviewMessenger from '../../../services/webviewMessenger';
import { activityActions } from '../../../actions/activity.action';

import ActivityResponseInterface from '../../../interfaces/activity-response.interface';
import StoreInterface from '../../../interfaces/store.interface';
import { ViewportConstants } from '../../../constants/viewport-constants';
import { utilConstants } from '../../../constants/utils.constants';
import { AnimationConfig } from '../../../constants/animation-configs';

import ShortAnswerModal from '../ShortAnswer/ShortAnswerModal';
import ImageUploadModal from '../ImageUpload/ImageUploadModal';
import SlideDrawingModal from '../SlideDrawing/SlideDrawingModal';
import MultimediaActivityModalPageDisplay from './MultimediaActivityModalPageDisplay';

interface MultiMediaActivityModalInterface {
    selectedResponses: ActivityResponseInterface[];
    choosedResponse: ActivityResponseInterface;
    setChoosedResponse: (arg: ActivityResponseInterface) => void;
    responseIndex: number;
    setResponseIndex: (arg: number) => void;
    onResponseIndexDeleted: (arg: number) => void;
    lazyLoadedResponsesCount: number;
    onShowMore: () => void;
    searchKeyword: string;
    isModalOpen: boolean;
    setIsModalOpen: (arg: boolean) => void;
    activityType: string;
    isVoting: boolean;
}

function MultiMediaActivityModal({
    selectedResponses,
    choosedResponse,
    setChoosedResponse,
    responseIndex,
    setResponseIndex,
    onResponseIndexDeleted,
    lazyLoadedResponsesCount,
    onShowMore,
    searchKeyword,
    isModalOpen,
    setIsModalOpen,
    activityType,
    isVoting,
}: MultiMediaActivityModalInterface) {
    const dispatch = useDispatch();
    const timeOutAnimation = useRef<any>();
    const classSession = useSelector((state: StoreInterface) => state.classSession);
    const activity = getActivityFromStore();

    const [pointsAdded, setPointsAdded] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);

    const showNext = () => {
        webviewMessenger.sendUsageLog(`${classSession ? '[S] ' : ''}Moved to next response in modal`);
        if (responseIndex >= selectedResponses.length - 1) {
            setResponseIndex(0);
            setChoosedResponse(selectedResponses[0]);
        } else {
            setResponseIndex(responseIndex + 1);
            if (lazyLoadedResponsesCount - responseIndex <= 4) onShowMore();
            setChoosedResponse(selectedResponses[responseIndex + 1]);
        }
    };

    const showPrev = () => {
        webviewMessenger.sendUsageLog(`${classSession ? '[S] ' : ''}Moved to previous response in modal`);
        if (responseIndex <= 0) {
            setResponseIndex(selectedResponses.length - 1);
            setChoosedResponse(selectedResponses[selectedResponses.length - 1]);
        } else {
            setResponseIndex(responseIndex - 1);
            setChoosedResponse(selectedResponses[responseIndex - 1]);
        }
    };

    const givePoint = (response: ActivityResponseInterface, points: number) => {
        dispatch(activityActions.handleGivePointsToResponses([{ response, points }]));
    };

    const handleGivePointButtonClick = () => {
        webviewMessenger.sendUsageLog(`${classSession ? '[S] ' : ''}Awarded star to a response from modal`);
        clearTimeout(timeOutAnimation.current);
        setIsAnimating(true);
        setPointsAdded(pointsAdded + 1);
    };

    useEffect(() => {
        if (pointsAdded > 0) {
            timeOutAnimation.current = setTimeout(() => {
                givePoint(choosedResponse, pointsAdded);
                setPointsAdded(0);
                setTimeout(() => {
                    setIsAnimating(false);
                }, AnimationConfig.TICK_LOTTIE_DURATION - AnimationConfig.GIVE_POINT_DELAY);
            }, AnimationConfig.TICK_LOTTIE_DURATION);
        }
        return () => clearTimeout(timeOutAnimation.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pointsAdded]);

    useEffect(() => {
        setChoosedResponse(activity.activityResponses[responseIndex]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activity.activityResponses]);

    useEffect(() => {
        function onKeyup(e: any) {
            if (e.key === 'ArrowLeft') {
                !isAnimating && showPrev();
            }
            if (e.key === 'ArrowRight') {
                !isAnimating && showNext();
            }
        }
        window.addEventListener('keyup', onKeyup);
        return () => window.removeEventListener('keyup', onKeyup);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseIndex, isAnimating]);

    const handleModalClose = () => {
        if (!isAnimating) {
            webviewMessenger.sendUsageLog(`${classSession ? '[S] ' : ''}Closed response modal`);
            setIsModalOpen(false);
        }
    };

    return (
        <>
            <Modal
                open={isModalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="short_ans_modal"
            >
                <Fragment>
                    <FitToViewport
                        className="modal_viewport"
                        width={ViewportConstants.width}
                        height={ViewportConstants.height}
                        minZoom={ViewportConstants.minZoom}
                        maxZoom={ViewportConstants.maxZoom}
                    >
                        <Box className="short_ans_box">
                            <div className="close_modal" onClick={handleModalClose}></div>
                            <Fragment>
                                <Button
                                    className="sky-slider__prev"
                                    title="button"
                                    onClick={() => {
                                        !isAnimating && showPrev();
                                    }}
                                >
                                    <svg
                                        className="chevron-right"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 306 306"
                                    >
                                        <polygon points="94.35,0 58.65,35.7 175.95,153 58.65,270.3 94.35,306 247.35,153 " />
                                    </svg>
                                </Button>
                                <Button
                                    className="sky-slider__next"
                                    title="button"
                                    onClick={() => !isAnimating && showNext()}
                                >
                                    <svg
                                        className="chevron-right"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 306 306"
                                    >
                                        <polygon points="94.35,0 58.65,35.7 175.95,153 58.65,270.3 94.35,306 247.35,153 " />
                                    </svg>
                                </Button>

                                <div className="sky_slider_container">
                                    {activityType === utilConstants.ACTIVITY_TYPE.SHORT_ANSWER && (
                                        <ShortAnswerModal
                                            choosedResponse={choosedResponse}
                                            responseIndex={responseIndex}
                                            onResponseIndexDeleted={onResponseIndexDeleted}
                                            searchKeyword={searchKeyword}
                                            isVoting={isVoting}
                                            handleGivePointButtonClick={handleGivePointButtonClick}
                                            isAnimating={isAnimating}
                                            pointsAdded={pointsAdded}
                                        />
                                    )}
                                    {activityType === utilConstants.ACTIVITY_TYPE.SLIDE_DRAWING && (
                                        <SlideDrawingModal
                                            choosedResponse={choosedResponse}
                                            responseIndex={responseIndex}
                                            onResponseIndexDeleted={onResponseIndexDeleted}
                                            searchKeyword={searchKeyword}
                                            isVoting={isVoting}
                                            handleGivePointButtonClick={handleGivePointButtonClick}
                                            isAnimating={isAnimating}
                                            pointsAdded={pointsAdded}
                                        />
                                    )}
                                    {activityType === utilConstants.ACTIVITY_TYPE.IMAGE_UPLOAD && (
                                        <ImageUploadModal
                                            choosedResponse={choosedResponse}
                                            responseIndex={responseIndex}
                                            onResponseIndexDeleted={onResponseIndexDeleted}
                                            searchKeyword={searchKeyword}
                                            isVoting={isVoting}
                                            handleGivePointButtonClick={handleGivePointButtonClick}
                                            isAnimating={isAnimating}
                                            pointsAdded={pointsAdded}
                                        />
                                    )}
                                </div>
                                <MultimediaActivityModalPageDisplay
                                    index={responseIndex}
                                    total={selectedResponses.length}
                                />
                            </Fragment>
                        </Box>
                    </FitToViewport>
                </Fragment>
            </Modal>
        </>
    );
}

export default MultiMediaActivityModal;
