import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useSound from 'use-sound';
import { Button, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import StoreInterface from '../../../interfaces/store.interface';
import { SoundLibrary } from '../../../constants/sound-library';
import { timerStopWatchActions } from '../../../actions/timer-stopwatch.action';

const TimerSoundSetting = ({ setShowSettings }: { setShowSettings: (arg: boolean) => void }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const timerStopwatch = useSelector((state: StoreInterface) => state.timerStopwatch);

    const [timeupSoundId, setTimeupSoundId] = useState(timerStopwatch.timeupSoundId || 0);

    const [playSound_0, { stop: stopSound_0 }] = useSound(SoundLibrary.TIMER_TIMEUP_SOUNDS[0]);
    const [playSound_1, { stop: stopSound_1 }] = useSound(SoundLibrary.TIMER_TIMEUP_SOUNDS[1]);
    const [playSound_2, { stop: stopSound_2 }] = useSound(SoundLibrary.TIMER_TIMEUP_SOUNDS[2]);
    const [playSound_3, { stop: stopSound_3 }] = useSound(SoundLibrary.TIMER_TIMEUP_SOUNDS[3]);
    const [playSound_4, { stop: stopSound_4 }] = useSound(SoundLibrary.TIMER_TIMEUP_SOUNDS[4]);
    const [playSound_5, { stop: stopSound_5 }] = useSound(SoundLibrary.TIMER_TIMEUP_SOUNDS[5]);

    const soundArray: { id: number; text: string }[] = [
        { id: 0, text: t('lang_timer.sound_doorbell') },
        { id: 1, text: t('lang_timer.sound_pager') },
        { id: 2, text: t('lang_timer.sound_tada') },
        { id: 3, text: t('lang_timer.sound_fairy') },
        { id: 4, text: t('lang_timer.sound_heaviness') },
        { id: 5, text: t('lang_timer.sound_carnival') },
    ];

    const stopAudio = () => {
        stopSound_0();
        stopSound_1();
        stopSound_2();
        stopSound_3();
        stopSound_4();
        stopSound_5();
    };

    const previewSound = (id: number) => {
        stopAudio();
        switch (id) {
            case 0:
                playSound_0();
                break;
            case 1:
                playSound_1();
                break;
            case 2:
                playSound_2();
                break;
            case 3:
                playSound_3();
                break;
            case 4:
                playSound_4();
                break;
            case 5:
                playSound_5();
                break;
            default:
                playSound_0();
                break;
        }

        setTimeupSoundId(() => {
            return id;
        });

        dispatch(timerStopWatchActions.updateTimeupSoundId(id));
    };

    const goBackHandler = () => {
        stopAudio();
        setShowSettings(false);
    };

    return (
        <Fragment>
            <div className="sound_setting_sec">
                <div className="inner_sound_sec">
                    <div onClick={() => goBackHandler()} className="head">
                        <ArrowBackIcon />
                        <Typography variant="body1">{t('lang_timer.heading_time_up_alarm')}</Typography>
                    </div>
                    <div className="sound_list">
                        {soundArray.map((data) => {
                            return (
                                <Button
                                    onClick={() => previewSound(data.id)}
                                    key={data.id}
                                    className={data.id === timeupSoundId ? 'active' : ''}
                                >
                                    {data.text} {data.id === timeupSoundId && <CheckIcon />}
                                </Button>
                            );
                        })}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default TimerSoundSetting;
