import { Fragment, useEffect, useRef, useState } from 'react';
import Typewriter from 'typewriter-effect';
import { useDispatch, useSelector } from 'react-redux';
import { motion, useAnimation } from 'framer-motion';
import Lottie from 'react-lottie-player';
import { useTranslation } from 'react-i18next';

import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoneIcon from '@mui/icons-material/Done';
import { Button } from '@mui/material';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

import { aiQuizActions } from '../../../actions/aiQuiz.action';
import { classSessionActions } from '../../../actions/class-session.action';
import {
    BT,
    generateMcqLabel,
    generatePrompt,
    generateQuestionsFromSlide,
    isFillBlanks,
    isQuestionTypeSelected,
    QT,
    removeExistingMcqLabel,
    saveQuestionAsSlide,
    isFillBlanksOrShortAnswer,
} from '../../../helpers/aiQuiz.helper';
import { getValidUserFromStore } from '../../../helpers/storeHelpers';

import StoreInterface from '../../../interfaces/store.interface';
import { AiDisplayMode } from '../../../interfaces/aiQuiz.interface';
import { utilConstants } from '../../../constants/utils.constants';

import CommonButton from '../../../components/Common/CommonButton';
import { aiQuestionAnimation } from './animation/Question.animation';
import greenTick from '../../../assets/animation/green_tick.json';
import { GeneratingView, ErrorDisplayView, ReadingSlideView, ThinkingView } from './QuestionFlowViews';
import { AiIcon } from './AiIcon';
import { UpdateToolbarActionsType } from '../../../dtos/update-toolbar-actions.dto';
import { localService } from '../../../services/localStorageService';

interface QuestionFlowInterface {
    questionTypes: QT[];
    btLevels: BT[];
    questionLanguage: string;
    errorMessage: string;
    setErrorMessage: (arg: string) => void;
}

function QuestionFlow({
    questionTypes,
    btLevels,
    questionLanguage,
    errorMessage,
    setErrorMessage,
}: QuestionFlowInterface) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = getValidUserFromStore('QuestionFlow');

    const AiIconAnimationRef = useRef<any>(null);
    const isRtl = document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? true : false;

    const aiQuizState = useSelector((state: StoreInterface) => state.aiQuiz);
    const activeSlideId = aiQuizState.props.activeSlideId;
    const activeSlideQuizState = aiQuizState[activeSlideId];
    const activeSlideQuestionList = activeSlideQuizState?.questionList || [];
    const currentQuestionIndex = activeSlideQuizState?.currentQuestionIndex || 0;
    const currentQuestion = activeSlideQuestionList[currentQuestionIndex] || undefined;
    const isCurrentQuestionViewed = currentQuestion?.viewed || false;
    const pageTotal = isCurrentQuestionViewed
        ? activeSlideQuestionList.filter((q) => q.viewed).length
        : activeSlideQuestionList.filter((q) => q.viewed).length + 1;

    const [shouldNavigateToNewQuestion, setShouldNavigateToNewQuestion] = useState(currentQuestion ? false : true);
    const [isQuestionGenerating, setIsQuestionGenerating] = useState(currentQuestion ? false : true);
    const [isSaveSlideAnimating, setIsSaveSlideAnimating] = useState(false);
    const [isSaveSlideAnimationPlayed, setIsSaveSlideAnimationPlayed] = useState(false);
    const [insertAsSlideBtnText, setInsertAsSlideBtnText] = useState('');
    const [selectNextQuestion, setSelectNextQuestion] = useState(false);
    const [isShowingAnswer, setIsShowingAnswer] = useState(false);
    const [displayMode, setDisplayMode] = useState(
        currentQuestion ? AiDisplayMode.SHOWING_QUESTION : AiDisplayMode.READING_SLIDE,
    );

    const aiIconAnimation = useAnimation();
    const textBoxAnimation = useAnimation();
    const sideIconAnimation = useAnimation();
    const btnTextAnimation = useAnimation();
    const btnOneAnimation = useAnimation();
    const btnTwoAnimation = useAnimation();
    const btnThreeAnimation = useAnimation();

    const aiQuestionAnimationConfig = aiQuestionAnimation(
        isRtl,
        aiIconAnimation,
        textBoxAnimation,
        sideIconAnimation,
        btnTextAnimation,
        btnOneAnimation,
        btnTwoAnimation,
        btnThreeAnimation,
        isQuestionGenerating,
    );

    const remainingQuestions = activeSlideQuestionList.filter((q) => q.viewed === false);

    const selectNextOrPrevQuestion = (actionType: 'prev' | 'next') => {
        const newIndex = actionType === 'prev' ? currentQuestionIndex - 1 : currentQuestionIndex + 1;
        dispatch(aiQuizActions.changeCurrentQuestionIndex(activeSlideId, newIndex));
    };

    const questionSelectionHandler = () => {
        if (remainingQuestions.length > 0) {
            const nextQuestionIndex = activeSlideQuestionList.findIndex((q) => q.viewed === false);
            dispatch(aiQuizActions.changeCurrentQuestionIndex(activeSlideId, nextQuestionIndex));
            return setShouldNavigateToNewQuestion(true);
        }

        if (activeSlideQuizState.hasGenerationDone) {
            const questionTypes = (user.userPreferences?.aiQuizSettings?.questionTypes as QT[]) || [
                QT.MC,
                QT.FB,
                QT.SA,
            ];
            const btLevels = (user.userPreferences?.aiQuizSettings?.btLevels as BT[]) || [
                BT.REMEMBER,
                BT.UNDERSTAND,
                BT.APPLY,
                BT.ANALYZE,
                BT.EVALUATE,
                BT.CREATE,
            ];
            const prompt = generatePrompt(
                activeSlideQuizState.slideText,
                activeSlideQuizState.slideNote,
                btLevels,
                [
                    isQuestionTypeSelected(questionTypes, QT.MC),
                    isQuestionTypeSelected(questionTypes, QT.FB),
                    isQuestionTypeSelected(questionTypes, QT.SA),
                ],
                questionLanguage,
            );
            dispatch(classSessionActions.generateAiQuiz(activeSlideId, prompt));
            setShouldNavigateToNewQuestion(true);
        }
    };

    const generateQuestionHandler = () => {
        aiQuestionAnimationConfig.fadeOutAnimation();

        if (remainingQuestions.length === 0) {
            const timeFromLatestQuestion = Date.now() - activeSlideQuizState.latestQuestionTimestamp;
            setDisplayMode(AiDisplayMode.THINKING);
            if (activeSlideQuizState.hasGenerationDone || timeFromLatestQuestion > 1000 * 10) {
                // if last question was received more than 10 seconds ago, consider there won't be any new questions coming
                generateQuestionsFromSlide(
                    dispatch,
                    {
                        currentSlideId: aiQuizState.props.activeSlideId,
                        currentSlideText: aiQuizState.props.activeSlideText,
                        currentSlideNote: aiQuizState.props.activeSlideNote,
                    },
                    btLevels,
                    questionTypes,
                    questionLanguage,
                );
            }
            setSelectNextQuestion(true);
        } else {
            setDisplayMode(AiDisplayMode.THINKING);
            setSelectNextQuestion(true);
        }
    };

    useEffect(() => {
        if (selectNextQuestion && remainingQuestions.length > 0) {
            questionSelectionHandler();
            setSelectNextQuestion(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectNextQuestion, aiQuizState]);

    useEffect(() => {
        if (shouldNavigateToNewQuestion && currentQuestion) {
            setTimeout(() => {
                setDisplayMode(AiDisplayMode.GENERATING);
                setShouldNavigateToNewQuestion(false);
            }, 1000);
        }
        if (activeSlideQuizState?.hasGenerationDone && activeSlideQuestionList.length === 0) {
            setErrorMessage(t('lang_ai_quiz.question_flow.api_error'));
            setDisplayMode(AiDisplayMode.ERROR_DISPLAY);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aiQuizState, currentQuestion, shouldNavigateToNewQuestion]);

    useEffect(() => {
        if (isSaveSlideAnimationPlayed)
            setTimeout(() => {
                setInsertAsSlideBtnText(`${t(`lang_ai_quiz.question_flow.save_as_slide`)}`);
                setIsSaveSlideAnimationPlayed(false);
            }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSaveSlideAnimationPlayed]);

    useEffect(() => {
        let text = '';
        if (isSaveSlideAnimating) text = t(`lang_ai_quiz.question_flow.saving`);
        else if (isSaveSlideAnimationPlayed) text = t(`lang_ai_quiz.question_flow.saved`);
        else text = t(`lang_ai_quiz.question_flow.save_as_slide`);
        setInsertAsSlideBtnText(text);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSaveSlideAnimating, isSaveSlideAnimationPlayed]);

    useEffect(() => {
        aiQuestionAnimationConfig.startAnimation();
        setTimeout(() => AiIconAnimationRef.current?.idleAnimation());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleShowAnswerComplete = () => {
        dispatch(aiQuizActions.showQuestionAnswer(activeSlideId, currentQuestionIndex));
        setIsShowingAnswer(false);
    };

    return (
        <div className="random_Qa_sec">
            <div className="inner_random_Qa">
                <div className="chat_bot_sec_wrapper">
                    <div className="chat_bot_area">
                        <motion.div className={`questionAreaContainer`}>
                            {currentQuestion && currentQuestion.bloomTaxonomyLevel && (
                                <motion.div
                                    initial={aiQuestionAnimationConfig.animationConstants.sideIconInitial}
                                    animate={sideIconAnimation}
                                    className="level_chip_wrapper"
                                >
                                    <div className={`level_chip ${currentQuestion.bloomTaxonomyLevel}`}>
                                        <img src={utilConstants.CHATBOT.PYRAMID} alt="PYRAMID" />
                                        {currentQuestion.bloomTaxonomyLevel}
                                    </div>
                                </motion.div>
                            )}

                            <motion.div
                                initial={aiQuestionAnimationConfig.animationConstants.textBoxInitial}
                                animate={textBoxAnimation}
                                className={`question_area alternateFlow`}
                            >
                                <div className="innerQuestion_area">
                                    {displayMode === AiDisplayMode.READING_SLIDE && (
                                        <ReadingSlideView errorMessage={errorMessage} setDisplayMode={setDisplayMode} />
                                    )}

                                    {displayMode === AiDisplayMode.ERROR_DISPLAY && (
                                        <ErrorDisplayView errorMessage={errorMessage} />
                                    )}

                                    {displayMode === AiDisplayMode.THINKING && <ThinkingView />}

                                    {displayMode === AiDisplayMode.GENERATING && (
                                        <GeneratingView
                                            aiQuestionAnimationConfig={aiQuestionAnimationConfig}
                                            setIsQuestionGenerating={setIsQuestionGenerating}
                                            setDisplayMode={setDisplayMode}
                                        />
                                    )}

                                    {displayMode === AiDisplayMode.SHOWING_QUESTION && currentQuestion && (
                                        <div className="contentWrapper">
                                            <span className="question_section">{currentQuestion.question}</span>
                                            <br />
                                            {currentQuestion.options && <span className="border_line"></span>}

                                            {currentQuestion.options &&
                                                currentQuestion.options.map((option, index) => (
                                                    <Fragment key={index}>
                                                        <span
                                                            className={`option fullWidth ${
                                                                (isShowingAnswer || currentQuestion.showAnswer) &&
                                                                index === currentQuestion.correct &&
                                                                'correct'
                                                            }`}
                                                        >
                                                            <div className="tickLottie">
                                                                <span>
                                                                    <Lottie
                                                                        loop={false}
                                                                        animationData={greenTick}
                                                                        play={
                                                                            (isShowingAnswer ||
                                                                                currentQuestion.showAnswer) &&
                                                                            index === currentQuestion.correct
                                                                        }
                                                                        onComplete={handleShowAnswerComplete}
                                                                        speed={1}
                                                                    />
                                                                </span>
                                                            </div>
                                                            <span className="indexHighlight">
                                                                {generateMcqLabel(index) + '.'}
                                                            </span>{' '}
                                                            {removeExistingMcqLabel(option)}
                                                        </span>

                                                        <br />
                                                    </Fragment>
                                                ))}

                                            {isFillBlanksOrShortAnswer(currentQuestion) &&
                                                (isShowingAnswer || currentQuestion.showAnswer) && (
                                                    <span className="correct_ans">
                                                        <i className="icon">
                                                            <CheckCircleIcon />
                                                        </i>
                                                        {isShowingAnswer ? (
                                                            <Typewriter
                                                                options={{
                                                                    cursor: '||',
                                                                    delay: 5,
                                                                }}
                                                                onInit={(typewriter: any) => {
                                                                    typewriter
                                                                        .typeString(
                                                                            `<span class="bold">${
                                                                                isFillBlanks(currentQuestion)
                                                                                    ? `${t(
                                                                                          `lang_ai_quiz.question_flow.correct_answer`,
                                                                                      )}:`
                                                                                    : `${t(
                                                                                          `lang_ai_quiz.question_flow.suggested_answer`,
                                                                                      )}:`
                                                                            }<br /></span> ${
                                                                                isFillBlanks(currentQuestion)
                                                                                    ? currentQuestion.correctAnswer
                                                                                    : currentQuestion.answer
                                                                            }`,
                                                                        )
                                                                        .callFunction(() => {
                                                                            handleShowAnswerComplete();
                                                                        })
                                                                        .start();
                                                                }}
                                                            />
                                                        ) : (
                                                            <span className="answer">
                                                                <span className="bold">
                                                                    {isFillBlanks(currentQuestion)
                                                                        ? `${t(
                                                                              `lang_ai_quiz.question_flow.correct_answer`,
                                                                          )}:`
                                                                        : `${t(
                                                                              `lang_ai_quiz.question_flow.suggested_answer`,
                                                                          )}:`}
                                                                    <br />
                                                                </span>{' '}
                                                                {isFillBlanks(currentQuestion)
                                                                    ? currentQuestion.correctAnswer
                                                                    : currentQuestion.answer}
                                                            </span>
                                                        )}
                                                    </span>
                                                )}
                                        </div>
                                    )}
                                </div>
                            </motion.div>
                        </motion.div>

                        <motion.div
                            initial={aiQuestionAnimationConfig.animationConstants.iconInitial}
                            animate={aiIconAnimation}
                            className="ai_icon"
                        >
                            {/* <img src={utilConstants.CHATBOT.AiICON} alt="Ai Icon" /> */}
                            <div className="animatedAiIcon">
                                <AiIcon ref={AiIconAnimationRef} actionType="idle" />
                            </div>
                        </motion.div>

                        <motion.div
                            initial={aiQuestionAnimationConfig.animationConstants.sideIconInitial}
                            animate={sideIconAnimation}
                            className="nav_bar alternative_nav_bar"
                        >
                            <Button
                                onClick={() => selectNextOrPrevQuestion('prev')}
                                className={`previous ${currentQuestionIndex === 0 && 'disable'}`}
                                disabled={currentQuestionIndex === 0}
                            >
                                <KeyboardArrowLeftIcon className={`icon`} />
                            </Button>
                            <p className="slideCount">{`${currentQuestionIndex + 1} / ${pageTotal}`}</p>
                            <Button
                                onClick={() => selectNextOrPrevQuestion('next')}
                                className={`forward ${currentQuestionIndex >= pageTotal - 1 && 'disable'}`}
                                disabled={currentQuestionIndex >= pageTotal - 1}
                            >
                                <KeyboardArrowRightIcon className={`icon`} />
                            </Button>
                        </motion.div>
                    </div>
                </div>

                <div className="use_Qa_group alternate_use_Qa_group">
                    <div className="btn_group">
                        <motion.div
                            initial={aiQuestionAnimationConfig.animationConstants.allBtnInitial}
                            animate={btnOneAnimation}
                            className="btnContainer"
                        >
                            <CommonButton
                                isLoading={false}
                                type="button"
                                startIcon={<RemoveRedEyeOutlinedIcon />}
                                className={`use_QA_btn check_answer ${currentQuestion?.showAnswer && 'notClickable'}`}
                                variant="contained"
                                clickFunction={() => {
                                    if (!currentQuestion.showAnswer) setIsShowingAnswer(true);
                                }}
                                text={t(`lang_ai_quiz.question_flow.check_answer`)}
                            />
                        </motion.div>

                        <motion.div
                            initial={aiQuestionAnimationConfig.animationConstants.oneBtnInitial}
                            animate={btnTwoAnimation}
                            className="btnContainer"
                        >
                            <CommonButton
                                isLoading={isSaveSlideAnimating}
                                type="button"
                                className={`use_QA_btn saveSlide ${isSaveSlideAnimationPlayed && 'saved'} ${
                                    isSaveSlideAnimating && 'animating'
                                }`}
                                variant="contained"
                                startIcon={insertAsSlideBtnText === 'Saved' ? <DoneIcon /> : <SaveAltIcon />}
                                clickFunction={() => {
                                    localService.updateToolbarActionsFromWebview(
                                        UpdateToolbarActionsType.saveAiQuestionSlide,
                                    );
                                    setIsSaveSlideAnimating(true);
                                    saveQuestionAsSlide(currentQuestion);
                                    setTimeout(() => {
                                        setIsSaveSlideAnimating(false);
                                        setIsSaveSlideAnimationPlayed(true);
                                    }, 1000);
                                }}
                                text={insertAsSlideBtnText}
                            />
                        </motion.div>

                        <motion.div
                            initial={aiQuestionAnimationConfig.animationConstants.saveBtnInitial}
                            animate={btnThreeAnimation}
                            className="btnContainer"
                        >
                            <CommonButton
                                isLoading={false}
                                type="button"
                                startIcon={<AutorenewOutlinedIcon />}
                                className="use_QA_btn generateQuestionBtn"
                                variant="contained"
                                clickFunction={() => {
                                    localService.updateToolbarActionsFromWebview(
                                        UpdateToolbarActionsType.generateAiQuestion,
                                    );
                                    localService.incrementCurrentDayAiConsumption(user.email);
                                    generateQuestionHandler();
                                }}
                                text={t(`lang_ai_quiz.question_flow.generate_another`)}
                            />
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QuestionFlow;
