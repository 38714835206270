import { useTranslation } from 'react-i18next';

import styles from './TemplateLibraryHeader.module.scss';
import { Button } from '@material-ui/core';
import webviewMessenger from '../../../../services/webviewMessenger';
import { TemplateCategoryType } from '../../categories';

type TemplateLibraryHeaderProps = {
    currentMenu: TemplateCategoryType;
};

export default function TemplateLibraryHeader({ currentMenu }: TemplateLibraryHeaderProps) {
    const { t } = useTranslation();

    return (
        <div className={`${styles.heading}`}>
            <p className={`${styles.title}`}>
                {currentMenu.name === 'unpublished'
                    ? 'Unpublished'
                    : `${t('lang_template_library.quiz_ideas_on')}: ${t(`lang_activity.${currentMenu.label}`)}`}
            </p>
            {currentMenu.name === 'unpublished' && (
                <Button variant="outlined" onClick={() => webviewMessenger.sendSimpleMessage('uploadSlidesAsTemplate')}>
                    Upload selected slides
                </Button>
            )}
        </div>
    );
}
