import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FitToViewport } from 'react-fit-to-viewport';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
// import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import TranslateIcon from '@mui/icons-material/Translate';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Button from '@mui/material/Button';

import './Setting.scss';

import { userActions } from '../../actions/user.actions';
import { getValidUserFromStore } from '../../helpers/storeHelpers';
import { canUserReferClassPoint, isUserOnTrialOrPro } from '../../helpers/userhelper';
import apiUser from '../../services/apiUser';

import { HostEventTypes } from '../../constants/host-event-types';
import { UserActionTypes } from '../../constants/user-action-types';
import { ViewportSavedClasses } from '../../constants/viewport-constants';

import { Gamification, SlideShow, Subscription } from '../../Icon';

import About from './About/About';
import Slideshow from './Slideshow/Slideshow';
// import Profile from './Profile/Profile';
import Subscriptions from './subscriptions/Subscriptions';
import LanguageSetting from './LanguageSetting/LanguageSetting';
import Levels from './CustomizeLevels/Levels';
import EmptyModal from '../EmptyModal/EmptyModal';
import ShareClassPoint from './ShareClassPoint/ShareClassPoint';
import ProfileSummary from './Profile/ProfileSummary';
import { isCpVerAtLeast } from '../../helpers/utils';

interface TabPanelProps {
    children?: React.ReactNode;
    name: string;
    currentTabIndex: number;
}

function a11yProps(name: string) {
    return {
        id: `vertical-tab-${name}`,
        'aria-controls': `vertical-tabpanel-${name}`,
    };
}

const Setting = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const user = getValidUserFromStore('Setting');
    const isTrialOrPro = isUserOnTrialOrPro(user);
    const canUserRefer = canUserReferClassPoint(user);

    const { urlTabName }: any = useParams();

    const HIDE_SUBSCRIPTION_VER = '2.6.0';
    const tabs = isCpVerAtLeast(HIDE_SUBSCRIPTION_VER)
        ? canUserRefer
            ? ['slideshow', 'gamification', 'language', 'refer', 'about']
            : ['slideshow', 'gamification', 'language', 'about']
        : canUserRefer
        ? ['subscription', 'slideshow', 'gamification', 'language', 'refer', 'about']
        : ['subscription', 'slideshow', 'gamification', 'language', 'about'];

    const landingTab = urlTabName ? tabs.indexOf(urlTabName) : 0;
    const [currentTabIndex, setCurrentTabIndex] = useState(landingTab);

    function TabPanel(props: TabPanelProps) {
        const { children, currentTabIndex, name, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={tabs[currentTabIndex] !== name}
                id={`vertical-tabpanel-${name}`}
                aria-labelledby={`vertical-tab-${name}`}
                {...other}
                className="tabbody"
            >
                {tabs[currentTabIndex] === name && <Box>{children}</Box>}
            </div>
        );
    }

    const [showEmptyModal, setShowEmptyModal] = useState(false);

    const handleChange = (event: React.SyntheticEvent, tabIndex: number) => {
        setCurrentTabIndex(tabIndex);
    };

    const signOut = () => {
        if (!user) return;
        dispatch(userActions.unloadUser(UserActionTypes.USER_MANUALLY_SIGNED_OUT, HostEventTypes.SIGNED_OUT));
        apiUser.signOut(user.email);
        navigate('/loading');
    };

    useEffect(() => {
        setShowEmptyModal(true);
        setTimeout(() => {
            setShowEmptyModal(false);
        }, 1);
        (window as any).fbq('trackCustom', 'OpenSettings');
    }, []);

    return (
        <FitToViewport
            className="viewport"
            width={ViewportSavedClasses.width}
            height={ViewportSavedClasses.height}
            minZoom={ViewportSavedClasses.minZoom}
            maxZoom={ViewportSavedClasses.maxZoom}
        >
            {showEmptyModal && <EmptyModal viewportConfig={ViewportSavedClasses} />}

            <Box className="setting_body">
                <div className={`settingsContainer`}>
                    <ProfileSummary />

                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={currentTabIndex}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        sx={{ borderRight: 1, borderColor: 'divider' }}
                    >
                        {!isCpVerAtLeast(HIDE_SUBSCRIPTION_VER) && (
                            <Tab
                                icon={<Subscription />}
                                label={t('lang_settings.menu_btn_subscriptions')}
                                {...a11yProps('subscription')}
                            />
                        )}
                        {/* <Tab
                            icon={<PersonOutlinedIcon />}
                            label={t('lang_settings.menu_btn_profile')}
                            {...a11yProps('profile')}
                        /> */}
                        <Tab
                            icon={<SlideShow />}
                            label={t('lang_settings.menu_btn_slideshow')}
                            {...a11yProps('slideshow')}
                        />
                        <Tab
                            icon={<Gamification />}
                            label={
                                <>
                                    {t('lang_customize_levels.heading_customize_levels')}
                                    {!isTrialOrPro && <span className="not_pro">PRO</span>}
                                </>
                            }
                            {...a11yProps('gamification')}
                        />
                        <Tab
                            icon={<TranslateIcon />}
                            label={t('lang_settings.menu_btn_language')}
                            {...a11yProps('language')}
                        />
                        {canUserRefer && (
                            <Tab
                                icon={<FavoriteBorderIcon />}
                                // icon={<ShareIcon />}
                                label={t('lang_share_classpoint.txt_classpoint_title')}
                                {...a11yProps('refer')}
                            />
                        )}
                        <Tab
                            icon={<InfoOutlinedIcon />}
                            label={t('lang_settings.menu_btn_about')}
                            {...a11yProps('about')}
                        />
                    </Tabs>

                    <Button onClick={() => signOut()} className={`logOutBtn`} color="error">
                        {t('lang_common.txt_sign_out')}
                    </Button>
                </div>

                <TabPanel currentTabIndex={currentTabIndex} name="subscription">
                    <Subscriptions />
                </TabPanel>
                {/* <TabPanel currentTabIndex={currentTabIndex} name="profile">
                    <Profile />
                </TabPanel> */}
                <TabPanel currentTabIndex={currentTabIndex} name="slideshow">
                    <Slideshow />
                </TabPanel>
                <TabPanel currentTabIndex={currentTabIndex} name="gamification">
                    <Levels />
                </TabPanel>
                <TabPanel currentTabIndex={currentTabIndex} name="language">
                    <LanguageSetting />
                </TabPanel>
                {canUserRefer && (
                    <TabPanel currentTabIndex={currentTabIndex} name="refer">
                        <ShareClassPoint />
                    </TabPanel>
                )}
                <TabPanel currentTabIndex={currentTabIndex} name="about">
                    <About />
                </TabPanel>
            </Box>
        </FitToViewport>
    );
};

export default Setting;
