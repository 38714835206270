import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import { Typography } from '@mui/material';

import { getActivityFromStore } from '../../../helpers/storeHelpers';

import { utilConstants } from '../../../constants/utils.constants';

function QuickPollChoiceItem({ styles, qpItem, maxCount, handleOpen, qpType, qpChoicesCount }: any) {
    const activity = getActivityFromStore();
    const [initialWidth, setInitialWidth] = useState('2px');
    const { t } = useTranslation();

    const findDimension = (value?: number) => {
        let rate = 650 / maxCount;
        let height = 0;
        let imgSize = 0;
        let flexGap = 24;

        switch (qpType) {
            case 'qpTrueFalse': {
                height = 100;
                imgSize = 60;
                break;
            }
            case 'qpYesNoUnsure': {
                height = 80;
                imgSize = 56;
                break;
            }
            case 'qpRating': {
                height = 56;
                imgSize = 48;
                break;
            }
            case 'qpMultiple': {
                height = 48;
                flexGap = 12;
                rate = 750 / maxCount;
                break;
            }
        }

        switch (qpChoicesCount) {
            case 2: {
                height = 100;
                break;
            }
            case 3: {
                height = 80;
                break;
            }
            case 4: {
                height = 68;
                break;
            }
            case 5: {
                height = 56;
                break;
            }
            case 6: {
                height = 48;
                break;
            }
            case 7: {
                height = 42;
                break;
            }
            case 8: {
                height = 40;
                break;
            }
        }

        if (typeof value === 'undefined') {
            return [`${height}px`, `${imgSize}px`, `${flexGap}px`];
        } else if (maxCount !== 0) {
            let width = rate * value;
            return [`${width}px`];
        } else {
            return [`0px`];
        }
    };

    useEffect(() => {
        if (qpItem.count > 0) setInitialWidth(findDimension(qpItem.count)[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qpItem.count]);

    return (
        <li className={styles.wrong}>
            <div className={styles.quickpool_img}>
                <div className={styles.img_icon}>{qpItem.iconUrl && <img src={qpItem.iconUrl} alt="msgicon" />}</div>
                <span className={styles.typeA}>
                    <Typography variant="body1">{t(`lang_activity.qpchoice_${qpItem.id}`)}</Typography>
                </span>
            </div>

            <motion.div
                initial={
                    activity.activityMode === utilConstants.ACTIVITY_MODE.VIEW_ACTIVITY_IN_SLIDESHOW
                        ? false
                        : {
                              width: initialWidth,
                          }
                }
                transition={{
                    type: 'spring',
                    stiffness: 1000,
                    mass: 2.8,
                    damping: 40,
                }}
                animate={{
                    width: findDimension(qpItem.count)[0],
                }}
                className={
                    qpItem.count === 0 ? `${styles.bargraph}  ${styles.quickpoll_noresponse}` : `${styles.bargraph}`
                }
                style={{
                    height: findDimension()[0],
                    backgroundColor: qpItem.color,
                }}
                onClick={() => {
                    if (findDimension(qpItem.count)[0] !== '0px') handleOpen(qpItem.id);
                }}
            >
                {qpItem.count !== 0 && (
                    <span className={`${styles.chart_tooltip} rtl_chart_tooltip`}>{qpItem.count}</span>
                )}
            </motion.div>
        </li>
    );
}

export default QuickPollChoiceItem;
