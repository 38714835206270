import Masonry from 'react-responsive-masonry';

import { Box } from '@mui/material';
// import Masonry from '@mui/lab/Masonry';

import ActivityResponseInterface from '../../../interfaces/activity-response.interface';

import ShortAnswerSingleResponse from './ShortAnswerSingleResponse';

interface ShortAnswerContentInterface {
    selectedResponses: ActivityResponseInterface[];
    searchKeyword: string;
    isVoting: boolean;
    isVotingNamesHidden: boolean;
    onThumbClicked: any;
    sortSelectedResponses: (arg: ActivityResponseInterface[]) => ActivityResponseInterface[];
}

function ShortAnswerContent(props: ShortAnswerContentInterface) {
    return (
        <Box sx={{ width: 942, minHeight: 300 }}>
            {props.sortSelectedResponses(props.selectedResponses).length > 0 && (
                <Masonry columnsCount={4} gutter={'16px'} className="inner_short_answer shortans_300">
                    {props.sortSelectedResponses(props.selectedResponses).map((response, index) => (
                        <ShortAnswerSingleResponse
                            key={response.responseId}
                            response={response}
                            index={index}
                            onThumbClicked={props.onThumbClicked}
                            isVoting={props.isVoting}
                            searchKeyword={props.searchKeyword}
                        />
                    ))}
                </Masonry>
            )}
        </Box>
    );
}

export default ShortAnswerContent;
