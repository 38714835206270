import styles from './SideMenu.module.scss';

import { getValidUserFromStore } from '../../../../helpers/storeHelpers';
import { isSlidesTemplateManager } from '../../../../helpers/userhelper';

import { CATEGORIES, TemplateCategoryType } from '../../categories';

type SideMenuProps = {
    currentMenu: TemplateCategoryType;
    onMenuChange: (currentMenu: TemplateCategoryType) => void;
};

export default function SideMenu({ currentMenu, onMenuChange }: SideMenuProps) {
    const user = getValidUserFromStore('templateLibrary');

    return (
        <div className={`${styles.sideMenuContainer}`}>
            {CATEGORIES.map((category) => {
                if (category.name === 'unpublished' && !isSlidesTemplateManager(user.email)) return null;
                return (
                    <div
                        key={category.name}
                        onClick={() => onMenuChange(category)}
                        className={`${styles.imageContainer} ${category.name === currentMenu.name && styles.active}`}
                    >
                        <img src={category.icon} className={`${styles.image}`} alt={category.name} />
                    </div>
                );
            })}
        </div>
    );
}
