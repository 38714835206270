import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

function AddLevel({ onAdd }: any) {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div className="level_card add_btn" onClick={onAdd}>
                <div className="add_btn_icon">
                    <AddIcon />
                </div>
                <div className="add_btn_txt">
                    <Typography variant="body1">{t('lang_customize_levels.txt_add_level')}</Typography>
                </div>
            </div>
        </Fragment>
    );
}

export default AddLevel;
