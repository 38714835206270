import { Fragment } from 'react';
import { Avatar, Typography } from '@mui/material';

import CustomAvatar, { AvatarType } from '../../../components/Common/CustomAvatar';
import { utilConstants } from '../../../constants/utils.constants';
import { getValidUserFromStore } from '../../../helpers/storeHelpers';
import { calculateParticipantLevelAndBadge, getLevelsArray } from '../../../helpers/userhelper';
import LeaderboardParticipantInterface from '../../../interfaces/leaderboard-participant.interface';
import { SavedClassGroupInterface } from '../../../interfaces/savedClassGroup.interface';

type LeaderBoardTopPlaceProps = {
    leadersOverall: LeaderboardParticipantInterface[];
    savedClassGroups: SavedClassGroupInterface[];
};

const LeaderBoardTopPlace = ({ leadersOverall, savedClassGroups }: LeaderBoardTopPlaceProps) => {
    const user = getValidUserFromStore('LeaderBoardTopPlace');

    const firstGroup = leadersOverall.filter((leader) => leader.participantRank === 1);
    const secondGroup = leadersOverall.filter((leader) => leader.participantRank === 2);
    const thirdGroup = leadersOverall.filter((leader) => leader.participantRank === 3);

    const displayMultipleLeaders = (group: LeaderboardParticipantInterface[]): string => {
        switch (group.length) {
            case 1:
                return group[0]?.participantName;
            case 2:
                return `${group[0]?.participantName}, ${group[1]?.participantName}`;

            default:
                return `${group[0]?.participantName} + ${group.length - 1}`;
        }
    };

    return (
        <Fragment>
            <div className="leader_board_rank">
                <div className="leader_board_rank_sec">
                    <div className="leader_board_rank_barchart">
                        <ul className="leader_board_rank_chart ">
                            <li className={`second ${!secondGroup.length && 'no_place'}`}>
                                <span className="chart_tooltip">
                                    <div className={`crown_avater ${secondGroup.length === 2 && 'double'}`}>
                                        {secondGroup.length ? (
                                            <div className="images">
                                                {secondGroup.map((participant, index: number) => {
                                                    return secondGroup.length === 2 ? (
                                                        <CustomAvatar
                                                            key={index}
                                                            avatarName={participant.participantName}
                                                            avatarUrl={participant.participantAvatar}
                                                            type={AvatarType.PARTICIPANT}
                                                            savedClassGroups={savedClassGroups}
                                                            groupId={participant.groupId}
                                                        />
                                                    ) : index === 0 ? (
                                                        <CustomAvatar
                                                            key={index}
                                                            avatarName={participant.participantName}
                                                            avatarUrl={participant.participantAvatar}
                                                            type={AvatarType.PARTICIPANT}
                                                            savedClassGroups={savedClassGroups}
                                                            groupId={participant.groupId}
                                                        />
                                                    ) : (
                                                        <CustomAvatar
                                                            key={index}
                                                            avatarName={participant.participantName}
                                                            avatarUrl={participant.participantAvatar}
                                                            type={AvatarType.PARTICIPANT}
                                                            savedClassGroups={savedClassGroups}
                                                            groupId={participant.groupId}
                                                            className={index === 1 ? 'left_image' : 'right_image'}
                                                        />

                                                        // <Avatar
                                                        //     key={index}
                                                        //     alt={participant.participantName}
                                                        //     className={index === 1 ? 'left_image' : 'right_image'}
                                                        //     src={utilConstants.IMAGE_URLS.multipleWinnerAvatar}
                                                        // />
                                                    );
                                                })}
                                            </div>
                                        ) : (
                                            <Avatar
                                                className="noAvatar"
                                                alt="?"
                                                src={utilConstants.IMAGE_URLS.noAvatar}
                                            />
                                        )}
                                    </div>
                                    <Typography variant="body1">{displayMultipleLeaders(secondGroup)}</Typography>
                                    <div className="point_star_box">
                                        <img src={utilConstants.IMAGE_URLS.quizSummaryIconStar} alt="point_icon" />
                                        <Typography variant="body1">{secondGroup[0]?.participantSumPoints}</Typography>
                                    </div>
                                </span>
                                <div className="bar">
                                    <div className="level_display">
                                        <img
                                            src={
                                                calculateParticipantLevelAndBadge(
                                                    secondGroup[0]?.participantSumPoints,
                                                    getLevelsArray(user),
                                                ).badgeUrl
                                            }
                                            alt="level_icon"
                                        />
                                        <Typography variant="body1">
                                            Lv{' '}
                                            {
                                                calculateParticipantLevelAndBadge(
                                                    secondGroup[0]?.participantSumPoints,
                                                    getLevelsArray(user),
                                                ).level
                                            }
                                        </Typography>
                                    </div>
                                </div>
                            </li>
                            <li className={`first ${!firstGroup.length && 'no_place'}`}>
                                <span className="chart_tooltip">
                                    <div className={`crown_avater ${firstGroup.length === 2 && 'double'}`}>
                                        {firstGroup.length ? (
                                            <div className="images">
                                                {firstGroup.map((participant, index: number) => {
                                                    return firstGroup.length === 2 ? (
                                                        <CustomAvatar
                                                            key={index}
                                                            avatarName={participant.participantName}
                                                            avatarUrl={participant.participantAvatar}
                                                            type={AvatarType.PARTICIPANT}
                                                            savedClassGroups={savedClassGroups}
                                                            groupId={participant.groupId}
                                                        />
                                                    ) : index === 0 ? (
                                                        <CustomAvatar
                                                            key={index}
                                                            avatarName={participant.participantName}
                                                            avatarUrl={participant.participantAvatar}
                                                            type={AvatarType.PARTICIPANT}
                                                            savedClassGroups={savedClassGroups}
                                                            groupId={participant.groupId}
                                                        />
                                                    ) : (
                                                        <CustomAvatar
                                                            key={index}
                                                            avatarName={participant.participantName}
                                                            avatarUrl={participant.participantAvatar}
                                                            type={AvatarType.PARTICIPANT}
                                                            savedClassGroups={savedClassGroups}
                                                            groupId={participant.groupId}
                                                            className={index === 1 ? 'left_image' : 'right_image'}
                                                        />

                                                        // <Avatar
                                                        //     key={index}
                                                        //     alt={participant.participantName}
                                                        //     className={index === 1 ? 'left_image' : 'right_image'}
                                                        //     src={utilConstants.IMAGE_URLS.multipleWinnerAvatar}
                                                        // />
                                                    );
                                                })}
                                            </div>
                                        ) : (
                                            <Avatar
                                                className="noAvatar"
                                                alt="?"
                                                src={utilConstants.IMAGE_URLS.noAvatar}
                                            />
                                        )}
                                        <img src={utilConstants.IMAGE_URLS.crown} alt="crown" className="crown_img" />
                                    </div>
                                    <Typography variant="body1">{displayMultipleLeaders(firstGroup)}</Typography>
                                    <div className="point_star_box">
                                        <img src={utilConstants.IMAGE_URLS.quizSummaryIconStar} alt="point_icon" />
                                        <Typography variant="body1">{firstGroup[0]?.participantSumPoints}</Typography>
                                    </div>
                                </span>
                                <div className="bar">
                                    <div className="level_display">
                                        <img
                                            src={
                                                calculateParticipantLevelAndBadge(
                                                    firstGroup[0]?.participantSumPoints,
                                                    getLevelsArray(user),
                                                ).badgeUrl
                                            }
                                            alt="level_icon"
                                        />
                                        <Typography variant="body1">
                                            Lv{' '}
                                            {
                                                calculateParticipantLevelAndBadge(
                                                    firstGroup[0]?.participantSumPoints,
                                                    getLevelsArray(user),
                                                ).level
                                            }
                                        </Typography>
                                    </div>
                                </div>
                            </li>
                            <li className={`third ${!thirdGroup.length && 'no_place'}`}>
                                <span className="chart_tooltip">
                                    <div className={`crown_avater ${thirdGroup.length === 2 && 'double'}`}>
                                        {thirdGroup.length ? (
                                            <div className="images">
                                                {thirdGroup.map((participant, index: number) => {
                                                    return thirdGroup.length === 2 ? (
                                                        <CustomAvatar
                                                            key={index}
                                                            avatarName={participant.participantName}
                                                            avatarUrl={participant.participantAvatar}
                                                            type={AvatarType.PARTICIPANT}
                                                            savedClassGroups={savedClassGroups}
                                                            groupId={participant.groupId}
                                                        />
                                                    ) : index === 0 ? (
                                                        <CustomAvatar
                                                            key={index}
                                                            avatarName={participant.participantName}
                                                            avatarUrl={participant.participantAvatar}
                                                            type={AvatarType.PARTICIPANT}
                                                            savedClassGroups={savedClassGroups}
                                                            groupId={participant.groupId}
                                                        />
                                                    ) : (
                                                        <CustomAvatar
                                                            key={index}
                                                            avatarName={participant.participantName}
                                                            avatarUrl={participant.participantAvatar}
                                                            type={AvatarType.PARTICIPANT}
                                                            savedClassGroups={savedClassGroups}
                                                            groupId={participant.groupId}
                                                            className={index === 1 ? 'left_image' : 'right_image'}
                                                        />

                                                        // <Avatar
                                                        //     key={index}
                                                        //     alt={participant.participantName}
                                                        //     className={index === 1 ? 'left_image' : 'right_image'}
                                                        //     src={utilConstants.IMAGE_URLS.multipleWinnerAvatar}
                                                        // />
                                                    );
                                                })}
                                            </div>
                                        ) : (
                                            <Avatar
                                                className="noAvatar"
                                                alt="?"
                                                src={utilConstants.IMAGE_URLS.noAvatar}
                                            />
                                        )}
                                    </div>
                                    <Typography variant="body1">{displayMultipleLeaders(thirdGroup)}</Typography>
                                    <div className="point_star_box">
                                        <img src={utilConstants.IMAGE_URLS.quizSummaryIconStar} alt="point_icon" />
                                        <Typography variant="body1">{thirdGroup[0]?.participantSumPoints}</Typography>
                                    </div>
                                </span>
                                <div className="bar">
                                    <div className="level_display">
                                        <img
                                            src={
                                                calculateParticipantLevelAndBadge(
                                                    thirdGroup[0]?.participantSumPoints,
                                                    getLevelsArray(user),
                                                ).badgeUrl
                                            }
                                            alt="level_icon"
                                        />
                                        <Typography variant="body1">
                                            Lv{' '}
                                            {
                                                calculateParticipantLevelAndBadge(
                                                    thirdGroup[0]?.participantSumPoints,
                                                    getLevelsArray(user),
                                                ).level
                                            }
                                        </Typography>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default LeaderBoardTopPlace;
