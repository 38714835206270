import { Box, Modal, Typography } from '@mui/material';
import { Fragment } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from 'react-i18next';

const ImageFileTypeWrongAlert = ({ isAlertOpen, onCloseAlert }: any) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <Modal
                open={isAlertOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="mymodal "
                onClose={onCloseAlert}
            >
                <Box className="modal_Box error_file">
                    <div className="product_key_box package_info">
                        <div className="product_content">
                            <div className="media">
                                <div className="media_icon">
                                    <CancelIcon className="danger" />
                                </div>
                                <div className="media_body">
                                    <Typography variant="body1">{t('lang_common.err_select_image_only')}</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </Fragment>
    );
};

export default ImageFileTypeWrongAlert;
