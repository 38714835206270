import { Link, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Fragment, useEffect } from 'react';
import { utilConstants } from '../../../constants/utils.constants';
import {
    FacebookRoundedIcon,
    Instragram,
    LinkedInIcon,
    PinterestIcon,
    TwitterIcon,
    YouTubeIcon,
} from '../../../SocialIcon';
import webviewMessenger from '../../../services/webviewMessenger';
import { localService } from '../../../services/localStorageService';
import { getCpVer } from '../../../helpers/utils';

const About = () => {
    const { t } = useTranslation();

    const newVersion = localService.getNewVersionDownloaded();

    useEffect(() => {
        webviewMessenger.sendUsageLog(`Navigated to Settings -> About`);
    }, []);

    return (
        <Fragment>
            <div className="body_head">
                <Typography variant="h2">{t('lang_about.title')}</Typography>
            </div>
            <div className="classpoint_about">
                <div className="classpoint_inner">
                    <div className="media">
                        <div className="update_img">
                            <img src={utilConstants.IMAGE_URLS.brand_logo} alt="brandlogo" />
                        </div>
                        <div className="media_body">
                            <div className="update_content">
                                <Typography variant="h2">ClassPoint</Typography>
                                <Typography variant="h4" display="block" className="">
                                    {t('lang_about.txt_version')} {getCpVer()}
                                    {'.0'}
                                </Typography>
                                {newVersion && (
                                    <Typography variant="h5" display="block" className="restart">
                                        {t('lang_about.will_update_after_restart', { version: newVersion })}
                                    </Typography>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="about_info">
                        <Typography variant="body1">{t('lang_about.txt_about')}</Typography>
                    </div>
                    <div className="social_icon">
                        <ul>
                            <motion.li whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>
                                <Link
                                    onClick={() =>
                                        webviewMessenger.sendOpenBrowser('https://www.facebook.com/classpoint.io')
                                    }
                                >
                                    <FacebookRoundedIcon />
                                </Link>
                            </motion.li>
                            <motion.li whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>
                                <Link
                                    onClick={() =>
                                        webviewMessenger.sendOpenBrowser('https://www.youtube.com/c/ClassPoint')
                                    }
                                >
                                    <YouTubeIcon />
                                </Link>
                            </motion.li>
                            <motion.li whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>
                                <Link
                                    onClick={() =>
                                        webviewMessenger.sendOpenBrowser('https://www.instagram.com/classpoint.io/')
                                    }
                                >
                                    <Instragram />
                                </Link>
                            </motion.li>
                            <motion.li whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>
                                <Link
                                    onClick={() =>
                                        webviewMessenger.sendOpenBrowser('https://www.linkedin.com/company/inknoe/')
                                    }
                                >
                                    <LinkedInIcon />
                                </Link>
                            </motion.li>
                            <motion.li whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>
                                <Link
                                    onClick={() =>
                                        webviewMessenger.sendOpenBrowser('https://www.pinterest.com/InknoeClassPoint/')
                                    }
                                >
                                    <PinterestIcon />
                                </Link>
                            </motion.li>
                            <motion.li whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>
                                <Link
                                    onClick={() => webviewMessenger.sendOpenBrowser('https://twitter.com/classpointio')}
                                >
                                    <TwitterIcon />
                                </Link>
                            </motion.li>
                        </ul>
                    </div>
                </div>
                <div className="footer_copyright">
                    <Typography variant="body1">
                        {t('lang_about.txt_copyright', { year: new Date().getFullYear() })}
                    </Typography>
                </div>
            </div>
        </Fragment>
    );
};

export default About;
