import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FitToViewport } from 'react-fit-to-viewport';

import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined';

import styles from './ContentModalView.module.scss';

import { userActions } from '../../../../actions/user.actions';
import { getValidUserFromStore } from '../../../../helpers/storeHelpers';
import { isSlidesTemplateManager } from '../../../../helpers/userhelper';
import apiTemplateLibrary from '../../../../services/apiTemplateLibrary';

import { TemplateItemInterface } from '../../../../interfaces/templateLibrary.interface';
import { ViewportSavedClasses } from '../../../../constants/viewport-constants';
import { utilConstants } from '../../../../constants/utils.constants';

import CommonButton from '../../../../components/Common/CommonButton';
import ContentEditForm from './contentEditForm/ContentEditForm';

type ContentModalViewProps = {
    modalItem: TemplateItemInterface;
    shouldShowPro: boolean;
    onInsertTemplateItem: (item: TemplateItemInterface) => void;
    onRefreshItems: () => void;
    onCloseModal: () => void;
};

const ContentModalView = ({
    modalItem,
    shouldShowPro,
    onInsertTemplateItem,
    onRefreshItems,
    onCloseModal,
}: ContentModalViewProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = getValidUserFromStore('templateLibrary');

    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isUnpublishing, setIsUnpublishing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const nextAndPrevQuestionHandler = (type: 'prev' | 'next') => {
        if (modalItem.imageUrls.length < 2) return;
        if (type === 'prev') {
            return setCurrentSlideIndex(currentSlideIndex === 0 ? modalItem.imageUrls.length - 1 : (prev) => prev - 1);
        }
        if (type === 'next') {
            return setCurrentSlideIndex(currentSlideIndex === modalItem.imageUrls.length - 1 ? 0 : (prev) => prev + 1);
        }
    };

    const unpublishTemplateHandler = async () => {
        setIsUnpublishing(true);

        const unpublishTemplate = await apiTemplateLibrary.publishUnpublishTemplate(modalItem._id, false);
        setIsUnpublishing(false);
        if (!unpublishTemplate) return dispatch(userActions.showApiError());

        onCloseModal();
        onRefreshItems();
    };

    const deleteTemplateHandler = async () => {
        setIsDeleting(true);

        const deleteTemplate = await apiTemplateLibrary.deleteTemplate(modalItem._id);
        setIsDeleting(false);
        if (!deleteTemplate) return dispatch(userActions.showApiError());

        onCloseModal();
        onRefreshItems();
    };

    return (
        <Modal open={true} onClose={onCloseModal} className={styles.templateLibraryContentModal}>
            <>
                <FitToViewport
                    className={`modal_viewport ${styles.viewport}`}
                    width={ViewportSavedClasses.width}
                    height={ViewportSavedClasses.height}
                    minZoom={ViewportSavedClasses.minZoom}
                    maxZoom={ViewportSavedClasses.maxZoom}
                >
                    <div className={styles.closeModal} onClick={onCloseModal}></div>

                    {isEditMode ? (
                        <div className={`${styles.templateEditForm}`}>
                            <ContentEditForm
                                modalItem={modalItem}
                                onRefreshItems={onRefreshItems}
                                onCloseModal={onCloseModal}
                            />
                        </div>
                    ) : (
                        <>
                            <div className={styles.contentModalViewImageContainer}>
                                <img
                                    src={
                                        modalItem.imageUrls[currentSlideIndex] ||
                                        utilConstants.IMAGE_URLS.UPLOAD_DOCUMENT
                                    }
                                    className={`${styles.image}`}
                                    alt="ClassPoint Templates"
                                />

                                {shouldShowPro && <p className={`${styles.proIcon}`}>PRO</p>}
                            </div>

                            {modalItem.imageUrls.length > 1 && (
                                <>
                                    <IconButton
                                        onClick={() => nextAndPrevQuestionHandler('prev')}
                                        title="button"
                                        className={`${styles.prevBtn}`}
                                    >
                                        <svg
                                            className={styles.chevron}
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 306 306"
                                        >
                                            <polygon points="94.35,0 58.65,35.7 175.95,153 58.65,270.3 94.35,306 247.35,153 " />
                                        </svg>
                                    </IconButton>
                                    <IconButton
                                        onClick={() => nextAndPrevQuestionHandler('next')}
                                        title="button"
                                        className={`${styles.nextBtn}`}
                                    >
                                        <svg
                                            className={styles.chevron}
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 306 306"
                                        >
                                            <polygon points="94.35,0 58.65,35.7 175.95,153 58.65,270.3 94.35,306 247.35,153 " />
                                        </svg>
                                    </IconButton>
                                    <p className={`${styles.currentSlideIndex}`}>
                                        {currentSlideIndex + 1} / {modalItem.imageUrls.length}
                                    </p>
                                </>
                            )}

                            <div className={`${styles.btnContainer}`}>
                                {modalItem.isPublished && (
                                    <CommonButton
                                        text={t('lang_template_library.btn_insert_template')}
                                        clickFunction={() => onInsertTemplateItem(modalItem)}
                                        startIcon={<AddIcon />}
                                        className={`${styles.insertBtn}`}
                                    />
                                )}
                                {isSlidesTemplateManager(user.email) && (
                                    <>
                                        <CommonButton
                                            text="Edit"
                                            clickFunction={() => setIsEditMode(true)}
                                            startIcon={<EditOutlinedIcon />}
                                            className={`${styles.editBtn}`}
                                        />
                                        {modalItem.isPublished && (
                                            <CommonButton
                                                text="Unpublish"
                                                isLoading={isUnpublishing}
                                                clickFunction={() => unpublishTemplateHandler()}
                                                startIcon={<UnpublishedOutlinedIcon />}
                                                className={`${styles.unpublishBtn}`}
                                            />
                                        )}
                                        {!modalItem.isPublished && (
                                            <CommonButton
                                                text="Delete"
                                                clickFunction={() => deleteTemplateHandler()}
                                                isLoading={isDeleting}
                                                type="button"
                                                className={`${styles.deleteBtn}`}
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </FitToViewport>
            </>
        </Modal>
    );
};

export default ContentModalView;
