import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getActivityFromStore } from '../../../helpers/storeHelpers';
import { activityActions } from '../../../actions/activity.action';

import { utilConstants } from '../../../constants/utils.constants';
import { AlertTypes } from '../../../constants/alert-types-enum';

import CommonButton from '../../../components/Common/CommonButton';
import ActivityFooterStartActivity from './ActivityFooterStartActivity';
import ActivityFooterViewInEditMode from './ActivityFooterViewInEditMode';
import ActivityFooterViewInSlideshow from './ActivityFooterViewInSlideshow';

import { SHARE_OUTLINE } from '../../../Icon';

const ActivityFooter = ({
    selectedResponsesCount,
    closeSubmission,
    handleCloseWindow,
    onRestartActivity,
    onGivePointsToAll,
    onToggleCorrectAnswer,
    onDeleteResponses,
    onToggleShowActivityResponses,
    onToggleActivityMusic,
    startVoting,
    openStartVoting,
    setIsGivePointsToAll,
    givePointsConfettiRef,
    onAdjustActivityMusicVolume,
    onShuffleActivityMusic,
    setAlertType,
}: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const activity = getActivityFromStore();
    const hasResponses = activity.activityResponses?.length > 0;
    const { t } = useTranslation();

    const handleRunAnotherPoll = () => {
        dispatch(activityActions.endActivity(true, true));
        navigate('/qpselect');
    };

    switch (activity.activityMode) {
        case utilConstants.ACTIVITY_MODE.START_ACTIVITY:
            return (
                <ActivityFooterStartActivity
                    closeSubmission={closeSubmission}
                    onToggleCorrectAnswer={onToggleCorrectAnswer}
                    onToggleShowActivityResponses={onToggleShowActivityResponses}
                    onToggleActivityMusic={onToggleActivityMusic}
                    onAdjustActivityMusicVolume={onAdjustActivityMusicVolume}
                    onShuffleActivityMusic={onShuffleActivityMusic}
                />
            );

        case utilConstants.ACTIVITY_MODE.VIEW_ACTIVITY_IN_EDIT:
            return (
                <>
                    {hasResponses && activity.activityProps?.activityId.startsWith('sa') && (
                        <div className="insights_button">
                            <CommonButton
                                isLoading={false}
                                variant="contained"
                                className="default_btn"
                                type="button"
                                color="primary"
                                clickFunction={() => setAlertType(AlertTypes.ASK_CP_AI)}
                                text={
                                    <>
                                        <img
                                            src={utilConstants.IMAGE_URLS.ACTIVITY_SHORT_ANSWER_AI}
                                            alt="insights"
                                            className="insightsImage"
                                        />
                                        {t('lang_activity.short_answer_insights')}
                                    </>
                                }
                            />
                        </div>
                    )}
                    {hasResponses && (
                        <div className="share_button">
                            <CommonButton
                                isLoading={false}
                                variant="contained"
                                className="default_btn"
                                type="button"
                                color="primary"
                                startIcon={<SHARE_OUTLINE />}
                                clickFunction={() => setAlertType(AlertTypes.SHARE_ACTIVITY)}
                                text={t('share_activity.btn_share')}
                            />
                        </div>
                    )}
                    <ActivityFooterViewInEditMode
                        selectedResponsesCount={selectedResponsesCount}
                        onDeleteResponses={onDeleteResponses}
                        onGivePointsToAll={onGivePointsToAll}
                        setIsGivePointsToAll={setIsGivePointsToAll}
                        givePointsConfettiRef={givePointsConfettiRef}
                        setAlertType={setAlertType}
                    />
                </>
            );

        case utilConstants.ACTIVITY_MODE.VIEW_ACTIVITY_IN_SLIDESHOW:
            return (
                <>
                    {hasResponses && activity.activityProps?.activityId.startsWith('sa') && (
                        <div className="insights_button">
                            <CommonButton
                                isLoading={false}
                                variant="contained"
                                className="default_btn"
                                type="button"
                                color="primary"
                                clickFunction={() => setAlertType(AlertTypes.ASK_CP_AI)}
                                text={
                                    <>
                                        <img
                                            src={utilConstants.IMAGE_URLS.ACTIVITY_SHORT_ANSWER_AI}
                                            alt="insights"
                                            className="insightsImage"
                                        />
                                        {t('lang_activity.short_answer_insights')}
                                    </>
                                }
                            />
                        </div>
                    )}
                    {hasResponses && !activity.activityProps?.activityId.startsWith('qp') && (
                        <div className="share_button">
                            <CommonButton
                                isLoading={false}
                                variant="contained"
                                className="default_btn"
                                type="button"
                                color="primary"
                                startIcon={<SHARE_OUTLINE />}
                                clickFunction={() => setAlertType(AlertTypes.SHARE_ACTIVITY)}
                                text={t('share_activity.btn_share')}
                            />
                        </div>
                    )}
                    {activity.activityProps?.activityType === utilConstants.ACTIVITY_TYPE.QUICK_POLL ? (
                        <div className="footer_middle">
                            <CommonButton
                                isLoading={false}
                                type="button"
                                className=" foot_btn_blue"
                                clickFunction={handleRunAnotherPoll}
                                text={t(`lang_activity.btn_run_another_poll`)}
                            />
                        </div>
                    ) : (
                        <ActivityFooterViewInSlideshow
                            selectedResponsesCount={selectedResponsesCount}
                            onRestartActivity={onRestartActivity}
                            onGivePointsToAll={onGivePointsToAll}
                            setIsGivePointsToAll={setIsGivePointsToAll}
                            givePointsConfettiRef={givePointsConfettiRef}
                            startVoting={startVoting}
                            openStartVoting={openStartVoting}
                            setAlertType={setAlertType}
                        />
                    )}
                </>
            );
        default:
            return null;
    }
};

export default ActivityFooter;
