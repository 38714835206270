export const SoundLibrary = {
    GIVE_POINT: '/assets/sound/award_star.mp3',
    DEDUCT_POINT: '/assets/sound/deduct_star.mp3',
    TIMER_TIMEUP_SOUNDS: [
        '/assets/sound/Door_bell.mp3',
        '/assets/sound/Pager.mp3',
        '/assets/sound/Tada.mp3',
        '/assets/sound/Fairy.mp3',
        '/assets/sound/Heaviness.mp3',
        '/assets/sound/Carnival.mp3',
    ],
    SPIN_WHEEL: '/assets/sound/wheel.mp3',
    CARD_FLIP: '/assets/sound/flip.mp3',
};
