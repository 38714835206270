export const FillInColorConstants = {
    colorArray: [
        '#EF476F',
        '#FFBA00',
        '#06D6A0',
        '#7D29C0',
        '#2AA4E3',
        '#FF871E',
    ],
};
