import firstmedal from '../assets/images/medal.svg';
import secondmedal from '../assets/images/medal2.svg';
import thirdmedal from '../assets/images/medal3.svg';

export const utilConstants = {
    IMAGE_URLS: {
        brand_logo: '/assets/logo/cpCircular.svg',
        cpCircular: '/assets/logo/cpCircular.svg',
        errorcross: '/assets/logo/errorcross.svg',
        cct_badge: '/assets/badge/CCTBadge.svg',
        cce_badge: '/assets/badge/CCEBadge.svg',
        csc_badge: '/assets/badge/CSCBadge.svg',

        error_blundary_image: '/assets/logo/ic_error-boundary.png',
        starIcon: '/assets/logo/iconStar.svg',
        starIconbig: '/assets/logo/iconStarbig.png',
        nameSortIcon: '/assets/logo/az.png',
        luckybox: './assets/logo/luckybox.png',
        starsgroup: './assets/logo/starsgroup.png',
        boxopen: './assets/logo/boxopen.png',
        lallipop: './assets/logo/lallipop.png',
        SingleiconStar: './assets/logo/SingleiconStar.png',
        ic_sagree: './assets/logo/ic_s-agree.png',
        ic_sdisagree: './assets/logo/ic_s-disagree.png',
        ic_agree: './assets/logo/ic_agree.png',
        ic_disagree: './assets/logo/ic_disagree.png',
        ic_neutral: './assets/logo/ic_neutral.png',
        ic_unsure: '/assets/logo/ic_unsure.png',
        ic_no: './assets/logo/ic_no.png',
        ic_yes: '/assets/logo/ic_yes.png',
        classfull: '/assets/logo/classfull.png',
        quotemark: '/assets/logo/quotemark.svg',
        whiteboardImg: '/assets/whiteboards/musicsheet.png',
        Slide1: '/assets/setting/slide.svg',
        ClassCode: '/assets/setting/classCode.svg',
        toolbar: '/assets/setting/toolbar.svg',
        imagesthum: './assets/logo/imagesthum.png',
        slidethum: './assets/logo/slidethum.png',
        whiteboardthum: './assets/logo/whiteboardthum.png',
        iconMedal: './assets/logo/iconMedal.png',
        thumbicon: './assets/logo/thumbicon.png',
        allowUser: '/assets/logo/allowUser.svg',
        disallowUser: '/assets/logo/disallowUser.svg',
        Thunder: '/assets/logo/Thunder.svg',
        Lightning: '/assets/logo/myClassCurrent.svg',
        happyemoji: '/assets/setting/happyemoji.svg',
        sademoji: '/assets/setting/sademoji.svg',
        quizSummaryIconStar: '/assets/logo/iconStar.svg',
        level1: '/assets/logo/Level-01-small.svg',
        crown: '/assets/logo/crown.svg',
        noAvatar: '/assets/leaderboard/noAvatar.svg',
        multipleWinnerAvatar: '/assets/leaderboard/multipleWinnerAvatar.svg',
        bookmark: './assets/browser/bookmark.svg',
        cpFavicon: './assets/browser/cpFavicon.svg',
        cameraIcon: './assets/myclass/camera.svg',
        bulbIcon: './assets/myclass/bulb.svg',
        imgBasicTotalStarRank: './assets/leaderboard/imgBasicTotalStarRank.png',
        SHARE_CP: '/assets/setting/ShareCP_bg.svg',
        POWERPOINT_ICON: '/assets/dashboard/powerpoint.svg',
        PASSWORD_VISIBLE: '/assets/auth/ic_reveal.svg',
        PASSWORD_HIDE: '/assets/auth/ic_hide.svg',

        WATCH_TUTORIAL_PLAY_ICON: '/assets/logo/playCircleFilled.svg',
        WATCH_TUTORIAL_PLAY_ICON_WHITE: '/assets/logo/playCircleFilledWhite.svg',
        UPLOAD_DOCUMENT: '/assets/importCsv/importDocument.svg',

        summary_go_pro: '/assets/trialFlow/summary_go_pro.webp',
        participant_count: '/assets/trialFlow/participant_count.webp',
        draggable_objects: '/assets/trialFlow/draggable_object.webp',
        accumulated_stars: '/assets/trialFlow/accumulated_stars.webp',
        total_leaderboard: '/assets/trialFlow/total_leaderboard.webp',
        pro_activity_fib: '/assets/trialFlow/pro_activity_fib.webp',
        pro_activity_ar: '/assets/trialFlow/pro_activity_ar.webp',
        pro_activity_vu: '/assets/trialFlow/pro_activity_vu.webp',
        quota_activity_button: '/assets/trialFlow/activity_button.webp',
        saved_class: '/assets/trialFlow/saved_class.webp',
        class_management: '/assets/trialFlow/class_management.webp',
        gamification: '/assets/trialFlow/gamification.webp',
        quiz_mode_pro: '/assets/trialFlow/quiz_mode_pro.webp',
        podium_onhover_mousedown: '/assets/myclass/podium-onhover-mousedown.svg',
        email_share_icon: '/assets/shareclass/email_icon.webp',

        TEMPLATE_LIBRARY_AUDIO_RECORD: '/assets/templateLibrary/audioRecord.png',
        TEMPLATE_LIBRARY_FILL_IN_THE_BLANKS: '/assets/templateLibrary/fillInTheBlanks.png',
        TEMPLATE_LIBRARY_IMAGE_UPLOAD: '/assets/templateLibrary/imageUpload.png',
        TEMPLATE_LIBRARY_MULTIPLE_CHOICE: '/assets/templateLibrary/multipleChoice.png',
        TEMPLATE_LIBRARY_SHORT_ANSWER: '/assets/templateLibrary/shortAnswer.png',
        TEMPLATE_LIBRARY_SLIDE_DRAWING: '/assets/templateLibrary/slideDrawing.png',
        TEMPLATE_LIBRARY_VIDEO_UPLOAD: '/assets/templateLibrary/videoUpload.png',
        TEMPLATE_LIBRARY_WORD_CLOUD: '/assets/templateLibrary/wordCloud.png',
        TEMPLATE_LIBRARY_DRAGGABLE_OBJECTS: '/assets/templateLibrary/draggableObjects.svg',
        TEMPLATE_LIBRARY_UNPUBLISHED: '/assets/ic_unsure.png',

        TEMPLATE_LIBRARY_PRO_MODAL: '/assets/templateLibrary/proModal.gif',

        ACTIVITY_SHORT_ANSWER_AI: '/assets/activity/ai.png',
    },

    CHATBOT: {
        AiICON: '/assets/chatBot/aiIcon.svg',
        FILL_BLANK: '/assets/chatBot/fillintheblanks.svg',
        MULTIPLE_CHOICE: '/assets/chatBot/multiplechoice.svg',
        SHORT_ANSWER: '/assets/chatBot/shortanswer.svg',
        BIRTHDAY_CONFETTI: '/assets/chatBot/birthdayconfetti.png',
        PYRAMID: '/assets/chatBot/pyramid.png',
    },

    SETTING: {
        FINISH: '/assets/setting/finish.webp',
        CROWN: '/assets/setting/crown.webp',
        DISCOUNT: '/assets/setting/discount.svg',
        SHOPEE: '/assets/setting/shopee.svg',
        PREMIUM_UI: '/assets/setting/premiumUi.svg',
        PREMIUM_CROWN: '/assets/setting/premiumCrown.png',
    },

    LOGIN: {
        Login_logo: '/assets/logo/classpoint_logo_mono-01.svg',
        SHAPE_2: '/assets/logo/Shape2.svg',
        SHAPE_ROUND: '/assets/logo/Shaperound.svg',
        SQUARE_1: '/assets/logo/square1.svg',
        SQUARE_2: '/assets/logo/square1.svg',
    },

    SOCIAL: {
        FACEBOOK: '/assets/social/iconFacebook.svg',
        GOOGLE: '/assets/social/Google__G__Logo.svg',
        MICROSOFT: '/assets/social/microsoft.svg',
    },

    SHARE: {
        EMAIL_BIG: '/assets/shareclass/emailBig.svg',
        LINK: '/assets/shareclass/link.png',
    },

    LEADER_BOARD_IMAGE_URLS: {
        firstmedal: firstmedal,
        secondmedal: secondmedal,
        thirdmedal: thirdmedal,
        ANIMATION_STAR: '/assets/leaderboard/star.svg',
        LEAEDER_BOARD_CUP: '/assets/myclass/leaderboard_cup.svg',
    },

    WELCOME_SCREEN: {
        CP_FULL_LOGO: '/assets/welcomeScreen/cpFullLogo.svg',
        TOOLBAR_NEW: '/assets/welcomeScreen/topToolbarNew.webp',
        TOOLBAR_OLD: '/assets/welcomeScreen/topToolbarOld.webp',
        TOP_WAVE: '/assets/welcomeScreen/topWave.svg',
        BOTTOM_WAVE: '/assets/welcomeScreen/bottomWave.svg',
        SLIDESHOW_ICON: '/assets/welcomeScreen/slideshowIcon.svg',
    },

    ADVANCED_FEATURE_URL: `${process.env.REACT_APP_CPWEBSITE}/pricing`,
    GAMIFICATION_PAGE_URL: `${process.env.REACT_APP_CPWEBSITE}/gamification-in-powerpoint`,

    LOADER_DELAY_MS: 2000,

    AVATAR_COLORS: ['#f6a404', '#0c63be', '#dc3545', '#1f9436', '#d7441c', '#800080', '#b40199'],
    MCQ_COLOR_ARRAY: ['#06d6a0', '#ef476f', '#2aa4e3', '#ffba00', '#b36cfd', '#ff7a00', '#049F76', '#F25240'],
    MCQ_COLOR_FADE_ARRAY: [
        '#06d6a01a',
        '#ef476f1a',
        '#2aa4e31a',
        '#ffba001a',
        '#b36cfd1a',
        '#ff7a001a',
        '#049F761a',
        '#F252401a',
    ],
    RANDOM_COLOR_ARRAY: ['#EF476F', '#FFBA00', '#06D6A0', '#7D29C0', '#2AA4E3', '#FF871E'],
    COLORS: ['#EF476F', '#FF7700', '#FFBA00', '#06D6A0', '#2AA4E3', '#7D29C0'],
    AUDIO_RECORD_COLORS: ['#EF476F', '#FFBA00', '#06D6A0', '#7D29C0', '#2AA4E3', '#FF871E'],
    SHORT_ANSWER_COLORS: ['#E6FDCD', '#FFD6D6', '#D3EDFF', '#FFF4BA', '#E7DAFF', '#FFE5CD', '#DCFDF4', '#FFDEF0'],
    PICK_NAME_COLORS: ['#EF476F', '#FFBA00', '#06D6A0', '#7D29C0', '#F25240', '#2AA4E3', '#FF871E', '#049F76'],

    MODAL_NAME: {
        CHANGE_PRODUCT: 'ChangeProduct',
        PRODUCT_KEY: 'ProductKey',
        UPDATE_KEY_CONFIRMATION: 'UpdateKeyConfirmation',
        REMOVE_KEY: 'RemoveKey',
        CONFIRM_REMOVE_KEY: 'ConfirmRemoveKey',
        EDIT_ACCOUNT: 'EditAccount',
        CHOOSE_BILLING: 'ChooseBilling',
        CONFIRM_BILLING: 'ConfirmBilling',
        BILLING_RESPONSE: 'BillingResponse',
        SUBSCRIPTION_CANCELLATION: 'SubscriptionCancellation',
        CONFIRM_CANCELLATION: 'ConfirmCancellation',
        CANCELLATION_RESPONSE: 'CancellationResponse',
        CONFIRM_KEY: 'ConfirmKey',
        CONFIRM_CHANGE_KEY: 'ConfirmChangeKey',
        CONGRATS: 'Congrats',
        REVOKE_CANCELLATION: 'RevokeCancellation',
        REVOKE_RESPONSE: 'RevokeResponse',
        MY_CLASS_SETTINGS_BACK_MAIN_ALERT: 'MyClassSettingBackMainAlert',
        MY_CLASS_SETTINGS_ERROR_ALERT: 'MyClassSettingErrorAlert',
        MY_CLASS_SETTINGS_CLASS_EXISTS_ALERT: 'MyClassSettingClassExistsAlert',
        WHITEBOARD_BASIC_USER_ALERT: 'WhiteboardBasicUserAlert',
        WHITEBOARD_PRO_USER_ERROR_ALERT: 'WhiteboardProUserErrorAlert',
    },

    PLAN_TYPE: {
        BASIC: 'Basic',
        STANDARD: 'Standard',
        ORGANIZATION: 'Organization',
        SUBSCRIPTION: 'Subscription',
        PREMIUM: 'Premium',
    },

    PLAN_CYCLE: {
        Monthly: 'Monthly',
        Quarterly: 'Quarterly',
        Annual: 'Annual',
    },

    SUBSCRIPTION_PAGE_TYPE: {
        Landing_Page: 'Landing_Page',
        Add_Key: 'Add_Key',
        Confirm_Key: 'Confirm_Key',
        Change_Key: 'Change_Key',
        Confirm_Change_Key: 'Confirm_Change_Key',
        Remove_Key: 'Remove_Key',
        Loading_Page: 'Loading_Page',
        Page_Acknologement: 'Page_Acknologement',
        Page_Alert: 'Page_Alert',
        Cancel_Subscription: 'Cancel_Subscription',
        Cancel_Subscription_Confirm: 'Cancel_Subscription_Confirm',
        Confirm_Revoke_plan: 'Confirm_Revoke_plan',
        Check_Eligibility: 'Check_Eligibility',
        Trial_Activated: 'Trial_Activated',
        Request_Trial_Success: 'Request_Trial_Success',
        Request_Trial_Failed: 'Request_Trial_Failed',
    },

    AI_QUIZ_PAGE_TYPE: {
        WELCOME: 'WELCOME',
        QUESTION: 'QUESTION',
    },

    SUBSCRIPTION_VARIABLES: {
        Extend_Trial_Gap_Duration: 60,
        Eligibility_Check_Delay: 2000,
        Start_Trial_Delay: 2000,
    },

    JOB_ROLES: [
        // 'educatorPrimary',
        // 'educatorSecondary',
        // 'educatorHigherEd',
        // 'schoolAdmin',
        'teacher',
        'trainer',
        'corporateProfessional',
        'student',
        'other',
    ],

    LEAD_SOURCES: [
        'onlineSearch',
        // 'schoolOrganization',
        // 'friendsColleagues',
        'organizationOrColleagues',
        'webinarTraining',
        'socialMedia',
        // 'other',
        // 'tiktok',
        // 'otherSocialMedia',
    ],

    ALL_SUBJECTS: ['Mathematics', 'Technology', 'Language', 'Business', 'Humanities', 'Arts', 'Sciences', 'Others'],

    ACTIVITY_TYPE: {
        MULTIPLE_CHOICE: 'Multiple Choice',
        WORD_CLOUD: 'Word Cloud',
        SHORT_ANSWER: 'Short Answer',
        SLIDE_DRAWING: 'Slide Drawing',
        IMAGE_UPLOAD: 'Image Upload',
        AUDIO_RECORD: 'Audio Record',
        VIDEO_UPLOAD: 'Video Upload',
        FILL_IN_THE_BLANKS: 'Fill in the Blanks',
        QUICK_POLL: 'Quick Poll',
        QNA: 'Qna',
    },

    ACTIVITY_MODE: {
        START_ACTIVITY: 'startActivity',
        VIEW_ACTIVITY_IN_SLIDESHOW: 'viewActivityInSlideshow',
        VIEW_ACTIVITY_IN_EDIT: 'viewActivityInEdit',
    },

    DEFAULT_LEVELS_ARRAY: [0, 5, 10, 20, 30, 40, 50, 60, 80, 100],

    MOVABLE_OBJECTS_VIEWS: {
        LandingPage: 'LandingPage',
        FolderList: 'FolderList',
        SingleFolderView: 'SingleFolderView',
        UploadInputFiles: 'UploadInputFiles',
    },

    TIME_LIMITS: {
        // Time in Seconds
        RESEND_VERIFICATION_CODE: 60,
    },

    REGEX: {
        // eslint-disable-next-line no-useless-escape
        EMAIL: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,12})+$/,
        PASSWORD: /^(?=.*[a-zA-Z])(?=.*[0-9])/,
    },

    DEFAULT_PARTICIPANT_COLOR: '#C9C5CA',
    GROUP_COLORS: [
        { color: '#ab0022', name: 'red' },
        { color: '#de1818', name: 'scarlet' },
        { color: '#ff5722', name: 'orange' },
        { color: '#ff9800', name: 'yellow' },
        { color: '#f3b600', name: 'gold' },
        { color: '#99bd3c', name: 'lime' },
        { color: '#4f9c2a', name: 'green' },
        { color: '#425d0e', name: 'olive' },
        { color: '#01919e', name: 'teal' },
        { color: '#3bbeb6', name: 'aqua' },
        { color: '#42d4f4', name: 'cyan' },
        { color: '#03a9f4', name: 'azure' },
        { color: '#0072c4', name: 'blue' },
        { color: '#023b9d', name: 'navy' },
        { color: '#673ab7', name: 'purple' },
        { color: '#9c27b0', name: 'violet' },
        { color: '#aa7aff', name: 'lavender' },
        { color: '#ff7ac0', name: 'pink' },
        { color: '#ff2899', name: 'magenta' },
        { color: '#d1055f', name: 'maroon' },
    ],
    LEADER_BOARD_RANK_IMAGE: {
        RANK_1: '/assets/leaderboard/groupRank_1.png',
        RANK_2: '/assets/leaderboard/groupRank_2.png',
        RANK_3: '/assets/leaderboard/groupRank_3.png',
    },
};

export const INPUT_LIMITS = {
    EMAIL_MAX: 60,
    FIRST_LAST_NAME_MAX: 15,
    ORGANIZATION_MIN: 2,
    ORGANIZATION_MAX: 100,
    PASSWORD_MIN: 6,
    PASSWORD_MAX: 20,
    PARTICIPANT_NAME_MAX: 25,
    MOBILE_MIN: 9,
    MOBILE_MAX: 11,
    MOBILE_COUNTRY_CODE_MAX: 4,
};

// export const CP_CONTENT_URL = 'https://classpoint.azureedge.net';
export const CP_CONTENT_URL = 'https://content.classpoint.io';

export const DEFAULT_COUNTRY_CODE = 'default';
export const DEFAULT_COUNTRY = 'default';

export const WEBSITE_URL = {
    CCE: 'https://www.classpoint.io/classpoint-certified-educator',
    CSC: 'https://www.classpoint.io/classpoint-school-coach',
    CCT: 'https://www.classpoint.io/classpoint-certified-trainer',
    PRICING: 'https://www.classpoint.io/pricing',
    AI: 'https://www.classpoint.io/classpoint-ai',
};

export const NO_RANK_DISPLAY = '-';
