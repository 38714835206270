import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';

const HelpdeskHead = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div className="helpdeskHead">
                <div className="helpdesk_left">
                    <Typography variant="h4">{t('lang_help_desk.heading')}</Typography>
                </div>
                <div className="helpdesk_right">
                    <AnnouncementOutlinedIcon />
                </div>
            </div>
        </Fragment>
    );
};

export default HelpdeskHead;
