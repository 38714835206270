import { sentryLogSignalrError } from './../services/sentryService';
import { LeaderboardActionTypes } from './../constants/leaderboard-action-types';
import { store } from '../helpers/store';
import { getValidUserFromStore } from '../helpers/storeHelpers';
import LeaderboardParticipantInterface from '../interfaces/leaderboard-participant.interface';
import { logger } from '../services/logger';
import { invokeHubWithRetry } from './class-session.action';

export const leaderBoardActions = {
    refreshLeadersCurrentClass,
    refreshLeadersOverall,
    signalrShowLeaderboard,
};

function refreshLeadersCurrentClass(participants: LeaderboardParticipantInterface[]) {
    return async (dispatch: (arg0: { type: string; payload: LeaderboardParticipantInterface[] }) => void) => {
        dispatch({
            type: LeaderboardActionTypes.REFRESH_LEADERS_CURRENT_CLASS,
            payload: participants,
        });
    };
}

function refreshLeadersOverall(participants: LeaderboardParticipantInterface[]) {
    return async (dispatch: (arg0: { type: string; payload: LeaderboardParticipantInterface[] }) => void) => {
        dispatch({
            type: LeaderboardActionTypes.REFRESH_LEADERS_OVERALL,
            payload: participants,
        });
    };
}

function signalrShowLeaderboard(leaderboard: LeaderboardParticipantInterface[]) {
    return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
        const user = getValidUserFromStore('signalrShowLeaderboard');
        const invokeHub = async () => {
            const connection = store.getState().connection;
            await connection.invoke('PresenterShowLeaderboard', { email: user.email }, JSON.stringify(leaderboard));
        };
        const invokeHubOnError = (error: any) => {
            logger.error(error);
            sentryLogSignalrError(error, 'PresenterShowLeaderboard', {
                email: user.email,
                leaderboard,
            });
        };
        await invokeHubWithRetry(invokeHub, invokeHubOnError);
    };
}
