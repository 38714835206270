import { getValidClassSessionFromStore } from '../helpers/storeHelpers';
import { PickNamesActionTypes } from '../constants/pick-names-action-types';
import { store } from '../helpers/store';
import PickNamesParticipantInterface from '../interfaces/pick-names-participant.interface';
import { generateNewPickNamesList } from '../helpers/pickNamesHelpers';
import { PickNamesCardThemes } from '../constants/pickNameFlipCardImageConstant';
import { utilConstants } from '../constants/utils.constants';

export const pickNamesActions = {
    startPickNames,
    updatePickNamesList,
    pickOneName,
    unPickAll,
};

function startPickNames(forceShuffle = false) {
    return async (dispatch: (arg0: { type: string }) => void) => {
        const classSession = getValidClassSessionFromStore('startPickNames');
        let pickNamesList: PickNamesParticipantInterface[] = store.getState().pickNamesList;

        let hasNewClassSessionStarted = false;
        if (pickNamesList.length > 0) {
            const pickNamesClassSessionId = pickNamesList[0].classSessionId;
            if (pickNamesClassSessionId !== classSession.classSessionId) {
                // console.log('classSessionId has changed, re-initialize pick names list');
                hasNewClassSessionStarted = true;
            }
        }

        if (pickNamesList.filter((p) => p.isPicked).length === 0 || forceShuffle || hasNewClassSessionStarted) {
            pickNamesList = generateNewPickNamesList(classSession.participantList);
            if (!forceShuffle) dispatch(initializePickNames(pickNamesList));
            else dispatch(resetPickNames(pickNamesList));
        }

        let pickNamesDict: any = {};
        for (let index = 0; index < pickNamesList.length; index++) {
            pickNamesDict[pickNamesList[index].participantId] = index;
        }
    };
}

function updatePickNamesList() {
    return async (dispatch: (arg0: { type: string }) => void) => {
        const classSession = getValidClassSessionFromStore('updatePickNamesList');
        const participantList = classSession.participantList;
        const pickNamesList: PickNamesParticipantInterface[] = store.getState().pickNamesList;

        const updatedPickNamesList: PickNamesParticipantInterface[] = [];

        for (let p of participantList) {
            const existingIndex = pickNamesList.findIndex((ep) => ep.participantId === p.participantId);
            if (existingIndex >= 0) {
                updatedPickNamesList.push({
                    classSessionId: classSession.classSessionId,
                    participantId: p.participantId,
                    participantName: p.participantName,
                    participantAvatar: p.participantAvatar,
                    participantSumPoints: p.participantPoints + p.participantTotalPoints,
                    isOnline: p.joined && !p.left ? true : false,
                    colorHex: pickNamesList[existingIndex].colorHex,
                    cardIndex: pickNamesList[existingIndex].cardIndex,
                    isPicked: pickNamesList[existingIndex].isPicked,
                    groupId: p.groupId,
                });
            } else {
                const randomColorHex =
                    utilConstants.PICK_NAME_COLORS[Math.floor(utilConstants.PICK_NAME_COLORS.length * Math.random())];
                let currentCardThemeIndex = 0;
                if (pickNamesList.length > 0) currentCardThemeIndex = pickNamesList[0].cardIndex[0];
                const currentTheme = PickNamesCardThemes[currentCardThemeIndex];
                const randomCardImageIndex = Math.floor(currentTheme.length * Math.random());
                updatedPickNamesList.push({
                    classSessionId: classSession.classSessionId,
                    participantId: p.participantId,
                    participantName: p.participantName,
                    participantAvatar: p.participantAvatar,
                    participantSumPoints: p.participantPoints + p.participantTotalPoints,
                    isOnline: p.joined && !p.left ? true : false,
                    colorHex: randomColorHex,
                    cardIndex: [currentCardThemeIndex, randomCardImageIndex],
                    isPicked: false,
                    groupId: p.groupId,
                });
            }
        }
        dispatch(updatePickNames(updatedPickNamesList));
    };
}

function pickOneName(id: string, isPicked: boolean) {
    return async (dispatch: (arg0: { type: string }) => void) => {
        const pickNamesList = store.getState().pickNamesList;
        const updatedPickNamesList: PickNamesParticipantInterface[] = JSON.parse(JSON.stringify(pickNamesList));

        const pickedName = updatedPickNamesList.find((item) => item.participantId === id);

        if (pickedName) {
            pickedName.isPicked = isPicked;
            pickedName.pickedTime = new Date().getTime();
        }
        dispatch(pickOneNameAction(updatedPickNamesList));
    };
}

function unPickAll() {
    return async (dispatch: (arg0: { type: string }) => void) => {
        const pickNamesList: PickNamesParticipantInterface[] = store.getState().pickNamesList;

        const updatedPickNamesList: PickNamesParticipantInterface[] = JSON.parse(JSON.stringify(pickNamesList));
        updatedPickNamesList.forEach((p) => {
            p.isPicked = false;
        });
        dispatch(updatePickNames(updatedPickNamesList));
    };
}

export const initializePickNames = (payload: PickNamesParticipantInterface[]) => ({
    type: PickNamesActionTypes.INITIALIZE_PICK_NAMES,
    payload: payload,
});

export const updatePickNames = (payload: PickNamesParticipantInterface[]) => ({
    type: PickNamesActionTypes.UPDATE_PICK_NAMES,
    payload: payload,
});

export const resetPickNames = (payload: PickNamesParticipantInterface[]) => ({
    type: PickNamesActionTypes.RESET_PICK_NAMES,
    payload: payload,
});

export const pickOneNameAction = (payload: PickNamesParticipantInterface[]) => ({
    type: PickNamesActionTypes.PICK_ONE_NAME,
    payload: payload,
});
