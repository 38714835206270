import { getCachedReducer } from './../helpers/storeHelpers';
import { BrowserTabsActionTypes } from '../constants/browser-tabs-action-types';
import { UserActionTypes } from '../constants/user-action-types';
import { BrowserTabInterface } from '../interfaces/browser-tabs-interface';
import { localService } from '../services/localStorageService';
import { ReducerName } from '../constants/reducer-name.enum';

export default function BrowserTabsReducer(
    state = getCachedBrowserTabsState(),
    action: { type: string; payload: any },
): BrowserTabInterface[] {
    switch (action.type) {
        case BrowserTabsActionTypes.REMOVE_START_PAGES_ON_INITIALIZATION:
        case BrowserTabsActionTypes.OPEN_NEW_TAB:
        case BrowserTabsActionTypes.SWITCH_BROWSER_TAB:
        case BrowserTabsActionTypes.CLOSE_BROWSER_TAB:
            return [...action.payload];

        case BrowserTabsActionTypes.UPDATE_BROWSER_TAB:
        case BrowserTabsActionTypes.LOAD_TAB_COMPLETED:
            const index = state.findIndex((x) => x.tabId === action.payload.tabId);
            return [
                ...state.slice(0, index),
                Object.assign({}, state[index], action.payload),
                ...state.slice(index + 1),
            ];

        case UserActionTypes.HOST_AUTO_UNLOAD_USER:
        case UserActionTypes.AUTO_SIGN_IN_WITH_PASSWORD_FAILED:
        case UserActionTypes.USER_MANUALLY_SIGNED_OUT:
        case UserActionTypes.DEVICE_ID_CHANGED:
        case UserActionTypes.SIGN_OUT_ON_ERROR_BOUNDARY:
            return noBrowserTabsState;

        default:
            return state;
    }
}

const getCachedBrowserTabsState = (): BrowserTabInterface[] => {
    const browserTabsCached: BrowserTabInterface[] | null = getCachedReducer(ReducerName.browserTabs);
    if (browserTabsCached) return [...noBrowserTabsState, ...browserTabsCached];

    const storeCached = localService.getStoreData();
    if (storeCached && storeCached.browserTabs) return [...noBrowserTabsState, ...storeCached.browserTabs];

    return noBrowserTabsState;
};

const noBrowserTabsState: BrowserTabInterface[] = [];
