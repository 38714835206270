import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';
import webviewMessenger from '../../services/webviewMessenger';
import WatchTutorialButton from './components/WatchTutorialButton';
import VideoModal from '../VideoTutorial/Components/VideoModal';
import { ViewportSavedClasses } from '../../constants/viewport-constants';
import AddNewSavedClassCard from './components/AddNewSavedClassCard';
import SavedClassForUserInterface from '../../interfaces/saved-class-for-user.interface';
import DemoClass from './components/DemoClass';

interface LangingSavedClassesInterface {
    changeCurrentPage: (currentPage: string, savedClass?: SavedClassForUserInterface) => void;
    demoClass?: SavedClassForUserInterface;
}

const LangingSavedClasses = ({ changeCurrentPage, demoClass }: LangingSavedClassesInterface) => {
    const { t } = useTranslation();
    const [tutorialVideoId, setTutorialVideoId] = useState('');

    return (
        <Fragment>
            {tutorialVideoId && (
                <VideoModal
                    tutorialVideoId={tutorialVideoId}
                    setTutorialVideoId={setTutorialVideoId}
                    viewportConstant={ViewportSavedClasses}
                />
            )}
            <div className="addingClass_inner langingSavedClasses">
                <div className="manageClass_src">
                    <div className="manageText">
                        <Typography variant="h2">{t('lang_saved_classes.manage_classes')}</Typography>
                        <Typography variant="body1">{t('lang_saved_classes.manage_classes_detalis')}</Typography>
                    </div>
                    <div className="participent_grid_inner">
                        {demoClass && <DemoClass demoClass={demoClass} changeCurrentPage={changeCurrentPage} />}
                        <AddNewSavedClassCard changeCurrentPage={changeCurrentPage} />
                    </div>
                    <WatchTutorialButton
                        onClickHandler={() => {
                            webviewMessenger.sendUsageLog(`Clicked "Watch tutorial" (overview_gamification)`);
                            setTutorialVideoId('overview_gamification');
                        }}
                        isWhiteIcon={true}
                        className="savedClassWelcome"
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default LangingSavedClasses;
