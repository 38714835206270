import {
    CreateOrEditGroupInterface,
    GroupStageType,
    SavedClassGroupInterface,
} from '../interfaces/savedClassGroup.interface';
import { utilConstants } from '../constants/utils.constants';

export const getSavedClassGroupProps = (
    type: 'groupName' | 'groupColor',
    groupId: number | undefined,
    savedClassGroups: SavedClassGroupInterface[] | undefined,
): string => {
    const group = savedClassGroups?.find((group) => group.groupId === groupId);
    if (type === 'groupName') return group ? group.groupName : 'cp_ungrouped';
    return group ? decodeGroupColorHash(group.groupColor) : utilConstants.DEFAULT_PARTICIPANT_COLOR;
};

export const createSavedClassGroupProps = (
    type: 'groupName' | 'groupColor',
    groupId: number | undefined,
    participantId: string,
    currentGroupDetails: CreateOrEditGroupInterface | undefined,
    savedClassGroups?: SavedClassGroupInterface[],
) => {
    if (currentGroupDetails?.participantIds.includes(participantId)) return currentGroupDetails[type];
    const group = savedClassGroups?.find((group) => group.groupId === groupId);
    const removedParticipantIds = currentGroupDetails?.initialParticipantIds?.filter(
        (id) => !currentGroupDetails.participantIds.includes(id),
    );

    const defaultReturn = type === 'groupName' ? 'cp_ungrouped' : utilConstants.DEFAULT_PARTICIPANT_COLOR;
    if (removedParticipantIds?.includes(participantId)) return defaultReturn;

    if (type === 'groupName') return group ? group.groupName : 'cp_ungrouped';
    return group ? decodeGroupColorHash(group.groupColor) : utilConstants.DEFAULT_PARTICIPANT_COLOR;
};

export const decodeGroupColorHash = (color: string): string => {
    const colorMap = {
        // '#FF5722': '#000000',
    };

    if (colorMap[color]) return colorMap[color];
    return `${color}`;
};

export const isClickOutsideToCloseGroupAllowed = (
    groupStage: GroupStageType,
    isGroupIdPresent: boolean,
    isViewingInMyClass?: boolean,
) => {
    if (isViewingInMyClass && isGroupIdPresent) return true;
    if (groupStage === 'view') return true;
    if (groupStage === 'group' && !isGroupIdPresent) return true;
    if (groupStage === 'point') return true;
    return false;
};
