import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';

import AddIcon from '@mui/icons-material/Add';
import Skeleton from '@mui/material/Skeleton';

import styles from './TemplateContent.module.scss';

import { getValidUserFromStore } from '../../../../helpers/storeHelpers';
import { isSlidesTemplateManager, isUserOnTrialOrPro } from '../../../../helpers/userhelper';
import webviewMessenger from '../../../../services/webviewMessenger';
import apiTemplateLibrary from '../../../../services/apiTemplateLibrary';

import { TemplateItemInterface } from '../../../../interfaces/templateLibrary.interface';
import { utilConstants } from '../../../../constants/utils.constants';

import UpgradeToProModal from '../UpgradeToProModal/UpgradeToProModal';
import ContentModalView from '../ContentModalView/ContentModalView';
import TemplateLibraryHeader from '../Header/TemplateLibraryHeader';

import emptyFace from '../../../../assets/animation/empty-face.json';
import { TemplateCategoryType } from '../../categories';

interface TemplateContentProps {
    currentMenu: TemplateCategoryType;
}

export default function TemplateContent({ currentMenu }: TemplateContentProps) {
    const { t } = useTranslation();
    const user = getValidUserFromStore('templateLibrary');
    const isProUser = isUserOnTrialOrPro(user);
    const proActivityTypes = ['fill_blanks', 'audio_record', 'video_upload'];

    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState<TemplateItemInterface[]>([]);
    const [showUpgradeToProModal, setShowUpgradeToProModal] = useState(false);
    const [modalItem, setModalItem] = useState<TemplateItemInterface>();

    useEffect(() => {
        refreshItems();
        const templateContentBody = document.getElementById('templateContent');
        if (templateContentBody) templateContentBody.scrollTop = 0;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMenu]);

    const refreshItems = async () => {
        setIsLoading(true);
        const items =
            currentMenu.name === 'unpublished'
                ? await apiTemplateLibrary.getUnpublishedTemplates()
                : await apiTemplateLibrary.getTemplatesByCategory(
                      currentMenu.name,
                      isSlidesTemplateManager(user.email),
                  );
        setIsLoading(false);
        setItems(items || []);
    };

    const shouldShowPro = (item: TemplateItemInterface) => {
        if (isProUser) return false;
        const shouldShow = item.isPro || proActivityTypes.includes(currentMenu.name);
        return shouldShow;
    };

    const insertTemplateHandler = (item: TemplateItemInterface) => {
        if (shouldShowPro(item)) {
            setModalItem(undefined);
            setShowUpgradeToProModal(true);
            return;
        }
        webviewMessenger.insertTemplate(item.pptUrl);
        apiTemplateLibrary.downloadTemplate(item._id);
    };

    return (
        <div className={`${styles.contentArea}`}>
            {showUpgradeToProModal && <UpgradeToProModal closeModalHandler={() => setShowUpgradeToProModal(false)} />}

            {modalItem && (
                <ContentModalView
                    modalItem={modalItem}
                    shouldShowPro={shouldShowPro(modalItem)}
                    onInsertTemplateItem={insertTemplateHandler}
                    onRefreshItems={refreshItems}
                    onCloseModal={() => setModalItem(undefined)}
                />
            )}

            <TemplateLibraryHeader currentMenu={currentMenu} />

            {isLoading ? (
                <div className={`${styles.templateContent}`}>
                    {Array.from({ length: 16 }, (item, index) => (
                        <Skeleton key={index} height={150} style={{ borderRadius: '8px' }} variant="rectangular" />
                    ))}
                </div>
            ) : items.length < 1 ? (
                <div className={`${styles.noTemplateFound}`}>
                    <Lottie loop={true} animationData={emptyFace} play speed={1} />
                    <p className={`${styles.noTemplateFoundText}`}>{t('lang_template_library.no_template_found')}</p>
                </div>
            ) : (
                <div id="templateContent" className={`${styles.templateContent}`}>
                    {items.map((item, index) => (
                        <div key={index} className={`${styles.thumbnailContainer}`}>
                            <img
                                onClick={() => setModalItem(item)}
                                src={item.imageUrls[0] || utilConstants.IMAGE_URLS.UPLOAD_DOCUMENT}
                                className={`${styles.thumbnail}`}
                                alt="ClassPoint Templates"
                            />
                            {shouldShowPro(item) && <p className={`${styles.proIcon}`}>PRO</p>}
                            {isSlidesTemplateManager(user.email) && (
                                <p className={`${styles.downloadsCount}`}>{item.downloadsCount}</p>
                            )}
                            <AddIcon onClick={() => insertTemplateHandler(item)} className={`${styles.addIcon}`} />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
