import { TextField } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import SavedClassAvatar from './SavedClassAvatar';

interface AddSavedClassStepOneInterface {
    savedClassAvatar: string;
    setSavedClassAvatar: (arg: string) => void;
    className: string;
    setClassName: (arg: string) => void;
    classNameError: string;
    setClassNameError: (arg: string) => void;
    classCode: string;
    setClassCode: (arg: string) => void;
    classCodeError: string;
    setClassCodeError: (arg: string) => void;
}

function AddSavedClassStepOne({
    savedClassAvatar,
    setSavedClassAvatar,
    className,
    setClassName,
    classNameError,
    setClassNameError,
    classCode,
    setClassCode,
    classCodeError,
    setClassCodeError,
}: AddSavedClassStepOneInterface) {
    const { t } = useTranslation();

    const handleSetClassName = (value: string) => {
        setClassName(value);
        setClassNameError('');
    };

    const handleSetClassCode = (value: string) => {
        setClassCode(value.trim().toUpperCase());
        setClassCodeError('');
    };

    return (
        <Fragment>
            <div className="participent_set_sec">
                <div className="profile_upload">
                    <SavedClassAvatar savedClassAvatar={savedClassAvatar} setSavedClassAvatar={setSavedClassAvatar} />
                </div>
                <div className="participent_input">
                    <div className="text_field">
                        <TextField
                            autoComplete="none"
                            inputProps={{ maxLength: 30 }}
                            id="standard-helperText"
                            label={t('lang_common.txt_class_name')}
                            variant="standard"
                            placeholder={t('lang_saved_classes.place_holder_class_name')}
                            value={className}
                            error={classNameError ? true : false}
                            helperText={classNameError ? classNameError : ''}
                            onChange={(e) => handleSetClassName(e.target.value)}
                            focused
                        />
                    </div>
                    <div className="text_field">
                        <TextField
                            autoComplete="none"
                            inputProps={{ maxLength: 8 }}
                            id="standard-helperText"
                            label={t('lang_common.txt_class_code')}
                            variant="standard"
                            value={classCode}
                            error={classCodeError ? true : false}
                            helperText={classCodeError ? classCodeError : ''}
                            placeholder={t('lang_saved_classes.place_holder_class_code')}
                            focused
                            onChange={(e) => {
                                // handleSetClassCode(e.target.value.replace(/[^\w\s]/gi, ''));
                                const regex = /^[a-z0-9]+$/i;
                                if (!e.currentTarget.value) return handleSetClassCode('');

                                // Split the input into an array and check each character
                                const chars = e.currentTarget.value.split('');
                                const filtered = chars.filter((char) => regex.test(char));
                                // Update the value of the input with only alphanumeric characters
                                handleSetClassCode(filtered.join('').toUpperCase());
                            }}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default AddSavedClassStepOne;
