import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Typography } from '@mui/material';

import { getValidUserFromStore } from '../../../helpers/storeHelpers';
import { findUserClassLimit, isUserOnTrialOrPro } from '../../../helpers/userhelper';

import SavedClassAddedParticipantInterface from '../../../interfaces/saved-class-added-participant.interface';
import { ViewportSavedClasses } from '../../../constants/viewport-constants';
import { QuotaConstants } from '../../../constants/quota-constants';

import CommonAlert from '../../../components/Common/CommonAlert';
import AddParticipantInput from './AddParticipantInput';
import AddParticipantsLimitInfoModal from './Modal/AddParticipantsLimitInfoModal';
import ImportCSV from './ImportCSV';
import { TrialFlow } from '../../activateTrial/TrialFlow';
import webviewMessenger from '../../../services/webviewMessenger';

const AddSavedClassStepTwo = () => {
    const { t } = useTranslation();
    const user = getValidUserFromStore('AddSavedClassStepTwo');
    const isEmailVerified = user.userStats.isEmailVerified;
    const isTrialOrPro = isUserOnTrialOrPro(user);
    const userClassLimit = findUserClassLimit(user);

    const [isShowInfoModal, setIsShowInfoModal] = useState(false);
    const [initialRows, setInitialRows] = useState(userClassLimit);
    const [addedRows, setAddedRows] = useState(0);
    const [isCsvModalOpen, setIsCsvModalOpen] = useState(false);
    const [csvData, setCsvData] = useState<SavedClassAddedParticipantInterface[]>([]);
    const [resetData, setResetData] = useState(false);

    const [isTrialFlowModalOpen, setIsTrialFlowModalOpen] = useState(false);
    const [isActivatingTrial, setIsActivatingTrial] = useState(false);

    const addRowOnImportCsv = (csvDataLength: number) => {
        setInitialRows(csvDataLength);
        if (csvDataLength <= findUserClassLimit(user)) {
            setAddedRows(findUserClassLimit(user) - csvDataLength);
            return;
        }
        const numberToNextFive = 5 - (csvDataLength % 5);
        const remainingLimit = userClassLimit - csvDataLength;
        setAddedRows(Math.min(numberToNextFive, remainingLimit));
    };

    useEffect(() => {
        setTimeout(() => {
            document.getElementById('mui-inp0')?.focus();
        }, 1);
    }, []);

    const checkAndAddRows = (index: number) => {
        const defaultRowsToAdd = 5;
        if (index < userClassLimit - 1 && index >= initialRows + addedRows - 4) {
            const rowsToAdd = Math.min(userClassLimit - (initialRows + addedRows), defaultRowsToAdd);
            setAddedRows(addedRows + rowsToAdd);
        }
    };

    const handleInputChange = (count: number, value: any) => {
        let tempDataSet: any[] = [];
        csvData.forEach((data: any, index: number) => {
            if (index === count) {
                tempDataSet.push({
                    index: data.index,
                    name: typeof value === 'string' ? value : data.name,
                    points: typeof value === 'number' ? value : data.points,
                    error: data.error,
                });
            } else {
                tempDataSet.push({
                    index: data.index,
                    name: data.name,
                    points: data.points,
                    error: data.error,
                });
            }
        });
        setCsvData(tempDataSet);
    };

    return (
        <>
            {isCsvModalOpen && (
                <ImportCSV
                    csvUploadOpen={isCsvModalOpen}
                    handleCSVClose={() => setIsCsvModalOpen(false)}
                    classLimit={userClassLimit}
                    setCsvData={setCsvData}
                    setResetData={setResetData}
                    addRowOnImportCsv={addRowOnImportCsv}
                />
            )}

            <CommonAlert
                showAlert={isTrialFlowModalOpen}
                closeAlert={() => {
                    if (!isActivatingTrial) setIsTrialFlowModalOpen(false);
                }}
                content={
                    <TrialFlow
                        isShowMultipleSlides={true}
                        startingSlideId={'participant_count'}
                        setIsTrialFlowModalOpen={setIsTrialFlowModalOpen}
                        isActivatingTrial={isActivatingTrial}
                        setIsActivatingTrial={setIsActivatingTrial}
                        viewport={ViewportSavedClasses}
                        onTrialActivated={() => setIsTrialFlowModalOpen(false)}
                    />
                }
                viewportConfig={ViewportSavedClasses}
                customModalClassNames="small"
                customBoxClassNames={
                    isActivatingTrial
                        ? isEmailVerified
                            ? `subscriptionTrialFlowModal smallModal`
                            : ''
                        : `savedClassTrialFlowModal`
                }
            />

            <div className="participent_type_head">
                <div className="ptype_left">
                    {/* <Typography variant="body1">
                        <Typography variant="caption">{t('lang_saved_classes.txt_participant_names')}</Typography> (
                        {t('lang_saved_classes.txt_max_participant', { classLimit: userClassLimit })}){' '}
                        <InfoIcon onClick={() => setIsShowInfoModal(true)} />
                    </Typography> */}

                    <Typography className={`textContainer`} variant="body1">
                        <Typography variant="caption">{t('lang_saved_classes.txt_participant_names')}</Typography>
                        {!isTrialOrPro && (
                            <>
                                <Typography className={`maxCount`} variant="caption">
                                    {`(${t('lang_new_trial.max_25_participants', {
                                        classLimit: findUserClassLimit(user),
                                    })})`}
                                </Typography>
                                <Button
                                    onClick={() => {
                                        setIsTrialFlowModalOpen(true);
                                        webviewMessenger.sendUsageLog(`[C] Clicked "Unlock 200 with pro"`);
                                    }}
                                    className={`unlockProBtn`}
                                >
                                    {t('lang_new_trial.unlock_200_with_pro', {
                                        classLimit: QuotaConstants.PRO_USER_CLASS_LIMIT,
                                    })}
                                </Button>
                            </>
                        )}
                    </Typography>
                </div>
                <div
                    className="ptype_right"
                    onClick={() => {
                        webviewMessenger.sendUsageLog(`Clicked "Import from CSV"`);
                        setIsCsvModalOpen(true);
                    }}
                >
                    <Typography variant="body1">{t('lang_saved_classes.import_csv')}</Typography>
                </div>
            </div>

            <CommonAlert
                showAlert={isShowInfoModal}
                closeAlert={() => setIsShowInfoModal(false)}
                content={<AddParticipantsLimitInfoModal closeAlert={() => setIsShowInfoModal(false)} />}
                viewportConfig={ViewportSavedClasses}
                customModalClassNames="small"
            />

            <div className="participent_table">
                {csvData.length > 0
                    ? csvData.map((item, index: number) => {
                          return (
                              <AddParticipantInput
                                  key={index}
                                  index={index}
                                  name={item.name}
                                  points={item.points}
                                  handleInputChange={handleInputChange}
                                  onCheckAndAddRows={checkAndAddRows}
                                  resetData={resetData}
                                  setResetData={setResetData}
                                  dataLength={csvData.length - 1}
                              />
                          );
                      })
                    : [...Array(initialRows)].map((item, index) => (
                          <AddParticipantInput
                              key={index}
                              index={index}
                              onCheckAndAddRows={checkAndAddRows}
                              resetData={resetData}
                              setResetData={setResetData}
                          />
                      ))}
                {[...Array(addedRows)].map((item, index) => {
                    return (
                        <AddParticipantInput
                            key={initialRows + index}
                            index={initialRows + index}
                            onCheckAndAddRows={checkAndAddRows}
                            resetData={resetData}
                            setResetData={setResetData}
                        />
                    );
                })}
            </div>
        </>
    );
};

export default AddSavedClassStepTwo;
