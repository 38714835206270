import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 } from 'uuid';

import CameraAltIcon from '@mui/icons-material/CameraAlt';

import './EditParticipantAvatar.scss';

import { getBase64 } from '../../../../helpers/utils';
import { getValidUserFromStore } from '../../../../helpers/storeHelpers';
import { calculateParticipantLevelAndBadge, getLevelsArray } from '../../../../helpers/userhelper';
import apiSavedClasses from '../../../../services/apiSavedClasses';

import SavedClassParticipantInterface from '../../../../interfaces/saved-class-participant.interface';
import { SavedClassGroupInterface } from '../../../../interfaces/savedClassGroup.interface';

import CustomAvatar, { AvatarType } from '../../../../components/Common/CustomAvatar';
import ImagePickCropUpload from '../../../../components/Common/ImagePickCropUpload';
import { userActions } from '../../../../actions/user.actions';

interface IEditParticipantAvatar {
    selectedParticipant: SavedClassParticipantInterface;
    savedClassId: string;
    savedClassGroups: SavedClassGroupInterface[];
    onParticipantsUpdated: any;
}

const EditParticipantAvatar = ({
    selectedParticipant,
    savedClassId,
    savedClassGroups,
    onParticipantsUpdated,
}: IEditParticipantAvatar) => {
    const dispatch = useDispatch();

    const user = getValidUserFromStore('RenameParticipantModal');

    const participantImageRef = useRef<any>(null);

    const [imageData, setImageData] = useState<any>();
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [participantAvatar, setParticipantAvatar] = useState(selectedParticipant.participantAvatar);

    const participantId = selectedParticipant.participantId;
    const participantName = selectedParticipant.participantName;
    const groupId = selectedParticipant.groupId;

    const loadImageDataFromUrl = (url: string) => {
        getBase64(url).then((base64) => setImageData(base64));
    };

    const handleImageUpdated = async (imageSavedPayload: {
        imageUrl: string;
        originalImageUrl: string;
        cropCursor: string;
    }) => {
        if (!selectedParticipant.participantUsername) return;

        const editAvatarResponse = await apiSavedClasses.renameParticipant(
            savedClassId,
            selectedParticipant.participantId,
            {
                participantUsername: selectedParticipant.participantUsername,
                participantName: selectedParticipant.participantName,
                participantAvatar: imageSavedPayload.imageUrl || '',
            },
        );

        if (!editAvatarResponse) return dispatch(userActions.showApiError());

        const participantsWithLevel = editAvatarResponse.participants.map((p) => ({
            ...p,
            participantLevelAndBadge: calculateParticipantLevelAndBadge(p.participantTotalPoints, getLevelsArray(user)),
        }));

        onParticipantsUpdated(participantsWithLevel);
        setParticipantAvatar(imageSavedPayload.imageUrl || '');
        setIsImageModalOpen(false);
        return;
    };

    const handleImageEditCanceled = async () => {
        if (participantAvatar) getBase64(participantAvatar).then((base64) => setImageData(base64));
        else setImageData(null);
        setIsImageModalOpen(false);
    };

    useEffect(() => {
        if (participantAvatar) loadImageDataFromUrl(participantAvatar);
    }, [participantAvatar]);

    return (
        <div className="profile_upload">
            {participantAvatar ? (
                <div className="upload_profile_images">
                    <label htmlFor="icon-button-saved-class-participant" onClick={() => setIsImageModalOpen(true)}>
                        <CustomAvatar
                            avatarUrl={participantAvatar}
                            avatarName={participantName}
                            type={AvatarType.PARTICIPANT}
                            groupId={groupId}
                            savedClassGroups={savedClassGroups}
                        />
                    </label>
                    <div className="changes_profile_images">
                        <label htmlFor="icon-button-file">
                            <CameraAltIcon onClick={() => setIsImageModalOpen(true)} />
                            <ImagePickCropUpload
                                inputfileref={participantImageRef}
                                isInputDisabled={true}
                                initialImageData={imageData}
                                setImageData={setImageData}
                                outputImageName={`${participantId}-avatar-${v4()}.jpg`}
                                isImageEditModalOpen={isImageModalOpen}
                                setIsImageEditModalOpen={setIsImageModalOpen}
                                onUpdated={handleImageUpdated}
                                handleImageEditCanceled={handleImageEditCanceled}
                                canReset={true}
                                setSavedCrop={() => {}}
                            />
                        </label>
                    </div>
                </div>
            ) : (
                <div className="upload_profile_images">
                    <label
                        onClick={() => participantImageRef.current.click()}
                        htmlFor="icon-button-saved-class-participant"
                    >
                        <CustomAvatar
                            avatarUrl={participantAvatar}
                            avatarName={participantName}
                            type={AvatarType.PARTICIPANT}
                            groupId={groupId}
                            savedClassGroups={savedClassGroups}
                        />
                    </label>
                    <div className="changes_profile_images">
                        <label htmlFor="icon-button-file">
                            <CameraAltIcon onClick={() => participantImageRef.current.click()} />
                            <ImagePickCropUpload
                                inputfileref={participantImageRef}
                                initialImageData={imageData}
                                setImageData={setImageData}
                                outputImageName={`${participantId}-avatar-Image-${v4()}.jpg`}
                                isImageEditModalOpen={isImageModalOpen}
                                setIsImageEditModalOpen={setIsImageModalOpen}
                                onUpdated={handleImageUpdated}
                                handleImageEditCanceled={handleImageEditCanceled}
                                setSavedCrop={() => {}}
                            />
                        </label>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EditParticipantAvatar;
