import { Button, Link, Typography } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { getValidUserFromStore } from '../../../../../helpers/storeHelpers';
import BrowserBookmarkInterface from '../../../../../interfaces/browser-bookmark.interface';
import { useDispatch } from 'react-redux';
import { userActions } from '../../../../../actions/user.actions';
import { UserActionTypes } from '../../../../../constants/user-action-types';
import webviewBrowserCommands from '../../../../../services/webviewBrowserCommands';
import { GlobeFavicon, Pushpin, Unpushpin } from '../../../../../Icon';
import { Fragment } from 'react';
import apiPreferences from '../../../../../services/apiPreferences';
interface Props {
    bookmark: BrowserBookmarkInterface;
    bookMarks: any[];
    setBookMarks: any;
    dataIndex: number;
    hasPinLimitReached: boolean;
}

export const BookMarkItem = ({ bookmark, bookMarks, setBookMarks, dataIndex, hasPinLimitReached }: Props) => {
    const dispatch = useDispatch();
    const user = getValidUserFromStore('BookMarkItem');

    const removeBookMark = async () => {
        setBookMarks(bookMarks.filter((item) => item.id !== bookmark.id));
        const updatedUser = await apiPreferences.deleteBrowserBookmark(user.email, bookmark.id);
        if (!updatedUser) return dispatch(userActions.showApiError());
        dispatch(userActions.updateUser(UserActionTypes.REMOVED_BOOKMARK, updatedUser));
    };

    const handleBookmarkClick = () => {
        webviewBrowserCommands.openBookmarkInTab(bookmark.pageUrl);
    };

    const pinUnpinBookmark = async () => {
        apiPreferences.pinUnpinBrowserBookmark(user.email, bookmark.id, !bookmark.isPinned);
        setBookMarks(
            bookMarks.map((item) => {
                if (item.id === bookmark.id) {
                    item.isPinned = !item.isPinned;
                }
                return item;
            }),
        );
    };

    return (
        <Fragment>
            <li>
                <div className="bookmark_card">
                    <div className="bookmark_name_area" onClick={handleBookmarkClick}>
                        {bookmark.favicon ? <img src={bookmark.favicon} alt="brandlogo" /> : <GlobeFavicon />}
                        <Typography variant="body1">{bookmark.name}</Typography>
                    </div>
                    <div className="bookmark_url" onClick={handleBookmarkClick}>
                        <Link href="" onClick={(event: any) => event.preventDefault()}>
                            {bookmark.pageUrl}
                        </Link>
                    </div>
                    <div className="bookmark_btn_group">
                        <div
                            className={
                                hasPinLimitReached ? 'pinbtn disable' : bookmark.isPinned ? 'pinbtn active' : 'pinbtn'
                            }
                        >
                            <Button variant="text" onClick={pinUnpinBookmark}>
                                <Pushpin />
                                <Unpushpin />
                            </Button>
                        </div>

                        <div className="remove_bookmark">
                            <CloseRoundedIcon onClick={removeBookMark} />
                        </div>
                    </div>
                </div>
            </li>
        </Fragment>
    );
};
