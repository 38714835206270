import { localService } from './../services/localStorageService';

export const displayLocaleDate = (planExpiryDate: string | number | Date) => {
    let expiryDate = new Date(planExpiryDate).toLocaleDateString(chooseDateFormat(localService.getLanguage()), {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    });
    return expiryDate;
};

const chooseDateFormat = (language: string) => {
    switch (language) {
        case 'ar':
            return 'ar-AA';
        case 'zh':
            return 'zh-CN';
        case 'de':
            return 'de-DE';
        case 'en':
            return 'en-GB';
        case 'es':
            return 'es-ES';
        case 'fr':
            return 'fr-FR';
        case 'hi':
            return 'hi-IN';
        case 'id':
            return 'id-ID';
        case 'ja':
            return 'ja-JP';
        case 'ms':
            return 'ms-MY';
        case 'fil':
            return 'tl-PH';
        case 'pt':
            return 'pt-PT';
        case 'ru':
            return 'ru-RU';
        case 'th':
            return 'th-TH';
        case 'vi':
            return 'vi-VN';
        default:
            return 'en-GB';
    }
};
