import { Button } from '@mui/material';
import './CommonButton.scss';
import webviewMessenger from '../../services/webviewMessenger';
import { useTranslation } from 'react-i18next';
import apiUser from '../../services/apiUser';
// import ReactGA from 'react-ga4';
import { getValidUserFromStore } from '../../helpers/storeHelpers';
import { getNewUserDiscount } from '../../helpers/userhelper';

export const UpgradeToProButton = ({ caller, email }: { caller: string; email: string }) => {
    const { t } = useTranslation();
    const user = getValidUserFromStore('UpgradeToProButton');
    const discount = getNewUserDiscount(user);
    const subscribeUrl = `${process.env.REACT_APP_WEBAPP}/cp/checkout`;

    return (
        <Button
            onClick={() => {
                webviewMessenger.sendOpenBrowser(subscribeUrl);
                webviewMessenger.sendUsageLog(`[C] Clicked "Upgrade to Pro" from: ${caller}`);
                apiUser.recordGoproTrigger(email, caller);
                (window as any).fbq('trackCustom', 'GoProTrigger', { source: caller });
                // ReactGA.event({
                //     category: 'goproTrigger',
                //     action: caller,
                // });
                // webviewMessenger.sendHideWebview();
            }}
            className={`trialActivationButton new_video_btn ${caller === 'aiBasicUser' && 'aiLongButton'}`}
        >
            {t('lang_new_trial.upgrade_to_pro')}
            {discount
                ? ` - ${t('lang_new_discount.percentage_off', {
                      percentage: discount.discountPercentage,
                  })}`
                : ''}
        </Button>
    );
};
