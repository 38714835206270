import { VideoTutorials } from './../../constants/video-tutorials';
export const getLocalizedVideoInfo = (videoInfo: any, selectedLanguage: string) => {
    if (!videoInfo) return '';
    return videoInfo[`lang_${selectedLanguage}`] || videoInfo['lang_en'];
};

export const getSectionIdFromVideoId = (videoId: string) => {
    for (let section of VideoTutorials) {
        const isSectionActive = section.videoIds.some((id) => id === videoId);
        if (isSectionActive) return section.sectionId;
    }
    return '';
};
