import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FitToViewport } from 'react-fit-to-viewport';
import { useTranslation } from 'react-i18next';

import { Modal, Box } from '@mui/material';

import { userActions } from '../../actions/user.actions';
import apiUser from '../../services/apiUser';
import { dataURLtoFile } from '../../helpers/utils';
import { getValidUserFromStore } from '../../helpers/storeHelpers';
import { ViewportSavedClasses } from '../../constants/viewport-constants';
import { ViewportConstants } from '../../constants/viewport-constants';

import CommonButton from './CommonButton';
import ImageCropper from './ImageCropper';

export default function ImageEditModal({
    isModalOpen,
    onModalClose,
    imageData,
    savedCrop,
    previewCanvasRef,
    onChangeImage,
    onUpdated,
    onCanceled,
    imageName,
    originalImageName,
    canReset,
}: any) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const user = getValidUserFromStore('ImageEditModal');
    const [isProcessing, setIsProcessing] = useState(false);
    const [cropCursor, setCropCursor] = useState<any>();
    const [cropData, setCropData] = useState('#');
    const location = useLocation();
    const pathname = location.pathname;
    let actualViewPort;
    if (pathname === '/savedClass' || pathname === '/settings') actualViewPort = ViewportSavedClasses;
    else actualViewPort = ViewportConstants;

    useEffect(() => {
        if (!isModalOpen) return;
        setCropCursor(undefined);
        var i = new Image();
        i.onload = function () {
            if (savedCrop === undefined || !savedCrop) {
                const imageElement: any = previewCanvasRef?.current;
                const cropper: any = imageElement?.cropper;
                setTimeout(() => {
                    cropper.setAspectRatio(1);
                }, 200);
            }
        };

        i.src = imageData;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageData, isModalOpen]);

    const handleChangeImage = () => {
        setIsProcessing(false);
        onChangeImage();
    };

    // const handleSave = async () => {
    //     setIsProcessing(true);

    //     const canvas1: any = document.createElement('canvas');
    //     const canvas: any = previewCanvasRef.current;
    //     let ctx: any = canvas.getContext('2d');

    //     let isImageTransparent = hasAlpha(ctx, canvas);
    //     let dataURL = '';
    //     if (!isImageTransparent) {
    //         dataURL = canvas.toDataURL();

    //     } else {
    //         canvas1.width = canvas.width;
    //         canvas1.height = canvas.height;
    //         let ctx1: any = canvas1.getContext('2d');

    //         ctx1.fillStyle = 'black';
    //         ctx1.fillRect(0, 0, canvas.width, canvas.height);

    //         let canvas3: any = document.createElement('canvas');
    //         let ctx3 = canvas3.getContext('2d');
    //         canvas3.width = canvas.width;
    //         canvas3.height = canvas.height;
    //         ctx3.drawImage(canvas1, 0, 0);
    //         ctx3.drawImage(canvas, 0, 0);
    //         dataURL = canvas3.toDataURL();

    //     }
    //     let croppedImageFile;
    //     if (cropCursor.width && cropCursor.height) croppedImageFile = dataURLtoFile(dataURL, imageName);
    //     else croppedImageFile = dataURLtoFile(imageData, imageName);

    //     const imageUrl = await apiUser.uploadToStorage(croppedImageFile, imageName, 'cpfile', 'user');
    //     if (!imageUrl) return dispatch(userActions.showApiError());

    //     let originalImageUrl = '';
    //     if (originalImageName) {
    //         const originalImageFile = dataURLtoFile(imageData, originalImageName);
    //         const fileUrl = await apiUser.uploadToStorage(originalImageFile, originalImageName, 'cpfile', 'user');
    //         if (!fileUrl) return dispatch(userActions.showApiError());
    //         originalImageUrl = fileUrl;
    //     }

    //     setIsProcessing(false);
    //     onUpdated({
    //         imageUrl,
    //         originalImageUrl,
    //         cropCursor: JSON.stringify(cropCursor),
    //     });
    // };

    const handleSave = async () => {
        setIsProcessing(true);

        let croppedImageFile;
        if (cropCursor.width && cropCursor.height) croppedImageFile = dataURLtoFile(cropData, imageName);
        else croppedImageFile = dataURLtoFile(imageData, imageName);

        const imageUrl = await apiUser.uploadToStorage(croppedImageFile, imageName, user.apiServer, false, 'user');
        if (!imageUrl) return dispatch(userActions.showApiError());

        let originalImageUrl = '';
        if (originalImageName) {
            const originalImageFile = dataURLtoFile(imageData, originalImageName);
            const fileUrl = await apiUser.uploadToStorage(
                originalImageFile,
                originalImageName,
                user.apiServer,
                false,
                'user',
            );
            if (!fileUrl) return dispatch(userActions.showApiError());
            originalImageUrl = fileUrl;
        }

        setIsProcessing(false);
        onUpdated({
            imageUrl,
            originalImageUrl,
            cropCursor: JSON.stringify(cropCursor),
        });
    };

    const resetHandler = () => {
        onUpdated({
            imageUrl: null,
            originalImageUrl: null,
            cropCursor: null,
        });
    };

    return (
        <Fragment>
            <Modal
                open={isModalOpen}
                onClose={onModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="csv_upload_modal"
            >
                {pathname === '/profile' ? (
                    <Fragment>
                        <div className="close_modal" onClick={onModalClose}></div>
                        <Box className="modal_Box csvmodal_box">
                            <ImageCropper
                                image={imageData}
                                setref={previewCanvasRef}
                                savedCrop={savedCrop}
                                cropCursor={cropCursor}
                                setCropCursor={setCropCursor}
                                setCropData={setCropData}
                            />
                            {/* <ImageCrop
                            image={imageData}
                            setref={previewCanvasRef}
                            savedCrop={savedCrop}
                            cropCursor={cropCursor}
                            setCropCursor={setCropCursor}
                            completedCrop={completedCrop}
                            setCompletedCrop={setCompletedCrop}
                        /> */}

                            <div className="button_sec">
                                <CommonButton
                                    isLoading={false}
                                    type="button"
                                    className="dark_button"
                                    variant="contained"
                                    clickFunction={handleChangeImage}
                                    text={t('lang_common.btn_change')}
                                />

                                {isProcessing ? (
                                    <CommonButton
                                        isLoading={true}
                                        disabled={true}
                                        type="button"
                                        className=" change_images"
                                        variant="contained"
                                        text={t('lang_common.btn_saving')}
                                    />
                                ) : (
                                    <CommonButton
                                        isLoading={false}
                                        type="button"
                                        className=" change_images"
                                        variant="contained"
                                        clickFunction={handleSave}
                                        text={t('lang_common.btn_save')}
                                    />
                                )}

                                <CommonButton
                                    isLoading={false}
                                    className="dark_button"
                                    variant="contained"
                                    type="button"
                                    text={t('lang_common.btn_cancel')}
                                    clickFunction={onCanceled}
                                />
                            </div>
                        </Box>
                    </Fragment>
                ) : (
                    <Fragment>
                        <FitToViewport
                            className="modal_viewport"
                            width={actualViewPort.width}
                            height={actualViewPort.height}
                            minZoom={actualViewPort.minZoom}
                            maxZoom={actualViewPort.maxZoom}
                        >
                            <div className="close_modal" onClick={onModalClose}></div>
                            <Box className="modal_Box csvmodal_box">
                                {/* <ImageCrop
                                image={imageData}
                                setref={previewCanvasRef}
                                savedCrop={savedCrop}
                                cropCursor={cropCursor}
                                setCropCursor={setCropCursor}
                                completedCrop={completedCrop}
                                setCompletedCrop={setCompletedCrop}
                            /> */}
                                <ImageCropper
                                    image={imageData}
                                    setref={previewCanvasRef}
                                    savedCrop={savedCrop}
                                    cropCursor={cropCursor}
                                    setCropCursor={setCropCursor}
                                    setCropData={setCropData}
                                />
                                <div className="button_sec">
                                    <CommonButton
                                        isLoading={false}
                                        type="button"
                                        className="dark_button"
                                        variant="contained"
                                        clickFunction={handleChangeImage}
                                        text={t('lang_common.btn_change')}
                                    />

                                    {isProcessing ? (
                                        <CommonButton
                                            isLoading={true}
                                            disabled={true}
                                            type="button"
                                            className=" change_images"
                                            variant="contained"
                                            text={t('lang_common.btn_saving')}
                                        />
                                    ) : (
                                        <CommonButton
                                            isLoading={false}
                                            type="button"
                                            className=" change_images"
                                            variant="contained"
                                            clickFunction={handleSave}
                                            text={t('lang_common.btn_save')}
                                        />
                                    )}

                                    <CommonButton
                                        isLoading={false}
                                        className={`${canReset && 'reset'} dark_button`}
                                        variant="contained"
                                        type="button"
                                        text={canReset ? t('lang_pick_names.btn_reset') : t('lang_common.btn_cancel')}
                                        clickFunction={canReset ? resetHandler : onCanceled}
                                    />
                                </div>
                            </Box>
                        </FitToViewport>
                    </Fragment>
                )}
            </Modal>
        </Fragment>
    );
}
