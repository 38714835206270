import { Fragment, useEffect, useState } from 'react';
// import { Router, Route, Switch, useLocation } from 'react-router-dom';
import { Route, useLocation, Routes } from 'react-router-dom';
import { Auth } from '../pages/Auth/Auth';
import MyClass from '../pages/myClass/MyClass';
import Activity from '../pages/activity';
import webviewMessenger from '../services/webviewMessenger';
import LeaderBoard from '../pages/leaderBoard/LeaderBoard';
import WhiteBoard from '../pages/WhiteBoard';
import Helpdesk from '../pages/Helpdesk/Helpdesk';
import Setting from '../pages/setting/Setting';
import Loading from '../pages/activity/Loading/Loading';
import PickNames from '../pages/PickNames/PickNames';
import QuizSummary from '../pages/Quizsummary/Quizsummary';
import SharePdf from '../pages/SharePDF/SharePdf';
import TimerStopwatch from '../pages/Timer/TimerStopwatch';
import QuickPollSelect from '../pages/activity/QuickPoll/QuickPollSelect';
import Dashboard from '../pages/Dashboard/Dashboard';
import SavedClasses from '../pages/savedClass/SavedClasses';
import WhiteBoardSetting from '../pages/WhiteBoardSetting/WhiteBoardSetting';
import VideoTutorial from '../pages/VideoTutorial/VideoTutorial';
import GAHistoryTrack from './GAHistoryTrack';
import { HostLogType } from '../constants/host-log-types.enum';
import GoPro from '../pages/GoPro/GoPro';
import NewWelcomeScreen from '../pages/newWelcomeScreen/NewWelcomeScreen';
import ContextualTutorial from '../pages/ContextualTutorial/ContextualTutorial';
import AiQuiz from '../pages/aiQuiz/AiQuiz';
import Qna from '../pages/Qna/Qna';
import TemplateLibrary from '../pages/TemplateLibrary/TemplateLibrary';
import ControlUi from '../pages/Browser/components/ControlUi/ControlUi';
import StartPage from '../pages/Browser/components/ContentUi/StartPage/StartPage';
import BookMarks from '../pages/Browser/components/ContentUi/BookMark/BookMarks';

const Pages = ({ history }: any) => {
    const location = useLocation();
    const [previousPathname, setPreviousPathname] = useState(location.pathname);

    useEffect(() => {
        webviewMessenger.sendHostLog(HostLogType.NAVIGATION, `${previousPathname} => ${location.pathname}`);
        setPreviousPathname(location.pathname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <Fragment>
            {/* <Router history={history}>
                <Switch>
                    <Route path="/loading" component={Loading} />
                    <Route path="/signin" component={Auth} />
                    <Route path="/hello" component={Dashboard}></Route>
                    <Route path="/welcome" component={NewWelcomeScreen}></Route>
                    <Route path="/gopro/:startingSlideId?" component={GoPro}></Route>
                    <Route path="/activity" component={activity}></Route>
                    <Route path="/myclass" component={MyClass}></Route>
                    <Route path="/leaderboard" component={LeaderBoard}></Route>
                    <Route path="/whiteboard" component={WhiteBoard}></Route>
                    <Route path="/picknames" component={PickNames}></Route>
                    <Route path="/aiQuiz" component={AiQuiz}></Route>
                    <Route path="/qpselect" component={QuickPollSelect}></Route>
                    <Route path="/savedClass" component={SavedClasses}></Route>
                    <Route path="/whiteboardSettings" component={WhiteBoardSetting}></Route>
                    <Route path="/tutorial" component={VideoTutorial}></Route>
                    <Route path="/contextual-tutorial" component={ContextualTutorial}></Route>
                    <Route path="/settings" component={Setting}></Route>
                    <Route path="/browser" component={DefaultBrowser}></Route>
                    <Route path="/settingsRedirect/:urlTabName" exact component={Setting}></Route>
                    <Route path="/quizsummary" component={QuizSummary}></Route>
                    <Route path="/helpdesk" component={Helpdesk}></Route>
                    <Route path="/exportpdf" component={SharePdf}></Route>
                    <Route path="/timer" component={TimerStopwatch}></Route>
                    <Route path="/qna" component={Qna}></Route>
                    <Route path="/library" component={TemplateLibrary}></Route>
                </Switch>
                <GAHistoryTrack />
            </Router> */}

            <Routes>
                <Route path="/loading" element={<Loading />} />
                <Route path="/signin" element={<Auth />} />
                <Route path="/hello" element={<Dashboard />} />
                <Route path="/welcome" element={<NewWelcomeScreen />} />
                <Route path="/gopro/:startingSlideId?" element={<GoPro />} />
                <Route path="/activity" element={<Activity />} />
                <Route path="/myclass" element={<MyClass />} />
                <Route path="/leaderboard" element={<LeaderBoard />} />
                <Route path="/whiteboard" element={<WhiteBoard />} />
                <Route path="/picknames" element={<PickNames />} />
                <Route path="/aiQuiz" element={<AiQuiz />} />
                <Route path="/qpselect" element={<QuickPollSelect />} />
                <Route path="/savedClass" element={<SavedClasses />} />
                <Route path="/whiteboardSettings" element={<WhiteBoardSetting />} />
                <Route path="/tutorial" element={<VideoTutorial />} />
                <Route path="/contextual-tutorial" element={<ContextualTutorial />} />
                <Route path="/settings" element={<Setting />} />
                <Route path="/browser">
                    <Route path={`/browser`} element={<ControlUi />} />
                    <Route path={`/browser/start`} element={<StartPage />} />
                    <Route path={`/browser/bookmarks`} element={<BookMarks />} />
                </Route>
                <Route path="/settingsRedirect/:urlTabName" element={<Setting />} />
                <Route path="/quizsummary" element={<QuizSummary />} />
                <Route path="/helpdesk" element={<Helpdesk />} />
                <Route path="/exportpdf" element={<SharePdf />} />
                <Route path="/timer" element={<TimerStopwatch />} />
                <Route path="/qna" element={<Qna />} />
                <Route path="/library" element={<TemplateLibrary />} />
            </Routes>
            <GAHistoryTrack />
        </Fragment>
    );
};

export default Pages;
