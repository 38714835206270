import { Typography } from '@mui/material';
import { Fragment, useEffect } from 'react';
import Lottie from 'react-lottie-player';
import { useTranslation } from 'react-i18next';
import success from '../../assets/animation/success.json';
import CommonButton from '../../components/Common/CommonButton';
import webviewMessenger from '../../services/webviewMessenger';

const HelpdeskSuccess = ({ onComponentChange, onHelpdeskSuccess }: any) => {
    const handleClose = () => {
        webviewMessenger.sendSimpleMessage('closeHelpdesk');
        onComponentChange('form');
    };
    const { t } = useTranslation();
    useEffect(() => {
        onHelpdeskSuccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <div className="helpDeskMessage">
                <Lottie loop={false} animationData={success} play speed={1} />
                <Typography variant="body1">{t('lang_help_desk.txt_msg_sent_successfully')}</Typography>
            </div>
            <div className="helper_btn_group">
                <div className="imagesbtn">
                    <CommonButton
                        isLoading={false}
                        variant="contained"
                        className="rotateleft "
                        type="button"
                        color="primary"
                        text={t('lang_common.btn_close')}
                        clickFunction={handleClose}
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default HelpdeskSuccess;
