import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import CommonButton from '../../../../components/Common/CommonButton';
import InfoIcon from '@mui/icons-material/Info';

interface AddZeroParticipantAlertInterface {
    onConfirm: () => void;
    closeAlert: () => void;
    isSubmitting: boolean;
}

const AddZeroParticipantAlert = ({ onConfirm, closeAlert, isSubmitting }: AddZeroParticipantAlertInterface) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div className="media">
                <div className="media_icon">
                    <InfoIcon className="info" />
                </div>
                <div className="media_body">
                    <div className="alert_content">
                        <Typography variant="body1">
                            {t('lang_saved_classes.warn_skip_adding_participants_now')}
                        </Typography>
                    </div>
                </div>
            </div>
            <div className="button_sec">
                <CommonButton
                    isLoading={isSubmitting}
                    variant="contained"
                    type="button"
                    text={isSubmitting ? t('lang_common.btn_finishing') : t('lang_common.btn_yes')}
                    clickFunction={() => onConfirm()}
                />
                <CommonButton
                    isLoading={false}
                    variant="text"
                    type="button"
                    text={t('lang_common.btn_no')}
                    clickFunction={closeAlert}
                />
            </div>
        </Fragment>
    );
};

export default AddZeroParticipantAlert;
