import { Fragment, useState, useEffect, useRef } from 'react';
import { FitToViewport } from 'react-fit-to-viewport';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import { timerViewportConstants, timerViewportFullScreenConstants } from '../../constants/viewport-constants';
import StoreInterface from '../../interfaces/store.interface';
import webviewMessenger from '../../services/webviewMessenger';
import { timerStopWatchActions } from '../../actions/timer-stopwatch.action';
import { getRemainingSeconds } from '../../helpers/timerHelpers';
import TimerSoundSetting from './components/TimerSoundSetting';
import EmptyModal from '../EmptyModal/EmptyModal';
import Timer from './components/Timer';
import Stopwatch from './components/Stopwatch';
import './Timer.scss';

function TimerStopwatch() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const timerStopwatch = useSelector((state: StoreInterface) => state.timerStopwatch);
    const currentTimeRef = useRef(0);
    const [showEmptyModal, setShowEmptyModal] = useState(false);
    const [isTimerMode, setIsTimerMode] = useState(timerStopwatch.isTimerMode);
    const [showTab, setShowTab] = useState(true);
    const [showSettings, setShowSettings] = useState(false);
    const [isRunning, setIsRunning] = useState(false);
    const [timerValue, setTimerValue] = useState(timerStopwatch.timerTime || 300);
    const [stopwatchValue, setStopwatchValue] = useState(0);
    const [isPaused, setIsPaused] = useState(false);

    const handleActiveTab = (event: React.SyntheticEvent, newValue: string) => {
        const isTimer = newValue === '1';
        dispatch(timerStopWatchActions.updateTimerMode(isTimer));
        setIsTimerMode(isTimer);
    };

    const getCurrentTimeForHost = () => {
        if (isTimerMode) {
            return timerStopwatch.isTimerPaused
                ? Math.floor((timerStopwatch.timerEndTime - timerStopwatch.timerPauseTime) / 1000)
                : getRemainingSeconds(timerStopwatch.timerEndTime);
        } else return currentTimeRef.current;
    };

    useEffect(() => {
        if (timerStopwatch.isWindowMaximized) return;
        setShowEmptyModal(true);
        setTimeout(() => {
            setShowEmptyModal(false);
        }, 100);
    }, [showTab, timerStopwatch.isWindowMaximized]);

    useEffect(() => {
        if (timerStopwatch.isWindowMaximized) {
            setShowTab(false);
            webviewMessenger.timerFullScreenSize();
        }
    }, [timerStopwatch.isWindowMaximized]);

    useEffect(() => {
        if (!isTimerMode) currentTimeRef.current = 0;
    }, [isTimerMode]);

    useEffect(() => {
        if (timerStopwatch.isWindowCollapsed) {
            // enter this block when user collapses timerstopwatch window
            if (isRunning) {
                webviewMessenger.timerCollapseDataToHost({
                    isTimer: isTimerMode,
                    currentTime: getCurrentTimeForHost(),
                    isPaused: isPaused,
                });
            } else webviewMessenger.sendHideWebview();
        } else {
            // enter this block when user restores timerstopwatch window
            if (isRunning) {
                if (isTimerMode && !isPaused) {
                    const now = new Date();
                    now.setSeconds(now.getSeconds() + timerStopwatch.timeDataFromHost + 1);
                    const newTimerEndTime = now.getTime();
                    dispatch(timerStopWatchActions.startTimer(newTimerEndTime));
                    return;
                }
                if (!isTimerMode) {
                    setStopwatchValue(timerStopwatch.timeDataFromHost);
                    return;
                }
            } else {
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timerStopwatch.isWindowCollapsed]);

    return (
        <Fragment>
            <FitToViewport
                className={`viewport ${timerStopwatch.isWindowMaximized && 'fullscreen'} overflow-hidden`}
                width={
                    timerStopwatch.isWindowMaximized
                        ? timerViewportFullScreenConstants.width
                        : timerViewportConstants.width
                }
                height={
                    timerStopwatch.isWindowMaximized
                        ? timerViewportFullScreenConstants.height
                        : timerViewportConstants.height
                }
                minZoom={
                    timerStopwatch.isWindowMaximized
                        ? timerViewportFullScreenConstants.minZoom
                        : timerViewportConstants.minZoom
                }
                maxZoom={
                    timerStopwatch.isWindowMaximized
                        ? timerViewportFullScreenConstants.maxZoom
                        : timerViewportConstants.maxZoom
                }
            >
                <div className="timer_sec">
                    {showEmptyModal && <EmptyModal viewportConfig={timerViewportConstants} />}

                    {showSettings ? (
                        <TimerSoundSetting setShowSettings={setShowSettings} />
                    ) : (
                        <div className="timer_tab_sec">
                            <TabContext value={isTimerMode ? '1' : '2'}>
                                {showTab && (
                                    <Box className="tabContainer">
                                        <TabList onChange={handleActiveTab}>
                                            <Tab
                                                label={t('lang_timer.tab_timer')}
                                                icon={<HourglassEmptyRoundedIcon />}
                                                value="1"
                                            />
                                            <Tab
                                                label={t('lang_timer.tab_stopwatch')}
                                                icon={<TimerOutlinedIcon />}
                                                value="2"
                                            />
                                        </TabList>
                                    </Box>
                                )}

                                <TabPanel
                                    value="1"
                                    className={isRunning || timerStopwatch.isWindowMaximized ? 'isRunning timmer' : ''}
                                >
                                    <Timer
                                        timerValue={timerValue}
                                        setTimerValue={setTimerValue}
                                        isRunning={isRunning}
                                        setIsRunning={setIsRunning}
                                        setShowSettings={setShowSettings}
                                        setShowTab={setShowTab}
                                        currentTimeRef={currentTimeRef}
                                        setIsPaused={setIsPaused}
                                    />
                                </TabPanel>

                                <TabPanel
                                    value="2"
                                    className={isRunning || timerStopwatch.isWindowMaximized ? 'isRunning' : ''}
                                >
                                    <Stopwatch
                                        stopwatchValue={stopwatchValue}
                                        setStopwatchValue={setStopwatchValue}
                                        isRunning={isRunning}
                                        setIsRunning={setIsRunning}
                                        isFullScreen={timerStopwatch.isWindowMaximized}
                                        setShowTab={setShowTab}
                                        showTab={showTab}
                                        currentTimeRef={currentTimeRef}
                                        isPaused={isPaused}
                                        setIsPaused={setIsPaused}
                                    />
                                </TabPanel>
                            </TabContext>
                        </div>
                    )}
                </div>
            </FitToViewport>
        </Fragment>
    );
}

export default TimerStopwatch;
