import { useTranslation } from 'react-i18next';
import { motion, Reorder } from 'framer-motion';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';

import { utilConstants } from '../../../../constants/utils.constants';

interface Props {
    item: any;
    isSelected: boolean;
    onClick: () => void;
    onRemove: () => void;
}

export const Tab = ({ item, onClick, onRemove, isSelected }: Props) => {
    const { t } = useTranslation();

    return (
        <Reorder.Item
            value={item}
            id={item.tabId}
            initial={{ opacity: 0, y: 30 }}
            animate={{
                opacity: 1,
                y: 0,
                transition: { duration: 0.15 },
            }}
            exit={{ opacity: 0, y: 20, transition: { duration: 0.3 } }}
            className={`tab-item ${item.active ? 'active' : ''}`}
            onPointerDown={onClick}
        >
            <motion.div className="title_sec">
                <div className="favicon_box">
                    {item.favicon ? (
                        <img src={item.favicon} alt="favicon" />
                    ) : (
                        <img src={utilConstants.IMAGE_URLS.cpFavicon} alt="favicon" />
                    )}
                </div>
                <Typography variant="body1">{item.title ? item.title : t('lang_browser.heading_new_tab')}</Typography>
            </motion.div>
            <div className="cross" onClick={() => onRemove()}>
                <CloseIcon />
            </div>
        </Reorder.Item>
    );
};
