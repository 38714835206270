import { useMemo } from 'react';
import PickNamesParticipantInterface from '../../../interfaces/pick-names-participant.interface';
import { Chip } from '@mui/material';
import CustomAvatar, { AvatarType } from '../../../components/Common/CustomAvatar';
import { useSelector } from 'react-redux';
import StoreInterface from '../../../interfaces/store.interface';

interface PickNamesFlipCardInterface {
    participant: PickNamesParticipantInterface;
    setPickedParticipant: (arg: PickNamesParticipantInterface) => void;
    setIsPickedModalOpen: (arg: boolean) => void;
}

function CacheWheelViewSideList({
    participant,
    setPickedParticipant,
    setIsPickedModalOpen,
}: PickNamesFlipCardInterface) {
    const classSession = useSelector((state: StoreInterface) => state.classSession);

    return useMemo(() => {
        return (
            <Chip
                onClick={() => {
                    setPickedParticipant(participant);
                    setIsPickedModalOpen(true);
                }}
                style={{
                    border: `2px solid ${participant.colorHex}`,
                }}
                avatar={
                    <CustomAvatar
                        avatarName={participant.participantName}
                        avatarUrl={participant.participantAvatar}
                        type={AvatarType.PARTICIPANT}
                        savedClassGroups={classSession?.groups}
                        groupId={participant.groupId}
                    />
                }
                label={participant.participantName}
            />
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [participant.colorHex, participant.participantName, participant.participantAvatar]);
}

export default CacheWheelViewSideList;
