import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Avatar } from '@mui/material';

import { getColorForString, getFirstLettersFromName } from '../../helpers/utils';
import { isUserOnPremium } from '../../helpers/userhelper';
import { getSavedClassGroupProps } from '../../helpers/savedClassGroupHelper';

import { SavedClassGroupInterface } from '../../interfaces/savedClassGroup.interface';
import StoreInterface from '../../interfaces/store.interface';
import { utilConstants } from '../../constants/utils.constants';

export enum AvatarType {
    USER,
    PARTICIPANT,
    SAVEDCLASS,
}

const CustomAvatar = ({
    avatarName,
    avatarUrl,
    onClick,
    type,
    savedClassGroups,
    groupId,
    style,
    className,
}: {
    avatarName?: string;
    avatarUrl?: string;
    onClick?: any;
    type: AvatarType;
    savedClassGroups?: SavedClassGroupInterface[];
    groupId?: number;
    style?: object;
    className?: string;
}) => {
    const user = useSelector((state: StoreInterface) => state.user);

    const [isImageValid, setIsImageValid] = useState(true);

    const determineBackgroundColor = (avatarName: string) => {
        if (type === AvatarType.PARTICIPANT) {
            if (!user || !savedClassGroups?.length || !groupId?.toString()) {
                return utilConstants.DEFAULT_PARTICIPANT_COLOR;
            }

            // check if user is premium
            const isPremium = isUserOnPremium(user);
            if (!isPremium) return utilConstants.DEFAULT_PARTICIPANT_COLOR;

            return getSavedClassGroupProps('groupColor', groupId, savedClassGroups);
        } else {
            return getColorForString(avatarName);
        }
    };

    return avatarUrl && isImageValid ? (
        <Avatar
            onClick={onClick}
            src={avatarUrl}
            onError={() => setIsImageValid(false)}
            alt={avatarName?.toUpperCase() || ''}
            style={style}
            className={className}
            variant="circular"
        />
    ) : avatarName ? (
        <Avatar
            children={getFirstLettersFromName(avatarName.toUpperCase())}
            onClick={onClick}
            sx={{ background: determineBackgroundColor(avatarName) }}
            style={style}
            className={className}
            variant="circular"
        />
    ) : null;
};

export default CustomAvatar;
