import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { userActions } from '../../../actions/user.actions';
import apiUser from '../../../services/apiUser';
import { localService } from '../../../services/localStorageService';
import apiPreferences from '../../../services/apiPreferences';
import webviewMessenger from '../../../services/webviewMessenger';
import { mapUserToSyncWithHost } from '../../../helpers/userhelper';
import { ResetPasswordInterface } from '../../../interfaces/signInSignUp.interface';
import { UserActionTypes } from '../../../constants/user-action-types';
import { HostEventTypes } from '../../../constants/host-event-types';

import CommonButton from '../../../components/Common/CommonButton';
import { GtmService } from '../../../services/googleTagManager.service';

interface ForgotPasswordSuccessInterface {
    forgotPasswordDetails: ResetPasswordInterface;
}

export const ForgotPasswordSuccess = ({ forgotPasswordDetails }: ForgotPasswordSuccessInterface) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const currentLanguage = localService.getLanguage();

    const [isLoading, setIsLoading] = useState(false);

    const signInHandler = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const statsFromHost = localService.getStatsFromHost();
        const password = forgotPasswordDetails.password;

        setIsLoading(true);
        const repliedUser = await apiUser.signInWithPassword(
            forgotPasswordDetails.email,
            { password, ...statsFromHost },
            'passwordReset',
        );
        setIsLoading(false);

        if (!repliedUser || typeof repliedUser === 'number') return dispatch(userActions.showApiError());
        dispatch({
            type: UserActionTypes.SIGN_IN_AFTER_PASSWORD_RESET_SUCCESS,
            payload: repliedUser,
        });
        if (!repliedUser.userPreferences?.language) apiPreferences.saveLanguage(repliedUser.email, currentLanguage);
        else dispatch(userActions.updateUser(UserActionTypes.LOAD_USER_LANGUAGE_PREFERENCE, repliedUser));
        webviewMessenger.sendUserLoaded(HostEventTypes.MANUAL_SIGN_IN_SUCCESS, mapUserToSyncWithHost(repliedUser));
        GtmService.sendLoginEvent(repliedUser.userId, 'reset-password');
        (window as any).fbq('trackCustom', 'SignIn');
        // history.push('/hello');
        // webviewMessenger.sendSimpleMessage('resizeWebviewWindow');
        webviewMessenger.sendHideWebview();
        webviewMessenger.sendUpdatePassword(password);
    };

    useEffect(() => {
        const submitForm = (event: any) => {
            if (event.key === 'Enter' || event.keyCode === '13') {
                signInHandler(event);
            }
        };
        window.addEventListener('keydown', submitForm);
        return () => window.removeEventListener('keydown', submitForm);
    });

    return (
        <>
            <div className="intro">
                <h4>{t(`lang_auth.txt_resetPassword_success_title`)}</h4>
                <p>{t(`lang_auth.txt_reset_password_success`)}</p>
            </div>

            <form className="form" onSubmit={signInHandler}>
                <div className="profile_left">
                    <div className="profile_update_btn">
                        <CommonButton
                            isLoading={isLoading}
                            variant="contained"
                            type="submit"
                            color="primary"
                            text={t(`lang_auth.heading_btn_login`)}
                        />
                    </div>
                </div>
            </form>
        </>
    );
};
