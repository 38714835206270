import PickNamesParticipantInterface from '../../../interfaces/pick-names-participant.interface';
import CachePickNameFlipCard from '../components/CachePickNameFlipCard';

interface AutoPickRevealerInterface {
    autoPickNames: PickNamesParticipantInterface[];
    givePointsConfettiRef: any;
}

function AutoPickRevealer({ autoPickNames, givePointsConfettiRef }: AutoPickRevealerInterface) {
    return (
        <div className="pick_name_card_wrapper">
            <div className="pickNumber_card">
                {autoPickNames.map((participant, index: number) => (
                    <CachePickNameFlipCard
                        key={participant.participantId}
                        participant={participant}
                        isAutoPicking={true}
                        flipOrderIndex={index}
                        onClickHandler={() => {}}
                        givePointsConfettiRef={givePointsConfettiRef}
                    />
                ))}
            </div>
        </div>
    );
}

export default AutoPickRevealer;
