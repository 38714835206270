import { UserActionTypes } from '../constants/user-action-types';
import ErrorsInterface from '../interfaces/errors.interface';

export default function signInFailedReducer(state = noError, action: { type: string; payload: any }): ErrorsInterface {
    switch (action.type) {
        case UserActionTypes.SHOW_API_ERROR:
            return { ...state, apiError: true };
        case UserActionTypes.DISMISS_API_ERROR:
            return { ...state, apiError: false };
        default:
            return state;
    }
}

const noError: ErrorsInterface = {
    apiError: false,
    kickedOut: false,
};
