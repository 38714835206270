import VotingCount from '../../../components/Common/VotingCount';
import MultiMediaActivityResponseFooter from '../MultiMediaActivityResponseFooter';
import { AnimatePresence, motion } from 'framer-motion';
import ActivityResponseInterface from '../../../interfaces/activity-response.interface';
import { SimpleFadeAnimation } from '../../../constants/animation-configs';
import { TickLottie } from '../../../components/Common/AnimationModules';
import { stringToNumber } from '../../../helpers/utils';
import { findCenter } from '../../../helpers/animationHelpers';
import { useEffect, useRef, useState } from 'react';
import Loading from '../Loading/Loading';

interface SlideDrawingModalInterface {
    choosedResponse: ActivityResponseInterface;
    responseIndex: number;
    onResponseIndexDeleted: any;
    searchKeyword: string;
    isVoting: boolean;
    handleGivePointButtonClick: any;
    isAnimating: boolean;
    pointsAdded: number;
}

function SlideDrawingModal({
    choosedResponse,
    responseIndex,
    onResponseIndexDeleted,
    searchKeyword,
    isVoting,
    handleGivePointButtonClick,
    isAnimating,
    pointsAdded,
}: SlideDrawingModalInterface) {
    const boxSizeRef = useRef<any>();
    const [isProcessing, setIsProcessing] = useState(false);
    useEffect(() => {
        setIsProcessing(false);
    }, [choosedResponse.responseId]);

    return (
        <ul className="sky-slider__img-container slidedrawing_modal">
            <motion.li variants={SimpleFadeAnimation} initial="initial" animate="animate" className="active">
                <div className="big_sh_box">
                    {choosedResponse.voterParticipantIds && (
                        <div className="big_sh_head">
                            <VotingCount response={choosedResponse} />
                        </div>
                    )}
                    <div ref={boxSizeRef} className="big_sh_content">
                        {isAnimating && <div className="overlayAnimationGlobal show"></div>}
                        {isProcessing && <Loading />}
                        <AnimatePresence>
                            {isAnimating && (
                                <TickLottie
                                    key={choosedResponse.responseId}
                                    index={stringToNumber(choosedResponse.responseId)}
                                    xPosition={() => findCenter(boxSizeRef).x}
                                    yPosition={() => findCenter(boxSizeRef).y}
                                    scale="2"
                                />
                            )}
                        </AnimatePresence>
                        <img
                            key={choosedResponse.responseData}
                            src={choosedResponse.responseData}
                            alt={choosedResponse.responseData}
                            loading="lazy"
                            style={{
                                borderBottomLeftRadius: 4,
                                borderBottomRightRadius: 4,
                            }}
                        />
                    </div>
                    <MultiMediaActivityResponseFooter
                        response={choosedResponse}
                        responseIndex={responseIndex}
                        onResponseIndexDeleted={onResponseIndexDeleted}
                        searchKeyword={searchKeyword}
                        isVoting={isVoting}
                        useFromModal={true}
                        pointsAdded={pointsAdded}
                        onGivePointButtonClick={handleGivePointButtonClick}
                        setIsProcessing={setIsProcessing}
                    />
                </div>
            </motion.li>
        </ul>
    );
}

export default SlideDrawingModal;
