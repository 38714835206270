import { HostLogType } from './../constants/host-log-types.enum';
import { createStore, applyMiddleware, Dispatch, Middleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers/root.reducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import webviewMessenger from '../services/webviewMessenger';
import { createLogMessage } from './utils';

const reduxLogger = createLogger();

const hostLogger: Middleware = () => (next: Dispatch) => (action: any) => {
    webviewMessenger.sendHostLog(
        HostLogType.REDUX,
        `Action: ${action.type}, payload: ${createLogMessage(action.payload, 500)}`,
    );
    return next(action);
};

export const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunkMiddleware, hostLogger, reduxLogger)),
);

export type AppDispatch = typeof store.dispatch;
