import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { webviewSettingsActions } from '../../../actions/webview-settings.actions';
import { WebviewSettingsActionTypes } from '../../../constants/webview-settings-action-types';
import { findPicked } from '../../../helpers/pickNamesHelpers';
import PickNamesParticipantInterface from '../../../interfaces/pick-names-participant.interface';
import CacheWheelViewSideList from './CacheWheelViewSideList';
import { useEffect, useState } from 'react';

interface WheelViewSideListInterface {
    pickNamesList: PickNamesParticipantInterface[];
    isPickedNamesShown: boolean;
    setIsPickedNamesShown: (arg: boolean) => void;
    setIsPickedModalOpen: (arg: boolean) => void;
    setPickedParticipant: (arg: PickNamesParticipantInterface) => void;
}

const WheelViewSideList = ({
    pickNamesList,
    isPickedNamesShown,
    setIsPickedNamesShown,
    setIsPickedModalOpen,
    setPickedParticipant,
}: WheelViewSideListInterface) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [pickedNames, setPickedNames] = useState<PickNamesParticipantInterface[]>([]);

    const handlePickNamesPanelView = () => {
        dispatch(
            webviewSettingsActions.updateWebviewSettings(WebviewSettingsActionTypes.UPDATE_PICKED_NAMES_PANEL_VIEW, {
                ...{
                    isPickedNamesPanelShown: !isPickedNamesShown,
                },
            }),
        );
        setIsPickedNamesShown(!isPickedNamesShown);
    };

    useEffect(() => {
        setTimeout(() => {
            setPickedNames([...findPicked(pickNamesList)]);
        }, 400);
    }, [pickNamesList]);

    return (
        <>
            {pickedNames.length > 0 && (
                <div className={isPickedNamesShown ? 'pickwheel_right' : 'pickwheel_right close'}>
                    <div className="pick_head">
                        <Typography variant="body1">
                            {t('lang_pick_names.txt_picked_names')} ({pickedNames.length})
                        </Typography>
                    </div>

                    <div className="pickwheel_name">
                        <div className="pickname_arrow" onClick={() => handlePickNamesPanelView()}>
                            <ArrowForwardIosIcon />
                        </div>

                        <div className="select_name">
                            <Stack direction="column" spacing={1}>
                                {pickedNames.map((participant) => {
                                    return (
                                        <CacheWheelViewSideList
                                            key={participant.participantId}
                                            participant={participant}
                                            setPickedParticipant={setPickedParticipant}
                                            setIsPickedModalOpen={setIsPickedModalOpen}
                                        />
                                    );
                                })}
                            </Stack>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default WheelViewSideList;
