import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FitToViewport } from 'react-fit-to-viewport';

import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';

import './GroupView.scss';

import { isClickOutsideToCloseGroupAllowed } from '../../../../helpers/savedClassGroupHelper';
import apiSavedClasses from '../../../../services/apiSavedClasses';
import { localService } from '../../../../services/localStorageService';
import { classSessionActions } from '../../../../actions/class-session.action';

import ParticipantAndPointsDto from '../../../../dtos/participant-and-points.dto';
import { UpdateToolbarActionsType } from '../../../../dtos/update-toolbar-actions.dto';
import SavedClassParticipantInterface, {
    OrderProperties,
    Orders,
} from '../../../../interfaces/saved-class-participant.interface';
import {
    CreateOrEditGroupInterface,
    GroupStageType,
    SavedClassGroupInterface,
} from '../../../../interfaces/savedClassGroup.interface';
import ParticipantInterface from '../../../../interfaces/participant.interface';
import { ViewportConstants, ViewportSavedClasses } from '../../../../constants/viewport-constants';
import { AnimationConfig } from '../../../../constants/animation-configs';

import CommonAlert from '../../../../components/Common/CommonAlert';
import DeleteAlert from '../alert/DeleteAlert';
import GroupModalHeader from '../groupView/GroupModalHeader';
import GroupCards from '../groupView/GroupCards';
import GroupDetails from '../groupView/GroupDetails';
import EditGroup from '../groupView/EditGroup';
import GroupMembers from '../groupView/GroupMembers';
import GroupModalFooter from '../groupView/GroupModalFooter';
import AutoCreateGroup from '../groupView/AutoCreateGroup';
import GroupPointsModal from '../groupView/GroupPointsModal';
import { FullScreenPointLottie } from '../../../../components/Common/AnimationModules';
import { isUserOnPremium } from '../../../../helpers/userhelper';
import { getValidUserFromStore } from '../../../../helpers/storeHelpers';
import { utilConstants } from '../../../../constants/utils.constants';
import webviewMessenger from '../../../../services/webviewMessenger';

interface GroupViewInterface {
    sortedParticipants: SavedClassParticipantInterface[];
    participantsInMyClass?: ParticipantInterface[];
    savedClassId: string;
    savedClassGroups: SavedClassGroupInterface[];
    refreshSavedClass: (actionType?: string, groupId?: number) => void;
    orderBy: OrderProperties;
    setOrderBy: (arg: OrderProperties) => void;
    order: Orders;
    setOrder: (arg: Orders) => void;
    isViewingInMyClass?: boolean;
}

export default function GroupView({
    sortedParticipants,
    participantsInMyClass,
    savedClassId,
    savedClassGroups,
    refreshSavedClass,
    orderBy,
    setOrderBy,
    order,
    setOrder,
    isViewingInMyClass,
}: GroupViewInterface) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = getValidUserFromStore('SavedClassParticipants');

    const givePointsConfettiRef = useRef<any>();
    const timeOutPointUpdate = useRef<any>();

    const [isAnimating, setIsAnimating] = useState(false);
    const [pointsAdded, setPointsAdded] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isAddOrEditModalOpen, setIsAddOrEditModalOpen] = useState(false);
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);
    const [groupStage, setGroupStage] = useState<GroupStageType>('');
    const [isEditMode, setIsEditMode] = useState(false);
    const [isAutoGroupModalOpen, setIsAutoGroupModalOpen] = useState(false);
    const [currentGroupDetails, setCurrentGroupDetails] = useState<CreateOrEditGroupInterface>({
        groupId: -1,
        groupName: '',
        groupColor: '',
        participantIds: [],
    });
    const [error, setError] = useState('');

    const isPremiumUser = isUserOnPremium(user);
    const premiumUrl = `${process.env.REACT_APP_CPWEBSITE}/schools-districts`;

    const selectedParticipantsFromMyClass = participantsInMyClass?.filter(
        (participant) => participant.groupId === currentGroupDetails.groupId,
    );
    const selectedParticipants = sortedParticipants.filter((participant) =>
        currentGroupDetails.participantIds.includes(participant.participantId),
    );
    const ungroupedParticipantsCount = sortedParticipants.filter(
        (participant) => !participant.groupId && participant.groupId !== 0,
    ).length;

    const toggleEditMode = (value: boolean) => setIsEditMode(value);
    const groupStageHandler = (value: GroupStageType) => setGroupStage(value);
    const errorHandler = (value: string) => setError(value);
    const toggleDeleteWarningModal = (value: boolean) => setShowDeleteWarning(value);
    const toggleAddOrEditModal = (value: boolean) => setIsAddOrEditModalOpen(value);

    const groupDetailsHandler = (value: string, fieldName: keyof CreateOrEditGroupInterface) => {
        errorHandler('');

        if (fieldName === 'participantIds') {
            let updatedMembers = [...currentGroupDetails.participantIds];
            if (updatedMembers.includes(value)) {
                updatedMembers = updatedMembers.filter((member) => member !== value);
            } else updatedMembers.push(value);
            return setCurrentGroupDetails((prev) => ({ ...prev, [fieldName]: updatedMembers }));
        }

        if (!isEditMode) return setCurrentGroupDetails((prev) => ({ ...prev, [fieldName]: value }));
        setCurrentGroupDetails((prev) => ({ ...prev, [`${fieldName}Edited`]: value }));
    };

    const createGroupHandler = async () => {
        setIsLoading(true);
        const result = await apiSavedClasses.addGroup(savedClassId, {
            groupName: currentGroupDetails.groupName,
            groupColor: currentGroupDetails.groupColor,
            participantIds: currentGroupDetails.participantIds,
        });
        if (result) {
            closeAddOrEditModalHandler();
            refreshSavedClass();
        }
        setIsLoading(false);
    };

    const updateGroupDetails = async () => {
        setIsLoading(true);
        const result = await apiSavedClasses.editGroup(savedClassId, currentGroupDetails.groupId, {
            groupName: currentGroupDetails.groupNameEdited || currentGroupDetails.groupName,
            groupColor: currentGroupDetails.groupColorEdited || currentGroupDetails.groupColor,
        });

        if (result) {
            setCurrentGroupDetails((prev) => ({
                ...prev,
                groupName: prev.groupNameEdited || prev.groupName,
                groupColor: prev.groupColorEdited || prev.groupColor,
            }));
            groupStageHandler(isViewingInMyClass ? 'point' : 'view');
            refreshSavedClass();
        }
        setIsLoading(false);
    };

    const updateGroupMembers = async () => {
        setIsLoading(true);
        const addedParticipantIds = currentGroupDetails.participantIds.filter(
            (id) => !currentGroupDetails.initialParticipantIds?.includes(id),
        );
        const removedParticipantIds = currentGroupDetails.initialParticipantIds?.filter(
            (id) => !currentGroupDetails.participantIds.includes(id),
        );
        const result = await apiSavedClasses.editGroup(savedClassId, currentGroupDetails.groupId, {
            addParticipantIds: addedParticipantIds,
            removeParticipantIds: removedParticipantIds,
        });

        if (result) {
            setCurrentGroupDetails((prev) => ({
                ...prev,
                participantIds: prev.participantIds,
                initialParticipantIds: prev.participantIds,
            }));
            groupStageHandler(isViewingInMyClass ? 'point' : 'view');
            refreshSavedClass();
        }
        setIsLoading(false);
    };

    const editGroupClickHandler = (group: SavedClassGroupInterface) => {
        groupStageHandler(isViewingInMyClass ? 'point' : 'view');
        setIsAddOrEditModalOpen(true);
        const groupParticipantsId = sortedParticipants
            .filter((participant) => participant.groupId === group.groupId)
            .map((participant) => participant.participantId);
        setCurrentGroupDetails({
            groupId: group.groupId,
            groupName: group.groupName,
            groupNameEdited: group.groupName,
            groupColor: group.groupColor,
            groupColorEdited: group.groupColor,
            participantIds: groupParticipantsId,
            initialParticipantIds: groupParticipantsId,
        });
    };

    const deleteGroupHandler = async () => {
        setIsLoading(true);
        const result = await apiSavedClasses.deleteGroup(savedClassId, currentGroupDetails.groupId);
        if (result) {
            toggleDeleteWarningModal(false);
            closeAddOrEditModalHandler();
            refreshSavedClass('delete', currentGroupDetails.groupId);
        }
        setIsLoading(false);
    };

    const closeAddOrEditModalHandler = () => {
        groupStageHandler('');
        errorHandler('');
        toggleEditMode(false);
        setCurrentGroupDetails({
            groupId: -1,
            groupName: '',
            groupColor: '',
            participantIds: [],
        });
        setIsAddOrEditModalOpen(false);
    };

    const givePointToAll = (points: number) => {
        if (!selectedParticipantsFromMyClass) return;
        const participantsAndPoints: ParticipantAndPointsDto[] = selectedParticipantsFromMyClass.map(
            (participant: ParticipantInterface) => {
                return {
                    participantId: participant.participantId,
                    points: points,
                };
            },
        );
        localService.updateToolbarActionsFromWebview(UpdateToolbarActionsType.awardStar);
        dispatch(classSessionActions.givePointsToParticipants(participantsAndPoints));
    };

    const givePointHandler = () => {
        if (!selectedParticipantsFromMyClass) return;
        clearTimeout(timeOutPointUpdate.current);
        setIsAnimating(true);
        givePointsConfettiRef.current.playLottieAnimation(true);
        setPointsAdded(pointsAdded + 1);
    };

    useEffect(() => {
        if (pointsAdded > 0) {
            timeOutPointUpdate.current = setTimeout(() => {
                givePointToAll(pointsAdded);
                setPointsAdded(0);
                setTimeout(() => {
                    setIsAnimating(false);
                    if (givePointsConfettiRef.current) givePointsConfettiRef.current.playLottieAnimation(false);
                }, AnimationConfig.CONFETTI_LOTTIE_DURATION - AnimationConfig.GIVE_POINT_DELAY);
            }, AnimationConfig.GIVE_POINT_DELAY);
        }
        return () => clearTimeout(timeOutPointUpdate.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pointsAdded]);

    useEffect(() => {
        return () => setIsAnimating(false);
    }, []);

    return !isPremiumUser ? (
        <div className={`modalContent`}>
            <div className={`imageContainer`}>
                <img
                    src={utilConstants.IMAGE_URLS.TEMPLATE_LIBRARY_PRO_MODAL}
                    className={`image`}
                    alt="ClassPoint pro"
                />
            </div>
            <p className={`text`}>{t('lang_saved_classes.saved_class_group_checkout_premium')}</p>
            <Button
                onClick={() => {
                    webviewMessenger.sendOpenBrowser(premiumUrl);
                    webviewMessenger.sendUsageLog(`[C] Clicked "Check out Premium in saved class library"`);
                }}
                className={`upgradeToProBtn`}
            >
                {t('lang_common.btn_learn_more')}
            </Button>
        </div>
    ) : (
        <div className={`savedClassGroupView ${isViewingInMyClass && 'myClassView'}`}>
            {savedClassGroups.length <= 0 && <p className={`noGroupText`}>{t('lang_grouping.no_group')}</p>}

            <GroupCards
                isViewingInMyClass={isViewingInMyClass}
                savedClassGroups={savedClassGroups}
                sortedParticipants={sortedParticipants}
                participantsInMyClass={participantsInMyClass}
                groupStageHandler={groupStageHandler}
                editGroupClickHandler={editGroupClickHandler}
                toggleAddOrEditModal={toggleAddOrEditModal}
            />

            {savedClassGroups.length < 1 && (
                <Button onClick={() => setIsAutoGroupModalOpen(true)} className={`autoGroupBtn`}>
                    <AutoFixHighOutlinedIcon className={`icon`} />
                    {t('lang_grouping.automatic_grouping')}
                </Button>
            )}

            {ungroupedParticipantsCount > 0 && savedClassGroups.length > 0 && (
                <p className={`ungroupedParticipantCount`}>
                    {t('lang_grouping.ungrouped_participants_count', {
                        ungroupedParticipantsCount: ungroupedParticipantsCount,
                    })}
                </p>
            )}

            <Modal
                open={isAddOrEditModalOpen}
                onClose={() => isViewingInMyClass && !isAnimating && closeAddOrEditModalHandler()}
                className="addOrEditGroupModal"
            >
                <>
                    <FullScreenPointLottie ref={givePointsConfettiRef} />
                    <FitToViewport
                        className={`modal_viewport`}
                        width={isViewingInMyClass ? ViewportConstants.width : ViewportSavedClasses.width}
                        height={isViewingInMyClass ? ViewportConstants.height : ViewportSavedClasses.height}
                        minZoom={isViewingInMyClass ? ViewportConstants.minZoom : ViewportSavedClasses.minZoom}
                        maxZoom={isViewingInMyClass ? ViewportConstants.maxZoom : ViewportSavedClasses.maxZoom}
                    >
                        <div
                            onClick={() =>
                                isClickOutsideToCloseGroupAllowed(
                                    groupStage,
                                    currentGroupDetails.groupId > -1,
                                    isViewingInMyClass,
                                ) &&
                                !isAnimating &&
                                closeAddOrEditModalHandler()
                            }
                            className={`bgOverlay`}
                        ></div>

                        <div className={`modalContent`}>
                            <GroupModalHeader
                                isViewingInMyClass={isViewingInMyClass}
                                isAnimating={isAnimating}
                                isEditMode={isEditMode}
                                toggleEditMode={toggleEditMode}
                                groupStage={groupStage}
                                groupStageHandler={groupStageHandler}
                                currentGroupDetails={currentGroupDetails}
                                setCurrentGroupDetails={setCurrentGroupDetails}
                                selectedParticipants={selectedParticipants}
                                selectedParticipantsFromMyClass={selectedParticipantsFromMyClass}
                                closeAddOrEditModalHandler={closeAddOrEditModalHandler}
                            />

                            <div className={`body ${groupStage === 'point' && 'showOverflow'}`}>
                                {groupStage === 'group' && (
                                    <GroupDetails
                                        isEditMode={isEditMode}
                                        currentGroupDetails={currentGroupDetails}
                                        groupDetailsHandler={groupDetailsHandler}
                                        savedClassGroups={savedClassGroups}
                                    />
                                )}
                                {groupStage === 'member' && (
                                    <GroupMembers
                                        isEditMode={isEditMode}
                                        sortedParticipants={sortedParticipants}
                                        currentGroupDetails={currentGroupDetails}
                                        groupDetailsHandler={groupDetailsHandler}
                                        savedClassGroups={savedClassGroups}
                                    />
                                )}
                                {groupStage === 'view' && (
                                    <EditGroup
                                        selectedParticipants={selectedParticipants}
                                        savedClassGroups={savedClassGroups}
                                        order={order}
                                        setOrder={setOrder}
                                        orderBy={orderBy}
                                        setOrderBy={setOrderBy}
                                    />
                                )}
                                {groupStage === 'point' && selectedParticipantsFromMyClass && (
                                    <GroupPointsModal
                                        selectedGroupParticipants={selectedParticipantsFromMyClass}
                                        savedClassGroups={savedClassGroups}
                                    />
                                )}

                                {error && <p className={`errorText`}>{error}</p>}
                            </div>

                            <GroupModalFooter
                                isLoading={isLoading}
                                groupStage={groupStage}
                                groupStageHandler={groupStageHandler}
                                isEditMode={isEditMode}
                                toggleEditMode={toggleEditMode}
                                savedClassGroups={savedClassGroups}
                                currentGroupDetails={currentGroupDetails}
                                setCurrentGroupDetails={setCurrentGroupDetails}
                                createGroupHandler={createGroupHandler}
                                updateGroupDetails={updateGroupDetails}
                                updateGroupMembers={updateGroupMembers}
                                errorHandler={errorHandler}
                                toggleDeleteWarningModal={toggleDeleteWarningModal}
                                givePointHandler={givePointHandler}
                            />
                        </div>
                    </FitToViewport>
                </>
            </Modal>

            {isAutoGroupModalOpen && (
                <AutoCreateGroup
                    savedClassId={savedClassId}
                    sortedParticipants={sortedParticipants}
                    refreshSavedClass={refreshSavedClass}
                    closeAutoGroupModalHandler={() => setIsAutoGroupModalOpen(false)}
                />
            )}

            <CommonAlert
                showAlert={showDeleteWarning}
                content={
                    <DeleteAlert
                        onConfirmDeleteClass={() => {
                            toggleDeleteWarningModal(false);
                            deleteGroupHandler();
                        }}
                        onCloseAlert={() => toggleDeleteWarningModal(false)}
                        content={t('lang_grouping.delete_group_warning', {
                            groupName: currentGroupDetails.groupName,
                        })}
                        deleteType={'group'}
                        highlightWarning={true}
                    />
                }
                viewportConfig={isViewingInMyClass ? ViewportConstants : ViewportSavedClasses}
                customModalClassNames="small"
            />
        </div>
    );
}
