import { Cropper } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { hasAlpha } from '../../helpers/utils';

export default function ImageCropper({ image, setref, savedCrop, cropCursor, setCropCursor, setCropData }: any) {
    const previewCanvasRefp = setref;

    const onCrop = () => {
        const imageElement: any = previewCanvasRefp?.current;
        const cropper: any = imageElement?.cropper;
        const canvas1: any = document.createElement('canvas');
        const canvas: any = cropper.getCroppedCanvas();
        let ctx: any = canvas.getContext('2d');

        let isImageTransparent = hasAlpha(ctx, canvas);
        let dataURL = '';
        if (!isImageTransparent) {
            dataURL = canvas.toDataURL();
            setCropData(cropper.getCroppedCanvas().toDataURL());
        } else {
            canvas1.width = canvas.width;
            canvas1.height = canvas.height;
            let ctx1: any = canvas1.getContext('2d');
            ctx1.fillStyle = 'black';
            ctx1.fillRect(0, 0, canvas.width, canvas.height);

            let canvas3: any = document.createElement('canvas');
            let ctx3 = canvas3.getContext('2d');
            canvas3.width = canvas.width;
            canvas3.height = canvas.height;
            ctx3.drawImage(canvas1, 0, 0);
            ctx3.drawImage(canvas, 0, 0);
            dataURL = canvas3.toDataURL();
            setCropData(dataURL);
        }

        setCropCursor(cropper.getCropBoxData());
    };

    return (
        <div className="App" style={{ width: '400px', height: '280px' }}>
            <Cropper
                style={{ height: '100%', width: '100%' }}
                zoomTo={0.5}
                className="crop_center"
                ref={previewCanvasRefp}
                crop={onCrop}
                initialAspectRatio={1}
                aspectRatio={4 / 4}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                ready={() => {
                    const imageElement: any = previewCanvasRefp?.current;
                    const cropper: any = imageElement?.cropper;
                    if (savedCrop) {
                        const cropData = JSON.parse(savedCrop);
                        cropper.setCropBoxData({
                            left: cropData.left,
                            top: cropData.top,
                            width: cropData.width,
                            height: cropData.height,
                        });
                    }
                    // else {
                    //     cropper.setCropBoxData({
                    //         left: cropper.imageData.left,
                    //         top: cropper.imageData.top,
                    //         width: cropper.imageData.width,
                    //         height: cropper.imageData.height,
                    //     });
                    // }
                }}
                onInitialized={() => {
                    const imageElement: any = previewCanvasRefp?.current;
                    const cropper: any = imageElement?.cropper;
                    if (savedCrop) {
                        const cropData = JSON.parse(savedCrop);
                        cropper.setCropBoxData({
                            left: cropData.left,
                            top: cropData.top,
                            width: cropData.width,
                            height: cropData.height,
                        });
                    } else {
                        cropper.setAspectRatio(1);
                    }
                }}
                guides={false}
                zoomOnTouch={false}
                scalable={false}
                movable={false}
                zoomable={false}
                // cropBoxMovable={false}
                // cropBoxResizable={false}
                dragMode="move"
                rotatable={false}
            />
        </div>
    );
}
