import { Fragment, useEffect, useState } from 'react';
import { FitToViewport } from 'react-fit-to-viewport';
import { ViewportExportPdf } from '../../constants/viewport-constants';
import EmptyModal from '../EmptyModal/EmptyModal';
import Generatepdf from './Components/Generatepdf';
import GeneratingPdf from './Components/GeneratingPdf';
import QrCodeGenerated from './Components/QrCodeGenerated';
import './SharePdf.scss';

const SharePdf = () => {
    const [activePage, setActivePage] = useState('EntryPage');
    const [showEmptyModal, setShowEmptyModal] = useState(false);

    useEffect(() => {
        setShowEmptyModal(true);
        setTimeout(() => {
            setShowEmptyModal(false);
        }, 1);
    }, []);

    const activePageHandler = (pageData: string) => {
        setActivePage(pageData);
    };

    let activeComponents;
    switch (activePage) {
        case 'EntryPage':
            activeComponents = <Generatepdf activePageHandler={activePageHandler} />;
            break;
        case 'loadingPage':
            activeComponents = <GeneratingPdf activePageHandler={activePageHandler} />;
            break;
        case 'qrcodePage':
            activeComponents = <QrCodeGenerated activePageHandler={activePageHandler} />;
            break;
        default:
            activeComponents = <Generatepdf activePageHandler={activePageHandler} />;
    }

    return (
        <Fragment>
            <FitToViewport
                className="viewport"
                width={ViewportExportPdf.width}
                height={ViewportExportPdf.height}
                minZoom={ViewportExportPdf.minZoom}
                maxZoom={ViewportExportPdf.maxZoom}
            >
                {showEmptyModal && <EmptyModal viewportConfig={ViewportExportPdf} />}
                <div className="sharepdf_sec">{activeComponents}</div>
            </FitToViewport>
        </Fragment>
    );
};

export default SharePdf;
