export enum CurrentPageType {
    SignIn,
    SignUp,
    ForgotPassword,
}

export enum SignUpStepType {
    ProfileInfo,
    Interest,
    Success,
}

export enum SignUpStatus {
    BEFORE_SUBMIT,
    SIGNING_UP,
    SIGNED_UP,
}

export enum ForgotPasswordStepType {
    Email,
    VerificationCode,
    Password,
    Success,
}

export interface SignUpUserInterface {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    leadSource: string;
    jobRole: string;
    phone: string;
    phoneCode: string;
    interests: string[];
    ssoType?: string;
    organization: string;
    signupOs?: string;
    signupDevice?: string;
    location?: {
        countryCode: string;
        country: string;
        city: string | null;
    };
}

export interface ResetPasswordInterface {
    email: string;
    userADId: string;
    password: string;
}
