import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Button, Link, Stack, TextField, Typography } from '@mui/material';
import Masonry from '@mui/lab/Masonry';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { getValidUserFromStore } from '../../helpers/storeHelpers';
import { userActions } from '../../actions/user.actions';
import { UserActionTypes } from '../../constants/user-action-types';
import CommonAlert from '../../components/Common/CommonAlert';
import DeleteAlert from './components/DeleteAlert';
import { webviewSettingsActions } from '../../actions/webview-settings.actions';
import { WebviewSettingsActionTypes } from '../../constants/webview-settings-action-types';
import ImageUpdateModal from './components/ImageUpdateModal';
import StoreInterface from '../../interfaces/store.interface';
import { whiteBoardConstants } from '../../constants/viewport-constants';
import apiPreferences from '../../services/apiPreferences';

const CustomWhiteboardList = ({ imageData, shadowHandler }: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = getValidUserFromStore('CustomWhiteboardList');
    const webviewSettingsEditMode = useSelector((state: StoreInterface) => state.webviewSettingsEditMode);
    const [whiteBoardData, setWhiteBoardData] = React.useState([...imageData]);
    const [selectedImage, setSelectedImage] = React.useState<{ id: string; name: string; url: string }>();
    const [isShowDeleteWarning, setIsShowDeleteWarning] = React.useState(false);
    const [isDontRemindChecked, setIsDontRemindChecked] = React.useState(false);
    const [deleteOneData, setDeleteOneData] = React.useState<{ id: string; index: number }>();
    const [isClickingNearBottom, setIsClickingNearBottom] = useState(false);
    const [isImageUpdateModalOpen, setIsImageUpdateModalOpen] = React.useState(false);
    const inputEl = useRef<any>([]);

    const handleMenuClick = async (index: number, e?: any) => {
        if (e) {
            let tableDiv: any = document.getElementById('inner_whiteboard_img_scroll');
            let currentTableY = e.clientY - 95;
            if (currentTableY >= (tableDiv.clientHeight * 70) / 100) {
                setIsClickingNearBottom(true);
            } else {
                setIsClickingNearBottom(false);
            }
        }
        let newArray = [...whiteBoardData];
        if (!newArray[index].dropDown) {
            newArray[index].dropDown = 'active';
        } else {
            newArray[index].dropDown = '';
        }
        setWhiteBoardData(newArray);
    };

    const onDeleteSingle = async (id: string, index: number, e: any) => {
        handleMenuClick(index);
        const updatedUser = await apiPreferences.deleteWhiteboard(user.email, id);
        if (!updatedUser) {
            dispatch(userActions.showApiError());
            return;
        }
        dispatch(userActions.updateUser(UserActionTypes.USER_PROFILE_EDITED, updatedUser));
    };

    const handleInputFocus = (index: number, targetElem: any) => {
        const newArray = [...whiteBoardData];
        newArray[index].isTextInputFocus = true;
        newArray[index].dropDown = '';
        setWhiteBoardData(newArray);
        setTimeout(() => {
            const refId: any = document.getElementById(`standard-basic${index}`);
            refId.focus();
            refId.select();
        }, 10);
    };

    const textChangesHandler = (e: any, index: number) => {
        let newArray = [...whiteBoardData];
        newArray[index].name = e.target.value;
        setWhiteBoardData(newArray);
    };

    const handleKeyUp = async (e: any, index: number, id: string) => {
        if (e.key === 'Enter') {
            renameHandler(index, id);
        }
    };

    const renameHandler = async (index: number, id: string) => {
        let newArray = [...whiteBoardData];
        newArray[index].isTextInputFocus = false;
        setWhiteBoardData(newArray);
        if (newArray[index].name.trim() === imageData[index].name.trim()) return;

        const updatedUser = await apiPreferences.editWhiteboard(
            user.email,
            id,
            newArray[index].name.trim(),
            newArray[index].url,
        );
        if (!updatedUser) {
            dispatch(userActions.showApiError());
            return;
        }
        dispatch(userActions.updateUser(UserActionTypes.UPDATED_WHITEBOARD, updatedUser));
    };

    const imageUpdateHandler = (id: string, name: string, index: number, url: string, e: any) => {
        handleMenuClick(index, e);
        setSelectedImage({ id, name, url });
        setIsImageUpdateModalOpen(true);
    };

    const handleCloseDeleteWhiteboardWarning = (index: number) => {
        setIsShowDeleteWarning(false);
        handleMenuClick(index);
    };

    useEffect(() => {
        let arrayData: any = [];
        imageData.forEach((item: any) => {
            arrayData.push({
                ...item,
                dropDown: '',
                isTextInputFocus: false,
            });
        });
        setWhiteBoardData(arrayData);
    }, [imageData]);

    return (
        <Fragment>
            {selectedImage && (
                <ImageUpdateModal
                    imageUpdateOpen={isImageUpdateModalOpen}
                    imageUpdateCloseHandler={() => setIsImageUpdateModalOpen(false)}
                    selectedImage={selectedImage}
                />
            )}

            {deleteOneData && (
                <CommonAlert
                    showAlert={isShowDeleteWarning}
                    closeAlert={() => handleCloseDeleteWhiteboardWarning(deleteOneData.index)}
                    viewportConfig={whiteBoardConstants}
                    content={
                        <DeleteAlert
                            confirmDelete={(e: any) => {
                                onDeleteSingle(deleteOneData.id, deleteOneData.index, e);
                                setIsShowDeleteWarning(false);
                                dispatch(
                                    webviewSettingsActions.updateWebviewSettings(
                                        WebviewSettingsActionTypes.WHITE_BOARD_DELETE_ALERT,
                                        {
                                            ...{
                                                isWhiteBoardDeleteAlert: !isDontRemindChecked,
                                            },
                                        },
                                    ),
                                );
                            }}
                            closeAlert={() => handleCloseDeleteWhiteboardWarning(deleteOneData.index)}
                            toggleShowWhiteBoardDeleteAlert={setIsDontRemindChecked}
                        />
                    }
                />
            )}

            <div className="whiteboard_Image_sec">
                <div className="whiteboardContainer">
                    <div onScroll={shadowHandler} className="inner_whiteboard_img" id="inner_whiteboard_img_scroll">
                        <Box sx={{ width: 735, minHeight: 355 }}>
                            <Masonry columns={3} spacing={2}>
                                {whiteBoardData.map((item: any, index: any) => (
                                    <Stack key={index.toString()} className="images_boxs">
                                        <div className="big_sh_body" />
                                        <img src={item.url} alt="boardImg" />
                                        <div className="sh_footer ">
                                            <div className="profile_foot">
                                                {!item.isTextInputFocus ? (
                                                    <Typography variant="body1">{item.name}</Typography>
                                                ) : (
                                                    <TextField
                                                        inputProps={{ maxLength: 20 }}
                                                        inputRef={(el) => (inputEl.current[index] = el)}
                                                        onKeyUp={(e) => handleKeyUp(e, index, item.id)}
                                                        onBlur={() => {
                                                            renameHandler(index, item.id);
                                                        }}
                                                        onChange={(e: any) => {
                                                            textChangesHandler(e, index);
                                                        }}
                                                        value={item.name}
                                                        id={`standard-basic${index}`}
                                                        label=""
                                                        variant="standard"
                                                    />
                                                )}
                                            </div>
                                            <div className="foot_icon">
                                                <ul>
                                                    <li></li>
                                                    <li>
                                                        <Button
                                                            id="basic-button"
                                                            aria-haspopup="true"
                                                            onClick={(e) => handleMenuClick(index, e)}
                                                        >
                                                            <MoreVertIcon />
                                                        </Button>
                                                        <div className="menu_modal">
                                                            <div
                                                                className={`menu_overlay ${item.dropDown}`}
                                                                onClick={(e) => handleMenuClick(index, e)}
                                                            ></div>
                                                            <div
                                                                className={`menu ${item.dropDown}`}
                                                                style={
                                                                    isClickingNearBottom
                                                                        ? {
                                                                              top: '-115px',
                                                                          }
                                                                        : {
                                                                              top: '30px',
                                                                          }
                                                                }
                                                            >
                                                                <ul>
                                                                    <li>
                                                                        <Link
                                                                            onClick={(e) => {
                                                                                handleInputFocus(
                                                                                    index,
                                                                                    inputEl.current[index],
                                                                                );
                                                                            }}
                                                                        >
                                                                            {t('lang_common.btn_rename')}
                                                                        </Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link
                                                                            onClick={(e) => {
                                                                                imageUpdateHandler(
                                                                                    item.id,
                                                                                    item.name,
                                                                                    index,
                                                                                    item.url,
                                                                                    e,
                                                                                );
                                                                            }}
                                                                        >
                                                                            {t('lang_common.btn_change_image')}
                                                                        </Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link
                                                                            onClick={(e) => {
                                                                                if (
                                                                                    webviewSettingsEditMode.isWhiteBoardDeleteAlert
                                                                                ) {
                                                                                    setDeleteOneData({
                                                                                        id: item.id,
                                                                                        index: index,
                                                                                    });
                                                                                    setIsShowDeleteWarning(true);
                                                                                } else {
                                                                                    onDeleteSingle(item.id, index, e);
                                                                                }
                                                                            }}
                                                                        >
                                                                            {t('lang_common.btn_delete')}
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Stack>
                                ))}
                            </Masonry>
                        </Box>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default CustomWhiteboardList;
