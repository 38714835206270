// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#apply_promotions
// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm

// Google Analytics list of recommended events
// https://support.google.com/analytics/answer/9267735?hl=en

import TagManager, { TagManagerArgs } from 'react-gtm-module';

const gtmId = process.env.REACT_APP_GTM_ID || '';
const tagManagerArgs: TagManagerArgs = { gtmId };

export const GtmService = {
    initialize: function () {
        TagManager.initialize(tagManagerArgs);
    },

    sendSignUpEvent: (userId: string, signupMethod: string) => {
        TagManager.dataLayer({
            dataLayer: {
                user_id: userId,
                event: 'sign_up',
                method: signupMethod,
            },
        });
    },

    sendFirstLoginEvent: (userId: string, loginMethod: string) => {
        TagManager.dataLayer({
            dataLayer: {
                user_id: userId,
                event: 'first_login',
                method: loginMethod,
            },
        });
    },

    sendLoginEvent: (userId: string, loginMethod: string) => {
        TagManager.dataLayer({
            dataLayer: {
                user_id: userId,
                event: 'login',
                method: loginMethod,
            },
        });
    },

    sendStartSlideshowEvent: (userId: string) => {
        TagManager.dataLayer({
            dataLayer: {
                user_id: userId,
                event: 'start_slideshow',
                method: '',
            },
        });
    },
};
