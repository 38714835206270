import { useEffect, useState, forwardRef } from 'react';
import { FitToViewport } from 'react-fit-to-viewport';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { userActions } from '../../../../actions/user.actions';
import apiSavedClasses from '../../../../services/apiSavedClasses';
import { getValidUserFromStore } from '../../../../helpers/storeHelpers';
import SavedClassForUserInterface from '../../../../interfaces/saved-class-for-user.interface';
import { ViewportSavedClasses } from '../../../../constants/viewport-constants';

import CommonButton from '../../../../components/Common/CommonButton';
import { validateSavedClassCode, validateSavedClassName } from '../../savedClassHelpers';

interface EditSavedClassModalInterface {
    closeAlert: () => void;
    savedClass: SavedClassForUserInterface;
    setSavedClass: (arg: SavedClassForUserInterface) => void;
}

const EditSavedClassModal = ({ closeAlert, savedClass, setSavedClass }: EditSavedClassModalInterface, ref: any) => {
    const user = getValidUserFromStore('EditSavedClassModal');
    const savedClasses = user.userProfile.savedClasses || [];
    const [isSaving, setIsSaving] = useState(false);
    const [className, setClassName] = useState('');
    const [classCode, setClassCode] = useState('');
    const [classNameError, setClassNameError] = useState('');
    const [classCodeError, setClassCodeError] = useState('');

    useEffect(() => {
        setClassName(savedClass.savedClassName);
        setClassCode(savedClass.savedClassCode);
    }, [savedClass]);

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const handleSetClassName = (value: string) => {
        setClassName(value);
        setClassNameError('');
    };

    const handleSetClassCode = (value: string) => {
        setClassCode(value.trim().toUpperCase());
        setClassCodeError('');
    };

    const validateClass = async () => {
        setIsSaving(true);

        const classNameError = validateSavedClassName(
            className.trim(),
            savedClasses.filter((c) => c.savedClassId !== savedClass.savedClassId).map((c) => c.savedClassName),
        );
        const classCodeError = validateSavedClassCode(classCode.trim());

        if (classNameError) {
            setIsSaving(false);
            setClassNameError(t(`lang_saved_classes.${classNameError}`));
            return;
        }
        if (classCodeError) {
            setIsSaving(false);
            setClassCodeError(t(`lang_saved_classes.${classCodeError}`));
            return;
        }

        const updatedSavedClass = await apiSavedClasses.editSavedClassNameCodeAvatar(savedClass.savedClassId, {
            savedClassName: className,
            savedClassCode: classCode,
        });

        if (!updatedSavedClass) {
            setIsSaving(false);
            closeAlert();
            dispatch(userActions.showApiError());
            return;
        }
        if (typeof updatedSavedClass === 'number') {
            setIsSaving(false);
            setClassCodeError(t('lang_saved_classes.err_class_code_taken'));
            return;
        }

        setSavedClass({
            ...savedClass,
            savedClassName: updatedSavedClass.savedClassName,
            savedClassCode: updatedSavedClass.savedClassCode,
        });
        dispatch(userActions.checkAndUpdateUser());
        setIsSaving(false);
        closeAlert();
    };

    return (
        <Box>
            <FitToViewport
                className="modal_viewport"
                width={ViewportSavedClasses.width}
                height={ViewportSavedClasses.height}
                minZoom={ViewportSavedClasses.minZoom}
                maxZoom={ViewportSavedClasses.maxZoom}
            >
                <div
                    className="close_modal"
                    onClick={() => {
                        closeAlert();
                    }}
                ></div>
                <Box className="csvmodal_box wdth_400">
                    <div
                        className="modal_head"
                        onClick={() => {
                            closeAlert();
                        }}
                    >
                        <CloseIcon />
                    </div>

                    <div className="addparticipent_modal_sec">
                        <Typography variant="body1">{t('lang_saved_classes.btn_edit_class')}</Typography>
                        <div className="participent_input">
                            <div className="text_field">
                                <TextField
                                    autoComplete="none"
                                    inputProps={{ maxLength: 30 }}
                                    id="standard-helperText"
                                    label={t('lang_common.txt_class_name')}
                                    variant="standard"
                                    value={className}
                                    error={classNameError ? true : false}
                                    helperText={classNameError ? classNameError : ''}
                                    onChange={(e) => handleSetClassName(e.target.value)}
                                />
                            </div>
                            <div className="text_field">
                                <TextField
                                    id="standard-helperText"
                                    label={t('lang_common.txt_class_code')}
                                    autoComplete="none"
                                    inputProps={{ maxLength: 8 }}
                                    variant="standard"
                                    value={classCode}
                                    error={classCodeError ? true : false}
                                    helperText={classCodeError ? classCodeError : ''}
                                    onChange={(e) => {
                                        // handleSetClassCode(e.target.value.replace(/[^\w\s]/gi, ''));
                                        const regex = /^[a-z0-9]+$/i;
                                        if (!e.currentTarget.value) return handleSetClassCode('');

                                        // Split the input into an array and check each character
                                        const chars = e.currentTarget.value.split('');
                                        const filtered = chars.filter((char) => regex.test(char));
                                        // Update the value of the input with only alphanumeric characters
                                        handleSetClassCode(filtered.join('').toUpperCase());
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="button_sec justify_center">
                        {isSaving ? (
                            <CommonButton
                                isLoading={isSaving}
                                variant="contained"
                                type="button"
                                text={t('lang_common.btn_updating')}
                            />
                        ) : (
                            <CommonButton
                                isLoading={isSaving}
                                variant="contained"
                                type="button"
                                clickFunction={() => validateClass()}
                                text={t('lang_common.btn_save')}
                            />
                        )}
                    </div>
                </Box>
            </FitToViewport>
        </Box>
    );
};

export default forwardRef(EditSavedClassModal);
