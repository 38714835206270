import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography, Button } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { SortBy } from '../../constants/sortby-types';
import { webviewSettingsActions } from '../../actions/webview-settings.actions';
import { WebviewSettingsActionTypes } from '../../constants/webview-settings-action-types';
import { SortedBy } from './Icon';
import StoreInterface from '../../interfaces/store.interface';
import webviewMessenger from '../../services/webviewMessenger';

type SortSectionProps = {
    currentSort: SortBy;
    onSortByJoinOrder: () => void;
    onSortByName: () => void;
    onSortByPoints: () => void;
};

const SortSection = ({ currentSort, onSortByJoinOrder, onSortByName, onSortByPoints }: SortSectionProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const webviewSettingsSlideshow = useSelector((state: StoreInterface) => state.webviewSettingsSlideshow);

    const [isDroppedDown, setIsDroppedDown] = useState(false);

    const isMyClassShowOnlineParticipant = webviewSettingsSlideshow.isMyClassShowOnlineParticipant;
    const orderOptions = [
        {
            order: SortBy.JOIN_ORDER,
            icon: <DoneIcon />,
        },
        {
            order: SortBy.NAME,
            icon: <DoneIcon />,
        },
    ];

    const updateSortBy = (order: SortBy, icon: any) => {
        webviewMessenger.sendUsageLog(`[S] Sorted participants by: ${order}`);
        dispatch(
            webviewSettingsActions.updateWebviewSettings(WebviewSettingsActionTypes.UPDATE_PARTICIPANT_SORT_ORDER, {
                ...{ sortParticipantsBy: order },
            }),
        );
        switch (order) {
            case SortBy.JOIN_ORDER:
                return onSortByJoinOrder();
            case SortBy.NAME:
                return onSortByName();
            case SortBy.POINTS:
                return onSortByPoints();
        }
    };

    const languageConversion = (order: any) => {
        if (order === SortBy.JOIN_ORDER) {
            return t('lang_myclass.txt_join_order');
        } else if (order === SortBy.NAME) {
            return t('lang_myclass.txt_name');
        } else {
            return t('lang_myclass.txt_points');
        }
    };

    const showOnlineParticipantHandler = (value: boolean) => {
        webviewMessenger.sendUsageLog(`[S] Toggled ${value ? 'ON' : 'OFF'} online participants only`);
        dispatch(
            webviewSettingsActions.updateWebviewSettings(
                WebviewSettingsActionTypes.TOGGLE_MY_CLASS_SHOW_ONLINE_PARTICIPANT,
                {
                    ...{
                        isMyClassShowOnlineParticipant: value,
                    },
                },
            ),
        );
    };

    return (
        <Fragment>
            <div className="sort">
                <div
                    className={isDroppedDown ? 'bgOverlay show' : 'bgOverlay'}
                    onClick={() => setIsDroppedDown(!isDroppedDown)}
                ></div>
                <div className="content">
                    <div className="visible">
                        <Button variant="text" onClick={() => setIsDroppedDown(!isDroppedDown)}>
                            <span className="icon">
                                <SortedBy />
                            </span>
                            <Typography>{t('lang_myclass.txt_sort_by')}</Typography>
                        </Button>
                    </div>

                    <div className={isDroppedDown ? 'listItemContainer show' : 'listItemContainer'}>
                        {orderOptions.map((data, index) => {
                            return (
                                <Button
                                    className={currentSort === data.order ? 'active' : ''}
                                    key={index}
                                    variant="text"
                                    onClick={() => updateSortBy(data.order, data.icon)}
                                >
                                    <span className="icon">{data.icon}</span>
                                    <Typography>{languageConversion(data.order)}</Typography>
                                </Button>
                            );
                        })}
                        <Button className="online_control">
                            <FormControlLabel
                                control={
                                    <Switch
                                        onChange={(e) => showOnlineParticipantHandler(e.target.checked)}
                                        checked={isMyClassShowOnlineParticipant}
                                    />
                                }
                                label={t('lang_myclass.toggle_show_online_participants_only')}
                            />
                        </Button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SortSection;
