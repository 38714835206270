import { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { TextField } from '@mui/material';

import { userActions } from '../../../actions/user.actions';
import apiUser from '../../../services/apiUser';
import { ResetPasswordInterface, ForgotPasswordStepType } from '../../../interfaces/signInSignUp.interface';
import { utilConstants } from '../../../constants/utils.constants';

import CommonButton from '../../../components/Common/CommonButton';

interface ForgotPasswordVerificationCodeInterface {
    forgotPasswordDetails: ResetPasswordInterface;
    setForgotPasswordStep: (arg: ForgotPasswordStepType) => void;
}

export const ForgotPasswordVerificationCode = ({
    forgotPasswordDetails,
    setForgotPasswordStep,
}: ForgotPasswordVerificationCodeInterface) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [timeToResendCode, setTimeToResendCode] = useState(utilConstants.TIME_LIMITS.RESEND_VERIFICATION_CODE);
    const [isLoading, setIsLoading] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [codeError, setCodeError] = useState('');

    const codeValidation = yup.object({
        code: yup
            .string()
            .required('error_verifyCode_required')
            .min(6, 'error_verifyCode_length')
            .max(6, 'error_verifyCode_length'),
    });

    const onChangeHandler = (value: string) => {
        const codePattern = /^[0-9]*$/;
        if (!value.match(codePattern)) return;
        setVerificationCode(value.trim());
        setCodeError('');
    };

    const submitFormHandler = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const isValidCode = await codeValidation.isValid({
            code: verificationCode,
        });

        if (isValidCode) {
            setIsLoading(true);
            const reply = await apiUser.verifyVerificationCode(forgotPasswordDetails.email, verificationCode);
            setIsLoading(false);

            if (reply < 0) return dispatch(userActions.showApiError());
            if (reply > 0) return setCodeError(`lang_auth.error_api_verification_code_${reply}`);
            setForgotPasswordStep(ForgotPasswordStepType.Password);
        } else {
            codeValidation.validate({ code: verificationCode }).catch((data) => {
                setCodeError(`lang_auth.${data.errors[0]}`);
            });
        }
    };

    const sendNewCodeHandler = async () => {
        setCodeError('');
        const sendVerificationCodeSuccess = await apiUser.sendVerificationCode(forgotPasswordDetails.email);
        if (!sendVerificationCodeSuccess) return dispatch(userActions.showApiError());
        setTimeToResendCode(utilConstants.TIME_LIMITS.RESEND_VERIFICATION_CODE);
    };

    useEffect(() => {
        let timerHandler: NodeJS.Timeout;
        if (timeToResendCode > 0)
            timerHandler = setInterval(() => setTimeToResendCode((prevValue) => prevValue - 1), 1000);
        return () => clearInterval(timerHandler);
    }, [timeToResendCode]);

    return (
        <>
            <div className="intro">
                <h4>{t(`lang_auth.heading_txt_verify_email`)}</h4>
                <p>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('lang_auth.txt_verifyEmail_subtitle', {
                                interpolation: { escapeValue: false },
                                email: forgotPasswordDetails.email,
                            }),
                        }}
                    />
                </p>
            </div>

            <form className="form" onSubmit={submitFormHandler}>
                <div className="profile_left">
                    <div className="pf_form">
                        <TextField
                            onChange={(e) => onChangeHandler(e.target.value)}
                            value={verificationCode}
                            error={codeError ? true : false}
                            helperText={t(`${codeError}`)}
                            inputProps={{ maxLength: 6 }}
                            label={t(`lang_auth.input_verifyCode`)}
                            autoComplete="none"
                            variant="standard"
                        />
                    </div>
                    <div className="profile_update_btn">
                        <CommonButton
                            isLoading={isLoading}
                            variant="contained"
                            className="Edit profile"
                            type="submit"
                            color="primary"
                            text={t('lang_auth.btn_verifyCode')}
                        />
                    </div>
                    <div className="signUp_link">
                        {timeToResendCode === 0 ? (
                            <p>
                                <span onClick={() => sendNewCodeHandler()}>{t('lang_auth.txt_send_new_code')}</span>
                            </p>
                        ) : (
                            <p>
                                {t('lang_auth.txt_send_new_code_in_x_seconds', {
                                    seconds: timeToResendCode,
                                })}
                            </p>
                        )}
                    </div>
                </div>
            </form>
        </>
    );
};
