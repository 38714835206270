import { useState } from 'react';

import { getValidUserFromStore } from '../../../helpers/storeHelpers';
import { getSubscriptionSubPageType } from '../../../helpers/userhelper';

import { utilConstants } from '../../../constants/utils.constants';
import { ViewportSavedClasses } from '../../../constants/viewport-constants';

import CommonAlert from '../../../components/Common/CommonAlert';
import { TrialFlow } from '../../activateTrial/TrialFlow';
import SubscriptionBasic from './subscriptionTypes/SubscriptionBasic';
import SubscriptionTrial from './subscriptionTypes/SubscriptionTrial';
import SubscriptionStandardOrganization from './subscriptionTypes/SubscriptionStandardOrganization';
import SubscriptionRecurring from './subscriptionTypes/SubscriptionRecurring';
import { SUBPAGE_TYPE } from '../../../constants/subscription-subpage-type.enum';
import { useTranslation } from 'react-i18next';
import { VerifyEmail } from '../verifyEmail/VerifyEmail';

interface SubscriptionLandingInterface {
    setCurrentPage: (arg: string) => void;
    onShowDiscountModal: () => void;
}

const SubscriptionLanding = ({ setCurrentPage, onShowDiscountModal }: SubscriptionLandingInterface) => {
    const { t } = useTranslation();
    const user = getValidUserFromStore('SubscriptionLanding');
    const isEmailVerified = user.userStats.isEmailVerified;
    const subpageType = getSubscriptionSubPageType(user);

    const [isTrialFlowModalOpen, setIsTrialFlowModalOpen] = useState(false);
    const [isActivatingTrial, setIsActivatingTrial] = useState(false);
    const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false);

    const getActiveComponent = () => {
        if (subpageType === SUBPAGE_TYPE.BASIC) {
            return (
                <SubscriptionBasic
                    addProductKey={() => setCurrentPage(utilConstants.SUBSCRIPTION_PAGE_TYPE.Add_Key)}
                    onOpenTrialFlow={() => setIsTrialFlowModalOpen(true)}
                    onActivateTrial={() => {
                        setIsTrialFlowModalOpen(true);
                        setIsActivatingTrial(true);
                    }}
                    onShowDiscountModal={onShowDiscountModal}
                />
            );
        } else if (subpageType === SUBPAGE_TYPE.TRIAL) {
            return (
                <SubscriptionTrial
                    setCurrentPage={setCurrentPage}
                    onClickAndMore={() => setIsTrialFlowModalOpen(true)}
                />
            );
        } else if (subpageType === SUBPAGE_TYPE.STANDARD_ORGANIZATION) {
            return (
                <SubscriptionStandardOrganization
                    changeProductKey={() => setCurrentPage(utilConstants.SUBSCRIPTION_PAGE_TYPE.Change_Key)}
                    onClickAndMore={() => setIsTrialFlowModalOpen(true)}
                />
            );
        } else {
            return (
                <SubscriptionRecurring
                    cancelSubscription={() => setCurrentPage(utilConstants.SUBSCRIPTION_PAGE_TYPE.Cancel_Subscription)}
                    revokePlanCancellation={() =>
                        setCurrentPage(utilConstants.SUBSCRIPTION_PAGE_TYPE.Confirm_Revoke_plan)
                    }
                    onClickAndMore={() => setIsTrialFlowModalOpen(true)}
                />
            );
        }
    };

    return (
        <div className={`new_subscription ${subpageType === SUBPAGE_TYPE.BASIC && 'noPadding'}`}>
            <CommonAlert
                showAlert={isTrialFlowModalOpen}
                closeAlert={() => {
                    if (!isActivatingTrial) setIsTrialFlowModalOpen(false);
                }}
                content={
                    <TrialFlow
                        isShowMultipleSlides={true}
                        setIsTrialFlowModalOpen={setIsTrialFlowModalOpen}
                        isActivatingTrial={isActivatingTrial}
                        setIsActivatingTrial={setIsActivatingTrial}
                        viewport={ViewportSavedClasses}
                        onTrialActivated={() => setIsTrialFlowModalOpen(false)}
                        customClassNames={`default`}
                    />
                }
                viewportConfig={ViewportSavedClasses}
                customModalClassNames="small"
                customBoxClassNames={
                    isActivatingTrial
                        ? isEmailVerified
                            ? `subscriptionTrialFlowModal smallModal`
                            : ''
                        : `subscriptionTrialFlowModal`
                }
            />

            {!isEmailVerified && (
                <>
                    <div className={`verifyEmailWarningText`}>
                        <p className={`text`}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t('lang_auth.txt_your_email_needs_verification', {
                                        interpolation: { escapeValue: false },
                                        email: user.email,
                                    }),
                                }}
                            />
                            <span
                                onClick={() => {
                                    setShowVerifyEmailModal(true);
                                }}
                                className={`link`}
                            >
                                {t('lang_auth.heading_txt_verify_email')}
                            </span>
                        </p>
                    </div>
                    <VerifyEmail
                        userEmail={user.email}
                        isShowWarningModal={showVerifyEmailModal}
                        onCloseModal={() => setShowVerifyEmailModal(false)}
                    />
                </>
            )}

            {getActiveComponent()}
        </div>
    );
};

export default SubscriptionLanding;
