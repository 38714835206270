import { useEffect, useState } from 'react';

import { Typography } from '@material-ui/core';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';

import { secondsToTimeDisplay } from '../../../helpers/activityHelpers';
import webviewMessenger from '../../../services/webviewMessenger';

const Countdown = ({ activityEndTime, closeSubmission }: any) => {
    const initialRemainingMiliseconds = activityEndTime - +new Date();
    const [remainingMiliseconds, setRemainingMiliseconds] = useState(initialRemainingMiliseconds);
    const [newActivityEndTime, setNewActivityEndTime] = useState(activityEndTime);

    useEffect(() => {
        let timer: any;
        if (remainingMiliseconds <= 0) return closeSubmission();
        if (remainingMiliseconds > 0) {
            timer = setTimeout(() => {
                setRemainingTime();
            }, 500);
        }
        return () => {
            clearTimeout(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remainingMiliseconds]);

    const setRemainingTime = () => {
        const remainingMiliseconds = newActivityEndTime - +new Date();
        setRemainingMiliseconds(remainingMiliseconds);
        const remainingSeconds = Math.ceil(remainingMiliseconds / 1000);
        // notify Host to sync time
        webviewMessenger.sendSyncCountdown(remainingSeconds);
    };

    const extendCountdown = async (timeToExtend: number) => {
        webviewMessenger.sendUsageLog(`[S] Extended close submission countdown for 15s`);
        setRemainingTime();
        const newEndTime = newActivityEndTime + timeToExtend * 1000;
        const newRemainingMiliseconds = newEndTime - +new Date();
        setRemainingMiliseconds(newRemainingMiliseconds);
        setNewActivityEndTime(newEndTime);
    };

    return (
        <>
            <span>
                {' '}
                <TimerOutlinedIcon />
            </span>
            <span className={Math.ceil(remainingMiliseconds / 1000) <= 10 ? 'red' : ''}>
                {secondsToTimeDisplay(Math.ceil(remainingMiliseconds / 1000))}
            </span>
            <Typography variant="caption" className="prime" onClick={() => extendCountdown(15)}>
                +15s
            </Typography>
        </>
    );
};

export default Countdown;
