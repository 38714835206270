// Inspired by Chatbot-UI and modified to fit the needs of this project
// @see https://github.com/mckaywrigley/chatbot-ui/blob/main/components/Markdown/CodeBlock.tsx

'use client';

import { FC, memo } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coldarkDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';

import { Check, Copy } from 'lucide-react';
import { useCopyToClipboard } from '../hooks/useCopyToClipboard';

interface Props {
    language: string;
    value: string;
}

const CodeBlock: FC<Props> = memo(({ language, value }) => {
    const { isCopied, copyToClipboard } = useCopyToClipboard({ timeout: 2000 });

    const onCopy = () => {
        if (isCopied) return;
        copyToClipboard(value);
    };

    return (
        <div className="codeblock relative mb-4 mt-4 w-full overflow-hidden rounded-lg bg-zinc-950 font-sans">
            <div className="flex w-full items-center justify-between bg-zinc-800 px-6 py-3 pr-6 text-zinc-100">
                <span className="text-xs lowercase">{language}</span>
                <div className="flex items-center space-x-1">
                    <div className="text-xs" onClick={onCopy}>
                        {isCopied ? (
                            <span className="flex cursor-default items-center gap-1">
                                <Check size={16} /> Copied!
                            </span>
                        ) : (
                            <span className="flex cursor-pointer items-center gap-1">
                                <Copy size={16} /> Copy
                            </span>
                        )}
                    </div>
                </div>
            </div>
            <SyntaxHighlighter
                language={language}
                style={coldarkDark}
                PreTag="div"
                showLineNumbers
                customStyle={{
                    margin: 0,
                    width: '100%',
                    background: 'transparent',
                    padding: '1.5rem 1rem',
                }}
                lineNumberStyle={{
                    userSelect: 'none',
                }}
                codeTagProps={{
                    style: {
                        fontSize: '0.9rem',
                        fontFamily: 'var(--font-mono)',
                    },
                }}
            >
                {value}
            </SyntaxHighlighter>
        </div>
    );
});
CodeBlock.displayName = 'CodeBlock';

export { CodeBlock };
