import { AnimationControls } from 'framer-motion';

// export const leaderBoardAnimation = (
//     leaderBoardAnimation_Star_Part1: AnimationControls,
//     leaderBoardAnimation_Bar_Part1: AnimationControls,
//     leaderBoardAnimation_Star_Part2: AnimationControls,
//     leaderBoardAnimation_Bar_Part2: AnimationControls,
//     leaderBoardAnimation_Star_Part3: AnimationControls,
//     leaderBoardAnimation_Bar_Part3: AnimationControls,
// ) => {
//     const animationConstantArray = {
//         part1: {
//             animationStar: leaderBoardAnimation_Star_Part1,
//             animationStarInitial: {
//                 marginBlockEnd: '4px',
//                 marginInlineEnd: 0,
//                 rotate: 0,
//                 scale: 1,
//             },
//             animationBar: leaderBoardAnimation_Bar_Part1,
//             animationBarInitial: {
//                 height: '9px',
//             },
//         },
//         part2: {
//             animationStar: leaderBoardAnimation_Star_Part2,
//             animationStarInitial: {
//                 scale: 1,
//                 marginBlockEnd: '6px',
//             },
//             animationBar: leaderBoardAnimation_Bar_Part2,
//             animationBarInitial: {
//                 height: '14px',
//             },
//         },
//         part3: {
//             animationStar: leaderBoardAnimation_Star_Part3,
//             animationStarInitial: {
//                 marginBlockEnd: '4px',
//                 marginInlineStart: 0,
//                 rotate: 0,
//                 scale: 1,
//             },
//             animationBar: leaderBoardAnimation_Bar_Part3,
//             animationBarInitial: {
//                 height: '7px',
//             },
//         },
//     };

//     const animationTransition = {
//         duration: 0.3,
//         type: 'spring',
//         stiffness: 1000,
//         damping: 12,
//     };

//     const mouseEnterAnimation = () => {
//         // ========== Part 1 ==========
//         leaderBoardAnimation_Star_Part1.start({
//             marginInlineEnd: '3px',
//             rotate: -20,
//             scale: 1.2,
//             transition: animationTransition,
//         });
//         leaderBoardAnimation_Bar_Part1.start({
//             height: '13px',
//             transition: animationTransition,
//         });

//         // ========== Part 2 ==========
//         leaderBoardAnimation_Star_Part2.start({
//             scale: 1.2,
//             transition: animationTransition,
//         });
//         leaderBoardAnimation_Bar_Part2.start({
//             height: '18px',
//             transition: animationTransition,
//         });

//         // ========== Part 3 ==========
//         leaderBoardAnimation_Star_Part3.start({
//             marginInlineStart: '3px',
//             rotate: 20,
//             scale: 1.2,
//             transition: animationTransition,
//         });
//         leaderBoardAnimation_Bar_Part3.start({
//             height: '11px',
//             transition: animationTransition,
//         });
//     };

//     const mouseLeaveAnimation = () => {
//         // ========== Part 1 ==========
//         leaderBoardAnimation_Star_Part1.start({
//             ...animationConstantArray.part1.animationStarInitial,
//             transition: animationTransition,
//         });
//         leaderBoardAnimation_Bar_Part1.start({
//             ...animationConstantArray.part1.animationBarInitial,
//             transition: animationTransition,
//         });

//         // ========== Part 2 ==========
//         leaderBoardAnimation_Star_Part2.start({
//             ...animationConstantArray.part2.animationStarInitial,
//             transition: animationTransition,
//         });
//         leaderBoardAnimation_Bar_Part2.start({
//             ...animationConstantArray.part2.animationBarInitial,
//             transition: animationTransition,
//         });

//         // ========== Part 3 ==========
//         leaderBoardAnimation_Star_Part3.start({
//             ...animationConstantArray.part3.animationStarInitial,
//             transition: animationTransition,
//         });
//         leaderBoardAnimation_Bar_Part3.start({
//             ...animationConstantArray.part3.animationBarInitial,
//             transition: animationTransition,
//         });
//     };

//     return {
//         animationConstants: animationConstantArray,
//         mouseEnterAnimation: mouseEnterAnimation,
//         mouseLeaveAnimation: mouseLeaveAnimation,
//     };
// };

export const leaderBoardCupAnimationConfig = (
    leaderBoardCupAnimation: AnimationControls,
    leaderBoardStarAnimation: AnimationControls,
) => {
    const animationConstantArray = {
        cupInitial: {
            y: 0,
        },
        starInitial: {
            y: 0,
        },
    };

    const cupTransition = {
        type: 'spring',
        stiffness: 1000,
        mass: 2,
        damping: 20,
    };

    const starTransition = {
        type: 'spring',
        stiffness: 1000,
        mass: 2,
        damping: 30,
    };

    const mouseEnterAnimation = () => {
        leaderBoardCupAnimation.start({
            y: -4,
            transition: cupTransition,
        });
        leaderBoardStarAnimation.start({
            y: -4,
            transition: starTransition,
        });
    };

    const mouseLeaveAnimation = () => {
        leaderBoardCupAnimation.start({
            ...animationConstantArray.cupInitial,
            transition: cupTransition,
        });

        leaderBoardStarAnimation.start({
            ...animationConstantArray.starInitial,
            transition: starTransition,
        });
    };

    return {
        animationConstants: animationConstantArray,
        mouseEnterAnimation: mouseEnterAnimation,
        mouseLeaveAnimation: mouseLeaveAnimation,
    };
};
