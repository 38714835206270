import { FunctionComponent, ReactNode, useEffect } from 'react';
import { AuthError, AuthenticationResult } from '@azure/msal-browser';
import { v4 } from 'uuid';

import { blobToBase64, checkToIE, getScopes, getUserAgentApp } from '../../../helpers/microsoftLoginHelpers';
import { dataURLtoFile } from '../../../helpers/utils';
import { uploadToAzureStorage } from '../../../services/azureStorageService';
import { microsoftSsoApi } from '../../../services/microsoftSso.api';

import { MICROSOFT_APP_ID } from '../../../constants/sso-constants';

type MicrosoftLoginPrompt = 'login' | 'select_account' | 'consent' | 'none';

interface MicrosoftLoginProps {
    authCallback: (data: any) => void;
    tenantUrl?: string;
    postLogoutRedirectUri?: string;
    prompt?: MicrosoftLoginPrompt;
    children: ReactNode;
    className?: string;
}

const MicrosoftLogin: FunctionComponent<MicrosoftLoginProps> = ({
    authCallback,
    tenantUrl,
    postLogoutRedirectUri,
    prompt,
    children,
    className,
}) => {
    const clientId = MICROSOFT_APP_ID;
    const redirectUri = '';
    const scopes = getScopes(['user.read']);
    const useLocalStorageCache = true;

    const msalInstance = getUserAgentApp({
        clientId,
        tenantUrl,
        redirectUri,
        postLogoutRedirectUri,
        useLocalStorageCache,
    });

    const getUserData = async (AuthenticationResultWithAccessToken: AuthenticationResult) => {
        // console.log("Fetch Graph API 'access_token' SUCCEEDED", AuthenticationResultWithAccessToken);

        const { accessToken } = AuthenticationResultWithAccessToken;
        // console.log('Fetch Graph API user data STARTED');

        const options = { method: 'GET', headers: { Authorization: `Bearer ${accessToken}` } };

        const personalDetails = await microsoftSsoApi.getPersonalDetails(options);
        if (!personalDetails) return authCallback({ isSuccessful: false, data: 'sign_up_start.error_login' });

        const organization = await microsoftSsoApi.getOrganizationName(options);

        const imageResponse = await microsoftSsoApi.getImageDetails(options);
        let imageUrlFromAzureStorage = '';

        if (imageResponse) {
            const imageBlob = await imageResponse.blob();
            const imageInBase64 = await blobToBase64(imageBlob);
            const imageFile = dataURLtoFile(imageInBase64, `${personalDetails.mail}-avatar-${v4()}.jpg`);
            imageUrlFromAzureStorage = await uploadToAzureStorage(
                imageFile,
                `${personalDetails.mail}-avatar-${v4()}.jpg`,
                'microsoftSsoAvatarUpload',
                false,
                'user',
            );
        }

        const requiredPersonalDetails = {
            email: personalDetails.mail || '',
            firstName: personalDetails.givenName || '',
            lastName: personalDetails.surname || '',
            avatarUrl: imageUrlFromAzureStorage,
            organization,
            provider: 'microsoft',
            accessToken: accessToken || '',
        };

        authCallback({ isSuccessful: true, data: requiredPersonalDetails });
    };

    const getGraphAPITokenAndUser = async (isRedirect?: boolean) => {
        if (!msalInstance) return;
        await msalInstance.initialize();
        try {
            try {
                const silentRes = await msalInstance.acquireTokenSilent({ scopes });
                getUserData(silentRes);
            } catch (err) {
                // console.log("Fetch Graph API 'access_token' in silent mode is FAILED", err, true);
                if (isRedirect) {
                    // console.log("Fetch Graph API 'access_token' with redirect STARTED");
                    msalInstance.acquireTokenRedirect({ scopes });
                } else {
                    // console.log("Fetch Graph API 'access_token' with popup STARTED");
                    const popupRes = await msalInstance.acquireTokenPopup({ scopes });
                    getUserData(popupRes);
                }
            }
        } catch (error: any) {
            // console.log('Login FAILED', error, true);
            console.log(error);
            authCallback({ isSuccessful: false, data: 'cancelled' });
        }
    };

    const handleMsalInstance = async () => {
        if (!msalInstance) return;
        await msalInstance.initialize();
        msalInstance
            .handleRedirectPromise()
            .then((value: AuthenticationResult | null) => {
                if (value) {
                    // console.log("Fetch Azure AD 'token' with redirect SUCCEEDED", value);
                    // console.log("Fetch Graph API 'access_token' in silent mode STARTED");
                    getGraphAPITokenAndUser(true);
                }
            })
            .catch((error: AuthError) => {
                // console.log("Fetch Azure AD 'token' with redirect FAILED", error, true);
                authCallback(error);
            });
    };

    const redirectLogin = () => {
        if (!msalInstance) return;
        // console.log("Fetch Azure AD 'token' with redirect STARTED");
        msalInstance.loginRedirect({ scopes, prompt });
    };

    const popupLogin = async () => {
        if (!msalInstance) return;
        try {
            // console.log("Fetch Azure AD 'token' with popup SUCCEEDED", AuthenticationResult);
            getGraphAPITokenAndUser();
        } catch (err: any) {
            // console.log("Fetch Azure AD 'token' with popup FAILED", err, true);
            authCallback(err);
        }
    };

    const login = () => {
        if (checkToIE()) redirectLogin();
        else popupLogin();
    };

    useEffect(() => {
        handleMsalInstance();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const clientToken = useLocalStorageCache
            ? localStorage.getItem('msal.idtoken')
            : sessionStorage.getItem('msal.idtoken');

        clientToken && getGraphAPITokenAndUser(checkToIE());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [msalInstance]);

    return (
        <div onClick={login} className={className || ''}>
            {children}
        </div>
    );
};

interface LoginWithMicrosoftInterface {
    children: ReactNode;
    loginHandler: (data: any) => void;
    className?: string;
}

export const LoginWithMicrosoft = ({ children, loginHandler, className }: LoginWithMicrosoftInterface) => {
    return <MicrosoftLogin authCallback={loginHandler} children={children} className={className} />;
};
