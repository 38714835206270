export enum TimerStopwatchActionTypes {
    TOGGLE_TIMER_STOPWATCH = 'TOGGLE_TIMER_STOPWATCH',
    START_TIMER = 'START_TIMER',
    PAUSE_TIMER = 'PAUSE_TIMER',
    RESUME_TIMER = 'RESUME_TIMER',
    TIMER_TIMEUP = 'TIMER_TIMEUP',
    SET_TIME_LIMIT = 'SET_TIME_LIMIT',
    RESET_TIMER = 'RESET_TIMER',
    TIMER_WINDOW_COLLAPSE = 'TIMER_WINDOW_COLLAPSE',
    TIMER_WINDOW_RESTORE = 'TIMER_WINDOW_RESTORE',
    MAXIMIZE_TIMER_WINDOW = 'MAXIMIZE_TIMER_WINDOW',
    TIMER_WINDOW_RESET = 'TIMER_WINDOW_RESET',
    UPDATE_TIMEUP_SOUND_ID = 'UPDATE_TIMEUP_SOUND_ID',
    UPDATE_TIMER_MODE = 'UPDATE_TIMER_MODE',
}
