import { useTranslation } from 'react-i18next';

import { Link, Typography } from '@mui/material';
import { getValidUserFromStore } from '../../../../helpers/storeHelpers';
import { displayLocaleDate } from '../../../../helpers/languageHelpers';

import { utilConstants } from '../../../../constants/utils.constants';
import PlanTypeBadge from '../components/PlanTypeBadge';
import FeatureList from '../components/FeatureList';
import { getRemainingProDays } from '../../../../helpers/userhelper';

const SubscriptionStandardOrganization = ({
    changeProductKey,
    onClickAndMore,
}: {
    changeProductKey: () => void;
    onClickAndMore: () => void;
}) => {
    const { t } = useTranslation();
    const user = getValidUserFromStore('SubscriptionStandardOrganization');
    const userSubscription = user.userSubscription;
    const planType = userSubscription.planType;
    const planExpiryDate = new Date(userSubscription.planExpiryDate || '0000-01-01');
    const formattedDate = displayLocaleDate(planExpiryDate);
    const differenceInDays = getRemainingProDays(userSubscription.planExpiryDate);
    const alertDaysThreshold = 15;

    return (
        <div className="inner_subscription">
            <div className="body_head">
                <Typography variant="h2">{t('lang_subscription.title')}</Typography>
            </div>
            <div className="big_card">
                <div className="subcription_display">
                    {/* <div className="card_icon">
                        <img src={utilConstants.SETTING.CROWN} alt="crown" />
                    </div> */}
                    <div className="plan_box_wrapper">
                        <div className="planbox">
                            <div className="plan_head">
                                <Typography variant="h3">{t(`lang_subscription.txt_plan`)}</Typography>
                            </div>
                            <div className="plan_body">
                                <Typography variant="h4" className={`title`}>
                                    {userSubscription.isPremium
                                        ? t('lang_premium.classpoint_premium')
                                        : t(`lang_new_trial.classpoint_pro`)}
                                </Typography>
                                {planType === utilConstants.PLAN_TYPE.ORGANIZATION && (
                                    <Typography variant="body1">
                                        {t(`lang_subscription.txt_organization_plan_summary`, {
                                            organization: user.userProfile.organization,
                                        })}
                                    </Typography>
                                )}
                            </div>
                            <PlanTypeBadge user={user} />
                        </div>

                        <div className="planbox">
                            <div className="plan_head">
                                <Typography variant="h3">{t(`lang_subscription.txt_expiry_date`)}</Typography>
                            </div>
                            <div className="plan_body">
                                <Typography
                                    variant="h4"
                                    className={differenceInDays <= alertDaysThreshold ? 'red' : ''}
                                >
                                    {formattedDate}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="feature_list">
                <h5 className="list_head">
                    {userSubscription.isPremium ? t(`lang_premium.heading`) : t(`lang_new_trial.txt_in_pro`)}
                </h5>
                <FeatureList
                    caller="SubscriptionStandardOrganization"
                    onClickAndMore={onClickAndMore}
                    isPremiumUser={userSubscription.isPremium}
                />
            </div>

            <div className="link">
                <Link onClick={changeProductKey}>{t(`lang_subscription.txt_change_product_key`)}</Link>
            </div>
        </div>
    );
};

export default SubscriptionStandardOrganization;
