import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Avatar, Button, Link, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { activityActions } from '../../actions/activity.action';

import VotingCount from '../../components/Common/VotingCount';
import ActivityResponseInterface from '../../interfaces/activity-response.interface';
import GivePointToResponse from '../../components/Common/GivePointToResponse';
import Highlighter from 'react-highlight-words';
import { generateAsterisksFromName, getBase64, getColorForString, stringToNumber } from '../../helpers/utils';
import CustomAvatar, { AvatarType } from '../../components/Common/CustomAvatar';
import webviewMessenger from '../../services/webviewMessenger';
import { utilConstants } from '../../constants/utils.constants';
import { useTranslation } from 'react-i18next';
import { getActivityFromStore, getValidUserFromStore } from '../../helpers/storeHelpers';
import {
    canEditResponses,
    checkUrlExistInFileApp,
    findImageExtensionFromUrl,
    isJson,
    searchKeywordToArray,
} from '../../helpers/activityHelpers';
import { ActivityPropsInterface } from '../../interfaces/activity-props.interface';
import apiUser from '../../services/apiUser';
import StoreInterface from '../../interfaces/store.interface';

import filter from '../../services/badwords.service';

interface MultiMediaActivityResponseFooterInterface {
    response: ActivityResponseInterface;
    responseIndex: number;
    onResponseIndexDeleted: (arg: number) => any;
    searchKeyword: string;
    isVoting: boolean;
    useFromModal?: boolean;
    pointsAdded?: number;
    onGivePointButtonClick?: any;
    setIsProcessing?: (arg: boolean) => void;
}

function MultiMediaActivityResponseFooter({
    response,
    responseIndex,
    onResponseIndexDeleted,
    searchKeyword,
    isVoting,
    useFromModal,
    pointsAdded,
    onGivePointButtonClick,
    setIsProcessing,
}: MultiMediaActivityResponseFooterInterface) {
    const dispatch = useDispatch();
    const user = getValidUserFromStore('MultiMediaActivityResponseFooter');
    const classSession = useSelector((state: StoreInterface) => state.classSession);
    const activity = getActivityFromStore();
    const activityProps = activity.activityProps as ActivityPropsInterface;
    const activityType = activityProps.activityType;
    const isNamesHidden = activityProps.isNamesHidden as boolean;
    const canEditResponsesInView = canEditResponses(activity, user.email);
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
    const [shouldMenuFlipUp, setShouldMenuFlipUp] = useState(false);
    const open = Boolean(anchorEl);
    const menuItemsCount = document.getElementById('listId')?.childElementCount;
    const { t } = useTranslation();
    const pointsAddedNew = pointsAdded === undefined ? 0 : pointsAdded;

    useEffect(() => {
        if (isContextMenuOpen) setIsContextMenuOpen(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseIndex]);

    const handleClick = (e: any) => {
        if (!isContextMenuOpen) setIsContextMenuOpen(true);
        else setIsContextMenuOpen(false);
        const containerHeight = document.getElementById('activityCommonDiv')?.clientHeight || 0;
        const menuHeight = document.getElementById('listId')?.clientHeight || 0;

        if (containerHeight - (e.clientY + menuHeight) < -130) {
            setShouldMenuFlipUp(true);
        } else {
            setShouldMenuFlipUp(false);
        }
    };

    const insertAsSlide = async () => {
        switch (activityType) {
            case utilConstants.ACTIVITY_TYPE.SHORT_ANSWER:
                saveShortAnswerAsSlide();
                setIsContextMenuOpen(false);
                break;
            case utilConstants.ACTIVITY_TYPE.FILL_IN_THE_BLANKS:
                saveFibAsSlide();
                setIsContextMenuOpen(false);
                break;
            case utilConstants.ACTIVITY_TYPE.SLIDE_DRAWING:
            case utilConstants.ACTIVITY_TYPE.IMAGE_UPLOAD:
                saveImageAsSlide();
                setIsContextMenuOpen(false);
                break;
            default:
                break;
        }
    };

    const saveShortAnswerAsSlide = () => {
        const payload = {
            activityType,
            title: t(`lang_activity.insert_slide_title_single`, {
                activityType: t(`lang_activity.${activityType}`),
                participantName: isNamesHidden
                    ? generateAsterisksFromName(response.participantName)
                    : response.participantName,
            }),
            responses: isNamesHidden
                ? [
                      {
                          responseData: `<div style="color:black">${filter
                              .clean(`a${response.responseData}`)
                              .slice(1)}</div>`,
                          shortAnswerCardBg:
                              utilConstants.SHORT_ANSWER_COLORS[
                                  stringToNumber(response.responseId) % utilConstants.SHORT_ANSWER_COLORS.length
                              ],
                      },
                  ]
                : [
                      {
                          participantName: response.participantName,
                          responseData: `<div style="color:black">${filter
                              .clean(`a${response.responseData}`)
                              .slice(1)}</div>`,
                          shortAnswerCardBg:
                              utilConstants.SHORT_ANSWER_COLORS[
                                  stringToNumber(response.responseId) % utilConstants.SHORT_ANSWER_COLORS.length
                              ],
                      },
                  ],
        };
        // console.log('payload', payload);
        webviewMessenger.sendInsertAsSlide(JSON.stringify(payload));
    };

    const saveImageAsSlide = async () => {
        let fileUrl;
        if (setIsProcessing !== undefined) setIsProcessing(true);
        if (isJson(response.responseData)) fileUrl = JSON.parse(response.responseData)[0];
        else fileUrl = response.responseData;

        const fileAppBaseUrl = process.env.REACT_APP_CPFILE_BASE_URL;
        if (fileAppBaseUrl && !checkUrlExistInFileApp(fileUrl, fileAppBaseUrl))
            fileUrl = await apiUser.uploadUrlToStorage(fileUrl);

        const imageData: any = await getBase64(fileUrl);
        const payload = {
            activityType,
            title: t(`lang_activity.insert_slide_title_single`, {
                activityType: t(`lang_activity.${activityType}`),
                participantName: isNamesHidden
                    ? generateAsterisksFromName(response.participantName)
                    : response.participantName,
            }),
            responses: isNamesHidden
                ? [
                      {
                          responseData: imageData.split(',')[1],
                          caption:
                              activityType === utilConstants.ACTIVITY_TYPE.IMAGE_UPLOAD
                                  ? JSON.parse(response.responseData)[1]
                                  : undefined,
                      },
                  ]
                : [
                      {
                          participantName: response.participantName,
                          responseData: imageData.split(',')[1],
                          caption:
                              activityType === utilConstants.ACTIVITY_TYPE.IMAGE_UPLOAD
                                  ? JSON.parse(response.responseData)[1]
                                  : undefined,
                      },
                  ],
        };
        webviewMessenger.sendInsertAsSlide(JSON.stringify(payload));
        if (setIsProcessing !== undefined) setIsProcessing(false);
    };

    const saveFibAsSlide = () => {
        const payload = {
            activityType,
            title: t(`lang_activity.insert_slide_title_single`, {
                activityType: t(`lang_activity.${activityType}`),
                participantName: isNamesHidden
                    ? generateAsterisksFromName(response.participantName)
                    : response.participantName,
            }),
            responses: isNamesHidden
                ? [
                      {
                          responseData: response.responseData,
                      },
                  ]
                : [
                      {
                          participantName: response.participantName,
                          responseData: response.responseData,
                      },
                  ],
        };
        // console.log('payload', payload);
        webviewMessenger.sendInsertAsSlide(JSON.stringify(payload));
    };

    const downloadResponse = (response: ActivityResponseInterface) => {
        const participantName = response.participantName;
        let fileUrl = '';
        let fileName = '';
        if (isJson(response.responseData)) {
            fileUrl = JSON.parse(response.responseData)[0];
        } else fileUrl = response.responseData;

        if (activityProps.activityType === utilConstants.ACTIVITY_TYPE.IMAGE_UPLOAD) {
            const ext = findImageExtensionFromUrl(fileUrl);
            fileName = `${t(`lang_activity.${activityType}`)}-${participantName}${ext}`;
        } else {
            fileName = `${t(`lang_activity.${activityType}`)}-${participantName}.${fileUrl.split('.').pop()}`;
        }

        const payload = JSON.stringify({
            fileName,
            fileUrl,
        });

        const encodeValue = window.btoa(encodeURIComponent(payload));
        const downloadUrl = `${process.env.REACT_APP_CPDOWNLOAD}/activity-files/${encodeValue}`;
        webviewMessenger.sendOpenBrowser(downloadUrl);
        setIsContextMenuOpen(false);
    };

    const onDeleteResponse = (response: ActivityResponseInterface) => {
        onResponseIndexDeleted(responseIndex);
        dispatch(activityActions.deleteIndividualResponses([response]));
        setIsContextMenuOpen(false);
    };

    // console.log('responsePoints >>>>>', response.responsePoints);

    return (
        <>
            <div className="sh_footer ">
                <div className="profile_foot">
                    {isNamesHidden ? (
                        <div className="avater_box">
                            <Avatar
                                alt={response.participantName}
                                style={{
                                    backgroundColor: getColorForString(response.participantName),
                                }}
                            >
                                ?
                            </Avatar>
                        </div>
                    ) : (
                        <div className="avater_box">
                            <CustomAvatar
                                avatarName={response.participantName}
                                avatarUrl={response.participantAvatar}
                                type={AvatarType.PARTICIPANT}
                            />
                        </div>
                    )}
                    {isNamesHidden ? (
                        <Typography variant="body1">{generateAsterisksFromName(response.participantName)}</Typography>
                    ) : (
                        <>
                            {searchKeyword ? (
                                <Highlighter
                                    highlightClassName="YourHighlightClass"
                                    searchWords={searchKeywordToArray(searchKeyword)}
                                    autoEscape={true}
                                    textToHighlight={response.participantName}
                                    className={response.participantName.length > 12 ? 'smallFontText' : 'largeFontText'}
                                />
                            ) : (
                                <span
                                    className={response.participantName.length > 12 ? 'smallFontText' : 'largeFontText'}
                                >
                                    {response.participantName}
                                </span>
                            )}
                        </>
                    )}
                </div>
                {isVoting && <VotingCount response={response} />}
                {!isVoting && (
                    <div className="foot_icon">
                        <ul>
                            <li
                                className={
                                    !canEditResponsesInView && response.responsePoints + pointsAddedNew < 1
                                        ? 'nostarview'
                                        : ''
                                }
                            >
                                {canEditResponsesInView ? (
                                    <GivePointToResponse
                                        response={response}
                                        pointsAdded={pointsAdded}
                                        onGivePointButtonClick={() => {
                                            webviewMessenger.sendUsageLog(
                                                `${classSession ? '[S] ' : ''}Awarded star to response`,
                                            );
                                            onGivePointButtonClick();
                                        }}
                                    />
                                ) : (
                                    <GivePointToResponse response={response} pointsAdded={pointsAdded} />
                                )}
                            </li>
                            <li>
                                <Button
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={(e: any) => handleClick(e)}
                                >
                                    <MoreVertIcon />
                                </Button>
                                <div className="menu_modal">
                                    <div
                                        className={`menu_overlay ${isContextMenuOpen ? 'active' : ''}`}
                                        onClick={(e: any) => handleClick(e)}
                                    ></div>
                                    <div
                                        className={`menu ${isContextMenuOpen ? 'active' : ''}`}
                                        style={
                                            shouldMenuFlipUp && !useFromModal
                                                ? menuItemsCount === 1
                                                    ? {
                                                          top: '-50px',
                                                      }
                                                    : menuItemsCount === 2
                                                    ? {
                                                          top: '-85px',
                                                      }
                                                    : { top: '-120px' }
                                                : {
                                                      top: '30px',
                                                  }
                                        }
                                    >
                                        <ul id="listId">
                                            {(activityType === utilConstants.ACTIVITY_TYPE.SHORT_ANSWER ||
                                                activityType === utilConstants.ACTIVITY_TYPE.SLIDE_DRAWING ||
                                                activityType === utilConstants.ACTIVITY_TYPE.IMAGE_UPLOAD) && (
                                                <li>
                                                    <Link
                                                        onClick={() => {
                                                            webviewMessenger.sendUsageLog(
                                                                `${
                                                                    classSession ? '[S] ' : ''
                                                                }Inserted as slide from 3-dot menu`,
                                                            );
                                                            insertAsSlide();
                                                        }}
                                                    >
                                                        {t('lang_common.btn_insert_as_slide')}
                                                    </Link>
                                                </li>
                                            )}
                                            {(activityType === utilConstants.ACTIVITY_TYPE.SLIDE_DRAWING ||
                                                activityType === utilConstants.ACTIVITY_TYPE.IMAGE_UPLOAD ||
                                                activityType === utilConstants.ACTIVITY_TYPE.AUDIO_RECORD ||
                                                activityType === utilConstants.ACTIVITY_TYPE.VIDEO_UPLOAD) && (
                                                <li>
                                                    <Link
                                                        onClick={() => {
                                                            webviewMessenger.sendUsageLog(
                                                                `${
                                                                    classSession ? '[S] ' : ''
                                                                }Downloaded response from 3-dot menu`,
                                                            );
                                                            downloadResponse(response);
                                                        }}
                                                    >
                                                        {t('lang_common.btn_download')}
                                                    </Link>
                                                </li>
                                            )}
                                            {canEditResponsesInView && (
                                                <li>
                                                    <Link
                                                        onClick={() => {
                                                            webviewMessenger.sendUsageLog(
                                                                `${
                                                                    classSession ? '[S] ' : ''
                                                                }Deleted response from 3-dot menu`,
                                                            );
                                                            onDeleteResponse(response);
                                                        }}
                                                    >
                                                        {t('lang_common.btn_delete')}
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                )}
            </div>
        </>
    );
}

export default MultiMediaActivityResponseFooter;
