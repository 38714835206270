import ClassSessionInterface from '../interfaces/classSessionInterface';
import { QnaDataInterface, QnaInterface } from '../interfaces/qna.interface';
import { SavedClassGroupInterface } from '../interfaces/savedClassGroup.interface';

export const refreshQnaAndSort = (
    classSession: ClassSessionInterface,
): { newQnas: QnaInterface[]; answeredQnas: QnaInterface[]; savedClassGroups: SavedClassGroupInterface[] } => {
    const qnaDataWithVoters = updateVotersList(classSession.qnaData.qnas || [], classSession.qnaData.qnaVoters);
    const newQnas = sortByVotesThenByCreated(qnaDataWithVoters.filter((qna) => !qna.isAnswered));
    const answeredQnas = sortByVotesThenByCreated(qnaDataWithVoters.filter((qna) => qna.isAnswered));
    const savedClassGroups = classSession.groups || [];
    return { newQnas, answeredQnas, savedClassGroups };
};

const sortByVotesThenByCreated = (qnas: QnaInterface[]) => {
    return qnas.sort((a, b) => {
        if (b.votes === a.votes) {
            return new Date(a.created).getTime() - new Date(b.created).getTime();
        }
        return b.votes - a.votes;
    });
};

export const updateQnasFromClassSession = (
    newQnas: QnaInterface[],
    answeredQnas: QnaInterface[],
    classSessionQnaData: QnaDataInterface,
): { newQnas: QnaInterface[]; answeredQnas: QnaInterface[] } => {
    const classSessionQnas = classSessionQnaData.qnas || [];
    const classSessionQnaVoters = classSessionQnaData.qnaVoters || {};
    const updatedAllQnaIds = classSessionQnas.map((qna) => qna.id);

    // FOR newQnas: remove dismissed, update existing's votes and isAnswered
    const updatedNewQnas = newQnas.filter((qna) => updatedAllQnaIds.includes(qna.id));
    updatedNewQnas.forEach((qna) => {
        const updatedQna = classSessionQnas.find((q) => q.id === qna.id);
        if (updatedQna) {
            qna.votes = updatedQna.votes;
            qna.isAnswered = updatedQna.isAnswered;
        }
    });

    // FOR answeredQnas: remove dismissed, update existing's votes and isAnswered
    const updatedAnsweredQnas = answeredQnas.filter((qna) => updatedAllQnaIds.includes(qna.id));
    updatedAnsweredQnas.forEach((qna) => {
        const updatedQna = classSessionQnas.find((q) => q.id === qna.id);
        if (updatedQna) {
            qna.votes = updatedQna.votes;
            qna.isAnswered = updatedQna.isAnswered;
        }
    });

    // ADDITIONALLY FOR newQnas: append new ones
    const updatedNewQnasIds = updatedNewQnas.map((qna) => qna.id);
    const newQnasToAdd = sortByVotesThenByCreated(
        classSessionQnas.filter((qna) => !qna.isAnswered && !updatedNewQnasIds.includes(qna.id)),
    );
    const updatedNewQnasWithNewQnas = updatedNewQnas.concat(newQnasToAdd);

    const newQnasWithVoters = updateVotersList(updatedNewQnasWithNewQnas, classSessionQnaVoters);
    const answeredQnasWithVoters = updateVotersList(updatedAnsweredQnas, classSessionQnaVoters);

    return { newQnas: newQnasWithVoters, answeredQnas: answeredQnasWithVoters };
};

const updateVotersList = (qnas: QnaInterface[], qnaVoters: { [key: string]: number[] }) => {
    const qnaDataWithVoters: QnaInterface[] = qnas.map((qna) => ({ ...qna, voters: [] }));
    Object.keys(qnaVoters).forEach((voter) => {
        const votedQnas = qnaVoters[voter];
        votedQnas.forEach((qnaId) => qnaDataWithVoters.find((qna) => qna.id === qnaId)?.voters.push(voter));
    });
    return qnaDataWithVoters;
};

export const calculateQnaModalTextSize = (question: string): number => {
    const questionLength = question.length;

    if (questionLength <= 30) return 42;
    if (questionLength > 30 && questionLength <= 50) return 36;
    if (questionLength > 50 && questionLength <= 100) return 34;
    if (questionLength > 100 && questionLength <= 150) return 32;
    if (questionLength > 150 && questionLength <= 200) return 30;
    if (questionLength > 200 && questionLength <= 250) return 28;
    if (questionLength > 250 && questionLength <= 300) return 26;
    if (questionLength > 300 && questionLength <= 350) return 24;
    if (questionLength > 350 && questionLength <= 400) return 22;
    if (questionLength > 400 && questionLength <= 450) return 20;
    if (questionLength > 450 && questionLength <= 500) return 18;
    return 16;
};
