import { useEffect, useState } from 'react';

export default function useInitAiChat(append: any) {
	// Run the following code becuase I want AI to start talking first. But for some reason directy calling startChat() in useEffect always results in error, so I have to use a state variable to trigger it.

	const [init, setInit] = useState(false);

	useEffect(() => {
		setInit(true);
	}, []);

	useEffect(() => {
		if (init) startChat();
	}, [init]);

	const startChat = async () => {
		await append({
			content: '',
			role: 'user',
		});
	};
}
