import TutorialSettingsInterface from '../interfaces/tutorial-seetings.interface';
import { TutorialSettingsActionTypes } from './../constants/tutorial-settings-action-types';

const updateActiveTutorialVideo = (activeTutorialVideoId: string) => {
    return async (dispatch: (arg0: { type: string; payload: Partial<TutorialSettingsInterface> }) => void) => {
        dispatch({
            type: TutorialSettingsActionTypes.UPDATE_ACTIVE_TUTORIAL_VIDEO,
            payload: { activeTutorialVideoId },
        });
    };
};

const updateTutorialCaptionLang = (tutorialCaptionLang: string) => {
    return async (dispatch: (arg0: { type: string; payload: Partial<TutorialSettingsInterface> }) => void) => {
        dispatch({
            type: TutorialSettingsActionTypes.UPDATE_TUTORIAL_CAPTION_LANG,
            payload: { tutorialCaptionLang },
        });
    };
};

const adjustTutorialVolume = (videoTutorialVolume: number) => {
    return async (dispatch: (arg0: { type: string; payload: Partial<TutorialSettingsInterface> }) => void) => {
        dispatch({
            type: TutorialSettingsActionTypes.ADJUST_TUTORIAL_VOLUME,
            payload: { videoTutorialVolume },
        });
    };
};

const muteUnmuteVideoVolume = (isTutorialVideoMuted: boolean) => {
    return async (dispatch: (arg0: { type: string; payload: Partial<TutorialSettingsInterface> }) => void) => {
        dispatch({
            type: TutorialSettingsActionTypes.MUTE_UNMUTE_VIDEO_VOLUME,
            payload: { isTutorialVideoMuted },
        });
    };
};

export const tutorialSettingsActions = {
    updateActiveTutorialVideo,
    updateTutorialCaptionLang,
    adjustTutorialVolume,
    muteUnmuteVideoVolume,
};
