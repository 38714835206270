import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { pick } from 'lodash';

import { updateQnaState } from '../../../../reducers/qna.reducer';
import { refreshQnaAndSort } from '../../../../helpers/qnaHelpers';
import webviewMessenger from '../../../../services/webviewMessenger';

import StoreInterface from '../../../../interfaces/store.interface';
import { QnaActionType } from '../../../../interfaces/qna.interface';
import { utilConstants } from '../../../../constants/utils.constants';

const QnaHeader = () => {
    const { t } = useTranslation();
    const classSession = useSelector((state: StoreInterface) => state.classSession);
    const { activeTab, newQnas, answeredQnas } = useSelector((state: StoreInterface) => state.qna);

    const insertAsSlide = async () => {
        const headerColumns = [t('lang_myclass.txt_name'), t('lang_qna.question')];
        const responses = activeTab === 'new' ? newQnas : answeredQnas;
        const responseDataWithSelectedFields = responses.map((res) => pick(res, ['participantName', 'question']));
        const processedResponses = responseDataWithSelectedFields.map((res) => ({
            participantName: res.participantName,
            responseData: res.question,
        }));

        const payload = {
            activityType: utilConstants.ACTIVITY_TYPE.QNA,
            title: `${t('lang_qna.qna')} (${
                activeTab === 'new' ? t('lang_qna.not_answered') : t('lang_qna.answered')
            })`,
            isInsertAsTable: true,
            headerColumns,
            responses: processedResponses,
        };
        webviewMessenger.sendInsertAsSlide(JSON.stringify(payload));
    };

    const handleChangeTab = (activeTab: 'new' | 'answered') => {
        if (!classSession) return;
        const { newQnas, answeredQnas, savedClassGroups } = refreshQnaAndSort(classSession);
        updateQnaState(QnaActionType.REFRESH_QNA, { newQnas, answeredQnas, savedClassGroups });
        updateQnaState(QnaActionType.CHANGE_TAB, { activeTab });
    };

    return (
        <>
            <div className={`class_code `}>
                <div className={`qnaModule switches-container ${activeTab === 'answered' && 'switch_box'}`}>
                    <label onClick={() => handleChangeTab('new')}>
                        {t(`lang_qna.new`)} <span className={`highlight`}>{newQnas.length}</span>
                    </label>
                    <label onClick={() => handleChangeTab('answered')}>
                        {t(`lang_qna.answered`)} <span className={`highlight`}>{answeredQnas.length}</span>
                    </label>
                    <div className="switch-wrapper">
                        <div className="switch">
                            <div>
                                {t(`lang_qna.new`)} <span className={`highlight`}>{newQnas.length}</span>
                            </div>
                            <div>
                                {t(`lang_qna.answered`)} <span className={`highlight`}>{answeredQnas.length}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="live_status">
                {((activeTab === 'new' && newQnas.length > 0) ||
                    (activeTab === 'answered' && answeredQnas.length > 0)) && (
                    <h2 onClick={() => insertAsSlide()}>
                        <b>{t('lang_common.btn_insert_as_slide')}</b>
                    </h2>
                )}
            </div>
        </>
    );
};

export default QnaHeader;
