import { useTranslation } from 'react-i18next';

import AddIcon from '@mui/icons-material/Add';

import './GroupCards.scss';

import { GroupStageType, SavedClassGroupInterface } from '../../../../interfaces/savedClassGroup.interface';
import SavedClassParticipantInterface from '../../../../interfaces/saved-class-participant.interface';
import ParticipantInterface from '../../../../interfaces/participant.interface';
import { utilConstants } from '../../../../constants/utils.constants';

import SingleCard from './SingleCard';

interface IGroupCards {
    isViewingInMyClass?: boolean;
    savedClassGroups: SavedClassGroupInterface[];
    sortedParticipants: SavedClassParticipantInterface[];
    participantsInMyClass?: ParticipantInterface[];
    groupStageHandler: (value: GroupStageType) => void;
    editGroupClickHandler: (group: SavedClassGroupInterface) => void;
    toggleAddOrEditModal: (value: boolean) => void;
}

export default function GroupCards({
    isViewingInMyClass,
    savedClassGroups,
    sortedParticipants,
    participantsInMyClass,
    groupStageHandler,
    editGroupClickHandler,
    toggleAddOrEditModal,
}: IGroupCards) {
    const { t } = useTranslation();

    return (
        <div className={`savedClassGroupCardContainer`}>
            <div className={`savedClassGroups ${savedClassGroups.length <= 0 && 'centerAlign'}`}>
                {savedClassGroups.length > 0 &&
                    savedClassGroups.map((group) => (
                        <div
                            key={group.groupId}
                            onClick={() => editGroupClickHandler(group)}
                            className={`groupCardContainer`}
                        >
                            <SingleCard
                                isViewingInMyClass={isViewingInMyClass}
                                group={group}
                                sortedParticipants={sortedParticipants}
                                participantsInMyClass={participantsInMyClass}
                                savedClassGroups={savedClassGroups}
                            />
                        </div>
                    ))}

                {savedClassGroups.length < utilConstants.GROUP_COLORS.length &&
                    savedClassGroups.length < sortedParticipants.length && (
                        <div
                            onClick={() => {
                                groupStageHandler('group');
                                toggleAddOrEditModal(true);
                            }}
                            className={`addBtn`}
                        >
                            <AddIcon className={`icon`} />
                            {t('lang_grouping.add_new_group')}
                        </div>
                    )}
            </div>
        </div>
    );
}
