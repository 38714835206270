import { BrowserTabsActionTypes } from '../constants/browser-tabs-action-types';
import { UpdateToolbarActionsType } from '../dtos/update-toolbar-actions.dto';
import { getBrowserTabsFromStore } from '../helpers/storeHelpers';
import { localService } from '../services/localStorageService';
import webviewBrowserCommands from '../services/webviewBrowserCommands';

export const browserTabsActions = {
    updateBrowserTabs,
    openNewTab,
    switchBrowserTabs,
    createNewTab,
};

function openNewTab(newTabData: any) {
    return async (dispatch: (arg0: { type: any; payload: any }) => void) => {
        const browserTabs = getBrowserTabsFromStore();
        const updatedTabs = browserTabs.map((tab) => {
            tab.active = false;
            return tab;
        });
        const newtabs = [...updatedTabs, newTabData];
        const payload = newtabs;
        dispatch({
            type: BrowserTabsActionTypes.OPEN_NEW_TAB,
            payload,
        });
        localService.updateToolbarActionsFromWebview(UpdateToolbarActionsType.openBrowserTab);
    };
}

function createNewTab(data: any) {
    return async (dispatch: (arg0: { type: any; payload: any }) => void) => {
        const browserTabs = getBrowserTabsFromStore();

        let newTabId = 1;
        if (browserTabs.length > 0) {
            const maxTabId = Math.max(...browserTabs.map((tab) => tab.tabId));
            newTabId = maxTabId + 1;
        }
        const newTabData = {
            tabId: newTabId,
            active: true,
            isLoading: true,
        };
        const updatedTabs = browserTabs.map((tab) => {
            tab.active = false;
            return tab;
        });
        const newtabs = [...updatedTabs, newTabData];
        const payload = newtabs;
        // console.log('payload', payload);
        dispatch({
            type: BrowserTabsActionTypes.OPEN_NEW_TAB,
            payload,
        });
        webviewBrowserCommands.createTab(newTabId, data.uri, true);
        localService.updateToolbarActionsFromWebview(UpdateToolbarActionsType.openBrowserTab);
    };
}

function switchBrowserTabs(type: string, data: any) {
    return async (dispatch: (arg0: { type: any; payload: any }) => void) => {
        const browserTabs = getBrowserTabsFromStore();
        const updatedTabs = browserTabs.map((tab) => {
            if (tab.tabId === data.tabId) tab.active = true;
            else tab.active = false;
            return tab;
        });
        const newtabs = [...updatedTabs];
        console.log('newtabs', newtabs);
        const payload = newtabs;
        dispatch({
            type,
            payload,
        });
    };
}

function updateBrowserTabs(type: string, payload: any) {
    return async (dispatch: (arg0: { type: any; payload: any }) => void) => {
        const tabId = payload.tabId;
        const browserTabs = getBrowserTabsFromStore();
        const currentTab = browserTabs.filter((tab) => tab.tabId === tabId);
        const data = {
            ...currentTab[0],
            ...payload,
        };
        console.log('data', data);
        dispatch({
            type,
            payload,
        });
    };
}
