import { FormEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { TextField } from '@mui/material';

import { userActions } from '../../../actions/user.actions';
import apiUser from '../../../services/apiUser';
import {
    ResetPasswordInterface,
    ForgotPasswordStepType,
    CurrentPageType,
} from '../../../interfaces/signInSignUp.interface';
import { INPUT_LIMITS, utilConstants } from '../../../constants/utils.constants';

import CommonButton from '../../../components/Common/CommonButton';

interface ForgotPasswordEmailInterface {
    forgotPasswordDetails: ResetPasswordInterface;
    setForgotPasswordDetails: any;
    setForgotPasswordStep: (arg: ForgotPasswordStepType) => void;
    setCurrentPage: (arg: CurrentPageType) => void;
}

export const ForgotPasswordEmail = ({
    forgotPasswordDetails,
    setForgotPasswordDetails,
    setForgotPasswordStep,
    setCurrentPage,
}: ForgotPasswordEmailInterface) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [emailError, setEmailError] = useState('');

    const emailValidation = yup.object({
        email: yup
            .string()
            .required('emailError:error_email_required')
            .email('emailError:error_email_is_invalid')
            .matches(utilConstants.REGEX.EMAIL, 'emailError:error_email_is_invalid'),
    });

    const onChangeHandler = async (emailValue: string) => {
        setForgotPasswordDetails((prevState: ResetPasswordInterface) => ({ ...prevState, email: emailValue.trim() }));
        setEmailError('');
    };

    const submitFormHandler = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const isValidEmail = await emailValidation.isValid({
            email: forgotPasswordDetails.email,
        });

        if (isValidEmail) {
            setIsLoading(true);
            const user = await apiUser.getUser(forgotPasswordDetails.email);
            setIsLoading(false);
            if (!user) return setEmailError('lang_auth.error_api_sign_in_404');

            setForgotPasswordDetails((prevState: ResetPasswordInterface) => ({
                ...prevState,
                userADId: user.userADId,
            }));
            setIsLoading(true);
            const sendVerificationCodeSuccess = await apiUser.sendVerificationCode(forgotPasswordDetails.email);
            setIsLoading(false);

            if (!sendVerificationCodeSuccess) return dispatch(userActions.showApiError());
            setForgotPasswordStep(ForgotPasswordStepType.VerificationCode);
        } else {
            emailValidation.validate({ email: forgotPasswordDetails.email }).catch((data) => {
                const valuePair = data.errors[0].split(':');
                setEmailError(`lang_auth.${valuePair[1]}`);
            });
        }
    };

    return (
        <>
            <div className="intro">
                <h4>{t(`lang_auth.txt_forgotPassword`)}</h4>
                <p>{t(`lang_auth.txt_enterEmail`)}</p>
            </div>

            <form className="form" onSubmit={submitFormHandler}>
                <div className="profile_left">
                    <div className="pf_form">
                        <TextField
                            onChange={(e) => onChangeHandler(e.target.value)}
                            value={forgotPasswordDetails.email}
                            error={emailError ? true : false}
                            helperText={t(`${emailError}`)}
                            inputProps={{ maxLength: INPUT_LIMITS.EMAIL_MAX }}
                            label={t(`lang_auth.input_email`)}
                            variant="standard"
                        />
                    </div>
                    <div className="profile_update_btn">
                        <CommonButton
                            isLoading={isLoading}
                            variant="contained"
                            type="submit"
                            color="primary"
                            text={t(`lang_common.btn_next`)}
                        />
                    </div>
                </div>
            </form>
        </>
    );
};
