import { useState } from 'react';
import { FitToViewport } from 'react-fit-to-viewport';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import '../VideoTutorial/VideoTutorial.scss';
import './TutorialLanding.scss';

import webviewMessenger from '../../services/webviewMessenger';
import { ViewportSavedClasses } from '../../constants/viewport-constants';

import VideoModal from './Components/VideoModal';
import { Button } from '@mui/material';
import { CP_CONTENT_URL } from '../../constants/utils.constants';
import { useTranslation } from 'react-i18next';

interface TutorialLandingInterface {
    setShowAllVideos: (arg: boolean) => void;
}

export default function TutorialLanding({ setShowAllVideos }: TutorialLandingInterface) {
    const { t } = useTranslation();
    const [tutorialVideoId, setTutorialVideoId] = useState('');
    const OVERVIEW_1 = 'overview_gamification';
    const OVERVIEW_2 = 'overview_presentation_tools';
    const OVERVIEW_3 = 'overview_interactive_quiz_female';

    return (
        <FitToViewport
            className="viewport"
            width={ViewportSavedClasses.width}
            height={ViewportSavedClasses.height}
            minZoom={ViewportSavedClasses.minZoom}
            maxZoom={ViewportSavedClasses.maxZoom}
        >
            {tutorialVideoId && (
                <VideoModal
                    tutorialVideoId={tutorialVideoId}
                    setTutorialVideoId={setTutorialVideoId}
                    viewportConstant={ViewportSavedClasses}
                    isFullScreenHidden={true}
                />
            )}

            <div className="tutorialComponent">
                <div className="new_Welcome_inner_sec">
                    <div className="new_welcome_content">
                        <h2 className="title">{t('lang_video_tutorial.title')}</h2>
                        <p className="description">{t('lang_video_tutorial.paragraph')}</p>

                        <div className="navigation_grid_box">
                            <div
                                onClick={() => {
                                    webviewMessenger.sendUsageLog(`Clicked "Gamification overview" video`);
                                    setTutorialVideoId(OVERVIEW_1);
                                }}
                                className="grid_box"
                            >
                                <div className="thumbnail_box">
                                    <img
                                        src={`${CP_CONTENT_URL}/tutorial/${OVERVIEW_1}_thumb.webp`}
                                        alt="Gamification overview"
                                        className={`videoThumbnail`}
                                    />
                                    <div className={`overlay`}>
                                        <PlayArrowIcon className={`playIcon`} />
                                    </div>
                                </div>
                                <p className="title">{t('lang_video_tutorial.gamification')}</p>
                            </div>
                            <div
                                onClick={() => {
                                    webviewMessenger.sendUsageLog(`Clicked "Presentation tools overview" video`);
                                    setTutorialVideoId(OVERVIEW_2);
                                }}
                                className="grid_box"
                            >
                                <div className="thumbnail_box">
                                    <img
                                        src={`${CP_CONTENT_URL}/tutorial/${OVERVIEW_2}_thumb.webp`}
                                        alt="Presentation tools overview"
                                        className={`videoThumbnail`}
                                    />
                                    <div className={`overlay`}>
                                        <PlayArrowIcon className={`playIcon`} />
                                    </div>
                                </div>
                                <p className="title">{t('lang_video_tutorial.presentation_tools')}</p>
                            </div>
                            <div
                                onClick={() => {
                                    webviewMessenger.sendUsageLog(`Clicked "Interactive quizzes overview" video`);
                                    setTutorialVideoId(OVERVIEW_3);
                                }}
                                className="grid_box"
                            >
                                <div className="thumbnail_box">
                                    <img
                                        src={`${CP_CONTENT_URL}/tutorial/${OVERVIEW_3}_thumb.webp`}
                                        alt="Interactive quizzes overview"
                                        className={`videoThumbnail`}
                                    />
                                    <div className={`overlay`}>
                                        <PlayArrowIcon className={`playIcon`} />
                                    </div>
                                </div>
                                <p className="title">{t('lang_video_tutorial.interactive_quizzes')}</p>
                            </div>
                        </div>
                        <div className="link_group">
                            <Button
                                onClick={() => {
                                    webviewMessenger.sendUsageLog(`Clicked "Watch all videos" link`);
                                    setShowAllVideos(true);
                                }}
                                className="grid_box watchAllBtn"
                            >
                                {t('lang_video_tutorial.watch_all_videos')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </FitToViewport>
    );
}
