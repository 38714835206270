import { Fragment } from 'react';
import { FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

const CommonCheckBox = (props: any) => {
    return (
        <Fragment>
            {props.isHookRegistered ? (
                <FormControlLabel
                    control={<Checkbox checked={props.defaultChecked} />}
                    value={props.value}
                    label={props.label}
                    onChange={props.changeFunction}
                    {...props.register(props.registerName, props.validationObject)}
                    disabled={props.disabled}
                />
            ) : (
                <FormControlLabel
                    control={<Checkbox checked={props.defaultChecked} />}
                    value={props.value}
                    label={props.label}
                    onChange={props.changeFunction}
                    disabled={props.disabled}
                />
            )}
        </Fragment>
    );
};

export default CommonCheckBox;
