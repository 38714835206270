import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import CustomAvatar, { AvatarType } from '../../../components/Common/CustomAvatar';
import { utilConstants } from '../../../constants/utils.constants';
import { QuizSummaryParticipantInterface } from '../../../helpers/quizSummaryHelper';

const ParticipentPointTable = ({ rankedParticipants }: { rankedParticipants: QuizSummaryParticipantInterface[] }) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div className="participent_points_table ">
                <div className="quizResponseContainer">
                    <div className="table-container">
                        <div className="ans_table_inner timepoints">
                            <TableContainer component={Paper} sx={{ maxHeight: 449 }}>
                                <Table sx={{ minWidth: '100%' }} aria-label="customized sticky table" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">{t('lang_common.txt_rank')}</TableCell>
                                            <TableCell align="left">{t('lang_myclass.txt_name')}</TableCell>
                                            <TableCell align="center">{t('lang_common.txt_participation')}</TableCell>
                                            <TableCell align="center">{t('lang_common.txt_correct')}</TableCell>
                                            <TableCell align="center">{t('lang_common.txt_stars')}</TableCell>
                                            <TableCell align="center">{t('lang_common.txt_speed')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rankedParticipants.map((participant, index: number) => (
                                            <TableRow key={index}>
                                                <TableCell align="center" scope="row">
                                                    <Typography variant="caption" className="number">
                                                        {participant.rank}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <div className="profile">
                                                        <CustomAvatar
                                                            avatarName={participant.participantName}
                                                            avatarUrl={participant.participantAvatar}
                                                            type={AvatarType.PARTICIPANT}
                                                        />
                                                        <Typography variant="body1">
                                                            {participant.participantName}
                                                        </Typography>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Typography variant="body1">
                                                        {participant.participationCount}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Typography
                                                        variant="body1"
                                                        className={participant.correctCount > 0 ? 'green' : ''}
                                                    >
                                                        {participant.correctCount}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="center" className="star">
                                                    {participant.totalQuizPoints > 0 && (
                                                        <Typography variant="body1">
                                                            <img
                                                                src={utilConstants.IMAGE_URLS.quizSummaryIconStar}
                                                                alt="staricon"
                                                            />
                                                            {participant.totalQuizPoints}
                                                        </Typography>
                                                    )}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <div className="avg_time">
                                                        {participant.averageTimeTaken > 0 && (
                                                            <Typography variant="body1">
                                                                {participant.averageTimeTaken.toFixed(1)}
                                                                <span className="blue"> S</span>
                                                            </Typography>
                                                        )}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ParticipentPointTable;
