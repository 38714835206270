import { useRef, useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FitToViewport } from 'react-fit-to-viewport';
import { motion } from 'framer-motion';
import { Box, Modal, Typography } from '@mui/material';

import { classSessionActions } from '../../../actions/class-session.action';
import CustomAvatar, { AvatarType } from '../../../components/Common/CustomAvatar';
import { AnimationConfig, ModalAnimationConfig } from '../../../constants/animation-configs';
import ParticipantAndPointsDto from '../../../dtos/participant-and-points.dto';
import PickNamesParticipantInterface from '../../../interfaces/pick-names-participant.interface';
import { FullScreenPointLottie, SparkleLottie } from '../../../components/Common/AnimationModules';
import GivePointButton from '../../../components/Common/GivePointButton';
import { ViewportConstants } from '../../../constants/viewport-constants';
import { pickNamesActions } from '../../../actions/pickNames.action';
import { localService } from '../../../services/localStorageService';
import { UpdateToolbarActionsType } from '../../../dtos/update-toolbar-actions.dto';
import StoreInterface from '../../../interfaces/store.interface';

interface PickedParticipantModalInterface {
    isPickedModalOpen: boolean;
    setIsPickedModalOpen: (arg: boolean) => void;
    pickedParticipant: PickNamesParticipantInterface;
    isAnimating: boolean;
    setIsAnimating: (arg: boolean) => void;
    givePointsConfettiRef: any;
}

const PickedParticipantModal = ({
    isPickedModalOpen,
    setIsPickedModalOpen,
    pickedParticipant,
    isAnimating,
    setIsAnimating,
    givePointsConfettiRef,
}: PickedParticipantModalInterface) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const classSession = useSelector((state: StoreInterface) => state.classSession);

    const sparkleRef = useRef<any>();
    const textRef = useRef<any>();

    const timeOutPointUpdate = useRef<any>();
    const [pointsAdded, setPointsAdded] = useState(0);

    const backToWheelHandler = () => {
        dispatch(pickNamesActions.pickOneName(pickedParticipant.participantId, false));
        setIsPickedModalOpen(false);
        localService.removePickedNamesInModal();
    };

    const closeModalHandler = () => {
        !isAnimating && setIsPickedModalOpen(false);
        localService.removePickedNamesInModal();
    };

    const givePoint = (points: number) => {
        const participantsAndPoints: ParticipantAndPointsDto[] = [
            { participantId: pickedParticipant.participantId, points: points },
        ];
        localService.updateToolbarActionsFromWebview(UpdateToolbarActionsType.awardStar);
        dispatch(classSessionActions.givePointsToParticipants(participantsAndPoints));
    };

    const givePointHandler = () => {
        clearTimeout(timeOutPointUpdate.current);
        setIsAnimating(true);
        // sparkleRef.current.playLottieAnimation(true);
        givePointsConfettiRef.current.playLottieAnimation(true);
        setPointsAdded(pointsAdded + 1);
    };

    useEffect(() => {
        if (pointsAdded > 0) {
            timeOutPointUpdate.current = setTimeout(() => {
                givePoint(pointsAdded);
                setPointsAdded(0);
                setTimeout(() => {
                    setIsAnimating(false);
                    // sparkleRef.current.playLottieAnimation(false);
                    if (givePointsConfettiRef.current) givePointsConfettiRef.current.playLottieAnimation(false);
                    // }, AnimationConfig.BUTTON_STAR_LOTTIE_DURATION - AnimationConfig.GIVE_POINT_DELAY);
                }, AnimationConfig.CONFETTI_LOTTIE_DURATION - AnimationConfig.GIVE_POINT_DELAY);
            }, AnimationConfig.GIVE_POINT_DELAY);
        }
        return () => clearTimeout(timeOutPointUpdate.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pointsAdded]);

    return (
        <Modal
            open={isPickedModalOpen}
            onClose={() => closeModalHandler()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="flipcard_modal"
        >
            <Fragment>
                <FitToViewport
                    className="modal_viewport"
                    width={ViewportConstants.width}
                    height={ViewportConstants.height}
                    minZoom={ViewportConstants.minZoom}
                    maxZoom={ViewportConstants.maxZoom}
                >
                    <>
                        <FullScreenPointLottie ref={givePointsConfettiRef} />
                        <div className="close_modal" onClick={() => closeModalHandler()}></div>
                        <Box>
                            <div className="pickNumber_service">
                                <div className={'flip-box flip180'}>
                                    <div className="flip-box-inner">
                                        <motion.div
                                            variants={ModalAnimationConfig}
                                            initial="initial"
                                            animate="animate"
                                            className="flip-box-back"
                                        >
                                            <div
                                                className="flip_head"
                                                style={{
                                                    backgroundColor: pickedParticipant.colorHex,
                                                }}
                                            >
                                                <Typography variant="h4">
                                                    {t('lang_pick_names.txt_we_have_name')}
                                                </Typography>
                                            </div>

                                            <div className="flip_body pickNameSparkleLottie">
                                                <CustomAvatar
                                                    avatarName={pickedParticipant.participantName}
                                                    avatarUrl={pickedParticipant.participantAvatar}
                                                    type={AvatarType.PARTICIPANT}
                                                    savedClassGroups={classSession?.groups}
                                                    groupId={pickedParticipant.groupId}
                                                />
                                                <p ref={textRef} className="profile_name">
                                                    {pickedParticipant.participantName}
                                                </p>

                                                <SparkleLottie ref={sparkleRef} />

                                                <div className="pickname_star">
                                                    <span className="backToWheel" onClick={() => backToWheelHandler()}>
                                                        {t('lang_pick_names.btn_put_back_wheel')}
                                                    </span>
                                                    <GivePointButton
                                                        activity="pickNames"
                                                        number={null}
                                                        onClick={() => givePointHandler()}
                                                    />
                                                </div>
                                            </div>
                                        </motion.div>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </>
                </FitToViewport>
            </Fragment>
        </Modal>
    );
};

export default PickedParticipantModal;
