import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { webviewSettingsActions } from '../../../actions/webview-settings.actions';
import { WebviewSettingsActionTypes } from '../../../constants/webview-settings-action-types';
import StoreInterface from '../../../interfaces/store.interface';

const PickNamesHeader = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const webviewSettingsSlideshow = useSelector((state: StoreInterface) => state.webviewSettingsSlideshow);
    const isPickNamesModeWheel = webviewSettingsSlideshow.isPickNamesModeWheel;
    const isPickNameShowOnlineParticipant = webviewSettingsSlideshow.isPickNameShowOnlineParticipant;

    const handlePickNamesModeChange = () => {
        dispatch(
            webviewSettingsActions.updateWebviewSettings(WebviewSettingsActionTypes.UPDATE_PICK_NAMES_MODE, {
                ...{
                    isPickNamesModeWheel: !isPickNamesModeWheel,
                },
            }),
        );
    };

    const showOnlineParticipantHandler = (value: boolean) => {
        dispatch(
            webviewSettingsActions.updateWebviewSettings(
                WebviewSettingsActionTypes.TOGGLE_PICK_NAME_SHOW_ONLINE_PARTICIPANT,
                {
                    isPickNameShowOnlineParticipant: value,
                },
            ),
        );
    };

    return (
        <Fragment>
            <div className="class_code">
                <FormControlLabel
                    control={
                        <Switch
                            onChange={(e) => showOnlineParticipantHandler(e.target.checked)}
                            checked={isPickNameShowOnlineParticipant}
                        />
                    }
                    label={t('lang_pick_names.toggle_show_online_participants_only')}
                />
            </div>
            <div className="live_status">
                <h2 onClick={() => handlePickNamesModeChange()}>
                    {!isPickNamesModeWheel ? (
                        <b>{t('lang_pick_names.btn_change_to_wheel_view')}</b>
                    ) : (
                        <b>{t('lang_pick_names.btn_change_to_card_view')}</b>
                    )}
                </h2>
            </div>
        </Fragment>
    );
};

export default PickNamesHeader;
