import { Link, Modal } from '@mui/material';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { getValidUserFromStore } from '../../../helpers/storeHelpers';
import { isUserOnTrialOrPro } from '../../../helpers/userhelper';
import SavedClassForUserInterface from '../../../interfaces/saved-class-for-user.interface';
import webviewMessenger from '../../../services/webviewMessenger';
import EditSavedClassModal from './Modal/EditSavedClassModal';

interface EditDeleteSavedClassInterface {
    savedClass: SavedClassForUserInterface;
    setSavedClass: (arg: SavedClassForUserInterface) => void;
    onDeleteSavedClass: () => void;
    onResetStars: () => void;
}

const EditDeleteSavedClass = ({
    savedClass,
    setSavedClass,
    onDeleteSavedClass,
    onResetStars,
}: EditDeleteSavedClassInterface) => {
    const { t } = useTranslation();
    const user = getValidUserFromStore('EditDeleteSavedClass');
    const isProUser = isUserOnTrialOrPro(user);
    const [isEditClassModalOpen, setIsEditClassModalOpen] = React.useState(false);

    return (
        <Fragment>
            <Modal
                open={isEditClassModalOpen}
                onClose={() => setIsEditClassModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="csv_upload_modal"
            >
                <EditSavedClassModal
                    closeAlert={() => setIsEditClassModalOpen(false)}
                    savedClass={savedClass}
                    setSavedClass={setSavedClass}
                />
            </Modal>
            <div className="edit_Delete_sec">
                <ul>
                    {savedClass && savedClass.savedClassCode && (
                        <li>
                            <Link
                                className="edit"
                                onClick={() => {
                                    webviewMessenger.sendUsageLog(`Clicked "Edit class name or code"`);
                                    setIsEditClassModalOpen(true);
                                }}
                            >
                                {t('lang_saved_classes.btn_edit_class')}
                            </Link>
                        </li>
                    )}
                    <li className="reset_box">
                        {isProUser && savedClass.participantsCount > 0 && (
                            <Link
                                className="resset flex1"
                                onClick={() => {
                                    webviewMessenger.sendUsageLog(`Clicked "Reset stars"`);
                                    onResetStars();
                                }}
                            >
                                {t('lang_saved_classes.btn_reset_stars')}
                            </Link>
                        )}
                        <Link
                            className="editdelete flex1"
                            onClick={() => {
                                webviewMessenger.sendUsageLog(`Clicked "Delete class"`);
                                onDeleteSavedClass();
                            }}
                        >
                            {t('lang_saved_classes.btn_delete_class')}
                        </Link>
                    </li>
                </ul>
            </div>
        </Fragment>
    );
};

export default EditDeleteSavedClass;
