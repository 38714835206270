import { Button, Divider, FormControl, FormGroup, Typography } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import CommonButton from '../../../components/Common/CommonButton';
import CommonCheckBox from '../../../components/Common/CommonCheckBox';
import { Playslide, Slideright } from './Icons';
import webviewMessenger from '../../../services/webviewMessenger';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import StoreInterface from '../../../interfaces/store.interface';
import { exportPdfActions } from '../../../actions/export-pdf.action';

const Generatepdf = ({ activePageHandler }: any) => {
    const dispatch = useDispatch();
    const state = useSelector((state: StoreInterface) => state);
    const exportPdf = state.exportPdf;
    const [activeButton, setActiveButton] = useState('all');
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    const [isChecked, setChecked] = useState(false);
    const [isIncludeAnnotation, setIsIncludeAnnotation] = useState(true);
    const [isIncludeWhiteboard, setIsIncludeWhiteboard] = useState(true);

    useEffect(() => {
        setIsIncludeAnnotation(exportPdf.isIncludeAnnotation);
        setIsIncludeWhiteboard(exportPdf.isIncludeWhiteboard);
    }, [exportPdf]);

    const [isAllSelected, setAllSelected] = useState(true);
    const slideButtonToggleHandler = (type: string) => {
        setActiveButton(type);
        setAllSelected(type === 'all');
    };

    const { t } = useTranslation();

    const generatePdfHandler = () => {
        let selectedOnly;
        if (activeButton === 'all') {
            selectedOnly = false;
            webviewMessenger.sendExportPdf(selectedOnly, isIncludeAnnotation, isIncludeWhiteboard);
        } else {
            selectedOnly = true;
            webviewMessenger.sendExportPdf(selectedOnly, isIncludeAnnotation, isIncludeWhiteboard);
        }
        dispatch(exportPdfActions.includeAnnotationWhiteboard(isIncludeAnnotation, isIncludeWhiteboard));
        activePageHandler('loadingPage');
    };

    return (
        <Fragment>
            <div className="generate_sec">
                <div className="generate_head">
                    <Typography variant="body1">{t('lang_share_pdf.txt_choose_slide_to_share')}</Typography>
                </div>
                <div className="Shareimg_btn_group ">
                    <Button
                        onClick={() => slideButtonToggleHandler('all')}
                        size="large"
                        className={isAllSelected ? 'active' : ''}
                    >
                        <Playslide />
                        {t('lang_share_pdf.btn_all')}
                    </Button>

                    <Button
                        onClick={() => slideButtonToggleHandler('selected')}
                        size="large"
                        className={isAllSelected ? '' : 'active'}
                    >
                        <Slideright />
                        {t('lang_share_pdf.btn_selected')} ({exportPdf.selectedSlidesCount})
                    </Button>
                </div>
                <Divider />
                <div className="annotaion">
                    <FormControl
                        sx={{
                            m: 2,
                        }}
                        component="fieldset"
                        variant="standard"
                    >
                        <FormGroup>
                            <Fragment
                            // key={index}
                            >
                                <div className="checkbox">
                                    <CommonCheckBox
                                        isHookRegistered={false}
                                        label={t('lang_share_pdf.chk_include_annotations')}
                                        value={isIncludeAnnotation}
                                        defaultChecked={isIncludeAnnotation}
                                        changeFunction={(e: any) => {
                                            setChecked(e.target.checked);
                                            setIsIncludeAnnotation(e.target.checked);
                                        }}
                                    />
                                </div>
                                <div className="checkbox">
                                    <CommonCheckBox
                                        isHookRegistered={false}
                                        label={t('lang_share_pdf.chk_include_whiteboard')}
                                        value={isIncludeWhiteboard}
                                        defaultChecked={isIncludeWhiteboard}
                                        changeFunction={(e: any) => {
                                            setChecked(e.target.checked);
                                            setIsIncludeWhiteboard(e.target.checked);
                                        }}
                                    />
                                </div>
                            </Fragment>
                        </FormGroup>
                    </FormControl>
                </div>
                <div className="profile_update_btn">
                    <CommonButton
                        isLoading={false}
                        variant="contained"
                        color="primary"
                        text={t('lang_share_pdf.btn_generate_pdf')}
                        clickFunction={() => {
                            generatePdfHandler();
                        }}
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default Generatepdf;
