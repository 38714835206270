import _ from 'lodash';
import { utilConstants } from '../constants/utils.constants';
import { MusicLibrary } from '../constants/music-library';
import { localService } from '../services/localStorageService';
const semver = require('semver');

export const getRandomMusic = () => {
    const musicIndex = Math.floor(Math.random() * MusicLibrary.length);
    const musicUrl = `${MusicLibrary[musicIndex]}?${Date.now()}`;
    return musicUrl;
};

export const isJson = (jsonString: string) => {
    try {
        JSON.parse(jsonString);
    } catch (error) {
        return false;
    }
    return true;
};

export const getFirstLettersFromName = (name: string) => {
    const firstLetters = name
        .split(' ')
        .slice(0, 2)
        .map((word) => word[0])
        .join('');
    return firstLetters;
};

export const stringToNumber = (inputString: string): number => {
    let outputNumber = 0;

    for (let i = 0; i < inputString.length; i++) {
        let code = inputString.charCodeAt(i);
        outputNumber += code;
    }
    return outputNumber;
};

export const getColorForString = (inputString: string) => {
    const colorIndex = stringToNumber(inputString) % utilConstants.AVATAR_COLORS.length;
    return utilConstants.AVATAR_COLORS[colorIndex];
};

export const generateAsterisksFromName = (name: string) => {
    let asterisksLength = stringToNumber(name) % 6;
    asterisksLength = 3;
    return Array(asterisksLength + 4)
        .fill('*')
        .join('');
};

export const findLargestNumberInArray = (array: number[]): number => {
    if (array.length <= 0) return 0;
    return _.sortBy(array)[array.length - 1];
};

export const dateTimeForDownLoad = () => {
    const dateObj = new Date();
    const month = ('0' + (dateObj.getUTCMonth() + 1)).slice(-2); //months from 1-12
    const date = ('0' + (dateObj.getUTCDate() + 1)).slice(-2);
    const year = dateObj.getUTCFullYear();
    const hours = ('0' + (dateObj.getUTCHours() + 1)).slice(-2);
    const minutes = ('0' + (dateObj.getUTCMinutes() + 1)).slice(-2);
    const seconds = ('0' + (dateObj.getUTCSeconds() + 1)).slice(-2);
    return year + month + date + hours + minutes + seconds;
};

export const localDateForFileName = () => {
    const dateObj = new Date();
    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2); //months from 1-12
    const date = ('0' + dateObj.getDate()).slice(-2);
    const year = dateObj.getFullYear();
    return `${year}${month}${date}`;
};

export const addLeadingZeros = (num: string, digits: number): string => {
    var s = '000000000' + num;
    return s.substring(s.length - digits);
};

export const dataURLtoFile = (dataurl: any, filename: string) => {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
};

export const generatefileName = (email: string, file: { name: string }, originalFileName: string) => {
    const result = email.replace(/@/g, '-');
    // var ext = file.name.split('.').pop();
    const fileName = result + '-' + originalFileName;
    return fileName;
};

export const dataURItoBlob = async (dataURI: any) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
    else byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
};

export const getBase64 = async (fileurl: RequestInfo) => {
    const uid = new Date().getTime().toString(36);
    const data = await fetch(fileurl + '?' + uid);
    const blob = await data.blob();
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
        };
    });
};

export const getBase64FromFile = (file: any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
};

export const hasAlpha = (context: any, canvas: any) => {
    var data = context.getImageData(0, 0, canvas.width, canvas.height).data,
        hasAlphaPixels = false;
    for (var i = 3, n = data.length; i < n; i += 4) {
        if (data[i] < 255) {
            hasAlphaPixels = true;
            break;
        }
    }
    return hasAlphaPixels;
};

export const fileurlToFile = (url: any) =>
    fetch(url)
        .then((response) => response.blob())
        .then(
            (blob) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                }),
        );

export const base64TextToImage = (url: any) =>
    fetch(url)
        .then((response) => response.blob())
        .then(
            (blob) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsText(blob);
                }),
        );

export const isCpVerAtLeast = (ver: string) => {
    const cpVer = getCpVer();
    return semver.gte(cpVer, ver);
};

export const isNewVerAtLeast = (ver: string) => {
    const newVer = localService.getNewVersionDownloaded();
    if (!newVer) return false;
    return semver.gte(newVer, ver);
};

export const getCpVer = () => {
    const statsFromHost = localService.getStatsFromHost();
    const cpVer = statsFromHost?.cpVer || '0.0.1';
    return cpVer;
};

export const createLogMessage = (payload: any, maxChar = 0) => {
    let msg = '';
    try {
        if (typeof payload === 'object') {
            msg = JSON.stringify(payload);
        } else {
            msg = payload + '';
        }
        if (maxChar === 0 || msg.length <= maxChar) return msg;
        return msg.substring(0, maxChar) + `... (${msg.substring(maxChar).length} chars remaining)`;
    } catch (error) {
        return `createLogMessage exception: ${error}`;
    }
};

export const numberWithThousandSeparator = (x: number, currencyCode: string) => {
    let separator = ',';
    switch (currencyCode) {
        case 'IDR':
            separator = '.';
            break;
        default:
            break;
    }

    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

export const shuffleArray = (array: any[]) => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
};
