import { Button, Typography } from '@mui/material';
import { Fragment, useRef } from 'react';
import useSound from 'use-sound';
import ActivityResponseInterface from '../../interfaces/activity-response.interface';
import { ButtonStar, ButtonLottie } from './AnimationModules';
import { getActivityFromStore, getValidUserFromStore } from '../../helpers/storeHelpers';
import { canEditResponses } from '../../helpers/activityHelpers';
import { SoundLibrary } from '../../constants/sound-library';

type StarProps = {
    activityName?: string;
    response?: ActivityResponseInterface;
    pointsAdded?: number;
    onGivePointButtonClick?: any;
};

function GivePointToResponse({ response, pointsAdded, onGivePointButtonClick, activityName }: StarProps) {
    const user = getValidUserFromStore('GivePointToResponse');
    const activity = getActivityFromStore();
    const starButtonRef = useRef<any>();
    const starLottieRef = useRef<any>();
    const [playSound] = useSound(SoundLibrary.GIVE_POINT);
    const canEditResponsesInView = canEditResponses(activity, user.email);

    const handleAddStar = () => {
        playSound();
        starButtonRef.current.playStarAnimation();
        starLottieRef.current.playLottieAnimation();
        onGivePointButtonClick && onGivePointButtonClick(response);
    };

    return (
        <Fragment>
            {activityName !== 'pickNames' && response ? (
                <Fragment>
                    {canEditResponsesInView ? (
                        <div
                            className={
                                response.responsePoints || pointsAdded ? 'star_point_btn pointactive' : 'star_point_btn'
                            }
                        >
                            <Button
                                className="star_img"
                                onClick={() => {
                                    handleAddStar();
                                }}
                            >
                                <ButtonStar ref={starButtonRef} />
                                <ButtonLottie ref={starLottieRef} />
                            </Button>
                            {response.responsePoints || pointsAdded ? (
                                <Typography variant="caption" className="star_point">
                                    {pointsAdded && pointsAdded > 0
                                        ? response.responsePoints + pointsAdded
                                        : response.responsePoints}
                                </Typography>
                            ) : null}
                        </div>
                    ) : (
                        <div className="star_point_btn pointactive nostarhover">
                            <Button className="star_img">
                                <ButtonStar ref={starButtonRef} />
                                {/* <ButtonLottie ref={starLottieRef} /> */}
                            </Button>
                            {response.responsePoints || pointsAdded ? (
                                <Typography variant="caption" className="star_point">
                                    {pointsAdded && pointsAdded > 0
                                        ? response.responsePoints + pointsAdded
                                        : response.responsePoints}
                                </Typography>
                            ) : null}
                        </div>
                    )}
                </Fragment>
            ) : (
                <div className="star_point_btn pointactive">
                    <Button
                        className="star_img"
                        onClick={() => {
                            handleAddStar();
                        }}
                    >
                        <ButtonStar ref={starButtonRef} />
                        <ButtonLottie ref={starLottieRef} />
                    </Button>
                    {pointsAdded ? (
                        <Typography variant="caption" className="star_point">
                            {pointsAdded && pointsAdded > 0 && pointsAdded}
                        </Typography>
                    ) : null}
                </div>
            )}
        </Fragment>
    );
}

export default GivePointToResponse;
