import { Fragment, useEffect, useState } from 'react';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Typography, Chip, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CommonButton from '../../../components/Common/CommonButton';
import { getValidUserFromStore } from '../../../helpers/storeHelpers';
import { QuotaConstants } from '../../../constants/quota-constants';
import { findUserClassLimit, isUserOnTrialOrPro } from '../../../helpers/userhelper';
import CustomAvatar, { AvatarType } from '../../../components/Common/CustomAvatar';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { AlertTypes } from '../../../constants/alert-types-enum';
import { userActions } from '../../../actions/user.actions';
import { UserActionTypes } from '../../../constants/user-action-types';
import apiSavedClasses from '../../../services/apiSavedClasses';
import { useDispatch } from 'react-redux';
import { UpgradeToProButton } from '../../../components/Common/UpgradeToProButton';
import webviewMessenger from '../../../services/webviewMessenger';

export interface SelectedClassInterface {
    classId: string;
    className: string;
    classCode: string;
    participantCount: number;
    active: boolean;
}

const RestartClassModal = ({
    triggerRestartClass,
    onCancelAlert,
    isProcessing,
    alertType,
    selectedClass,
    setSelectedClass,
    isAllowGuests,
    setIsAllowGuests,
    isSaveGuests,
    setIsSaveGuests,
}: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = getValidUserFromStore('RestartClassModal');
    const isTrialOrPro = isUserOnTrialOrPro(user);
    const userProfile = user.userProfile;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const savedClasses = userProfile?.savedClasses || [];

    const [disableGuest, setDisableGuest] = useState(true);
    const [selectedColumnGrid, setSelectedColumnGrid] = useState<string>('twoColumn');

    const publicClass: SelectedClassInterface = {
        classId: 'public',
        className: t('lang_myclass.opt_txt_new_public_class'),
        classCode: t('lang_myclass.opt_txt_random_code'),
        participantCount: 0,
        active: false,
    };

    useEffect(() => {
        setSelectedClass({ ...publicClass, active: true });
        syncSavedClasses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const syncSavedClasses = async () => {
        const updatedUser = await apiSavedClasses.syncSavedClasses(user.email, user.userId);
        if (updatedUser) dispatch(userActions.updateUser(UserActionTypes.SYNC_SAVED_CLASSES, updatedUser));
    };

    useEffect(() => {
        switch (savedClasses.length) {
            case 1:
                setSelectedColumnGrid('twoColumn');
                break;
            default:
                setSelectedColumnGrid('threeColumn');
                break;
        }
    }, [savedClasses]);

    if (!selectedClass) return null;
    return (
        <Fragment>
            <div className={savedClasses.length > 0 ? 'media flex_col switch_classes_body' : 'media'}>
                {savedClasses.length > 0 ? (
                    <Fragment>
                        <Typography variant="body1">{t('lang_myclass.txt_choose_class_alert')}</Typography>
                        <div className={`switch_class_wrapper`}>
                            <div className={`switch_classes ${selectedColumnGrid}`}>
                                <div
                                    className={
                                        selectedClass.classId === publicClass.classId && selectedClass.active
                                            ? `active switch_participent_box center`
                                            : `switch_participent_box center`
                                    }
                                    onClick={() => {
                                        setSelectedClass({ ...publicClass, active: true });
                                        setDisableGuest(true);
                                        setIsAllowGuests(false);
                                        setIsSaveGuests(false);
                                    }}
                                    onDoubleClick={() => {
                                        webviewMessenger.sendUsageLog(`[S] Double-clicked public class to start`);
                                        setSelectedClass({ ...publicClass, active: true });
                                        triggerRestartClass();
                                    }}
                                >
                                    <Typography variant="h4">{publicClass.className}</Typography>
                                    <div className="ramdom_code">
                                        <Typography variant="caption">{publicClass.classCode}</Typography>
                                    </div>
                                </div>
                                {savedClasses
                                    .slice(
                                        0,
                                        isTrialOrPro
                                            ? savedClasses.length
                                            : QuotaConstants.BASIC_USER_SAVED_CLASS_QUOTA,
                                    )
                                    .map((savedClass, index) => {
                                        return (
                                            <div
                                                className={
                                                    savedClass.savedClassId === selectedClass.classId &&
                                                    selectedClass.active
                                                        ? `active switch_participent_box ${
                                                              !savedClass.savedClassCode ? 'demo' : ''
                                                          }`
                                                        : `switch_participent_box ${
                                                              !savedClass.savedClassCode ? 'demo' : ''
                                                          }`
                                                }
                                                onClick={() => {
                                                    setSelectedClass({
                                                        classId: savedClass.savedClassId,
                                                        className: savedClass.savedClassName,
                                                        classCode: savedClass.savedClassCode,
                                                        participantCount: savedClass.participantsCount,
                                                        active: true,
                                                    });
                                                    setDisableGuest(false);
                                                }}
                                                onDoubleClick={() => {
                                                    if (
                                                        !isTrialOrPro &&
                                                        selectedClass.participantCount > findUserClassLimit(user)
                                                    )
                                                        return;
                                                    webviewMessenger.sendUsageLog(
                                                        `[S] Double-clicked saved class to start`,
                                                    );
                                                    setSelectedClass({
                                                        classId: savedClass.savedClassId,
                                                        className: savedClass.savedClassName,
                                                        classCode: savedClass.savedClassCode,
                                                        participantCount: savedClass.participantsCount,
                                                        active: true,
                                                    });
                                                    triggerRestartClass();
                                                }}
                                                key={index}
                                            >
                                                <div className="participent_grid_top">
                                                    <div className="profile">
                                                        <div className="profile_text">
                                                            <Typography variant="h4" className="title">
                                                                {savedClass.savedClassName}
                                                            </Typography>
                                                        </div>
                                                        <div className="profile_img">
                                                            <CustomAvatar
                                                                avatarName={savedClass.savedClassName}
                                                                avatarUrl={savedClass.savedClassAvatar}
                                                                type={AvatarType.SAVEDCLASS}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="total_participent">
                                                    <div className="p_class_code">
                                                        <Chip
                                                            label={
                                                                savedClass.savedClassCode
                                                                    ? savedClass.savedClassCode
                                                                    : t('lang_myclass.opt_txt_random_code')
                                                            }
                                                        />
                                                    </div>
                                                    <GroupOutlinedIcon />
                                                    <Typography variant="caption">
                                                        {savedClass.participantsCount}
                                                    </Typography>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className="media_icon">
                            <ReportProblemIcon className="warning" />
                        </div>
                        <div className="media_body">
                            <div className="alert_content">
                                <Typography>{t('lang_myclass.txt_confirm_restart_class_no_saved_class')}</Typography>
                            </div>
                        </div>
                    </Fragment>
                )}
            </div>
            {alertType === AlertTypes.RESTART_CLASS && savedClasses.length > 0 ? (
                <>
                    {!isTrialOrPro && selectedClass.participantCount > findUserClassLimit(user) ? (
                        <>
                            <div className="isproUser_participantCount">
                                <Typography className="classLimitWarning">
                                    {t('lang_myclass.err_basic_user_class_limit', {
                                        basicClassLimit: findUserClassLimit(user),
                                    })}
                                </Typography>
                                <UpgradeToProButton caller="restartClass25" email={user.email} />
                            </div>
                        </>
                    ) : (
                        <>
                            <div
                                className={`button_sec switch_participent_button  ${
                                    savedClasses.length === 1 && 'oneSaveClasses'
                                }`}
                            >
                                <div className="saveClassOption">
                                    <FormGroup className="menu_checkbox">
                                        {/* show allow guest checkbox only if this is neither public class nor demo class */}
                                        {selectedClass.classId !== 'public' && selectedClass.classCode !== null && (
                                            <FormControlLabel
                                                disabled={disableGuest}
                                                control={
                                                    <Checkbox
                                                        checked={isAllowGuests}
                                                        onClick={() => {
                                                            setIsAllowGuests(!isAllowGuests);
                                                            setIsSaveGuests(!isAllowGuests);
                                                            // if (isAllowGuests) setIsSaveGuests(false);
                                                        }}
                                                    />
                                                }
                                                label={t('lang_common.txt_allowGuest')}
                                            />
                                        )}
                                        {/* if allow guest is on, turn on save guest as well */}
                                        {/* <FormControlLabel
                                            disabled={!isAllowGuests}
                                            control={
                                                <Checkbox
                                                    checked={isSaveGuests}
                                                    onClick={() => setIsSaveGuests(!isSaveGuests)}
                                                />
                                            }
                                            label={t('lang_myclass.toggle_save_guests')}
                                        /> */}
                                    </FormGroup>
                                </div>
                                <CommonButton
                                    isLoading={isProcessing}
                                    variant="contained"
                                    type="button"
                                    text={
                                        isProcessing
                                            ? t('lang_common.btn_restarting')
                                            : t('lang_myclass.btn_start_new_class')
                                    }
                                    clickFunction={() => {
                                        webviewMessenger.sendUsageLog(
                                            `[S] Clicked "Start new class" button for ${
                                                selectedClass.classId === 'public' ? 'public' : 'saved'
                                            } class`,
                                        );
                                        triggerRestartClass();
                                    }}
                                />
                            </div>
                        </>
                    )}
                </>
            ) : (
                <div className="button_sec">
                    <CommonButton
                        isLoading={isProcessing}
                        variant="contained"
                        type="button"
                        text={isProcessing ? t('lang_common.btn_restarting') : t('lang_common.btn_confirm')}
                        clickFunction={triggerRestartClass}
                    />
                    <CommonButton
                        isLoading={false}
                        variant="text"
                        type="button"
                        text={t('lang_common.btn_cancel')}
                        clickFunction={onCancelAlert}
                    />
                </div>
            )}
        </Fragment>
    );
};

export default RestartClassModal;
