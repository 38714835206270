// Inspired by Chatbot-UI and modified to fit the needs of this project
// @see https://github.com/mckaywrigley/chatbot-ui/blob/main/components/Chat/ChatMessage.tsx

import { Message } from 'ai';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import { cn } from '../utils/common.utils';
import { MemoizedReactMarkdown } from './Markdown';
import { CodeBlock } from './CodeBlock';
import CustomAvatar, { AvatarType } from '../../Common/CustomAvatar';
import { getValidUserFromStore } from '../../../helpers/storeHelpers';
import { utilConstants } from '../../../constants/utils.constants';

export interface ChatMessageProps {
    index: number;
    message: Message;
    isLoading: boolean;
}

export function ChatMessage({ index, message, isLoading, ...props }: ChatMessageProps) {
    const appUser = getValidUserFromStore('AiChatMessage');
    if (!appUser) return null;

    if (message.role === 'system') return null;
    if (message.content === '') return null;

    return (
        <div className={cn('group relative mb-8')} {...props}>
            <div>
                {message.role === 'user' ? (
                    <div className="flex items-center gap-1.5">
                        <CustomAvatar
                            avatarUrl={appUser.userProfile.avatarUrl}
                            avatarName={`${appUser.userProfile.firstName} ${appUser.userProfile.lastName}`}
                            type={AvatarType.USER}
                            className="!h-6 !w-6"
                        />
                        <div className="font-semibold text-sm">Me</div>
                    </div>
                ) : (
                    <div className="flex w-fit items-center justify-start gap-1.5">
                        <img
                            src={utilConstants.IMAGE_URLS.ACTIVITY_SHORT_ANSWER_AI}
                            alt="insights"
                            className="insightsImage h-7 w-7"
                        />
                        <div className="font-semibold text-sm">ClassPoint AI</div>
                    </div>
                )}
            </div>

            <div className="mt-2 flex-1 space-y-2 overflow-hidden px-1 text-sm">
                <MemoizedReactMarkdown
                    className="prose dark:prose-invert prose-p:leading-relaxed prose-pre:p-0 break-words"
                    remarkPlugins={[remarkGfm, remarkMath]}
                    components={{
                        p({ children }) {
                            return <p className="mb-4 last:mb-0">{children}</p>;
                        },
                        code({ node, inline, className, children, ...props }: any) {
                            if (children.length) {
                                if (children[0] == '▍') {
                                    return <span className="mt-1 animate-pulse cursor-default">▍</span>;
                                }

                                children[0] = (children[0] as string).replace('`▍`', '▍');
                            }

                            const match = /language-(\w+)/.exec(className || '');

                            if (inline) {
                                return (
                                    <code className={className} {...props}>
                                        {children}
                                    </code>
                                );
                            }

                            return (
                                <CodeBlock
                                    key={Math.random()}
                                    language={(match && match[1]) || ''}
                                    value={String(children).replace(/\n$/, '')}
                                    {...props}
                                />
                            );
                        },
                        table: ({ children, ...props }) => (
                            <table
                                className="mb-4 mt-4 w-full border-separate border-spacing-0 overflow-hidden rounded-lg border border-b-0 border-ring"
                                {...props}
                            >
                                {children}
                            </table>
                        ),
                        th: ({ children, ...props }) => (
                            <th className="border-b border-r bg-muted px-4 py-2 text-left last:border-r-0" {...props}>
                                {children}
                            </th>
                        ),
                        td: ({ children, ...props }) => (
                            <td className="border-b border-r px-4 py-2 text-left last:border-r-0" {...props}>
                                {children}
                            </td>
                        ),
                        tr: ({ children, ...props }) => (
                            <tr className="first:border-t" {...props}>
                                {children}
                            </tr>
                        ),
                        ul: ({ children, ...props }) => (
                            <ul className="mb-4 mt-4 list-disc pl-5" {...props}>
                                {children}
                            </ul>
                        ),
                        ol: ({ children, ...props }) => (
                            <ol className="mb-4 mt-4 list-decimal pl-5" {...props}>
                                {children}
                            </ol>
                        ),
                        li: ({ children, ...props }) => (
                            <li className="mb-2 pl-1 last:mb-0" {...props}>
                                {children}
                            </li>
                        ),
                        hr: ({ children, ...props }) => <hr className="mb-4 mt-2" {...props} />,
                    }}
                >
                    {message.content}
                </MemoizedReactMarkdown>
            </div>
        </div>
    );
}
