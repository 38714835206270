import { Reorder } from 'framer-motion';
import { Fragment, useEffect } from 'react';
import { generateLeaders } from '../../../helpers/leaderboardHelpers';
import ParticipantInterface from '../../../interfaces/participant.interface';
import { leaderBoardActions } from '../../../actions/leaderboard.action';
import { useDispatch, useSelector } from 'react-redux';
import StoreInterface from '../../../interfaces/store.interface';
import Loading from '../../activity/Loading/Loading';
import LeaderBoardCurrentSingle from './LeaderBoardCurrentSingle';
import { SavedClassGroupInterface } from '../../../interfaces/savedClassGroup.interface';

const LeaderBoardCurrentClass = ({
    savedClassGroups,
    participantList,
    numOfElements,
}: {
    savedClassGroups: SavedClassGroupInterface[];
    participantList: ParticipantInterface[];
    numOfElements: number;
}) => {
    const dispatch = useDispatch();
    const leadersCurrentClass = useSelector((state: StoreInterface) => state.leaderboard.leadersCurrentClass);

    const maxCurrentPoint = Math.max(leadersCurrentClass[0]?.participantPoints, 10);
    const maxBarWidth = 650;
    const minBarWidth = 100;
    const barWidthArray: number[] = [];

    for (let x = 0; x < leadersCurrentClass.length; x++) {
        if (leadersCurrentClass[x].participantPoints > 0) {
            barWidthArray.push((maxBarWidth / maxCurrentPoint) * leadersCurrentClass[x]?.participantPoints + 130);
        } else {
            barWidthArray.push(minBarWidth);
        }
    }

    useEffect(() => {
        refreshLeaders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refreshLeaders = async () => {
        const sortedLeadersList = generateLeaders(participantList, 'participantPoints');
        setTimeout(() => {
            dispatch(leaderBoardActions.refreshLeadersCurrentClass(sortedLeadersList));
        }, 1000);
    };

    return (
        <Fragment>
            {leadersCurrentClass.length === 0 ? (
                <Loading />
            ) : (
                <Fragment>
                    <ul className="leader_board_chart">
                        <Reorder.Group values={leadersCurrentClass} onReorder={() => {}}>
                            {leadersCurrentClass.slice(0, numOfElements).map((participant, index: number) => (
                                <Reorder.Item key={participant.participantId} value={participant} className="checking">
                                    <LeaderBoardCurrentSingle
                                        index={index}
                                        savedClassGroups={savedClassGroups}
                                        participant={participant}
                                        barWidthArray={barWidthArray}
                                    />
                                </Reorder.Item>
                            ))}
                        </Reorder.Group>
                    </ul>
                </Fragment>
            )}
        </Fragment>
    );
};

export default LeaderBoardCurrentClass;
