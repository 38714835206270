// Button Star Animation
export const AnimationConfig = {
    GIVE_POINT_DELAY: 500,
    BUTTON_STAR_LOTTIE_DURATION: 1000,
    TICK_LOTTIE_DURATION: 800,
    SPARKLE_LOTTIE_DURATION: 1500,
    MYCLASS_SPARKLE_LOTTIE_DURATION: 1000,
    CONFETTI_LOTTIE_DURATION: 1200,
    LEVEL_UP_ANIMATION_DURATION: 2000,

    BUTTON_STAR_HOVER_START: { scale: 0.8 },
    BUTTON_STAR_HOVER_END: { scale: 1 },
    BUTTON_STAR_CLICK: {
        scale: [1, 0, 1],
        opacity: [1, 0, 1],
        transition: { duration: 0.5 },
    },
};

export const SimpleFadeAnimation = {
    initial: { opacity: 0 },
    animate: {
        opacity: 1,
        transition: { duration: 0.1 },
    },
    exit: {
        opacity: 0,
        transition: { duration: 0.1 },
    },
};

export const ModalAnimationConfig = {
    initial: { opacity: 0, scale: 0.3 },
    animate: {
        opacity: 1,
        scale: 1,
        transition: {
            type: 'spring',
            stiffness: 1000,
            mass: 2.8,
            damping: 40,
        },
    },
    exit: {
        opacity: 0,
        scale: 0.5,
        transition: { duration: 0.6 },
    },
};

export const MultipleChoiceTickLottie = {
    initial: { opacity: 0, scale: 0.3, y: 100 },
    animate: {
        opacity: 1,
        scale: 1,
        y: 0,
        transition: {
            type: 'spring',
            stiffness: 1000,
            mass: 2.8,
            damping: 40,
        },
    },
    exit: {
        opacity: 0,
        scale: 0.5,
        transition: { duration: 0.6 },
    },
};

export const PickNamesCardFlipAnimation = (isPicked: boolean, flipOrderIndex: number, type: string) => {
    let rotateYValue: number;
    type === 'flipped' ? (rotateYValue = 540) : (rotateYValue = 0);

    return {
        rotateY: rotateYValue,
        transition: {
            duration: 0.1,
            ease: 'easeInOut',
            delay: !isPicked ? flipOrderIndex : 0,
            repeatDelay: 1,
        },
    };
};
