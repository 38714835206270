import { isCpVerAtLeast } from './../helpers/utils';
import { HostEventTypes } from './../constants/host-event-types';
import { UserActionTypes } from '../constants/user-action-types';
import apiUser from '../services/apiUser';
import webviewMessenger from '../services/webviewMessenger';
import _ from 'lodash';
import { localService } from '../services/localStorageService';
import { checkAndUpdateLanguage, handleUserDataUpdated, mapUserToSyncWithHost } from '../helpers/userhelper';
import { Dispatch } from 'redux';
import UserInterface from '../interfaces/user-interface';
import { getValidUserFromStore } from '../helpers/storeHelpers';
import { GtmService } from '../services/googleTagManager.service';

export const userActions = {
    preloadUser,
    autoSignInUserWithPassword,
    checkAndUpdateUser,
    updateUser,
    unloadUser,
    reloadUserReducer,
    showApiError,
    dismissApiError,
};

function preloadUser(payload: UserInterface) {
    return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
        dispatch(updateUserAction(UserActionTypes.PAGE_REFRESHED_LOAD_CACHED_USER, payload));
    };
}

function autoSignInUserWithPassword(email: string, password: string) {
    return async (dispatch: any) => {
        let statsFromHost = localService.getStatsFromHost();
        const reply = await apiUser.signInWithPassword(email, { password, ...statsFromHost }, 'appLaunchAutoSignIn');
        if (!reply || typeof reply === 'number')
            return dispatch(
                unloadUser(
                    UserActionTypes.AUTO_SIGN_IN_WITH_PASSWORD_FAILED,
                    isCpVerAtLeast('2.0.30') ? HostEventTypes.AUTO_SIGN_IN_FAILED : null,
                ),
            );
        dispatch(updateUserAction(UserActionTypes.AUTO_SIGN_IN_WITH_PASSWORD_SUCCESS, reply));
        checkAndUpdateLanguage(reply);
        webviewMessenger.sendUserLoaded(HostEventTypes.AUTO_SIGN_IN_SUCCESS, mapUserToSyncWithHost(reply));
        GtmService.sendLoginEvent(reply.userId, 'launch-ppt');
    };
}

function checkAndUpdateUser() {
    return async (
        // dispatch: (arg0: (dispatch: (arg0: { type: string; payload: UserInterface }) => void) => Promise<void>) => void,
        dispatch: (arg0: { type: string; payload: UserInterface }) => Promise<void>,
    ) => {
        // Get user from local storage, if user is null (signed out but slideshow instance is not aware), don't make api call
        const userReducerString = localStorage.getItem('user');
        // console.log('userReducerString', userReducerString);
        const userInLocalStorage = userReducerString ? JSON.parse(userReducerString) : null;
        const email = userInLocalStorage ? userInLocalStorage.email : null;
        // console.log('email', email);
        if (!email) return;

        console.log('checkAndUpdateUser()', email);
        const updatedUser = await apiUser.getUser(email);
        if (!updatedUser) return;

        const user = getValidUserFromStore(`updateUser:checkAndUpdateUser`);
        if (!_.isEqual(user, updatedUser)) {
            dispatch(updateUserAction(UserActionTypes.PERIODIC_CHECK_USER_CHANGED, updatedUser));
            handleUserDataUpdated(user, updatedUser);
        }
    };
}

function updateUser(updateUserActionType: string, updatedUser: UserInterface) {
    return async (dispatch: (arg0: { type: string; payload: UserInterface }) => void) => {
        const user = getValidUserFromStore(`updateUser:${updateUserActionType}`);
        dispatch(updateUserAction(updateUserActionType, updatedUser));
        checkAndUpdateLanguage(updatedUser);
        handleUserDataUpdated(user, updatedUser);
    };
}

function unloadUser(unloadUserActionType: string, eventToHost: string | null) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: unloadUserActionType });
        // localStorage.removeItem('statsFromHost');
        localStorage.removeItem('quizActivityIds');
        localStorage.removeItem('quizSummary');
        if (eventToHost === null) return;
        webviewMessenger.sendUserUnloaded(eventToHost);
    };
}

function reloadUserReducer() {
    return async (dispatch: Dispatch) => {
        dispatch({ type: UserActionTypes.RELOAD_USER_REDUCER });
    };
}

function showApiError() {
    return async (dispatch: Dispatch) => {
        dispatch({ type: UserActionTypes.SHOW_API_ERROR });
    };
}

function dismissApiError() {
    return async (dispatch: Dispatch) => {
        dispatch({ type: UserActionTypes.DISMISS_API_ERROR });
    };
}

const updateUserAction = (type: string, updatedUser: UserInterface) => ({
    type,
    payload: updatedUser,
});
