import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { userActions } from '../../actions/user.actions';
import { SavedClassAddParticipantDto } from '../../dtos/saved-class-add-participant.dto';
import apiSavedClasses from '../../services/apiSavedClasses';
import { localService } from '../../services/localStorageService';
import { getValidUserFromStore } from '../../helpers/storeHelpers';
import { findNonEmptyAddedParticipants, validateSavedClassCode, validateSavedClassName } from './savedClassHelpers';
import SavedClassForUserInterface from '../../interfaces/saved-class-for-user.interface';
import { UserActionTypes } from '../../constants/user-action-types';
import { ViewportSavedClasses } from '../../constants/viewport-constants';

import CommonAlert from '../../components/Common/CommonAlert';
import AddSavedClassesFooter from './components/AddSavedClassesFooter';
import AddSavedClassStepProgress from './components/AddSavedClassStepProgress';
import DiscardAddSavedClassAlert from './components/Modal/DiscardAddSavedClassAlert';
import AddSavedClassStepOne from './components/AddSavedClassStepOne';
import AddSavedClassStepTwo from './components/AddSavedClassStepTwo';
import AddZeroParticipantAlert from './components/alert/AddZeroParticipantAlert';

interface AddSavedClassInterface {
    changeCurrentPage: any;
    savedClass?: SavedClassForUserInterface;
    setSavedClass: (arg: SavedClassForUserInterface) => void;
}

function AddSavedClass({ changeCurrentPage, savedClass, setSavedClass }: AddSavedClassInterface) {
    const dispatch = useDispatch();
    const user = getValidUserFromStore('AddSavedClass');
    const savedClasses = user.userProfile.savedClasses || [];
    const { t } = useTranslation();
    const [currentStep, setCurrentStep] = useState(1);
    const [isShowDiscardAlert, setIsShowDiscardAlert] = useState(false);
    const [className, setClassName] = useState('');
    const [classNameError, setClassNameError] = useState('');
    const [classCode, setClassCode] = useState('');
    const [classCodeError, setClassCodeError] = useState('');
    const [savedClassAvatar, setSavedClassAvatar] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isShowZeroParticipantAlert, setIsShowZeroParticipantAlert] = useState(false);

    const discardAddSavedClass = () => {
        setCurrentStep(1);
        localService.removeTempAddedParticipants();
        changeCurrentPage('SavedClassList');
    };

    const handleSubmitStepOne = async () => {
        setClassNameError('');
        setClassCodeError('');
        const classNameError = validateSavedClassName(
            className.trim(),
            savedClasses.map((c) => c.savedClassName),
        );
        const classCodeError = validateSavedClassCode(classCode.trim());

        if (classNameError) {
            setClassNameError(t(`lang_saved_classes.${classNameError}`));
            return;
        }
        if (classCodeError) {
            setClassCodeError(t(`lang_saved_classes.${classCodeError}`));
            return;
        }

        setIsSubmitting(true);
        const isClassCodeValidated = await apiSavedClasses.validateSavedClassCode(user.email, classCode.trim());
        setIsSubmitting(false);
        if (!isClassCodeValidated) {
            setClassCodeError(t('lang_saved_classes.err_class_code_taken'));
            return;
        }

        setCurrentStep(2);
    };

    const handleSubmitStepTwo = async () => {
        const addedParticipants = localService.getTempAddedParticipants();
        let error = '';

        if (addedParticipants) {
            const errorsCount = addedParticipants.map((p) => p.error).filter((error) => !!error).length;
            if (errorsCount > 0) {
                error = t('lang_saved_classes.err_red_massage');
                console.log('error', error);
            }
        }

        // setErrorMessage(error);
        if (!error) {
            const nonEmptyParticipants = findNonEmptyAddedParticipants(addedParticipants || []);
            setIsSubmitting(true);
            const updatedUser = await apiSavedClasses.addSavedClass(
                user.email,
                user.userId,
                className.trim(),
                classCode.trim().toUpperCase(),
                savedClassAvatar,
                nonEmptyParticipants.map((p) => {
                    const dto: SavedClassAddParticipantDto = {
                        participantUsername: p.name.trim(),
                        participantName: p.name.trim(),
                        participantTotalPoints: p.points || 0,
                    };
                    return dto;
                }),
            );
            setIsSubmitting(false);
            if (!updatedUser) {
                dispatch(userActions.showApiError());
                return;
            }

            if (typeof updatedUser === 'number') {
                setClassCodeError(t('lang_saved_classes.err_class_code_taken'));
                setIsShowZeroParticipantAlert(false);
                setCurrentStep(1);
                return;
            }

            dispatch(
                userActions.updateUser(
                    nonEmptyParticipants.length > 0
                        ? UserActionTypes.ADDED_SAVED_CLASS
                        : UserActionTypes.ADDED_SAVED_CLASS_NO_PARTICIPANT,
                    updatedUser,
                ),
            );

            setIsShowZeroParticipantAlert(false);
            localService.removeTempAddedParticipants();

            if (updatedUser.userProfile.savedClasses && updatedUser.userProfile.savedClasses.length > 0) {
                const newSavedClass = updatedUser.userProfile.savedClasses[
                    updatedUser.userProfile.savedClasses.length - 1
                ] as SavedClassForUserInterface;
                setSavedClass(newSavedClass);
                changeCurrentPage('ViewEditClass', newSavedClass);
            }
        }
    };

    return (
        <Fragment>
            <div className="stepPanel">
                <div className="savedClassContainer">
                    <AddSavedClassStepProgress currentStep={currentStep} />
                    {currentStep === 1 && (
                        <AddSavedClassStepOne
                            className={className}
                            setClassName={setClassName}
                            classNameError={classNameError}
                            setClassNameError={setClassNameError}
                            classCode={classCode}
                            setClassCode={setClassCode}
                            classCodeError={classCodeError}
                            setClassCodeError={setClassCodeError}
                            savedClassAvatar={savedClassAvatar}
                            setSavedClassAvatar={setSavedClassAvatar}
                        />
                    )}
                    {currentStep === 2 && <AddSavedClassStepTwo />}
                </div>
                <AddSavedClassesFooter
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    onShowDiscardAddClassAlert={() => setIsShowDiscardAlert(true)}
                    onDiscardAddSavedClass={discardAddSavedClass}
                    onSubmitStepOne={handleSubmitStepOne}
                    isSubmitting={isSubmitting}
                    onSubmitStepTwo={handleSubmitStepTwo}
                    onShowZeroParticipantAlert={() => setIsShowZeroParticipantAlert(true)}
                />
                <CommonAlert
                    showAlert={isShowZeroParticipantAlert}
                    closeAlert={() => setIsShowZeroParticipantAlert(false)}
                    content={
                        <AddZeroParticipantAlert
                            onConfirm={handleSubmitStepTwo}
                            closeAlert={() => setIsShowZeroParticipantAlert(false)}
                            isSubmitting={isSubmitting}
                        />
                    }
                    viewportConfig={ViewportSavedClasses}
                    customModalClassNames="small"
                />
                <CommonAlert
                    showAlert={isShowDiscardAlert}
                    closeAlert={() => setIsShowDiscardAlert(false)}
                    content={
                        <DiscardAddSavedClassAlert
                            confirmToMain={() => discardAddSavedClass()}
                            closeAlert={() => setIsShowDiscardAlert(false)}
                        />
                    }
                    viewportConfig={ViewportSavedClasses}
                />
            </div>
        </Fragment>
    );
}

export default AddSavedClass;
