import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ReplayIcon from '@mui/icons-material/Replay';
import CloseIcon from '@mui/icons-material/Close';
import { getBrowserTabsFromStore } from '../../../../helpers/storeHelpers';
import { BrowserTabInterface } from '../../../../interfaces/browser-tabs-interface';
import webviewBrowserCommands from '../../../../services/webviewBrowserCommands';
import webviewMessenger from '../../../../services/webviewMessenger';

function ControlGroup() {
    const browserTabs = getBrowserTabsFromStore();
    const [activeTab, setActiveTab] = useState<BrowserTabInterface>();

    useEffect(() => {
        const tabs = browserTabs.filter((tabItem) => tabItem.active);
        if (tabs.length > 0) {
            setActiveTab(tabs[0]);
        }
    }, [browserTabs]);

    return (
        <div className="controls-group">
            <div className="btn btn-back">
                <IconButton
                    color="primary"
                    size="small"
                    onClick={() => {
                        webviewMessenger.sendUsageLog(`[S] Browser back button clicked`);
                        webviewBrowserCommands.goBackward();
                    }}
                >
                    <ArrowBackIcon fontSize="small" />
                </IconButton>
            </div>
            <div className="btn btn-forward">
                <IconButton
                    color="primary"
                    size="small"
                    onClick={() => {
                        webviewMessenger.sendUsageLog(`[S] Browser forward button clicked`);
                        webviewBrowserCommands.goForward();
                    }}
                >
                    <ArrowForwardIcon fontSize="small" />
                </IconButton>
            </div>
            <div className="btn btn-reload">
                {activeTab && activeTab.isLoading ? (
                    <IconButton
                        size="small"
                        onClick={() => {
                            webviewMessenger.sendUsageLog(`[S] Browser cancel button clicked`);
                            webviewBrowserCommands.cancelReloadPage();
                        }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                ) : (
                    <IconButton
                        color="primary"
                        size="small"
                        onClick={() => {
                            webviewMessenger.sendUsageLog(`[S] Browser reload button clicked`);
                            webviewBrowserCommands.reloadPage();
                        }}
                    >
                        <ReplayIcon fontSize="small" />
                    </IconButton>
                )}
            </div>
        </div>
    );
}

export default ControlGroup;
