import { useEffect, useState, useRef, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FitToViewport } from 'react-fit-to-viewport';
import { Box, Button, Modal, Typography } from '@mui/material';

import AutoPickRevealer from './AutoPickRevealer';
import AutoPickCounter from './AutoPickCounter';
import {
    findParticipantPickNamesListIndex,
    findUnPicked,
    getMultipleParticipantsInModal,
} from '../../../helpers/pickNamesHelpers';
import { store } from '../../../helpers/store';
import PickNamesParticipantInterface from '../../../interfaces/pick-names-participant.interface';
import ParticipantAndPointsDto from '../../../dtos/participant-and-points.dto';
import { classSessionActions } from '../../../actions/class-session.action';
import { AnimationConfig } from '../../../constants/animation-configs';
import { ViewportConstants } from '../../../constants/viewport-constants';
import { FullScreenPointLottie } from '../../../components/Common/AnimationModules';
// import { pickNamesActions } from '../../../actions/pickNames.action';
import { localService } from '../../../services/localStorageService';
import StoreInterface from '../../../interfaces/store.interface';
import { UpdateToolbarActionsType } from '../../../dtos/update-toolbar-actions.dto';

interface AutoPickModalInterface {
    isAnimating: boolean;
    isAutoPickModalOpen: boolean;
    setIsAutoPickModalOpen: (arg: boolean) => void;
    autoPickCount: number;
    onIncrease: () => void;
    onDecrease: () => void;
    setAllPickedResetModalOpen: (arg: boolean) => void;
    setIsAnimating: (arg: boolean) => void;
    givePointsConfettiRef: any;
}

const AutoPickModal = ({
    isAnimating,
    isAutoPickModalOpen,
    setIsAutoPickModalOpen,
    autoPickCount,
    onIncrease,
    onDecrease,
    setAllPickedResetModalOpen,
    setIsAnimating,
    givePointsConfettiRef,
}: AutoPickModalInterface) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const webviewSettingsSlideshow = useSelector((state: StoreInterface) => state.webviewSettingsSlideshow);
    const pickNamesList: PickNamesParticipantInterface[] = store.getState().pickNamesList;

    const timeOutPointUpdate = useRef<any>();

    const pickedParticipantIdsInModal = localService.getPickedNamesInModal();
    const isPickNameShowOnlineParticipant = webviewSettingsSlideshow.isPickNameShowOnlineParticipant;

    const pickNamesListWithOnlineStatus = isPickNameShowOnlineParticipant
        ? pickNamesList.filter((data) => data.isOnline === true)
        : pickNamesList;

    const [pointsAdded, setPointsAdded] = useState(0);
    const [hasPicked, setHasPicked] = useState(pickedParticipantIdsInModal.length > 1 ? true : false);
    const [autoPickedParticipants, setAutoPickedParticipants] = useState<PickNamesParticipantInterface[]>(
        getMultipleParticipantsInModal(pickNamesListWithOnlineStatus, pickedParticipantIdsInModal),
    );

    useEffect(() => {
        setIsAutoPickModalOpen(pickedParticipantIdsInModal.length > 1 ? true : false);
        return () => {
            setIsAnimating(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAutoPick = () => {
        let unPicked = findUnPicked(pickNamesListWithOnlineStatus);
        const autoPicked: PickNamesParticipantInterface[] = [];

        for (let index = 0; index < autoPickCount; index++) {
            const random = Math.floor(Math.random() * unPicked.length);
            autoPicked.push(unPicked[random]);
            unPicked = unPicked.filter((p, index: number) => index !== random);
        }
        setAutoPickedParticipants(autoPicked);
        setHasPicked(true);
        localService.setPickedNamesInModal(autoPicked.map((data) => data.participantId));
        localService.updateToolbarActionsFromWebview(UpdateToolbarActionsType.pickName);
    };

    // const putBackAll = () => {
    //     for (let index = 0; index < autoPickedParticipants.length; index++) {
    //         const pickedParticipant = autoPickedParticipants[index];
    //         dispatch(pickNamesActions.pickOneName(pickedParticipant.participantId, false));
    //     }

    //     setIsAutoPickModalOpen(false);
    //     setHasPicked(false);
    //     localService.removePickedNamesInModal();
    // };

    const closeModalHandler = () => {
        !isAnimating && setIsAutoPickModalOpen(false);
        setHasPicked(false);
        localService.removePickedNamesInModal();
    };

    const givePointToAll = (points: number) => {
        const participantsAndPoints: ParticipantAndPointsDto[] = autoPickedParticipants.map((pickedParticipant) => {
            return {
                participantId: pickedParticipant.participantId,
                points: points,
            };
        });
        localService.updateToolbarActionsFromWebview(UpdateToolbarActionsType.awardStar);
        dispatch(classSessionActions.givePointsToParticipants(participantsAndPoints));
    };

    // const givePointHandler = () => {
    //     clearTimeout(timeOutPointUpdate.current);
    //     setIsAnimating(true);
    //     givePointsConfettiRef.current.playLottieAnimation(true);
    //     setPointsAdded(pointsAdded + 1);
    // };

    useEffect(() => {
        if (autoPickedParticipants) {
            const updatedPickedParticipants: PickNamesParticipantInterface[] = [];
            autoPickedParticipants.forEach((p) => {
                const index = findParticipantPickNamesListIndex(p);
                updatedPickedParticipants.push(pickNamesList[index]);
            });
            setAutoPickedParticipants(updatedPickedParticipants);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pickNamesList]);

    useEffect(() => {
        if (pointsAdded > 0) {
            timeOutPointUpdate.current = setTimeout(() => {
                givePointToAll(pointsAdded);
                setPointsAdded(0);
                setTimeout(() => {
                    setIsAnimating(false);
                    if (givePointsConfettiRef.current) givePointsConfettiRef.current.playLottieAnimation(false);
                }, AnimationConfig.CONFETTI_LOTTIE_DURATION - AnimationConfig.GIVE_POINT_DELAY);
            }, AnimationConfig.GIVE_POINT_DELAY);
        }
        return () => clearTimeout(timeOutPointUpdate.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pointsAdded]);

    return (
        <Fragment>
            <Modal
                open={isAutoPickModalOpen}
                onClose={() => closeModalHandler()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="flipcard_modal"
            >
                <Fragment>
                    <FitToViewport
                        className="modal_viewport"
                        width={ViewportConstants.width}
                        height={ViewportConstants.height}
                        minZoom={ViewportConstants.minZoom}
                        maxZoom={ViewportConstants.maxZoom}
                    >
                        <Fragment>
                            <FullScreenPointLottie ref={givePointsConfettiRef} />

                            <div className="close_modal" onClick={() => closeModalHandler()}></div>
                            <Box>
                                <div className="auto_pick_modal">
                                    <div className="modal_head">
                                        <Typography variant="h4">{t('lang_pick_names.txt_auto_pick_names')}</Typography>
                                    </div>

                                    <div className="auto_pick_modal_body">
                                        {!hasPicked ? (
                                            <AutoPickCounter
                                                count={autoPickCount}
                                                canIncrease={
                                                    Math.min(10, findUnPicked(pickNamesListWithOnlineStatus).length) >
                                                    autoPickCount
                                                }
                                                canDecrease={autoPickCount > 1}
                                                onIncrease={onIncrease}
                                                onDecrease={onDecrease}
                                            />
                                        ) : (
                                            <AutoPickRevealer
                                                autoPickNames={autoPickedParticipants}
                                                givePointsConfettiRef={givePointsConfettiRef}
                                            />
                                        )}
                                    </div>

                                    {!hasPicked && (
                                        <div className="auto_pick_modal_foot">
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                className="auto_pick_foot_btn"
                                                onClick={handleAutoPick}
                                            >
                                                {autoPickCount > 1
                                                    ? t('lang_pick_names.btn_pick_names_count', {
                                                          autoPickCount: autoPickCount,
                                                      })
                                                    : t('lang_pick_names.btn_pick_name_count', {
                                                          autoPickCount: autoPickCount,
                                                      })}
                                            </Button>
                                        </div>
                                    )}

                                    {hasPicked && (
                                        <div className="auto_pick_modal_foot">
                                            {/* <GivePointButton
                                                label={t('lang_common.btn_give_points_to_all')}
                                                activity="autoPickNames"
                                                onClick={givePointHandler}
                                            />
                                            <span className="backToWheel" onClick={putBackAll}>
                                                {t('lang_pick_names.txt_put_back')}
                                            </span> */}
                                        </div>
                                    )}
                                </div>
                            </Box>
                        </Fragment>
                    </FitToViewport>
                </Fragment>
            </Modal>
        </Fragment>
    );
};

export default AutoPickModal;
