import { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AnimatePresence } from 'framer-motion';

import { Stack, Typography } from '@mui/material';

import { checkAndMarkMatchingHtml, linkify } from '../../../helpers/activityHelpers';
import { stringToNumber } from '../../../helpers/utils';
import { findCenter } from '../../../helpers/animationHelpers';
import { activityActions } from '../../../actions/activity.action';

import ActivityResponseInterface from '../../../interfaces/activity-response.interface';
import { AnimationConfig } from '../../../constants/animation-configs';
import { utilConstants } from '../../../constants/utils.constants';

import VotingCount from '../../../components/Common/VotingCount';
import MultiMediaActivityResponseFooter from '../MultiMediaActivityResponseFooter';
import { TickLottie } from '../../../components/Common/AnimationModules';

import filter from '../../../services/badwords.service';

interface ShortAnswerSingleResponseInterface {
    response: ActivityResponseInterface;
    index: number;
    onThumbClicked: any;
    isVoting: boolean;
    searchKeyword: string;
}

function ShortAnswerSingleResponse({
    response,
    index,
    onThumbClicked,
    isVoting,
    searchKeyword,
}: ShortAnswerSingleResponseInterface) {
    const dispatch = useDispatch();
    const timeOutPointUpdate = useRef<any>();
    const boxSizeRef = useRef<any>();

    const [pointsAdded, setPointsAdded] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);

    const givePoint = (response: ActivityResponseInterface, points: number) => {
        dispatch(activityActions.handleGivePointsToResponses([{ response, points }]));
    };

    const handleGivePointButtonClick = () => {
        clearTimeout(timeOutPointUpdate.current);
        setIsAnimating(true);
        setPointsAdded(pointsAdded + 1);
    };

    useEffect(() => {
        if (pointsAdded > 0) {
            timeOutPointUpdate.current = setTimeout(() => {
                givePoint(response, pointsAdded);
                setPointsAdded(0);
                setTimeout(() => {
                    setIsAnimating(false);
                }, AnimationConfig.TICK_LOTTIE_DURATION - AnimationConfig.GIVE_POINT_DELAY);
            }, AnimationConfig.GIVE_POINT_DELAY);
        }
        return () => clearTimeout(timeOutPointUpdate.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pointsAdded]);

    return (
        <Stack>
            <div
                className="sh_box"
                style={{
                    order: index,
                    background:
                        utilConstants.SHORT_ANSWER_COLORS[
                            stringToNumber(response.responseId) % utilConstants.SHORT_ANSWER_COLORS.length
                        ],
                }}
            >
                <div ref={boxSizeRef} className="content_box" onClick={() => onThumbClicked(response, index)}>
                    {isAnimating && <div className="overlayAnimationGlobal show"></div>}
                    <AnimatePresence>
                        {isAnimating && (
                            <TickLottie
                                key={response.responseId}
                                index={stringToNumber(response.responseId)}
                                xPosition={() => findCenter(boxSizeRef).x}
                                yPosition={() => findCenter(boxSizeRef).y}
                                scale="0.8"
                            />
                        )}
                    </AnimatePresence>
                    {!isVoting && response.voterParticipantIds && <VotingCount response={response} />}
                    <div className="shortmodal_content">
                        <Typography
                            variant="body1"
                            dangerouslySetInnerHTML={{
                                __html: linkify(
                                    checkAndMarkMatchingHtml(
                                        filter.clean(`a${response.responseData}`).slice(1),
                                        searchKeyword.toString(),
                                    ).replacedHtml,
                                ),
                            }}
                            className={`shortAnswerResponseText`}
                        ></Typography>
                    </div>
                </div>

                <MultiMediaActivityResponseFooter
                    response={response}
                    responseIndex={index}
                    pointsAdded={pointsAdded}
                    onGivePointButtonClick={handleGivePointButtonClick}
                    onResponseIndexDeleted={() => {}}
                    searchKeyword={searchKeyword}
                    isVoting={isVoting}
                />
            </div>
        </Stack>
    );
}

export default ShortAnswerSingleResponse;
