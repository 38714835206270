import { ReducerName } from '../constants/reducer-name.enum';
import { TutorialSettingsActionTypes } from '../constants/tutorial-settings-action-types';
import { VideoTutorials } from '../constants/video-tutorials';
import { getCachedReducer } from '../helpers/storeHelpers';
import TutorialSettingsInterface from '../interfaces/tutorial-seetings.interface';

export default function tutorialSettingsReducer(
    state = getCachedTutorialSettingsState(),
    action: { type: string; payload: Partial<TutorialSettingsInterface> },
) {
    switch (action.type) {
        case TutorialSettingsActionTypes.UPDATE_ACTIVE_TUTORIAL_VIDEO:
        case TutorialSettingsActionTypes.UPDATE_TUTORIAL_CAPTION_LANG:
        case TutorialSettingsActionTypes.ADJUST_TUTORIAL_VOLUME:
        case TutorialSettingsActionTypes.MUTE_UNMUTE_VIDEO_VOLUME:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

const getCachedTutorialSettingsState = (): TutorialSettingsInterface => {
    const cachedTutorialSettings: TutorialSettingsInterface | null = getCachedReducer(ReducerName.tutorialSettings);
    if (cachedTutorialSettings) return { ...defaultTutorialSettings, ...cachedTutorialSettings };

    return defaultTutorialSettings;
};

const defaultTutorialSettings: TutorialSettingsInterface = {
    activeTutorialVideoId: VideoTutorials[0].videoIds[0],
    tutorialCaptionLang: '',
    videoTutorialVolume: 0.5,
    isTutorialVideoMuted: false,
};
