import { Typography } from '@mui/material';
import { Fragment } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SavedClassInfoSummary from './SavedClassInfoSummary';
import EditDeleteSavedClass from './EditDeleteSavedClass';
import { useTranslation } from 'react-i18next';
import SavedClassForUserInterface from '../../../interfaces/saved-class-for-user.interface';
import webviewMessenger from '../../../services/webviewMessenger';

interface ViewEditSavedClassSidePanalInterface {
    changeCurrentPage: (arg: string) => void;
    savedClass: SavedClassForUserInterface;
    setSavedClass: (arg: SavedClassForUserInterface) => void;
    onDeleteSavedClass: () => void;
    onResetStars: () => void;
}

const ViewEditSavedClassSidePanal = ({
    changeCurrentPage,
    savedClass,
    setSavedClass,
    onDeleteSavedClass,
    onResetStars,
}: ViewEditSavedClassSidePanalInterface) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div className="edit_side_panal">
                <div className="edit_sec_top">
                    <div
                        className="backto_page"
                        onClick={() => {
                            webviewMessenger.sendUsageLog(`Clicked "Back to My Classes"`);
                            changeCurrentPage('SavedClassList');
                        }}
                    >
                        <ArrowBackIcon />
                        <Typography variant="caption">{t('lang_saved_classes.btn_back_my_class')}</Typography>
                    </div>
                </div>
                <div className="editProfile_sec">
                    <SavedClassInfoSummary savedClass={savedClass} setSavedClass={setSavedClass} />
                    <EditDeleteSavedClass
                        savedClass={savedClass}
                        setSavedClass={setSavedClass}
                        onDeleteSavedClass={onDeleteSavedClass}
                        onResetStars={onResetStars}
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default ViewEditSavedClassSidePanal;
