import React, { useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { VideoTutorials } from '../../../constants/video-tutorials';
import { useTranslation } from 'react-i18next';
import VideoListItem from './VideoListItem';
import { getSectionIdFromVideoId } from '../videoTutorialHelpers';
import UserInterface from '../../../interfaces/user-interface';

interface VideoListInterface {
    user: UserInterface;
    activeVideoId: string;
}

const VideoList = ({ user, activeVideoId }: VideoListInterface) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = React.useState<string[]>([]);

    useEffect(() => {
        const sectionId = getSectionIdFromVideoId(activeVideoId);
        if (!expanded.includes(sectionId)) setExpanded([...expanded, sectionId]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeVideoId]);

    return (
        <div>
            {VideoTutorials.map((section, index: number) => (
                <Accordion
                    key={index}
                    expanded={expanded.includes(section.sectionId)}
                    onChange={() => {
                        if (!expanded.includes(section.sectionId)) setExpanded([...expanded, section.sectionId]);
                        else
                            setExpanded(
                                expanded.filter(function (sectionId) {
                                    return sectionId !== section.sectionId;
                                }),
                            );
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id={section.sectionId}
                    >
                        <Typography variant="caption" className="video_number">
                            {index + 1}
                        </Typography>
                        <Typography>{t(`lang_video_tutorial.${section.sectionId}`)}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ul>
                            {section.videoIds.map((videoId) => {
                                return (
                                    <VideoListItem
                                        key={videoId}
                                        user={user}
                                        videoId={videoId}
                                        activeVideoId={activeVideoId}
                                    />
                                );
                            })}
                        </ul>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
};

export default VideoList;
