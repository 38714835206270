import { getValidUserFromStore } from './storeHelpers';
import { DateTime } from 'luxon';
import { utilConstants } from '../constants/utils.constants';
import ActivityResponseInterface from '../interfaces/activity-response.interface';
// import { encode } from 'html-entities';
import { decode } from 'html-entities';
import ParticipantAndPointsDto from '../dtos/participant-and-points.dto';
import _ from 'lodash';
import ResponseAndPointsDto from '../dtos/response-and-points.dto';
import { ActivityPropsInterface } from '../interfaces/activity-props.interface';
import QuizResponseAndPointsDto from '../dtos/quiz-response-and-points.dto';
import ParticipantChipInterface from '../interfaces/participant-chip.interface';
import apiUser from '../services/apiUser';
import { dataURLtoFile } from './utils';
import ActivityInterface from '../interfaces/activity.interface';

export const generateActivityId = (activityType: string, isQuizMode = false) => {
    let prefixString;
    switch (activityType) {
        case utilConstants.ACTIVITY_TYPE.MULTIPLE_CHOICE:
            prefixString = isQuizMode ? 'mq' : 'mc';
            break;
        case utilConstants.ACTIVITY_TYPE.WORD_CLOUD:
            prefixString = 'wc';
            break;
        case utilConstants.ACTIVITY_TYPE.SHORT_ANSWER:
            prefixString = 'sa';
            break;
        case utilConstants.ACTIVITY_TYPE.SLIDE_DRAWING:
            prefixString = 'sd';
            break;
        case utilConstants.ACTIVITY_TYPE.IMAGE_UPLOAD:
            prefixString = 'iu';
            break;
        case utilConstants.ACTIVITY_TYPE.AUDIO_RECORD:
            prefixString = 'ar';
            break;
        case utilConstants.ACTIVITY_TYPE.VIDEO_UPLOAD:
            prefixString = 'vu';
            break;
        case utilConstants.ACTIVITY_TYPE.FILL_IN_THE_BLANKS:
            prefixString = 'fb';
            break;
        case utilConstants.ACTIVITY_TYPE.QUICK_POLL:
            prefixString = 'qp';
            break;
        default:
            break;
    }
    return prefixString + timeStampSuffix();
};

export const getNumOfSubmissionsAllowed = (activityId: string): number => {
    const prefixString = activityId.substring(0, 2);
    switch (prefixString) {
        case 'sa':
            return 3;
        case 'iu':
            return 1; // multiple submissions in Image Upload is turned off
        default:
            return 1;
    }
};

export const convertHostMsgToActivityProps = async (msgData: any): Promise<ActivityPropsInterface> => {
    // console.log('msgData', msgData);
    const fallbackSlideUrl = window.location.origin + '/assets/images/blank.jpg';
    if (msgData.base64EncodedImage) {
        const fullImageDataBase64 = `data:image/png;base64,${msgData.base64EncodedImage}`;
        const activitySlideName = `slide-${msgData.activityId}.jpg`;
        const activitySlideFile = dataURLtoFile(fullImageDataBase64, activitySlideName);
        const user = getValidUserFromStore('convertHostMsgToActivityProps');

        let imageUrl = '';
        for (let i = 0; i < 3; i++) {
            imageUrl = await apiUser.uploadToStorage(
                activitySlideFile,
                activitySlideName,
                user.apiServer,
                true,
                'slides',
            );
            if (imageUrl) break;
        }
        msgData.activitySlideUrl = imageUrl;
    }
    msgData.activityStartTime = new Date().getTime();
    if (msgData.countdown > 0) {
        msgData.activityEndTime = calculateActivityEndTime(msgData.countdown);
    }
    if (msgData.isMultipleSubmissionsAllowed !== undefined) {
        msgData.numOfSubmissionsAllowed = msgData.isMultipleSubmissionsAllowed
            ? getNumOfSubmissionsAllowed(msgData.activityId)
            : 1;
    }

    let activityProps: ActivityPropsInterface = {
        activityId: msgData.activityId,
        activityType: msgData.activityType,
        activitySlideUrl: msgData.activitySlideUrl,
        activityStartTime: msgData.activityStartTime,
        activityEndTime: msgData.activityEndTime,
        countdown: msgData.countdown,
    };
    switch (msgData.activityType) {
        case utilConstants.ACTIVITY_TYPE.MULTIPLE_CHOICE:
            activityProps.mcChoices = msgData.mcChoices;
            activityProps.mcIsAllowSelectMultiple = msgData.mcIsAllowSelectMultiple;
            activityProps.mcCorrectAnswers = msgData.mcCorrectAnswers;
            activityProps.isQuizMode = msgData.isQuizMode;
            if (activityProps.isQuizMode) {
                activityProps.correctPoints = msgData.correctPoints;
                activityProps.correctSpeedBonus = msgData.correctSpeedBonus;
            }
            break;
        case utilConstants.ACTIVITY_TYPE.FILL_IN_THE_BLANKS:
            activityProps.fbNumOfBlanks = msgData.fbNumOfBlanks;
            activityProps.fbCorrectAnswers = msgData.fbCorrectAnswers;
            activityProps.isNamesHidden = msgData.isNamesHidden;
            break;
        case utilConstants.ACTIVITY_TYPE.WORD_CLOUD:
            activityProps.numOfSubmissionsAllowed = msgData.numOfSubmissionsAllowed;
            break;
        case utilConstants.ACTIVITY_TYPE.SHORT_ANSWER:
            activityProps.numOfSubmissionsAllowed = msgData.numOfSubmissionsAllowed;
            activityProps.isNamesHidden = msgData.isNamesHidden;
            break;
        case utilConstants.ACTIVITY_TYPE.SLIDE_DRAWING:
            // Slide Drawing cannot have empty url
            if (!activityProps.activitySlideUrl) activityProps.activitySlideUrl = fallbackSlideUrl;
            activityProps.numOfSubmissionsAllowed = msgData.numOfSubmissionsAllowed;
            activityProps.isNamesHidden = msgData.isNamesHidden;
            break;
        case utilConstants.ACTIVITY_TYPE.IMAGE_UPLOAD:
            activityProps.numOfSubmissionsAllowed = msgData.numOfSubmissionsAllowed;
            activityProps.isNamesHidden = msgData.isNamesHidden;
            activityProps.isCaptionRequired = msgData.isCaptionRequired;
            break;
        case utilConstants.ACTIVITY_TYPE.AUDIO_RECORD:
            activityProps.isNamesHidden = msgData.isNamesHidden;
            break;
        case utilConstants.ACTIVITY_TYPE.VIDEO_UPLOAD:
            activityProps.isNamesHidden = msgData.isNamesHidden;
            activityProps.isCaptionRequired = msgData.isCaptionRequired;
            break;
        case utilConstants.ACTIVITY_TYPE.QUICK_POLL:
            activityProps.qpType = msgData.qpType;
            activityProps.qpChoices = msgData.qpChoices;
            break;
        default:
            break;
    }
    // console.log('activityProps', activityProps);
    return activityProps;
};

const calculateActivityEndTime = (countdown: number) => {
    const now = new Date();
    return now.setSeconds(now.getSeconds() + countdown);
};

export const timeStampSuffix = () => {
    const nowTimeString = DateTime.now().toUTC().toFormat('yyyyMMddHHmmssSSS');
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let randomString = '';
    for (let i = 0; i < 4; i++) {
        randomString += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return nowTimeString + randomString;
};

export const sortSelectedResponses = (responses: any[]) => {
    if (responses[0].voterParticipantIds) return responses.sort(sortFunc);
    else return responses;
};

const sortFunc = (
    a: { voterParticipantIds: string | any[]; responseSubmittedOn: string },
    b: { voterParticipantIds: string | any[]; responseSubmittedOn: string },
) => {
    if (a.voterParticipantIds.length === b.voterParticipantIds.length)
        return Date.parse(a.responseSubmittedOn) - Date.parse(b.responseSubmittedOn);
    return b.voterParticipantIds.length - a.voterParticipantIds.length;
};

export const processResponses = (
    allResponses: ActivityResponseInterface[],
    searchKeyword: string,
    isSearchNameOnly: boolean,
    isNamesHidden: boolean,
    isShowStarred: boolean,
    searchCaption: boolean,
): ActivityResponseInterface[] => {
    const filteredResponses = isShowStarred
        ? allResponses.filter((x: ActivityResponseInterface) => x.responsePoints > 0)
        : allResponses;

    if (!searchKeyword.trim()) return filteredResponses;
    if (isSearchNameOnly && isNamesHidden) return filteredResponses;

    const searchedResponses = filteredResponses.filter(function (obj: ActivityResponseInterface) {
        const toSearch = searchCaption ? JSON.parse(obj.responseData)[1] : obj.responseData;
        if (isNamesHidden) {
            return checkAndMarkMatchingHtml(toSearch.toLowerCase(), searchKeyword.toLowerCase()).isMatching;
        }
        if (isSearchNameOnly) {
            return checkAndMarkMatchingHtml(obj.participantName.toLowerCase(), searchKeyword.toLowerCase()).isMatching;
        }
        return (
            checkAndMarkMatchingHtml(obj.participantName.toLowerCase(), searchKeyword.toLowerCase()).isMatching ||
            checkAndMarkMatchingHtml(toSearch.toLowerCase(), searchKeyword.toLowerCase()).isMatching
        );
    });
    // console.log('searchedResponses', searchedResponses);
    return searchedResponses;
};

export const populateParticipantChips = (
    activityResponses: ActivityResponseInterface[],
): ParticipantChipInterface[] => {
    let participants: ParticipantChipInterface[] = [];
    activityResponses.map((item, index: any) =>
        participants.push({
            participantId: item.participantId,
            participantName: item.participantName,
            participantAvatar: item.participantAvatar,
        }),
    );
    const duplicatesRemoved = participants.filter((value, index) => participants.indexOf(value) === index);
    return duplicatesRemoved;
};

export const checkAndMarkMatchingHtml = (
    html: string,
    search: string,
): { isMatching: boolean; replacedHtml: string } => {
    if (!search.trim() || search.trim() === '<' || search.trim() === '>')
        return { isMatching: true, replacedHtml: html };

    html = decode(html, { level: 'html5' });
    // sometimes html is pure text, for our logic to work, we surround it with a pair of html tags
    if (!html.startsWith('<')) html = `<>${html}</>`;

    const tempArray = html.split('<');
    let separatedArray: string[] = [];

    tempArray.forEach((item) => {
        if (!!item) {
            item = '<' + item;
            if (item.includes('>')) {
                const splitted = item.split('>');
                separatedArray.push(splitted[0] + '>');
                separatedArray.push(splitted[1]);
            } else separatedArray.push(item);
        }
    });

    const replacedArray = separatedArray.map((text) => {
        if (text.includes('<')) return text;
        return replaceMultipleMatchingText(text, search);
    });
    const replacedHtml = replacedArray.join('');
    return { isMatching: html !== replacedHtml, replacedHtml };
};

const replaceMultipleMatchingText = (text: string, search: string): string => {
    const searchArray = search
        .split(',')
        .map((s) => s.trim().toLowerCase())
        .filter((value, index, self) => self.indexOf(value) === index);
    for (const singleSearch of searchArray) {
        if (!singleSearch) continue;
        text = replaceSingleMatchingText(text, singleSearch);
    }
    return text.replaceAll('<<<<<<', '<mark>').replaceAll('>>>>>>', '</mark>');
};

const replaceSingleMatchingText = (text: string, search: string): string => {
    const searchLeng = search.length;
    let indexArr: number[] = [];
    let array: string[] = [];

    if (!text.toLowerCase().includes(search.toLowerCase())) return text;

    for (let i = 0; i < text.length; i++) {
        const subString = text.substring(i, i + search.length);
        if (subString.toLowerCase() === search.toLowerCase()) indexArr.push(i);
    }

    array.push(text.substring(0, indexArr[0]));
    for (let j = 0; j < indexArr.length; j++) {
        const matchingPart = text.substring(indexArr[j], indexArr[j] + searchLeng);
        array.push(`<<<<<<${matchingPart}>>>>>>`);
        const tillNextIndex = text.substring(indexArr[j] + searchLeng, indexArr[j + 1]);
        array.push(tillNextIndex);
    }
    const replacedText = array.join('');

    return replacedText;
};

export const searchKeywordToArray = (searchKeyword: string): string[] => {
    return searchKeyword
        .split(',')
        .map((t) => t.trim().toLowerCase())
        .filter((t) => t);
};

export const getWordCloudTopAnswerMinimumCount = (countArray: number[], topNumberCountMax: number): number => {
    const uniqueCounts = countArray.filter((value, index, self) => self.indexOf(value) === index);
    let numberIndexArray: number[] = [];
    uniqueCounts.forEach((number) => {
        numberIndexArray.push(countArray.findIndex((n) => n === number));
    });
    const trimmedNumberIndexArray = numberIndexArray.filter((index) => index <= topNumberCountMax);
    const lastIndex = trimmedNumberIndexArray[trimmedNumberIndexArray.length - 1];

    const lastAllowedCount = countArray[lastIndex - 1];

    return lastAllowedCount;
};

export const getActivityComponentClassName = (activityType: string): string => {
    let dclassName;
    switch (activityType) {
        case utilConstants.ACTIVITY_TYPE.SHORT_ANSWER:
            dclassName = 'activity_short_ans';
            break;
        case utilConstants.ACTIVITY_TYPE.IMAGE_UPLOAD:
            dclassName = 'activity_image_upload';
            break;
        case utilConstants.ACTIVITY_TYPE.SLIDE_DRAWING:
            dclassName = 'activity_slide_drawing';
            break;
        case utilConstants.ACTIVITY_TYPE.AUDIO_RECORD:
            dclassName = 'activity_audio_record';
            break;
        case utilConstants.ACTIVITY_TYPE.VIDEO_UPLOAD:
            dclassName = 'activity_video_upload';
            break;
        default:
            dclassName = '';
            break;
    }
    return dclassName;
};

export const generateParticipantsAndPoints = (
    responsesAndPoints: ResponseAndPointsDto[],
): ParticipantAndPointsDto[] => {
    const participantIdsAndPoints = responsesAndPoints.map((item) => {
        return {
            participantId: item.response.participantId,
            points: item.points,
        };
    });
    const groupedObj = _.groupBy(participantIdsAndPoints, (n) => n.participantId);

    const participantsAndPoints: ParticipantAndPointsDto[] = [];
    Object.keys(groupedObj).forEach(function (key) {
        const sum = groupedObj[key].map((item) => item.points).reduce((a, b) => a + b, 0);
        const obj = { participantId: key, points: sum };
        participantsAndPoints.push(obj);
    });

    return participantsAndPoints;
};

export const calculateParticipantsAndPointsForQuiz = (
    activityProps: ActivityPropsInterface,
    activityResponses: ActivityResponseInterface[],
): ParticipantAndPointsDto[] => {
    const mcCorrectAnswers = activityProps.mcCorrectAnswers;
    if (!mcCorrectAnswers) return [];

    const correctResponses = activityResponses.filter(
        (response) => response.responseData === JSON.stringify(mcCorrectAnswers),
    );

    const speedBonusCount = 5;
    const correctPoints = activityProps.correctPoints || 0;
    const speedBonusPoints = activityProps.correctSpeedBonus || 0;

    const participantsAndPoints: ParticipantAndPointsDto[] = correctResponses.map((response) => ({
        participantId: response.participantId,
        points: correctPoints,
    }));
    const bonusParticipantsCount = Math.min(correctResponses.length, speedBonusCount);
    for (let i = 0; i < bonusParticipantsCount; i++) {
        participantsAndPoints[i].points += speedBonusPoints;
    }

    return participantsAndPoints;
};

export const calculateQuizResponseAndPointsDto = (
    activityProps: ActivityPropsInterface,
    activityResponses: ActivityResponseInterface[],
): QuizResponseAndPointsDto[] => {
    const mcCorrectAnswers = activityProps.mcCorrectAnswers;
    if (!mcCorrectAnswers) return [];

    const correctResponses = activityResponses.filter(
        (response) => response.responseData === JSON.stringify(mcCorrectAnswers),
    );

    const speedBonusCount = 5;
    const correctPoints = activityProps.correctPoints || 0;
    const correctSpeedBonus = activityProps.correctSpeedBonus || 0;

    const quizResponsesAndPoints: QuizResponseAndPointsDto[] = correctResponses.map((response) => ({
        response: { responseId: response.responseId, participantId: response.participantId },
        correctPoints,
        correctSpeedBonus: 0,
    }));
    const bonusParticipantsCount = Math.min(correctResponses.length, speedBonusCount);
    for (let i = 0; i < bonusParticipantsCount; i++) {
        quizResponsesAndPoints[i].correctSpeedBonus = correctSpeedBonus;
    }

    return quizResponsesAndPoints;
};

export const isJson = (str: string): boolean => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export const checkIfSingleBlankIsCorrect = (possibleCorrectAnswersString: string, answer: string) => {
    const possibeCorrectAnswers: string[] = JSON.parse(possibleCorrectAnswersString);
    return possibeCorrectAnswers
        .map((correctAnswer) => correctAnswer.trim().toLowerCase())
        .includes(answer.trim().toLowerCase());
};

export const calculateShortAnswerModalTextCssName = (responseData: string): string => {
    const originalText = removeTags(responseData);
    const originalTextLength = originalText ? originalText.length : 0;
    let size: string;
    if (originalTextLength <= 160) {
        size = '28';
    } else if (originalTextLength > 160 && originalTextLength <= 180) {
        size = '26';
    } else if (originalTextLength > 180 && originalTextLength <= 195) {
        size = '24';
    } else if (originalTextLength > 195 && originalTextLength <= 270) {
        size = '22';
    } else if (originalTextLength > 270 && originalTextLength <= 325) {
        size = '20';
    } else if (originalTextLength > 325 && originalTextLength <= 385) {
        size = '28';
    } else {
        size = '16';
    }
    return size;
};

const removeTags = (str: string) => {
    if (str === null || str.trim() === '') return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, '');
};

export const secondsToTimeDisplay = (seconds: number) => {
    const h = Math.floor(seconds / 3600)
        .toString()
        .padStart(2, '0');
    const m = Math.floor((seconds % 3600) / 60)
            .toString()
            .padStart(2, '0'),
        s = Math.floor(seconds % 60)
            .toString()
            .padStart(2, '0');
    if (h !== '00') return h + ':' + m + ':' + s;
    return m + ':' + s;
};

export const generateActivityFileArrayForDownload = (
    selectedResponses: ActivityResponseInterface[],
    allResponses: ActivityResponseInterface[],
) => {
    let payload: { index: number; fileName: string; url: string }[] = [];
    selectedResponses.forEach((response: ActivityResponseInterface) => {
        const participantName = response.participantName;
        const responseIndex = allResponses.findIndex((r) => r.responseId === response.responseId);
        let url, fileName;
        if (isJson(response.responseData)) {
            url = JSON.parse(response.responseData)[0];
        } else url = response.responseData;
        fileName = `${participantName}-${url.substring(url.length - 7)}`;

        payload.push({
            index: responseIndex,
            fileName,
            url,
        });
    });

    return payload;
};

export const generateActivityFileIndexesForDownload = (
    selectedResponses: ActivityResponseInterface[],
    allResponses: ActivityResponseInterface[],
): string => {
    let indexArray: number[] = [];
    selectedResponses.forEach((response: ActivityResponseInterface) => {
        const responseIndex = allResponses.findIndex((r) => r.responseId === response.responseId);
        indexArray.push(responseIndex);
    });

    const indexesString = indexArray.join(',');
    // console.log('indexesString', indexesString);
    return indexesString;
};

export const processHtmlForInsertSlide = (html: string): string => {
    //remove code brakes and tabs
    html = html.replace(/\n/g, '');
    html = html.replace(/\t/g, '');

    //keep html brakes and tabs
    html = html.replace(/<\/td>/g, '\t');
    html = html.replace(/<\/table>/g, '\n');
    html = html.replace(/<\/tr>/g, '\n');
    html = html.replace(/<\/p>/g, '\n');
    html = html.replace(/<\/div>/g, '\n');
    html = html.replace(/<\/h>/g, '\n');
    html = html.replace(/<\/ul>/g, '\n');
    html = html.replace(/<li>/g, '• ');
    html = html.replace(/<\/li>/g, '\n');
    html = html.replace(/<br>/g, '\n');
    html = html.replace(/<br( )*\/>/g, '\n');
    html = html.substring(0, html.length - 1);

    // remove ending \n
    while (html.endsWith('\n')) {
        html = html.slice(0, html.length - 1);
    }

    //parse html into text
    var dom = new DOMParser().parseFromString('<!doctype html><body>' + html, 'text/html');
    return dom.body.textContent || '';
};

export const canEditResponses = (activity: ActivityInterface, email: string): boolean => {
    const isNotActivityVersion1 = activity.activityVersion !== 1;
    const isCurrentUser = !activity.activityUser || activity.activityUser?.email === email;
    return isNotActivityVersion1 && isCurrentUser;
};

export const linkify = (html: string) => {
    // eslint-disable-next-line no-useless-escape
    var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return html.replace(urlRegex, function (url) {
        return '<a href="' + url + '" target="_blank">' + url + '</a>';
    });
};

export const checkUrlExistInFileApp = (fileUrl: string, fileAppBaseUrl: string): boolean => {
    if (fileAppBaseUrl && fileUrl.split('/').length > 0) {
        return fileUrl.split('/')[2].includes(fileAppBaseUrl);
    } else return false;
};

export const findImageExtensionFromUrl = (imageUrl: string): string => {
    const acceptedExts = ['.jpg', '.jpeg', '.png', '.gif', '.tif', '.tiff', '.bmp', '.eps'];
    for (let ext of acceptedExts) {
        if (imageUrl.includes(ext)) return ext;
    }
    return '.jpg';
};
