import { PublicClientApplication } from '@azure/msal-browser';

const CLIENT_ID_REGEX = /[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/;

interface GetUserAgentAppInterface {
    clientId: string;
    tenantUrl?: string;
    redirectUri?: string;
    postLogoutRedirectUri?: string;
    useLocalStorageCache?: boolean;
}

export const getUserAgentApp = ({
    clientId,
    tenantUrl,
    redirectUri,
    postLogoutRedirectUri,
    useLocalStorageCache,
}: GetUserAgentAppInterface) => {
    if (clientId && CLIENT_ID_REGEX.test(clientId)) {
        return new PublicClientApplication({
            auth: {
                ...(redirectUri && { redirectUri }),
                ...(tenantUrl && { authority: tenantUrl }),
                ...(postLogoutRedirectUri && { postLogoutRedirectUri }),

                clientId,
                navigateToLoginRequestUrl: false,
            },
            cache: {
                ...(useLocalStorageCache ? { cacheLocation: 'localStorage' } : { cacheLocation: 'sessionStorage' }),
            },
        });
    }
};

export const getScopes = (graphScopes?: string[]) => {
    const scopes = graphScopes || [];
    if (!scopes.find((el: string) => el.toLowerCase() === 'user.read')) {
        scopes.push('user.read');
    }
    return scopes;
};

export const checkToIE = (): boolean => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    const msie11 = ua.indexOf('Trident/');
    const msedge = ua.indexOf('Edge/');
    const isIE = msie > 0 || msie11 > 0;
    const isEdge = msedge > 0;
    return isIE || isEdge;
};

export const blobToBase64 = (blob: any) => {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
};
