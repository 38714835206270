export enum WebviewSettingsActionTypes {
    HOST_FEED_PREFERENCES = 'HOST_FEED_PREFERENCES',
    UPDATE_SHOW_LOCK_ALERT = 'UPDATE_SHOW_LOCK_ALERT',
    UPDATE_SHOW_REMOVE_PARTICIPANT_ALERT = 'UPDATE_SHOW_REMOVE_PARTICIPANT_ALERT',
    UPDATE_SHOW_RESTART_ACTIVITY_ALERT = 'UPDATE_SHOW_RESTART_ACTIVITY_ALERT',
    UPDATE_SHOW_DELETE_RESPONSE_ALERT = 'UPDATE_SHOW_DELETE_RESPONSE_ALERT',
    UPDATE_SHOW_RESPONSE = 'UPDATE_SHOW_RESPONSE',
    UPDATE_PLAY_ACTIVITY_MUSIC = 'UPDATE_PLAY_ACTIVITY_MUSIC',
    UPDATE_SHOW_END_ACTIVITY_ALERT = 'UPDATE_SHOW_END_ACTIVITY_ALERT',
    UPDATE_PARTICIPANT_SORT_ORDER = 'UPDATE_PARTICIPANT_SORT_ORDER',
    TURN_OFF_PICK_NAMES_RESET_REMINDER = 'TURN_OFF_PICK_NAMES_RESET_REMINDER',
    UPDATE_PICK_NAMES_MODE = 'UPDATE_PICK_NAMES_MODE',
    WHITE_BOARD_DELETE_ALERT = 'WHITE_BOARD_DELETE_ALERT',
    TOGGLE_SHOW_TOOLBAR_IN_SLIDESHOW = 'TOGGLE_SHOW_TOOLBAR_IN_SLIDESHOW',
    TOGGLE_SHOW_CLASS_CODE_LABEL_IN_SLIDESHOW = 'TOGGLE_SHOW_CLASS_CODE_LABEL_IN_SLIDESHOW',
    TOGGLE_AUDIENCE_SLIDE_VIEWER = 'TOGGLE_AUDIENCE_SLIDE_VIEWER',
    TOGGLE_ADD_WHITEBOARD_VIEW = 'TOGGLE_ADD_WHITEBOARD_VIEW',
    TOGGLE_SHOW_50_MORE_RESPONSES = 'TOGGLE_SHOW_50_MORE_RESPONSES',
    ADJUST_ACTIVITY_MUSIC_VOLUME = 'ADJUST_ACTIVITY_MUSIC_VOLUME',
    UPDATE_ACTIVITY_MUSIC_URL = 'UPDATE_ACTIVITY_MUSIC_URL',
    UPDATE_PICKED_NAMES_PANEL_VIEW = 'UPDATE_PICKED_NAMES_PANEL_VIEW',
    UPDATE_IS_SHOW_LEADERS_OVERALL_VIEW = 'UPDATE_IS_SHOW_LEADERS_OVERALL_VIEW',
    UPDATE_IS_MY_CLASS_SIDEBAR_VIEW = 'UPDATE_IS_MY_CLASS_SIDEBAR_VIEW',
    TOGGLE_PICK_NAME_SHOW_ONLINE_PARTICIPANT = 'TOGGLE_PICK_NAME_SHOW_ONLINE_PARTICIPANT',
    TOGGLE_MY_CLASS_SHOW_ONLINE_PARTICIPANT = 'TOGGLE_MY_CLASS_SHOW_ONLINE_PARTICIPANT',
    SHOW_RESET_STAR_ALERT = 'SHOW_RESET_STAR_ALERT',
    UPDATE_PARTICIPANT_NAMES_HIDDEN = 'UPDATE_PARTICIPANT_NAMES_HIDDEN',
    TOGGLE_SHOW_TOOLBAR_LABELS = 'TOGGLE_SHOW_TOOLBAR_LABELS',
}
