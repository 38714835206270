import { Fragment, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import webviewMessenger from '../../../../services/webviewMessenger';
import CloseButton from '../../../activity/activityButtons/CloseButton';
import CommonButton from '../../../../components/Common/CommonButton';
import BackUi from '../../../../components/Common/BackUi';
import { useSelector } from 'react-redux';
import StoreInterface from '../../../../interfaces/store.interface';

interface ILeaderFooter {
    isCurrentClassLeaderView: boolean;
    showMoreDefaultView: () => void;
    showMoreCurrentClassLeader: () => void;
    numOfElementsDefaultView: number;
    numOfElementsCurrentView: number;
    hideBackBtn: boolean;
    isPremiumUser: boolean;
    showGroupRankModalHandler: (value: boolean) => void;
}

function LeaderFooter({
    isCurrentClassLeaderView,
    showMoreDefaultView,
    showMoreCurrentClassLeader,
    numOfElementsDefaultView,
    numOfElementsCurrentView,
    hideBackBtn,
    isPremiumUser,
    showGroupRankModalHandler,
}: ILeaderFooter) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const classSession = useSelector((state: StoreInterface) => state.classSession);

    const showBackToMyClass = location.state ? true : false;
    const participantList = classSession ? classSession.participantList : [];

    const [isLoadingShowMore, setIsLoadingShowMore] = useState(false);

    const handleClose = () => {
        webviewMessenger.sendHideWebview();
        navigate('/loading');
    };

    const showmore = () => {
        setIsLoadingShowMore(true);
        setTimeout(() => {
            isCurrentClassLeaderView ? showMoreCurrentClassLeader() : showMoreDefaultView();
        }, 500);
        setTimeout(() => {
            setIsLoadingShowMore(false);
        }, 500);
    };

    const backToMyClass = () => {
        navigate('/myclass');
    };

    return (
        <Fragment>
            {showBackToMyClass && !hideBackBtn && (
                <div className="back" onClick={() => backToMyClass()}>
                    <BackUi />
                </div>
            )}

            <div className="footer_middle gap leaderBoardFooter">
                {participantList.length > 0 ? (
                    <Fragment>
                        {isLoadingShowMore ? (
                            <CommonButton className="default_btn" isLoading={true} text="Loading" />
                        ) : (
                            <CommonButton
                                isLoading={false}
                                disabled={
                                    isCurrentClassLeaderView
                                        ? numOfElementsCurrentView >= participantList.length
                                            ? true
                                            : false
                                        : numOfElementsDefaultView >= participantList.length
                                        ? true
                                        : false
                                }
                                className="default_btn"
                                type="button"
                                clickFunction={() => showmore()}
                                text={t('lang_leaderboard.btn_show_more')}
                            />
                        )}

                        {isPremiumUser && classSession && !!classSession.savedClassId && (
                            <CommonButton
                                isLoading={false}
                                className="default_btn showGroupRankBtn"
                                type="button"
                                clickFunction={() => showGroupRankModalHandler(true)}
                                text={t('lang_grouping.leaderboard_footer_show_rank')}
                            />
                        )}
                    </Fragment>
                ) : (
                    <CloseButton onClose={handleClose} />
                )}
            </div>
        </Fragment>
    );
}

export default LeaderFooter;
