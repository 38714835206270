import { useEffect, useState, useRef } from 'react';
import { FitToViewport } from 'react-fit-to-viewport';
import { useDispatch, useSelector } from 'react-redux';

import './MyClass.scss';

import { classSessionActions } from '../../actions/class-session.action';
import { getValidUserFromStore } from '../../helpers/storeHelpers';

import ParticipantInterface from '../../interfaces/participant.interface';
import StoreInterface from '../../interfaces/store.interface';
import { ViewportConstants } from '../../constants/viewport-constants';
import { AlertTypes } from '../../constants/alert-types-enum';

import CommonAlert from '../../components/Common/CommonAlert';
import { FullScreenPointLottie } from '../../components/Common/AnimationModules';
import MyClassHeader from './MyClassHeader';
import ParticipantPanel from './ParticipantPanel';
import MyClassFooter from './MyClassFooter';
import EmptyModal from '../EmptyModal/EmptyModal';
import Loading from '../activity/Loading/Loading';
import LockClassAlert from './modal/LockClassAlert';
import RemoveParticipantAlert from './modal/RemoveParticipantAlert';
import MyClassQRModal from './modal/MyClassQRModal';
import RestartClassModal, { SelectedClassInterface } from './modal/RestartClassModal';
import MyClassQRCodeModal from './modal/MyClassQRCodeModal';
import { userActions } from '../../actions/user.actions';
import { ActivateTrial } from '../activateTrial/ActivateTrial';
import apiClassSessions from '../../services/apiClassSessions';
import { getCpcsRegion } from '../../helpers/classSessionHelpers';
import webviewMessenger from '../../services/webviewMessenger';
import ResetStarsAlert from './modal/ResetStarsAlert';
import { findUserClassLimit, isUserOnTrialOrPro } from '../../helpers/userhelper';
import { QuotaConstants } from '../../constants/quota-constants';
import { aiQuizActions } from '../../actions/aiQuiz.action';
import apiSavedClasses from '../../services/apiSavedClasses';

const MyClass = () => {
    const dispatch = useDispatch();
    const user = getValidUserFromStore('MyClass');
    const isTrialOrPro = isUserOnTrialOrPro(user);
    const classSession = useSelector((state: StoreInterface) => state.classSession);
    const webviewSettingsSlideshow = useSelector((state: StoreInterface) => state.webviewSettingsSlideshow);
    const givePointsConfettiRef = useRef<any>();

    const [isMyClassSidebarShown, setIsMyClassSidebarShown] = useState(webviewSettingsSlideshow.isMyClassSidebarShown);
    const [showEmptyModal, setShowEmptyModal] = useState(false);
    const [selectedParticipant, setSelectedParticipant] = useState<ParticipantInterface>();
    const [isQRModalOpen, setIsQRModalOpen] = useState(false);
    const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);
    const [alertType, setAlertType] = useState<AlertTypes>();
    const [isRestartingClass, setIsRestartingClass] = useState(false);
    const [sortedParticipants, setSortedParticipants] = useState<ParticipantInterface[]>([]);
    const [isAnimating, setIsAnimating] = useState(false);

    const [selectedClass, setSelectedClass] = useState<SelectedClassInterface>();
    const [isAllowGuests, setIsAllowGuests] = useState(false);
    const [isSaveGuests, setIsSaveGuests] = useState(false);

    const [isActivateTrialModalOpen, setIsActivateTrialModalOpen] = useState(false);

    const userProfile = user.userProfile;
    let savedClasses = userProfile?.savedClasses || [];

    const toggleSidebar = () => setIsMyClassSidebarShown((prev) => !prev);

    useEffect(() => {
        setAlertType(undefined);
        setIsRestartingClass(false);
    }, [classSession?.classSessionId]);

    useEffect(() => {
        setShowEmptyModal(true);
        setTimeout(() => {
            setShowEmptyModal(false);
        }, 50);
    }, [isRestartingClass]);

    const triggerRestartClass = async (doubleConfirmed = false) => {
        if (!selectedClass) return;

        if (savedClasses.length === 0) {
            return confirmRestartClass(null, null, false, false);
        }
        // public class
        if (selectedClass.classId === 'public') {
            return confirmRestartClass(null, null, false, false);
        }
        // demo class
        if (selectedClass.classCode === null) {
            return confirmRestartClass(selectedClass.classId, selectedClass.classCode, isAllowGuests, isSaveGuests);
        }
        // check if we should show reset stars alert
        if (!isTrialOrPro && webviewSettingsSlideshow.isShowResetStarAlert && !doubleConfirmed) {
            setIsRestartingClass(true);
            const savedClass = await apiSavedClasses.getSavedClass(selectedClass.classId, user.email);
            const participantTotalPoints = savedClass?.participants
                .map((p) => p.participantTotalPoints)
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            if (participantTotalPoints && participantTotalPoints > 0) {
                setIsRestartingClass(false);
                setAlertType(AlertTypes.RESET_STARS);
                return;
            }
        }
        // this case will happen if a basic user was previously a pro user
        if (!isTrialOrPro && selectedClass.participantCount > findUserClassLimit(user)) return;

        confirmRestartClass(selectedClass.classId, selectedClass.classCode, isAllowGuests, isSaveGuests);
    };

    const confirmRestartClass = (
        savedClassId: string | null,
        savedClassCode: string | null,
        isAllowGuests: boolean,
        isSaveGuests: boolean,
    ) => {
        dispatch(classSessionActions.restartClass(savedClassId, savedClassCode, isAllowGuests, isSaveGuests));
        setIsRestartingClass(true);
        dispatch(aiQuizActions.resetAiQuiz());
    };

    const confirmLockClass = () => {
        dispatch(classSessionActions.lockUnlockClass(true));
        setAlertType(undefined);
    };

    const confirmRemoveParticipant = () => {
        if (!selectedParticipant) return;
        classSessionActions.removeParticipant(selectedParticipant, dispatch);
        setAlertType(undefined);
    };

    let alertComponent;
    switch (alertType) {
        case AlertTypes.LOCK_CLASS:
            alertComponent = (
                <LockClassAlert
                    onConfirmLockClass={() => confirmLockClass()}
                    onCancelAlert={() => setAlertType(undefined)}
                />
            );
            break;
        case AlertTypes.REMOVE_PARTICIPANT:
            alertComponent = (
                <RemoveParticipantAlert
                    onConfirmRemoveParticipant={() => confirmRemoveParticipant()}
                    onCancelAlert={() => setAlertType(undefined)}
                />
            );
            break;
        case AlertTypes.RESTART_CLASS:
            alertComponent = (
                <RestartClassModal
                    triggerRestartClass={triggerRestartClass}
                    onCancelAlert={() => setAlertType(undefined)}
                    isProcessing={isRestartingClass}
                    alertType={alertType}
                    selectedClass={selectedClass}
                    setSelectedClass={setSelectedClass}
                    isAllowGuests={isAllowGuests}
                    setIsAllowGuests={setIsAllowGuests}
                    isSaveGuests={isSaveGuests}
                    setIsSaveGuests={setIsSaveGuests}
                />
            );
            break;
        case AlertTypes.RESET_STARS:
            alertComponent = (
                <ResetStarsAlert
                    user={user}
                    handleDoubleConfirm={() => {
                        triggerRestartClass(true);
                    }}
                />
            );
            break;
        default:
            break;
    }

    return classSession ? (
        <div className="black">
            <FitToViewport
                className={`viewport myClassPointContainer ${isAnimating && 'show'}`}
                width={ViewportConstants.width}
                height={ViewportConstants.height}
                minZoom={ViewportConstants.minZoom}
                maxZoom={ViewportConstants.maxZoom}
            >
                <FullScreenPointLottie ref={givePointsConfettiRef} />
                {showEmptyModal && <EmptyModal viewportConfig={ViewportConstants} />}

                <div className="my_section">
                    <MyClassHeader
                        openQRModal={() => setIsQRModalOpen(true)}
                        openQRCodeModal={() => setIsQRCodeModalOpen(true)}
                        isMyClassSidebarShown={isMyClassSidebarShown}
                    />
                    <div className="myclass_body">
                        <ParticipantPanel
                            onSelectParticipantToRemove={(participant: ParticipantInterface) => {
                                setSelectedParticipant(participant);
                                setAlertType(AlertTypes.REMOVE_PARTICIPANT);
                            }}
                            sortedParticipants={sortedParticipants}
                            setSortedParticipants={setSortedParticipants}
                            onShowMyClassQRModal={() => setIsQRModalOpen(true)}
                            isMyClassSidebarShown={isMyClassSidebarShown}
                            toggleSidebar={toggleSidebar}
                            openQRCodeModal={() => setIsQRCodeModalOpen(true)}
                        />
                    </div>

                    <MyClassFooter
                        restartClass={() => setAlertType(AlertTypes.RESTART_CLASS)}
                        sortedParticipants={sortedParticipants}
                        givePointsConfettiRef={givePointsConfettiRef}
                        setIsAnimating={setIsAnimating}
                    />

                    <CommonAlert
                        showAlert={alertType !== undefined}
                        closeAlert={() => {
                            if (!isRestartingClass) {
                                webviewMessenger.sendUsageLog(`[S] Closed modal`);
                                setAlertType(undefined);
                            }
                        }}
                        content={alertComponent}
                        viewportConfig={ViewportConstants}
                        customModalClassNames={
                            alertType === AlertTypes.REMOVE_PARTICIPANT || alertType === AlertTypes.RESTART_CLASS
                                ? 'small'
                                : ''
                        }
                        customBoxClassNames={
                            AlertTypes.RESTART_CLASS && savedClasses.length > 0
                                ? ` ${
                                      alertType === AlertTypes.RESET_STARS ? 'restartalertmodal' : 'switch_participent'
                                  }`
                                : ''
                        }
                    />
                </div>
            </FitToViewport>

            <MyClassQRModal
                isQRModalOpen={isQRModalOpen}
                closeModal={() => {
                    webviewMessenger.sendUsageLog(`[S] Closed modal`);
                    setIsQRModalOpen(false);
                }}
                onStartActivateTrial={() => {
                    setIsQRModalOpen(false);
                    setIsActivateTrialModalOpen(true);
                }}
            />
            <MyClassQRCodeModal isQRCodeModalOpen={isQRCodeModalOpen} closeModal={() => setIsQRCodeModalOpen(false)} />

            <CommonAlert
                showAlert={isActivateTrialModalOpen}
                content={
                    <ActivateTrial
                        viewport={ViewportConstants}
                        onActivationFailed={() => {
                            setIsActivateTrialModalOpen(false);
                            dispatch(userActions.showApiError());
                        }}
                        onSuccessBtnClick={() => {
                            setIsActivateTrialModalOpen(false);
                            apiClassSessions.updateClassLimit(getCpcsRegion(user), user.email, user.classLimit);
                        }}
                        onDismissVerifyEmail={() => {
                            setIsActivateTrialModalOpen(false);
                        }}
                    />
                }
                viewportConfig={ViewportConstants}
                customModalClassNames="small"
                customBoxClassNames="subscriptionTrialActivationModal"
            />
        </div>
    ) : (
        <Loading />
    );
};

export default MyClass;
