import axios from 'axios';
import axiosWithLog from './axios-with-log';

import VerifyKeyOutDto from '../dtos/verify-key-out.dto';
import { uploadToAzureStorage } from './azureStorageService';
import { sentryLogApiError } from './sentryService';

import UserInterface from '../interfaces/user-interface';
import { UserMetricsInterface } from '../interfaces/user-metrics.interface';
import { ResetPasswordInterface, SignUpUserInterface } from '../interfaces/signInSignUp.interface';
import UserClassSessionInterface from '../interfaces/user-class-session.interface';

import { logger } from './logger';
// import { AxiosRetryConfig } from '../constants/axios-retry-configs';
import webviewMessenger from './webviewMessenger';
import { DEFAULT_COUNTRY, DEFAULT_COUNTRY_CODE } from '../constants/utils.constants';
import { GeoInfoInterface, getSavedGeoInfo } from './locationService';
import { SsoSignUpInterface } from '../interfaces/sso-signup.interface';
// import { localService } from './localStorageService';

let cancelToken: any;

interface UpdatedUserWithDurationInterface {
    updatedUser: UserInterface;
    duration: number;
}

const apiUser = {
    getGeoInfoChange: async (savedIp: string): Promise<null | GeoInfoInterface> => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API}/geo-info-change?savedIp=${savedIp}`);
            if (!response.data) return null;
            return {
                ip: response.data.ip,
                countryCode: response.data.country_code || DEFAULT_COUNTRY_CODE,
                country: response.data.country || DEFAULT_COUNTRY,
                city: response.data.city,
            };
        } catch (error: any) {
            sentryLogApiError(error, 'getGeoInfoChange', {
                query: { savedIp },
            });
            return null;
        }
    },

    signInWithPassword: async (
        email: string,
        statsFromHost: any,
        signInType: string,
    ): Promise<UserInterface | number | null> => {
        try {
            const url = `${process.env.REACT_APP_API}/users/sign-in-pw?email=${email}`;
            // axiosRetry(axiosWithLog, {
            //     retries: AxiosRetryConfig.NO_OF_RETRIES,
            // });
            const response = await axiosWithLog.patch(url, statsFromHost);
            return response.data as UserInterface;
        } catch (error: any) {
            if (error.response) {
                const responseStatus = error.response.status;
                if ([404, 409, 401].includes(responseStatus)) return error.response.status;
            }
            // sentryLogApiError(error, `signInWithPassword: ${signInType}`, {
            //     query: { email, signInType },
            //     body: statsFromHost,
            // });
            return null;
        }
    },

    sendVerificationCode: async (email: string): Promise<boolean> => {
        try {
            const url = `${process.env.REACT_APP_API}/users/verification/send-code?email=${email}`;
            await axios.post(url);
            return true;
        } catch (error: any) {
            sentryLogApiError(error, 'sendVerificationCode', {
                query: { email },
            });
            return false;
        }
    },

    sendVerificationEmail: async (email: string): Promise<boolean> => {
        try {
            const url = `${process.env.REACT_APP_API}/users/verification/send-email?email=${email}`;
            const response: any = await axiosWithLog.post(url);
            webviewMessenger.sendUsageLog(`Email verification sent, took ${response.duration} ms`);
            return true;
        } catch (error: any) {
            sentryLogApiError(error, 'sendVerificationEmail', {
                query: { email },
            });
            return false;
        }
    },

    verifyVerificationCode: async (email: string, code: string): Promise<number> => {
        try {
            const url = `${process.env.REACT_APP_API}/users/verification/verify-code?email=${email}&code=${code}`;
            await axios.post(url);
            return 0;
        } catch (error: any) {
            if (error.response) {
                const responseStatus = error.response.status;
                if ([400, 404].includes(responseStatus)) return error.response.status;
            }
            sentryLogApiError(error, 'verifyVerificationCode', {
                query: { email, code },
            });
            return -1;
        }
    },

    resetPassword: async (body: ResetPasswordInterface): Promise<boolean> => {
        try {
            const url = `${process.env.REACT_APP_API}/legacy/update-password-db`;
            await axios.post(url, body);
            return true;
        } catch (error: any) {
            sentryLogApiError(error, 'resetPassword', {
                query: { body },
            });
            return false;
        }
    },

    signUpCheckEmail: async (email: string): Promise<boolean | null> => {
        try {
            const url = `${process.env.REACT_APP_API}/users/signup-check?email=${email}`;
            await axios.get(url);
            return true;
        } catch (error: any) {
            if (error.response && error.response.status === 409) return false;
            sentryLogApiError(error, 'signUpCheckEmail', {
                query: { email },
            });
            return null;
        }
    },

    createUser: async (body: SignUpUserInterface | {}): Promise<string> => {
        body = { ...body, signupOs: 'add-in', signupDevice: 'add-in' };
        const location = getSavedGeoInfo();
        if (location) {
            body = { ...body, location };
        }
        try {
            const url = `${process.env.REACT_APP_API}/users`;
            const response = await axios.post(url, body);
            const newUser = response.data;
            return newUser._id;
        } catch (error: any) {
            logger.error('createUser() error: ', error.response || error);
            sentryLogApiError(error, 'createUser', { body });
            const reportSlackUrl = `${process.env.REACT_APP_API}/users/slack?subject=Signup_Failed`;
            await axios.post(reportSlackUrl, { body, error: error?.response?.data || error });
            return '';
        }
    },

    getUser: async (email: string): Promise<UserInterface | null> => {
        try {
            const url = process.env.REACT_APP_API + '/users/dto/presenter-app?email=' + email;
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            logger.error('getUser() error: ', error.response || error);
            return null;
        }
    },

    getUserMetrics: async (userId: string): Promise<UserMetricsInterface | null> => {
        try {
            const url = process.env.REACT_APP_APITWO + '/user-metrics?userId=' + userId;
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            logger.error('getUserMetrics() error: ', error.response || error);
            sentryLogApiError(error, 'getUserMetrics', {
                query: { userId },
            });
            return null;
        }
    },

    uploadToStorage: async (
        file: any,
        fileName: string,
        apiServer: string,
        isTemp: boolean,
        containerName: string,
    ): Promise<string> => {
        try {
            const urlFromAzureStorage = await uploadToAzureStorage(file, fileName, apiServer, isTemp, containerName);
            if (urlFromAzureStorage) return urlFromAzureStorage;

            const form = new FormData();
            form.append('blobFile', file, fileName);
            const storageType = isTemp ? 'cpblob' : 'cpfile';
            const url = `${process.env.REACT_APP_FILEAPP}/add/${storageType}/${containerName}`;
            const response = await axios.post(url, form, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data.fileUrl;
        } catch (error: any) {
            logger.error('uploadToStorage() error: ', error.response || error);
            sentryLogApiError(error, 'uploadToStorage');
            return '';
        }
    },

    uploadUrlToStorage: async (fileUrl: string): Promise<string> => {
        const baseUrl = `${process.env.REACT_APP_FILEAPP}/add-by-url/cpfile/user`;
        try {
            const response = await axios.post(baseUrl, {
                sourceUrl: fileUrl,
            });
            return response.data.fileUrl;
        } catch (error: any) {
            logger.error('uploadUrlToStorage() error: ', error.response || error);
            sentryLogApiError(error, 'uploadUrlToStorage');
            return fileUrl;
        }
    },

    uploadSlide: async (file: any, fileName: string, apiServer: string): Promise<string> => {
        if (cancelToken !== undefined) {
            cancelToken.cancel('Operation canceled due to new request.');
        }

        cancelToken = axios.CancelToken.source();

        const form = new FormData();
        form.append('blobFile', file, fileName);

        try {
            const urlFromAzureStorage = await uploadToAzureStorage(file, fileName, apiServer, true, 'slides');
            if (urlFromAzureStorage) return urlFromAzureStorage;

            const url = `${process.env.REACT_APP_FILEAPP}/add/cpblob/slides`;
            const response = await axios.post(url, form, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                cancelToken: cancelToken.token,
            });
            return response.data.fileUrl;
        } catch (error: any) {
            logger.error('uploadSlide() error: ', error.response || error);
            sentryLogApiError(error, 'uploadSlide');
            return '';
        }
    },

    deleteFromStorage: async (imageUrl: string) => {
        try {
            const url = `${process.env.REACT_APP_FILEAPP}/delete?url=${imageUrl}`;
            const response = await axios.delete(url);
            return response.data;
        } catch (error: any) {
            logger.error('deleteFromStorage() error: ', error.response || error);
            sentryLogApiError(error, 'deleteFromStorage', {
                query: { imageUrl },
            });
            return null;
        }
    },

    updateUserProfile: async (email: string, userProfileInput: any): Promise<UserInterface | null> => {
        try {
            const url = process.env.REACT_APP_API + '/users/update-profile?email=' + email;
            const response: any = await axiosWithLog.patch(url, userProfileInput);
            webviewMessenger.sendUsageLog(`Edited user profile, took ${response.duration} ms`);
            return response.data;
        } catch (error: any) {
            logger.error('updateUserProfile() error: ', error.response || error);
            sentryLogApiError(error, 'updateUserProfile', {
                query: { email },
                body: userProfileInput,
            });
            return null;
        }
    },

    verifyKey: async (email: string, productKey: string): Promise<VerifyKeyOutDto | null> => {
        try {
            const url = `${process.env.REACT_APP_API}/subscriptions/keys/verify`;
            const response = await axios.get(url, {
                params: { email, key: productKey },
            });
            return response.data;
        } catch (error: any) {
            if (error.response) return { errorMessage: error.response.data.message };
            else {
                logger.error('verifyKey() error: ', error.response || error);
                return null;
            }
        }
    },

    applyKey: async (email: string, key: string): Promise<UpdatedUserWithDurationInterface | null> => {
        try {
            const url = process.env.REACT_APP_API + '/subscriptions/keys/apply';
            const response: any = await axios.post(url, {
                email,
                key,
            });
            return { updatedUser: response.data, duration: response.duration };
        } catch (error: any) {
            logger.error('applyKey() error: ', error.response || error);
            sentryLogApiError(error, 'applyKey', {
                body: { email, key },
            });
            return null;
        }
    },

    removeKey: async (email: string): Promise<UpdatedUserWithDurationInterface | null> => {
        try {
            const url = process.env.REACT_APP_API + '/subscriptions/keys/remove?email=' + email;
            const response: any = await axios.patch(url);

            return { updatedUser: response.data, duration: response.duration };
        } catch (error: any) {
            logger.error('removeKey() error: ', error.response || error);
            sentryLogApiError(error, 'removeKey', {
                query: { email },
            });
            return null;
        }
    },

    scheduleChangePlanZoho: async (subscriptionId: string, planCode: string, email: string): Promise<boolean> => {
        try {
            const url = process.env.REACT_APP_API + '/zoho/subscription/schedule-change-plan';
            await axios.post(url, null, {
                params: {
                    planCode,
                    subscriptionId,
                    email,
                },
            });
            return true;
        } catch (error: any) {
            logger.error('scheduleChangePlanZoho() error: ', error.response || error);
            sentryLogApiError(error, 'scheduleChangePlanZoho', {
                query: { planCode, subscriptionId },
            });
            return false;
        }
    },

    scheduleCancellationZoho: async (subscriptionId: string, reason: string): Promise<boolean> => {
        try {
            const url = process.env.REACT_APP_API + '/zoho/subscription/schedule-cancel';
            await axios.post(url, null, {
                params: {
                    subscriptionId,
                    reason,
                },
            });
            return true;
        } catch (error: any) {
            logger.error('scheduleCancellationZoho() error: ', error.response || error);
            sentryLogApiError(error, 'scheduleCancellationZoho', {
                query: { subscriptionId, reason },
            });
            return false;
        }
    },

    revokeCancellationZoho: async (subscriptionId: string): Promise<boolean> => {
        try {
            const url = process.env.REACT_APP_API + '/zoho/subscription/revoke-cancel';
            await axios.post(url, null, {
                params: {
                    subscriptionId,
                },
            });
            return true;
        } catch (error: any) {
            logger.error('revokeCancellationZoho() error: ', error.response || error);
            sentryLogApiError(error, 'revokeCancellationZoho', {
                query: { subscriptionId },
            });
            return false;
        }
    },

    updateUserSubscription: async (email: string, payload: any): Promise<UserInterface | null> => {
        try {
            const url = process.env.REACT_APP_API + '/subscriptions/recurring/update?email=' + email;
            const response = await axios.put(url, payload);
            return response.data;
        } catch (error: any) {
            logger.error('updateUserSubscription() error: ', error.response || error);
            sentryLogApiError(error, 'updateUserSubscription', {
                query: { email },
                body: payload,
            });
            return null;
        }
    },

    activatePackage: async (email: string, packageId: string): Promise<UserInterface | { error: any } | null> => {
        try {
            const url = process.env.REACT_APP_API + '/subscriptions/packages/activate';
            const response = await axios.post(url, {
                email,
                packageId,
            });
            return response.data;
        } catch (error: any) {
            logger.error('activatePackage() error: ', error.response || error);
            if (error.response) {
                if (error.response.status < 500) return { error: error.response.data };
            }
            sentryLogApiError(error, 'activatePackage', {
                body: { email, packageId },
            });
            return null;
        }
    },

    sendHelpDesk: async (
        email: string,
        displayName: string,
        message: string,
        imageUrls: string[],
        subject: string,
    ): Promise<boolean> => {
        try {
            const url = `${process.env.REACT_APP_API}/users/helpdesk?email=${email}`;
            await axios.post(url, {
                displayName,
                message,
                imageUrls,
                subject,
            });
            return true;
        } catch (error: any) {
            logger.error('sendHelpDesk() error: ', error.response || error);
            sentryLogApiError(error, 'sendHelpDesk', {
                query: { email },
                body: { displayName, message, imageUrls },
            });
            return false;
        }
    },

    pushPdf: async (userId: string, pdfUrl: string): Promise<void> => {
        try {
            const url = `${process.env.REACT_APP_APITWO}/classSessions/pdf-exported?userId=${userId}&pdfUrl=${pdfUrl}`;
            await axios.post(url);
        } catch (error: any) {
            logger.error('pushPdf() error: ', error.response || error);
            sentryLogApiError(error, 'pushPdf', {
                query: { userId, pdfUrl },
            });
        }
    },

    checkTrialEligibility: async (email: string): Promise<number> => {
        try {
            const url = `${process.env.REACT_APP_API}/subscriptions/trial/check?email=${email}`;
            await axios.get(url);
            return 0;
        } catch (error: any) {
            if (error.response && error.response.status === 409) return 409; // deviceId used before
            else {
                logger.error('checkTrialEligibility() error: ', error.response || error);
                sentryLogApiError(error, 'checkTrialEligibility', {
                    query: { email },
                });
                return -1;
            }
        }
    },

    startTrial: async (email: string): Promise<UserInterface | null> => {
        // return null;
        try {
            const url = `${process.env.REACT_APP_API}/subscriptions/trial/start?email=${email}`;
            const response: any = await axiosWithLog.post(url);
            webviewMessenger.sendUsageLog(`[C] Trial activated, took ${response.duration} ms`);
            return response.data;
        } catch (error: any) {
            logger.error('startTrial() error: ', error.response || error);
            sentryLogApiError(error, 'startTrial', {
                query: { email },
            });
            return null;
        }
    },

    reportToSlack: async (email: string, subject: string, payload: any) => {
        try {
            const url = `${process.env.REACT_APP_API}/users/slack?subject=${subject}`;
            await axios.post(url, payload);
        } catch (error: any) {
            logger.error('reportToSlack() error: ', error.response || error);
            sentryLogApiError(error, 'reportToSlack', {
                query: { email },
                body: payload,
            });
        }
    },

    addWatchedTutorial: async (email: string, tutorialId: string): Promise<UserInterface | null> => {
        try {
            const url = `${process.env.REACT_APP_API}/users/tutorials?email=${email}&tutorialId=${tutorialId}`;
            const response = await axios.patch(url);
            return response.data;
        } catch (error: any) {
            logger.error('addWatchedTutorial() error: ', error.response || error);
            sentryLogApiError(error, 'addWatchedTutorial', {
                query: { email, tutorialId },
            });
            return null;
        }
    },

    getReferralCode: async (userId: string): Promise<string> => {
        try {
            const url = `${process.env.REACT_APP_API}/users/referral-links?userId=${userId}`;
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            logger.error('getReferralCode() error: ', error.response || error);
            sentryLogApiError(error, 'getReferralCode', {
                query: { userId },
            });
            return '';
        }
    },

    updateUserClassSession: async (email: string, dto: UserClassSessionInterface): Promise<UserInterface | null> => {
        try {
            const url = `${process.env.REACT_APP_API}/users/class-session?email=${email}`;
            const response = await axios.patch(url, dto);
            return response.data;
        } catch (error: any) {
            logger.error('updateUserClassSession() error: ', error.response || error);
            sentryLogApiError(error, 'updateUserClassSession', {
                query: { email, dto },
            });
            return null;
        }
    },

    addFirstUsageLog: async (email: string) => {
        if (!email) return;
        try {
            const url = `${process.env.REACT_APP_API}/users/stats/first-usage-log?email=${email}`;
            await axios.post(url);
        } catch (error: any) {
            logger.error('addFirstUsageLog() error: ', error.response || error);
            sentryLogApiError(error, 'addFirstUsageLog', {
                query: { email },
            });
        }
    },

    addUserOnBoarding: async (email: string, event: string): Promise<UserInterface | null> => {
        if (!email || !event) return null;
        try {
            const url = `${process.env.REACT_APP_API}/users/onboarding?email=${email}&event=${event}`;
            const response = await axios.post(url);
            return response.data;
        } catch (error: any) {
            logger.error('addUserOnBoarding() error: ', error.response || error);
            sentryLogApiError(error, 'addUserOnBoarding', {
                query: { email, event },
            });
            return null;
        }
    },

    recordGoproTrigger: async (email: string, trigger: string) => {
        if (!email || !trigger) return;
        try {
            const url = `${process.env.REACT_APP_API}/users/stats/record-gopro-trigger?email=${email}&trigger=${trigger}`;
            await axios.post(url);
        } catch (error: any) {
            logger.error('recordGoproTrigger() error: ', error.response || error);
            sentryLogApiError(error, 'recordGoproTrigger', {
                query: { email, trigger },
            });
        }
    },

    signInWithSso: async (ssoData: SsoSignUpInterface, statsFromHost: any): Promise<UserInterface | number | null> => {
        try {
            const url = `${process.env.REACT_APP_API}/users/sso-login?email=${ssoData.email}&provider=${ssoData.provider}`;
            const response = await axios.patch(url, statsFromHost, {
                headers: {
                    Authorization: `Bearer ${ssoData.accessToken}`,
                },
            });
            return response.data.user as UserInterface;
        } catch (error: any) {
            if (error.response) {
                const responseStatus = error.response.status;
                if (responseStatus === 404) return error.response.status;
            }
            sentryLogApiError(error, `signInWithSso: ${ssoData.provider}`, {
                query: { email: ssoData.email, provider: ssoData.provider },
                body: { ssoData, statsFromHost },
            });
            return null;
        }
    },

    getFaceBookProfileInfo: async (accessToken: string): Promise<any> => {
        try {
            const jsonHeader = { call_count: 28, total_time: 15, total_cputime: 24 };
            const url = `https://graph.facebook.com/me?fields=email,name,first_name,last_name,picture.type(large).width(300).height(300)&access_token=${accessToken}`;
            const response = await axios.get(url, {
                headers: {
                    'X-App-Usage': JSON.stringify(jsonHeader),
                },
            });
            return response.data;
        } catch (error: any) {
            logger.error('getFaceBookProfileInfo() error: ', error.response || error);
            return null;
        }
    },

    getGoogleProfileInfo: async (accessToken: string): Promise<any> => {
        try {
            const url = `https://www.googleapis.com/oauth2/v3/userinfo?alt=json`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            return response.data;
        } catch (error: any) {
            logger.error('getGoogleProfileInfo() error: ', error.response || error);
            return null;
        }
    },

    trackAddingFirstActivityButton: async (email: string) => {
        if (!email) return;
        try {
            const url = `${process.env.REACT_APP_API}/users/stats/track/add-first-activity-button?email=${email}`;
            await axios.post(url);
        } catch (error: any) {
            logger.error('trackAddingFirstActivityButton() error: ', error.response || error);
            sentryLogApiError(error, 'trackAddingFirstActivityButton', {
                query: { email },
            });
        }
    },

    signOut: async (email: string): Promise<void> => {
        if (!email) return;
        try {
            const url = `${process.env.REACT_APP_API}/users/sign-out?email=${email}`;
            await axiosWithLog.post(url);
        } catch (error) {}
    },

    trackFirstOpenQuizIdeas: async (email: string) => {
        if (!email) return;
        try {
            const url = `${process.env.REACT_APP_API}/users/stats/track/first-open-quiz-ideas?email=${email}`;
            await axios.post(url);
        } catch (error: any) {
            logger.error('trackFirstOpenQuizIdeas() error: ', error.response || error);
            sentryLogApiError(error, 'trackFirstOpenQuizIdeas', {
                query: { email },
            });
        }
    },
};

export default apiUser;
