import { Fragment, useEffect, useRef, useState, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FitToViewport } from 'react-fit-to-viewport';
import { useDispatch } from 'react-redux';
import * as XLSX from 'xlsx';

import { Box, Link, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { userActions } from '../../../../actions/user.actions';
import apiSavedClasses from '../../../../services/apiSavedClasses';
import { localService } from '../../../../services/localStorageService';
import webviewMessenger from '../../../../services/webviewMessenger';
import { calculateParticipantLevelAndBadge, findUserClassLimit, getLevelsArray } from '../../../../helpers/userhelper';
import { getValidUserFromStore } from '../../../../helpers/storeHelpers';
import SavedClassForUserInterface from '../../../../interfaces/saved-class-for-user.interface';
import SavedClassParticipantInterface from '../../../../interfaces/saved-class-participant.interface';
import SavedClassAddedParticipantInterface from '../../../../interfaces/saved-class-added-participant.interface';
import { ViewportSavedClasses } from '../../../../constants/viewport-constants';

import CommonButton from '../../../../components/Common/CommonButton';
import AddParticipantInput from '../AddParticipantInput';
import { findNonEmptyAddedParticipants } from '../../savedClassHelpers';

interface SavedClassAddParticipantModalInterface {
    onCloseAlert: () => void;
    savedClass: SavedClassForUserInterface;
    setSavedClass: (arg: SavedClassForUserInterface) => void;
    sortedParticipants: SavedClassParticipantInterface[];
    onParticipantsUpdated: (arg: SavedClassParticipantInterface[]) => void;
}

const SavedClassAddParticipantModal = (
    {
        onCloseAlert,
        savedClass,
        setSavedClass,
        sortedParticipants,
        onParticipantsUpdated,
    }: SavedClassAddParticipantModalInterface,
    ref: any,
) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = getValidUserFromStore('SavedClassAddParticipantModal');
    const userClassLimit = findUserClassLimit(user);
    const addParticipantsLimit = userClassLimit - sortedParticipants.length;
    const [error, setError] = useState('');
    const [isAdding, setIsAdding] = useState(false);
    const [initialRows, setInitialRows] = useState(Math.min(addParticipantsLimit, 5));
    const [addedRows, setAddedRows] = useState(0);
    const [csvData, setCsvData] = useState<SavedClassAddedParticipantInterface[]>([]);
    const inputFile = useRef<any>(null);
    const [file, setFile] = useState<any>();
    let newDataList: any[] = [];
    let validationSuccess = false;
    const [resetData, setResetData] = useState(false);

    const syncAddedParticipants = () => {
        const addedParticipants = localService.getTempAddedParticipants();
        if (!addedParticipants) return;
        const nonEmptyParticipants = findNonEmptyAddedParticipants(addedParticipants).length;
        const errorsCount = addedParticipants.map((p) => p.error).filter((error) => !!error).length;
        if (errorsCount === 0 && nonEmptyParticipants > 0) setError('');
    };

    const focusInput = () => {
        document.getElementById('add-new')?.focus();
    };

    const submitAddParticipants = async () => {
        if (error) return;
        const addedParticipants = localService.getTempAddedParticipants();

        if (!addedParticipants) {
            setError(t('lang_saved_classes.txt_participant_error'));
            return;
        } else {
            const nonEmptyParticipants = findNonEmptyAddedParticipants(addedParticipants).length;
            if (nonEmptyParticipants === 0) {
                setError(t('lang_saved_classes.txt_participant_error'));
                return;
            } else {
                const errorsCount = addedParticipants.map((p) => p.error).filter((error) => !!error).length;
                if (errorsCount > 0) {
                    setError(t('lang_saved_classes.err_red_massage'));
                    return;
                }
            }
        }

        const nonEmptyParticipants = findNonEmptyAddedParticipants(addedParticipants || []);
        setIsAdding(true);

        const editSavedClassReply = await apiSavedClasses.addNewParticipants(
            savedClass.savedClassId,
            nonEmptyParticipants.map((p) => {
                return {
                    participantUsername: p.name.trim(),
                    participantName: p.name.trim(),
                    participantTotalPoints: p.points || 0,
                };
            }),
        );

        setIsAdding(false);
        onCloseAlert();

        if (!editSavedClassReply) return dispatch(userActions.showApiError());

        const participantsWithLevel = editSavedClassReply.participants.map((p) => ({
            ...p,
            participantLevelAndBadge: calculateParticipantLevelAndBadge(p.participantTotalPoints, getLevelsArray(user)),
        }));

        onParticipantsUpdated(participantsWithLevel);

        setSavedClass({ ...savedClass, participantsCount: editSavedClassReply.participants.length });
    };

    const addRowOnImportCsv = (csvDataLength: number) => {
        setInitialRows(csvDataLength);
        if (addParticipantsLimit <= 5) {
            setAddedRows(addParticipantsLimit - csvDataLength);
            return;
        }
        const numberToNextFive = 5 - (csvDataLength % 5);
        const remainingLimit = addParticipantsLimit - csvDataLength;
        setAddedRows(Math.min(numberToNextFive, remainingLimit));
    };

    const checkAndAddRows = (index: number) => {
        const defaultRowsToAdd = 5;
        if (index < addParticipantsLimit - 1 && index >= initialRows + addedRows - 4) {
            const rowsToAdd = Math.min(addParticipantsLimit - (initialRows + addedRows), defaultRowsToAdd);
            setAddedRows(addedRows + rowsToAdd);
        }
    };

    const parseCsv = () => {
        const reader = new FileReader();
        reader.onload = function (e: any) {
            let fileData = XLSX.read(e.target.result, {
                type: 'array',
            });
            setError('');
            const dataArray = fileData.Sheets.Sheet1;
            if (!dataArray) {
                return setError(t('lang_saved_classes.txt_error_invalid_structure'));
            }
            const lastNameCount: any =
                Object.keys(dataArray)
                    .filter((value) => /^A/.test(value))
                    ?.pop()
                    ?.replace(/[^0-9]/g, '') || 0;

            let csvFileData: any[] = [];

            for (let n = 1; n <= lastNameCount; n++) {
                // if (dataArray[`A1`]?.v !== 'Name' || dataArray[`B1`]?.v !== 'Stars') {
                //     return setError(t('lang_saved_classes.txt_error_invalid_structure'));
                // }
                // if (dataArray[`A${n}`]?.v?.trim() === 'Name' && dataArray[`B${n}`]?.v?.trim() === 'Stars') {
                //     continue;
                // }
                if (typeof dataArray[`B${n}`]?.v !== 'undefined') {
                    if (typeof dataArray[`B${n}`]?.v !== 'number') {
                        return setError(t('lang_saved_classes.err_points_should_be_number'));
                    }
                    if (dataArray[`B${n}`]?.v < 0) {
                        return setError(t('lang_saved_classes.txt_error_points_can_not_be_negative'));
                    }
                }

                if (typeof dataArray[`A${n}`]?.v === 'undefined' && typeof dataArray[`B${n}`]?.v === 'number') {
                    return setError(t('lang_saved_classes.txt_error_some_names_are_missing'));
                } else if (typeof dataArray[`A${n}`]?.v !== 'undefined') {
                    if (typeof dataArray[`B${n}`]?.v === 'number') {
                        csvFileData.push({
                            name: dataArray[`A${n}`]?.v.toString(),
                            points: dataArray[`B${n}`]?.v,
                        });
                    } else if (typeof dataArray[`B${n}`]?.v === 'undefined') {
                        csvFileData.push({
                            name: dataArray[`A${n}`]?.v.toString(),
                            points: 0,
                        });
                    }
                }
            }

            if (csvFileData.length > addParticipantsLimit) {
                return setError(
                    t('lang_saved_classes.txt_error_max_participant_allowed', {
                        classLimit: addParticipantsLimit,
                    }),
                );
            }

            csvFileData !== undefined &&
                csvFileData.forEach((data: { name: string; points: number }, index: number) => {
                    return newDataList.push({
                        index: index,
                        name: data.name,
                        points: data.points,
                        error: '',
                    });
                });
            for (let n = 0; n < newDataList.length; n++) {
                if (typeof newDataList[n].name === 'string' && typeof newDataList[n].points === 'number') {
                    validationSuccess = true;
                } else {
                    validationSuccess = false;
                }
            }

            handleImport();
        };
        reader.readAsArrayBuffer(file);
    };

    const handleImport = () => {
        if (validationSuccess) {
            localService.setTempAddedParticipants([]);
            setResetData(true);
            setCsvData(newDataList);
            addRowOnImportCsv(newDataList.length);
        }
    };

    const chooseFile = () => {
        inputFile.current.click();
    };

    const handleInputChange = (count: number, value: any) => {
        let tempDataSet: any[] = [];
        csvData.forEach((data: any, index: number) => {
            if (index === count) {
                tempDataSet.push({
                    index: data.index,
                    name: typeof value === 'string' ? value : data.name,
                    points: typeof value === 'number' ? value : data.points,
                    error: data.error,
                });
            } else {
                tempDataSet.push({
                    index: data.index,
                    name: data.name,
                    points: data.points,
                    error: data.error,
                });
            }
        });
        setCsvData(tempDataSet);
    };

    const handleFileInput = (fileInfo: any) => {
        let fileExtension = fileInfo.name.slice(fileInfo.name.length - 4, fileInfo.name.length).toLowerCase();
        fileExtension === 'xlsx' || fileExtension === '.csv' || fileExtension === '.xls'
            ? setFile(fileInfo)
            : setError(t('lang_saved_classes.txt_error_only_csv_allowed'));
    };

    const deselectFile = () => {
        setError('');
        setCsvData([]);
    };

    const closeAddParticipantModal = () => {
        onCloseAlert();
        deselectFile();
    };

    const handleDownloadCsvTemplate = () => {
        let csvContent = '';
        for (let index = 0; index < 5; index++) {
            csvContent += `${t('lang_saved_classes.csv_template_name')} ${index + 1}\n`;
        }
        const fileName = `Import-participants-csv-template.csv`;
        webviewMessenger.sendCsv(fileName, csvContent);
    };

    useEffect(() => {
        setTimeout(() => {
            focusInput();
        }, 1);
    }, []);

    useEffect(() => {
        const timer = setInterval(async () => {
            syncAddedParticipants();
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (file !== undefined) parseCsv();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file]);

    return (
        <Fragment>
            <FitToViewport
                className="modal_viewport"
                width={ViewportSavedClasses.width}
                height={ViewportSavedClasses.height}
                minZoom={ViewportSavedClasses.minZoom}
                maxZoom={ViewportSavedClasses.maxZoom}
            >
                <Box className="csvmodal_box addparticipent_modal_box">
                    <div className="modal_head close" onClick={closeAddParticipantModal}>
                        <CloseIcon />
                    </div>
                    <div className="addparticipent_modal_sec">
                        <div className="addparticipent_head">
                            <Typography variant="body1">
                                {t('lang_saved_classes.txt_participant_names')} (
                                {t('lang_saved_classes.txt_number_of_participants_remaining_to_add', {
                                    addParticipantsLimit,
                                })}
                                )
                            </Typography>
                            <div className="import_download_csv">
                                <p>
                                    <input
                                        type="file"
                                        className="react-csv-input"
                                        accept=".csv, .xls, .xlsx"
                                        ref={inputFile}
                                        style={{ display: 'none' }}
                                        onChange={(e) => handleFileInput(e.target.files && e.target.files[0])}
                                    />
                                    <Link href="#" onClick={chooseFile}>
                                        {t('lang_saved_classes.import_csv')}
                                    </Link>{' '}
                                    ({t('lang_saved_classes.txt_download')}{' '}
                                    <Link href="#" className="fw400" onClick={handleDownloadCsvTemplate}>
                                        {t('lang_saved_classes.txt_template')}
                                    </Link>
                                    )
                                </p>
                            </div>
                        </div>
                        <div className="participent_table">
                            {csvData.length > 0
                                ? csvData.map((item, index: number) => {
                                      return (
                                          <AddParticipantInput
                                              key={index}
                                              index={index}
                                              name={item.name}
                                              points={item.points}
                                              handleInputChange={handleInputChange}
                                              onCheckAndAddRows={checkAndAddRows}
                                              resetData={resetData}
                                              setResetData={setResetData}
                                              dataLength={csvData.length - 1}
                                              sortedParticipants={sortedParticipants}
                                          />
                                      );
                                  })
                                : [...Array(initialRows)].map((item, index) => (
                                      <AddParticipantInput
                                          key={index}
                                          index={index}
                                          onCheckAndAddRows={checkAndAddRows}
                                          resetData={resetData}
                                          setResetData={setResetData}
                                          sortedParticipants={sortedParticipants}
                                      />
                                  ))}
                            {[...Array(addedRows)].map((item, index) => {
                                return (
                                    <AddParticipantInput
                                        key={initialRows + index}
                                        index={initialRows + index}
                                        onCheckAndAddRows={checkAndAddRows}
                                        resetData={resetData}
                                        setResetData={setResetData}
                                        sortedParticipants={sortedParticipants}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className="button_sec justify_space_between">
                        <div className="participent_table_error">
                            <Typography>{error}</Typography>
                        </div>
                        {isAdding ? (
                            <CommonButton
                                isLoading={true}
                                variant="contained"
                                type="button"
                                text={t('lang_common.btn_adding')}
                            />
                        ) : (
                            <CommonButton
                                isLoading={false}
                                variant="contained"
                                type="button"
                                text={t('lang_common.btn_finish')}
                                clickFunction={() => submitAddParticipants()}
                            />
                        )}
                    </div>
                </Box>
            </FitToViewport>
        </Fragment>
    );
};

export default forwardRef(SavedClassAddParticipantModal);
