import _ from 'lodash';
import LeaderboardParticipantInterface from '../interfaces/leaderboard-participant.interface';
import ParticipantInterface from '../interfaces/participant.interface';

export const generateLeaders = (
    participantList: ParticipantInterface[],
    orderBy: string,
): LeaderboardParticipantInterface[] => {
    const tempLeadersList: LeaderboardParticipantInterface[] = participantList.map((p: ParticipantInterface) => {
        return {
            participantId: p.participantId,
            participantName: p.participantName,
            participantAvatar: p.participantAvatar,
            participantPoints: p.participantPoints,
            participantSumPoints: p.participantPoints + p.participantTotalPoints,
            participantRank: 0,
            groupId: p.groupId,
        };
    });

    const sortedLeadersList: LeaderboardParticipantInterface[] = _.orderBy(
        tempLeadersList,
        [orderBy, 'participantName'],
        ['desc', 'asc'],
    );

    let uniquePointsArray = _.uniq(sortedLeadersList.map((p) => p[orderBy]));
    // To make sure if all participants have 0 point, participantRank stays at 0
    sortedLeadersList.forEach(
        (p) =>
            (p.participantRank = Math.max(...uniquePointsArray) <= 0 ? 0 : uniquePointsArray.indexOf(p[orderBy]) + 1),
    );
    return sortedLeadersList;
};

export const percentCalculator = (topScore: number, currentScore: number) => {
    return (currentScore * 100) / topScore;
};

export const findTrophyCssClass = (participantRank: number): string => {
    switch (participantRank) {
        case 1:
            return 'first';
        case 2:
            return 'second';
        case 3:
            return 'third';
        default:
            return '';
    }
};
