import { useEffect } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { userActions } from './actions/user.actions';
import { classSessionActions } from './actions/class-session.action';
import { localService } from './services/localStorageService';
import { webviewSettingsActions } from './actions/webview-settings.actions';
import { UserActionTypes } from './constants/user-action-types';
import { WebviewSettingsActionTypes } from './constants/webview-settings-action-types';
import { activityActions } from './actions/activity.action';
import webviewMessenger from './services/webviewMessenger';
import { utilConstants } from './constants/utils.constants';
import { exportPdfActions } from './actions/export-pdf.action';
import { timerStopWatchActions } from './actions/timer-stopwatch.action';
import { getActivityFromStore } from './helpers/storeHelpers';
import apiActivities from './services/apiActivities';
import { browserTabsActions } from './actions/browser-tabs.actions';
import { BrowserTabsActionTypes } from './constants/browser-tabs-action-types';
import { HostBrowserCommands } from './constants/host-browser-commands';
import StoreInterface from './interfaces/store.interface';
import { logger } from './services/logger';
import apiPreferences from './services/apiPreferences';
import i18next from 'i18next';
import { isDotnetLangOn, isUserOnTrialOrPro } from './helpers/userhelper';
import { tutorialSettingsActions } from './actions/tutorial-settings.action';
import { aiQuizActions } from './actions/aiQuiz.action';
import { isCpVerAtLeast } from './helpers/utils';
import apiUser from './services/apiUser';
import apiTemplateLibrary from './services/apiTemplateLibrary';
import { HostEventTypes } from './constants/host-event-types';

const withHostEvent = (Component: any) => {
    return (props: any) => {
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const state = useSelector((state: StoreInterface) => state);
        const user = useSelector((state: StoreInterface) => state.user);
        const classSession = useSelector((state: StoreInterface) => state.classSession);
        const activity = getActivityFromStore();
        const { t } = useTranslation();

        useEffect(() => {
            if (window.chrome.webview) {
                window.chrome.webview.addEventListener('message', handleWebviewMessages);
                return () => {
                    window.chrome.webview.removeEventListener('message', handleWebviewMessages);
                };
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [state]);

        const handleBrowserMessage = (event: any) => {
            const message = event.data.message;
            //alert(message);
            //debugger;
            const args = event.data.args;
            logger.warn('handleBrowserMessage()', { message, args });
            switch (message) {
                case HostBrowserCommands.MG_CREATE_TAB:
                    dispatch(browserTabsActions.createNewTab(args));
                    break;
                case HostBrowserCommands.MG_UPDATE_URI:
                    const parser = document.createElement('a');
                    parser.href = args.uri;
                    const parts = args.uri.split('/');
                    // console.log('parts', parts);
                    const lastSegment = parts.pop() || parts.pop();
                    let data = { ...args };
                    if (parser.host === window.location.host && lastSegment === 'start') {
                        data = { ...data, uriToShow: null, uri: '' };
                    } else if (parser.host === window.location.host && lastSegment === 'bookmarks') {
                        data = { ...data, uri: data.uriToShow, title: t('lang_browser.heading_bookmarks') };
                    } else {
                        data = { ...data, uriToShow: null };
                    }
                    dispatch(browserTabsActions.updateBrowserTabs(BrowserTabsActionTypes.UPDATE_BROWSER_TAB, data));
                    break;
                case HostBrowserCommands.MG_NAV_STARTING:
                    dispatch(
                        browserTabsActions.updateBrowserTabs(BrowserTabsActionTypes.UPDATE_BROWSER_TAB, {
                            tabId: args.tabId,
                            isLoading: true,
                        }),
                    );
                    break;
                case HostBrowserCommands.MG_NAV_COMPLETED:
                    let favIconUri;
                    let title;
                    if (args.title === 'new_tab') {
                        favIconUri = '';
                        title = 'New Tab';
                    } else if (args.title === 'bookmarks') {
                        favIconUri = utilConstants.IMAGE_URLS.bookmark;
                        title = 'Bookmarks';
                    } else {
                        // favIconUri = args.favIconUri || 'https://www.google.com/favicon.ico';
                        favIconUri = args.favIconUri;
                        // title = args.title;
                        title = args.title ? args.title.replace(/\\n/g, '').replace(/\\t/g, '') : '';
                    }
                    dispatch(
                        browserTabsActions.updateBrowserTabs(BrowserTabsActionTypes.LOAD_TAB_COMPLETED, {
                            tabId: args.tabId,
                            title: title,
                            favicon: favIconUri,
                            isLoading: false,
                        }),
                    );
                    webviewMessenger.sendUsageLog(
                        `[S] Browser navigated to: ${
                            state.browserTabs.find((tab) => tab.tabId === args.tabId)?.uri || 'New tab or Bookmark page'
                        }`,
                    );
                    break;
                default:
                    console.log(`Received unexpected message: ${JSON.stringify(event.data)}`);
            }
        };

        const handleWebviewMessages = async (event: any) => {
            event.preventDefault();
            if (!event.data) {
                return false;
            }
            if (event.data.message) {
                handleBrowserMessage(event);
                return;
            }
            const wpfMsg = JSON.parse(event.data);
            const msgType = wpfMsg.type;
            const msgData = JSON.parse(wpfMsg.data);

            logger.warn('wpfMsg: ', msgType, msgData);
            switch (msgType) {
                case 'reloadReducer':
                    // add a delay so it will load the updated state from localStorage
                    setTimeout(() => {
                        dispatch({ type: msgData });
                    }, 200);
                    break;
                case 'syncDataFromHost':
                    localService.setStatsFromHost(msgData.stats);
                    localService.removeNewVersionDownloaded();
                    dispatch(
                        webviewSettingsActions.updateWebviewSettings(
                            WebviewSettingsActionTypes.HOST_FEED_PREFERENCES,
                            msgData.webviewSettings,
                        ),
                    );
                    if (isDotnetLangOn(user?.email || '')) {
                        i18next.changeLanguage(msgData.language);
                        localService.setLanguage(msgData.language);
                    }
                    msgData.user !== null && msgData.user.email && msgData.user.password
                        ? dispatch(userActions.autoSignInUserWithPassword(msgData.user.email, msgData.user.password))
                        : dispatch(userActions.unloadUser(UserActionTypes.HOST_AUTO_UNLOAD_USER, null));
                    break;
                case 'closeWebview':
                    const pathname = window.location.pathname;
                    logger.warn('pathname', pathname);
                    webviewMessenger.sendUsageLog(`${classSession ? '[S] ' : ''}Closed webview window: ${pathname}`);
                    if (pathname === '/activity') {
                        dispatch(activityActions.endActivity(false, false, true));
                    } else {
                        webviewMessenger.sendHideWebview();
                        // if there's an ongoing activity at the background, don't navigate away, just hide webview
                        if (activity.activityMode !== utilConstants.ACTIVITY_MODE.START_ACTIVITY) {
                            navigate('/loading');
                            if (pathname === '/exportpdf') {
                                dispatch(exportPdfActions.cancelExportPdf());
                                webviewMessenger.sendSimpleMessage('cancelExportPdf');
                            }
                        }
                        // stop timer if we are on timer page
                        if (pathname === '/timer') dispatch(timerStopWatchActions.resetTimer());
                        // reset aiQuiz if all generated questions are viewed
                        // if (pathname === '/aiQuiz') {
                        //     const aiQuizState = store.getState().aiQuiz;
                        //     const currentSlideId = localService.getSlideIdText().currentSlideId;
                        //     if (aiQuizState[currentSlideId]?.questionList.filter((q) => !q.viewed).length === 0) {
                        //         console.log('all generated questions are viewed, reset aiQuiz');
                        //         dispatch(aiQuizActions.resetAiQuizForCurrentSlide(currentSlideId));
                        //     }
                        // }
                    }
                    break;
                case 'clearBrowserTabsStorage':
                    //localService.removeBrowserTabs();
                    break;
                case 'signIn':
                    // before we do manual sign in, clear user
                    dispatch(userActions.unloadUser(UserActionTypes.HOST_AUTO_UNLOAD_USER, null));
                    localService.setStatsFromHost(msgData.stats);
                    dispatch(
                        webviewSettingsActions.updateWebviewSettings(
                            WebviewSettingsActionTypes.HOST_FEED_PREFERENCES,
                            msgData.webviewSettings,
                        ),
                    );
                    navigate('/signin');
                    break;
                case 'hello':
                    navigateAfterDelay(navigate, '/hello');
                    break;
                case 'upgrade':
                    webviewMessenger.sendOpenBrowser(`${process.env.REACT_APP_WEBAPP}/cp/account`);
                    break;
                case 'goPro':
                    if (msgData) {
                        navigateAfterDelay(navigate, '/gopro', { state: { startingSlideId: msgData } });
                    } else navigateAfterDelay(navigate, '/gopro');

                    break;
                case 'savedClasses':
                    navigateAfterDelay(navigate, '/savedClass');
                    break;
                case 'whiteboardSettings':
                    navigateAfterDelay(navigate, '/whiteboardSettings');
                    break;
                case 'settings':
                    navigateAfterDelay(navigate, '/settingsRedirect/slideshow');
                    break;
                case 'about':
                    navigateAfterDelay(navigate, `/settingsRedirect/about`);
                    break;
                case 'currentSlideAsWhiteboard':
                    localService.setTempWhiteboard(msgData);
                    break;
                case 'currentShapeAsMovableObject':
                    localService.setTempMovableObject(msgData);
                    break;
                case 'openExportPdf':
                    console.log('openExportPdf', msgData);
                    dispatch(exportPdfActions.openExportPdf(parseInt(msgData)));
                    navigateAfterDelay(navigate, '/exportpdf');
                    break;
                case 'selectedSlidesCount':
                    console.log('selectedSlidesCount', msgData);
                    dispatch(exportPdfActions.updateSelectedSlidesCount(parseInt(msgData)));
                    break;
                case 'pdfExported':
                    console.log('pdfExported', msgData);
                    dispatch(exportPdfActions.pdfExported(msgData));
                    break;
                case 'updateMcqCorrectPointsAndAnswers':
                    const messageId = Math.floor(Math.random() * 1000);
                    const payload = {
                        ...msgData,
                        email: user?.email,
                        messageId,
                    };
                    localStorage.setItem('updateMcqCorrectPointsAndAnswers', JSON.stringify(payload));
                    setTimeout(() => {
                        apiActivities.updateMcqCorrectPointsAndAnswers(messageId);
                    }, 100);
                    break;
                case 'updateFbCorrectAnswers':
                    const fbMessageId = Math.floor(Math.random() * 1000);
                    const fbPayload = {
                        ...msgData,
                        email: user?.email,
                        fbMessageId,
                    };
                    localStorage.setItem('updateFbCorrectAnswers', JSON.stringify(fbPayload));
                    setTimeout(() => {
                        apiActivities.updateFbCorrectAnswers(fbMessageId);
                    }, 100);
                    break;
                case 'savePlayOptions':
                    if (user) apiPreferences.savePlayOptions(user.email, msgData);
                    break;
                case 'deletePlayOptions':
                    if (user) apiPreferences.deletePlayOptions(user.email);
                    break;
                case 'sendQuizActivityIds':
                    localService.setQuizActivityIds(msgData);
                    break;
                case 'openQuizSummaryInEdit':
                    localService.setQuizActivityIds(msgData);
                    navigateAfterDelay(navigate, '/quizsummary', { state: { isFromRibbon: true } });
                    break;
                case 'startSlideshow':
                    if (isCpVerAtLeast('2.0.36')) {
                        dispatch(userActions.reloadUserReducer());
                        i18next.changeLanguage(localService.getLanguage());
                    }
                    dispatch(classSessionActions.startSlideshow(msgData, false));
                    break;
                case 'reconnectPresenter':
                    dispatch(classSessionActions.startSlideshow(msgData, true));
                    break;
                case 'gotoStep':
                    webviewMessenger.sendUsageLog(
                        `[S] Navigated to slide: ${msgData.currentSlideIndex}/${msgData.totalSlideCount}`,
                    );
                    classSessionActions.gotoStep(msgData);
                    break;
                case 'myClass':
                    if (window.location.pathname === '/activity') {
                        if (
                            activity.activityMode === utilConstants.ACTIVITY_MODE.START_ACTIVITY &&
                            activity.activityProps?.activityType === utilConstants.ACTIVITY_TYPE.QUICK_POLL
                        ) {
                            dispatch(activityActions.endActivity(false, true));
                        }
                    }
                    setTimeout(() => {
                        // Need to add this delay otherwise the running Quick Poll above will not be ended. Uncommenting the console log will also work
                        navigateAfterDelay(navigate, '/myclass');
                    }, 200);
                    break;
                case 'startActivity':
                    dispatch(activityActions.startActivity(msgData));
                    navigateAfterDelay(navigate, '/activity');
                    break;
                case 'viewActivityInSlideshow':
                    dispatch(activityActions.clearActivity());
                    dispatch(activityActions.viewActivityInSlideshow(msgData));
                    navigateAfterDelay(navigate, '/activity');
                    break;
                case 'viewActivityInEdit':
                    dispatch(activityActions.clearActivity());
                    dispatch(activityActions.viewActivityInEdit(msgData));
                    navigateAfterDelay(navigate, '/activity');
                    break;
                case 'gotoActivity':
                    navigate('/activity');
                    break;
                case 'postActivitySlideUrl':
                    apiActivities.saveActivitySlide(msgData.activityId, msgData.slideImage, user);
                    break;
                case 'whiteboard':
                    navigateAfterDelay(navigate, '/whiteboard');
                    break;
                case 'launchQuickPoll':
                    navigateAfterDelay(navigate, '/qpselect');
                    break;
                case 'pickNames':
                    navigateAfterDelay(navigate, '/picknames');
                    break;
                case 'leaderboard':
                    navigateAfterDelay(navigate, '/leaderboard');
                    break;
                case 'endSlideshow':
                    navigate('/loading');
                    localService.removeGotoStepDto();
                    localService.removeSlideIdText();
                    dispatch(classSessionActions.endSlideshow());
                    dispatch(aiQuizActions.resetAiQuiz());
                    break;
                case 'timer':
                    webviewMessenger.timerDefaultSize();
                    navigateAfterDelay(navigate, '/timer');
                    break;
                case 'collapseTimer':
                    dispatch(timerStopWatchActions.collapseTimerWindow());
                    break;
                case 'restoreTimer':
                    dispatch(timerStopWatchActions.restoreTimerWindow(msgData));
                    break;
                case 'maximizeTimerWindow':
                    dispatch(timerStopWatchActions.maximizeTimerWindow());
                    break;
                case 'tutorial':
                    if (msgData) {
                        dispatch(tutorialSettingsActions.updateActiveTutorialVideo(msgData));
                        navigateAfterDelay(navigate, '/tutorial', {
                            state: {
                                playInFullscreen: true,
                                isAutoPlay: true,
                            },
                        });
                    } else navigateAfterDelay(navigate, '/tutorial');
                    break;
                case 'contextualTutorial':
                    navigateAfterDelay(navigate, '/contextual-tutorial', { state: { type: msgData } });
                    break;
                case 'syncToolbarActions':
                    localService.syncToolbarActionsFromHost(msgData);
                    break;
                case 'newVersionDownloaded':
                    localService.setNewVersionDownloaded(msgData.newVersion);
                    break;
                case 'firstUsageLog':
                    apiUser.addFirstUsageLog(user?.email || '');
                    break;
                case 'refer':
                    navigate('/settingsRedirect/refer');
                    break;
                case '/':
                    navigate('/');
                    break;
                case 'updateSlideText':
                    localService.setSlideIdText({
                        currentSlideId: msgData.currentSlideId,
                        currentSlideText: msgData.currentSlideText,
                        currentSlideNote: msgData.currentSlideNote,
                    });
                    break;
                case 'openAiQuiz':
                    navigateAfterDelay(navigate, '/aiQuiz');
                    break;
                case 'addedFirstActivityButton':
                    apiUser.trackAddingFirstActivityButton(user?.email || '');
                    break;
                case 'openQna':
                    navigateAfterDelay(navigate, '/qna');
                    break;
                case 'openLibrary':
                    navigateAfterDelay(navigate, '/library');
                    break;
                case 'uploadSlideTemplate':
                    apiTemplateLibrary.uploadTemplate(msgData.pptUrl, msgData.imageUrls);
                    break;
                case 'openSubscription':
                    webviewMessenger.sendOpenBrowser(`${process.env.REACT_APP_WEBAPP}/cp/account`);
                    break;
                case 'openProfile':
                    webviewMessenger.sendOpenBrowser(`${process.env.REACT_APP_WEBAPP}/cp/account/profile`);
                    break;
                case 'openReport':
                    webviewMessenger.sendOpenBrowser(`${process.env.REACT_APP_WEBAPP}/cp/reports`);
                    break;
                case 'signOut':
                    if (!user) return;
                    dispatch(
                        userActions.unloadUser(UserActionTypes.USER_MANUALLY_SIGNED_OUT, HostEventTypes.SIGNED_OUT),
                    );
                    apiUser.signOut(user.email);
                    navigate('/loading');
                    break;
                default:
                    break;
            }
        };

        return <Component {...props} navigate={navigate} />;
    };
};

const navigateAfterDelay = (navigate: NavigateFunction, route: string, historyState?: any) => {
    setTimeout(() => {
        navigate(route, historyState);
    }, 100);
};

export default withHostEvent;
