import { useTranslation } from 'react-i18next';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Typography } from '@mui/material';

const SubscriptionBackButton = ({ setCurrentPage, previousPage, disable }: any) => {
    const { t } = useTranslation();
    return (
        <div className="subcription_bottom">
            <div
                className="backto_page"
                onClick={() => {
                    setCurrentPage(previousPage);
                }}
                style={{ pointerEvents: `${disable ? 'none' : 'all'}` }}
            >
                <ArrowBackIcon />
                <Typography variant="caption">{t('lang_common.btn_back')}</Typography>
            </div>
        </div>
    );
};

export default SubscriptionBackButton;
