import { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import './ParticipantPanel.scss';

import { classSessionActions } from '../../actions/class-session.action';
import { getValidClassSessionFromStore, getValidUserFromStore } from '../../helpers/storeHelpers';
import { searchKeywordToArray } from '../../helpers/activityHelpers';
import { filterOnlineParticipants } from '../../helpers/classSessionHelpers';
import { isUserOnPremium } from '../../helpers/userhelper';

import ParticipantInterface from '../../interfaces/participant.interface';
import StoreInterface from '../../interfaces/store.interface';
import { SortBy } from '../../constants/sortby-types';

import ParticipantCountSection from './ParticipantCountSection';
import SortSection from './SortSection';
import SearchBox from '../activity/components/SearchBox';
import WaitingForParticipants from './WaitingForParticipants';
import CacheParticipantCard from './CacheParticipantCard';
import { processParticipantNamesForSorting } from '../savedClass/savedClassHelpers';
import MyClassSidebar from './components/MyClassSidebar';
import MyClassViewToggle from './MyClassViewToggle';
import MyClassGroupView from './groupView/MyClassGroupView';
import { SavedClassGroupInterface } from '../../interfaces/savedClassGroup.interface';

interface ParticipantPanelInterface {
    sortedParticipants: ParticipantInterface[];
    setSortedParticipants: (arg: ParticipantInterface[]) => void;
    onSelectParticipantToRemove: (arg: ParticipantInterface) => void;
    onShowMyClassQRModal: () => void;
    isMyClassSidebarShown: boolean;
    toggleSidebar: () => void;
    openQRCodeModal: () => void;
}

const ParticipantPanel = ({
    sortedParticipants,
    setSortedParticipants,
    onSelectParticipantToRemove,
    onShowMyClassQRModal,
    isMyClassSidebarShown,
    toggleSidebar,
    openQRCodeModal,
}: ParticipantPanelInterface) => {
    const dispatch = useDispatch();
    const user = getValidUserFromStore('SavedClassParticipants');
    const isPremiumUser = isUserOnPremium(user);
    const classSession = getValidClassSessionFromStore('ParticipantPanel');
    const webviewSettingsSlideshow = useSelector((state: StoreInterface) => state.webviewSettingsSlideshow);

    const [currentSort, setCurrentSort] = useState<SortBy>(
        webviewSettingsSlideshow.sortParticipantsBy || SortBy.JOIN_ORDER,
    );
    const [searchKeyword, setSearchKeyword] = useState('');
    const [isOrderChanged, setIsOrderChanged] = useState(false);
    const [activeTabIndex, setActiveTabIndex] = useState('1');
    const [savedClassGroups, setSavedClassGroups] = useState<SavedClassGroupInterface[]>(classSession.groups || []);

    const participantList = classSession ? classSession.participantList : [];
    const isMyClassShowOnlineParticipant = webviewSettingsSlideshow.isMyClassShowOnlineParticipant;

    const participantListWithOnlineStatus = isMyClassShowOnlineParticipant
        ? participantList.filter((data) => data.joined && !data.left)
        : participantList;

    const sortParticipantsByName = (searchKeyword: string) => {
        setCurrentSort(SortBy.NAME);
        const filteredParticipants = getFilteredParticipants(searchKeyword);

        setSortedParticipants(
            _.orderBy(
                filteredParticipants,
                [(participant) => processParticipantNamesForSorting(participant.participantName)],
                'asc',
            ),
        );
    };

    const sortParticipantsByJoinOrder = (searchKeyword: string) => {
        setCurrentSort(SortBy.JOIN_ORDER);
        const filteredParticipants = getFilteredParticipants(searchKeyword);
        const onlineFilteredParticipants = filterOnlineParticipants(filteredParticipants);
        const sortedOnlineParticipants = _.orderBy(onlineFilteredParticipants, 'joined', 'asc');
        const offlineFilteredParticipants = filteredParticipants.filter(
            (participant) => !onlineFilteredParticipants.includes(participant),
        );
        const sortedOfflineParticipants = _.orderBy(
            offlineFilteredParticipants,
            [(participant) => processParticipantNamesForSorting(participant.participantName)],
            'asc',
        );

        setSortedParticipants([...sortedOnlineParticipants, ...sortedOfflineParticipants]);
    };

    const sortParticipantsByPoints = (searchKeyword: string) => {
        setCurrentSort(SortBy.POINTS);
        const filteredParticipants = _.orderBy(
            getFilteredParticipants(searchKeyword),
            [(participant) => processParticipantNamesForSorting(participant.participantName)],
            'asc',
        );
        const sorted = filteredParticipants.sort(function (a, b) {
            if (b.participantTotalPoints + b.participantPoints === a.participantTotalPoints + a.participantPoints) {
                if (a.participantName.toUpperCase() < b.participantName.toUpperCase()) {
                    return -1;
                }
                if (a.participantName.toUpperCase() > b.participantName.toUpperCase()) {
                    return 1;
                }
            }
            return b.participantTotalPoints + b.participantPoints - a.participantTotalPoints - a.participantPoints;
        });
        setSortedParticipants(sorted);
    };

    const getFilteredParticipants = (searchKeyword: string): ParticipantInterface[] => {
        if (!searchKeyword || !searchKeyword.trim()) return participantListWithOnlineStatus;

        const searchArray = searchKeywordToArray(searchKeyword);
        return participantListWithOnlineStatus.filter((p) => {
            const matchedArray = searchArray.filter((item) => p.participantName.toLowerCase().includes(item));
            if (matchedArray.length > 0) return true;
            else return false;
        });
    };

    const filterParticipants = (searchKeyword: string) => {
        setSearchKeyword(searchKeyword);
        sortParticipants(currentSort, searchKeyword);
    };

    const removeParticipant = (participant: ParticipantInterface) => {
        if (!webviewSettingsSlideshow.isShowParticipantRemoveAlert) {
            classSessionActions.removeParticipant(participant, dispatch);
            return;
        }
        onSelectParticipantToRemove(participant);
    };

    const sortParticipants = (sortBy: SortBy, searchKeyword: string) => {
        switch (sortBy) {
            case SortBy.NAME:
                sortParticipantsByName(searchKeyword);
                break;
            case SortBy.JOIN_ORDER:
                sortParticipantsByJoinOrder(searchKeyword);
                break;
            case SortBy.POINTS:
                sortParticipantsByPoints(searchKeyword);
                break;
            default:
                break;
        }
    };

    const handleActiveTab = (event: SyntheticEvent, newValue: string) => {
        setActiveTabIndex(newValue);
    };

    const refreshSavedClass = (actionType?: string, groupId?: number) => {
        if (actionType === 'delete' && groupId) {
            const updatedGroups = savedClassGroups.filter((group) => group.groupId !== groupId);
            setSavedClassGroups(updatedGroups || []);
        }
    };

    useEffect(() => {
        if (!isPremiumUser) setActiveTabIndex('1');
        if (webviewSettingsSlideshow.sortParticipantsBy) {
            const sortedParticipantIds = _.sortBy(sortedParticipants.map((p) => p.participantId));
            const participantIds = _.sortBy(participantListWithOnlineStatus.map((p) => p.participantId));

            if (
                webviewSettingsSlideshow.sortParticipantsBy === SortBy.POINTS &&
                JSON.stringify(sortedParticipantIds) === JSON.stringify(participantIds)
            ) {
                // if participants remain the same but only points are changed, don't re-sort
                const updatedSortedParticipants = sortedParticipants.map((p) => {
                    const targetIndex = participantListWithOnlineStatus.findIndex(
                        (ep) => ep.participantId === p.participantId,
                    );
                    return { ...p, ...participantListWithOnlineStatus[targetIndex] };
                });
                setSortedParticipants(updatedSortedParticipants);
                return;
            }
            sortParticipants(webviewSettingsSlideshow.sortParticipantsBy, searchKeyword);
        } else sortParticipants(SortBy.JOIN_ORDER, searchKeyword);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classSession, isMyClassShowOnlineParticipant, isPremiumUser]);

    useEffect(() => {
        if (webviewSettingsSlideshow.sortParticipantsBy === SortBy.POINTS) sortParticipantsByPoints(searchKeyword);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classSession.classSessionId]);

    useEffect(() => {
        setIsOrderChanged(true);
        setTimeout(() => setIsOrderChanged(false), 10);
    }, [sortedParticipants]);

    useEffect(() => {
        setSavedClassGroups(classSession.groups || []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classSession.groups]);

    return (
        <>
            <div className={`participant_content_sec`}>
                <div className="participant_sec_container">
                    <div className="participant_head_sec">
                        {classSession.participantList.length > 0 && (
                            <>
                                <div className="searchfieldContainer">
                                    {activeTabIndex === '1' && <SearchBox onSearch={filterParticipants} />}
                                </div>

                                {isPremiumUser && !!classSession.savedClassId ? (
                                    <MyClassViewToggle
                                        activeTabIndex={activeTabIndex}
                                        handleActiveTab={handleActiveTab}
                                        sortedParticipantsLength={sortedParticipants.length}
                                        savedClassGroupsLength={savedClassGroups.length}
                                    />
                                ) : (
                                    <ParticipantCountSection onShowMyClassQRModal={onShowMyClassQRModal} />
                                )}

                                <div className="sortSectionContainer">
                                    {activeTabIndex === '1' && (
                                        <SortSection
                                            currentSort={currentSort}
                                            onSortByJoinOrder={() => sortParticipantsByJoinOrder(searchKeyword)}
                                            onSortByName={() => sortParticipantsByName(searchKeyword)}
                                            onSortByPoints={() => sortParticipantsByPoints(searchKeyword)}
                                        />
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="participant_card_container">
                    <MyClassSidebar
                        isSidebarShown={isMyClassSidebarShown}
                        toggleSidebar={toggleSidebar}
                        openQRCodeModal={openQRCodeModal}
                    />

                    {activeTabIndex === '1' ? (
                        <div className={`participent_card ${isMyClassSidebarShown && 'showSidebar'}`}>
                            {classSession.participantList.length > 0 ? (
                                sortedParticipants.map((participant, index: number) => (
                                    <CacheParticipantCard
                                        key={index}
                                        savedClassGroups={savedClassGroups}
                                        participant={participant}
                                        removeParticipant={removeParticipant}
                                        searchTextHighlight={searchKeyword.trim()}
                                        isOrderChanged={isOrderChanged}
                                    />
                                ))
                            ) : (
                                <WaitingForParticipants />
                            )}
                        </div>
                    ) : (
                        <MyClassGroupView
                            savedClassGroups={savedClassGroups}
                            sortedParticipants={sortedParticipants}
                            isMyClassSidebarShown={isMyClassSidebarShown}
                            refreshSavedClass={refreshSavedClass}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default ParticipantPanel;
