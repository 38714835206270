import { Switch } from '@mui/material';

const SwitchSectionHeader = ({
    triggerFunction,
    label,
    disabled,
    checked,
    switchText,
    className,
    switchClassName,
    disableOnClick,
}: any) => {
    return (
        <div className="class_code">
            <Switch
                onChange={(e) => triggerFunction(e.target.checked)}
                {...label}
                disabled={disabled}
                checked={checked}
                className={switchClassName}
            />{' '}
            <span onClick={() => (disableOnClick ? console.log('') : triggerFunction(!checked))} className={className}>
                {switchText}
            </span>
        </div>
    );
};

export default SwitchSectionHeader;
