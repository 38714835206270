import { useTranslation } from 'react-i18next';
import { Button, IconButton } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';

import EditableInputTime from './EditableInputTime';

interface TimerDefaultInterface {
    timerValue: number;
    setTimerValue: (arg: number) => void;
    startTimer: () => void;
    increaseTime: () => void;
    decreaseTime: () => void;
    setShowSettings: (arg: boolean) => void;
}

const TimerSetter = ({
    timerValue,
    setTimerValue,
    startTimer,
    increaseTime,
    decreaseTime,
    setShowSettings,
}: TimerDefaultInterface) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="time_add_wraper">
                <div className="timer_add_inner">
                    <IconButton
                        color="primary"
                        className="defult_btn"
                        disabled={timerValue <= 1}
                        onClick={() => decreaseTime()}
                    >
                        <RemoveIcon />
                    </IconButton>

                    <div className="time_display">
                        <EditableInputTime timerValue={timerValue} setTimerValue={setTimerValue} />
                    </div>

                    <IconButton
                        color="primary"
                        className="defult_btn"
                        disabled={timerValue >= 5999}
                        onClick={() => increaseTime()}
                    >
                        <AddIcon />
                    </IconButton>
                </div>
            </div>

            <div className="timmer_btn">
                <Button onClick={() => startTimer()} variant="contained">
                    {t('lang_timer.btn_start')}
                </Button>

                <IconButton onClick={() => setShowSettings(true)} color="primary" className="defult_btn float">
                    <NotificationsActiveOutlinedIcon />
                </IconButton>
            </div>
        </>
    );
};

export default TimerSetter;
