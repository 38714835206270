import { getCachedReducer } from './../helpers/storeHelpers';
import { UserActionTypes } from '../constants/user-action-types';
import UserInterface from '../interfaces/user-interface';
import { localService } from '../services/localStorageService';
import { ReducerName } from '../constants/reducer-name.enum';

export default function user(
    state = getCachedUserState(),
    action: { type: string; payload: any },
): UserInterface | null {
    switch (action.type) {
        case UserActionTypes.PAGE_REFRESHED_LOAD_CACHED_USER:
        case UserActionTypes.AUTO_SIGN_IN_WITH_PASSWORD_SUCCESS:
        case UserActionTypes.MANUAL_SIGN_IN_WITH_PASSWORD_SUCCESS:
        case UserActionTypes.MANUAL_SIGN_IN_WITH_SSO_SUCCESS:
        case UserActionTypes.SIGN_IN_AFTER_SIGN_UP_SUCCESS:
        case UserActionTypes.SIGN_IN_AFTER_PASSWORD_RESET_SUCCESS:
        case UserActionTypes.PERIODIC_CHECK_USER_CHANGED:
        case UserActionTypes.USER_PROFILE_EDITED:
        case UserActionTypes.UPLOADED_AVATAR_IMAGES:
        case UserActionTypes.PRODUCT_KEY_APPLIED:
        case UserActionTypes.PRODUCT_KEY_CHANGED:
        case UserActionTypes.PRODUCT_KEY_REMOVED:
        case UserActionTypes.PLAN_CHANGE_SCHEDULED:
        case UserActionTypes.CANCELLATION_SCHEDULED:
        case UserActionTypes.CANCELLATION_REVOKED:
        case UserActionTypes.ADDED_SAVED_CLASS:
        case UserActionTypes.ADDED_SAVED_CLASS_NO_PARTICIPANT:
        case UserActionTypes.CHANGED_SAVED_CLASS_NAME_OR_CODE:
        case UserActionTypes.DELETE_SAVED_CLASS:
        case UserActionTypes.REORDER_SAVED_CLASSES:
        case UserActionTypes.ADDED_WHITEBOARD:
        case UserActionTypes.UPDATED_WHITEBOARD:
        case UserActionTypes.DELETED_WHITEBOARD:
        case UserActionTypes.CHANGE_LANGUAGE:
        case UserActionTypes.LOAD_USER_LANGUAGE_PREFERENCE:
        case UserActionTypes.TRIAL_STARTED:
        case UserActionTypes.TRIAL_EXTENDED:
        case UserActionTypes.SAVE_CUSTOMIZED_LEVELS:
        case UserActionTypes.RESET_CUSTOMIZED_LEVELS:
        case UserActionTypes.CREATE_MOVABLE_FOLDER:
        case UserActionTypes.RENAME_MOVABLE_FOLDER:
        case UserActionTypes.DELETE_MOVABLE_FOLDER:
        case UserActionTypes.UPLOAD_MOVABLE_FILE:
        case UserActionTypes.MOVE_MOVABLE_FILE:
        case UserActionTypes.DELETE_MOVABLE_FILE:
        case UserActionTypes.ADDED_BOOKMARK:
        case UserActionTypes.REARRANGED_BOOKMARKS:
        case UserActionTypes.REMOVED_BOOKMARK:
        case UserActionTypes.UPDATE_USER_CLASS_SESSION:
        case UserActionTypes.ADDED_ONBOARDING_EVENT:
        case UserActionTypes.TOGGLE_QNA:
            return action.payload;
        case UserActionTypes.HOST_AUTO_UNLOAD_USER:
        case UserActionTypes.AUTO_SIGN_IN_WITH_PASSWORD_FAILED:
        case UserActionTypes.USER_MANUALLY_SIGNED_OUT:
        case UserActionTypes.DEVICE_ID_CHANGED:
        case UserActionTypes.SIGN_OUT_ON_ERROR_BOUNDARY:
            return noUserState;
        case UserActionTypes.RELOAD_USER_REDUCER:
            return getCachedUserState();
        default:
            return state;
    }
}

const getCachedUserState = () => {
    const userCached: UserInterface | null = getCachedReducer(ReducerName.user);
    if (userCached) return userCached;

    const storeCached = localService.getStoreData();
    if (storeCached && storeCached.user) return storeCached.user;

    return noUserState;
};

const noUserState = null;
