import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Typography } from '@mui/material';

import { webviewSettingsActions } from '../../../actions/webview-settings.actions';

import UserInterface from '../../../interfaces/user-interface';
import StoreInterface from '../../../interfaces/store.interface';
import { WebviewSettingsActionTypes } from '../../../constants/webview-settings-action-types';

import CommonButton from '../../../components/Common/CommonButton';
import { UpgradeToProButton } from '../../../components/Common/UpgradeToProButton';
import CommonCheckBox from '../../../components/Common/CommonCheckBox';

interface ParticipantResetAlertInterface {
    user: UserInterface;
    handleDoubleConfirm: () => void;
}

const ResetStarsAlert = ({ user, handleDoubleConfirm }: ParticipantResetAlertInterface) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const webviewSettingsSlideshow = useSelector((state: StoreInterface) => state.webviewSettingsSlideshow);

    const [checked, setChecked] = useState(!webviewSettingsSlideshow.isShowResetStarAlert);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const restartClassHandler = () => {
        if (checked) {
            dispatch(
                webviewSettingsActions.updateWebviewSettings(WebviewSettingsActionTypes.SHOW_RESET_STAR_ALERT, {
                    isShowResetStarAlert: false,
                }),
            );
        }
        handleDoubleConfirm();
    };

    return (
        <Fragment>
            <div className="media">
                <div className="media_icon">
                    <ReportProblemIcon className="warning" />
                </div>
                <div className="media_body">
                    <div className="alert_content">
                        <Typography variant="body1">{t('lang_myclass.warning_reset_stars')}</Typography>

                        <div className="alert_check">
                            <CommonCheckBox
                                value={checked}
                                isHookRegistered={false}
                                label={t('lang_common.txt_dont_remind_me_again')}
                                changeFunction={handleChange}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="button_sec">
                <CommonButton
                    isLoading={false}
                    variant="text"
                    type="button"
                    text={t('lang_common.btn_confirm')}
                    clickFunction={() => restartClassHandler()}
                />
                <UpgradeToProButton caller="ResetStarsAlert" email={user.email} />
            </div>
        </Fragment>
    );
};

export default ResetStarsAlert;
