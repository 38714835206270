import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Typography } from '@mui/material';
import PlayCircleFilledTwoToneIcon from '@mui/icons-material/PlayCircleFilledTwoTone';
import { userActions } from '../../../actions/user.actions';
import CommonButton from '../../../components/Common/CommonButton';
import { UserActionTypes } from '../../../constants/user-action-types';
import { utilConstants } from '../../../constants/utils.constants';
import { getValidUserFromStore } from '../../../helpers/storeHelpers';
import apiPreferences from '../../../services/apiPreferences';
import AddLevel from './AddLevel';
import LevelBox2 from './LevelBox';
import ResetAlert from './ResetAlert';
import { isUserOnTrialOrPro } from '../../../helpers/userhelper';
import VideoModal from '../../VideoTutorial/Components/VideoModal';
import { TrialFlow } from '../../activateTrial/TrialFlow';
import { ViewportSavedClasses } from '../../../constants/viewport-constants';
import webviewMessenger from '../../../services/webviewMessenger';

function Levels() {
    const [tutorialVideoId, setTutorialVideoId] = useState('');

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const user = getValidUserFromStore('Levels');
    const isTrialOrPro = isUserOnTrialOrPro(user);

    const customizedLevelsArray =
        user.userPreferences?.customizedLevels?.split(',').map((level) => parseInt(level)) ||
        utilConstants.DEFAULT_LEVELS_ARRAY;

    const [levels, setLevels] = useState(customizedLevelsArray);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isResetWarningOpen, setIsResetWarningOpen] = useState(false);

    const [isTrialFlowModalOpen, setIsTrialFlowModalOpen] = useState(false);
    const [isActivatingTrial, setIsActivatingTrial] = useState(false);

    useEffect(() => {
        webviewMessenger.sendUsageLog(`Navigated to Settings -> Gamification`);
        if (!isTrialOrPro) setIsTrialFlowModalOpen(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setLevels(customizedLevelsArray);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, isEditMode]);

    const checkLevelError = (index: number) => {
        if (index === 0) return false;
        if (index < levels.length - 1) {
            if (levels[index] <= levels[index - 1]) return true;
            if (levels[index] >= levels[index + 1]) return true;
        }
        if (levels[index] <= levels[index - 1]) return true;
        return false;
    };

    const addLevel = () => {
        const updatedLevels = [...levels];
        updatedLevels.push(levels[levels.length - 1] + 1);
        setLevels(updatedLevels);
    };

    const changeLevelValue = (index: number, newValue: number) => {
        const updatedLevels = [...levels];
        updatedLevels[index] = newValue;
        setLevels(updatedLevels);
        tryClearErrorMessage(updatedLevels);
    };

    const tryClearErrorMessage = (updatedLevels: number[]) => {
        if (updatedLevels.length === 0) return setErrorMessage('');
        for (let index = 1; index < updatedLevels.length; index++) {
            if (updatedLevels[index] <= updatedLevels[index - 1]) return;
        }
        setErrorMessage('');
    };

    const deleteLevel = () => {
        const updatedLevels = [...levels];
        updatedLevels.pop();
        setLevels(updatedLevels);
    };

    const saveLevels = async () => {
        if (levels.length > 1) {
            for (let index = 1; index < levels.length; index++) {
                if (levels[index] <= levels[index - 1])
                    return setErrorMessage(t('lang_customize_levels.err_levels_error'));
            }
        }
        setErrorMessage('');
        const updatedLevels = levels.join(',');
        if (updatedLevels === customizedLevelsArray.join(',')) return setIsEditMode(false);

        setIsSaving(true);
        const updatedUser = await apiPreferences.saveCustomizedLevels(user.email, updatedLevels);
        setIsSaving(false);
        if (!updatedUser) return dispatch(userActions.showApiError());
        dispatch(userActions.updateUser(UserActionTypes.SAVE_CUSTOMIZED_LEVELS, updatedUser));
        setIsEditMode(false);
    };

    const handleResetLevels = async () => {
        const updatedUser = await apiPreferences.resetCustomizedLevels(user.email);
        setIsResetWarningOpen(false);
        if (!updatedUser) return dispatch(userActions.showApiError());
        dispatch(userActions.updateUser(UserActionTypes.RESET_CUSTOMIZED_LEVELS, updatedUser));
    };

    return (
        <Fragment>
            <ResetAlert
                showAlert={isResetWarningOpen}
                onResetLevels={handleResetLevels}
                onClose={() => setIsResetWarningOpen(false)}
            />

            {isTrialFlowModalOpen && (
                <div className="modal comming_soon_modal">
                    <div className="back_drop settingsGamification"></div>
                    <div className={`trialFlowContentContainer`}>
                        <TrialFlow
                            isShowMultipleSlides={false}
                            startingSlideId={'gamification'}
                            isActivatingTrial={isActivatingTrial}
                            setIsActivatingTrial={setIsActivatingTrial}
                            viewport={ViewportSavedClasses}
                            isGamificationModal={true}
                            customClassNames="gamificationTrialActivation verticalText"
                            onTrialActivated={() => setIsTrialFlowModalOpen(false)}
                        />
                    </div>
                </div>
            )}

            <div className="body_head justify_space_between">
                <Typography variant="h2">{t('lang_customize_levels.heading_customize_levels')}</Typography>
                <Button
                    variant="outlined"
                    className="watch_tutorial_btn"
                    onClick={() => {
                        webviewMessenger.sendUsageLog(`Clicked "Watch tutorial" (customize_levels)`);
                        setTutorialVideoId('customize_levels');
                    }}
                    startIcon={<PlayCircleFilledTwoToneIcon />}
                >
                    {t('lang_common.btn_watch_tutorial')}
                </Button>
            </div>
            <div className="level_sec">
                <div className="level_top_content">
                    <Typography variant="body1">{t('lang_customize_levels.txt_description_pro')}</Typography>
                </div>
                <div className="level_display_sec">
                    <div className="level_wrapper">
                        {levels.map((level, index: number) => (
                            <Fragment key={index}>
                                <LevelBox2
                                    index={index}
                                    title={t(`lang_customize_levels.txt_level_title`, { num: index + 1 })}
                                    image={`/assets/levels/level_${index + 1}.svg`}
                                    value={level}
                                    isEditMode={isEditMode && index > 0}
                                    hasError={checkLevelError(index)}
                                    canDelete={isEditMode && index === levels.length - 1 && index > 0}
                                    onChange={changeLevelValue}
                                    onDelete={deleteLevel}
                                />
                            </Fragment>
                        ))}
                        {levels.length < 10 && isEditMode && <AddLevel onAdd={addLevel} />}
                    </div>
                    <div className="error level_error">
                        <Typography variant="caption">{errorMessage}</Typography>
                    </div>
                </div>

                <div className="profile_update_btn flex">
                    {isEditMode ? (
                        <Fragment>
                            <CommonButton
                                isLoading={isSaving}
                                variant="contained"
                                className="Edit profile"
                                color="primary"
                                text={
                                    isSaving
                                        ? t('lang_customize_levels.btn_saving_levels')
                                        : t('lang_customize_levels.btn_save_levels')
                                }
                                clickFunction={() => {
                                    saveLevels();
                                }}
                            />
                            <CommonButton
                                isLoading={false}
                                variant="contained"
                                className="cancel"
                                type="submit"
                                color="primary"
                                text={t('lang_common.btn_cancel')}
                                clickFunction={() => {
                                    setIsEditMode(false);
                                    setErrorMessage('');
                                }}
                            />
                        </Fragment>
                    ) : (
                        <Fragment>
                            <CommonButton
                                isLoading={false}
                                variant="contained"
                                className="Edit profile"
                                color="primary"
                                text={t('lang_customize_levels.btn_edit_levels')}
                                clickFunction={() => setIsEditMode(true)}
                            />
                            {user.userPreferences?.customizedLevels && (
                                <CommonButton
                                    isLoading={false}
                                    variant="contained"
                                    className="reset"
                                    color="primary"
                                    text={t('lang_customize_levels.btn_reset_levels')}
                                    clickFunction={() => setIsResetWarningOpen(true)}
                                />
                            )}
                        </Fragment>
                    )}
                </div>
            </div>
            {tutorialVideoId && (
                <VideoModal
                    tutorialVideoId={tutorialVideoId}
                    setTutorialVideoId={setTutorialVideoId}
                    viewportConstant={ViewportSavedClasses}
                />
            )}
        </Fragment>
    );
}

export default Levels;
