import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { localService } from '../../../services/localStorageService';

import CommonButton from '../../../components/Common/CommonButton';
import { findNonEmptyAddedParticipants } from '../savedClassHelpers';
import webviewMessenger from '../../../services/webviewMessenger';

interface AddSavedClassesFooterInterface {
    currentStep: number;
    setCurrentStep: (arg: number) => void;
    onDiscardAddSavedClass: () => void;
    onShowDiscardAddClassAlert: () => void;
    onSubmitStepOne: () => void;
    isSubmitting: boolean;
    onSubmitStepTwo: () => void;
    onShowZeroParticipantAlert: () => void;
}

const AddSavedClassesFooter = ({
    currentStep,
    setCurrentStep,
    onDiscardAddSavedClass,
    onShowDiscardAddClassAlert,
    onSubmitStepOne,
    isSubmitting,
    onSubmitStepTwo,
    onShowZeroParticipantAlert,
}: AddSavedClassesFooterInterface) => {
    const { t } = useTranslation();
    const [nonEmptyParticipantCount, setNonEmptyParticipantCount] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const timer = setInterval(async () => {
            if (currentStep === 2) {
                syncAddedParticipants();
            } else clearInterval(timer);
        }, 1000);

        return () => clearInterval(timer);
    }, [currentStep]);

    const syncAddedParticipants = () => {
        const addedParticipants = localService.getTempAddedParticipants();
        if (!addedParticipants || addedParticipants.length < 1) return;
        const nonEmptyParticipants = findNonEmptyAddedParticipants(addedParticipants).length;
        setNonEmptyParticipantCount(nonEmptyParticipants);
        const errorsCount = addedParticipants.map((p) => p.error).filter((error) => !!error).length;

        // if (errorsCount === 0 && nonEmptyParticipants > 0) setErrorMessage('');
        if (errorsCount === 0) setErrorMessage('');
    };

    const finishSetup = () => {
        const addedParticipants = localService.getTempAddedParticipants();
        if (!addedParticipants || addedParticipants.length < 1) {
            onShowZeroParticipantAlert();
        } else {
            const nonEmptyParticipants: number = findNonEmptyAddedParticipants(addedParticipants).length;
            if (nonEmptyParticipants === 0) {
                onShowZeroParticipantAlert();
            } else {
                const errorsCount = addedParticipants.map((p) => p.error).filter((error) => !!error).length;
                if (errorsCount > 0) {
                    setErrorMessage(t('lang_saved_classes.err_red_massage'));
                } else {
                    onSubmitStepTwo();
                }
            }
        }
    };

    const backToPrevious = () => {
        if (currentStep === 2) {
            setCurrentStep(1);
        } else if (currentStep === 1) {
            if (nonEmptyParticipantCount > 0) {
                onShowDiscardAddClassAlert();
            } else {
                onDiscardAddSavedClass();
            }
        }
    };

    // const footerContent = () => {
    //     if (currentStep !== 2) return null;
    //     if (nonEmptyParticipantCount === 0)
    //         return (
    //             // <Typography variant="body1" className="addParticipantLaterLink" onClick={onSubmitStepTwo}>
    //             //     {t('lang_saved_classes.txt_add_participants_later')}
    //             // </Typography>

    //             <Button className="addParticipantLaterLink button" onClick={onSubmitStepTwo}>
    //                 {t('lang_saved_classes.txt_add_participants_later')}
    //             </Button>
    //         );

    //     // return (
    //     //     <Typography variant="body1" className="cursor_default">
    //     //         {nonEmptyParticipantCount > 1
    //     //             ? t('lang_saved_classes.txt_participants_added', {
    //     //                   numberOfParticipants: nonEmptyParticipantCount,
    //     //               })
    //     //             : t('lang_saved_classes.txt_participant_added', {
    //     //                   numberOfParticipants: nonEmptyParticipantCount,
    //     //               })}
    //     //     </Typography>
    //     // );
    // };

    return (
        <div className={`participent_footer`}>
            <div className="savedClassContainer">
                <div className="participent_innner_foot">
                    <div className="participent_messages">
                        {/* {errorMessage ? (
                                <Typography variant="body1" className="danges cursor_default">
                                    {errorMessage}
                                </Typography>
                            ) : (
                                <Fragment>{footerContent()}</Fragment>
                            )} */}

                        {errorMessage && (
                            <Typography variant="body1" className="danges cursor_default">
                                {errorMessage}
                            </Typography>
                        )}
                    </div>

                    <div className="step_btn">
                        <Button
                            color="inherit"
                            onClick={backToPrevious}
                            className="default_btn"
                            sx={{ mr: 1 }}
                            startIcon={<ArrowBackIosIcon />}
                        >
                            {t('lang_common.btn_back')}
                        </Button>

                        {currentStep === 1 ? (
                            isSubmitting ? (
                                <CommonButton className="solid" isLoading={true} text={t('lang_common.btn_next')} />
                            ) : (
                                <CommonButton
                                    className="solid"
                                    clickFunction={() => {
                                        webviewMessenger.sendUsageLog(`Clicked "Next"`);
                                        onSubmitStepOne();
                                    }}
                                    text={t('lang_common.btn_next')}
                                />
                            )
                        ) : // : nonEmptyParticipantCount === 0 ? (
                        //     <>
                        //         <CommonButton
                        //             className="addParticipantLaterLink button"
                        //             clickFunction={() => {
                        //                 webviewMessenger.sendUsageLog(`Clicked "I will add participants later"`);
                        //                 onSubmitStepTwo();
                        //             }}
                        //             text={t('lang_saved_classes.txt_add_participants_later')}
                        //             isLoading={isSubmitting}
                        //         />
                        //     </>
                        // )
                        isSubmitting ? (
                            <CommonButton className="solid" isLoading={true} text={t('lang_common.btn_finishing')} />
                        ) : (
                            <CommonButton
                                className="solid"
                                clickFunction={() => {
                                    webviewMessenger.sendUsageLog(`Clicked "Finish"`);
                                    finishSetup();
                                }}
                                text={t('lang_common.btn_finish')}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddSavedClassesFooter;
