import { Fragment, useEffect, useState } from 'react';
import { FitToViewport } from 'react-fit-to-viewport';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Divider, Link, Typography } from '@mui/material';
import { WEBSITE_URL, utilConstants } from '../../constants/utils.constants';
import { dashboard } from '../../constants/viewport-constants';
import CustomAvatar, { AvatarType } from '../../components/Common/CustomAvatar';
import Loading from '../activity/Loading/Loading';
import StoreInterface from '../../interfaces/store.interface';
import webviewMessenger from '../../services/webviewMessenger';
import WelcomeUser from './WelcomeUser';
import apiUser from '../../services/apiUser';
// import { UserMetricsInterface } from '../../interfaces/user-metrics.interface';
import EmptyModal from '../EmptyModal/EmptyModal';
import './Dashboard.scss';
import PlanTypeBadge from '../setting/subscriptions/components/PlanTypeBadge';
import { userActions } from '../../actions/user.actions';
import { HostEventTypes } from '../../constants/host-event-types';
import { UserActionTypes } from '../../constants/user-action-types';
import { getNewUserDiscount, getRemainingProDays } from '../../helpers/userhelper';
import { getCpVer } from '../../helpers/utils';
import NewDiscountGiftBox from './NewDiscountGiftBox';
import { BootstrapTooltip } from '../../helpers/mui/CustomToolTip';

function Dashboard() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [showEmptyModal, setShowEmptyModal] = useState(false);
    const [isFirstSignIn, setIsFirstSignIn] = useState(false);
    // const [userMetrics, setUserMetrics] = useState<UserMetricsInterface>({
    //     userId: '',
    //     numberOfClassSessions: 0,
    //     numberOfPointsGiven: 0,
    //     numberOfResponsesReceived: 0,
    // });
    const user = useSelector((state: StoreInterface) => state.user);
    const newUserDiscount = user ? getNewUserDiscount(user) : null;

    useEffect(() => {
        if (!user) dispatch(userActions.reloadUserReducer());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.log('Now sending fb pixel event: OpenDashboard');
        (window as any).fbq('trackCustom', 'OpenDashboard');
        webviewMessenger.sendUsageLog(`Navigated to Dashboard`);
        if (user) {
            const timeFromFirstSignIn = new Date().getTime() - new Date(user?.firstSignIn).getTime();
            if (timeFromFirstSignIn / (1000 * 3) < 1) setIsFirstSignIn(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setShowEmptyModal(true);
        setTimeout(() => {
            setShowEmptyModal(false);
        }, 500); // need longer delay for this page, otherwise black border will show after sign in
    }, []);

    // useEffect(() => {
    //     if (user) getUserMetrics(user.userId);
    // }, [user?.userId]);

    if (!user) return <Loading />;

    const { userProfile } = user;
    const today = new Date().getTime();
    const isCCT = userProfile.cctExpiryDate && new Date(userProfile.cctExpiryDate).getTime() > today ? true : false;
    const isCCE = userProfile.cceExpiryDate && new Date(userProfile.cceExpiryDate).getTime() > today ? true : false;
    const isCSC = userProfile.cscExpiryDate && new Date(userProfile.cscExpiryDate).getTime() > today ? true : false;
    const hasMultipleBadge = [isCCT, isCCE, isCSC].filter((data) => data).length > 1;

    // const getUserMetrics = async (userId: string) => {
    //     const userMetrics = await apiUser.getUserMetrics(userId);
    //     if (!userMetrics) return;
    //     setUserMetrics(userMetrics);
    // };

    const signOut = () => {
        dispatch(userActions.unloadUser(UserActionTypes.USER_MANUALLY_SIGNED_OUT, HostEventTypes.SIGNED_OUT));
        apiUser.signOut(user.email);
        navigate('/loading');
    };

    const displayPlanExpiringInfo = (): string => {
        const planType = user.userSubscription.planType;

        if (planType === utilConstants.PLAN_TYPE.BASIC) return '';
        if (planType === utilConstants.PLAN_TYPE.SUBSCRIPTION) {
            if (!user.userSubscription.isCancelSubscriptionScheduled) return '';
            return t('lang_dashboard.txt_recurring_canceled');
        }

        const planExpiryDate = new Date(user.userSubscription.planExpiryDate || '0000-01-01');
        if (user.userSubscription.isTrial) {
            if (!user.userSubscription.trialStarted) return '';
            const remainingProDays = getRemainingProDays(planExpiryDate);
            return remainingProDays < 10 ? t('lang_dashboard.txt_trial_expiring', { remainingProDays }) : '';
        }
        const remainingProDays = getRemainingProDays(planExpiryDate);
        return remainingProDays < 10 ? t('lang_dashboard.txt_subscription_expiring', { remainingProDays }) : '';
    };

    return (
        <Fragment>
            <FitToViewport
                className="viewport"
                width={dashboard.width}
                height={dashboard.height}
                minZoom={dashboard.minZoom}
                maxZoom={dashboard.maxZoom}
            >
                {showEmptyModal && <EmptyModal viewportConfig={dashboard} />}
                {isFirstSignIn ? (
                    <WelcomeUser onGotoDashboard={() => setIsFirstSignIn(false)} />
                ) : (
                    <div className="dashboard_main">
                        <div className="background_sec"></div>
                        <div className="dashboard_content">
                            <div className="inner_db_content">
                                <div className="dashboard_left">
                                    <div className="user_card">
                                        <div className="profile_image_avater">
                                            <CustomAvatar
                                                avatarName={`${userProfile.firstName} ${userProfile.lastName}`}
                                                avatarUrl={userProfile.avatarUrl}
                                                type={AvatarType.USER}
                                            />
                                        </div>
                                        <PlanTypeBadge user={user} />
                                        <div className="profile_name_url">
                                            <Typography variant="h2">
                                                {t('lang_dashboard.display_name', {
                                                    firstName: userProfile.firstName,
                                                    lastName: userProfile.lastName,
                                                })}
                                            </Typography>
                                            <Typography variant="body1">{user.email}</Typography>
                                        </div>
                                        <Divider className="divider" />
                                        <div className="class_name">
                                            <Typography variant="h3">{userProfile.organization}</Typography>
                                        </div>

                                        {hasMultipleBadge ? (
                                            <div className="certified_tainner_badge">
                                                {isCCE && (
                                                    <BootstrapTooltip
                                                        title="ClassPoint Certified Educator"
                                                        placement="top"
                                                    >
                                                        <img
                                                            onClick={() =>
                                                                webviewMessenger.sendOpenBrowser(WEBSITE_URL.CCE)
                                                            }
                                                            src={utilConstants.IMAGE_URLS.cce_badge}
                                                            alt="cce_badge"
                                                        />
                                                    </BootstrapTooltip>
                                                )}
                                                {isCSC && (
                                                    <BootstrapTooltip title="ClassPoint School Coach" placement="top">
                                                        <img
                                                            onClick={() =>
                                                                webviewMessenger.sendOpenBrowser(WEBSITE_URL.CSC)
                                                            }
                                                            src={utilConstants.IMAGE_URLS.csc_badge}
                                                            alt="csc_badge"
                                                        />
                                                    </BootstrapTooltip>
                                                )}
                                                {isCCT && (
                                                    <BootstrapTooltip
                                                        title="ClassPoint Certified Trainer"
                                                        placement="top"
                                                    >
                                                        <img
                                                            onClick={() =>
                                                                webviewMessenger.sendOpenBrowser(WEBSITE_URL.CCT)
                                                            }
                                                            src={utilConstants.IMAGE_URLS.cct_badge}
                                                            alt="cct_badge"
                                                        />
                                                    </BootstrapTooltip>
                                                )}
                                            </div>
                                        ) : (
                                            <>
                                                {isCCE && (
                                                    <div
                                                        onClick={() =>
                                                            webviewMessenger.sendOpenBrowser(WEBSITE_URL.CCE)
                                                        }
                                                        className="certified_tainner"
                                                    >
                                                        <img src={utilConstants.IMAGE_URLS.cce_badge} alt="cce_badge" />
                                                        <Typography variant="h4">Certified Educator</Typography>
                                                    </div>
                                                )}
                                                {isCSC && (
                                                    <div
                                                        onClick={() =>
                                                            webviewMessenger.sendOpenBrowser(WEBSITE_URL.CSC)
                                                        }
                                                        className="certified_tainner"
                                                    >
                                                        <img src={utilConstants.IMAGE_URLS.csc_badge} alt="csc_badge" />
                                                        <Typography variant="h4">School Coach</Typography>
                                                    </div>
                                                )}
                                                {isCCT && (
                                                    <div
                                                        onClick={() =>
                                                            webviewMessenger.sendOpenBrowser(WEBSITE_URL.CCT)
                                                        }
                                                        className="certified_tainner"
                                                    >
                                                        <img src={utilConstants.IMAGE_URLS.cct_badge} alt="cct_badge" />
                                                        <Typography variant="h4">Certified Trainer</Typography>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="dashboard_right">
                                    <div className="inner_db_right">
                                        <div className="hello">
                                            <Typography variant="h1">{t('lang_dashboard.hello')},</Typography>
                                            <Typography variant="h2">
                                                {t('lang_dashboard.greeting', {
                                                    firstName: userProfile.firstName,
                                                    lastName: userProfile.lastName,
                                                })}
                                            </Typography>
                                        </div>

                                        {/* <div className="sessions_wapper">
                                            <div className="sessions_card">
                                                <div className="number">
                                                    <Hash />
                                                    <Typography variant="caption">
                                                        {userMetrics.numberOfClassSessions}
                                                    </Typography>
                                                </div>
                                                <Typography variant="body1">
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: t('lang_common.txt_interactive_sessions'),
                                                        }}
                                                    />
                                                </Typography>
                                            </div>
                                            <div className="sessions_card awarded">
                                                <div className="number">
                                                    <Star />
                                                    <Typography variant="caption">
                                                        {userMetrics.numberOfPointsGiven}
                                                    </Typography>
                                                </div>
                                                <Typography variant="body1">
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: t('lang_common.txt_stars_awarded'),
                                                        }}
                                                    />
                                                </Typography>
                                            </div>
                                            <div className="sessions_card responses">
                                                <div className="number">
                                                    <Chat />
                                                    <Typography variant="caption">
                                                        {userMetrics.numberOfResponsesReceived}
                                                    </Typography>
                                                </div>
                                                <Typography variant="body1">
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: t('lang_common.txt_responses_received'),
                                                        }}
                                                    />
                                                </Typography>
                                            </div>
                                        </div> */}

                                        <div className="quick_link">
                                            {newUserDiscount ? (
                                                <NewDiscountGiftBox />
                                            ) : (
                                                <>
                                                    <div className="quick_head">
                                                        <Typography variant="h4">
                                                            {t('lang_common.txt_quick_links')}{' '}
                                                        </Typography>
                                                    </div>

                                                    <div className="quick_link_url">
                                                        <ul>
                                                            <li>
                                                                <Link
                                                                    onClick={() => {
                                                                        webviewMessenger.sendSimpleMessage(
                                                                            'dashboardToTutorial',
                                                                        );
                                                                        navigate('/tutorial');
                                                                    }}
                                                                >
                                                                    {t('lang_common.txt_watch_video_tutorials')}{' '}
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link
                                                                    onClick={() => {
                                                                        webviewMessenger.sendOpenBrowser(
                                                                            `${process.env.REACT_APP_WEBAPP}/cp/account`,
                                                                        );
                                                                    }}
                                                                >
                                                                    {t('lang_common.txt_manage_subscription')}
                                                                </Link>
                                                                <span className="info">
                                                                    {' '}
                                                                    {displayPlanExpiringInfo()}
                                                                </span>
                                                            </li>
                                                            {/* <li>
                                                                <Link
                                                                    onClick={() => {
                                                                        webviewMessenger.sendOpenBrowser(`${process.env.REACT_APP_WEBAPP}/cp/account/profile`);
                                                                    }}
                                                                >
                                                                    {t('lang_common.txt_edit_profile')}
                                                                </Link>
                                                            </li> */}
                                                        </ul>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <div className="sign_out_user">
                                            <Link onClick={() => signOut()}>{t('lang_common.txt_sign_out')} </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="version">
                                <Typography variant="body1" display="block" className="">
                                    {t('lang_about.txt_version')} {getCpVer()}
                                </Typography>
                            </div>
                        </div>
                    </div>
                )}
            </FitToViewport>
        </Fragment>
    );
}

export default Dashboard;
