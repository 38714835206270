import { useTranslation } from 'react-i18next';

import { Link, Typography } from '@mui/material';

import { getValidUserFromStore } from '../../../../helpers/storeHelpers';
import { displayLocaleDate } from '../../../../helpers/languageHelpers';

import { utilConstants } from '../../../../constants/utils.constants';

import PlanTypeBadge from '../components/PlanTypeBadge';
import FeatureList from '../components/FeatureList';

interface SubscriptionRecurringInterface {
    cancelSubscription: () => void;
    revokePlanCancellation: () => void;
    onClickAndMore: () => void;
}

const SubscriptionRecurring = ({
    cancelSubscription,
    revokePlanCancellation,
    onClickAndMore,
}: SubscriptionRecurringInterface) => {
    const { t } = useTranslation();
    const user = getValidUserFromStore('SubscriptionRecurring');
    const userSubscription = user.userSubscription;
    const isCancelSubscriptionScheduled = userSubscription.isCancelSubscriptionScheduled;

    const nextBillingDate = new Date(userSubscription.nextBillingDate || '0000-01-01');
    const formattedDate = displayLocaleDate(nextBillingDate);

    return (
        <>
            <div className="inner_subscription">
                <div className="body_head">
                    <Typography variant="h2">{t('lang_subscription.title')}</Typography>
                </div>
                <div className="big_card">
                    <div className="subcription_display">
                        {/* <div className="card_icon">
                            <img src={utilConstants.SETTING.CROWN} alt="crown" />
                        </div> */}
                        <div className="plan_box_wrapper">
                            <div className="planbox">
                                <div className="plan_head">
                                    <Typography variant="h3">{t(`lang_subscription.txt_plan`)}</Typography>
                                </div>
                                <div className="plan_body">
                                    <Typography variant="h4" className={`title`}>
                                        {userSubscription.isPremium
                                            ? t('lang_premium.classpoint_premium')
                                            : t(`lang_new_trial.classpoint_pro`)}
                                    </Typography>
                                </div>
                                <PlanTypeBadge user={user} />
                            </div>

                            <div className="planbox">
                                <div className="plan_head">
                                    <Typography variant="h3">{t(`lang_subscription.txt_next_billing_date`)}</Typography>
                                </div>
                                <div className="plan_body">
                                    <Typography
                                        className={isCancelSubscriptionScheduled ? 'subscriptionCancelled' : ''}
                                        variant="h4"
                                    >
                                        {isCancelSubscriptionScheduled
                                            ? t(`lang_subscription.subscription_cancelled`)
                                            : formattedDate}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {isCancelSubscriptionScheduled ? (
                    <div className="subsription_box">
                        <p className={`subscriptionCancelledText`}>
                            {t('lang_subscription.txt_cancel_subscription_success_msg', {
                                nextBillingDate: formattedDate,
                            })}
                        </p>
                    </div>
                ) : (
                    <div className="feature_list">
                        <h5 className="list_head">
                            {userSubscription.isPremium ? t(`lang_premium.heading`) : t(`lang_new_trial.txt_in_pro`)}
                        </h5>
                        <FeatureList
                            caller="SubscriptionRecurring"
                            onClickAndMore={onClickAndMore}
                            isPremiumUser={userSubscription.isPremium}
                        />
                    </div>
                )}

                <div className={`link ${!isCancelSubscriptionScheduled && 'red'}`}>
                    {isCancelSubscriptionScheduled ? (
                        <Link onClick={() => revokePlanCancellation()}>
                            {t(`lang_subscription.btn_revoke_plan_cancellation`)}
                        </Link>
                    ) : (
                        <Link className={`cancelSubscription`} onClick={() => cancelSubscription()}>
                            {t(`lang_subscription.txt_cancel_subscription`)}
                        </Link>
                    )}
                </div>
            </div>
        </>
    );
};

export default SubscriptionRecurring;
