import { Fragment } from 'react';
import './Footer.scss';
import CommonButton from '../../Common/CommonButton';
import LeaderFooter from '../../../pages/leaderBoard/components/common/LeaderFooter';
import WhiteBoardFooter from '../../../pages/WhiteBoard/components/footer';
import QuizSummaryFooter from '../../../pages/Quizsummary/Components/footer';
import QnaFooter from '../../../pages/Qna/components/common/QnaFooter';

const Footer = ({
    restartClass,
    layout,
    isCurrentClassLeaderView,
    showMoreDefaultView,
    showMoreCurrentClassLeader,
    numOfElementsDefaultView,
    numOfElementsCurrentView,
    onInsertWhiteboard,
    isAddWhiteboardButtonDisabled,
    isFromRibbon,
    hideBackBtn,
    isPremiumUser,
    showGroupRankModalHandler,
    isQnaEnabled,
    isQnaEnabledHandler,
}: any) => {
    return (
        <Fragment>
            <footer className="footer_sec participant_sec_foot">
                {layout === 'myclass' && (
                    <Fragment>
                        <div className="footer_middle">
                            <CommonButton
                                isLoading={false}
                                variant="contained"
                                className="foot_btn"
                                type="button"
                                color="primary"
                                clickFunction={restartClass}
                                text="Start new class"
                            />
                        </div>
                    </Fragment>
                )}

                {layout === 'leaderboard' && (
                    <LeaderFooter
                        isCurrentClassLeaderView={isCurrentClassLeaderView}
                        showMoreDefaultView={showMoreDefaultView}
                        showMoreCurrentClassLeader={showMoreCurrentClassLeader}
                        numOfElementsDefaultView={numOfElementsDefaultView}
                        numOfElementsCurrentView={numOfElementsCurrentView}
                        hideBackBtn={hideBackBtn}
                        isPremiumUser={isPremiumUser}
                        showGroupRankModalHandler={showGroupRankModalHandler}
                    />
                )}

                {layout === 'whiteboard' && (
                    <WhiteBoardFooter
                        onInsertWhiteboard={onInsertWhiteboard}
                        isAddWhiteboardButtonDisabled={isAddWhiteboardButtonDisabled}
                    />
                )}

                {layout === 'quizsummary' && <QuizSummaryFooter isFromRibbon={isFromRibbon} />}

                {layout === 'qna' && (
                    <QnaFooter isQnaEnabled={isQnaEnabled} isQnaEnabledHandler={isQnaEnabledHandler} />
                )}
            </footer>
        </Fragment>
    );
};

export default Footer;
