import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Paper, TableContainer, Table, Typography } from '@mui/material';

import Modal from '@material-ui/core/Modal';

import './ParticipantView.scss';

import { userActions } from '../../../../actions/user.actions';
import { getValidUserFromStore } from '../../../../helpers/storeHelpers';
import {
    calculateParticipantLevelAndBadge,
    getLevelsArray,
    isUserOnPremium,
    isUserOnTrialOrPro,
} from '../../../../helpers/userhelper';
import apiSavedClasses from '../../../../services/apiSavedClasses';

import SavedClassForUserInterface from '../../../../interfaces/saved-class-for-user.interface';
import SavedClassParticipantInterface, {
    OrderProperties,
    Orders,
} from '../../../../interfaces/saved-class-participant.interface';
import { SavedClassGroupInterface } from '../../../../interfaces/savedClassGroup.interface';
import { utilConstants } from '../../../../constants/utils.constants';
import { ViewportSavedClasses } from '../../../../constants/viewport-constants';

import SavedClassParticipantsTableHead from '../savedClassParticipantsTable/SavedClassParticipantsTableHead';
import CommonAlert from '../../../../components/Common/CommonAlert';
import RenameParticipantModal from '../Modal/RenameParticipantModal';
import AdjustStarParticipantModal from '../Modal/AdjustStarParticipantModal';
import DeleteAlert from '../alert/DeleteAlert';
import { TrialFlow } from '../../../activateTrial/TrialFlow';
import { OpenProPitchButton } from '../../../../components/Common/OpenProPitchButton';
import SavedClassParticipantsTableBody from '../savedClassParticipantsTable/SavedClassParticipantsTableBody';

interface ParticipantViewInterface {
    sortedParticipants: SavedClassParticipantInterface[];
    savedClassGroups: SavedClassGroupInterface[];
    order: Orders;
    setOrder: (arg: Orders) => void;
    orderBy: OrderProperties;
    setOrderBy: (arg: OrderProperties) => void;
    savedClass?: SavedClassForUserInterface;
    setSavedClass?: (arg: SavedClassForUserInterface) => void;
    onParticipantsUpdated?: (participants: SavedClassParticipantInterface[]) => void;
    onDeleteSavedClass?: () => void;
    isViewingGroup?: boolean;
}

export default function ParticipantView({
    sortedParticipants,
    savedClassGroups,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    savedClass,
    setSavedClass,
    onParticipantsUpdated,
    onDeleteSavedClass,
    isViewingGroup,
}: ParticipantViewInterface) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const isAfterTrial = true;
    const user = getValidUserFromStore('SavedClassParticipantsTable');
    const isEmailVerified = user.userStats.isEmailVerified;
    const isTrialOrPro = isUserOnTrialOrPro(user);
    const isPremiumUser = isUserOnPremium(user);

    const [showDeleteWarning, setShowDeleteWarning] = useState(false);
    const [selectedParticipant, setSelectedParticipant] = useState<SavedClassParticipantInterface>();
    const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
    const [isAdjustStarModalOpen, setIsAdjustStarModalOpen] = useState(false);
    const [isTrialFlowModalOpen, setIsTrialFlowModalOpen] = useState(false);
    const [isActivatingTrial, setIsActivatingTrial] = useState(false);

    const handleRequestSort = (property: OrderProperties) => {
        if (property === orderBy) setOrder(order === Orders.Asc ? Orders.Desc : Orders.Asc);
        else {
            setOrderBy(property);
            setOrder(property === OrderProperties.ParticipantName ? Orders.Asc : Orders.Desc);
        }
    };

    const handleDeleteParticipant = async () => {
        if (!savedClass || !onParticipantsUpdated || !setSavedClass) return;

        const editSavedClassReply = await apiSavedClasses.deleteParticipant(
            savedClass.savedClassId,
            selectedParticipant?.participantId || '',
        );

        if (!editSavedClassReply) return dispatch(userActions.showApiError());
        const participantsWithLevel = editSavedClassReply.participants.map((p) => ({
            ...p,
            participantLevelAndBadge: calculateParticipantLevelAndBadge(p.participantTotalPoints, getLevelsArray(user)),
        }));

        onParticipantsUpdated(participantsWithLevel);
        setSavedClass({ ...savedClass, participantsCount: editSavedClassReply.participants.length });
    };

    return (
        <>
            <Box
                sx={{ width: '100%' }}
                className={`savedClassParticipantTable ${!isTrialOrPro && 'basicUserTable'} ${
                    isViewingGroup && 'viewGroup'
                } ${isPremiumUser && 'premiumUser'} ${savedClassGroups.length < 1 && 'hideGroup'} `}
            >
                <Paper sx={{ width: '100%', mb: 2 }} className="box_shadow_none savedClassParticipantTableContent">
                    <TableContainer sx={{ maxHeight: isViewingGroup ? 300 : isTrialOrPro ? 460 : 412 }}>
                        <Table
                            stickyHeader
                            aria-label="sticky table"
                            sx={{ minWidth: isViewingGroup ? 0 : 600 }}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                        >
                            <SavedClassParticipantsTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                isPremiumUser={isPremiumUser}
                                hideGroupColumn={savedClassGroups.length < 1}
                                isViewingGroup={isViewingGroup}
                            />

                            <SavedClassParticipantsTableBody
                                isTrialOrPro={isTrialOrPro}
                                isPremiumUser={isPremiumUser}
                                sortedParticipants={sortedParticipants}
                                savedClassGroups={savedClassGroups}
                                setSelectedParticipant={setSelectedParticipant}
                                setShowDeleteWarning={setShowDeleteWarning}
                                setIsRenameModalOpen={setIsRenameModalOpen}
                                setIsAdjustStarModalOpen={setIsAdjustStarModalOpen}
                                isViewingGroup={isViewingGroup}
                            />
                        </Table>
                    </TableContainer>
                </Paper>
            </Box>

            <CommonAlert
                showAlert={showDeleteWarning}
                content={
                    <DeleteAlert
                        onConfirmDeleteClass={onDeleteSavedClass}
                        onCloseAlert={() => setShowDeleteWarning(false)}
                        content={t('lang_saved_classes.alert_delete_participant', {
                            participantName: selectedParticipant?.participantName,
                        })}
                        handleDeleteParticipant={handleDeleteParticipant}
                        highlightWarning={true}
                    />
                }
                viewportConfig={ViewportSavedClasses}
                customModalClassNames="small"
            />

            <CommonAlert
                showAlert={isTrialFlowModalOpen}
                closeAlert={() => !isActivatingTrial && setIsTrialFlowModalOpen(false)}
                content={
                    <TrialFlow
                        isShowMultipleSlides={true}
                        startingSlideId={'gamification'}
                        setIsTrialFlowModalOpen={setIsTrialFlowModalOpen}
                        isActivatingTrial={isActivatingTrial}
                        setIsActivatingTrial={setIsActivatingTrial}
                        viewport={ViewportSavedClasses}
                        onTrialActivated={() => setIsTrialFlowModalOpen(false)}
                    />
                }
                viewportConfig={ViewportSavedClasses}
                customModalClassNames="small"
                customBoxClassNames={
                    isActivatingTrial
                        ? isEmailVerified
                            ? `subscriptionTrialFlowModal smallModal`
                            : ''
                        : `savedClassTrialFlowModal`
                }
            />

            {selectedParticipant && savedClass && (
                <>
                    <Modal
                        open={isRenameModalOpen}
                        onClose={() => setIsRenameModalOpen(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className="csv_upload_modal"
                    >
                        <RenameParticipantModal
                            closeAlert={() => setIsRenameModalOpen(false)}
                            savedClass={savedClass}
                            selectedParticipant={selectedParticipant}
                            sortedParticipants={sortedParticipants}
                            onParticipantsUpdated={onParticipantsUpdated}
                            savedClassGroups={savedClassGroups}
                        />
                    </Modal>

                    <Modal
                        open={isAdjustStarModalOpen}
                        onClose={() => setIsAdjustStarModalOpen(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className="csv_upload_modal"
                    >
                        <AdjustStarParticipantModal
                            closeAlert={() => setIsAdjustStarModalOpen(false)}
                            savedClass={savedClass}
                            selectedParticipant={selectedParticipant}
                            onParticipantsUpdated={onParticipantsUpdated}
                            savedClassGroups={savedClassGroups}
                        />
                    </Modal>
                </>
            )}

            {!isTrialOrPro && (
                <div className="basicUser">
                    <Typography variant="body1">
                        <img src={utilConstants.IMAGE_URLS.bulbIcon} alt="ClassPoint" />
                        {t('lang_saved_classes.txt_upgrade_to_pro_for_basic')}
                    </Typography>
                    <OpenProPitchButton
                        btnLabel={!isAfterTrial ? t('lang_new_trial.try_pro') : t('lang_common.btn_learn_more')}
                        onClickHandler={() => setIsTrialFlowModalOpen(true)}
                    />
                </div>
            )}
        </>
    );
}
