import { useCallback, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import ReactPlayer from 'react-player';
import Lottie from 'react-lottie-player';

import { Slider, Typography, Button, Link } from '@mui/material';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseIcon from '@mui/icons-material/Pause';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FullscreenTwoToneIcon from '@mui/icons-material/FullscreenTwoTone';

import { isJson } from '../../../helpers/activityHelpers';
import { findCenter } from '../../../helpers/animationHelpers';
import { stringToNumber } from '../../../helpers/utils';
import webviewMessenger from '../../../services/webviewMessenger';

import ActivityResponseInterface from '../../../interfaces/activity-response.interface';

import { TickLottie } from '../../../components/Common/AnimationModules';

import loaderAnimation from '../../../assets/animation/87884-loader.json';
import { VolumeMute } from '../../../Icon';

interface VideoPlayerWithControlsInterface {
    playerRef: any;
    choosedResponse: ActivityResponseInterface;
    canEditResponsesInView: boolean;
    onDeleteResponse: (arg: ActivityResponseInterface) => void;
    recordedProgress: number;
    onVideoProgress: (arg: number) => void;
    isAnimating: boolean;
    videoPlayError: boolean;
    setVideoPlayError: any;
    isReady: boolean;
    setIsReady: (arg: boolean) => void;
}

export default function VideoPlayerWithControls({
    playerRef,
    choosedResponse,
    canEditResponsesInView,
    onDeleteResponse,
    recordedProgress,
    onVideoProgress,
    isAnimating,
    videoPlayError,
    setVideoPlayError,
    isReady,
    setIsReady,
}: VideoPlayerWithControlsInterface) {
    const { t } = useTranslation();

    const boxSizeRef = useRef<any>();

    const [videoState, setVideoState] = useState({
        isPlaying: false,
        playedSeconds: 0,
        duration: 0,
        volume: 0.5,
        muted: false,
        showVolumeSlider: false,
        isInFullScreen: false,
    });

    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
    const [isVideoHasSound, setIsVideoHasSound] = useState(false);
    const [fullScreenMode, setFullScreenMode] = useState<boolean>(false);
    const [play, setPlay] = useState(false);

    const onReady = useCallback(() => {
        if (!isReady) {
            playerRef.current.seekTo(recordedProgress, 'seconds');
            setIsReady(true);
        }
        const video = document.querySelector('video');
        if (video && hasAudio(video)) {
            setIsVideoHasSound(true);
        } else {
            setIsVideoHasSound(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReady]);

    const hasAudio = (video: any) => {
        return (
            video.mozHasAudio ||
            Boolean(video.webkitAudioDecodedByteCount) ||
            Boolean(video.audioTracks && video.audioTracks.length)
        );
    };

    let timeout: ReturnType<typeof setTimeout>;
    const showCenterIcon = () => {
        if (timeout) {
            clearTimeout(timeout as any);
        }
        timeout = setTimeout(() => {
            setPlay(false);
        }, 500);
    };

    const playPauseVideo = () => {
        setVideoState((prevState) => {
            return { ...prevState, isPlaying: !prevState.isPlaying };
        });
        setPlay(true);
        showCenterIcon();
    };

    const playPauseVideoBtn = () => {
        setVideoState((prevState) => {
            return { ...prevState, isPlaying: !prevState.isPlaying };
        });
    };

    const muteUnmute = () => {
        setVideoState((prevState) => {
            return { ...prevState, muted: !prevState.muted };
        });
    };

    const showVolume = () => {
        setVideoState((prevState) => {
            return { ...prevState, showVolumeSlider: true };
        });
    };

    const hideVolume = () => {
        setTimeout(() => {
            setVideoState((prevState) => {
                return { ...prevState, showVolumeSlider: false };
            });
        }, 300);
    };

    const changeVolume = (event: Event, value: number | number[]) => {
        typeof value === 'number' &&
            setVideoState((prevState) => {
                return { ...prevState, volume: value / 100 };
            });
        if (value > 0) {
            setVideoState((prevState) => {
                return { ...prevState, muted: false };
            });
        } else {
            setVideoState((prevState) => {
                return { ...prevState, muted: true };
            });
        }
    };

    const updateVideoProgress = (state: any) => {
        setVideoState((prevState) => {
            return {
                ...prevState,
                playedSeconds: state.playedSeconds,
            };
        });
        onVideoProgress(state.playedSeconds);
    };

    const playCurrent = (event: Event, value: number | number[]) => {
        typeof value === 'number' &&
            setVideoState((prevState) => {
                return {
                    ...prevState,
                    playedSeconds: value,
                };
            });
        playerRef.current.seekTo(value);
    };

    const downloadResponse = (response: ActivityResponseInterface) => {
        const participantName = response.participantName;
        let fileUrl: string;
        let fileName: string;
        if (isJson(response.responseData)) {
            fileUrl = JSON.parse(response.responseData)[0];
        } else fileUrl = response.responseData;
        fileName = `${t(`lang_activity.Video Upload`)}-${participantName}.mp4`;

        const payload = JSON.stringify({
            fileName,
            fileUrl,
        });

        const encodeValue = window.btoa(encodeURIComponent(payload));
        const downloadUrl = `${process.env.REACT_APP_CPDOWNLOAD}/activity-files/${encodeValue}`;
        webviewMessenger.sendOpenBrowser(downloadUrl);
        setIsContextMenuOpen(false);
    };

    const handleDeleteResponse = (choosedResponse: ActivityResponseInterface) => {
        setIsContextMenuOpen(false);
        onDeleteResponse(choosedResponse);
    };

    const getDuration = function (url: any, next: any) {
        const _player = new Audio(url);
        _player.addEventListener(
            'durationchange',
            function (e) {
                if (this.duration !== Infinity) {
                    const duration = this.duration;
                    _player.remove();
                    next(duration);
                }
            },
            false,
        );
        _player.load();
        _player.currentTime = 24 * 60 * 60; //fake big time
        _player.volume = 0;
        _player.muted = true;
        _player.play().catch((err) => {
            console.log('err', err);
        });
        //waiting...
    };

    useEffect(() => {
        playerRef.current.seekTo(recordedProgress, 'seconds');
    }, []);

    return (
        <div className={fullScreenMode ? 'video_display_section fullScreenVideo' : 'video_display_section'}>
            <div ref={boxSizeRef} className="big_sh_content">
                {isAnimating && <div className="overlayAnimationGlobal show videoUpload"></div>}
                <AnimatePresence>
                    {isAnimating && (
                        <TickLottie
                            key={choosedResponse.responseId}
                            index={stringToNumber(choosedResponse.responseId)}
                            xPosition={() => findCenter(boxSizeRef).x}
                            yPosition={() => findCenter(boxSizeRef).y}
                            scale="2"
                        />
                    )}
                </AnimatePresence>

                <div
                    className="sh_content_video"
                    onContextMenu={(e) => {
                        e.preventDefault();
                        return false;
                    }}
                >
                    {!isReady && (
                        <div className="video_loader">
                            <Lottie loop={true} animationData={loaderAnimation} play speed={1} />
                        </div>
                    )}
                    {videoPlayError && (
                        <div className="unsupported_video">
                            <Typography variant="body1">{t('lang_activity.err_video_format_not_supported')}</Typography>
                        </div>
                    )}

                    <ReactPlayer
                        url={`${JSON.parse(choosedResponse.responseData)[0]}#t=0.1`}
                        key={''}
                        className={
                            videoState.isInFullScreen
                                ? 'react-player video_upload videoFullView'
                                : `react-player video_upload`
                        }
                        controls={false}
                        playing={videoState.isPlaying}
                        volume={videoState.volume}
                        muted={videoState.muted}
                        progressInterval={100}
                        onProgress={(state) => {
                            updateVideoProgress(state);
                        }}
                        onError={() => setVideoPlayError(true)}
                        onEnded={playPauseVideoBtn}
                        ref={(el) => (playerRef.current = el)}
                        onReady={onReady}
                        onDuration={(time) => {
                            if (time === Infinity) {
                                getDuration(
                                    `${JSON.parse(choosedResponse.responseData)[0]}#t=0.1`,
                                    function (duration: any) {
                                        setVideoState((prevState) => {
                                            return { ...prevState, duration: duration };
                                        });
                                    },
                                );
                            } else
                                setVideoState((prevState) => {
                                    return { ...prevState, duration: time };
                                });
                        }}
                        onClick={playPauseVideo}
                    />
                    <div
                        className={
                            videoState.isPlaying
                                ? play
                                    ? 'center_play show'
                                    : 'center_play'
                                : play
                                ? 'center_play show'
                                : 'center_play'
                        }
                    >
                        {videoState.isPlaying ? <PlayArrowRoundedIcon /> : <PauseIcon />}
                    </div>
                </div>

                <div className={`${videoPlayError && 'hide'} videoControls `}>
                    <div onClick={playPauseVideoBtn} className="centerIcon show">
                        {videoState.isPlaying ? <PauseIcon /> : <PlayArrowRoundedIcon />}
                    </div>
                    <Slider
                        className="slider"
                        onChange={playCurrent}
                        min={0}
                        max={videoState.duration}
                        value={videoState.playedSeconds}
                    />

                    <Typography className="time">
                        {new Date(videoState.playedSeconds * 1000).toISOString().substring(14, 19)} /{' '}
                        {videoState.duration === Infinity
                            ? Infinity
                            : new Date(videoState.duration * 1000).toISOString().substring(14, 19)}
                    </Typography>

                    <div className={isVideoHasSound ? 'volumeSlider' : 'volumeSlider disabled'}>
                        <Slider
                            orientation="vertical"
                            className={`slider`}
                            value={videoState.volume * 100}
                            onChange={changeVolume}
                        />
                        <div onMouseOver={showVolume} onMouseLeave={hideVolume} className="controlIcon">
                            {isVideoHasSound ? (
                                <>
                                    {videoState.muted ? (
                                        <VolumeOffIcon onClick={muteUnmute} />
                                    ) : (
                                        <VolumeUpIcon onClick={muteUnmute} />
                                    )}
                                </>
                            ) : (
                                <VolumeMute />
                            )}
                        </div>
                    </div>

                    <div className="fullScreen">
                        {!fullScreenMode ? (
                            <FullscreenTwoToneIcon
                                onClick={() => {
                                    setFullScreenMode(!fullScreenMode);
                                }}
                                className="controlIcon"
                            />
                        ) : (
                            <FullscreenExitIcon
                                onClick={() => {
                                    setFullScreenMode(!fullScreenMode);
                                }}
                                className="controlIcon"
                            />
                        )}
                    </div>

                    <div className="option">
                        <Button
                            id="basic-button"
                            aria-controls="basic-menu"
                            aria-haspopup="true"
                            aria-expanded="true"
                            onClick={() => setIsContextMenuOpen(!isContextMenuOpen)}
                        >
                            <MoreVertIcon />
                        </Button>

                        <div className="menu_modal">
                            <div
                                className={`menu_overlay ${isContextMenuOpen ? 'active' : ''}`}
                                onClick={() => setIsContextMenuOpen(!isContextMenuOpen)}
                            ></div>
                            <div
                                className={`menu ${isContextMenuOpen ? 'active' : ''}`}
                                style={{
                                    top: '30px',
                                }}
                            >
                                <ul id="listId">
                                    <li>
                                        <Link onClick={() => downloadResponse(choosedResponse)}>
                                            {t('lang_common.btn_download')}
                                        </Link>
                                    </li>
                                    {canEditResponsesInView && (
                                        <li>
                                            <Link onClick={() => handleDeleteResponse(choosedResponse)}>
                                                {t('lang_common.btn_delete')}
                                            </Link>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
