import { useTranslation } from 'react-i18next';
import CommonButton from '../../../components/Common/CommonButton';
import webviewMessenger from '../../../services/webviewMessenger';

export default function RestartActivityButton({ onRestart }: any) {
    const { t } = useTranslation();
    return (
        <CommonButton
            isLoading={false}
            type="button"
            className="default_btn"
            clickFunction={() => {
                webviewMessenger.sendUsageLog(`[S] Clicked "Restart activity" button`);
                onRestart();
            }}
            text={t('lang_activity.restart_activity')}
        />
    );
}
