import { Fragment, useState, useRef } from 'react';
import { TextField, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { utilConstants } from '../../../constants/utils.constants';

interface LevelBoxInterface {
    index: number;
    title: string;
    image: string;
    value: number;
    isEditMode: boolean;
    hasError: boolean;
    canDelete: boolean;
    onChange: (arg1: number, arg2: number) => void;
    onDelete: () => void;
}

function LevelBox({
    index,
    title,
    image,
    value,
    isEditMode,
    hasError,
    canDelete,
    onChange,
    onDelete,
}: LevelBoxInterface) {
    const inputRef = useRef<any>();
    const [isInputActive, setIsInputActive] = useState(false);

    const updateSelectionStart = () => {
        if (typeof value === 'number' && !value) {
            inputRef.current.selectionStart = 0;
        }
    };

    return (
        <Fragment>
            <div className="level_card">
                {canDelete && (
                    <div className="close" onClick={onDelete}>
                        <CancelIcon />
                    </div>
                )}
                <div className="level_title">
                    <Typography variant="h4">{title}</Typography>
                </div>
                <div className="level_icon">
                    <img src={image} alt="Levelicon" />
                </div>
                <div className="level_input">
                    {isEditMode ? (
                        <TextField
                            onSelect={updateSelectionStart}
                            onFocus={() => setIsInputActive(true)}
                            onBlur={() => setIsInputActive(false)}
                            inputRef={inputRef}
                            autoComplete="none"
                            id="outlined-basic"
                            inputProps={{ maxLength: 4 }}
                            value={value > 0 ? value : '0'}
                            onChange={(e) => onChange(index, parseInt(e.target.value))}
                            disabled={!isEditMode}
                            placeholder="00"
                            error={hasError}
                            variant="outlined"
                            className={isInputActive ? '' : 'inactive'}
                        />
                    ) : (
                        <div className="level_star_number">
                            <img src={utilConstants.IMAGE_URLS.starIcon} alt="star" />
                            <p>{value > 0 ? value : '0'}</p>
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    );
}

export default LevelBox;
