import { Fragment, useEffect, useState } from 'react';
import { FitToViewport } from 'react-fit-to-viewport';
// import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import './VideoTutorial.scss';

import { userActions } from '../../actions/user.actions';
import webviewMessenger from '../../services/webviewMessenger';

import StoreInterface from '../../interfaces/store.interface';
import { ViewportSavedClasses } from '../../constants/viewport-constants';

import DisplayVideo from './Components/DisplayVideo';
import VideoList from './Components/VideoList';
import Loading from '../activity/Loading/Loading';
import TutorialLanding from './TutorialLanding';
import i18next from 'i18next';
import { localService } from '../../services/localStorageService';

function VideoTutorial() {
    const dispatch = useDispatch();
    // const history = useHistory();
    // const historyState: any = history.location.state;
    const historyState = useLocation().state;
    const state = useSelector((state: StoreInterface) => state);

    const [showAllVideos, setShowAllVideos] = useState(false);

    const user = state.user;
    const activeVideoId = state.tutorialSettings.activeTutorialVideoId;
    const playInFullscreen: boolean = historyState ? historyState.playInFullscreen : false;
    const isAutoPlay: boolean = historyState ? historyState.isAutoPlay : false;

    useEffect(() => {
        dispatch(userActions.reloadUserReducer());
        i18next.changeLanguage(localService.getLanguage());
        webviewMessenger.sendUsageLog(`Navigated to Tutorial`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!user) return <Loading />;
    return showAllVideos || playInFullscreen ? (
        <Fragment>
            <FitToViewport
                className="viewport"
                width={ViewportSavedClasses.width}
                height={ViewportSavedClasses.height}
                minZoom={ViewportSavedClasses.minZoom}
                maxZoom={ViewportSavedClasses.maxZoom}
            >
                <div className="videoTutorial_sec">
                    <div className="videoTutorial_inner_sec">
                        <div className="left_VcTutorial_sec">
                            <VideoList user={user} activeVideoId={activeVideoId} />
                        </div>
                        <div className="right_VcTutorial_sec">
                            <DisplayVideo user={user} activeVideoId={activeVideoId} isAutoPlay={isAutoPlay} />
                        </div>
                    </div>
                </div>
            </FitToViewport>
        </Fragment>
    ) : (
        <TutorialLanding setShowAllVideos={setShowAllVideos} />
    );
}

export default VideoTutorial;
