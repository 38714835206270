import { combineReducers } from 'redux';
import user from './user.reducer';
import browserTabs from './browser-tabs.reducer';
import connection from './connection.reducer';
import classSession from './classSession.reducer';
import errors from './errors.reducer';
import activity from './activity.reducer';
import pickNamesList from './pickNames.reducer';
import leaderboard from './leaderboard.reducer';
import exportPdf from './export-pdf.reducer';
import timerStopwatch from './timer.reducer';
import tutorialSettings from './tutorial.reducer';
import webviewSettingsEditMode from './webview-settings-edit.reducer';
import webviewSettingsSlideshow from './webview-settings-slideshow.reducer';
import aiQuiz from './aiQuiz.reducer';
import qna from './qna.reducer';

const rootReducer = combineReducers({
    user,
    browserTabs,
    connection,
    classSession,
    activity,
    pickNamesList,
    leaderboard,
    exportPdf,
    timerStopwatch,
    tutorialSettings,
    webviewSettingsEditMode,
    webviewSettingsSlideshow,
    errors,
    aiQuiz,
    qna,
});

export default rootReducer;
