import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FitToViewport } from 'react-fit-to-viewport';
import useSound from 'use-sound';

import Button from '@mui/material/Button';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';

import './NewWelcomeScreen.scss';

import { isCpVerAtLeast } from '../../helpers/utils';
import { getValidUserFromStore } from '../../helpers/storeHelpers';
import webviewMessenger from '../../services/webviewMessenger';

import { authPagesConstants, authPagesVideoPlayerConstants } from '../../constants/viewport-constants';
import { CP_CONTENT_URL, utilConstants } from '../../constants/utils.constants';
import { SoundLibrary } from '../../constants/sound-library';

import EmptyModal from '../EmptyModal/EmptyModal';
import { FullScreenPointLottie } from '../../components/Common/AnimationModules';
import Loading from '../activity/Loading/Loading';
import VideoModal from '../VideoTutorial/Components/VideoModal';

export default function NewWelcomeScreen() {
    const { t } = useTranslation();
    const user = getValidUserFromStore('WelcomeScreen');

    const givePointsConfettiRef = useRef<any>();

    const [showEmptyModal, setShowEmptyModal] = useState(false);
    const [tutorialVideoId, setTutorialVideoId] = useState('');

    const [playSound] = useSound(SoundLibrary.GIVE_POINT);

    useEffect(() => {
        setTimeout(() => playSound(), 100);
    }, [playSound]);

    useEffect(() => {
        setShowEmptyModal(true);
        setTimeout(() => setShowEmptyModal(false), 1);
        givePointsConfettiRef.current.playLottieAnimation(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!user) return <Loading />;
    return (
        <FitToViewport
            className="viewport"
            width={authPagesConstants.width}
            height={authPagesConstants.height}
            minZoom={authPagesConstants.minZoom}
            maxZoom={authPagesConstants.maxZoom}
        >
            {showEmptyModal && <EmptyModal viewportConfig={authPagesConstants} />}

            <FullScreenPointLottie ref={givePointsConfettiRef} />

            {tutorialVideoId && (
                <VideoModal
                    tutorialVideoId={tutorialVideoId}
                    setTutorialVideoId={setTutorialVideoId}
                    viewportConstant={authPagesVideoPlayerConstants}
                    isFullScreenHidden={true}
                    isSubtitlesHidden={true}
                />
            )}

            <div className="welcomeScreen">
                <div className={`topWave`}>
                    <img src={utilConstants.WELCOME_SCREEN.TOP_WAVE} className={`image`} alt="ClassPoint" />
                </div>
                <div className={`bottomWave`}>
                    <img src={utilConstants.WELCOME_SCREEN.BOTTOM_WAVE} className={`image`} alt="ClassPoint" />
                </div>

                <div className={`logoContainer`}>
                    <img src={utilConstants.WELCOME_SCREEN.CP_FULL_LOGO} className={`logo`} alt="ClassPoint" />
                </div>
                <div className={`detailsContainer`}>
                    <h1 className={`heading`}>{t('lang_new_welcome.welcome_to_classpoint')}</h1>
                    <p className={`details`}>{t('lang_new_welcome.supercharge')}</p>

                    <div
                        onClick={() => {
                            webviewMessenger.sendUsageLog(`Clicked Sara's welcome video`);
                            setTutorialVideoId('welcome');
                        }}
                        className="videoContainer"
                    >
                        <img
                            src={`${CP_CONTENT_URL}/tutorial/welcome_thumb.webp`}
                            alt="Welcome to ClassPoint"
                            className={`videoThumbnail`}
                        />
                        <div className={`overlay`}>
                            <PlayArrowRoundedIcon className={`playIcon`} />
                        </div>
                    </div>

                    {isCpVerAtLeast('2.0.39') && (
                        <div className={`btnContainer`}>
                            <Button
                                onClick={() => {
                                    webviewMessenger.sendUsageLog(`Clicked "Try ClassPoint in slideshow"`);
                                    if (isCpVerAtLeast('2.2.3')) webviewMessenger.enterSlideshow('skipAddingSlide');
                                    else webviewMessenger.enterSlideshow(null);
                                }}
                                className="btn"
                            >
                                <img
                                    src={utilConstants.WELCOME_SCREEN.SLIDESHOW_ICON}
                                    className={`icon`}
                                    alt="slideshow"
                                />
                                {t(`lang_new_welcome.start_slideshow`)}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </FitToViewport>
    );
}
