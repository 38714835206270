import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FitToViewport } from 'react-fit-to-viewport';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import DoneIcon from '@mui/icons-material/Done';
import TextField from '@mui/material/TextField';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import './OptionModal.scss';

// import apiPreferences from '../../../services/apiPreferences';
import apiPreferences from '../../../services/apiPreferences';
import { getValidUserFromStore } from '../../../helpers/storeHelpers';
import { QT, BT } from '../../../helpers/aiQuiz.helper';

import { ViewportConstants } from '../../../constants/viewport-constants';
import { utilConstants } from '../../../constants/utils.constants';
import { AiLanguages } from '../../../constants/ai-languages';

import Button from '@mui/material/Button';
// import CommonButton from '../../../components/Common/CommonButton';

interface questionTypeArrayInterface {
    type: QT;
    imgSrc: string;
    alt: string;
    itemName: string;
}

interface btLevelArrayInterface {
    type: BT;
}

interface LiveStatusInterface {
    openLiveStatusModal: boolean;
    questionTypes: QT[];
    setQuestionTypes: (arg: QT[]) => void;
    btLevels: BT[];
    setBtLevels: (arg: BT[]) => void;
    questionLanguage: string;
    setQuestionLanguage: (arg: string) => void;
    handleClose: () => void;
}

const OptionModal = ({
    openLiveStatusModal,
    questionTypes,
    setQuestionTypes,
    btLevels,
    setBtLevels,
    questionLanguage,
    setQuestionLanguage,
    handleClose,
}: LiveStatusInterface) => {
    const { t } = useTranslation();
    const user = getValidUserFromStore('OptionModal');

    const [hasMadeOptionChanges, setHasMadeOptionChanges] = useState(false);
    const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
    const [languageSearchInput, setLanguageSearchInput] = useState<string | undefined>(undefined);
    const [filteredLanguageLabels, setFilteredLanguageLabels] = useState(AiLanguages.map((data) => data.label));

    const questionTypeArray: questionTypeArrayInterface[] = [
        {
            type: QT.MC,
            imgSrc: utilConstants.CHATBOT.MULTIPLE_CHOICE,
            alt: 'MULTIPLE_CHOICE',
            itemName: `${t(`lang_activity.Multiple Choice`)}`,
        },
        {
            type: QT.FB,
            imgSrc: utilConstants.CHATBOT.FILL_BLANK,
            alt: 'FILL_BLANK',
            itemName: `${t(`lang_activity.Fill in the Blanks`)}`,
        },
        {
            type: QT.SA,
            imgSrc: utilConstants.CHATBOT.SHORT_ANSWER,
            alt: 'SHORT_ANSWER',
            itemName: `${t(`lang_activity.Short Answer`)}`,
        },
    ];

    const btLevelArray: btLevelArrayInterface[] = [
        { type: BT.REMEMBER },
        { type: BT.UNDERSTAND },
        { type: BT.APPLY },
        { type: BT.ANALYZE },
        { type: BT.EVALUATE },
        { type: BT.CREATE },
    ];

    const isQuestionTypeSelected = (type: QT) => questionTypes.includes(type);
    const isBtLevelSelected = (level: BT) => btLevels.includes(level);

    const toggleQuestionType = (type: QT) => {
        setHasMadeOptionChanges(true);

        if (questionTypes.includes(type)) {
            if (questionTypes.length === 1) return;
            const newQuestionTypes = questionTypes.filter((item) => item !== type);
            setQuestionTypes(newQuestionTypes);
        } else {
            const newQuestionTypes = [...questionTypes, type];
            setQuestionTypes(newQuestionTypes);
        }
    };

    const toggleBtLevel = (level: BT) => {
        setHasMadeOptionChanges(true);

        if (btLevels.includes(level)) {
            if (btLevels.length === 1) return;
            const newBtLevels = btLevels.filter((item) => item !== level);
            setBtLevels(newBtLevels);
        } else {
            const newBtLevels = [...btLevels, level];
            setBtLevels(newBtLevels);
        }
    };

    const onLanguageInputClick = () => {
        setIsLanguageDropdownOpen(true);
        const inputElement = document.getElementById('languageInput') as HTMLInputElement;
        inputElement.select();
    };

    const selectLanguageHandler = (label: string) => {
        if (label !== questionLanguage) {
            setQuestionLanguage(label);
            setHasMadeOptionChanges(true);
        }
        closeLanguageDropdownHandler();
    };

    const closeLanguageDropdownHandler = () => {
        setLanguageSearchInput(undefined);
        setFilteredLanguageLabels(AiLanguages.map((data) => data.label));
        setIsLanguageDropdownOpen(false);
    };

    useEffect(() => {
        if (languageSearchInput === undefined) return;
        !isLanguageDropdownOpen && setIsLanguageDropdownOpen(true);
        setFilteredLanguageLabels(
            AiLanguages.map((lang) => lang.label).filter((label) =>
                label.toLowerCase().includes(languageSearchInput.toLowerCase()),
            ),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languageSearchInput]);

    const modalCloseHandler = () => {
        if (hasMadeOptionChanges) {
            const selectedLanguageValue = AiLanguages.find((data) => data.label === questionLanguage)?.value;
            apiPreferences.updateAiQuizSettings(user.email, {
                questionTypes: questionTypes,
                btLevels: btLevels,
                questionLanguage: selectedLanguageValue,
            });
        }
        closeLanguageDropdownHandler();
        handleClose();
    };

    return (
        <Fragment>
            <Modal
                open={openLiveStatusModal}
                onClose={modalCloseHandler}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="dailog_modal"
            >
                <div>
                    <FitToViewport
                        className="modal_viewport"
                        width={ViewportConstants.width}
                        height={ViewportConstants.height}
                        minZoom={ViewportConstants.minZoom}
                        maxZoom={ViewportConstants.maxZoom}
                    >
                        <Fragment>
                            <div className="close_modal" onClick={() => modalCloseHandler()}></div>
                            <div className="option_modal_box">
                                <Box className="option_modal">
                                    <div className="option_heading">
                                        <h4 className="head">{t(`lang_ai_quiz.option_modal.options`)}</h4>
                                    </div>
                                    <div className="option_body">
                                        <div className="qs_type">
                                            <h4 className="title">{t(`lang_ai_quiz.option_modal.question_type`)}</h4>
                                            <div className="qs_type_chip_wrapper">
                                                {questionTypeArray.map((data, index) => (
                                                    <div
                                                        key={index}
                                                        className={`qs_type_chip ${
                                                            questionTypes.length === 1 &&
                                                            questionTypes.includes(data.type) &&
                                                            'notClickable'
                                                        } ${isQuestionTypeSelected(data.type) && 'active'}`}
                                                        onClick={() => toggleQuestionType(data.type)}
                                                    >
                                                        <img src={data.imgSrc} alt={data.alt} />
                                                        <p className="item_name">{data.itemName}</p>
                                                        <div className="check">
                                                            <DoneIcon />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        <div className="bloom_level">
                                            <h4 className="title">
                                                {t(`lang_ai_quiz.option_modal.taxonomy_levels`)}{' '}
                                                <img src={utilConstants.CHATBOT.PYRAMID} alt="PYRAMID" />
                                            </h4>
                                            <div className="bloom_level_chip_wrapper">
                                                {btLevelArray.map((data, index) => (
                                                    <div
                                                        key={index}
                                                        className={`bloom_level_chip ${
                                                            btLevels.length === 1 &&
                                                            btLevels.includes(data.type) &&
                                                            'notClickable'
                                                        } ${isBtLevelSelected(data.type) && 'active'}`}
                                                        onClick={() => toggleBtLevel(data.type)}
                                                    >
                                                        <div className="check">
                                                            <DoneIcon />
                                                        </div>
                                                        <p className="item_name">{data.type}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        {isLanguageDropdownOpen && (
                                            <div
                                                onClick={() => closeLanguageDropdownHandler()}
                                                className={`languageOverlay`}
                                            ></div>
                                        )}
                                        <div className="language_change">
                                            <h4 className="title">{t(`lang_ai_quiz.option_modal.language`)}</h4>
                                            <div className="language_change_wrapper">
                                                <TextField
                                                    value={
                                                        languageSearchInput === undefined
                                                            ? questionLanguage
                                                            : languageSearchInput
                                                    }
                                                    onChange={(event) => setLanguageSearchInput(event.target.value)}
                                                    onClick={() => onLanguageInputClick()}
                                                    autoComplete="off"
                                                    id="languageInput"
                                                    className="inputField"
                                                    variant="outlined"
                                                />
                                                <div className={`searchArrow ${isLanguageDropdownOpen && 'open'}`}>
                                                    <ArrowDropDownIcon
                                                        onClick={() =>
                                                            isLanguageDropdownOpen
                                                                ? setIsLanguageDropdownOpen(false)
                                                                : onLanguageInputClick()
                                                        }
                                                    />
                                                </div>
                                                {isLanguageDropdownOpen && (
                                                    <div className={`dropdownContainer`}>
                                                        {filteredLanguageLabels.map((label, index) => (
                                                            <Button
                                                                key={index}
                                                                variant="text"
                                                                className={`listItem ${
                                                                    label === questionLanguage && 'active'
                                                                }`}
                                                                onClick={() => selectLanguageHandler(label)}
                                                            >
                                                                {label}
                                                            </Button>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                            </div>
                        </Fragment>
                    </FitToViewport>
                </div>
            </Modal>
        </Fragment>
    );
};

export default OptionModal;
