import { sentryLogComponentError } from './../services/sentryService';
import ActivityInterface from '../interfaces/activity.interface';
import ClassSessionInterface from '../interfaces/classSessionInterface';
import UserInterface from '../interfaces/user-interface';
import { store } from './store';
import { ReducerName } from '../constants/reducer-name.enum';

export const getValidUserFromStore = (caller: string) => {
    const user: UserInterface | null = store.getState().user;
    if (user) return user;

    const cachedUser: UserInterface | null = getCachedReducer(ReducerName.user);
    if (cachedUser) return cachedUser;

    sentryLogComponentError('getValidUserFromStore failed [after retry]', 'getValidUserFromStore', {
        page: window.location.pathname,
        caller,
        localStorageValue: localStorage.getItem(ReducerName.user) || 'null',
    });
    throw Error('Error boundary: getValidUserFromStore');
};

export const getValidClassSessionFromStore = (caller: string) => {
    const classSession: ClassSessionInterface | null = store.getState().classSession;
    if (classSession) return classSession;

    const cachedClassSession: ClassSessionInterface | null = getCachedReducer(ReducerName.classSession);
    if (cachedClassSession) return cachedClassSession;

    sentryLogComponentError('getValidClassSessionFromStore failed [after retry]', 'getValidClassSessionFromStore', {
        page: window.location.pathname,
        caller,
        localStorageValue: localStorage.getItem(ReducerName.classSession) || 'null',
    });
    throw Error('Error boundary: getValidClassSessionFromStore');
};

export const getActivityFromStore = () => {
    const activity: ActivityInterface = store.getState().activity;
    return activity;
};

export const getBrowserTabsFromStore = () => {
    const browserTabs = store.getState().browserTabs;
    return browserTabs;
};

export const cacheReducer = (reducerName: ReducerName) => {
    const reducer = store.getState()[reducerName];
    localStorage.setItem(reducerName, JSON.stringify(reducer));
};

export const getCachedReducer = (reducerName: ReducerName) => {
    const reducerString = localStorage.getItem(reducerName);
    if (!reducerString || reducerString === 'null' || reducerString === 'undefined') return null;
    return JSON.parse(reducerString);
};
