export const PresetBackgrounds = [
    '/assets/images/backgrounds/01.jpg',
    '/assets/images/backgrounds/02.jpg',
    '/assets/images/backgrounds/03.jpg',
    '/assets/images/backgrounds/04.jpg',
    '/assets/images/backgrounds/05.jpg',
    '/assets/images/backgrounds/06.jpg',
    '/assets/images/backgrounds/07.jpg',
    '/assets/images/backgrounds/08.jpg',
    '/assets/images/backgrounds/09.jpg',
    '/assets/images/backgrounds/10.jpg',
    '/assets/images/backgrounds/11.jpg',
    '/assets/images/backgrounds/12.jpg',
    '/assets/images/backgrounds/13.jpg',
    '/assets/images/backgrounds/14.jpg',
    '/assets/images/backgrounds/15.jpg',
    '/assets/images/backgrounds/16.jpg',
    '/assets/images/backgrounds/17.jpg',
    '/assets/images/backgrounds/18.jpg',
    '/assets/images/backgrounds/19.jpg',
    '/assets/images/backgrounds/20.jpg',
];
