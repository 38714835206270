import { Switch } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { QuizSummaryInterface } from '../../../../helpers/quizSummaryHelper';
import StoreInterface from '../../../../interfaces/store.interface';
import webviewMessenger from '../../../../services/webviewMessenger';
import ExcelSheet from '../../ExcelSheet/ExcelSheet';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

interface QuizSummaryHeaderProps {
    isShowQuizeResponse: boolean;
    setIsShowQuizeResponse: (arg: boolean) => void;
    quizSummary: QuizSummaryInterface | null;
}

const QuizSummaryHeader = ({ isShowQuizeResponse, setIsShowQuizeResponse, quizSummary }: QuizSummaryHeaderProps) => {
    const { t } = useTranslation();
    const classSession = useSelector((state: StoreInterface) => state.classSession);

    const toggleShowQuizResponses = () => {
        webviewMessenger.sendUsageLog(
            `${classSession ? '[S] ' : ''}Toggled ${isShowQuizeResponse ? 'OFF' : 'ON'} show quiz responses`,
        );
        setIsShowQuizeResponse(!isShowQuizeResponse);
    };

    return (
        <Fragment>
            <div className="class_code">
                <Switch onChange={toggleShowQuizResponses} {...label} checked={isShowQuizeResponse} />
                <span className="switchtext" onClick={toggleShowQuizResponses}>
                    {t('lang_activity.show_quiz_responses')}
                </span>
            </div>

            <div className="live_status">{quizSummary && <ExcelSheet quizSummary={quizSummary} />}</div>
        </Fragment>
    );
};

export default QuizSummaryHeader;
