import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import RemoveIcon from '@mui/icons-material/Remove';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';

import { getSavedClassGroupProps } from '../../../../helpers/savedClassGroupHelper';
import webviewMessenger from '../../../../services/webviewMessenger';

import SavedClassParticipantInterface from '../../../../interfaces/saved-class-participant.interface';
import { SavedClassGroupInterface } from '../../../../interfaces/savedClassGroup.interface';
import { utilConstants } from '../../../../constants/utils.constants';

import CustomAvatar, { AvatarType } from '../../../../components/Common/CustomAvatar';

interface ISavedClassParticipantsTableBody {
    isTrialOrPro: boolean;
    isPremiumUser: boolean;
    sortedParticipants: SavedClassParticipantInterface[];
    savedClassGroups: SavedClassGroupInterface[];
    setSelectedParticipant: (arg: SavedClassParticipantInterface) => void;
    setShowDeleteWarning: (arg: boolean) => void;
    setIsRenameModalOpen: (arg: boolean) => void;
    setIsAdjustStarModalOpen: (arg: boolean) => void;
    isViewingGroup?: boolean;
}

export default function SavedClassParticipantsTableBody({
    isTrialOrPro,
    isPremiumUser,
    sortedParticipants,
    savedClassGroups,
    setSelectedParticipant,
    setShowDeleteWarning,
    setIsRenameModalOpen,
    setIsAdjustStarModalOpen,
    isViewingGroup,
}: ISavedClassParticipantsTableBody) {
    const { t } = useTranslation();

    const [selectedModalIndex, setSelectedModalIndex] = useState<number | null>(null);
    const [isClickingLastRow, setIsClickingLastRow] = useState(false);

    const toggleMenu = (participant: SavedClassParticipantInterface, rowIndex: number, event: any) => {
        setSelectedParticipant(participant);
        let tableDiv: any = document.getElementById('inner_table');
        let currentTableY = event.clientY - 40;

        if (currentTableY >= (tableDiv.clientHeight * 60) / 100) {
            setIsClickingLastRow(true);
        } else {
            setIsClickingLastRow(false);
        }

        if (selectedModalIndex !== null) setSelectedModalIndex(null);
        else setSelectedModalIndex(rowIndex);
    };

    const onDeleteParticipant = async () => {
        setSelectedModalIndex(null);
        setShowDeleteWarning(true);
    };

    const getGroupName = (groupId?: number) => {
        const name = getSavedClassGroupProps('groupName', groupId, savedClassGroups);
        if (name === 'cp_ungrouped') return t('lang_grouping.ungrouped');
        return name;
    };

    useEffect(() => {
        setSelectedModalIndex(null);
    }, [sortedParticipants]);

    return (
        <TableBody className={`savedClassParticipantTableBody`}>
            {sortedParticipants.map((participant, index: number) => {
                return (
                    <TableRow
                        key={index}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        className={`savedClassParticipantTableRow`}
                    >
                        <TableCell padding="checkbox">
                            <Typography variant="body1" className="number">
                                {index + 1}
                            </Typography>
                        </TableCell>

                        <TableCell>
                            <div className="table_profile_sec">
                                <div className="profile_img">
                                    <CustomAvatar
                                        avatarName={participant.participantName}
                                        avatarUrl={participant.participantAvatar}
                                        type={AvatarType.PARTICIPANT}
                                        savedClassGroups={savedClassGroups}
                                        groupId={participant.groupId}
                                    />
                                </div>
                                <div className="profile_name">
                                    <Typography variant="h4">{participant.participantName}</Typography>
                                </div>
                            </div>
                        </TableCell>

                        {isPremiumUser && !isViewingGroup && savedClassGroups.length > 0 && (
                            <TableCell>
                                <div className={`groupNameContainer centerAlign`}>
                                    {participant.groupId !== undefined && (
                                        <p
                                            style={{
                                                background: getSavedClassGroupProps(
                                                    'groupColor',
                                                    participant.groupId,
                                                    savedClassGroups,
                                                ),
                                            }}
                                            className={`groupName`}
                                        >
                                            {getGroupName(participant.groupId)}
                                        </p>
                                    )}
                                </div>
                            </TableCell>
                        )}

                        <TableCell>
                            <div className={`starCountCell`}>
                                <img
                                    src={utilConstants.IMAGE_URLS.starIcon}
                                    alt="starIcon"
                                    style={{
                                        height: '14px',
                                        margin: '2px 5px 5px 5px',
                                    }}
                                />
                                <span className={`savedClasses starsCount`}>{participant.participantTotalPoints}</span>
                                <div className={`savedClasses recentlyEarnedStars`}>
                                    (
                                    {participant.recentlyEarned > 0 && (
                                        <Typography variant="body1" className="justify_end plus">
                                            <AddIcon className="plus" />
                                            {participant.recentlyEarned}
                                        </Typography>
                                    )}
                                    {participant.recentlyEarned === 0 && (
                                        <Typography variant="body1" className="justify_end">
                                            <RemoveIcon className="minus" />
                                        </Typography>
                                    )}
                                    {participant.recentlyEarned < 0 && (
                                        <Typography variant="body1" className="justify_end minus">
                                            – {-1 * participant.recentlyEarned}
                                        </Typography>
                                    )}
                                    )
                                </div>
                            </div>
                        </TableCell>

                        <TableCell className="classLevel">
                            <div className={'savedClassLevel centerAlign'}>
                                <img src={participant.participantLevelAndBadge?.badgeUrl} alt="level_icon" />
                            </div>
                        </TableCell>

                        {!isViewingGroup && (
                            <TableCell>
                                <div className="table_edit_icon">
                                    <Typography
                                        variant="caption"
                                        className="option"
                                        onClick={(e) => toggleMenu(participant, index, e)}
                                    >
                                        <MoreVertIcon />
                                    </Typography>

                                    <div className={isClickingLastRow ? 'menu_modal lastRowData' : 'menu_modal'}>
                                        <div
                                            className={
                                                selectedModalIndex === index ? `menu_overlay active` : 'menu_overlay'
                                            }
                                            onClick={(e) => toggleMenu(participant, index, e)}
                                        ></div>

                                        <div className={selectedModalIndex === index ? `menu active` : 'menu'}>
                                            <ul>
                                                {isTrialOrPro && (
                                                    <li>
                                                        <Link
                                                            onClick={() => {
                                                                webviewMessenger.sendUsageLog(`Clicked "Adjust stars"`);
                                                                setSelectedModalIndex(null);
                                                                setIsAdjustStarModalOpen(true);
                                                            }}
                                                        >
                                                            {t('lang_saved_classes.menu_adjust_stars')}
                                                        </Link>
                                                    </li>
                                                )}
                                                <li>
                                                    <Link
                                                        onClick={() => {
                                                            webviewMessenger.sendUsageLog(
                                                                `Clicked "Edit name/avatar (participant)"`,
                                                            );
                                                            setSelectedModalIndex(null);
                                                            setIsRenameModalOpen(true);
                                                        }}
                                                    >
                                                        {t('lang_saved_classes.edit_participant_name_and_avatar')}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        onClick={() => {
                                                            webviewMessenger.sendUsageLog(
                                                                `Clicked "Delete (participant)"`,
                                                            );
                                                            onDeleteParticipant();
                                                        }}
                                                    >
                                                        {t('lang_common.btn_delete')}
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </TableCell>
                        )}
                    </TableRow>
                );
            })}
        </TableBody>
    );
}
