export const getCurrentTime = (): number => {
    let todayDate = new Date();
    return todayDate.getTime();
};

export const getRemainingSeconds = (timerEndTime: number): number => {
    const nowTime = getCurrentTime();
    const remainingSeconds = Math.floor((timerEndTime - nowTime) / 1000);
    return remainingSeconds;
};

export const getPercentageValue = (partialValue: number, totalValue: number) => {
    return (100 * partialValue) / totalValue;
};

export const getProgressColors = (
    remainingSeconds: number,
): { progressedColor: string; backgroundColor: string; className: string } => {
    if (remainingSeconds >= 10)
        return {
            progressedColor: '#6378FF',
            backgroundColor: '#FFDBC7',
            className: 'regular',
        };
    return {
        progressedColor: '#B3261E',
        backgroundColor: '#FFDBC7',
        className: 'danger',
    };
};
