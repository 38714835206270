import { styled } from '@mui/material';
import imageCompression from 'browser-image-compression';
import { Fragment, useEffect, useRef, useState } from 'react';
import ImageEditModal from './ImageEditModal';
import ImageFileTypeWrongAlert from './ImageFileTypeWrongAlert';

export default function ImagePickCropUpload({
    inputfileref,
    isInputDisabled,
    initialImageData,
    savedCrop,
    setSavedCrop,
    outputImageName,
    outputOriginalImageName,
    isImageEditModalOpen,
    setIsImageEditModalOpen,
    onUpdated,
    setImageData,
    handleImageEditCanceled,
    canReset,
}: any) {
    const previewCanvasRef = useRef(null);

    const [isShowImageTypeWrongModal, setIsShowImageTypeWrongModal] = useState(false);

    useEffect(() => {
        setImageData(initialImageData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialImageData]);

    const Input = styled('input')({
        display: 'none',
    });

    const onImageInputChanged = async (event: any) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const isFileImage = file && file['type'].split('/')[0] === 'image';
            if (!isFileImage) return setIsShowImageTypeWrongModal(true);

            setSavedCrop();
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 512,
                useWebWorker: true,
            };
            const compressedFile = await imageCompression(file, options);
            let img = document.createElement('img');
            img.crossOrigin = 'anonymous';
            const reader = new FileReader();
            reader.onloadend = async () => {
                const convertedBlobFile = new File([compressedFile], file.name);
                const compressedFileData = await imageCompression.getDataUrlFromFile(convertedBlobFile);
                setImageData(compressedFileData);
                setIsImageEditModalOpen(true);
            };
            reader.readAsDataURL(compressedFile);
        }
    };

    const chooseAnotherImage = () => {
        inputfileref.current.value = '';
        inputfileref.current.disabled = false;
        inputfileref.current.click();
    };

    return (
        <Fragment>
            <Input
                ref={inputfileref}
                disabled={isInputDisabled}
                accept="image/*"
                id="icon-button-file-saved-class"
                type="file"
                onChange={onImageInputChanged}
            />

            {initialImageData ? (
                <ImageEditModal
                    isModalOpen={isImageEditModalOpen}
                    onModalClose={() => setIsImageEditModalOpen(false)}
                    imageData={initialImageData}
                    savedCrop={savedCrop}
                    previewCanvasRef={previewCanvasRef}
                    onChangeImage={chooseAnotherImage}
                    onUpdated={onUpdated}
                    onCanceled={handleImageEditCanceled}
                    imageName={outputImageName}
                    originalImageName={outputOriginalImageName}
                    canReset={canReset}
                />
            ) : null}

            <ImageFileTypeWrongAlert
                isAlertOpen={isShowImageTypeWrongModal}
                onCloseAlert={() => setIsShowImageTypeWrongModal(false)}
            />
        </Fragment>
    );
}
