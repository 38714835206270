import { getCachedReducer } from '../helpers/storeHelpers';
import { AiQuizInterface } from '../interfaces/aiQuiz.interface';
import { ReducerName } from '../constants/reducer-name.enum';
import { AiQuizActionTypes } from '../constants/ai-quiz-action-types';

export default function aiQuiz(
    state = getCachedAiQuizState(),
    action: { type: string; payload: AiQuizInterface },
): AiQuizInterface {
    switch (action.type) {
        case AiQuizActionTypes.INITIALIZE_AI_QUIZ_FOR_CURRENT_SLIDE:
        case AiQuizActionTypes.UPDATE_ACTIVE_SLIDE_ID_AND_TEXT:
        case AiQuizActionTypes.NEW_QUESTION:
        case AiQuizActionTypes.QUIZ_GENERATION_DONE:
        case AiQuizActionTypes.MARK_VIEWED:
        case AiQuizActionTypes.CHANGE_CURRENT_QUESTION_INDEX:
        case AiQuizActionTypes.SHOW_QUESTION_ANSWER:
        case AiQuizActionTypes.RESET_AI_QUIZ_FOR_CURRENT_SLIDE:
            return action.payload;
        case AiQuizActionTypes.RESET_AI_QUIZ:
            return noAiQuizState;
        default:
            return state;
    }
}

const getCachedAiQuizState = () => {
    const aiQuizCached: AiQuizInterface | null = getCachedReducer(ReducerName.aiQuiz);
    if (aiQuizCached) return aiQuizCached;

    return noAiQuizState;
};

const noAiQuizState: AiQuizInterface = {
    props: {
        activeSlideId: 0,
        activeSlideText: '',
        activeSlideNote: '',
    },
};
