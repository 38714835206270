import { Button } from '@mui/material';
import './CommonButton.scss';

export const OpenProPitchButton = ({ btnLabel, onClickHandler }: { btnLabel: string; onClickHandler: () => void }) => {
    return (
        <Button onClick={onClickHandler} style={{ width: 'min-content' }} className={`trialActivationButton`}>
            {btnLabel}
        </Button>
    );
};
