import { utilConstants } from './utils.constants';

export const ProPitchSlidesData = [
    {
        id: 'summary_go_pro',
        isInSlider: true,
        banner: utilConstants.IMAGE_URLS.summary_go_pro,
    },
    {
        id: 'participant_count',
        isInSlider: true,
        banner: utilConstants.IMAGE_URLS.participant_count,
    },
    {
        id: 'gamification',
        isInSlider: true,
        banner: utilConstants.IMAGE_URLS.accumulated_stars,
    },
    {
        id: 'pro_activity_quiz_mode',
        isInSlider: true,
        banner: utilConstants.IMAGE_URLS.quiz_mode_pro,
    },
    {
        id: 'quota_draggable_objects',
        isInSlider: true,
        banner: utilConstants.IMAGE_URLS.draggable_objects,
    },
    {
        id: 'pro_activity_fib',
        isInSlider: true,
        banner: utilConstants.IMAGE_URLS.pro_activity_fib,
    },
    {
        id: 'pro_activity_ar',
        isInSlider: true,
        banner: utilConstants.IMAGE_URLS.pro_activity_ar,
    },
    {
        id: 'pro_activity_vu',
        isInSlider: true,
        banner: utilConstants.IMAGE_URLS.pro_activity_vu,
    },
    {
        id: 'quota_activity_button',
        isInSlider: false,
        banner: utilConstants.IMAGE_URLS.quota_activity_button,
    },
    {
        id: 'saved_class',
        isInSlider: true,
        banner: utilConstants.IMAGE_URLS.saved_class,
    },
    {
        id: 'total_leaderboard',
        isInSlider: true,
        banner: utilConstants.IMAGE_URLS.total_leaderboard,
    },
    // {
    //     id: 'class_management',
    //     isInSlider: true,
    //     banner: utilConstants.IMAGE_URLS.class_management,
    // },
];
