import { useState } from 'react';

import './Auth.scss';

import {
    CurrentPageType,
    ResetPasswordInterface,
    ForgotPasswordStepType,
} from '../../interfaces/signInSignUp.interface';

import { ForgotPasswordEmail } from './components/ForgotPasswordEmail';
import { ForgotPasswordVerificationCode } from './components/ForgotPasswordVerificationCode';
import { ForgotPasswordReset } from './components/ForgotPasswordReset';
import { ForgotPasswordSuccess } from './components/ForgotPasswordSuccess';

interface SignUpInterface {
    setCurrentPage: (arg: CurrentPageType) => void;
}

export const ForgotPassword = ({ setCurrentPage }: SignUpInterface) => {
    const [forgotPasswordStep, setForgotPasswordStep] = useState<ForgotPasswordStepType>(ForgotPasswordStepType.Email);
    const [forgotPasswordDetails, setForgotPasswordDetails] = useState<ResetPasswordInterface>({
        email: '',
        userADId: '',
        password: '',
    });

    return (
        <div className="innerContainer">
            {forgotPasswordStep === ForgotPasswordStepType.Email && (
                <ForgotPasswordEmail
                    forgotPasswordDetails={forgotPasswordDetails}
                    setForgotPasswordDetails={setForgotPasswordDetails}
                    setForgotPasswordStep={setForgotPasswordStep}
                    setCurrentPage={setCurrentPage}
                />
            )}
            {forgotPasswordStep === ForgotPasswordStepType.VerificationCode && (
                <ForgotPasswordVerificationCode
                    forgotPasswordDetails={forgotPasswordDetails}
                    setForgotPasswordStep={setForgotPasswordStep}
                />
            )}
            {forgotPasswordStep === ForgotPasswordStepType.Password && (
                <ForgotPasswordReset
                    forgotPasswordDetails={forgotPasswordDetails}
                    setForgotPasswordDetails={setForgotPasswordDetails}
                    setForgotPasswordStep={setForgotPasswordStep}
                />
            )}
            {forgotPasswordStep === ForgotPasswordStepType.Success && (
                <ForgotPasswordSuccess forgotPasswordDetails={forgotPasswordDetails} />
            )}
        </div>
    );
};
