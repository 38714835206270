export enum AiQuizActionTypes {
    UPDATE_ACTIVE_SLIDE_ID_AND_TEXT = 'UPDATE_ACTIVE_SLIDE_ID_AND_TEXT',
    INITIALIZE_AI_QUIZ_FOR_CURRENT_SLIDE = 'INITIALIZE_AI_QUIZ_FOR_CURRENT_SLIDE',
    NEW_QUESTION = 'NEW_QUESTION',
    QUIZ_GENERATION_DONE = 'QUIZ_GENERATION_DONE',
    MARK_VIEWED = 'MARK_VIEWED',
    CHANGE_CURRENT_QUESTION_INDEX = 'UPDATE_CURRENT_QUESTION_INDEX',
    SHOW_QUESTION_ANSWER = 'SHOW_QUESTION_ANSWER',
    RESET_AI_QUIZ_FOR_CURRENT_SLIDE = 'RESET_AI_QUIZ_FOR_CURRENT_SLIDE',
    RESET_AI_QUIZ = 'RESET_AI_QUIZ',
}
