import { getActivityFromStore } from '../../helpers/storeHelpers';

import { utilConstants } from '../../constants/utils.constants';
import ActivityResponseInterface from '../../interfaces/activity-response.interface';

import FillBlanksContent from './FillBlanks/FillBlanksContent';
import MultiMediaActivityContent from './MultiMediaActivityContent';
import MultipleChoiceContent from './MultipleChoice/MultipleChoiceContent';
import QuickPollContent from './QuickPoll/QuickPollContent';
import WordCloudContent from './WordCloud/WordCloudContent';

interface ActivityContentInterface {
    isShowCorrectAnswer: boolean;
    isHighlightTopAnswers: boolean;
    selectedResponses: ActivityResponseInterface[];
    searchKeyword: string;
    onSearch: (arg: string) => void;
    isVoting: boolean;
    isVotingNamesHidden: boolean;
    isGivePointsToAll: boolean;
}

const ActivityContent = ({
    isShowCorrectAnswer,
    isHighlightTopAnswers,
    selectedResponses,
    searchKeyword,
    onSearch,
    isVoting,
    isVotingNamesHidden,
    isGivePointsToAll,
}: ActivityContentInterface) => {
    const activity = getActivityFromStore();
    if (!activity.activityProps) return null;

    const activityType = activity.activityProps.activityType;

    switch (activityType) {
        case utilConstants.ACTIVITY_TYPE.MULTIPLE_CHOICE:
            return <MultipleChoiceContent isShowCorrectAnswer={isShowCorrectAnswer} />;
        case utilConstants.ACTIVITY_TYPE.WORD_CLOUD:
            return <WordCloudContent isHighlightTopAnswers={isHighlightTopAnswers} />;
        case utilConstants.ACTIVITY_TYPE.FILL_IN_THE_BLANKS:
            return (
                <FillBlanksContent
                    selectedResponses={selectedResponses}
                    searchKeyword={searchKeyword}
                    onSearch={onSearch}
                    isShowCorrectAnswer={isShowCorrectAnswer}
                />
            );
        case utilConstants.ACTIVITY_TYPE.QUICK_POLL:
            return <QuickPollContent />;
        default:
            return (
                <MultiMediaActivityContent
                    selectedResponses={selectedResponses}
                    searchKeyword={searchKeyword}
                    onSearch={onSearch}
                    isVoting={isVoting}
                    isVotingNamesHidden={isVotingNamesHidden}
                    isGivePointsToAll={isGivePointsToAll}
                />
            );
    }
};

export default ActivityContent;
