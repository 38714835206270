import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { secondsToTimeDisplay } from '../../../helpers/activityHelpers';
import webviewMessenger from '../../../services/webviewMessenger';

const CountUp = () => {
    const [timeLeft, setTimeLeft] = useState(0);
    const activity = useSelector((state: any) => state.activity);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const timeLeft = Math.ceil((+new Date() - activity.activityProps.activityStartTime) / 1000);
            // notify Host to sync time
            webviewMessenger.sendSyncCountdown(timeLeft);
            setTimeLeft(timeLeft);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeLeft, activity]);

    return (
        <>
            <span>
                <TimerOutlinedIcon />
            </span>
            <span>{secondsToTimeDisplay(timeLeft)}</span>
        </>
    );
};

export default CountUp;
