import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// import ReactGA from 'react-ga4';

function GAHistoryTrack() {
    const location = useLocation();
    const pathname = location.pathname;
    const skippedPathnames = ['/loading', '/signin', '/'];

    useEffect(() => {
        if (!skippedPathnames.includes(pathname)) {
            // ReactGA.send({ hitType: 'pageview', page: pathname });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return null;
}

export default GAHistoryTrack;
