import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';

import styles from './NoQuestion.module.scss';

import StoreInterface from '../../../../interfaces/store.interface';

import questionMark from '../../../../assets/animation/questionmark.json';

interface INoQuestion {
    isQnaEnabled: boolean;
}

const NoQuestion = ({ isQnaEnabled }: INoQuestion) => {
    const { t } = useTranslation();

    const { activeTab } = useSelector((state: StoreInterface) => state.qna);

    return (
        <div className={styles.noQuestion}>
            <div className={styles.inner_noQuestion}>
                <div className={styles.icon}>
                    <Lottie loop={false} animationData={questionMark} play speed={2} />
                </div>
                <p className={styles.text}>
                    {activeTab === 'new' ? t(`lang_qna.no_new_question`) : t(`lang_qna.no_answered_question`)}
                </p>
            </div>
        </div>
    );
};

export default NoQuestion;
