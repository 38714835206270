import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AnimatePresence } from 'framer-motion';

import { stringToNumber } from '../../../helpers/utils';
import { findCenter } from '../../../helpers/animationHelpers';
import { activityActions } from '../../../actions/activity.action';

import ActivityResponseInterface from '../../../interfaces/activity-response.interface';
import { AnimationConfig } from '../../../constants/animation-configs';

import VotingCount from '../../../components/Common/VotingCount';
import MultiMediaActivityResponseFooter from '../MultiMediaActivityResponseFooter';
import { TickLottie } from '../../../components/Common/AnimationModules';
import AudioPlayerH5 from './AudioPlayerH5';

type Props = {
    index: number;
    dataReseponse: ActivityResponseInterface;
    color: string;
    searchKeyword: string;
    id: string;
    isVoting: boolean;
    pauseOthers: any;
};

const AudioPlayer = ({ index, dataReseponse, color, searchKeyword, id, isVoting, pauseOthers }: Props) => {
    const dispatch = useDispatch();
    const timeOutPointUpdate = useRef<any>();
    const boxSizeRef = useRef<any>();

    const [pointsAdded, setPointsAdded] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);

    const givePoint = (response: ActivityResponseInterface, points: number) => {
        dispatch(activityActions.handleGivePointsToResponses([{ response, points }]));
    };

    const handleGivePointButtonClick = () => {
        clearTimeout(timeOutPointUpdate.current);
        setIsAnimating(true);
        setPointsAdded(pointsAdded + 1);
    };

    useEffect(() => {
        if (pointsAdded > 0) {
            timeOutPointUpdate.current = setTimeout(() => {
                dataReseponse && givePoint(dataReseponse, pointsAdded);
                setPointsAdded(0);
                setTimeout(() => {
                    setIsAnimating(false);
                }, AnimationConfig.TICK_LOTTIE_DURATION - AnimationConfig.GIVE_POINT_DELAY);
            }, AnimationConfig.GIVE_POINT_DELAY);
        }
        return () => clearTimeout(timeOutPointUpdate.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pointsAdded]);

    return (
        <div key={id} className="slidewrap">
            <div className="sh_box">
                {/* <div ref={boxSizeRef} className="content_box" style={{ order: id, background: color + '33' }}> */}
                <div ref={boxSizeRef} className="content_box" style={{ order: id, background: '#f3f4f6' }}>
                    {isAnimating && <div className="overlayAnimationGlobal show"></div>}
                    <AnimatePresence>
                        {isAnimating && (
                            <TickLottie
                                key={dataReseponse.responseId}
                                index={stringToNumber(dataReseponse.responseId)}
                                xPosition={() => findCenter(boxSizeRef).x}
                                yPosition={() => findCenter(boxSizeRef).y}
                                scale="1.1"
                            />
                        )}
                    </AnimatePresence>

                    {dataReseponse.voterParticipantIds && <VotingCount response={dataReseponse} />}

                    <div className="audio_player">
                        <AudioPlayerH5 id={index} src={dataReseponse.responseData} color={color} />
                    </div>
                </div>

                <MultiMediaActivityResponseFooter
                    response={dataReseponse}
                    responseIndex={0}
                    onResponseIndexDeleted={() => {}}
                    searchKeyword={searchKeyword}
                    isVoting={isVoting}
                    pointsAdded={pointsAdded}
                    onGivePointButtonClick={handleGivePointButtonClick}
                />
            </div>
        </div>
    );
};

export default AudioPlayer;
