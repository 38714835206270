import _ from 'lodash';
import i18next from 'i18next';
import { ThunkDispatch } from 'redux-thunk';

import { store } from './store';
import { userActions } from '../actions/user.actions';
import { classSessionActions } from '../actions/class-session.action';
import webviewMessenger from '../services/webviewMessenger';
import { localService } from '../services/localStorageService';
import apiPreferences from '../services/apiPreferences';

import UserInterface from '../interfaces/user-interface';
import { InterfaceLanguages } from './../constants/languages';
import { HostEventTypes } from './../constants/host-event-types';
import { utilConstants } from '../constants/utils.constants';
import { UserActionTypes } from '../constants/user-action-types';
import { SUBPAGE_TYPE } from '../constants/subscription-subpage-type.enum';
import NewUserDiscountInterface from '../interfaces/new-user-discount.interface';
import { contextualTutorialData } from '../constants/contextual-tutorials';
import { isCpVerAtLeast } from './utils';
import apiClassSessions from '../services/apiClassSessions';
import { getCpcsRegion } from './classSessionHelpers';

export const checkAndUpdateLanguage = (user: UserInterface): void => {
    let updatedLanguage = user.userPreferences?.language;
    const langFound = InterfaceLanguages.findIndex((language) => language.code === updatedLanguage) >= 0;
    if (updatedLanguage) {
        if (langFound) {
            i18next.changeLanguage(updatedLanguage);
            localService.setLanguage(updatedLanguage);
        } else {
            updatedLanguage = 'en';
            apiPreferences.saveLanguage(user.email, updatedLanguage);
        }
        webviewMessenger.sendUpdateLanguage(updatedLanguage);
    } else {
        const currentLanguage = localService.getLanguage();
        apiPreferences.saveLanguage(user.email, currentLanguage);
    }
};

export const handleUserDataUpdated = async (user: UserInterface, updatedUser: UserInterface) => {
    // sync user info with host
    const userToHost = mapUserToSyncWithHost(user);
    const updatedUserToHost = mapUserToSyncWithHost(updatedUser);

    if (!_.isEqual(userToHost, updatedUserToHost)) {
        webviewMessenger.sendUserLoaded(HostEventTypes.USER_UPDATED, updatedUserToHost);
    }

    if (!user) return;

    // handle classLimit change
    if (updatedUser.classLimit !== user.classLimit && !!updatedUser.userClassSession) {
        // console.log(`classLimit changed from ${user.classLimit} to ${updatedUser.classLimit}`);
        apiClassSessions.updateClassLimit(getCpcsRegion(updatedUser), updatedUser.email, updatedUser.classLimit);
    }

    // handle deviceId change
    if (updatedUser?.deviceId !== user.deviceId) {
        if (store.getState().connection) {
            (store.dispatch as ThunkDispatch<any, undefined, any>)(classSessionActions.endSlideshow());
        }
        (store.dispatch as ThunkDispatch<any, undefined, any>)(
            userActions.unloadUser(UserActionTypes.DEVICE_ID_CHANGED, HostEventTypes.KICKED_OUT),
        );
    }
};

export const mapUserToSyncWithHost = (user: UserInterface) => {
    const userToSyncWithHost = {
        email: user.email,
        classLimit: findUserClassLimit(user),
        isOnPro: isUserOnTrialOrPro(user),
        // hasTried: isBasicUserAfterTrial(user),
        hasTried: true,
        userProfile: {
            firstName: user.userProfile.firstName,
            lastName: user.userProfile.lastName,
            avatarUrl: user.userProfile?.avatarUrl || null,
        },
        userSubscription: {
            planType: user.userSubscription.planType,
            isTrial: user.userSubscription.isTrial,
        },
        firstCp2SignIn: user.userStats.firstCp2SignIn,
        userPreferences: user.userPreferences || undefined,
        firstUsageLog: user.userStats.firstUsageLog,
        interactiveQuizOnboarded: checkIfOnboardingHappened(
            user,
            contextualTutorialData.multiple_choice.events.concat(
                contextualTutorialData.word_cloud.events,
                contextualTutorialData.short_answer.events,
                contextualTutorialData.slide_drawing.events,
                contextualTutorialData.image_upload.events,
                contextualTutorialData.fill_blanks.events,
                contextualTutorialData.audio_record.events,
                contextualTutorialData.video_upload.events,
            ),
        ),
        // mcOnboarded: checkIfOnboardingHappened(user, contextualTutorialData.multiple_choice.events),
        // wcOnboarded: checkIfOnboardingHappened(user, contextualTutorialData.word_cloud.events),
        // saOnboarded: checkIfOnboardingHappened(user, contextualTutorialData.short_answer.events),
        // sdOnboarded: checkIfOnboardingHappened(user, contextualTutorialData.slide_drawing.events),
        // iuOnboarded: checkIfOnboardingHappened(user, contextualTutorialData.image_upload.events),
        // fbOnboarded: checkIfOnboardingHappened(user, contextualTutorialData.fill_blanks.events),
        // arOnboarded: checkIfOnboardingHappened(user, contextualTutorialData.audio_record.events),
        // vuOnboarded: checkIfOnboardingHappened(user, contextualTutorialData.video_upload.events),
        mcOnboarded: true,
        wcOnboarded: true,
        saOnboarded: true,
        sdOnboarded: true,
        iuOnboarded: true,
        fbOnboarded: true,
        arOnboarded: true,
        vuOnboarded: true,
        // beforeFirstSlideshow: !checkIfOnboardingHappened(user, contextualTutorialData.slideshow_onboarding.events),
        beforeFirstSlideshow: false,
        // draggableObjectsOnboarded: checkIfOnboardingHappened(user, contextualTutorialData.draggable_objects.events),
        draggableObjectsOnboarded: true,
        isQuizModePro: process.env.REACT_APP_IS_QUIZ_MODE_PRO === 'true',
        shouldShowReferral: canUserReferClassPoint(user),
        isAiQuizOn: true,
        isSaCorrectAnswerOn: process.env.REACT_APP_ENV !== 'production',
        addedFirstActivityButton: user.userStats.addedFirstActivityButton,
        showQnaOnToolbar: determineQnaToggle(user),
        showLibraryIcon: true,
    };
    console.log('userToSyncWithHost', userToSyncWithHost);
    return userToSyncWithHost;
};

export const determineQnaToggle = (user: UserInterface) => {
    if (!user || !isUserOnPremium(user)) return false;
    return true;
};

const checkIfOnboardingHappened = (user: UserInterface, eventArray: string[]): boolean => {
    // show onboarding only if user signed up after 2.2.0 new UI release
    const not220 = !isCpVerAtLeast('2.2.0');
    const oldUserOn220 = new Date(user.userStats.firstCp2SignIn) < new Date('2023-09-01T00:00:00.000Z');
    if (not220 || oldUserOn220) return true;
    const events = user.userOnboarding ? user.userOnboarding.map((entry) => entry.event) : [];
    for (let i = 0; i < eventArray.length; i++) {
        if (events.includes(eventArray[i])) {
            return true;
        }
    }
    return false;
};

export const validateDelay = (responseDuration: number) => {
    const loaderDelay = utilConstants.LOADER_DELAY_MS;
    return responseDuration > loaderDelay ? responseDuration : loaderDelay;
};

export const getRemainingProDays = (planExpiryDate: string | number | Date | undefined): number => {
    const planExpiry = new Date(planExpiryDate || '0000-01-01');

    const now = new Date();
    const remainingDays = Math.ceil((planExpiry.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));

    return Math.max(remainingDays, 0);
};

export const getSubscriptionSubPageType = (user: UserInterface) => {
    const userSubscription = user.userSubscription;
    const planType = userSubscription.planType;

    if (planType === utilConstants.PLAN_TYPE.BASIC) return SUBPAGE_TYPE.BASIC;
    if (planType === utilConstants.PLAN_TYPE.SUBSCRIPTION) return SUBPAGE_TYPE.RECURRING;
    if (userSubscription.isTrial) {
        if (planType === utilConstants.PLAN_TYPE.STANDARD && !userSubscription.trialStarted) return SUBPAGE_TYPE.BASIC;
        else if (planType !== utilConstants.PLAN_TYPE.ORGANIZATION) return SUBPAGE_TYPE.TRIAL;
    }
    return SUBPAGE_TYPE.STANDARD_ORGANIZATION;
};

export const isUserOnTrialOrPro = (user: UserInterface): boolean => {
    const planType = user.userSubscription.planType;
    if (planType === utilConstants.PLAN_TYPE.BASIC) return false;
    if (user.userSubscription.isTrial) {
        if (planType === utilConstants.PLAN_TYPE.STANDARD && !user.userSubscription.trialStarted) return false;
        else return true;
    }
    return true;
};

export const isUserOnPremium = (user: UserInterface): boolean => {
    const isPro = isUserOnTrialOrPro(user);
    if (!isPro) return false;
    return user.userSubscription.isPremium || false;
};

const isUserOnPro = (user: UserInterface): boolean => {
    const planType = user.userSubscription.planType;
    if (planType === utilConstants.PLAN_TYPE.BASIC) return false;
    if (user.userSubscription.isTrial) return false;
    return true;
};

export const canUserReferClassPoint = (user: UserInterface): boolean => {
    const planType = user.userSubscription.planType;
    if (planType === utilConstants.PLAN_TYPE.SUBSCRIPTION) {
        if (!user.userSubscription.isCancelSubscriptionScheduled) return true;
    }
    if (planType === utilConstants.PLAN_TYPE.STANDARD && !user.userSubscription.isTrial) return true;
    return false;
};

export const isUserOnCp2Trial = (user: UserInterface): boolean => {
    if (user.userSubscription.isTrial && user.userSubscription.trialStarted) return true;
    else return false;
};

export const findUserClassLimit = (user: UserInterface) => {
    return user.classLimit;
};

export const calculateParticipantLevelAndBadge = (
    participantSumPoints: number,
    levelsArray: number[],
    useSvg = false,
) => {
    if (!levelsArray) levelsArray = utilConstants.DEFAULT_LEVELS_ARRAY;
    for (let index = 0; index < levelsArray.length; index++) {
        if (participantSumPoints < levelsArray[index]) {
            return {
                level: index,
                badgeUrl: useSvg ? `/assets/levels/level_${index}.svg` : `/assets/levels/badge_v1_${index}.png`,
            };
        }
    }
    return {
        level: levelsArray.length,
        badgeUrl: useSvg
            ? `/assets/levels/level_${levelsArray.length}.svg`
            : `/assets/levels/badge_v1_${levelsArray.length}.png`,
    };
};

export const getLevelsArray = (user: UserInterface): number[] => {
    const levelsArray = user.userPreferences?.customizedLevels
        ? user.userPreferences.customizedLevels.split(',').map((level) => parseInt(level))
        : utilConstants.DEFAULT_LEVELS_ARRAY;
    return levelsArray;
};

export const checkIfCanInspect = (user: UserInterface | null): boolean => {
    const isLocalhost = window.location.hostname === 'localhost';
    const isNotProd = process.env.REACT_APP_ENV !== 'production';
    if (isLocalhost || isNotProd) return true;

    const isInknoe = user?.email.endsWith('@inknoe.com') || false;
    return isInknoe;
};

export const determineChipClassName = (user: UserInterface) => {
    if (user.userSubscription.planType === utilConstants.PLAN_TYPE.BASIC) return 'basic';
    if (user.userSubscription.isPremium) return 'premium';
    if (user.userSubscription.isTrial) {
        if (user.userSubscription.planType === utilConstants.PLAN_TYPE.STANDARD && !user.userSubscription.trialStarted)
            return 'basic';
        else return 'trial';
    }
    return 'pro';
};

export const determinePlanDisplay = (user: UserInterface) => {
    if (user.userSubscription.planType === utilConstants.PLAN_TYPE.BASIC) return 'BASIC';
    if (user.userSubscription.isPremium) return 'PREMIUM';
    if (user.userSubscription.isTrial) {
        // ignore isTrial property in cp2, if user applied trial key in cp1
        if (user.userSubscription.planType === utilConstants.PLAN_TYPE.STANDARD && !user.userSubscription.trialStarted)
            return 'BASIC';
        else return 'TRIAL';
    }
    return 'PRO';
};

export const isDotnetLangOn = (email: string) => {
    if (isInknoeUser(email)) return true;
    return process.env.REACT_APP_DOTNETLANG === 'on';
};

export const isInknoeUser = (email: string) => {
    return email.endsWith('inknoe.com');
};

export const isSlidesTemplateManager = (email: string) => {
    const emails = ['john.yan@inknoe.com', 'maria.stephanie@inknoe.com', 'subhajit@inknoe.com'];
    return emails.includes(email);
};

export const getNewUserDiscount = (user: UserInterface): NewUserDiscountInterface | null => {
    if (isUserOnPro(user)) return null;
    if (!user.userSubscription.userSubscribePrice.newUserDiscount) return null;

    const discountPercentage = Math.floor(user.userSubscription.userSubscribePrice.newUserDiscount * 100);
    const daysLeft = user.userSubscription.userSubscribePrice.newUserDaysLeft || 0;
    return { discountPercentage, daysLeft };
};

export const displayNewUserPrice = (user: UserInterface) => {
    const originalPrice = user.userSubscription.userSubscribePrice.priceAnnual;
    const discountRate = user.userSubscription.userSubscribePrice.newUserDiscount;
    if (!discountRate) return originalPrice;

    const discountedPrice = originalPrice * (1 - discountRate);
    const firstDecimalPrice = Math.round(discountedPrice * 10) / 10;
    return firstDecimalPrice;
};

export const getShopeeIdCoupon = () => {
    // const yyyymm = new Date().toISOString().slice(0, 7).replace('-', ''); // '202304'
    // const convertedNumber = parseInt(yyyymm) * 7;
    // logCoupons();
    const convertedNumber = getCurrentWeekNumber() * 500;
    const suffix = convertedNumber.toString(36).toUpperCase();
    return 'BORA' + suffix;
};

const getCurrentWeekNumber = () => {
    // Get the date of first day in UTC
    const firstDayOfYear = new Date(Date.UTC(new Date().getUTCFullYear(), 0, 1));
    // console.log('firstDayOfYear', firstDayOfYear);

    // Get the number of the first day of the year (0-6)
    const firstDayInWeek = firstDayOfYear.getUTCDay();
    // console.log('firstDayInWeek', firstDayInWeek);

    // Calculate the number of days before the first week starts
    const daysBeforeFirstWeek = (7 - firstDayInWeek) % 7;
    // console.log('daysBeforeFirstWeek', daysBeforeFirstWeek);

    // Calculate the number of days since the first day of the year
    const daysSinceFirstDay = Math.floor((Date.now() - firstDayOfYear.getTime()) / (24 * 60 * 60 * 1000));
    // console.log('daysSinceFirstDay', daysSinceFirstDay);

    // Calculate the current week number
    const currentWeekNumber = Math.floor((daysSinceFirstDay - daysBeforeFirstWeek) / 7) + 1;
    // console.log('currentWeekNumber', currentWeekNumber);

    return currentWeekNumber;
};

// const logCoupons = () => {
//     let result = '';
//     for (let i = 17; i <= 52; i++) {
//         const convertedNumber = i * 500;
//         const suffix = convertedNumber.toString(36).toUpperCase();
//         const coupon = 'BORA' + suffix;
//         result += `Week ${i} (${getDateOfFirstDayOfWeek(2023, i)}): ${coupon}\n`;
//     }
//     console.log(result);
// };

// const getDateOfFirstDayOfWeek = (year: number, weekNumber: number) => {
//     // Create a new date object for January 1st of the given year
//     const firstDayOfYear = new Date(Date.UTC(new Date().getUTCFullYear(), 0, 1));

//     // Get the number of the first day of the year (0-6)
//     const firstDayInWeek = firstDayOfYear.getUTCDay();
//     // console.log('firstDayInWeek', firstDayInWeek);

//     // Calculate the number of days before the first week starts
//     const daysBeforeFirstWeek = (7 - firstDayInWeek) % 7;
//     // console.log('daysBeforeFirstWeek', daysBeforeFirstWeek);

//     // Calculate the number of days to the start of the requested week (assuming Sunday as the first day of the week)
//     const daysToStartOfWeek = (weekNumber - 1) * 7 + daysBeforeFirstWeek;

//     // Calculate the date of the first day of the requested week
//     const firstDayOfWeek = new Date(year, 0, daysToStartOfWeek + 1);

//     // Format the date as YYYY-mm-dd
//     const yearString = firstDayOfWeek.getFullYear().toString();
//     const monthString = (firstDayOfWeek.getMonth() + 1).toString().padStart(2, '0');
//     const dayString = firstDayOfWeek.getDate().toString().padStart(2, '0');
//     const formattedDate = `${yearString}-${monthString}-${dayString}`;

//     return formattedDate;
// };

// const canAccessAi = (user: UserInterface) => {
//     if (checkIfCanInspect(user)) return true;
//     // if (!isUserOnPro(user)) return false;
//     return user.canAccessAi;
// };

export const showJobRoleInSignUp = (countryCode: string) => {
    switch (countryCode) {
        case 'SG':
        case 'US':
            return true;
        case 'MY':
        case 'VN':
        case 'PH':
        case 'TH':
        case 'ID':
            return Math.random() > 0.5;
        default:
            return true;
    }
};
