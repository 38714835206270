import { Fragment, useState } from 'react';
import { FitToViewport } from 'react-fit-to-viewport';

import { Box, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useActiveVideoInfo from '../Common/useActiveVideoInfo';
import TutorialVideoPlayer from './TutorialVideoPlayer';
import { getValidUserFromStore } from '../../../helpers/storeHelpers';
import webviewMessenger from '../../../services/webviewMessenger';

interface ViewportConstantInterface {
    width: number;
    height: number;
    minZoom: number;
    maxZoom: number;
}
interface VideoModalInterface {
    tutorialVideoId: string;
    setTutorialVideoId: (arg: string) => void;
    viewportConstant: ViewportConstantInterface;
    isFullScreenHidden?: boolean;
    isSubtitlesHidden?: boolean;
}

function VideoModal({
    tutorialVideoId,
    setTutorialVideoId,
    viewportConstant,
    isFullScreenHidden,
    isSubtitlesHidden,
}: VideoModalInterface) {
    const user = getValidUserFromStore('VideoModal');
    const [videoInfo, videoDuration] = useActiveVideoInfo(tutorialVideoId);
    const [isFullscreen, setIsFullscreen] = useState(false);

    const onCloseAlert = () => {
        webviewMessenger.sendUsageLog(`Closed video tutorial modal`);
        setTutorialVideoId('');
    };

    return (
        <Fragment>
            {videoInfo && (
                <Modal
                    open={!!tutorialVideoId}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className={isFullscreen ? 'mymodal fullScreenVideo' : 'mymodal'}
                    onClose={onCloseAlert}
                >
                    <Fragment>
                        <FitToViewport
                            className={'modal_viewport '}
                            width={viewportConstant.width}
                            height={viewportConstant.height}
                            minZoom={viewportConstant.minZoom}
                            maxZoom={viewportConstant.maxZoom}
                        >
                            <Box className={'modal_box video_tutorial_modal_box'}>
                                <div className="close">
                                    <CloseIcon onClick={onCloseAlert} />
                                </div>
                                <TutorialVideoPlayer
                                    user={user}
                                    activeVideoId={tutorialVideoId}
                                    videoInfo={videoInfo}
                                    videoDuration={videoDuration}
                                    isFullscreen={isFullscreen}
                                    setIsFullscreen={setIsFullscreen}
                                    isFullScreenHidden={isFullScreenHidden}
                                    isAutoPlay={true}
                                    isSubtitlesHidden={isSubtitlesHidden}
                                />
                            </Box>
                        </FitToViewport>
                    </Fragment>
                </Modal>
            )}
        </Fragment>
    );
}

export default VideoModal;
