import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';

import { getActivityFromStore, getValidUserFromStore } from '../../../helpers/storeHelpers';
import { canEditResponses } from '../../../helpers/activityHelpers';
import { isUserOnTrialOrPro } from '../../../helpers/userhelper';
import webviewMessenger from '../../../services/webviewMessenger';
import { activityActions } from '../../../actions/activity.action';

import { AnimationConfig } from '../../../constants/animation-configs';
import { utilConstants } from '../../../constants/utils.constants';
import { AlertTypes } from '../../../constants/alert-types-enum';

import CommonButton from '../../../components/Common/CommonButton';
import GivePointButton from '../../../components/Common/GivePointButton';
import ResetActivityButton from '../activityButtons/ResetActivityButton';
import RestartActivityButton from '../activityButtons/RestartActivityButton';

import { ProIcon } from '../../../Icon';

export default function ActivityFooterViewInSlideshow({
    selectedResponsesCount,
    onRestartActivity,
    onGivePointsToAll,
    setIsGivePointsToAll,
    givePointsConfettiRef,
    setAlertType,
}: any) {
    const isQuizModeProFeature = process.env.REACT_APP_IS_QUIZ_MODE_PRO === 'true';
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = getValidUserFromStore('ActivityFooterViewInSlideshow');
    const shouldShowQuizAsPro = !isUserOnTrialOrPro(user) && isQuizModeProFeature;
    const activity = getActivityFromStore();
    const canEditResponsesInView = canEditResponses(activity, user.email);
    const timeOutPointUpdate = useRef<any>();
    const [pointsAdded, setPointsAdded] = useState(0);
    const { t } = useTranslation();

    const openQuizSummary = () => {
        webviewMessenger.sendUsageLog(`[S] Clicked "Quiz summary" button from activity footer`);
        dispatch(activityActions.endActivity(true, true));
        navigate('/quizsummary');
    };

    const givePointHandler = () => {
        webviewMessenger.sendUsageLog(`[S] Awarded stars to all from activity footer in PPT slideshow mode`);
        clearTimeout(timeOutPointUpdate.current);
        setIsGivePointsToAll(true);
        givePointsConfettiRef.current.playLottieAnimation(true);
        setPointsAdded(pointsAdded + 1);
    };

    useEffect(() => {
        if (pointsAdded > 0) {
            timeOutPointUpdate.current = setTimeout(() => {
                onGivePointsToAll(pointsAdded);
                setPointsAdded(0);
                setTimeout(() => {
                    setIsGivePointsToAll(false);
                    if (givePointsConfettiRef.current) givePointsConfettiRef.current.playLottieAnimation(false); // possibly dialog is close when animation completes
                }, AnimationConfig.CONFETTI_LOTTIE_DURATION - AnimationConfig.GIVE_POINT_DELAY);
            }, AnimationConfig.GIVE_POINT_DELAY);
        }
        return () => clearTimeout(timeOutPointUpdate.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pointsAdded]);

    useEffect(() => {
        return () => setIsGivePointsToAll(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            {activity.activityUser && user.email !== activity.activityUser.email ? (
                <Fragment>
                    {t('lang_activity.you_are_viewing_others_activity', {
                        firstName: activity.activityUser.firstName,
                        lastName: activity.activityUser.lastName,
                    })}
                    <ResetActivityButton activityId={activity.activityProps?.activityId} />
                </Fragment>
            ) : (
                <Fragment>
                    {activity.activityResponses.length > 0 ? (
                        <Fragment>
                            <div className="footer_left">
                                <RestartActivityButton onRestart={onRestartActivity} />
                            </div>

                            <div className="footer_middle">
                                {activity.activityProps && (
                                    <>
                                        {activity.activityProps.activityType ===
                                            utilConstants.ACTIVITY_TYPE.MULTIPLE_CHOICE &&
                                        activity.activityProps.isQuizMode ? (
                                            <CommonButton
                                                isLoading={false}
                                                variant="contained"
                                                className="foot_btn_blue"
                                                type="button"
                                                startIcon={
                                                    shouldShowQuizAsPro ? <ProIcon /> : <FormatListNumberedRtlIcon />
                                                }
                                                clickFunction={() => {
                                                    if (shouldShowQuizAsPro) setAlertType(AlertTypes.QUIZ_MODE_PRO);
                                                    else openQuizSummary();
                                                }}
                                                text={`${t('lang_activity.quiz_summary')}`}
                                            />
                                        ) : (
                                            <Fragment>
                                                {canEditResponsesInView && (
                                                    <GivePointButton
                                                        activity=""
                                                        onClick={() => givePointHandler()}
                                                        label={t('lang_common.btn_give_points_to_all')}
                                                    />
                                                )}
                                            </Fragment>
                                        )}
                                    </>
                                )}
                            </div>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className="footer_middle">
                                <RestartActivityButton onRestart={() => dispatch(activityActions.restartActivity())} />
                            </div>
                        </Fragment>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
}
