import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, Typography } from '@mui/material';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import TimerCountDown from './TimerCountDown';
import StoreInterface from '../../../interfaces/store.interface';

interface TimerRunningInterface {
    isFullScreen: boolean;
    playPauseTimer: () => void;
    resetTimer: () => void;
    exitFullScreen: () => void;
    enterFullScreen: () => void;
    setIsTimesup: (args: boolean) => void;
    setShowTab: (args: boolean) => void;
    fullScreenBtnRef: any;
    currentTimeRef: any;
    isPlaySound: boolean;
    setIsPlaySound: (args: boolean) => void;
}

const TimerRunning = ({
    isFullScreen,
    playPauseTimer,
    resetTimer,
    exitFullScreen,
    enterFullScreen,
    setIsTimesup,
    setShowTab,
    fullScreenBtnRef,
    currentTimeRef,
    isPlaySound,
    setIsPlaySound,
}: TimerRunningInterface) => {
    const { t } = useTranslation();
    const timerStopwatch = useSelector((state: StoreInterface) => state.timerStopwatch);

    const resetTimerHandler = () => {
        setShowTab(!isFullScreen);
        resetTimer();
    };

    return (
        <>
            <div className={`startTimer_sec ${isFullScreen && 'timerFullScreen'}`}>
                <div className="stopwatch">
                    <TimerCountDown
                        isRunning={true}
                        setIsTimesup={setIsTimesup}
                        currentTimeRef={currentTimeRef}
                        isPlaySound={isPlaySound}
                        setIsPlaySound={setIsPlaySound}
                    />
                </div>

                <div className="timmer_btn">
                    {!isPlaySound ? (
                        <>
                            <Button onClick={() => playPauseTimer()} variant="contained">
                                {timerStopwatch.isTimerPaused ? t('lang_timer.btn_resume') : t('lang_timer.btn_pause')}
                            </Button>
                            <Button onClick={() => resetTimerHandler()} variant="contained" className="light_btn">
                                {t('lang_timer.btn_reset')}
                            </Button>
                        </>
                    ) : (
                        <div className="times_up">
                            <Typography variant="h5">{t('lang_timer.txt_timeup')}</Typography>
                        </div>
                    )}
                    <IconButton
                        ref={fullScreenBtnRef}
                        onClick={() => (isFullScreen ? exitFullScreen() : enterFullScreen())}
                        color="primary"
                        className="defult_btn float"
                    >
                        {isFullScreen ? <FullscreenExitIcon /> : <FullscreenOutlinedIcon />}
                    </IconButton>
                </div>
            </div>
        </>
    );
};

export default TimerRunning;
