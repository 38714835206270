interface ContextualTutorialsInterface {
    [key: string]: {
        slides: string[];
        events: string[];
    };
}

export const contextualTutorialData: ContextualTutorialsInterface = {
    multiple_choice: {
        slides: [
            '/assets/contextualTutorial/multiple_choice/1.mp4',
            '/assets/contextualTutorial/multiple_choice/2.mp4',
            '/assets/contextualTutorial/multiple_choice/3.mp4',
        ],
        events: ['multipleChoiceOnboarded', 'multipleChoiceOnboardingSkipped'],
    },
    word_cloud: {
        slides: [
            '/assets/contextualTutorial/word_cloud/1.mp4',
            '/assets/contextualTutorial/word_cloud/2.mp4',
            '/assets/contextualTutorial/word_cloud/3.mp4',
        ],
        events: ['wordCloudOnboarded', 'wordCloudOnboardingSkipped'],
    },
    short_answer: {
        slides: [
            '/assets/contextualTutorial/short_answer/1.mp4',
            '/assets/contextualTutorial/short_answer/2.mp4',
            '/assets/contextualTutorial/short_answer/3.mp4',
        ],
        events: ['shortAnswerOnboarded', 'shortAnswerOnboardingSkipped'],
    },
    slide_drawing: {
        slides: [
            '/assets/contextualTutorial/slide_drawing/1.mp4',
            '/assets/contextualTutorial/slide_drawing/2.mp4',
            '/assets/contextualTutorial/slide_drawing/3.mp4',
        ],
        events: ['slideDrawingOnboarded', 'slideDrawingOnboardingSkipped'],
    },
    image_upload: {
        slides: [
            '/assets/contextualTutorial/image_upload/1.mp4',
            '/assets/contextualTutorial/image_upload/2.mp4',
            '/assets/contextualTutorial/image_upload/3.mp4',
        ],
        events: ['imageUploadOnboarded', 'imageUploadOnboardingSkipped'],
    },
    fill_blanks: {
        slides: [
            '/assets/contextualTutorial/fill_blanks/1.mp4',
            '/assets/contextualTutorial/fill_blanks/2.mp4',
            '/assets/contextualTutorial/fill_blanks/3.mp4',
        ],
        events: ['fillBlanksOnboarded', 'fillBlanksOnboardingSkipped'],
    },
    audio_record: {
        slides: [
            '/assets/contextualTutorial/audio_record/1.mp4',
            '/assets/contextualTutorial/audio_record/2.mp4',
            '/assets/contextualTutorial/audio_record/3.mp4',
        ],
        events: ['audioRecordOnboarded', 'audioRecordOnboardingSkipped'],
    },
    video_upload: {
        slides: [
            '/assets/contextualTutorial/video_upload/1.mp4',
            '/assets/contextualTutorial/video_upload/2.mp4',
            '/assets/contextualTutorial/video_upload/3.mp4',
        ],
        events: ['videoUploadOnboarded', 'videoUploadOnboardingSkipped'],
    },
    slideshow_onboarding: {
        slides: [
            '/assets/contextualTutorial/slideshow_onboarding/1.mp4',
            '/assets/contextualTutorial/slideshow_onboarding/2.mp4',
            '/assets/contextualTutorial/slideshow_onboarding/3.mp4',
            '/assets/contextualTutorial/slideshow_onboarding/4.mp4',
        ],
        events: ['slideshowOnboarded', 'slideshowOnboardingSkipped'],
    },
    draggable_objects: {
        slides: [
            '/assets/contextualTutorial/draggable_objects/1.mp4',
            '/assets/contextualTutorial/draggable_objects/2.mp4',
            '/assets/contextualTutorial/draggable_objects/3.mp4',
            '/assets/contextualTutorial/draggable_objects/4.mp4',
        ],
        events: ['draggableObjectsOnboarded', 'draggableObjectsOnboardingSkipped'],
    },
};
