import { useTranslation } from 'react-i18next';
import { FitToViewport } from 'react-fit-to-viewport';

import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

import styles from './UpgradeToProModal.module.scss';

import { getValidUserFromStore } from '../../../../helpers/storeHelpers';
import webviewMessenger from '../../../../services/webviewMessenger';
import apiUser from '../../../../services/apiUser';

import { utilConstants } from '../../../../constants/utils.constants';
import { ViewportSavedClasses } from '../../../../constants/viewport-constants';

interface IUpgradeToProModal {
    closeModalHandler: () => void;
}

export default function UpgradeToProModal({ closeModalHandler }: IUpgradeToProModal) {
    const { t } = useTranslation();
    const user = getValidUserFromStore('upgradeToProModal_templateLibrary');

    const subscribeUrl = `${process.env.REACT_APP_WEBAPP}/cp/checkout`;

    return (
        <Modal open={true} onClose={() => closeModalHandler()} className={`${styles.templateLibraryUpgradeToProModal}`}>
            <>
                <FitToViewport
                    className={`modal_viewport ${styles.viewport}`}
                    width={ViewportSavedClasses.width}
                    height={ViewportSavedClasses.height}
                    minZoom={ViewportSavedClasses.minZoom}
                    maxZoom={ViewportSavedClasses.maxZoom}
                >
                    <div className={`${styles.modalContent}`}>
                        <div className={`${styles.imageContainer}`}>
                            <img
                                src={utilConstants.IMAGE_URLS.TEMPLATE_LIBRARY_PRO_MODAL}
                                className={`${styles.image}`}
                                alt="ClassPoint pro"
                            />
                        </div>
                        <p className={`${styles.text}`}>{t('lang_template_library.upgrade_to_pro_features')}</p>
                        <Button
                            onClick={() => {
                                webviewMessenger.sendOpenBrowser(subscribeUrl);
                                webviewMessenger.sendUsageLog(`[C] Clicked "Upgrade to Pro in template library"`);
                                apiUser.recordGoproTrigger(user.email, 'template library');
                                (window as any).fbq('trackCustom', 'GoProTrigger', { source: 'template library' });
                            }}
                            className={`${styles.upgradeToProBtn}`}
                        >
                            {t('lang_new_trial.upgrade_to_pro')}
                        </Button>
                    </div>
                </FitToViewport>
            </>
        </Modal>
    );
}
