import { Typography } from '@mui/material';
import { Fragment, useEffect } from 'react';
import Lottie from 'react-lottie-player';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import loaderAnimation from '../../../assets/animation/computerRobot.json';
import { getValidUserFromStore } from '../../../helpers/storeHelpers';
import StoreInterface from '../../../interfaces/store.interface';
import apiUser from '../../../services/apiUser';

const GeneratingPdf = ({ activePageHandler }: any) => {
    const user = getValidUserFromStore('GeneratingPdf');
    const exportPdf = useSelector((state: StoreInterface) => state.exportPdf);
    const { t } = useTranslation();

    useEffect(() => {
        if (exportPdf.pdfUrl) {
            apiUser.pushPdf(user.userId, exportPdf.pdfUrl);
            activePageHandler('qrcodePage');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exportPdf]);

    return (
        <Fragment>
            <div className="generatingpdfloading">
                {/* <CircularProgress /> */}
                <Lottie loop={true} animationData={loaderAnimation} play speed={1} />
                <Typography variant="body1">{t('lang_share_pdf.btn_generating_pdf')}</Typography>
            </div>
        </Fragment>
    );
};

export default GeneratingPdf;
