import { getCachedReducer } from './../helpers/storeHelpers';
import { TimerStopwatchActionTypes } from './../constants/timer-stopwatch-action-types';
import TimerStopwatchInteface from '../interfaces/timer-stopwatch.interface';
import { localService } from '../services/localStorageService';
import { ReducerName } from '../constants/reducer-name.enum';

export default function TimerReducer(
    state = getCachedTimerStopwatchState(),
    action: { type: string; payload: Partial<TimerStopwatchInteface> },
): TimerStopwatchInteface {
    switch (action.type) {
        case TimerStopwatchActionTypes.START_TIMER:
        case TimerStopwatchActionTypes.PAUSE_TIMER:
        case TimerStopwatchActionTypes.RESUME_TIMER:
        case TimerStopwatchActionTypes.TIMER_TIMEUP:
        case TimerStopwatchActionTypes.RESET_TIMER:
        case TimerStopwatchActionTypes.TIMER_WINDOW_COLLAPSE:
        case TimerStopwatchActionTypes.TIMER_WINDOW_RESTORE:
        case TimerStopwatchActionTypes.TIMER_WINDOW_RESET:
        case TimerStopwatchActionTypes.MAXIMIZE_TIMER_WINDOW:
        case TimerStopwatchActionTypes.SET_TIME_LIMIT:
        case TimerStopwatchActionTypes.UPDATE_TIMER_MODE:
        case TimerStopwatchActionTypes.UPDATE_TIMEUP_SOUND_ID:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

const initialTimerStopwatch: TimerStopwatchInteface = {
    timerEndTime: 0,
    isTimerPaused: false,
    isTimesup: false,
    timerPauseTime: 0,
    isWindowCollapsed: false,
    isWindowMaximized: false,
    timeDataFromHost: 0,
    timeupSoundId: 0,
    isTimerMode: true,
    timerTime: 300,
};

const getCachedTimerStopwatchState = () => {
    const timerCached: TimerStopwatchInteface | null = getCachedReducer(ReducerName.timerStopwatch);
    if (timerCached) return { ...initialTimerStopwatch, ...timerCached };

    const storeCached = localService.getStoreData();
    if (storeCached && storeCached.timerStopwatch) return { ...initialTimerStopwatch, ...storeCached.timerStopwatch };

    return initialTimerStopwatch;
};
