import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { startCase } from 'lodash';

import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import styles from './ContentEditForm.module.scss';

import apiTemplateLibrary from '../../../../../services/apiTemplateLibrary';
import { userActions } from '../../../../../actions/user.actions';

import { EditTemplateItemDto, TemplateItemInterface } from '../../../../../interfaces/templateLibrary.interface';

import CommonButton from '../../../../../components/Common/CommonButton';

import { CATEGORIES } from '../../../categories';

type ContentEditFormProps = {
    modalItem: TemplateItemInterface;
    onRefreshItems: () => void;
    onCloseModal: () => void;
};

export default function ContentEditForm({ modalItem, onRefreshItems, onCloseModal }: ContentEditFormProps) {
    const dispatch = useDispatch();

    const [templateTags, setTemplateTags] = useState<string[]>([]);
    const [title, setTitle] = useState<string>(modalItem.title?.trim() || '');
    const [description, setDescription] = useState<string>(modalItem.description?.trim() || '');
    const [category, setCategory] = useState<string>(modalItem.category || '');
    const [tags, setTags] = useState<string[]>(modalItem.tags || []);
    const [isPro, setIsPro] = useState<boolean>(modalItem.isPro || false);
    const [isSaving, setIsSaving] = useState(false);
    const [isPublishing, setIsPublishing] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const getTagsHandler = async () => {
            const templateTags = await apiTemplateLibrary.getTags();
            if (templateTags) setTemplateTags(templateTags);
        };
        getTagsHandler();
    }, []);

    const saveDetailsHandler = async (shouldPublish: boolean) => {
        if (!title.trim() || tags.length < 1 || !category.trim()) {
            return setError('Title, tags and category are required');
        }
        shouldPublish ? setIsPublishing(true) : setIsSaving(true);

        const detailsObject: EditTemplateItemDto = {
            title: title.trim(),
            description: description.trim(),
            category,
            tags,
            isPro,
        };
        if (!modalItem.isPublished) detailsObject.shouldPublish = shouldPublish;
        const updateTemplate = await apiTemplateLibrary.updateTemplateDetails(modalItem._id, detailsObject);
        shouldPublish ? setIsPublishing(false) : setIsSaving(false);
        if (!updateTemplate) return dispatch(userActions.showApiError());

        onCloseModal();
        onRefreshItems();
    };

    useEffect(() => {
        setError('');
    }, [title, description, tags, category]);

    return (
        <div className={`${styles.formContent}`}>
            <h2 className={`${styles.head}`}>Edit Content Details</h2>

            <TextField
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                placeholder="Title"
                className={`${styles.searchInput}`}
                variant="outlined"
            />
            <TextField
                value={description}
                onChange={(event) => setDescription(event.target.value)}
                placeholder="Description"
                className={`${styles.searchInput}`}
                variant="outlined"
            />

            {templateTags.length > 0 && (
                <FormControl className={`${styles.selectInputContainer} ${styles.fullWidth}`}>
                    <InputLabel>Tags</InputLabel>
                    <Select
                        value={tags}
                        multiple
                        label="Tags"
                        onChange={(event) => {
                            const value = event.target.value;
                            setTags(typeof value === 'string' ? value.split(',') : value);
                        }}
                        className={`${styles.select}`}
                    >
                        {templateTags.map((item, index) => (
                            <MenuItem key={index} className={`${styles.templateSelectOptions}`} value={item}>
                                {startCase(item)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}

            <FormControl className={`${styles.selectInputContainer}`}>
                <InputLabel>Category</InputLabel>
                <Select
                    value={category}
                    label="Category"
                    onChange={(event: SelectChangeEvent) => setCategory(event.target.value)}
                    className={`${styles.select}`}
                >
                    {CATEGORIES.map((category, index) => (
                        <MenuItem key={index} className={`${styles.templateSelectOptions}`} value={category.name}>
                            {startCase(category.name)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl className={`${styles.selectInputContainer}`}>
                <InputLabel id="templateCategory">Is Pro</InputLabel>
                <Select
                    value={isPro ? 'true' : 'false'}
                    label="Is Pro"
                    onChange={(event: SelectChangeEvent) => setIsPro(event.target.value === 'true' ? true : false)}
                    labelId="templateCategory"
                    className={`${styles.select}`}
                >
                    <MenuItem className={`${styles.templateSelectOptions}`} value={'true'}>
                        Yes
                    </MenuItem>
                    <MenuItem className={`${styles.templateSelectOptions}`} value={'false'}>
                        No
                    </MenuItem>
                </Select>
            </FormControl>

            <div className={`${styles.btnContainer}`}>
                <CommonButton
                    text="Save"
                    clickFunction={() => saveDetailsHandler(false)}
                    isLoading={isSaving}
                    type="submit"
                    className={`${styles.saveBtn}`}
                />
                {!modalItem.isPublished && (
                    <CommonButton
                        text="Save and publish"
                        clickFunction={() => saveDetailsHandler(true)}
                        isLoading={isPublishing}
                        type="submit"
                        className={`${styles.saveAndPublishBtn}`}
                    />
                )}
            </div>

            <p className={`${styles.error}`}>{error}</p>
        </div>
    );
}
