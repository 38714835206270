import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typewriter from 'typewriter-effect';
import { AiDisplayMode } from '../../../interfaces/aiQuiz.interface';
import StoreInterface from '../../../interfaces/store.interface';
import { generateMcqLabel, removeExistingMcqLabel } from '../../../helpers/aiQuiz.helper';
import { aiQuizActions } from '../../../actions/aiQuiz.action';
import { useState } from 'react';

interface ReadingSlideViewInterface {
    errorMessage: string;
    setDisplayMode: (mode: AiDisplayMode) => void;
}

export const ReadingSlideView = ({ errorMessage, setDisplayMode }: ReadingSlideViewInterface) => {
    const { t } = useTranslation();

    return (
        <div className="head_sec">
            <Typewriter
                options={{ cursor: '||', delay: 10 }}
                onInit={(typewriter: any) => {
                    typewriter
                        .pauseFor(500)
                        .typeString(`${t(`lang_ai_quiz.question_flow.reading_slide`)}...`)
                        .callFunction(() => {
                            if (errorMessage) {
                                setTimeout(() => setDisplayMode(AiDisplayMode.ERROR_DISPLAY), 1000);
                            }
                        })
                        .start();
                }}
            />
        </div>
    );
};

export const ErrorDisplayView = ({ errorMessage }: { errorMessage: string }) => {
    const [errorTextTyped, setErrorTextTyped] = useState(false);

    return (
        <div className="head_sec notEnoughTextError">
            {errorTextTyped ? (
                <div className="Typewriter">
                    <span className="Typewriter__wrapper">{errorMessage}</span>
                </div>
            ) : (
                <Typewriter
                    options={{ cursor: '||', delay: 10 }}
                    onInit={(typewriter: any) => {
                        typewriter
                            .typeString(errorMessage)
                            .callFunction(() => setErrorTextTyped(true))
                            .start();
                    }}
                />
            )}
        </div>
    );
};

export const ThinkingView = () => {
    const { t } = useTranslation();

    return (
        <div className="head_sec">
            {
                <Typewriter
                    options={{
                        cursor: '||',
                        delay: 10,
                    }}
                    onInit={(typewriter: any) => {
                        typewriter.typeString(`${t(`lang_ai_quiz.question_flow.thinking`)}...`).start();
                    }}
                />
            }
        </div>
    );
};

interface GeneratingViewInterface {
    aiQuestionAnimationConfig: any;
    setIsQuestionGenerating: (arg: boolean) => void;
    setDisplayMode: (arg: AiDisplayMode) => void;
}

export const GeneratingView = ({
    aiQuestionAnimationConfig,
    setIsQuestionGenerating,
    setDisplayMode,
}: GeneratingViewInterface) => {
    const dispatch = useDispatch();

    const aiQuizState = useSelector((state: StoreInterface) => state.aiQuiz);
    const currentSlideId = aiQuizState.props.activeSlideId;
    const currentSlideQuestions = aiQuizState[currentSlideId]?.questionList || [];
    const currentQuestionIndex = aiQuizState[currentSlideId]?.currentQuestionIndex || 0;
    const currentQuestion = currentSlideQuestions[currentQuestionIndex] || undefined;

    if (!currentQuestion) return null;
    return (
        <Typewriter
            options={{
                cursor: '||',
                delay: 10,
            }}
            onInit={(typewriter: any) => {
                let allOptions = '';
                if (currentQuestion.options)
                    currentQuestion.options.forEach(
                        (item, index) =>
                            (allOptions =
                                allOptions +
                                `<span class="option"><span class="indexHighlight">${
                                    generateMcqLabel(index) + '.'
                                }</span> ${removeExistingMcqLabel(item)}</span><br/>`),
                    );
                typewriter
                    .typeString(
                        `<span class='question_section'>${currentQuestion.question}</span>${
                            allOptions ? '<br/> <span class="border_line"></span>' : ''
                        }`,
                    )
                    .typeString(allOptions)
                    .callFunction(() => {
                        const newAiQuizState = JSON.parse(JSON.stringify(aiQuizState));
                        newAiQuizState[currentSlideId].questionList[currentQuestionIndex].viewed = true;
                        dispatch(aiQuizActions.markViewed(currentSlideId, currentQuestionIndex));

                        aiQuestionAnimationConfig.animationAfterQuestionTyped();
                        setIsQuestionGenerating(false);
                        setDisplayMode(AiDisplayMode.SHOWING_QUESTION);
                    })
                    .start();
            }}
        />
    );
};
