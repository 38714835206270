export const WhiteboardPresets = [
    {
        name: 'preset_whiteboard',
        url: '/assets/whiteboards/whiteboard.png',
        thumbnail: '/assets/whiteboards/thumbnail/Whiteboard.png',
    },
    {
        name: 'preset_yellowboard',
        url: '/assets/whiteboards/yellow.png',
        thumbnail: '/assets/whiteboards/thumbnail/yellow.png',
    },
    {
        name: 'preset_greenboard',
        url: '/assets/whiteboards/green.png',
        thumbnail: '/assets/whiteboards/thumbnail/green.png',
    },
    {
        name: 'preset_blackboard',
        url: '/assets/whiteboards/blackboard.png',
        thumbnail: '/assets/whiteboards/thumbnail/Blackboard.png',
    },
    {
        name: 'preset_ruled_paper',
        url: '/assets/whiteboards/ruled.jpg',
        thumbnail: '/assets/whiteboards/thumbnail/Ruled.png',
    },
    {
        name: 'preset_graph_paper',
        url: '/assets/whiteboards/grid.png',
        thumbnail: '/assets/whiteboards/thumbnail/Grid.png',
    },
    {
        name: 'preset_xy_axes',
        url: '/assets/whiteboards/XYleft.png',
        thumbnail: '/assets/whiteboards/thumbnail/XYleft.png',
    },
    {
        name: 'preset_4_quadrants',
        url: '/assets/whiteboards/quadrants.png',
        thumbnail: '/assets/whiteboards/thumbnail/quadrants.png',
    },
    {
        name: 'preset_2_coltable',
        url: '/assets/whiteboards/column.png',
        thumbnail: '/assets/whiteboards/thumbnail/column.png',
    },
    {
        name: 'preset_kanban_paper',
        url: '/assets/whiteboards/kanban.png',
        thumbnail: '/assets/whiteboards/thumbnail/kanban.png',
    },
];
