import { useSelector } from 'react-redux';
import { FitToViewport } from 'react-fit-to-viewport';

import { Box, IconButton, Modal } from '@mui/material';

import styles from './QnaModal.module.scss';

import { updateQnaState } from '../../../../reducers/qna.reducer';

import StoreInterface from '../../../../interfaces/store.interface';
import { QnaActionType } from '../../../../interfaces/qna.interface';
import { ViewportConstants } from '../../../../constants/viewport-constants';

import QuestionModalCard from '../QuestionModalCard/QuestionModalCard';

interface QnaModalInterface {
    qnasCount: number;
}

const QnaModal = ({ qnasCount }: QnaModalInterface) => {
    const { selectedQuestionIndex } = useSelector((state: StoreInterface) => state.qna);

    const nextAndPrevQuestionHandler = (type: 'prev' | 'next') => {
        if (type === 'prev') {
            return updateQnaState(QnaActionType.NAVIGATE_IN_MODAL, {
                selectedQuestionIndex: selectedQuestionIndex !== 0 ? selectedQuestionIndex - 1 : qnasCount - 1,
            });
        }
        if (type === 'next') {
            return updateQnaState(QnaActionType.NAVIGATE_IN_MODAL, {
                selectedQuestionIndex: selectedQuestionIndex !== qnasCount - 1 ? selectedQuestionIndex + 1 : 0,
            });
        }
    };

    return (
        <Modal
            open={true}
            onClose={() => updateQnaState(QnaActionType.CLOSE_MODAL, { selectedQuestionIndex: -1 })}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={styles.short_ans_modal}
        >
            <>
                <FitToViewport
                    className={styles.modal_viewport}
                    width={ViewportConstants.width}
                    height={ViewportConstants.height}
                    minZoom={ViewportConstants.minZoom}
                    maxZoom={ViewportConstants.maxZoom}
                >
                    <Box className={styles.short_ans_box}>
                        <div
                            className={styles.close_modal}
                            onClick={() => updateQnaState(QnaActionType.CLOSE_MODAL, { selectedQuestionIndex: -1 })}
                        ></div>

                        <div className={styles.sky_slider_container}>
                            <QuestionModalCard />
                        </div>

                        <IconButton
                            onClick={() => nextAndPrevQuestionHandler('prev')}
                            title="button"
                            className={`${styles.sky_slider__prev}`}
                        >
                            <svg
                                className={styles.chevron_right}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 306 306"
                            >
                                <polygon points="94.35,0 58.65,35.7 175.95,153 58.65,270.3 94.35,306 247.35,153 " />
                            </svg>
                        </IconButton>
                        <IconButton
                            onClick={() => nextAndPrevQuestionHandler('next')}
                            title="button"
                            className={`${styles.sky_slider__next}`}
                        >
                            <svg
                                className={styles.chevron_right}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 306 306"
                            >
                                <polygon points="94.35,0 58.65,35.7 175.95,153 58.65,270.3 94.35,306 247.35,153 " />
                            </svg>
                        </IconButton>

                        <p className={`${styles.currentSlideIndex}`}>{`${selectedQuestionIndex + 1} / ${qnasCount}`}</p>
                    </Box>
                </FitToViewport>
            </>
        </Modal>
    );
};

export default QnaModal;
