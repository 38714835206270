import { CloseSubmissionDto } from './../dtos/close-submission.dto';
import axios from 'axios';
import { logger } from './logger';
import { sentryLogApiError } from './sentryService';
import ToolbarActionsInterface from '../interfaces/toolbar-actions.interface';
import { generateHubBaseUrl } from '../helpers/classSessionHelpers';

const apiClassSessions = {
    closeSubmissionThroughApi: async (hubBaseUrl: string, dto: CloseSubmissionDto) => {
        // No trycatch here so parent can catch it
        const url = hubBaseUrl + '/liveclasses/close-submission';
        await axios.post(url, dto);
    },

    endSlideshowThroughApi: async (
        hubBaseUrl: string,
        payload: { email: string; toolbarActions: ToolbarActionsInterface | null },
    ) => {
        const url = hubBaseUrl + '/liveclasses/end-slideshow';
        try {
            await axios.post(url, payload);
        } catch (error: any) {
            logger.error('endSlideshowThroughApi() error: ', error.response || error);
            sentryLogApiError(error, 'endSlideshowThroughApi', {
                query: { url },
                body: { payload },
            });
        }
    },

    updateClassLimit: async (cpcsRegion: string, email: string, classLimit: number) => {
        const hubBaseUrl = generateHubBaseUrl(cpcsRegion);
        const url = `${hubBaseUrl}/liveclasses/class-limit`;
        try {
            await axios.patch(url, null, { params: { email, classLimit } });
        } catch (error: any) {
            logger.error('updateClassLimit() error: ', error.response || error);
            sentryLogApiError(error, 'updateClassLimit', {
                query: { url },
            });
        }
    },

    markAnsweredQna: async (cpcsRegion: string, email: string, id: number): Promise<boolean> => {
        const hubBaseUrl = generateHubBaseUrl(cpcsRegion);
        const url = `${hubBaseUrl}/liveclasses/qna/mark-answered?email=${email}&id=${id}`;
        try {
            await axios.patch(url);
            return true;
        } catch (error: any) {
            logger.error('markAnsweredQna() error: ', error.response || error);
            sentryLogApiError(error, 'markAnsweredQna', {
                query: { url, cpcsRegion, email, id },
            });
            return false;
        }
    },

    deleteQna: async (cpcsRegion: string, email: string, id: number): Promise<boolean> => {
        const hubBaseUrl = generateHubBaseUrl(cpcsRegion);
        const url = `${hubBaseUrl}/liveclasses/qna?email=${email}&id=${id}`;
        try {
            await axios.delete(url);
            return true;
        } catch (error: any) {
            logger.error('deleteQna() error: ', error.response || error);
            sentryLogApiError(error, 'deleteQna', {
                query: { url, cpcsRegion, email, id },
            });
            return false;
        }
    },

    refetchPresenter: async (cpcsRegion: string, email: string): Promise<void> => {
        const hubBaseUrl = generateHubBaseUrl(cpcsRegion);
        const url = `${hubBaseUrl}/liveclasses/refetch-presenter?email=${email}`;
        try {
            await axios.post(url);
        } catch (error: any) {
            logger.error('refetchPresenter() error: ', error.response || error);
            sentryLogApiError(error, 'refetchPresenter', {
                query: { url, cpcsRegion, email },
            });
        }
    },

    healthCheck: async (cpcsRegion: string): Promise<boolean> => {
        const hubBaseUrl = generateHubBaseUrl(cpcsRegion);
        const url = `${hubBaseUrl}/ping`;
        try {
            const response = await axios.get(url);
            return response.status === 200;
        } catch (error: any) {
            return false;
        }
    },
};

export default apiClassSessions;
