import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TextField from '@mui/material/TextField';
import { Button } from '@material-ui/core';

import './GroupDetails.scss';

import { CreateOrEditGroupInterface, SavedClassGroupInterface } from '../../../../interfaces/savedClassGroup.interface';
import { utilConstants } from '../../../../constants/utils.constants';

import { NOT_ALLOWED, TICK } from '../../../../Icon';

interface GroupDetailsInterface {
    isEditMode: boolean;
    currentGroupDetails: CreateOrEditGroupInterface;
    groupDetailsHandler: (value: string, fieldName: keyof CreateOrEditGroupInterface) => void;
    savedClassGroups: SavedClassGroupInterface[];
}

export default function GroupDetails({
    isEditMode,
    currentGroupDetails,
    groupDetailsHandler,
    savedClassGroups,
}: GroupDetailsInterface) {
    const { t } = useTranslation();

    const [editModeReservedColor, setEditModeReservedColor] = useState('');

    const finalGroupName =
        typeof currentGroupDetails.groupNameEdited === 'undefined'
            ? currentGroupDetails.groupName
            : currentGroupDetails.groupNameEdited;
    const finalGroupColor =
        typeof currentGroupDetails.groupColorEdited === 'undefined'
            ? currentGroupDetails.groupColor
            : currentGroupDetails.groupColorEdited;

    const alreadyTakenGroupColors = savedClassGroups.map((group) => group.groupColor);
    const finalCheckColors = isEditMode
        ? alreadyTakenGroupColors?.filter((color) => color !== editModeReservedColor)
        : alreadyTakenGroupColors;

    useEffect(() => {
        if (isEditMode) setEditModeReservedColor(currentGroupDetails.groupColor);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={`groupDetails`}>
            <div className={`name`}>
                <p className={`label`}>{t('lang_grouping.group_name')}</p>
                <TextField
                    value={finalGroupName}
                    onChange={(event) => groupDetailsHandler(event.target.value, 'groupName')}
                    inputProps={{ maxLength: 20 }}
                    autoComplete="off"
                    className={`groupName`}
                    variant="standard"
                />
            </div>

            <div className={`color`}>
                <p className={`label`}>{t('lang_grouping.group_color')}</p>
                <div className={`colorOptions`}>
                    {utilConstants.GROUP_COLORS.map((item, index) => (
                        <Button
                            key={index}
                            onClick={() => groupDetailsHandler(item.color, 'groupColor')}
                            disabled={finalCheckColors?.includes(item.color)}
                            className={`option`}
                            style={{ background: item.color }}
                        >
                            {finalGroupColor === item.color && (
                                <i className="doneIcon">
                                    <TICK />
                                </i>
                            )}
                            {finalCheckColors?.includes(item.color) && (
                                <i className="takenIcon">
                                    <NOT_ALLOWED />
                                </i>
                            )}
                        </Button>
                    ))}
                </div>
            </div>
        </div>
    );
}
