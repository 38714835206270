import { useState, useEffect, RefObject } from 'react';

export function useAtBottom(ref?: RefObject<HTMLElement>, offset: number = 0): boolean {
	const [isAtBottom, setIsAtBottom] = useState(false);

	useEffect(() => {
		const element = ref?.current || window;

		const handleScroll = () => {
			let hasReachedBottom = false;

			if (ref?.current) {
				// When ref is provided and points to an HTMLElement
				const { scrollTop, scrollHeight, clientHeight } = ref.current;
				hasReachedBottom = scrollTop + clientHeight >= scrollHeight - offset;
			} else {
				// When no ref is provided, use the window
				hasReachedBottom =
					window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - offset;
			}

			setIsAtBottom(hasReachedBottom);
		};

		element.addEventListener('scroll', handleScroll, { passive: true });
		handleScroll(); // Initialize the state

		return () => {
			element.removeEventListener('scroll', handleScroll);
		};
	}, [ref, offset]);

	return isAtBottom;
}
