import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Typography } from '@mui/material';
import { store } from '../../../helpers/store';
import PickedParticipantModal from '../modals/PickedParticipantModal';
import PickNamesParticipantInterface from '../../../interfaces/pick-names-participant.interface';
import { findParticipantPickNamesListIndex, getSingleParticipantInModal } from '../../../helpers/pickNamesHelpers';
import { pickNamesActions } from '../../../actions/pickNames.action';
import { localService } from '../../../services/localStorageService';
import StoreInterface from '../../../interfaces/store.interface';
import CachePickNameFlipCard from '../components/CachePickNameFlipCard';
import { UpdateToolbarActionsType } from '../../../dtos/update-toolbar-actions.dto';

interface PickNamesCardViewInterface {
    isAnimating: boolean;
    setIsAnimating: (arg: boolean) => void;
    givePointsConfettiRef: any;
}

const PickNamesCardView = ({ isAnimating, setIsAnimating, givePointsConfettiRef }: PickNamesCardViewInterface) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const webviewSettingsSlideshow = useSelector((state: StoreInterface) => state.webviewSettingsSlideshow);
    const pickNamesList: PickNamesParticipantInterface[] = store.getState().pickNamesList;

    const pickedParticipantIdsInModal = localService.getPickedNamesInModal();
    const isPickNameShowOnlineParticipant = webviewSettingsSlideshow.isPickNameShowOnlineParticipant;

    const pickNamesListWithOnlineStatus = isPickNameShowOnlineParticipant
        ? pickNamesList.filter((data) => data.isOnline === true)
        : pickNamesList;

    const [isPickedModalOpen, setIsPickedModalOpen] = useState(pickedParticipantIdsInModal.length === 1 ? true : false);
    const [pickedParticipant, setPickedParticipant] = useState<PickNamesParticipantInterface | undefined>(
        getSingleParticipantInModal(pickNamesList, pickedParticipantIdsInModal),
    );

    const handleClick = (participant: PickNamesParticipantInterface) => {
        setPickedParticipant(participant);
        if (!participant.isPicked) {
            dispatch(pickNamesActions.pickOneName(participant.participantId, true));
            localService.updateToolbarActionsFromWebview(UpdateToolbarActionsType.pickName);
        } else {
            setIsPickedModalOpen(true);
            localService.setPickedNamesInModal([participant.participantId]);
        }
    };

    useEffect(() => {
        if (pickedParticipant) {
            const pickedIndex = findParticipantPickNamesListIndex(pickedParticipant);
            setPickedParticipant(pickNamesList[pickedIndex]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pickNamesList]);

    return (
        <>
            {pickedParticipant && (
                <PickedParticipantModal
                    isPickedModalOpen={isPickedModalOpen}
                    setIsPickedModalOpen={setIsPickedModalOpen}
                    pickedParticipant={pickedParticipant}
                    isAnimating={isAnimating}
                    setIsAnimating={setIsAnimating}
                    givePointsConfettiRef={givePointsConfettiRef}
                />
            )}

            <>
                <div className="pick_head">
                    <Typography variant="body1"> {t('lang_pick_names.txt_reveal_participant_name')}</Typography>
                </div>

                <div className="pickname_wrap">
                    <motion.div animate="open" className="pickNumber_card">
                        {pickNamesListWithOnlineStatus.map((participant, index: number) => {
                            return (
                                <CachePickNameFlipCard
                                    key={participant.participantId}
                                    participant={participant}
                                    isAutoPicking={false}
                                    flipOrderIndex={0}
                                    onClickHandler={() => handleClick(participant)}
                                    givePointsConfettiRef={givePointsConfettiRef}
                                />
                            );
                        })}
                    </motion.div>
                </div>
            </>
        </>
    );
};

export default PickNamesCardView;
