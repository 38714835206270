import { t } from 'i18next';

import { Link } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

const BackUi = () => {
    return (
        <Link className={`backBtn`}>
            <ArrowBackOutlinedIcon />
            {t('lang_common.btn_back')}
        </Link>
    );
};

export default BackUi;
