'use client';

import * as React from 'react';
import { useInView } from 'react-intersection-observer';
import { useAtBottom } from '../hooks/useAtBottom';

interface ChatScrollAnchorProps {
    trackVisibility?: boolean;
    autoScrollRef?: React.RefObject<HTMLDivElement>;
}

export function ChatScrollAnchor({ trackVisibility, autoScrollRef }: ChatScrollAnchorProps) {
    const isAtBottom = useAtBottom(autoScrollRef);

    const { ref, entry, inView } = useInView({
        trackVisibility,
        delay: 100,
        rootMargin: '0px 0px -150px 0px',
    });

    React.useEffect(() => {
        if (isAtBottom && trackVisibility && !inView) {
            entry?.target.scrollIntoView({
                block: 'start',
            });
        }
    }, [inView, entry, isAtBottom, trackVisibility]);

    return <div ref={ref} className="h-px w-full" />;
}
