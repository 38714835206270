import { Fragment } from 'react';
import Lottie from 'react-lottie-player';

import loaderAnimation from '../../../assets/animation/87884-loader.json';

const Loading = () => {
    return (
        <Fragment>
            <div className="loading">
                {/* <CircularProgress /> */}
                <Lottie loop={true} animationData={loaderAnimation} play speed={1} />
            </div>
        </Fragment>
    );
};

export default Loading;
