import { Fragment } from 'react';
import Lottie from 'react-lottie-player';
import { AnimatePresence, motion } from 'framer-motion';
import { Chip } from '@mui/material';

import CustomAvatar, { AvatarType } from './CustomAvatar';

import greenTick from '../../assets/animation/green_tick.json';
import pinkTick from '../../assets/animation/pink_tick.json';
import blueTick from '../../assets/animation/blue_tick.json';
import yellowTick from '../../assets/animation/yellow_tick.json';
import purpleTick from '../../assets/animation/violet_tick.json';
import orangeTick from '../../assets/animation/orange_tick.json';

const CommonChip = (props: any) => {
    const tickVariants = [greenTick, pinkTick, blueTick, yellowTick, purpleTick, orangeTick];

    return (
        <div className="avaterAnimation_group">
            <div className="avaterstart-container">
                <div className={`avaterstart rotateState `}>
                    {props.stars?.map((participant: any, index: number) => {
                        let wheelPieceStyles: any = {
                            '--item-nb': index,
                            '--degree': participant.possition,
                        };
                        return (
                            <Fragment>
                                <AnimatePresence>
                                    {props.animationStart && participant.isPlayed === false && (
                                        <div
                                            key={participant.index}
                                            className={`avaterstart-item`}
                                            style={wheelPieceStyles}
                                        >
                                            <span>
                                                <motion.span
                                                    initial={{
                                                        scale: 0.3,
                                                        opacity: 0,
                                                    }}
                                                    whileInView={{
                                                        scale: 1,
                                                        opacity: 1,
                                                        transition: {
                                                            type: 'spring',
                                                            stiffness: 500,
                                                            mass: 2.8,
                                                            damping: 40,
                                                        },
                                                    }}
                                                    exit={{
                                                        scale: 0.5,
                                                        opacity: 0,
                                                        transition: { duration: 0.5 },
                                                    }}
                                                >
                                                    <Lottie
                                                        loop={false}
                                                        animationData={tickVariants[participant.lottieIndex]}
                                                        play
                                                        speed={0.5}
                                                    />
                                                </motion.span>
                                            </span>
                                        </div>
                                    )}
                                </AnimatePresence>
                            </Fragment>
                        );
                    })}
                </div>
            </div>
            <Chip
                avatar={
                    <CustomAvatar
                        {...props}
                        avatarName={props.participantName}
                        avatarUrl={props.participantAvatar}
                        type={AvatarType.PARTICIPANT}
                    />
                }
                label={props.participantName}
                variant="outlined"
                onClick={props.onClick}
                onDelete={props.onDelete}
            />
        </div>
    );
};

export default CommonChip;
