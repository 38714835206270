import { useTranslation } from 'react-i18next';
import { Button, IconButton, Typography } from '@mui/material';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import TimerCountDown from './TimerCountDown';

interface TimerFullscreenResetInterface {
    isFullScreen: boolean;
    startTimer: () => void;
    showDefaultScreenSize: () => void;
    showFullScreenSize: () => void;
    setIsTimesup: (args: boolean) => void;
    setShowTab: (args: boolean) => void;
    fullScreenBtnRef: any;
    isPlaySound: boolean;
    setIsPlaySound: (args: boolean) => void;
}

const TimerFullscreenReset = ({
    isFullScreen,
    startTimer,
    showDefaultScreenSize,
    showFullScreenSize,
    setIsTimesup,
    setShowTab,
    fullScreenBtnRef,
    isPlaySound,
    setIsPlaySound,
}: TimerFullscreenResetInterface) => {
    const { t } = useTranslation();

    const screenSizeHandler = () => {
        setShowTab(true);
        isFullScreen ? showDefaultScreenSize() : showFullScreenSize();
    };

    return (
        <>
            <div className="startTimer_sec timerFullScreenReset">
                <div className="stopwatch">
                    <TimerCountDown
                        isRunning={false}
                        setIsTimesup={setIsTimesup}
                        isPlaySound={isPlaySound}
                        setIsPlaySound={setIsPlaySound}
                    />
                </div>

                <div className="timmer_btn">
                    {!isPlaySound ? (
                        <Button onClick={() => startTimer()} variant="contained">
                            {t('lang_timer.btn_start')}
                        </Button>
                    ) : (
                        <div className="times_up">
                            <Typography variant="h5">{t('lang_timer.txt_timeup')}</Typography>
                        </div>
                    )}
                    <IconButton
                        ref={fullScreenBtnRef}
                        onClick={() => screenSizeHandler()}
                        color="primary"
                        className="defult_btn float"
                    >
                        <FullscreenExitIcon />
                    </IconButton>
                </div>
            </div>
        </>
    );
};

export default TimerFullscreenReset;
