import { GtmService } from './../services/googleTagManager.service';
import { isJson } from './../helpers/activityHelpers';
import { isUserOnTrialOrPro } from './../helpers/userhelper';
import { filterOnlineParticipants, generateHubBaseUrl, getCpcsRegion } from './../helpers/classSessionHelpers';
import { sentryLogSignalrError } from './../services/sentryService';
import { userActions } from './user.actions';
import { activityActions } from './activity.action';
import { ClassSessionActionTypes } from '../constants/class-session-action-types';
import webviewMessenger from '../services/webviewMessenger';
import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import _ from 'lodash';
import { store } from '../helpers/store';
import UserInterface from '../interfaces/user-interface';
import ClassSessionInterface from '../interfaces/classSessionInterface';
import { ActivityActionTypes } from '../constants/activity-action-types';
import ParticipantInterface from '../interfaces/participant.interface';
import ActivityResponseInterface from '../interfaces/activity-response.interface';
import { getActivityFromStore, getValidClassSessionFromStore, getValidUserFromStore } from '../helpers/storeHelpers';
import apiClassSessions from '../services/apiClassSessions';
import ParticipantAndPointsDto from '../dtos/participant-and-points.dto';
import { UserActionTypes } from '../constants/user-action-types';
import apiUser from '../services/apiUser';
import { timeStampSuffix } from '../helpers/activityHelpers';
import { dataURLtoFile, isCpVerAtLeast } from '../helpers/utils';
import { localService } from '../services/localStorageService';
import { logger } from '../services/logger';
import { findUserClassLimit } from '../helpers/userhelper';
import CachedResponsesInterface from '../interfaces/cached-responses.interface';
import CachedParticipantsInterface from '../interfaces/cached-participants.interface';
import apiSavedClasses from '../services/apiSavedClasses';
import GotoStepDto from '../dtos/goto-step.dto';
import { HostLogType } from '../constants/host-log-types.enum';
import UserClassSessionInterface from '../interfaces/user-class-session.interface';
import { StartClassSessionDto } from '../dtos/start-class-session.dto';
import { aiQuizActions } from './aiQuiz.action';
import GenerateAiQuizDto from '../dtos/generate-ai-quiz.dto';
import AiQuizQuestionDto from '../dtos/ai-quiz-question.dto';
import { AiQuizQuestionReceived, AiQuizInterface, AiQuizQuestionInterface } from '../interfaces/aiQuiz.interface';
import { t } from 'i18next';
import { QnaDataInterface } from '../interfaces/qna.interface';
import { SavedClassGroupInterface } from '../interfaces/savedClassGroup.interface';

export const classSessionActions = {
    startSlideshow,
    endSlideshow,
    gotoStep,
    lockUnlockClass,
    toggleAllowGuests,
    toggleSaveGuests,
    removeParticipant,
    givePointsToParticipants,
    signalrGivePointsToParticipants,
    restartClass,
    showCorrectAnswer,
    generateAiQuiz,
};

async function signalrStartSlideshow(
    payload: { totalSlideCount: number; isAudienceSlideViewerEnabled: boolean },
    cpcsRegion: string,
    dispatch: (arg0: { type: string; payload: any }) => void,
    isReconnectingPresenter: boolean,
) {
    const user = getValidUserFromStore('signalrStartSlideshow');
    const hubBaseUrl = generateHubBaseUrl(cpcsRegion);
    const connection = new HubConnectionBuilder()
        .withUrl(hubBaseUrl + '/classsession', {
            transport: HttpTransportType.WebSockets,
            skipNegotiation: true,
        })
        .configureLogging(LogLevel.Debug)
        .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: () => {
                return 1000;
            },
        })
        .build();

    connection.onreconnected(async () => {
        logger.warn('Signalr reconnected, presenter joining the same class back');
        localService.markSignalrRetryDone();
        const user = getValidUserFromStore('onreconnected');
        const payload = { email: user.email };
        try {
            await connection.invoke('PresenterRejoinClass', payload);
        } catch (error) {
            sentryLogSignalrError(error, 'PresenterRejoinClass', payload);
        }
        dispatch({
            type: ClassSessionActionTypes.SIGNALR_RECONNECTED,
            payload: connection,
        });
    });

    connection.on('GetClassCodeFailed', () => {
        logger.error('GetClassCodeFailed');
        webviewMessenger.sendSimpleMessage('signalrNotConnected');
        sentryLogSignalrError('GetClassCodeFailed', 'PresenterStartSlideshow', {
            presenterDto: presenterDto(user),
            slideshowDto: {
                totalSlideCount: payload.totalSlideCount,
                isAudienceSlideViewerEnabled: payload.isAudienceSlideViewerEnabled,
            },
            createClassSessionDto: classSessionDto(user),
        });
    });

    connection.on('LoadSavedClassFailed', () => {
        logger.error('LoadSavedClassFailed');
        webviewMessenger.sendSimpleMessage('signalrNotConnected');
        apiSavedClasses.syncSavedClasses(user.email, user.userId);
        sentryLogSignalrError('LoadSavedClassFailed', 'PresenterStartSlideshow', {
            presenterDto: presenterDto(user),
            slideshowDto: {
                totalSlideCount: payload.totalSlideCount,
                isAudienceSlideViewerEnabled: payload.isAudienceSlideViewerEnabled,
            },
            createClassSessionDto: classSessionDto(user),
        });
    });

    connection.on('ClassSessionUpdated', (classSessionData: ClassSessionInterface) => {
        // console.log('ClassSessionUpdated', classSessionData);
        localService.markSignalrRetryDone();
        const updatedClassSession = { ...classSessionData };
        const participantsToDisplay = updatedClassSession.participantList.filter(
            (p) => p.isFromSavedClass || p.left === null,
        );
        updatedClassSession.participantList = participantsToDisplay;
        dispatch(updateClassSession(ClassSessionActionTypes.CLASSSESSION_UPDATED, updatedClassSession));
        checkGotoStep(updatedClassSession.participantList);
        webviewMessenger.sendClassSession(updatedClassSession);
        // checkAndUpdateUserClassSession(
        //     {
        //         cpcsRegion: classSessionData.cpcsRegion,
        //         classSessionId: classSessionData.classSessionId,
        //     },
        //     dispatch,
        // );
        // save current class code in local storage if this is a saved class, otherwise clear it
        if (updatedClassSession.savedClassId) {
            localService.setLastSavedClass(user.email, updatedClassSession.savedClassId, updatedClassSession.classCode);
        } else {
            localService.removeLastSavedClass();
        }
    });

    connection.on('NewClassStarted', (classSessionData: ClassSessionInterface) => {
        logger.error('NewClassStarted', classSessionData);
    });

    connection.on('ExistingClassResumed', (classSessionData: ClassSessionInterface) => {
        logger.error('ExistingClassResumed', classSessionData);
    });

    connection.on('PresenterRejoins', (classSessionData: ClassSessionInterface) => {
        logger.error('PresenterRejoins', classSessionData);
    });

    connection.on('ClassRestarted', (classSessionData: ClassSessionInterface) => {
        logger.error('ClassRestarted', classSessionData);
    });

    connection.on('ClassLimitUpdated', (newClassLimit: number) => {
        logger.error('ClassLimitUpdated', newClassLimit);
    });

    connection.on('AllowGuestsToggled', (isAllow: boolean) => {
        logger.error('AllowGuestsToggled', isAllow);
    });

    connection.on('SaveGuestsToggled', (isSave: boolean) => {
        logger.error('SaveGuestsToggled', isSave);
    });

    connection.on('SavedClassRenamed', (newClassSessionName: string) => {
        logger.error('SavedClassRenamed', newClassSessionName);
    });

    connection.on('NewOfflineParticipantsAddedToSavedClass', (newParticipants: ParticipantInterface[]) => {
        logger.error('NewOfflineParticipantsAddedToSavedClass', newParticipants);
    });

    connection.on('RenamedParticipantInSavedClass', (updatedParticipant: ParticipantInterface) => {
        logger.error('RenamedParticipantInSavedClass', updatedParticipant);
    });

    connection.on('ParticipantDeletedFromSavedClass', (deletedParticipantId: string) => {
        logger.error('ParticipantDeletedFromSavedClass', deletedParticipantId);
    });

    connection.on(
        'GroupsUpdated',
        ({ groups, participants }: { groups: SavedClassGroupInterface[]; participants: ParticipantInterface[] }) => {
            logger.error('GroupsUpdated', { groups, participants });
        },
    );

    connection.on('NewParticipantJoined', (participant: ParticipantInterface) => {
        // console.log('NewParticipantJoined', participant);

        // Approach A: dispatch action on every new participant join
        // const updatedClassSession = {
        //     ...getValidClassSessionFromStore(),
        // };
        // updatedClassSession.participantList.push(participant);
        // webviewMessenger.sendClassSession(updatedClassSession);
        // dispatch(updateClassSession(ClassSessionActionTypes.NEW_PARTICIPANT_JOINED, updatedClassSession));

        // Approach B: cache first then dispatch after a delay to reduce dispatch frequency
        const cachedParticipants: ParticipantInterface[] = localService.getCachedParticipants()?.participants || [];
        const updatedParticipants =
            cachedParticipants.length !== 0
                ? [...cachedParticipants, participant]
                : [...getValidClassSessionFromStore('NewParticipantJoined').participantList, participant];
        const messageId = Math.floor(Math.random() * 10000);
        const obj = { participants: updatedParticipants, messageId } as CachedParticipantsInterface;
        localService.setCachedParticipants(obj);

        setTimeout(() => {
            const cachedParticipantsObj = localService.getCachedParticipants();
            if (!cachedParticipantsObj) return;

            if (cachedParticipantsObj.messageId !== messageId) return;

            const allParticipants: ParticipantInterface[] = cachedParticipantsObj.participants;
            const updatedClassSession: ClassSessionInterface = JSON.parse(
                JSON.stringify(getValidClassSessionFromStore('NewParticipantJoined')),
            );
            updatedClassSession.participantList = allParticipants;
            webviewMessenger.sendClassSession(updatedClassSession);
            dispatch(updateClassSession(ClassSessionActionTypes.NEW_PARTICIPANT_JOINED, updatedClassSession));
            checkGotoStep(updatedClassSession.participantList);
            localService.removeCachedParticipants();
        }, 50);
    });

    connection.on('ExistingParticipantRefreshed', (participant: ParticipantInterface) => {
        // received when existing participant joins from another tab
        // console.log('ExistingParticipantRefreshed', participant);

        const updatedClassSession = {
            ...getValidClassSessionFromStore('ExistingParticipantRefreshed'),
        };
        updatedClassSession.participantList = updateParticipantList(
            updatedClassSession.participantList,
            participant,
            true,
        );
        dispatch(updateClassSession(ClassSessionActionTypes.EXISTING_PARTICIPANT_REFRESHED, updatedClassSession));
        checkGotoStep(updatedClassSession.participantList);
    });

    connection.on('OfflineParticipantConnected', (participant: ParticipantInterface) => {
        // received when a left participant joins back, or an offlineParticipant (defined in savedClass) joins online
        console.log('OfflineParticipantConnected', participant);

        const updatedClassSession = {
            ...getValidClassSessionFromStore('OfflineParticipantConnected'),
        };
        updatedClassSession.participantList = updateParticipantList(
            updatedClassSession.participantList,
            participant,
            true,
        );
        webviewMessenger.sendClassSession(updatedClassSession);
        dispatch(updateClassSession(ClassSessionActionTypes.OFFLINE_PARTICIPANT_JOINED, updatedClassSession));
        checkGotoStep(updatedClassSession.participantList);
    });

    connection.on('ParticipantGoesOffline', (participant: ParticipantInterface) => {
        // 1. when a participant leaves class
        // 2. when a participant from an inactive tab wants to leave the class, he will join then leave class
        // 3. when a participant rejoins a class but the class code is being used by another presenter: remove the participant from the new class
        console.log('ParticipantGoesOffline', participant);

        const updatedClassSession = {
            ...getValidClassSessionFromStore('ParticipantGoesOffline'),
        };
        if (participant.isFromSavedClass) {
            // if participant is from saved class, keep displaying him
            updatedClassSession.participantList = updateParticipantList(
                updatedClassSession.participantList,
                participant,
                true,
            );
        } else
            updatedClassSession.participantList = updateParticipantList(
                updatedClassSession.participantList,
                participant,
                false,
            );

        webviewMessenger.sendClassSession(updatedClassSession);
        dispatch(updateClassSession(ClassSessionActionTypes.PARTICIPANT_GOES_OFFLINE, updatedClassSession));
    });

    connection.on('ParticipantSubmittedResponse', async (data: string) => {
        if (typeof data === 'string') data = JSON.parse(data);
        // console.log('ParticipantSubmittedResponse', data);

        // Approach A: dispatch action on every message received
        // const updatedActivityResponsesA = [
        //     ...getActivityFromStore().activityResponses,
        //     data,
        // ] as ActivityResponseInterface[];
        // const responseParticipantIds = updatedActivityResponsesA.map((response) => response.participantId);
        // calculateUniqueParticipantCountAndNotifyHost(responseParticipantIds);
        // dispatch({
        //     type: ActivityActionTypes.RESPONSES_RECEIVED,
        //     payload: updatedActivityResponsesA,
        // });

        // Approach B: cache first then dispatch after a delay to reduce dispatch frequency
        const cachedResponses: ActivityResponseInterface[] = localService.getCachedResponses()?.responses || [];
        const updatedActivityResponses =
            cachedResponses.length !== 0
                ? [...cachedResponses, data]
                : [...getActivityFromStore().activityResponses, data];
        const messageId = Math.floor(Math.random() * 10000);
        const obj = { responses: updatedActivityResponses, messageId } as CachedResponsesInterface;
        // console.log('obj', obj);
        localService.setCachedResponses(obj);

        setTimeout(() => {
            const cachedResponsesObj = localService.getCachedResponses();
            if (!cachedResponsesObj) return;

            if (cachedResponsesObj.messageId !== messageId) {
                // console.log('messageId changed');
                return;
            }

            // console.log('messageId same');
            const allResponses: ActivityResponseInterface[] = cachedResponsesObj.responses;
            const responseParticipantIds = allResponses.map((response) => response.participantId);
            calculateUniqueParticipantCountAndNotifyHost(responseParticipantIds);
            dispatch({
                type: ActivityActionTypes.RESPONSES_RECEIVED,
                payload: allResponses,
            });
            localService.removeCachedResponses();
            webviewMessenger.sendUsageLog(`[S] ${allResponses.length} responses received`);
        }, 50);
    });

    connection.on('SyncActivityResponsesOnRejoin', async (responses: ActivityResponseInterface[]) => {
        // logger.warn('SyncActivityResponsesOnRejoin', responses);
        const allResponses: ActivityResponseInterface[] = responses;
        const responseParticipantIds = allResponses.map((response) => response.participantId);
        calculateUniqueParticipantCountAndNotifyHost(responseParticipantIds);
        dispatch({
            type: ActivityActionTypes.SYNC_RESPONSES_ON_REJOIN,
            payload: allResponses,
        });
    });

    connection.on('ParticipantSubmittedVote', async (data: { responseId: string; voterParticipantIds: string }) => {
        // console.log('ParticipantSubmittedVote', data);
        const updatedActivityResponses = [...getActivityFromStore().activityResponses];
        const targetResponse: any = updatedActivityResponses.find((r: any) => r.responseId === data.responseId);
        targetResponse.voterParticipantIds = data.voterParticipantIds;
        // calculate number of voted participants and send to Host
        const votedParticipantIds = _.flatten(
            updatedActivityResponses.map((response: any) => response.voterParticipantIds),
        );
        calculateUniqueParticipantCountAndNotifyHost(votedParticipantIds);
        console.log('updatedActivityResponses', updatedActivityResponses);
        dispatch({
            type: ActivityActionTypes.RESPONSE_VOTED,
            payload: updatedActivityResponses,
        });
    });

    connection.on('ParticipantsGotPoints', async (participants: ParticipantInterface[]) => {
        // console.log('ParticipantsGotPoints', participants);
        const updatedClassSession: ClassSessionInterface = JSON.parse(
            JSON.stringify(getValidClassSessionFromStore('ParticipantsGotPoints')),
        );
        participants.forEach((p) => {
            const targetParticipantIndex = updatedClassSession.participantList.findIndex(
                (ep) => ep.participantId === p.participantId,
            );
            if (targetParticipantIndex >= 0)
                updatedClassSession.participantList[targetParticipantIndex].participantPoints = p.participantPoints;
        });
        dispatch(updateClassSession(ClassSessionActionTypes.PARTICIPANTS_GOT_POINTS, updatedClassSession));
    });

    connection.on('ResponsesGotPoints', async (updatedResponses: ActivityResponseInterface[]) => {
        console.log('ResponsesGotPoints', updatedResponses);
        const activity = getActivityFromStore();
        const updatedActivityResponses: ActivityResponseInterface[] = JSON.parse(
            JSON.stringify(activity.activityResponses),
        );

        updatedResponses.forEach((response) => {
            const targetIndex = updatedActivityResponses.findIndex((r) => r.responseId === response.responseId);
            if (targetIndex >= 0) updatedActivityResponses[targetIndex].responsePoints = response.responsePoints;
        });

        dispatch({
            type: ActivityActionTypes.RESPONSES_GOT_POINTS,
            payload: updatedActivityResponses,
        });
    });

    connection.on('AiQuizQuestion', async (questionData: AiQuizQuestionDto) => {
        // console.log('AiQuizQuestion', questionData);
        const questionText = questionData.questionText;
        if (!isJson(questionText)) return;

        const questionDto = JSON.parse(questionText) as AiQuizQuestionReceived;
        const newQuestion: AiQuizQuestionInterface = {
            question: questionDto.question,
            bloomTaxonomyLevel: questionDto.bloomTaxonomyLevel,
            viewed: false,
            showAnswer: false,
        };

        if (questionDto.options) newQuestion.options = questionDto.options;
        if (questionDto.correct !== undefined) newQuestion.correct = questionDto.correct;
        if (questionDto.correctAnswer) newQuestion.correctAnswer = questionDto.correctAnswer;
        if (questionDto.answer) newQuestion.answer = questionDto.answer;

        const updatedAiQuizState = JSON.parse(JSON.stringify(store.getState().aiQuiz)) as AiQuizInterface;
        if (!updatedAiQuizState[questionData.slideId]) return;

        if (!updatedAiQuizState[questionData.slideId]?.questionList) {
            updatedAiQuizState[questionData.slideId].questionList = [newQuestion];
        } else {
            updatedAiQuizState[questionData.slideId].questionList.push(newQuestion);
            updatedAiQuizState[questionData.slideId].hasGenerationDone = false;
        }
        updatedAiQuizState[questionData.slideId].latestQuestionTimestamp = Date.now();

        dispatch(aiQuizActions.addNewQuestion(updatedAiQuizState));
    });

    connection.on('AiQuizGenerationDone', async (slideId: string) => {
        // console.log('AiQuizGenerationDone', slideId);
        const updatedAiQuizState = JSON.parse(JSON.stringify(store.getState().aiQuiz)) as AiQuizInterface;
        if (!updatedAiQuizState[slideId]) return;
        updatedAiQuizState[slideId].hasGenerationDone = true;
        dispatch(aiQuizActions.quizGenerationDone(updatedAiQuizState));
    });

    connection.on('StartActivityFailed', async (error: string) => {
        console.log('StartActivityFailed', error);
        dispatch({
            type: UserActionTypes.SHOW_API_ERROR,
            payload: null,
        });
    });

    connection.on('QnaDataUpdated', (data: { qnaData: QnaDataInterface; shouldNotifyHost: boolean }) => {
        // console.log('QnaDataUpdated', data);
        const updatedClassSession = {
            ...getValidClassSessionFromStore('QnaDataUpdated'),
        };
        updatedClassSession.qnaData = data.qnaData;
        dispatch(updateClassSession(ClassSessionActionTypes.QNA_DATA_UPDATED, updatedClassSession));
        if (data.shouldNotifyHost) {
            webviewMessenger.sendClassSession(updatedClassSession);
        }
    });

    connection.on('ClassTerminated', async () => {
        logger.error('ClassTerminated');
        webviewMessenger.sendSimpleMessage('startSlideshowAgain');
    });

    connection.onclose(async () => {
        logger.warn('signalr connection onclose');
        // setTimeout(() => {
        webviewMessenger.sendReconnectPresenter();
        // }, 3000);
    });

    if (!isReconnectingPresenter) {
        localService.removeToolbarActionsAndResetHost();
        try {
            await connection.start();
            await connection.invoke(
                'PresenterStartSlideshow',
                presenterDto(user),
                {
                    totalSlideCount: payload.totalSlideCount,
                    isAudienceSlideViewerEnabled: payload.isAudienceSlideViewerEnabled,
                },
                classSessionDto(user),
            );
            dispatch({
                type: ClassSessionActionTypes.START_SLIDESHOW,
                payload: connection,
            });
            localService.removeSignalrRetryCount();
            webviewMessenger.sendUsageLog(`[S] Signalr connected`);
            GtmService.sendStartSlideshowEvent(user.userId);
        } catch (error) {
            if (!isCpVerAtLeast('2.0.36')) {
                webviewMessenger.sendUsageLog(`[E] Signalr not connected (no retry). ${error}`);
                webviewMessenger.sendSimpleMessage('signalrNotConnected');
                logger.error('signalrStartSlideshow() error: ', error);
                sentryLogSignalrError(error, 'PresenterStartSlideshow', {
                    cpcsRegion,
                    hubBaseUrl,
                    presenterDto: presenterDto(user),
                    slideshowDto: {
                        totalSlideCount: payload.totalSlideCount,
                        isAudienceSlideViewerEnabled: payload.isAudienceSlideViewerEnabled,
                    },
                    createClassSessionDto: classSessionDto(user),
                });
            } else {
                const signalrRetryCount = localService.getSignalrRetryCount();
                // console.log('signalrRetryCount', signalrRetryCount);
                if (signalrRetryCount < 10) {
                    webviewMessenger.sendUsageLog(
                        `[E] Signalr not connected after ${signalrRetryCount} retries. Will try again. ${error}`,
                    );
                    webviewMessenger.sendSimpleMessage('startSlideshowAgain');
                    localService.setSignalrRetryCount(signalrRetryCount + 1);
                } else {
                    webviewMessenger.sendUsageLog(
                        `[E] Signalr not connected after ${signalrRetryCount} retries. Stop attempt. ${error}`,
                    );
                    localService.removeSignalrRetryCount();
                    logger.error('signalrStartSlideshow() error: ', error);

                    const healthCheckResult = await apiClassSessions.healthCheck(cpcsRegion);
                    if (!healthCheckResult) {
                        // if health check failed, it means the signalr server is down. Force connect to traffic manager
                        webviewMessenger.sendUsageLog(
                            `[E] Health check failed, Signalr server is down. Force connect to traffic manager`,
                        );
                        localService.setForceTrafficManager();
                        webviewMessenger.sendSimpleMessage('startSlideshowAgain');
                    }
                    sentryLogSignalrError(error, 'PresenterStartSlideshow', {
                        cpcsRegion,
                        hubBaseUrl,
                        presenterDto: presenterDto(user),
                        slideshowDto: {
                            totalSlideCount: payload.totalSlideCount,
                            isAudienceSlideViewerEnabled: payload.isAudienceSlideViewerEnabled,
                        },
                        createClassSessionDto: classSessionDto(user),
                        healthCheckResult,
                    });
                }
            }
        }
    } else {
        const payload = { email: user.email };
        try {
            await connection.start();
            await connection.invoke('PresenterRejoinClass', payload);
        } catch (error) {
            sentryLogSignalrError(error, 'PresenterRejoinClass', { cpcsRegion, hubBaseUrl, ...payload });
        }
        dispatch({
            type: ClassSessionActionTypes.SIGNALR_RECONNECTED,
            payload: connection,
        });
    }

    // setTimeout(() => {
    //     connection.stop();
    // }, 20000);
}

const updateParticipantList = (
    existingParticipants: ParticipantInterface[],
    participant: ParticipantInterface,
    isAdding: boolean,
) => {
    const remainingParticipants = existingParticipants.filter((p) => p.participantId !== participant.participantId);
    if (isAdding) remainingParticipants.push(participant);
    return remainingParticipants;
};

const presenterDto = (user: UserInterface) => {
    return {
        userId: user.userId,
        email: user.email,
        name: `${user.userProfile.firstName} ${user.userProfile.lastName}`,
        country: user.userProfile.country,
    };
};

const classSessionDto = (user: UserInterface): StartClassSessionDto => {
    const lastSavedClass = localService.getLastSavedClass();
    const reportWebhookUrl = user.userPreferences?.customBranding?.webhookUrl;
    if (
        !lastSavedClass ||
        !user.userProfile.savedClasses?.map((c) => c.savedClassId).includes(lastSavedClass.savedClassId)
    ) {
        // lastSavedClass doesn't exist, or user doesn't have this class
        localService.removeLastSavedClass();

        // if user has 0 saved classes, start demo class or public class (fallback); if user has 1 saved class, start it; if user has >1 saved classes, start public class
        const publicClassSessionDto: StartClassSessionDto = {
            classCode: null,
            classLimit: findUserClassLimit(user),
            savedClassId: null,
            isAllowGuests: true,
            reportWebhookUrl,
        };
        const savedClasses = user.userProfile.savedClasses || [];
        const demoClass = savedClasses.find((classItem) => classItem.savedClassCode === null);
        const savedClassesWithoutDemo = savedClasses.filter((classItem) => classItem.savedClassCode !== null);
        if (savedClassesWithoutDemo.length === 0) {
            if (demoClass)
                return {
                    classCode: null,
                    classLimit: findUserClassLimit(user),
                    savedClassId: demoClass.savedClassId,
                    isAllowGuests: true,
                    isSaveGuests: true,
                    reportWebhookUrl,
                };
            else return publicClassSessionDto;
        } else if (savedClassesWithoutDemo.length === 1) {
            return {
                classCode: savedClassesWithoutDemo[0].savedClassCode,
                classLimit: findUserClassLimit(user),
                savedClassId: savedClassesWithoutDemo[0].savedClassId,
                isAllowGuests: false,
                reportWebhookUrl,
            };
        } else return publicClassSessionDto;
    }
    logger.warn('START NEW SAVED CLASS');

    // start last saved class, but it's possible that class code has been changed, so we need to get the up-to-date class code
    const classCode =
        user.userProfile.savedClasses.find((c) => c.savedClassId === lastSavedClass.savedClassId)?.savedClassCode ||
        lastSavedClass.classCode;
    return {
        classCode,
        classLimit: findUserClassLimit(user),
        savedClassId: lastSavedClass.savedClassId,
        isAllowGuests: false,
        shouldResetStars: !isUserOnTrialOrPro(user) && lastSavedClass.classCode !== null,
    };
};

const checkGotoStep = (participants: ParticipantInterface[]) => {
    const onlineParticipantCount = filterOnlineParticipants(participants).length;
    if (onlineParticipantCount <= 0) return;
    const dto = localService.getGotoStepDto();
    if (!dto) return;
    gotoStep(dto);
    localService.removeGotoStepDto();
};

function startSlideshow(
    payload: { totalSlideCount: number; isAudienceSlideViewerEnabled: boolean },
    isReconnectingPresenter: boolean,
) {
    return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
        const user = getValidUserFromStore(
            isReconnectingPresenter ? 'startSlideshow:Reconnecting' : 'startSlideshow:New',
        );
        const cpcsRegion = getCpcsRegion(user, localService.getForceTrafficManager());
        webviewMessenger.sendHostLog(HostLogType.DEBUG, cpcsRegion);
        await signalrStartSlideshow(payload, cpcsRegion, dispatch, isReconnectingPresenter);
    };
}

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
async function checkAndUpdateUserClassSession(liveClassSession: UserClassSessionInterface, dispatch: any) {
    const user = getValidUserFromStore('checkAndUpdateUserClassSession');
    if (user.userClassSession && JSON.stringify(liveClassSession) === JSON.stringify(user.userClassSession)) {
        logger.warn('liveClassSession matches userClassSession');
        return;
    }
    logger.warn('liveClassSession does not match userClassSession, update userClassSession');
    const updatedUser = await apiUser.updateUserClassSession(user.email, liveClassSession);
    if (updatedUser) dispatch(userActions.updateUser(UserActionTypes.UPDATE_USER_CLASS_SESSION, updatedUser));
}

function endSlideshow() {
    return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
        const user = getValidUserFromStore('endSlideshow');
        activityActions.handleCloseAndEndOngoingActivity(dispatch);

        setTimeout(async () => {
            // add this delay because the last toolbar actions may take some time to be saved in localStorage
            const toolbarActions = localService.getToolbarActions();
            localService.removeToolbarActionsAndResetHost();
            const payload = { email: user.email, toolbarActions };
            const invokeHub = async () => {
                const connection = store.getState().connection;
                await connection.invoke('PresenterEndSlideshow', payload);
                await connection.stop();
                webviewMessenger.sendUsageLog(`[S] Signalr disconnected`);
            };
            const invokeHubOnError = async () => {
                const cpcsRegion = getCpcsRegion(user);
                if (!cpcsRegion) return;
                const hubBaseUrl = generateHubBaseUrl(cpcsRegion);
                apiClassSessions.endSlideshowThroughApi(hubBaseUrl, payload);
                const connection = store.getState().connection;
                await connection.stop();
            };
            try {
                await invokeHub();
            } catch {
                await invokeHubOnError();
            }
            dispatch({
                type: ClassSessionActionTypes.END_SLIDESHOW,
                payload: null,
            });
        }, 200);
    };
}

async function showCorrectAnswer(isChecked: boolean) {
    const user = getValidUserFromStore('showCorrectAnswer');
    const connection = store.getState().connection;
    if (connection) {
        logger.warn('signalrShowCorrectAnswer', isChecked);
        if (isChecked) {
            try {
                await connection.invoke('PresenterShowCorrectAnswer', { email: user.email }, isChecked);
            } catch (error) {
                // Ignore this error as it's still shown here on the presenter screen
                // sentryLogSignalrError(error, 'PresenterShowCorrectAnswer', {
                //     email: user.email,
                //     isChecked,
                // });
            }
        }
    }
}

async function gotoStep(payload: GotoStepDto) {
    const classSession = getValidClassSessionFromStore('gotoStep');
    const onlineParticipantCount = filterOnlineParticipants(classSession.participantList).length;
    if (onlineParticipantCount <= 0) {
        localService.saveGotoStepDto(payload);
        return;
    }
    localService.removeGotoStepDto();

    // Sometimes currentSlideIndex is 0 which means a hidden slide is navigated to. Skip
    if (payload.currentSlideIndex <= 0) return;

    const user = getValidUserFromStore('gotoStep');

    if (!payload.imageUrl) {
        const fullImageDataBase64 = `data:image/png;base64,${payload.base64EncodedImage}`;
        const slideName = `slide-${timeStampSuffix()}.jpg`;
        const slideFile = dataURLtoFile(fullImageDataBase64, slideName);

        let imageUrl = '';
        imageUrl = await apiUser.uploadSlide(slideFile, slideName, user.apiServer);
        if (imageUrl) {
            webviewMessenger.sendSlideImageUrl({
                slideId: payload.slideId,
                currentStep: payload.currentStep,
                imageUrl,
            });
        }
        payload.imageUrl = imageUrl;
    }
    const stepPayload = {
        email: user.email,
        totalSlideCount: payload.totalSlideCount,
        slideId: payload.slideId,
        currentSlideIndex: payload.currentSlideIndex,
        currentStep: payload.currentStep,
        imageUrl: payload.imageUrl,
    };
    // console.log('signalrGotoStep', stepPayload);
    const invokeHub = async () => {
        const connection = store.getState().connection;
        await connection.invoke('PresenterGotoStep', stepPayload);
    };
    const invokeHubOnError = (error: any) => {
        logger.error(error);
        sentryLogSignalrError(error, 'PresenterGotoStep', stepPayload);
    };
    await invokeHubWithRetry(invokeHub, invokeHubOnError);
}

async function removeParticipant(
    participant: ParticipantInterface,
    dispatch: (arg0: { type: string; payload: any }) => void,
) {
    const invokeHub = async () => {
        const connection = store.getState().connection;
        await connection.invoke('PresenterRemoveParticipant', participant);
        const updatedClassSession = {
            ...getValidClassSessionFromStore('removeParticipant'),
        };
        participant.left = new Date();
        updatedClassSession.participantList = updateParticipantList(
            updatedClassSession.participantList,
            participant,
            participant.isFromSavedClass,
        );
        dispatch(updateClassSession(ClassSessionActionTypes.PRESENTER_REMOVED_PARTICIPANT, updatedClassSession));
        webviewMessenger.sendClassSession(updatedClassSession);
        webviewMessenger.sendUsageLog(`[S] Participant removed`);
    };
    const invokeHubOnError = (error: any) => {
        sentryLogSignalrError(error, 'PresenterRemoveParticipant', participant);
    };
    await invokeHubWithRetry(invokeHub, invokeHubOnError);
}

function lockUnlockClass(isLocking: boolean) {
    return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
        const user = getValidUserFromStore('lockUnlockClass');
        const invokeHub = async () => {
            const connection = store.getState().connection;
            await connection.invoke('PresenterLockUnlockClass', { email: user.email }, isLocking);
            const updatedClassSession = {
                ...getValidClassSessionFromStore('lockUnlockClass'),
            };
            updatedClassSession.isLocked = isLocking;
            webviewMessenger.sendClassSession(updatedClassSession);
            dispatch(
                updateClassSession(
                    isLocking
                        ? ClassSessionActionTypes.PRESENTER_LOCKS_CLASS
                        : ClassSessionActionTypes.PRESENTER_UNLOCKS_CLASS,
                    updatedClassSession,
                ),
            );
        };
        const invokeHubOnError = (error: any) => {
            logger.error(error);
            sentryLogSignalrError(error, 'PresenterLockUnlockClass', {
                email: user.email,
                isLocking,
            });
        };
        await invokeHubWithRetry(invokeHub, invokeHubOnError);
    };
}

function toggleAllowGuests(isAllow: boolean) {
    return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
        const user = getValidUserFromStore('toggleAllowGuests');
        const invokeHub = async () => {
            const connection = store.getState().connection;
            await connection.invoke('PresenterTogglesAllowGuests', { email: user.email }, isAllow);
            const updatedClassSession = {
                ...getValidClassSessionFromStore('toggleAllowGuests'),
            };
            updatedClassSession.isAllowGuests = isAllow;
            dispatch(
                updateClassSession(
                    isAllow
                        ? ClassSessionActionTypes.PRESENTER_ALLOWS_GUESTS
                        : ClassSessionActionTypes.PRESENTER_DISALLOWS_GUESTS,
                    updatedClassSession,
                ),
            );
        };
        const invokeHubOnError = (error: any) => {
            logger.error(error);
            sentryLogSignalrError(error, 'PresenterTogglesAllowGuests', {
                email: user.email,
                isAllow,
            });
        };
        await invokeHubWithRetry(invokeHub, invokeHubOnError);
    };
}

function toggleSaveGuests(isSave: boolean) {
    return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
        const user = getValidUserFromStore('toggleSaveGuests');
        const invokeHub = async () => {
            const connection = store.getState().connection;
            await connection.invoke('PresenterTogglesSaveGuests', { email: user.email }, isSave);
            const updatedClassSession = {
                ...getValidClassSessionFromStore('toggleSaveGuests'),
            };
            updatedClassSession.isSaveGuests = isSave;
            dispatch(
                updateClassSession(
                    isSave
                        ? ClassSessionActionTypes.PRESENTER_TOGGLES_ON_SAVE_GUESTS
                        : ClassSessionActionTypes.PRESENTER_TOGGLES_OFF_SAVE_GUESTS,
                    updatedClassSession,
                ),
            );
        };
        const invokeHubOnError = (error: any) => {
            logger.error(error);
            sentryLogSignalrError(error, 'PresenterTogglesSaveGuests', {
                email: user.email,
                isSave,
            });
        };
        await invokeHubWithRetry(invokeHub, invokeHubOnError);
    };
}

function givePointsToParticipants(participantsAndPoints: ParticipantAndPointsDto[], callSignalr: boolean = true) {
    return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
        try {
            const updatedClassSession: ClassSessionInterface = JSON.parse(
                JSON.stringify(getValidClassSessionFromStore('givePointsToParticipants')),
            );

            participantsAndPoints.forEach((item) => {
                const targetParticipantIndex = updatedClassSession.participantList.findIndex(
                    (p) => p.participantId === item.participantId,
                );
                if (targetParticipantIndex >= 0)
                    updatedClassSession.participantList[targetParticipantIndex].participantPoints += item.points;
            });

            dispatch(updateClassSession(ClassSessionActionTypes.GIVE_POINTS_TO_PARTICIPANTS, updatedClassSession));
            if (callSignalr) await signalrGivePointsToParticipants(participantsAndPoints);
        } catch (error) {
            console.log(error);
        }
    };
}

async function signalrGivePointsToParticipants(participantsAndPoints: ParticipantAndPointsDto[]) {
    const user = getValidUserFromStore('signalrGivePointsToParticipants');
    const invokeHub = async () => {
        const connection = store.getState().connection;
        await connection.invoke('GivePointsToParticipants', { email: user.email }, participantsAndPoints);
    };
    const invokeHubOnError = (error: any) => {
        logger.error(error);
        sentryLogSignalrError(error, 'GivePointsToParticipants', {
            email: user.email,
            participantsAndPoints,
        });
    };
    await invokeHubWithRetry(invokeHub, invokeHubOnError);
}

function generateAiQuiz(slideId: number, prompt: string) {
    return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
        const user = getValidUserFromStore('generateAiQuiz');
        const connection = store.getState().connection;
        const dto: GenerateAiQuizDto = {
            email: user.email,
            slideId,
            systemMessage: t('lang_ai_quiz.prompt.systemMessage'),
            prompt,
            maxTokens: 2048,
            temperature: 0.9,
        };
        try {
            await connection.invoke('GenerateAiQuiz', dto);
        } catch (error) {
            sentryLogSignalrError(error, 'GenerateAiQuiz', dto);
            const updatedAiQuizState = JSON.parse(JSON.stringify(store.getState().aiQuiz)) as AiQuizInterface;
            if (!updatedAiQuizState[slideId]) return;
            updatedAiQuizState[slideId].hasGenerationDone = true;
            dispatch(aiQuizActions.quizGenerationDone(updatedAiQuizState));
        }
    };
}

function restartClass(
    savedClassId: string | null,
    savedClassCode: string | null,
    isAllowGuests: boolean,
    isSaveGuests: boolean,
) {
    return async (dispatch: any) => {
        const user = getValidUserFromStore('restartClass');
        dispatch({ type: ClassSessionActionTypes.TRIGGER_RESTART_CLASS });
        await activityActions.handleCloseAndEndOngoingActivity(dispatch);

        const toolbarActions = localService.getToolbarActions();
        localService.removeToolbarActionsAndResetHost();
        const invokeHub = async () => {
            const connection = store.getState().connection;
            await connection.invoke(
                'PresenterRestartClass',
                { email: user.email, toolbarActions },
                {
                    classLimit: findUserClassLimit(user),
                    classCode: savedClassCode,
                    savedClassId: savedClassId,
                    isAllowGuests,
                    isSaveGuests,
                    shouldResetStars: !isUserOnTrialOrPro(user) && savedClassCode !== null,
                    reportWebhookUrl: user.userPreferences?.customBranding?.webhookUrl,
                },
            );
            webviewMessenger.sendUsageLog(`[S] Class restarted for: ${savedClassId ? 'Saved class' : 'Public class'}`);
        };
        const invokeHubOnError = (error: any) => {
            logger.error('restartClass() error', error);
            sentryLogSignalrError(error, 'PresenterRestartClass', {
                email: user.email,
                classLimit: findUserClassLimit(user),
                classCode: savedClassCode,
                savedClassId: savedClassId,
                isAllowGuests,
                isSaveGuests,
            });
            dispatch(userActions.showApiError());
        };
        await invokeHubWithRetry(invokeHub, invokeHubOnError);
    };
}

const calculateUniqueParticipantCountAndNotifyHost = (participantIdsWithDuplicates: any[]) => {
    const uniqueParticipantCount = participantIdsWithDuplicates.filter(
        (element, index, array) => array.indexOf(element) === index,
    ).length;
    webviewMessenger.sendActivityOrVotingParticipantCount(uniqueParticipantCount);
};

const updateClassSession = (actionType: string, payload: ClassSessionInterface) => ({
    type: actionType,
    payload: payload,
});

export async function invokeHubWithRetry(invokeHub: Function, invokeHubOnError: Function) {
    try {
        await invokeHub();
    } catch (error) {
        for (let retryCount = 0; retryCount < 10; retryCount++) {
            // logger.log(retryCount);
            if (retryCount === 0) {
                webviewMessenger.sendReconnectPresenter();
                localService.markSignalrRetry();
            }
            await sleep(500);
            if (localService.checkIfSignalrRetryDone()) {
                await invokeHub();
                return;
            }
        }
        invokeHubOnError(error);
    }
}

function timeout(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
async function sleep(ms: number) {
    await timeout(ms);
}
