import { TextField, Typography } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import CommonButton from '../../../../components/Common/CommonButton';
import apiUser from '../../../../services/apiUser';
import { getValidUserFromStore } from '../../../../helpers/storeHelpers';

import { utilConstants } from '../../../../constants/utils.constants';
import SubscriptionBackButton from './SubscriptionBackButton';
import { useDispatch } from 'react-redux';
import { userActions } from '../../../../actions/user.actions';
import { QuotaConstants } from '../../../../constants/quota-constants';

type FormValues = {
    key: string;
};

const KeySchema = yup
    .object({
        key: yup.string().required().min(2).max(20),
    })
    .required();

const ChangeProductKey = ({ setVerifiedKey, setCurrentPage }: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = getValidUserFromStore('ChangeProductKey');

    const [isProcessing, setIsProcessing] = useState(false);
    const [productKey, setProductKey] = useState<string>('');
    const [errMsgAPI, setErrMsgAPI] = useState<string>('');

    const { register, handleSubmit, formState, setError } = useForm<FormValues>({ resolver: yupResolver(KeySchema) });
    const { errors }: any = formState;
    const onSubmit: SubmitHandler<FormValues> = (data) => {
        if (data.key.toUpperCase() === QuotaConstants.TRIAL_KEY) {
            setError('key', { type: 'errorFromAPI' }, { shouldFocus: true });
            setErrMsgAPI('err_key_is_trial');
            return;
        } else verifyKey(data.key);
    };

    const verifyKey = async (userProductKey: string) => {
        setIsProcessing(true);
        const verifyKeyReply = await apiUser.verifyKey(user.email, userProductKey);
        if (!verifyKeyReply) {
            setIsProcessing(false);
            dispatch(userActions.showApiError());
            return;
        } else if (verifyKeyReply.errorMessage) {
            setIsProcessing(false);
            setError('key', { type: 'errorFromAPI' }, { shouldFocus: true });
            setErrMsgAPI(verifyKeyReply.errorMessage);
        } else {
            setIsProcessing(false);
            setVerifiedKey(verifyKeyReply);
            setCurrentPage(utilConstants.SUBSCRIPTION_PAGE_TYPE.Confirm_Change_Key);
        }
    };

    return (
        <Fragment>
            <SubscriptionBackButton
                setCurrentPage={setCurrentPage}
                previousPage={utilConstants.SUBSCRIPTION_PAGE_TYPE.Landing_Page}
            />
            <div className="change_product_key_sec">
                <div className="product_key_text">
                    <Fragment>
                        {' '}
                        <Typography variant="h4">{t('lang_subscription.txt_change_product_key')}</Typography>
                        <Typography variant="body1">
                            {t('lang_subscription.txt_enter_product_key_would_like_to_change')}
                        </Typography>
                    </Fragment>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="profile_form">
                        <div className="row">
                            <div className="col12">
                                <div className="pf_form">
                                    <TextField
                                        {...register('key')}
                                        id="standard-basic"
                                        label={t('lang_subscription.field_label_product_key')}
                                        variant="standard"
                                        autoComplete="none"
                                        error={errors.key && Object.keys(errors.key).length > 0 ? true : false}
                                        onChange={(e) => {
                                            errors.key = {};
                                            setProductKey(e.target.value.trim().toUpperCase());
                                        }}
                                        value={productKey}
                                        helperText={
                                            errors?.key?.type === 'required'
                                                ? t('lang_subscription.err_key_required')
                                                : errors?.key?.type === 'min'
                                                ? t('lang_subscription.err_key_too_short')
                                                : errors?.key?.type === 'max'
                                                ? t('lang_subscription.err_key_too_long')
                                                : errors?.key?.type === 'invalid'
                                                ? t('lang_subscription.err_key_required')
                                                : errors?.key?.type === 'errorFromAPI'
                                                ? t(`lang_subscription.${errMsgAPI}`)
                                                : ''
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="profile_update_btn">
                        <CommonButton
                            isLoading={isProcessing}
                            variant="contained"
                            type="submit"
                            color="primary"
                            text={
                                isProcessing
                                    ? t('lang_subscription.btn_verifying') + '......'
                                    : t('lang_subscription.btn_verify')
                            }
                        />
                    </div>
                </form>
            </div>
        </Fragment>
    );
};

export default ChangeProductKey;
