import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { FitToViewport } from 'react-fit-to-viewport';
import { useNavigate, useLocation } from 'react-router-dom';

import { Typography } from '@mui/material';

import styles from './QuickPollSelect.module.scss';

import { generateActivityId } from '../../../helpers/activityHelpers';
import { getActivityFromStore } from '../../../helpers/storeHelpers';
import { localService } from '../../../services/localStorageService';
import { activityActions } from '../../../actions/activity.action';

import { UpdateToolbarActionsType } from '../../../dtos/update-toolbar-actions.dto';
import { utilConstants } from '../../../constants/utils.constants';
import { ViewportConstants } from '../../../constants/viewport-constants';

import QuickPollPresets from './QuickPollPresets';
import EmptyModal from '../../EmptyModal/EmptyModal';
import BackUi from '../../../components/Common/BackUi';
import ActivityHeader from '../ActivityHeader';
import Header from '../../../components/layout/Header/Header';
import CommonButton from '../../../components/Common/CommonButton';

interface SelectedPollInterface {
    qpType: string;
    qpChoices: string[];
}

function QuickPollSelect() {
    const location = useLocation();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const activity = getActivityFromStore();
    const activityProps = activity.activityProps;
    const activityType = activityProps?.activityType || '';
    const navigate = useNavigate();
    const [showEmptyModal, setShowEmptyModal] = useState(false);
    const [selectedPoll, setSelectedPoll] = useState<SelectedPollInterface>();
    const [selectedPresetIndex, setSelectedPresetIndex] = useState<number>();
    const [selectedNumberIndex, setSelectedNumberIndex] = useState<number>();
    const [isLaunching, setIsLaunching] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const showBackToMyClass = location.state ? true : false;

    useEffect(() => {
        setShowEmptyModal(true);
        setTimeout(() => {
            setShowEmptyModal(false);
        }, 1);
    }, []);

    useEffect(() => {
        if (activityType === utilConstants.ACTIVITY_TYPE.QUICK_POLL) {
            navigate('/activity');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activity]);

    const optionQuickPoll = {
        qpType: 'qpMultiple',
        qpChoices: [
            JSON.stringify({ id: 'a', text: 'A', color: '#06D6A0' }),
            JSON.stringify({ id: 'b', text: 'B', color: '#EF476F' }),
            JSON.stringify({ id: 'c', text: 'C', color: '#2AA4E3' }),
            JSON.stringify({ id: 'd', text: 'D', color: '#FFBA00' }),
            JSON.stringify({ id: 'e', text: 'E', color: '#7D29C0' }),
            JSON.stringify({ id: 'f', text: 'F', color: '#FF871E' }),
            JSON.stringify({ id: 'g', text: 'G', color: '#049F76' }),
            JSON.stringify({ id: 'h', text: 'H', color: '#F25240' }),
        ],
    };

    const runQuickPoll = () => {
        if (!selectedPoll) {
            setErrorMessage(t(`lang_activity.txt_select_poll_type`));
            return;
        }
        setIsLaunching(true);
        const activityType = utilConstants.ACTIVITY_TYPE.QUICK_POLL;
        const payload = {
            activityId: generateActivityId(activityType),
            activityType,
            countdown: 0,
            ...selectedPoll,
        };
        dispatch(activityActions.startActivity(payload));
        localService.updateToolbarActionsFromWebview(UpdateToolbarActionsType.quickPoll);
    };

    const handleSelectNumberOptions = (num: number, index: number) => {
        setErrorMessage('');
        setSelectedPoll({ qpType: optionQuickPoll.qpType, qpChoices: optionQuickPoll.qpChoices.slice(0, num) });
        setSelectedPresetIndex(undefined);
        setSelectedNumberIndex(index);
    };

    const handleSelectOptions = (index: number) => {
        setErrorMessage('');
        setSelectedPoll({ qpType: QuickPollPresets[index].qpType, qpChoices: QuickPollPresets[index].qpChoices });
        setSelectedPresetIndex(index);
        setSelectedNumberIndex(undefined);
    };

    const backToMyClass = () => {
        navigate('/myclass');
    };

    return (
        <Fragment>
            <div className="black">
                <FitToViewport
                    className="viewport"
                    width={ViewportConstants.width}
                    height={ViewportConstants.height}
                    minZoom={ViewportConstants.minZoom}
                    maxZoom={ViewportConstants.maxZoom}
                >
                    <Fragment>
                        {showEmptyModal && <EmptyModal viewportConfig={ViewportConstants} />}
                        <Header appBarTitle={t(`lang_activity.${utilConstants.ACTIVITY_TYPE.QUICK_POLL}`)}>
                            <Fragment>{activity.activityProps && <ActivityHeader activity={activity} />}</Fragment>
                        </Header>

                        <div className={styles.main_sec}>
                            <div className={styles.quickPoll_sec}>
                                <div className={styles.quickPollContent}>
                                    <div className={styles.option}>
                                        {QuickPollPresets.map((poll, index) => (
                                            <button
                                                key={index}
                                                className={selectedPresetIndex === index ? `${styles.active}` : ''}
                                                onClick={() => handleSelectOptions(index)}
                                                onDoubleClick={() => {
                                                    runQuickPoll();
                                                }}
                                            >
                                                <div className={styles.img_icon}>
                                                    <img src={poll.qpImage} alt="" />
                                                </div>
                                                <div className={styles.text}>{t(`lang_activity.${poll.qpType}`)}</div>
                                            </button>
                                        ))}
                                    </div>
                                    <h3 className={styles.subHeading}>
                                        {t(`lang_activity.txt_select_number_of_option_for_poll`)}{' '}
                                    </h3>
                                    <div className={styles.optionBtn}>
                                        {[2, 3, 4, 5, 6, 7, 8].map((num, index) => (
                                            <button
                                                key={index}
                                                className={selectedNumberIndex === index ? `${styles.active}` : ''}
                                                onClick={() => handleSelectNumberOptions(num, index)}
                                                onDoubleClick={() => {
                                                    runQuickPoll();
                                                }}
                                            >
                                                {num}
                                            </button>
                                        ))}
                                    </div>
                                    {errorMessage ? (
                                        <div className={styles.error}>
                                            <Typography variant="body1">{errorMessage}</Typography>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <footer className="footer_sec participant_sec_foot">
                            {showBackToMyClass && (
                                <div className="back" onClick={() => backToMyClass()}>
                                    <BackUi />
                                </div>
                            )}
                            <div className="footer_middle gap">
                                <CommonButton
                                    className="button foot_btn_blue"
                                    text={t(`lang_activity.btn_start_poll`)}
                                    isLoading={isLaunching}
                                    clickFunction={runQuickPoll}
                                />
                            </div>
                        </footer>
                    </Fragment>
                </FitToViewport>
            </div>
        </Fragment>
    );
}

export default QuickPollSelect;
