import { Typography } from '@mui/material';
import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { userActions } from '../../../../actions/user.actions';
import CommonButton from '../../../../components/Common/CommonButton';
import { UserActionTypes } from '../../../../constants/user-action-types';
import { utilConstants } from '../../../../constants/utils.constants';
import { getValidUserFromStore } from '../../../../helpers/storeHelpers';
import apiUser from '../../../../services/apiUser';
import SubscriptionBackButton from './SubscriptionBackButton';

const ConfirmRevokeCancellation = ({ setCurrentPage }: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = getValidUserFromStore('ConfirmRevokeCancellation');
    const [isProcessing, setIsProcessing] = useState(false);
    const [isSuccessfullyRevoked, setIsSuccessfullyRevoked] = useState(false);

    const proceedReactiveSubscriptionPlan = async () => {
        setIsProcessing(true);
        const revokePlanSuccess = await apiUser.revokeCancellationZoho(user.userSubscription.subscriptionId || '');
        if (!revokePlanSuccess) {
            setIsProcessing(false);
            dispatch(userActions.showApiError());
            return;
        }
        const updatedUser = await apiUser.updateUserSubscription(user.email, {
            event_type: 'subscription_scheduled_cancellation_removed',
        });
        if (!updatedUser) {
            setIsProcessing(false);
            dispatch(userActions.showApiError());
            return;
        }
        dispatch(userActions.updateUser(UserActionTypes.CANCELLATION_REVOKED, updatedUser));
        setIsProcessing(false);
        setIsSuccessfullyRevoked(true);
    };

    return (
        <Fragment>
            <SubscriptionBackButton
                setCurrentPage={setCurrentPage}
                previousPage={utilConstants.SUBSCRIPTION_PAGE_TYPE.Landing_Page}
            />
            <div className="change_product_key_sec">
                {!isSuccessfullyRevoked ? (
                    <Fragment>
                        <div className="product_key_text">
                            <Typography variant="h4">{t('lang_subscription.txt_revoke_cancellation')}</Typography>
                            <Typography variant="body1">
                                {t('lang_subscription.txt_confirm_revoke_scheduled_subscription_cancellation')}
                            </Typography>
                        </div>

                        <div className="profile_update_btn">
                            <CommonButton
                                type="button"
                                className="btnlink"
                                color="primary"
                                text={t('lang_common.btn_confirm')}
                                isLoading={isProcessing}
                                clickFunction={() => {
                                    proceedReactiveSubscriptionPlan();
                                }}
                            />
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className="product_key_text">
                            <Typography variant="h4">{t('lang_subscription.txt_revoke_cancellation')}</Typography>
                            <Typography variant="body1">
                                {t('lang_subscription.txt_revoke_cancellation_successful')}
                            </Typography>
                        </div>
                        <div className="profile_update_btn">
                            <CommonButton
                                type="button"
                                className="btnlink"
                                color="primary"
                                text={t('lang_subscription.btn_go_to_subscription')}
                                clickFunction={() => {
                                    setCurrentPage(utilConstants.SUBSCRIPTION_PAGE_TYPE.Landing_Page);
                                }}
                            />
                        </div>
                    </Fragment>
                )}
            </div>
        </Fragment>
    );
};

export default ConfirmRevokeCancellation;
