import { useTranslation } from 'react-i18next';

import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Typography } from '@mui/material';

import CommonButton from '../../../../components/Common/CommonButton';

const DeleteAlert = ({
    onConfirmDeleteClass,
    onCloseAlert,
    content,
    isDeleting,
    deleteType,
    handleDeleteParticipant,
    highlightWarning,
}: any) => {
    const { t } = useTranslation();

    const deleteParticipant = () => {
        if (deleteType === 'class' || deleteType === 'group') {
            onConfirmDeleteClass();
        } else {
            handleDeleteParticipant();
            onCloseAlert();
        }
    };

    return (
        <>
            <div className="media">
                <div className="media_icon">
                    <ReportProblemIcon className="warning" />
                </div>
                <div className="media_body">
                    <div className="alert_content">
                        <Typography variant="body1">{content}</Typography>
                    </div>
                </div>
            </div>

            <div className="button_sec">
                {isDeleting ? (
                    <CommonButton
                        className="solid"
                        isLoading={true}
                        variant="contained"
                        type="button"
                        text={t('lang_common.btn_deleting')}
                        clickFunction={onConfirmDeleteClass}
                    />
                ) : (
                    <CommonButton
                        isLoading={false}
                        variant="contained"
                        type="button"
                        text={t('lang_common.btn_yes')}
                        clickFunction={deleteParticipant}
                        className={`${highlightWarning && 'highlightWarning'}`}
                    />
                )}
                <CommonButton
                    isLoading={false}
                    variant="text"
                    type="button"
                    text={t('lang_common.btn_no')}
                    clickFunction={onCloseAlert}
                />
            </div>
        </>
    );
};

export default DeleteAlert;
