import React from 'react';
import ErrorPage from './pages/errorBoundary/ErrorPage';
import { sentryLogComponentError } from './services/sentryService';
import { getCpVer, createLogMessage } from './helpers/utils';
import { ReducerName } from './constants/reducer-name.enum';

interface IState {
    error: null;
    errorInfo: null;
}

export default class ErrorBoundary extends React.Component<any, IState> {
    constructor(props: any) {
        super(props);
        this.state = { error: null, errorInfo: null };
        this.sendError = this.sendError.bind(this);
    }

    componentDidCatch(error: any, errorInfo: any) {
        const payload = {
            cpVer: getCpVer(),
            page: window.location.pathname,
            error: error.toString(),
            errorInfo: errorInfo,
            userLocalStorage: createLogMessage(localStorage.getItem(ReducerName.user)),
        };
        const firstComponentName = errorInfo.componentStack.split('\n')[1].trim().split(' ')[1];
        sentryLogComponentError(error.toString(), `component:${firstComponentName}`, payload);
        this.sendError(error.toString());
        this.setState({ errorInfo: error.toString() });
    }

    sendError(error: string) {}

    render() {
        if (this.state.errorInfo) {
            return <ErrorPage></ErrorPage>;
        }

        return this.props.children;
    }
}
