import { Fragment, useState, useRef, useEffect } from 'react';
import { isNaN } from 'lodash';
import { TextField, Typography } from '@mui/material';

interface EditableInputTimeInterface {
    timerValue: number;
    setTimerValue: (arg: number) => void;
}

const EditableInputTime = ({ timerValue, setTimerValue }: EditableInputTimeInterface) => {
    const timerSecondRef = useRef<any>(null);
    const timerMinuteRef = useRef<any>(null);

    const [minuteWidth, setMinuteWidth] = useState('compact');
    const [secondWidth, setSecondWidth] = useState('compact');

    let timerMinuteValue = Math.floor(timerValue / 60);
    let timerSecondValue = Math.floor(timerValue % 60);

    const dynamicWidth = (value: number, type: string) => {
        if (value < 10) {
            if (type === 'min') setMinuteWidth('compact');
            if (type === 'sec') setSecondWidth('compact');
        } else {
            if (type === 'min') setMinuteWidth('extend');
            if (type === 'sec') setSecondWidth('extend');
        }
    };

    const timerChangeHandler = (input: string | number, type: string, callType?: string) => {
        const value = typeof input === 'string' ? parseInt(input) : input;
        if (value < 0) return;

        if (type === 'min') {
            dynamicWidth(value, 'min');
            timerMinuteValue = value || 0;
            if (value.toString.length >= 2 && callType !== 'keyPress')
                timerSecondRef.current.children[0].children[0].focus();
        } else if (type === 'sec') {
            dynamicWidth(value, 'sec');
            timerSecondValue = value || 0;
        }

        const inputTimeValue = timerMinuteValue * 60 + timerSecondValue;

        if (isNaN(inputTimeValue)) return setTimerValue(0);
        else if (inputTimeValue > 5999) return setTimerValue(5999);
        else return setTimerValue(inputTimeValue);
    };

    const arrowKeyPressHandler = (key: string, type: string) => {
        switch (key) {
            case 'ArrowUp':
            case 'ArrowRight':
                type === 'min'
                    ? timerChangeHandler(timerMinuteValue + 1, 'min', 'keyPress')
                    : timerChangeHandler(timerSecondValue + 1, 'sec', 'keyPress');
                break;
            case 'ArrowDown':
            case 'ArrowLeft':
                type === 'min'
                    ? timerChangeHandler(timerMinuteValue - 1, 'min', 'keyPress')
                    : timerChangeHandler(timerSecondValue - 1, 'sec', 'keyPress');
                break;
        }
    };

    useEffect(() => {
        dynamicWidth(timerMinuteValue, 'min');
        dynamicWidth(timerSecondValue, 'sec');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timerValue]);

    return (
        <Fragment>
            <TextField
                ref={timerMinuteRef}
                value={timerMinuteValue || 0}
                inputProps={{ maxLength: 2 }}
                onChange={(e: any) => {
                    timerChangeHandler(e.target.value, 'min');
                }}
                onFocus={(event: any) => event.target.select()}
                onKeyDown={(event: any) => arrowKeyPressHandler(event.key, 'min')}
                autoComplete="none"
                variant="standard"
                style={{
                    userSelect: 'none',
                    width: `${timerMinuteValue ? (minuteWidth === 'compact' ? '32px' : '62px') : '32px'}`,
                    caretColor: 'transparent',
                }}
            />
            <Typography
                onClick={() => timerMinuteRef.current.children[0].children[0].focus()}
                variant="caption"
                style={{ userSelect: 'none' }}
            >
                m
            </Typography>
            <TextField
                ref={timerSecondRef}
                value={timerSecondValue || 0}
                inputProps={{ maxLength: 2 }}
                onChange={(e: any) => {
                    timerChangeHandler(e.target.value, 'sec');
                }}
                onFocus={(event: any) => event.target.select()}
                onKeyDown={(event: any) => arrowKeyPressHandler(event.key, 'sec')}
                autoComplete="none"
                variant="standard"
                style={{
                    userSelect: 'none',
                    width: `${timerSecondValue ? (secondWidth === 'compact' ? '32px' : '62px') : '32px'}`,
                    caretColor: 'transparent',
                }}
            />
            <Typography
                onClick={() => timerSecondRef.current.children[0].children[0].focus()}
                variant="caption"
                style={{ userSelect: 'none' }}
            >
                s
            </Typography>
        </Fragment>
    );
};

export default EditableInputTime;
