import { Avatar, Button, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useDispatch, useSelector } from 'react-redux';
import { activityActions } from '../../../actions/activity.action';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CustomAvatar, { AvatarType } from '../../../components/Common/CustomAvatar';
import GivePointToResponse from '../../../components/Common/GivePointToResponse';
import { AnimationConfig } from '../../../constants/animation-configs';
import { utilConstants } from '../../../constants/utils.constants';
import { checkIfSingleBlankIsCorrect, searchKeywordToArray } from '../../../helpers/activityHelpers';
import { getActivityFromStore } from '../../../helpers/storeHelpers';
import { generateAsterisksFromName } from '../../../helpers/utils';
import { ActivityPropsInterface } from '../../../interfaces/activity-props.interface';
import ActivityResponseInterface from '../../../interfaces/activity-response.interface';
import { FillInColorConstants } from './FillBlanksColor';
import webviewMessenger from '../../../services/webviewMessenger';
import StoreInterface from '../../../interfaces/store.interface';

function FillBlanksRow({
    index,
    totalTheadWidth,
    totalbodyWidth,
    isNamesHidden,
    response,
    searchKeyword,
    showStarColumn,
    hasShownCorrectAnswers,
    trowWidth,
    button,
    canEditResponsesInView,
    anyResponseHasStars,
}: any) {
    const dispatch = useDispatch();
    const classSession = useSelector((state: StoreInterface) => state.classSession);
    const activity = getActivityFromStore();
    const activityProps = activity.activityProps as ActivityPropsInterface;
    const fbCorrectAnswers = activityProps.fbCorrectAnswers as string[];
    const fbNumOfBlanks = activityProps.fbNumOfBlanks as number;

    const [pointsAdded, setPointsAdded] = useState(0);
    const timeOutPointUpdate = useRef<any>();

    const calculateClassName = (answer: string, index: number): string => {
        if (activity.activityMode === utilConstants.ACTIVITY_MODE.START_ACTIVITY) return '';
        if (!hasShownCorrectAnswers) return '';

        if (fbCorrectAnswers.length === 0) return '';
        const possibleCorrectAnswersForThisBlank = fbCorrectAnswers[index] || '';
        if (!possibleCorrectAnswersForThisBlank) return '';
        if (JSON.parse(possibleCorrectAnswersForThisBlank)?.length <= 0) return '';
        return checkIfSingleBlankIsCorrect(possibleCorrectAnswersForThisBlank, answer) ? 'correct' : 'wrong';
    };

    const displayResponse = (response: ActivityResponseInterface) => {
        if (!response.responseData) return null;
        const responseData: string[] = JSON.parse(response.responseData);
        let updatedResponse: string[] = [];
        for (let index = 0; index < fbNumOfBlanks; index++) {
            if (responseData[index]) updatedResponse.push(responseData[index]);
            else updatedResponse.push('');
        }
        return updatedResponse.map((blankContent: string, index: number) => (
            <td key={index} style={{ width: trowWidth }}>
                <span className={calculateClassName(blankContent, index)}>
                    <Highlighter
                        highlightClassName="YourHighlightClass"
                        searchWords={searchKeywordToArray(searchKeyword)}
                        autoEscape={true}
                        textToHighlight={blankContent}
                    />
                </span>
            </td>
        ));
    };

    const givePoint = (points: number) => {
        dispatch(activityActions.handleGivePointsToResponses([{ response, points: points }]));
    };

    const handleGivePointButtonClick = () => {
        clearTimeout(timeOutPointUpdate.current);
        setPointsAdded(pointsAdded + 1);
    };

    useEffect(() => {
        if (pointsAdded > 0) {
            timeOutPointUpdate.current = setTimeout(() => {
                givePoint(pointsAdded);
                setPointsAdded(0);
            }, AnimationConfig.GIVE_POINT_DELAY);
        }
        return () => clearTimeout(timeOutPointUpdate.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pointsAdded]);

    const onDeleteResponse = (response: ActivityResponseInterface) => {
        dispatch(activityActions.deleteIndividualResponses([response]));
    };

    return (
        <React.Fragment>
            <tr className="trow">
                <td
                    style={{
                        width: totalbodyWidth,
                    }}
                >
                    <div>
                        {isNamesHidden ? (
                            <>
                                {' '}
                                <Avatar
                                    alt={response.participantName}
                                    style={{
                                        backgroundColor:
                                            FillInColorConstants.colorArray[
                                                index % FillInColorConstants.colorArray.length
                                            ],
                                    }}
                                >
                                    ?
                                </Avatar>{' '}
                                <Typography variant="body1">
                                    {generateAsterisksFromName(response.participantName)}
                                </Typography>
                            </>
                        ) : (
                            <>
                                {' '}
                                <CustomAvatar
                                    avatarName={response.participantName}
                                    avatarUrl={response.participantAvatar}
                                    type={AvatarType.PARTICIPANT}
                                />
                                <Highlighter
                                    highlightClassName="YourHighlightClass"
                                    searchWords={searchKeywordToArray(searchKeyword)}
                                    autoEscape={true}
                                    textToHighlight={response.participantName}
                                />
                            </>
                        )}
                    </div>
                </td>
                <>{displayResponse(response)}</>
                {showStarColumn ? (
                    <td
                        style={{
                            width: button,
                        }}
                        className={
                            response.responsePoints || pointsAdded
                                ? !canEditResponsesInView && anyResponseHasStars
                                    ? 'last_child active viewStarOnly'
                                    : 'last_child active'
                                : 'last_child'
                        }
                    >
                        <div className="response_btn_wrapper">
                            <GivePointToResponse
                                response={response}
                                pointsAdded={pointsAdded}
                                onGivePointButtonClick={() => {
                                    webviewMessenger.sendUsageLog(
                                        `${classSession ? '[S] ' : ''}Awarded star to a FIB response`,
                                    );
                                    handleGivePointButtonClick();
                                }}
                            />
                            <Button aria-label="delete" className="delete" color="error">
                                <DeleteForeverOutlinedIcon
                                    onClick={() => {
                                        webviewMessenger.sendUsageLog(
                                            `${classSession ? '[S] ' : ''}Deleted a FIB response`,
                                        );
                                        onDeleteResponse(response);
                                    }}
                                />
                            </Button>
                        </div>
                    </td>
                ) : null}
            </tr>
        </React.Fragment>
    );
}

export default FillBlanksRow;
