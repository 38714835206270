import { localService } from './../services/localStorageService';
import ParticipantInterface from '../interfaces/participant.interface';
import UserInterface from '../interfaces/user-interface';

export const filterOnlineParticipants = (participants: ParticipantInterface[]) => {
    return participants.filter((p) => p.joined && !p.left);
};

export const getCpcsRegion = (user: UserInterface, forceTrafficManager = false): string => {
    const TM = process.env.REACT_APP_DEFAULT_CPCS_REGION || '';
    // console.log('TM', TM);
    if (forceTrafficManager) {
        localService.removeForceTrafficManager();
        return TM;
    }
    if (user.userClassSession) return user.userClassSession.cpcsRegion;
    return TM;
};

export const generateHubBaseUrl = (cpcsRegion: string): string => {
    const hubBaseUrl = cpcsRegion === 'localhost' ? `https://localhost:44349` : `https://${cpcsRegion}.classpoint.app`;
    return hubBaseUrl;
};
