const QuickPollPresets = [
    {
        qpType: 'qpTrueFalse',
        qpImage: './assets/quickPoll/tick.svg',
        qpChoices: [
            JSON.stringify({
                id: 'true',
                iconUrl: './assets/logo/ic_true.png',
                color: '#5ACD00',
            }),
            JSON.stringify({
                id: 'false',
                iconUrl: './assets/logo/ic_false.png',
                color: '#EF476F',
            }),
        ],
    },
    {
        qpType: 'qpYesNoUnsure',
        qpImage: './assets/quickPoll/think.svg',
        qpChoices: [
            JSON.stringify({
                id: 'yes',
                iconUrl: './assets/logo/ic_yes.png',
                color: '#5ACD00',
            }),
            JSON.stringify({
                id: 'no',
                iconUrl: './assets/logo/ic_no.png',
                color: '#EF476F',
            }),
            JSON.stringify({
                id: 'unsure',
                iconUrl: './assets/logo/ic_unsure.png',
                color: '#2AA4E3',
            }),
        ],
    },
    {
        qpType: 'qpRating',
        qpImage: './assets/quickPoll/cloud.svg',
        qpChoices: [
            JSON.stringify({
                id: 'stronglyAgree',
                iconUrl: './assets/logo/ic_s-agree.png',
                color: '#5ACD00',
            }),
            JSON.stringify({
                id: 'agree',
                iconUrl: './assets/logo/ic_agree.png',
                color: '#ACE211',
            }),
            JSON.stringify({
                id: 'neutral',
                iconUrl: './assets/logo/ic_neutral.png',
                color: '#FFC700',
            }),
            JSON.stringify({
                id: 'disagree',
                iconUrl: './assets/logo/ic_disagree.png',
                color: '#FF7A00',
            }),
            JSON.stringify({
                id: 'stronglyDisagree',
                iconUrl: './assets/logo/ic_s-disagree.png',
                color: '#EF476F',
            }),
        ],
    },
];

export default QuickPollPresets;
