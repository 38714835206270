import { Link, Typography } from '@mui/material';
import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { userActions } from '../../../../actions/user.actions';
import CommonButton from '../../../../components/Common/CommonButton';
import { UserActionTypes } from '../../../../constants/user-action-types';
import { utilConstants } from '../../../../constants/utils.constants';
import { getValidUserFromStore } from '../../../../helpers/storeHelpers';
import apiUser from '../../../../services/apiUser';
import SubscriptionBackButton from './SubscriptionBackButton';
import { displayLocaleDate } from '../../../../helpers/languageHelpers';
import FeatureList from './FeatureList';
import CommonAlert from '../../../../components/Common/CommonAlert';
import { ViewportSavedClasses } from '../../../../constants/viewport-constants';
import { TrialFlow } from '../../../activateTrial/TrialFlow';

const CancelSubscriptionConfirm = ({ setCurrentPage, cancelSubscriptionReason }: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = getValidUserFromStore('CancelSubscriptionConfirm');
    const nextBillingDate = displayLocaleDate(new Date(user.userSubscription.nextBillingDate || '0000-01-01'));
    const [hasCanceled, setHasCanceled] = useState(false);
    const [isTrialFlowModalOpen, setIsTrialFlowModalOpen] = useState(false);
    const [isActivatingTrial, setIsActivatingTrial] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const confirmCancelSubscription = async () => {
        setIsProcessing(true);
        const cancelPlanSuccess = await apiUser.scheduleCancellationZoho(
            user.userSubscription.subscriptionId || '',
            cancelSubscriptionReason,
        );
        if (!cancelPlanSuccess) {
            setIsProcessing(false);
            dispatch(userActions.showApiError());
            return;
        }
        const updatedUser = await apiUser.updateUserSubscription(user.email, {
            event_type: 'subscription_cancellation_scheduled',
        });
        if (!updatedUser) {
            dispatch(userActions.showApiError());
            setIsProcessing(false);
            return;
        }
        dispatch(userActions.updateUser(UserActionTypes.CANCELLATION_SCHEDULED, updatedUser));
        setIsProcessing(false);
        setHasCanceled(true);
    };

    const giveUpCancelSubscription = () => {
        setCurrentPage(utilConstants.SUBSCRIPTION_PAGE_TYPE.Landing_Page);
    };

    return (
        <Fragment>
            <SubscriptionBackButton
                setCurrentPage={setCurrentPage}
                previousPage={
                    hasCanceled
                        ? utilConstants.SUBSCRIPTION_PAGE_TYPE.Landing_Page
                        : utilConstants.SUBSCRIPTION_PAGE_TYPE.Cancel_Subscription
                }
            />
            <div className="change_product_key_sec">
                <div className="product_key_text">
                    <Fragment>
                        <Typography variant="h4">{t('lang_subscription.txt_cancel_subscription')}</Typography>

                        {!hasCanceled ? (
                            <Typography variant="body1">
                                {t('lang_subscription.txt_cancel_subscription_confirm_msg', {
                                    nextBillingDate: nextBillingDate,
                                })}
                            </Typography>
                        ) : (
                            <Typography variant="body1">
                                {t('lang_subscription.txt_cancel_subscription_success_msg', {
                                    nextBillingDate: nextBillingDate,
                                })}
                            </Typography>
                        )}
                    </Fragment>
                </div>
                {!hasCanceled ? (
                    <Fragment>
                        <div className="cancel_feature_list">
                            <FeatureList
                                caller="SubscriptionRecurring"
                                onClickAndMore={() => {
                                    setIsTrialFlowModalOpen(true);
                                }}
                            />
                        </div>

                        <p className="info_text">{t('lang_subscription.txt_are_you_sure_to_cancel_subscription')}</p>
                        <div className="profile_update_btn row2">
                            <CommonButton
                                variant="contained"
                                type="button"
                                color="primary"
                                text={t('lang_subscription.txt_prefer_to_keep_pro_features')}
                                clickFunction={giveUpCancelSubscription}
                            />
                            <div className={`link red`}>
                                <Link onClick={() => confirmCancelSubscription()}>
                                    {isProcessing
                                        ? t(`lang_subscription.txt_cancelling_subscription`)
                                        : t(`lang_subscription.txt_still_cancel`)}
                                </Link>
                            </div>
                        </div>
                    </Fragment>
                ) : (
                    <div className="profile_update_btn">
                        <CommonButton
                            variant="contained"
                            type="button"
                            color="primary"
                            text={t('lang_subscription.btn_go_to_subscription')}
                            clickFunction={() => {
                                setCurrentPage(utilConstants.SUBSCRIPTION_PAGE_TYPE.Landing_Page);
                            }}
                        />
                    </div>
                )}
            </div>

            <CommonAlert
                showAlert={isTrialFlowModalOpen}
                closeAlert={() => {
                    setIsTrialFlowModalOpen(false);
                }}
                content={
                    <TrialFlow
                        isShowMultipleSlides={true}
                        setIsTrialFlowModalOpen={setIsTrialFlowModalOpen}
                        isActivatingTrial={isActivatingTrial}
                        setIsActivatingTrial={setIsActivatingTrial}
                        viewport={ViewportSavedClasses}
                        onTrialActivated={() => setIsTrialFlowModalOpen(false)}
                        customClassNames={`default`}
                    />
                }
                viewportConfig={ViewportSavedClasses}
                customModalClassNames="small"
                customBoxClassNames="subscriptionTrialFlowModal"
            />
        </Fragment>
    );
};

export default CancelSubscriptionConfirm;
