import { useTranslation } from 'react-i18next';
import CommonButton from '../../../components/Common/CommonButton';
import webviewMessenger from '../../../services/webviewMessenger';

export default function DeleteResponsesButton({ onDelete }: any) {
    const { t } = useTranslation();

    return (
        <CommonButton
            isLoading={false}
            type="button"
            className="delete_btn"
            color="error"
            text={t('lang_activity.delete_responses')}
            clickFunction={() => {
                webviewMessenger.sendUsageLog(`Clicked "Delete responses" button`);
                onDelete();
            }}
        />
    );
}
