import { type Message } from 'ai';
import { ChatMessage } from './ChatMessage';
import { useEffect, useRef } from 'react';

export interface ChatListInterface {
    messages: Message[];
    isLoading: boolean;
    actionType?: string;
}

export function ChatList({ messages, isLoading }: ChatListInterface) {
    const messagesCountRef = useRef(messages.length);

    useEffect(() => {
        messagesCountRef.current = messages.length;
    }, [messages.length]);

    if (!messages.length) {
        return null;
    }

    return (
        <div className="relative mx-auto max-w-3xl px-2 py-4">
            {messages.map((message, index) => (
                <div key={index}>
                    <ChatMessage index={index} message={message} isLoading={isLoading} />
                </div>
            ))}
        </div>
    );
}
