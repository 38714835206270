import React, { Fragment } from 'react';

export const Playslide = () => {
    return (
        <Fragment>
            <svg
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M3 6H27V9H3V6ZM6 0H24V3H6V0ZM27 12H3C1.35 12 0 13.35 0 15V27C0 28.65 1.35 30 3 30H27C28.65 30 30 28.65 30 27V15C30 13.35 28.65 12 27 12ZM27 27H3V15H27V27ZM12 16.095V25.89L21 21L12 16.095Z"
                    fill="#FF9264"
                />
            </svg>
        </Fragment>
    );
};

export const Slideright = () => {
    return (
        <Fragment>
            <svg
                viewBox="0 0 30 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27 0.5H3C1.35 0.5 0 1.85 0 3.5V24.5C0 26.15 1.35 27.5 3 27.5H27C28.65 27.5 30 26.15 30 24.5V3.5C30 1.85 28.65 0.5 27 0.5ZM27 24.5H3V3.5H27V24.5Z"
                    fill="#FF9264"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M26.115 11.63L23.985 9.5L19.23 14.255L17.115 12.125L15 14.24L19.23 18.5L26.115 11.63Z"
                    fill="#FF9264"
                />
                <path d="M12 6.5H4.5V9.5H12V6.5Z" fill="#FF9264" />
                <path d="M12 12.5H4.5V15.5H12V12.5Z" fill="#FF9264" />
                <path d="M12 18.5H4.5V21.5H12V18.5Z" fill="#FF9264" />
            </svg>
        </Fragment>
    );
};
