import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { localService } from '../../../services/localStorageService';
import { getLocalizedVideoInfo, getSectionIdFromVideoId } from '../videoTutorialHelpers';
import webviewMessenger from '../../../services/webviewMessenger';
import { YouTubeIcon } from '../../../Icon';

const VideoTitleDescription = ({ activeVideoId, videoInfo }: any) => {
    const { t } = useTranslation();
    const selectedLanguage = localService.getLanguage();

    useEffect(() => {
        if (!videoInfo) return;
        const links = document.getElementsByClassName('video_description_link');
        if (links.length > 0) for (let link of links) link.addEventListener('click', openVideoDescriptionLink);
        return () => {
            if (links.length > 0) for (let link of links) link.removeEventListener('click', openVideoDescriptionLink);
        };
    }, [videoInfo]);

    const openVideoDescriptionLink = (event: any) => {
        const linkUrl = event.currentTarget.getAttribute('href');
        webviewMessenger.sendOpenBrowser(linkUrl);
    };

    if (!videoInfo) return null;
    return (
        <Fragment>
            <div className="video_title_sec">
                <div className="left_title">
                    <Typography variant="h4">{getLocalizedVideoInfo(videoInfo, selectedLanguage).title}</Typography>
                    <Typography variant="body1">
                        {t('lang_video_tutorial.section')}:{' '}
                        {t(`lang_video_tutorial.${getSectionIdFromVideoId(activeVideoId)}`)}
                    </Typography>
                </div>
                <div className="share_video" onClick={() => webviewMessenger.sendOpenBrowser(videoInfo.youtubeUrl)}>
                    <Button className="icon">
                        <YouTubeIcon />
                    </Button>
                </div>
            </div>
            <div className="description">
                <Typography
                    variant="body1"
                    dangerouslySetInnerHTML={{
                        __html: getLocalizedVideoInfo(videoInfo, selectedLanguage).description,
                    }}
                ></Typography>
            </div>
        </Fragment>
    );
};

export default VideoTitleDescription;
