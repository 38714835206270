import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { PresetBackgrounds } from '../../../../../constants/preset-backgrounds';
import { utilConstants } from '../../../../../constants/utils.constants';
import useAddressBar from '../../Common/useAdressBar';
import { getValidUserFromStore } from '../../../../../helpers/storeHelpers';
import BrowserBookmarkInterface from '../../../../../interfaces/browser-bookmark.interface';
import webviewBrowserCommands from '../../../../../services/webviewBrowserCommands';

import './StartPage.scss';

function StartPage() {
    const { t } = useTranslation();

    const backgroundImageUrl = PresetBackgrounds[Math.floor(Math.random() * PresetBackgrounds.length)];
    const backgroundImageRef = useRef(backgroundImageUrl);
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    const [searchKeyword, setSearchInput, searchFieldRef] = useAddressBar();
    const user = getValidUserFromStore('StartPage');
    const browserBookmarks = user.userPreferences?.browserBookmarks;
    const [pinnedItems, setPinnedItems] = useState<BrowserBookmarkInterface[]>([]);

    useEffect(() => {
        if (browserBookmarks) {
            const pinitem = browserBookmarks.filter((item) => item.isPinned);
            setPinnedItems(pinitem);
        }
    }, [browserBookmarks]);

    const handleBookmarkClick = (pageUrl: string) => {
        webviewBrowserCommands.navigateTo(pageUrl, false);
        // webviewBrowserCommands.openBookmarkInTab(pageUrl);
    };

    return (
        <div
            className="contentUi__sec"
            style={{
                backgroundImage: `url(${backgroundImageRef.current})`,
                backgroundRepeat: 'no-repeat',
            }}
        >
            <div className="inner_content">
                <div className="cp_logo">
                    <img src={utilConstants.IMAGE_URLS.cpCircular} alt="brandlogo" />
                </div>
                <div className="urlsearch">
                    <TextField
                        fullWidth
                        autoComplete="none"
                        placeholder={t('lang_browser.search_google_placeholder')}
                        id="fullWidth"
                        onChange={(e: any) => {
                            setSearchInput(e.target.value.trim());
                        }}
                        inputRef={searchFieldRef}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <div className="start_page_pin">
                    <div className="pin_wraper">
                        {pinnedItems.map((bookmark, index: number) => (
                            <div className="pin_box">
                                <img
                                    src={bookmark.favicon}
                                    alt="favicon"
                                    onClick={() => handleBookmarkClick(bookmark.pageUrl)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StartPage;
