import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import VerifyKeyOutDto from '../../../dtos/verify-key-out.dto';
import { getValidUserFromStore } from '../../../helpers/storeHelpers';
import { getNewUserDiscount, getSubscriptionSubPageType, isUserOnTrialOrPro } from '../../../helpers/userhelper';

import { utilConstants } from '../../../constants/utils.constants';

import SubscriptionLanding from './SubscriptionLanding';
import CancelSubscription from './components/CancelSubscription';
import KeyActivated from './components/KeyActivated';
import ChangeProductKey from './components/ChangeProductKey';
import CancelSubscriptionConfirm from './components/CancelSubscriptionConfirm';
import ConfirmProductKey from './components/ConfirmProductKey';
import ConfirmChangeProductKey from './components/ConfirmChangeProductKey';
import EnterProductKey from './components/EnterProductKey';
import ConfirmRevokeCancellation from './components/ConfirmRevokeCancellation';
import { SUBPAGE_TYPE } from '../../../constants/subscription-subpage-type.enum';
import webviewMessenger from '../../../services/webviewMessenger';
import NewUserDiscountLabel from '../../../components/Common/NewUserDiscount/NewUserDiscountLabel';
import NewUserDiscountModal from '../../../components/Common/NewUserDiscount/NewUserDiscountModal';

const Subscriptions = () => {
    const { t } = useTranslation();

    const user = getValidUserFromStore('Subscriptions');
    const subpageType = getSubscriptionSubPageType(user);
    const newUserDiscount = getNewUserDiscount(user);

    const [showDiscountModal, setShowDiscountModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(utilConstants.SUBSCRIPTION_PAGE_TYPE.Landing_Page);
    const [cancelSubscriptionReason, setCancelSubscriptionReason] = useState('');
    const [verifiedKey, setVerifiedKey] = useState<VerifyKeyOutDto>();

    useEffect(() => {
        webviewMessenger.sendUsageLog(`Navigated to Settings -> Subscription`);
    }, []);

    let activeComponent: ReactNode;
    switch (currentPage) {
        case utilConstants.SUBSCRIPTION_PAGE_TYPE.Landing_Page:
            activeComponent = (
                <SubscriptionLanding
                    setCurrentPage={setCurrentPage}
                    onShowDiscountModal={() => setShowDiscountModal(true)}
                />
            );
            break;
        case utilConstants.SUBSCRIPTION_PAGE_TYPE.Add_Key:
            activeComponent = <EnterProductKey setCurrentPage={setCurrentPage} setVerifiedKey={setVerifiedKey} />;
            break;
        case utilConstants.SUBSCRIPTION_PAGE_TYPE.Confirm_Key:
            activeComponent = verifiedKey && (
                <ConfirmProductKey setCurrentPage={setCurrentPage} verifiedKey={verifiedKey} />
            );
            break;
        case utilConstants.SUBSCRIPTION_PAGE_TYPE.Page_Acknologement:
            activeComponent = (
                <KeyActivated
                    setCurrentPage={setCurrentPage}
                    verifiedKey={verifiedKey}
                    setVerifiedKey={setVerifiedKey}
                />
            );
            break;
        case utilConstants.SUBSCRIPTION_PAGE_TYPE.Change_Key:
            activeComponent = <ChangeProductKey setCurrentPage={setCurrentPage} setVerifiedKey={setVerifiedKey} />;
            break;
        case utilConstants.SUBSCRIPTION_PAGE_TYPE.Confirm_Change_Key:
            activeComponent = (
                <ConfirmChangeProductKey
                    setCurrentPage={setCurrentPage}
                    verifiedKey={verifiedKey}
                    setVerifiedKey={setVerifiedKey}
                />
            );
            break;
        case utilConstants.SUBSCRIPTION_PAGE_TYPE.Cancel_Subscription:
            activeComponent = (
                <CancelSubscription
                    setCurrentPage={setCurrentPage}
                    setCancelSubscriptionReason={setCancelSubscriptionReason}
                />
            );
            break;
        case utilConstants.SUBSCRIPTION_PAGE_TYPE.Cancel_Subscription_Confirm:
            activeComponent = (
                <CancelSubscriptionConfirm
                    setCurrentPage={setCurrentPage}
                    cancelSubscriptionReason={cancelSubscriptionReason}
                />
            );
            break;
        case utilConstants.SUBSCRIPTION_PAGE_TYPE.Confirm_Revoke_plan:
            activeComponent = <ConfirmRevokeCancellation setCurrentPage={setCurrentPage} />;
            break;
        default:
            activeComponent = null;
    }

    return (
        <>
            <div
                className={`subscription_sec ${
                    subpageType === SUBPAGE_TYPE.BASIC &&
                    currentPage === utilConstants.SUBSCRIPTION_PAGE_TYPE.Landing_Page &&
                    'noPadding'
                }`}
            >
                <div className="subscription_top">{activeComponent}</div>
                {newUserDiscount &&
                    subpageType === SUBPAGE_TYPE.TRIAL &&
                    currentPage === utilConstants.SUBSCRIPTION_PAGE_TYPE.Landing_Page && (
                        <NewUserDiscountLabel onShowDiscountModal={() => setShowDiscountModal(true)} />
                    )}
            </div>
            // TODO: Show banner and link to website page
            {/* {isUserOnTrialOrPro(user) && !user.userSubscription.isPremium && (
                <div className={`premiumBanner`}>
                    <img src={utilConstants.SETTING.PREMIUM_UI} alt="ClassPoint Premium UI" />
                    <p className={`text`}> {t('lang_premium.checkout_premium')}</p>
                </div>
            )} */}
            {showDiscountModal && (
                <NewUserDiscountModal userEmail={user.email} onHideDiscountModal={() => setShowDiscountModal(false)} />
            )}
        </>
    );
};

export default Subscriptions;
