import SavedClassAddedParticipantInterface from '../../interfaces/saved-class-added-participant.interface';
import SavedClassParticipantInterface from '../../interfaces/saved-class-participant.interface';
import { localService } from '../../services/localStorageService';

export const findNonEmptyAddedParticipants = (addedParticipants: SavedClassAddedParticipantInterface[]) => {
    return addedParticipants.filter((p) => {
        return p && p.name !== '';
    });
};

export const updateItemInArray = (list: any, index: number, replacement: any) => {
    const duplicatedList = [...list];
    duplicatedList[index] = replacement;
    return duplicatedList;
};

export const checkIfNameIsTaken = (
    index: number,
    name: string,
    sortedParticipants: SavedClassParticipantInterface[],
): boolean => {
    if (sortedParticipants && sortedParticipants.length > 0) {
        const isParticipantExist = sortedParticipants.find(
            ({ participantName }) => participantName.trim().toLowerCase() === name.trim().toLowerCase(),
        );
        if (isParticipantExist) return true;
    }
    const addedParticipants = localService.getTempAddedParticipants();
    if (!addedParticipants) return false;
    return addedParticipants
        .filter((p) => p.index !== index)
        ?.map((p) => p.name)
        .some((participantName) => participantName.trim().toLowerCase() === name.trim().toLowerCase());
};

export const updateAddedParticipants = (participant: SavedClassAddedParticipantInterface) => {
    let addedParticipants = localService.getTempAddedParticipants();
    if (!addedParticipants) addedParticipants = [participant];
    else {
        const targetIndex = addedParticipants.findIndex((p) => p.index === participant.index);
        if (targetIndex >= 0) addedParticipants[targetIndex] = participant;
        else addedParticipants.push(participant);
    }

    if (participant.name === '') {
        let newArray: SavedClassAddedParticipantInterface[] = [];
        for (let n = 0; n < addedParticipants?.length; n++) {
            addedParticipants[n].index !== participant.index && newArray.push(addedParticipants[n]);
        }
        addedParticipants = newArray;
    }

    localService.setTempAddedParticipants(addedParticipants);
};

export const hasAlphabet = (str: string): boolean => {
    return /[a-zA-Z]/.test(str);
};

export const validateSavedClassName = (className: string, existingNames: string[]): string => {
    if (!className) return 'err_empty_class_name';
    if (className.length > 0 && className.length < 3) return 'err_class_name_short';
    if (className.length > 30) return 'err_class_name_long';
    if (existingNames.length > 0 && existingNames.some((name) => name.toUpperCase() === className.toUpperCase()))
        return 'err_class_name_taken';
    return '';
};

export const validateSavedClassCode = (classCode: string): string => {
    if (!classCode) return 'err_empty_class_code';
    if (hasAlphabet(classCode) === false) return 'err_class_code_pattrn';
    if (classCode.length > 0 && classCode.length < 4) return 'err_class_code_short';
    if (classCode.length > 8) return 'err_class_code_long';
    // not allow class code to be JOIN
    if (classCode.toUpperCase() === 'JOIN') return 'err_class_code_taken';
    return '';
};

export function getComparator(order: 'asc' | 'desc', orderBy: string): (a: any, b: any) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator<T>(a: any, b: any, orderBy: keyof T) {
    if (typeChecker(b[orderBy]) < typeChecker(a[orderBy])) {
        return -1;
    }
    if (typeChecker(b[orderBy]) > typeChecker(a[orderBy])) {
        return 1;
    }
    return 0;
}

const typeChecker = (unknownData: any) => {
    if (typeof unknownData === 'string') return unknownData.toLowerCase();
    return unknownData;
};

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export const processParticipantNamesForSorting = (participantName: string) => {
    // do this so when participant names contain number they will be sorted by number
    var matches = participantName.toLowerCase().match(/\d+/g);
    if (matches != null) {
        let numb: any = participantName.toLowerCase().match(/\d/g);
        const participantNumber = numb.join('');
        return parseInt(participantNumber);
    } else return participantName.toLowerCase();
};
