import { utilConstants } from '../../constants/utils.constants';

export type TemplateCategoryType = { name: string; label: string; icon: string };

export const CATEGORIES: TemplateCategoryType[] = [
    {
        name: 'multiple_choice',
        label: 'Multiple Choice',
        icon: utilConstants.IMAGE_URLS.TEMPLATE_LIBRARY_MULTIPLE_CHOICE,
    },
    {
        name: 'word_cloud',
        label: 'Word Cloud',
        icon: utilConstants.IMAGE_URLS.TEMPLATE_LIBRARY_WORD_CLOUD,
    },
    {
        name: 'short_answer',
        label: 'Short Answer',
        icon: utilConstants.IMAGE_URLS.TEMPLATE_LIBRARY_SHORT_ANSWER,
    },
    {
        name: 'slide_drawing',
        label: 'Slide Drawing',
        icon: utilConstants.IMAGE_URLS.TEMPLATE_LIBRARY_SLIDE_DRAWING,
    },
    {
        name: 'image_upload',
        label: 'Image Upload',
        icon: utilConstants.IMAGE_URLS.TEMPLATE_LIBRARY_IMAGE_UPLOAD,
    },
    {
        name: 'fill_blanks',
        label: 'Fill in the Blanks',
        icon: utilConstants.IMAGE_URLS.TEMPLATE_LIBRARY_FILL_IN_THE_BLANKS,
    },
    {
        name: 'audio_record',
        label: 'Audio Record',
        icon: utilConstants.IMAGE_URLS.TEMPLATE_LIBRARY_AUDIO_RECORD,
    },
    {
        name: 'video_upload',
        label: 'Video Upload',
        icon: utilConstants.IMAGE_URLS.TEMPLATE_LIBRARY_VIDEO_UPLOAD,
    },
    {
        name: 'unpublished',
        label: 'Unpublished',
        icon: utilConstants.IMAGE_URLS.TEMPLATE_LIBRARY_UNPUBLISHED,
    },
];
