import axios from 'axios';
import { EditSavedClassNameCodeAvatarDto } from '../dtos/edit-saved-class.dto';
import SavedClassInterface from '../interfaces/saved-class.interface';
import UserInterface from '../interfaces/user-interface';
import { SavedClassAddParticipantDto } from '../dtos/saved-class-add-participant.dto';
import { SavedClassRenameParticipantDto } from '../dtos/saved-class-rename-participant.dto';
import { logger } from './logger';
import { sentryLogApiError } from './sentryService';
import axiosWithLog from './axios-with-log';
import webviewMessenger from './webviewMessenger';
import SavedClassAddGroupDto from '../dtos/saved-class-add-group.dto';
import SavedClassEditGroupDto from '../dtos/saved-class-edit-group.dto';
import { IAutoGroupData } from '../interfaces/savedClassGroup.interface';

const apiSavedClasses = {
    validateSavedClassCode: async (email: string, classCode: string): Promise<boolean> => {
        try {
            const url = `${process.env.REACT_APP_APITWO}/savedclasses/validate?email=${email}&classCode=${classCode}`;
            await axios.get(url);
            return true;
        } catch (error: any) {
            logger.error('validateSavedClassCode() error: ', error.response || error);
            return false;
        }
    },

    addSavedClass: async (
        email: string,
        ownerId: string,
        savedClassName: string,
        savedClassCode: string,
        savedClassAvatar: string,
        participants: SavedClassAddParticipantDto[],
    ): Promise<UserInterface | number | null> => {
        try {
            const url = `${process.env.REACT_APP_APITWO}/savedclasses?email=${email}`;
            const response: any = await axiosWithLog.post(url, {
                ownerId,
                savedClassName,
                savedClassCode,
                savedClassAvatar: savedClassAvatar || undefined,
                participants,
            });
            webviewMessenger.sendUsageLog(
                `Added saved class with ${participants.length} participants, took ${response.duration} ms`,
            );
            return response.data;
        } catch (error: any) {
            if (error.response && error.response.data.statusCode === 409) return 409;
            logger.error('addSavedClass() error: ', error.response || error);
            sentryLogApiError(error, 'addSavedClass', {
                body: { ownerId, savedClassName, savedClassCode, savedClassAvatar, participants },
            });
            return null;
        }
    },

    getSavedClass: async (savedClassId: string, email: string): Promise<SavedClassInterface | null> => {
        try {
            const url = `${process.env.REACT_APP_APITWO}/savedclasses?savedClassId=${savedClassId}&email=${email}`;
            const response = await axios.get(url);
            return response.data;
        } catch (error: any) {
            logger.error('getSavedClass() error: ', error.response || error);
            sentryLogApiError(error, 'getSavedClass', {
                query: { savedClassId },
            });
            return null;
        }
    },

    editSavedClassNameCodeAvatar: async (
        savedClassId: string,
        dto: EditSavedClassNameCodeAvatarDto,
    ): Promise<SavedClassInterface | number | null> => {
        try {
            const url = `${process.env.REACT_APP_APITWO}/savedclasses/class?savedClassId=${savedClassId}`;
            const response: any = await axiosWithLog.patch(url, dto);
            webviewMessenger.sendUsageLog(`Edited class name, code or avatar, took ${response.duration} ms`);
            return response.data;
        } catch (error: any) {
            if (error.response && error.response.data.statusCode === 409) return 409;
            logger.error('editSavedClassNameCodeAvatar() error: ', error.response || error);
            sentryLogApiError(error, 'editSavedClassNameCodeAvatar', {
                query: { savedClassId },
                body: dto,
            });
            return null;
        }
    },

    resetSavedClassPoints: async (savedClassId: string): Promise<SavedClassInterface | null> => {
        try {
            const url = `${process.env.REACT_APP_APITWO}/savedclasses/class/reset-points?savedClassId=${savedClassId}`;
            const response: any = await axiosWithLog.patch(url);
            webviewMessenger.sendUsageLog(`Reset stars successful, took ${response.duration} ms`);
            return response.data;
        } catch (error: any) {
            logger.error('resetSavedClassPoints() error: ', error.response || error);
            sentryLogApiError(error, 'resetSavedClassPoints', {
                query: { savedClassId },
            });
            return null;
        }
    },

    addNewParticipants: async (
        savedClassId: string,
        newParticipants: SavedClassAddParticipantDto[],
    ): Promise<SavedClassInterface | null> => {
        try {
            const url = `${process.env.REACT_APP_APITWO}/savedclasses/participants?savedClassId=${savedClassId}`;
            const response: any = await axiosWithLog.post(url, { newParticipants });
            webviewMessenger.sendUsageLog(
                `Added ${newParticipants.length} new participants, took ${response.duration} ms`,
            );
            return response.data;
        } catch (error: any) {
            logger.error('addNewParticipants() error: ', error.response || error);
            sentryLogApiError(error, 'addNewParticipants', {
                query: { savedClassId },
                body: newParticipants,
            });
            return null;
        }
    },

    renameParticipant: async (
        savedClassId: string,
        participantId: string,
        dto: SavedClassRenameParticipantDto,
    ): Promise<SavedClassInterface | null> => {
        try {
            const url = `${process.env.REACT_APP_APITWO}/savedclasses/participant/rename?savedClassId=${savedClassId}&participantId=${participantId}`;
            const response: any = await axiosWithLog.patch(url, dto);
            webviewMessenger.sendUsageLog(`Renamed participant, took ${response.duration} ms`);
            return response.data;
        } catch (error: any) {
            logger.error('renameParticipant() error: ', error.response || error);
            sentryLogApiError(error, 'renameParticipant', {
                query: { savedClassId, participantId },
                body: dto,
            });
            return null;
        }
    },

    adjustParticipantPoints: async (
        savedClassId: string,
        participantId: string,
        pointsDelta: number,
    ): Promise<SavedClassInterface | null> => {
        try {
            const url = `${process.env.REACT_APP_APITWO}/savedclasses/participant/adjust-points?savedClassId=${savedClassId}&participantId=${participantId}&pointsDelta=${pointsDelta}`;
            const response: any = await axiosWithLog.patch(url);
            webviewMessenger.sendUsageLog(`Adjusted stars, took ${response.duration} ms`);
            return response.data;
        } catch (error: any) {
            console.log('adjustParticipantPoints() error: ', error.response || error);
            logger.error('adjustParticipantPoints() error: ', error.response || error);
            sentryLogApiError(error, 'adjustParticipantPoints', {
                query: { savedClassId, participantId, pointsDelta },
            });
            return null;
        }
    },

    deleteParticipant: async (savedClassId: string, participantId: string): Promise<SavedClassInterface | null> => {
        try {
            const url = `${process.env.REACT_APP_APITWO}/savedclasses/participant?savedClassId=${savedClassId}&participantId=${participantId}`;
            const response: any = await axiosWithLog.delete(url);
            webviewMessenger.sendUsageLog(`Deleted participant, took ${response.duration} ms`);
            return response.data;
        } catch (error: any) {
            logger.error('deleteParticipant() error: ', error.response || error);
            sentryLogApiError(error, 'deleteParticipant', {
                query: { savedClassId, participantId },
            });
            return null;
        }
    },

    addGroup: async (savedClassId: string, payload: SavedClassAddGroupDto): Promise<SavedClassInterface | null> => {
        try {
            const url = `${process.env.REACT_APP_APITWO}/savedclasses/groups?savedClassId=${savedClassId}`;
            const response: any = await axiosWithLog.post(url, payload);
            webviewMessenger.sendUsageLog(`Added group, took ${response.duration} ms`);
            return response.data;
        } catch (error: any) {
            logger.error('addGroup() error: ', error.response || error);
            sentryLogApiError(error, 'addGroup', {
                query: { savedClassId },
                body: payload,
            });
            return null;
        }
    },

    editGroup: async (
        savedClassId: string,
        groupId: number,
        payload: SavedClassEditGroupDto,
    ): Promise<SavedClassInterface | null> => {
        try {
            const url = `${process.env.REACT_APP_APITWO}/savedclasses/groups?savedClassId=${savedClassId}&groupId=${groupId}`;
            const response: any = await axiosWithLog.put(url, payload);
            webviewMessenger.sendUsageLog(`Edited group, took ${response.duration} ms`);
            return response.data;
        } catch (error: any) {
            logger.error('editGroup() error: ', error.response || error);
            sentryLogApiError(error, 'editGroup', {
                query: { savedClassId, groupId },
                body: payload,
            });
            return null;
        }
    },

    deleteGroup: async (savedClassId: string, groupId: number): Promise<SavedClassInterface | null> => {
        try {
            const url = `${process.env.REACT_APP_APITWO}/savedclasses/groups?savedClassId=${savedClassId}&groupId=${groupId}`;
            const response: any = await axiosWithLog.delete(url);
            webviewMessenger.sendUsageLog(`Deleted group, took ${response.duration} ms`);
            return response.data;
        } catch (error: any) {
            logger.error('deleteGroup() error: ', error.response || error);
            sentryLogApiError(error, 'deleteGroup', {
                query: { savedClassId, groupId },
            });
            return null;
        }
    },

    deleteSavedClass: async (savedClassId: string): Promise<UserInterface | null> => {
        try {
            const url = `${process.env.REACT_APP_APITWO}/savedclasses?savedClassId=${savedClassId}`;
            const response: any = await axiosWithLog.delete(url);
            webviewMessenger.sendUsageLog(`Deleted saved class, took ${response.duration} ms`);
            return response.data;
        } catch (error: any) {
            logger.error('deleteSavedClass() error: ', error.response || error);
            sentryLogApiError(error, 'deleteSavedClass', {
                query: { savedClassId },
            });
            return null;
        }
    },

    reorderSavedClasses: async (email: string, savedClassesIds: string): Promise<UserInterface | null> => {
        try {
            const url = `${process.env.REACT_APP_API}/users/savedclasses/reorder?email=${email}&savedClassesIds=${savedClassesIds}`;
            const response: any = await axiosWithLog.put(url);
            webviewMessenger.sendUsageLog(`Reordered class list, took ${response.duration} ms`);
            return response.data;
        } catch (error: any) {
            logger.error('reorderSavedClasses() error: ', error.response || error);
            sentryLogApiError(error, 'reorderSavedClasses', {
                query: { email, savedClassesIds },
            });
            return null;
        }
    },

    syncSavedClasses: async (email: string, userId: string): Promise<UserInterface | null> => {
        try {
            const url = `${process.env.REACT_APP_API}/users/savedclasses/sync?email=${email}&userId=${userId}`;
            const response = await axios.put(url);
            return response.data;
        } catch (error: any) {
            logger.error('syncSavedClasses() error: ', error.response || error);
            sentryLogApiError(error, 'syncSavedClasses', {
                query: { email, userId },
            });
            return null;
        }
    },

    autoGroup: async (savedClassId: string, groupArray: IAutoGroupData[]): Promise<boolean> => {
        try {
            const url = `${process.env.REACT_APP_APITWO}/savedclasses/groups/automatic?savedClassId=${savedClassId}`;
            await axios.post(url, { addGroupDtos: groupArray });
            return true;
        } catch (error: any) {
            logger.error('autoGroup() error: ', error.response || error);
            sentryLogApiError(error, 'autoGroup', { query: { savedClassId } });
            return false;
        }
    },
};

export default apiSavedClasses;
