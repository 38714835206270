export const ViewportConstants = {
    width: 1000,
    height: 640,
    minZoom: 0,
    maxZoom: 5,
};

export const timerViewportConstants = {
    width: 360,
    height: 168,
    minZoom: 0,
    maxZoom: 5,
};

export const timerViewportFullScreenConstants = {
    width: 1200,
    height: 560,
    minZoom: 0,
    maxZoom: 2,
};

export const dashboard = {
    width: 584,
    height: 340,
    minZoom: 0,
    maxZoom: 5,
};

export const movableObjectViewportConstants = {
    width: 325,
    height: 550,
    minZoom: 0,
    maxZoom: 5,
};

export const whiteBoardConstants = {
    width: 800,
    height: 442,
    minZoom: 0,
    maxZoom: 5,
};

export const authPagesConstants = {
    width: 800,
    height: 612,
    minZoom: 0,
    maxZoom: 5,
};

export const authPagesVideoPlayerConstants = {
    width: 800,
    height: 500,
    minZoom: 0,
    maxZoom: 5,
};

export const ViewportExportPdf = {
    width: 400,
    height: 366,
    minZoom: 0,
    maxZoom: 5,
};

export const ViewportSavedClasses = {
    width: 920,
    height: 580,
    minZoom: 0,
    maxZoom: 5,
};

export const TrialFlowViewport = {
    width: 604,
    height: 472,
    minZoom: 0,
    maxZoom: 5,
};

export const ContextualTutorialViewport = {
    width: 800,
    height: 580,
    minZoom: 0,
    maxZoom: 5,
};

export const TemplateLibraryViewport = {
    width: 970,
    height: 580,
    minZoom: 0,
    maxZoom: 5,
};
