import { Fragment } from 'react';
import LazyLoadingInProgress from '../components/LazyLoadingInProgress';
import ActivityResponseInterface from '../../../interfaces/activity-response.interface';
import SlideDrawingSingleResponse from './SlideDrawingSingleResponse';

interface SlideDrawingContentInterface {
    selectedResponses: ActivityResponseInterface[];
    searchKeyword: string;
    isVoting: boolean;
    onThumbClicked: any;
    sortSelectedResponses: (arg: ActivityResponseInterface[]) => ActivityResponseInterface[];
    isFetchingMore: boolean;
}

function SlideDrawingContent({
    selectedResponses,
    searchKeyword,
    isVoting,
    onThumbClicked,
    sortSelectedResponses,
    isFetchingMore,
}: SlideDrawingContentInterface) {
    return (
        <Fragment>
            <div className="flexslide_drawing">
                <div className="inner_short_answer slidedrawing">
                    {sortSelectedResponses(selectedResponses).map((response, index) => (
                        <SlideDrawingSingleResponse
                            key={response.responseId}
                            index={index}
                            response={response}
                            isVoting={isVoting}
                            onThumbClicked={onThumbClicked}
                            searchKeyword={searchKeyword}
                        />
                    ))}
                </div>
                {isFetchingMore ? <LazyLoadingInProgress /> : null}
            </div>
        </Fragment>
    );
}

export default SlideDrawingContent;
