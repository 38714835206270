import { Avatar, Typography } from '@mui/material';
import { Fragment, useEffect, useRef, useState } from 'react';
import CustomAvatar, { AvatarType } from '../../../components/Common/CustomAvatar';
import { getActivityFromStore, getValidUserFromStore } from '../../../helpers/storeHelpers';
import { generateAsterisksFromName, getColorForString } from '../../../helpers/utils';
import { ActivityPropsInterface } from '../../../interfaces/activity-props.interface';
import SearchBox from '../components/SearchBox';
import { utilConstants } from '../../../constants/utils.constants';
import GivePointToResponse from '../../../components/Common/GivePointToResponse';
import ActivityResponseInterface from '../../../interfaces/activity-response.interface';
import { activityActions } from '../../../actions/activity.action';
import { useDispatch } from 'react-redux';
import VideoPlayerWithControls from './VideoPlayerWithControls';
import Highlighter from 'react-highlight-words';
import { canEditResponses, searchKeywordToArray } from '../../../helpers/activityHelpers';
import { AnimationConfig } from '../../../constants/animation-configs';
import ShowingResponsesCount from '../../../components/Common/ShowingResponsesCount';
// import { isUserOnTrialOrPro } from '../../../helpers/userhelper';

interface VideoUploadContentInterface {
    selectedResponses: ActivityResponseInterface[];
    searchKeyword: string;
    isVoting: boolean;
    onSearch: (arg: string) => void;
    isGivePointsToAll: boolean;
}

const VideoUploadContent = ({
    selectedResponses,
    searchKeyword,
    isVoting,
    onSearch,
    isGivePointsToAll,
}: VideoUploadContentInterface) => {
    const dispatch = useDispatch();
    const user = getValidUserFromStore('VideoUploadContent');
    const activity = getActivityFromStore();
    const activityProps = activity.activityProps as ActivityPropsInterface;
    const isNamesHidden = activityProps.isNamesHidden || false;
    const canEditResponsesInView = canEditResponses(activity, user.email);

    const [choosedResponse, setChoosedResponse] = useState<ActivityResponseInterface>();
    const [playedSeconds, setPlayedSeconds] = useState<{ [responseId: string]: number }>({});
    const playerRef = useRef<any>(null);
    const [isReady, setIsReady] = useState(false);

    const [pointsAdded, setPointsAdded] = useState(0);
    const timeOutPointUpdate = useRef<any>();
    const [isAnimating, setIsAnimating] = useState(false);
    const [videoPlayError, setVideoPlayError] = useState<boolean>(false);

    useEffect(() => {
        if (!choosedResponse) setChoosedResponse(selectedResponses[0]);
        else {
            // maybe currently choosed response has an update on responsePoints
            const choosedResponseUpdated = selectedResponses.find((r) => r.responseId === choosedResponse.responseId);
            if (choosedResponseUpdated) setChoosedResponse(choosedResponseUpdated);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedResponses]);

    useEffect(() => {
        if (!choosedResponse) return;
        const playedSecondsOfChoosedResponse = playedSeconds[choosedResponse.responseId];
        if (playedSecondsOfChoosedResponse) {
            playerRef.current.seekTo(playedSecondsOfChoosedResponse, 'seconds');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [choosedResponse]);

    const onDeleteResponse = (response: ActivityResponseInterface) => {
        if (selectedResponses.length <= 1) setChoosedResponse(undefined);
        else {
            const currentResponseIndex = selectedResponses.findIndex((r) => r.responseId === response.responseId);
            if (currentResponseIndex < selectedResponses.length - 1)
                setChoosedResponse(selectedResponses[currentResponseIndex + 1]);
            else setChoosedResponse(selectedResponses[currentResponseIndex - 1]);
        }
        dispatch(activityActions.deleteIndividualResponses([response]));
    };

    const givePoint = (response: ActivityResponseInterface, points: number) => {
        dispatch(activityActions.handleGivePointsToResponses([{ response, points }]));
    };

    const handleGivePointButtonClick = () => {
        clearTimeout(timeOutPointUpdate.current);
        setIsAnimating(true);
        setPointsAdded(pointsAdded + 1);
    };

    useEffect(() => {
        if (pointsAdded > 0) {
            timeOutPointUpdate.current = setTimeout(() => {
                choosedResponse && givePoint(choosedResponse, pointsAdded);
                setPointsAdded(0);
                setTimeout(() => {
                    setIsAnimating(false);
                }, AnimationConfig.TICK_LOTTIE_DURATION - AnimationConfig.GIVE_POINT_DELAY);
            }, AnimationConfig.GIVE_POINT_DELAY);
        }
        return () => clearTimeout(timeOutPointUpdate.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pointsAdded]);

    return (
        <Fragment>
            <div className="video_upload_sec">
                <div className="video_upload_left">
                    {choosedResponse && (
                        <Fragment>
                            <VideoPlayerWithControls
                                playerRef={playerRef}
                                videoPlayError={videoPlayError}
                                setVideoPlayError={setVideoPlayError}
                                isReady={isReady}
                                setIsReady={setIsReady}
                                choosedResponse={choosedResponse}
                                canEditResponsesInView={canEditResponsesInView}
                                onDeleteResponse={onDeleteResponse}
                                recordedProgress={playedSeconds[choosedResponse.responseId] || 0}
                                onVideoProgress={(playedSeconds: number) => {
                                    setPlayedSeconds((prevState) => ({
                                        ...prevState,
                                        [choosedResponse.responseId]: playedSeconds,
                                    }));
                                }}
                                isAnimating={isAnimating}
                            />
                            <div className="video_profile_sec">
                                <div className="video_left">
                                    <div className="avater_img">
                                        {isNamesHidden ? (
                                            <Avatar
                                                alt={choosedResponse.participantName}
                                                style={{
                                                    backgroundColor: getColorForString(choosedResponse.participantName),
                                                }}
                                            >
                                                ?
                                            </Avatar>
                                        ) : (
                                            <CustomAvatar
                                                avatarName={choosedResponse.participantName}
                                                avatarUrl={choosedResponse.participantAvatar}
                                                type={AvatarType.PARTICIPANT}
                                            />
                                        )}
                                    </div>
                                    <div className="video_content">
                                        <Typography variant="h4">
                                            {isNamesHidden ? (
                                                generateAsterisksFromName(choosedResponse.participantName)
                                            ) : (
                                                <Highlighter
                                                    highlightClassName="YourHighlightClass"
                                                    searchWords={searchKeywordToArray(searchKeyword)}
                                                    autoEscape={true}
                                                    textToHighlight={choosedResponse.participantName}
                                                />
                                            )}
                                        </Typography>
                                        <Typography variant="body1">
                                            <Highlighter
                                                highlightClassName="YourHighlightClass"
                                                searchWords={searchKeywordToArray(searchKeyword)}
                                                autoEscape={true}
                                                textToHighlight={JSON.parse(choosedResponse.responseData)[1]}
                                            />
                                        </Typography>
                                    </div>
                                </div>
                                <div
                                    className={
                                        !canEditResponsesInView && choosedResponse.responsePoints + pointsAdded < 1
                                            ? 'video_right nostarview'
                                            : 'video_right'
                                    }
                                >
                                    {canEditResponsesInView ? (
                                        <GivePointToResponse
                                            response={choosedResponse}
                                            pointsAdded={pointsAdded}
                                            onGivePointButtonClick={handleGivePointButtonClick}
                                        />
                                    ) : (
                                        <GivePointToResponse response={choosedResponse} pointsAdded={pointsAdded} />
                                    )}
                                </div>
                            </div>
                        </Fragment>
                    )}
                </div>

                <div className="video_upload_right">
                    <SearchBox onSearch={onSearch} />
                    {/* {isUserOnPro(user) && <SearchBox onSearch={onSearch} />} */}
                    <ShowingResponsesCount count={selectedResponses.length} />
                    {selectedResponses.length > 0 ? (
                        <div className="inner_upload_right">
                            {selectedResponses.map((response: ActivityResponseInterface) => (
                                <div
                                    key={response.responseId}
                                    className={
                                        response.responseId === choosedResponse?.responseId
                                            ? 'video_participant_card active'
                                            : 'video_participant_card'
                                    }
                                    onClick={() => {
                                        !isAnimating && !isGivePointsToAll && setChoosedResponse(response);
                                        setVideoPlayError(false);
                                        setIsReady(false);
                                    }}
                                >
                                    <div className="video_avatar">
                                        {isNamesHidden ? (
                                            <div className="avater_box">
                                                <Avatar
                                                    alt={response.participantName}
                                                    style={{
                                                        backgroundColor: getColorForString(response.participantName),
                                                    }}
                                                >
                                                    ?
                                                </Avatar>
                                            </div>
                                        ) : (
                                            <div className="avater_box">
                                                <CustomAvatar
                                                    avatarName={response.participantName}
                                                    avatarUrl={response.participantAvatar}
                                                    type={AvatarType.PARTICIPANT}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="video_content">
                                        <Typography variant="h4">
                                            {isNamesHidden ? (
                                                generateAsterisksFromName(response.participantName)
                                            ) : (
                                                <Highlighter
                                                    highlightClassName="YourHighlightClass"
                                                    searchWords={searchKeywordToArray(searchKeyword)}
                                                    autoEscape={true}
                                                    textToHighlight={response.participantName}
                                                />
                                            )}
                                        </Typography>
                                        <Typography variant="body1">
                                            <Highlighter
                                                highlightClassName="YourHighlightClass"
                                                searchWords={searchKeywordToArray(searchKeyword)}
                                                autoEscape={true}
                                                textToHighlight={JSON.parse(response.responseData)[1]}
                                            />
                                        </Typography>
                                    </div>
                                    <div className="participent_points">
                                        {response.responsePoints > 0 ||
                                        (choosedResponse?.responseId === response.responseId && pointsAdded > 0) ? (
                                            <Fragment>
                                                <img src={utilConstants.IMAGE_URLS.starIcon} alt="staricon" />
                                                <Typography variant="body1">
                                                    {response.responseId === choosedResponse?.responseId
                                                        ? response.responsePoints + pointsAdded
                                                        : response.responsePoints}
                                                </Typography>
                                            </Fragment>
                                        ) : null}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : null}
                </div>
            </div>
        </Fragment>
    );
};

export default VideoUploadContent;
