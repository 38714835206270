import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import { AzureStorages } from '../constants/azure-storages';

const determineStorage = (apiServer: string, isTemp: boolean) => {
    let isDev = false;
    let region = '';

    if (apiServer === 'microsoftSsoAvatarUpload') {
        isDev = process.env.REACT_APP_ENV !== 'production';
        region = '11';
    } else {
        isDev = apiServer.startsWith('dev') || apiServer.startsWith('localhost');
        region = apiServer === 'localhost' ? '11' : apiServer.slice(-4, -2);
    }

    let targetStorage = AzureStorages.find(
        (storage) => storage.isDev === isDev && storage.region === region && storage.isTemp === isTemp,
    );

    if (!targetStorage) {
        // if target storage is not found, fall back to 11
        targetStorage = AzureStorages.find(
            (storage) => storage.isDev === isDev && storage.region === '11' && storage.isTemp === isTemp,
        );
    }

    // if still cannot find one, return null so we can use file server instead
    if (!targetStorage) return null;

    return {
        storageAccountName: targetStorage.storageAccountName,
        sasToken: targetStorage.sasToken,
    };
};

const createBlobInContainer = async (
    containerClient: ContainerClient,
    file: File,
    fileName: string,
): Promise<boolean> => {
    const blobClient = containerClient.getBlockBlobClient(fileName);
    const options = { blobHTTPHeaders: { blobContentType: file.type } };
    try {
        await blobClient.uploadData(file, options);
        return true;
    } catch (error: any) {
        // logger.error('createBlobInContainer() error: ', error.response || error);
        return false;
    }
};

export const uploadToAzureStorage = async (
    file: File | null,
    fileName: string,
    apiServer: string,
    isTemp: boolean,
    containerName: string,
) => {
    if (!file) return '';

    try {
        const storage = determineStorage(apiServer, isTemp);
        if (!storage) return '';

        const blobService = new BlobServiceClient(
            `https://${storage.storageAccountName}.blob.core.windows.net/?${storage.sasToken}`,
        );
        const containerClient: ContainerClient = blobService.getContainerClient(containerName);
        const uploadSuccess = await createBlobInContainer(containerClient, file, fileName);

        return uploadSuccess
            ? `https://${storage.storageAccountName}.blob.core.windows.net/${containerName}/${fileName}`
            : '';
    } catch (error: any) {
        // logger.error('uploadToAzureStorage() error: ', error.response || error);
        return '';
    }
};
