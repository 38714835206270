import { useEffect, useState } from 'react';
import { FitToViewport } from 'react-fit-to-viewport';

import styles from './TemplateLibrary.module.scss';

import { ViewportSavedClasses } from '../../constants/viewport-constants';

import SideMenu from './components/SideMenu/SideMenu';
import TemplateContent from './components/TemplateContent/TemplateContent';

import { CATEGORIES, TemplateCategoryType } from './categories';
import apiUser from '../../services/apiUser';
import { getValidUserFromStore } from '../../helpers/storeHelpers';
import webviewMessenger from '../../services/webviewMessenger';

export default function TemplateLibrary() {
    const user = getValidUserFromStore('templateLibrary');
    const [currentMenu, setCurrentMenu] = useState<TemplateCategoryType>(CATEGORIES[0]);

    useEffect(() => {
        webviewMessenger.sendUsageLog(`Opened quiz idea`);
        // apiUser.addUserOnBoarding(user.email, 'openQuizIdea');
        apiUser.trackFirstOpenQuizIdeas(user.email);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FitToViewport
            className="viewport templateLibraryViewport"
            width={ViewportSavedClasses.width}
            height={ViewportSavedClasses.height}
            minZoom={ViewportSavedClasses.minZoom}
            maxZoom={ViewportSavedClasses.maxZoom}
        >
            <div className={`${styles.templateLibrary}`}>
                <div className={`${styles.sideMenu}`}>
                    <SideMenu currentMenu={currentMenu} onMenuChange={setCurrentMenu} />
                </div>
                <div className={`${styles.mainContent}`}>
                    <TemplateContent currentMenu={currentMenu} />
                </div>
            </div>
        </FitToViewport>
    );
}
