import { Fragment } from 'react';
import loadingPaperPlan from '../../assets/animation/loadingPaperPlan.json';
import Lottie from 'react-lottie-player';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import lockedSVG from '../../assets/images/locked.svg';
import { getValidClassSessionFromStore } from '../../helpers/storeHelpers';

const WaitingForParticipants = () => {
    const classSession = getValidClassSessionFromStore('WaitingForParticipants');
    const { t } = useTranslation();
    return (
        <Fragment>
            {classSession.isLocked ? (
                <div className="my_class_wating">
                    <img src={lockedSVG} alt="locked" />
                    <Typography variant="h5">{t('lang_myclass.heading_class_locked')}</Typography>
                </div>
            ) : (
                <div className="my_class_wating">
                    <Lottie loop={true} animationData={loadingPaperPlan} play speed={1} />
                    <Typography variant="h5">{t('lang_myclass.txt_waiting_for_participant')}</Typography>
                </div>
            )}
        </Fragment>
    );
};

export default WaitingForParticipants;
