import { useTranslation } from 'react-i18next';

import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

import './GroupModalHeader.scss';

import { decodeGroupColorHash, isClickOutsideToCloseGroupAllowed } from '../../../../helpers/savedClassGroupHelper';

import { CreateOrEditGroupInterface, GroupStageType } from '../../../../interfaces/savedClassGroup.interface';
import SavedClassParticipantInterface from '../../../../interfaces/saved-class-participant.interface';
import { utilConstants } from '../../../../constants/utils.constants';
import ParticipantInterface from '../../../../interfaces/participant.interface';

interface IGroupModalHeader {
    isViewingInMyClass?: boolean;
    isAnimating: boolean;
    isEditMode: boolean;
    toggleEditMode: (value: boolean) => void;
    groupStage: GroupStageType;
    groupStageHandler: (value: GroupStageType) => void;
    currentGroupDetails: CreateOrEditGroupInterface;
    setCurrentGroupDetails: (value: CreateOrEditGroupInterface) => void;
    selectedParticipants: SavedClassParticipantInterface[];
    selectedParticipantsFromMyClass?: ParticipantInterface[];
    closeAddOrEditModalHandler: () => void;
}

export default function GroupModalHeader({
    isViewingInMyClass,
    isAnimating,
    isEditMode,
    toggleEditMode,
    groupStage,
    groupStageHandler,
    currentGroupDetails,
    setCurrentGroupDetails,
    selectedParticipants,
    selectedParticipantsFromMyClass,
    closeAddOrEditModalHandler,
}: IGroupModalHeader) {
    const { t } = useTranslation();

    const prevStepHandler = () => {
        if (isViewingInMyClass && currentGroupDetails.groupId > -1) {
            return groupStageHandler('point');
        }
        if (isEditMode) {
            if (groupStage === 'member') {
                const groupDetails = {
                    groupId: currentGroupDetails.groupId,
                    groupName: currentGroupDetails.groupName,
                    groupNameEdited: currentGroupDetails.groupNameEdited,
                    groupColor: currentGroupDetails.groupColor,
                    groupColorEdited: currentGroupDetails.groupColorEdited,
                    participantIds: currentGroupDetails.initialParticipantIds || [],
                };
                setCurrentGroupDetails(groupDetails);
            }
            return groupStageHandler('view');
        }
        groupStageHandler('group');
    };

    return (
        <div className="groupModalHeader">
            {/* Left Side */}
            {(groupStage === 'member' ||
                (groupStage === 'group' && isEditMode) ||
                (groupStage === 'group' && isViewingInMyClass && currentGroupDetails.groupId > -1)) && (
                <IconButton className="leftSide backIcon" onClick={() => prevStepHandler()}>
                    <ArrowBackIcon />
                </IconButton>
            )}

            {(groupStage === 'view' || groupStage === 'point') && (
                <p className={`leftSide editGroup`}>
                    <span
                        style={{ background: decodeGroupColorHash(currentGroupDetails.groupColor) }}
                        className={`groupColor`}
                    ></span>{' '}
                    <span className={`groupName`}>{currentGroupDetails.groupName}</span>
                    {groupStage !== 'point' && (
                        <ModeEditIcon
                            onClick={() => {
                                groupStageHandler('group');
                                toggleEditMode(true);
                            }}
                            className={`editIcon`}
                        />
                    )}
                </p>
            )}

            {/* Center */}
            {groupStage !== 'view' && groupStage !== 'point' ? (
                <h2 className={`heading`}>
                    {isEditMode
                        ? groupStage === 'group'
                            ? t('lang_grouping.edit_group')
                            : `${t('lang_grouping.edit_members')} (${currentGroupDetails.participantIds.length})`
                        : groupStage === 'group'
                        ? t('lang_grouping.add_new_group')
                        : `${t('lang_grouping.add_members')} (${currentGroupDetails.participantIds.length})`}
                </h2>
            ) : (
                <p className={`starCount`}>
                    {t('lang_grouping.total_stars')}:{' '}
                    <img src={utilConstants.IMAGE_URLS.starIcon} className={`starIcon`} alt="starIcon" />
                    <span className={`highlight`}>
                        {isViewingInMyClass && selectedParticipantsFromMyClass
                            ? selectedParticipantsFromMyClass.reduce(
                                  (acc, data) => (acc = acc + data.participantTotalPoints + data.participantPoints),
                                  0,
                              )
                            : selectedParticipants.reduce((acc, data) => (acc = acc + data.participantTotalPoints), 0)}
                    </span>
                </p>
            )}

            {/* Right Side */}
            {!isClickOutsideToCloseGroupAllowed(groupStage, currentGroupDetails.groupId > -1, isViewingInMyClass) && (
                <IconButton className="closeIcon" onClick={() => !isAnimating && closeAddOrEditModalHandler()}>
                    <CloseIcon className={`icon`} />
                </IconButton>
            )}
        </div>
    );
}
