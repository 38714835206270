import { Box, IconButton, Slider, Stack, Typography } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import MusicOffOutlinedIcon from '@mui/icons-material/MusicOffOutlined';
import MusicNoteOutlinedIcon from '@mui/icons-material/MusicNoteOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CommonButton from '../../../components/Common/CommonButton';
import CountDown from '../CountDown';
import CountUp from '../CountUp';
import { getActivityFromStore } from '../../../helpers/storeHelpers';
import StoreInterface from '../../../interfaces/store.interface';
import { utilConstants } from '../../../constants/utils.constants';
import { useTranslation } from 'react-i18next';
import { ActivityPropsInterface } from '../../../interfaces/activity-props.interface';
import { Shuffle } from '../../../Icon';
import { getRandomMusic } from '../../../helpers/utils';
import webviewMessenger from '../../../services/webviewMessenger';

interface ActivityFooterStartActivityInterface {
    closeSubmission: () => void;
    onToggleCorrectAnswer?: (arg: boolean) => void;
    onToggleShowActivityResponses: (arg: boolean) => void;
    onToggleActivityMusic: (arg: boolean) => void;
    onAdjustActivityMusicVolume: (arg: number) => void;
    onShuffleActivityMusic: (arg: string) => void;
}

export default function ActivityFooterStartActivity({
    closeSubmission,
    onToggleCorrectAnswer,
    onToggleShowActivityResponses,
    onToggleActivityMusic,
    onAdjustActivityMusicVolume,
    onShuffleActivityMusic,
}: ActivityFooterStartActivityInterface) {
    const [isProcessing, setIsProcessing] = useState(false);
    const activity = getActivityFromStore();
    const activityProps = activity.activityProps as ActivityPropsInterface;
    const webviewSettingsSlideshow = useSelector((state: StoreInterface) => state.webviewSettingsSlideshow);
    const errors = useSelector((state: StoreInterface) => state.errors);
    const { t } = useTranslation();

    const activityParticipantIds = activity.activityResponses
        .map((response) => response.participantId)
        .filter((value, index, self) => self.indexOf(value) === index);

    useEffect(() => {
        setIsProcessing(false);
    }, [activity.activityMode, errors.apiError]);

    const handleCloseSubmission = () => {
        webviewMessenger.sendUsageLog(
            `[S] Clicked "Close submission" button, ${activity.activityResponses.length} responses collected`,
        );
        setIsProcessing(true);
        closeSubmission();
        if (
            activityProps.activityType === utilConstants.ACTIVITY_TYPE.MULTIPLE_CHOICE &&
            activityProps.isQuizMode &&
            activity.activityResponses.length > 0
        )
            setTimeout(() => {
                onToggleCorrectAnswer && onToggleCorrectAnswer(true);
            }, 500);
    };

    const toggleMute = () => {
        webviewMessenger.sendUsageLog(`[S] Turned ${!webviewSettingsSlideshow.isActivityMusicOn ? 'ON' : 'OFF'} music`);
        onToggleActivityMusic(!webviewSettingsSlideshow.isActivityMusicOn);
    };

    const shuffleMusic = () => {
        webviewMessenger.sendUsageLog(`[S] Shuffled music`);
        let randomUrl = getRandomMusic();
        onShuffleActivityMusic(randomUrl);
    };

    const updateActivityVolume = (volume: number) => {
        onAdjustActivityMusicVolume(volume);
    };

    return (
        <Fragment>
            <div className="footer_left">
                <Typography variant="h4">
                    <span>
                        <PeopleOutlineOutlinedIcon />
                    </span>
                    {activityParticipantIds.length}
                </Typography>
                <Typography variant="h4">
                    {activityProps.activityEndTime ? (
                        <CountDown
                            activityEndTime={activityProps.activityEndTime}
                            closeSubmission={handleCloseSubmission}
                        />
                    ) : (
                        <CountUp />
                    )}
                </Typography>
            </div>

            <div className="footer_middle">
                <CommonButton
                    isLoading={isProcessing}
                    variant="contained"
                    className="foot_btn"
                    type="button"
                    clickFunction={handleCloseSubmission}
                    text={t('lang_activity.close_submission')}
                />
            </div>
            <div className="footer_right">
                {webviewSettingsSlideshow.isShowActivityResponses ? (
                    <Typography
                        variant="h4"
                        onClick={() => {
                            webviewMessenger.sendUsageLog(`[S] Toggled OFF show responses`);
                            onToggleShowActivityResponses(false);
                        }}
                    >
                        <span>
                            <VisibilityOutlinedIcon />
                        </span>
                        {t('lang_activity.responses')}
                    </Typography>
                ) : (
                    <Typography
                        variant="h4"
                        onClick={() => {
                            webviewMessenger.sendUsageLog(`[S] Toggled ON show responses`);
                            onToggleShowActivityResponses(true);
                        }}
                    >
                        <span>
                            <VisibilityOffIcon />
                        </span>
                        {t('lang_activity.responses')}
                    </Typography>
                )}
                <div className="music_box">
                    <Stack spacing={2} direction="column" alignItems="center" className="volumeslider">
                        <IconButton aria-label="delete" size="small" className="shuffle" onClick={() => shuffleMusic()}>
                            <Shuffle />
                        </IconButton>
                        <Box className="slide_volume">
                            <Slider
                                sx={{
                                    '& input[type="range"]': {
                                        WebkitAppearance: 'slider-vertical',
                                    },
                                }}
                                orientation="vertical"
                                defaultValue={30}
                                valueLabelDisplay="auto"
                                aria-label="Volume"
                                onChange={(e: any) => updateActivityVolume(e.target.value)}
                                value={webviewSettingsSlideshow.activityMusicVolume}
                            />
                        </Box>
                        {webviewSettingsSlideshow.isActivityMusicOn ? (
                            <MusicNoteOutlinedIcon onClick={() => toggleMute()} />
                        ) : (
                            <MusicOffOutlinedIcon onClick={() => toggleMute()} />
                        )}
                    </Stack>
                    <Typography variant="h4" onClick={() => toggleMute()}>
                        {t('lang_activity.music')}
                    </Typography>
                </div>
            </div>
        </Fragment>
    );
}
