import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Close from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { getActivityFromStore } from '../../../helpers/storeHelpers';
import { utilConstants } from '../../../constants/utils.constants';
import { useCallback, useEffect, useRef, useState } from 'react';
import { store } from '../../../helpers/store';
import webviewMessenger from '../../../services/webviewMessenger';

function SearchBox({ onSearch }: { onSearch: (arg: string) => void }) {
    const { t } = useTranslation();
    const classSession = store.getState().classSession; // may not exist if we are in edit mode
    const activity = getActivityFromStore();
    const [searchKeyword, setSearchKeyword] = useState('');
    const activityType = activity.activityProps?.activityType || '';
    const isNamesHidden = activity.activityProps?.isNamesHidden;
    const searchTimeout = useRef<any>();

    useEffect(() => {
        onSearch('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classSession?.classSessionName, activity.activityProps]);

    const generatePlaceholderText = () => {
        const ActivityTypesEnum = utilConstants.ACTIVITY_TYPE;
        if (window.location.pathname === '/myclass') return t('lang_common.placeholder_search_name'); // in case of My Class dialog

        const searchNameOnlyActivities = [ActivityTypesEnum.SLIDE_DRAWING, ActivityTypesEnum.AUDIO_RECORD];

        if (activityType === utilConstants.ACTIVITY_TYPE.WORD_CLOUD) return t('lang_common.placeholder_search_answer');
        else if (searchNameOnlyActivities.includes(activityType)) {
            return isNamesHidden ? '' : t('lang_common.placeholder_search_name');
        } else if (activityType === ActivityTypesEnum.IMAGE_UPLOAD || activityType === ActivityTypesEnum.VIDEO_UPLOAD) {
            return isNamesHidden
                ? t('lang_common.placeholder_search_caption')
                : t('lang_common.placeholder_search_name_caption');
        } else {
            // Short Answer or Fill Blanks
            return isNamesHidden
                ? t('lang_common.placeholder_search_answer')
                : t('lang_common.placeholder_search_name_answer');
        }
    };

    const handleSearchChanged = useCallback((inputText: string) => {
        setSearchKeyword(inputText);
        clearTimeout(searchTimeout.current);
        searchTimeout.current = setTimeout(() => {
            webviewMessenger.sendUsageLog(
                `${classSession ? '[S] ' : ''}Searched keyword: ${inputText.trim().toLowerCase()}`,
            );
            onSearch(inputText.trim().toLowerCase());
        }, 100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="searchfield">
            <TextField
                autoComplete="none"
                fullWidth
                placeholder={generatePlaceholderText()}
                id="fullWidth"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {searchKeyword ? (
                                <Close
                                    onClick={() => {
                                        setSearchKeyword('');
                                        onSearch('');
                                    }}
                                />
                            ) : (
                                <SearchIcon />
                            )}
                        </InputAdornment>
                    ),
                }}
                value={searchKeyword}
                onChange={(e) => handleSearchChanged(e.target.value)}
            />
        </div>
    );
}

export default SearchBox;
