import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormControl, InputLabel, Typography } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { utilConstants } from '../../../../constants/utils.constants';

interface JobListTypeInterface {
    isViewMode: boolean;
    dropDownOpen: boolean;
    selectedJobRole: string;
    dropDownClickHandler: any;
    handleSelectJobRole: (arg: string) => void;
}

function JobListType({
    isViewMode,
    dropDownOpen,
    selectedJobRole,
    dropDownClickHandler,
    handleSelectJobRole,
}: JobListTypeInterface) {
    const { t } = useTranslation();

    return (
        <>
            <FormControl
                variant="standard"
                disabled={isViewMode}
                className={`${dropDownOpen ? 'select_box show_focus' : 'select_box'} ${selectedJobRole && 'hasValue'}`}
            >
                <InputLabel> {t('lang_auth.dropdown_profession')}</InputLabel>

                <Typography
                    variant="body1"
                    className="select"
                    onClick={() => {
                        !isViewMode && dropDownClickHandler();
                    }}
                >
                    {selectedJobRole ? t(`lang_auth.txt_${selectedJobRole}`) : isViewMode ? '-' : ''}
                    <ExpandLessIcon />
                </Typography>
            </FormControl>
            <div className={dropDownOpen ? 'listItemContainer show' : 'listItemContainer'}>
                {utilConstants.JOB_ROLES.map((role, index) => (
                    <Fragment key={index}>
                        <Button
                            variant="text"
                            className={`${role === selectedJobRole ? 'active' : ''} ${
                                index === utilConstants.JOB_ROLES.length - 1 && 'lastElement'
                            }`}
                            onClick={() => {
                                handleSelectJobRole(role);
                            }}
                        >
                            <Typography variant="body1">{t(`lang_auth.txt_${role}`)}</Typography>
                        </Button>
                    </Fragment>
                ))}
            </div>
        </>
    );
}

export default JobListType;
