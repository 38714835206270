import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { userActions } from '../../actions/user.actions';
import apiSavedClasses from '../../services/apiSavedClasses';
import { UserActionTypes } from '../../constants/user-action-types';
import { getValidUserFromStore } from '../../helpers/storeHelpers';
import SavedClassForUserInterface from '../../interfaces/saved-class-for-user.interface';
import { ViewportSavedClasses } from '../../constants/viewport-constants';

import ViewEditSavedClassSidePanal from './components/ViewEditSavedClassSidePanal';
import SavedClassParticipants from './components/SavedClassParticipants';
import CommonAlert from '../../components/Common/CommonAlert';
import DeleteAlert from './components/alert/DeleteAlert';
import ResetStarsAlert from './components/alert/ResetStarsAlert';

interface ViewEditSavedClassInterface {
    changeCurrentPage: (arg: string) => void;
    savedClass: SavedClassForUserInterface;
    setSavedClass: (arg: SavedClassForUserInterface) => void;
}

const ViewEditSavedClass = ({ changeCurrentPage, savedClass, setSavedClass }: ViewEditSavedClassInterface) => {
    const dispatch = useDispatch();
    const user = getValidUserFromStore('ViewEditSavedClass');
    const [isDeleteClassWarningOpen, setIsDeleteClassWarningOpen] = useState(false);
    const [isResetStarsOpen, setIsResetStarsOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isResetting, setIsResetting] = useState(false);
    const [isParticipantUpdated, setIsParticipantUpdated] = useState(false);

    const { t } = useTranslation();

    const handleDeleteSavedClass = async () => {
        setIsDeleting(true);
        const updatedUser = await apiSavedClasses.deleteSavedClass(savedClass.savedClassId);
        if (!updatedUser) {
            setIsDeleting(false);
            dispatch(userActions.showApiError());
            return;
        }
        dispatch(userActions.updateUser(UserActionTypes.DELETE_SAVED_CLASS, updatedUser));
        setIsDeleting(false);
        setIsDeleteClassWarningOpen(false);
        changeCurrentPage('SavedClassList');
    };

    const handleResetStars = async () => {
        setIsResetting(true);
        const savedClassDetails = await apiSavedClasses.getSavedClass(savedClass.savedClassId, user.email);
        if (!savedClassDetails) {
            setIsResetting(false);
            dispatch(userActions.showApiError());
            return;
        }

        const editSavedClassReply = await apiSavedClasses.resetSavedClassPoints(savedClass.savedClassId);
        if (!editSavedClassReply) {
            setIsDeleting(false);
            dispatch(userActions.showApiError());
            return;
        }
        setIsResetting(false);
        setIsResetStarsOpen(false);
        setIsParticipantUpdated(true);
    };

    return (
        <Fragment>
            <div className="view_edit_sec">
                <ViewEditSavedClassSidePanal
                    changeCurrentPage={changeCurrentPage}
                    savedClass={savedClass}
                    setSavedClass={setSavedClass}
                    onDeleteSavedClass={() => setIsDeleteClassWarningOpen(true)}
                    onResetStars={() => setIsResetStarsOpen(true)}
                />
                <SavedClassParticipants
                    isParticipantUpdated={isParticipantUpdated}
                    setIsParticipantUpdated={setIsParticipantUpdated}
                    savedClass={savedClass}
                    setSavedClass={setSavedClass}
                    onDeleteSavedClass={handleDeleteSavedClass}
                />
                <CommonAlert
                    showAlert={isDeleteClassWarningOpen}
                    content={
                        <DeleteAlert
                            onConfirmDeleteClass={() => handleDeleteSavedClass()}
                            onCloseAlert={() => setIsDeleteClassWarningOpen(false)}
                            content={t('lang_saved_classes.alert_delete_class', {
                                className: savedClass.savedClassName,
                            })}
                            isDeleting={isDeleting}
                            deleteType="class"
                        />
                    }
                    viewportConfig={ViewportSavedClasses}
                    customModalClassNames="small"
                />

                <CommonAlert
                    showAlert={isResetStarsOpen}
                    content={
                        <ResetStarsAlert
                            onConfirmResetStars={() => handleResetStars()}
                            onCloseAlert={() => setIsResetStarsOpen(false)}
                            content={
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: t('lang_saved_classes.alert_reset_stars', {
                                            className: savedClass.savedClassName,
                                        }),
                                    }}
                                />
                            }
                            isResetting={isResetting}
                        />
                    }
                    viewportConfig={ViewportSavedClasses}
                    customModalClassNames="small"
                />
            </div>
        </Fragment>
    );
};

export default ViewEditSavedClass;
