import { InterfaceLanguages } from './../constants/languages';
import { logger } from './logger';
import { QuizSummaryInterface } from './../helpers/quizSummaryHelper';
import SavedClassAddedParticipantInterface from '../interfaces/saved-class-added-participant.interface';
import StoreInterface from '../interfaces/store.interface';
import CachedResponsesInterface from '../interfaces/cached-responses.interface';
import CachedParticipantsInterface from '../interfaces/cached-participants.interface';
import ToolbarActionsInterface from '../interfaces/toolbar-actions.interface';
import webviewMessenger from './webviewMessenger';
import SyncToolbarActionsDto from '../dtos/sync-toolbar-actions.dto';
import { UpdateToolbarActionsType } from '../dtos/update-toolbar-actions.dto';
import GotoStepDto from '../dtos/goto-step.dto';
import { SlideIdAndTextInterface } from '../interfaces/aiQuiz.interface';

const getStatsFromHost = () => {
    return JSON.parse(localStorage.getItem('statsFromHost')!);
};

const setStatsFromHost = (value: any) => {
    localStorage.setItem('statsFromHost', JSON.stringify(value));
};

const getStoreData = (): StoreInterface => {
    return JSON.parse(localStorage.getItem('store')!);
};

const setStoreData = (value: any) => {
    localStorage.setItem('store', JSON.stringify(value));
};

const getTempAddedParticipants = (): SavedClassAddedParticipantInterface[] | null => {
    const savedString = localStorage.getItem('tempAddedParticipants');
    if (!savedString) return null;
    return JSON.parse(savedString);
};

const setTempAddedParticipants = (value: any) => {
    localStorage.setItem('tempAddedParticipants', JSON.stringify(value));
};

const removeTempAddedParticipants = () => {
    localStorage.removeItem('tempAddedParticipants');
};

const getQuizActivityIds = (): string[] => {
    const quizActivityIdsString = localStorage.getItem('quizActivityIds');
    if (!quizActivityIdsString) return [];
    return JSON.parse(quizActivityIdsString);
};

const setQuizActivityIds = (value: string[]) => {
    localStorage.setItem('quizActivityIds', JSON.stringify(value));
};

const getQuizSummary = (): QuizSummaryInterface | null => {
    const savedString = localStorage.getItem('quizSummary');
    if (!savedString) return null;
    return JSON.parse(savedString);
};

const setQuizSummary = (value: any) => {
    localStorage.setItem('quizSummary', JSON.stringify(value));
};

const getTempWhiteboard = (): string | null => {
    return localStorage.getItem('tempWhiteboard');
};

const setTempWhiteboard = (value: string) => {
    localStorage.setItem('tempWhiteboard', value);
};

const removeTempWhiteboard = () => {
    localStorage.removeItem('tempWhiteboard');
};

const getTempMovableObject = (): string | null => {
    return localStorage.getItem('tempMovableObject');
};

const setTempMovableObject = (value: string) => {
    localStorage.setItem('tempMovableObject', value);
};

const removeTempMovableObject = () => {
    localStorage.removeItem('tempMovableObject');
};

const getLanguage = (): string => {
    let currentLanguage = 'en';
    const cachedLanguage = localStorage.getItem('i18nextLng');
    if (cachedLanguage) {
        const langFound = InterfaceLanguages.findIndex((language) => language.code === cachedLanguage) >= 0;
        if (!langFound) {
            currentLanguage = 'en';
            setLanguage(currentLanguage);
        } else currentLanguage = cachedLanguage;
    } else setLanguage(currentLanguage);
    return currentLanguage;
};

const setLanguage = (languageCode: string) => {
    logger.warn('Local storage set language: ', languageCode);
    localStorage.setItem('i18nextLng', languageCode);
};

const getExitSlideshowTime = (): number => {
    return parseInt(localStorage.getItem('exitSlideshowTime') || '0');
};

const setExitSlideshowTime = () => {
    const onLocalEnv = process.env.REACT_APP_ENV === 'local';
    if (!onLocalEnv) localStorage.setItem('exitSlideshowTime', new Date().getTime().toString());
};

const removeExitSlideshowTime = () => {
    localStorage.removeItem('exitSlideshowTime');
};

const getBrowserTabs = () => {
    return localStorage.getItem('tabs');
};

const setBrowserTabs = (value: string) => {
    localStorage.setItem('tabs', value);
};

const removeBrowserTabs = () => {
    localStorage.removeItem('tabs');
};

const setPickedNamesInModal = (participantIdArray: string[]) => {
    localStorage.setItem('pickedParticipantIdsInModal', JSON.stringify(participantIdArray));
};

const getPickedNamesInModal = (): string[] => {
    const pickedParticipantIdsInModal = localStorage.getItem('pickedParticipantIdsInModal');
    if (pickedParticipantIdsInModal) return JSON.parse(pickedParticipantIdsInModal);
    else return [];
};

const removePickedNamesInModal = () => localStorage.removeItem('pickedParticipantIdsInModal');

const setLastSavedClass = (email: string, savedClassId: string, classCode: string) => {
    localStorage.setItem('lastSavedClass', JSON.stringify({ email, savedClassId, classCode }));
};

const getLastSavedClass = (): { email: string; savedClassId: string; classCode: string } | null => {
    const lastSavedClassString = localStorage.getItem('lastSavedClass');
    if (!lastSavedClassString) return null;
    return JSON.parse(lastSavedClassString);
};

const removeLastSavedClass = () => {
    localStorage.removeItem('lastSavedClass');
};

const cleanUp = () => {
    localStorage.removeItem('pickedIndex');
    localStorage.removeItem('cpcsRegion');
    localStorage.removeItem('exitSlideshowTime');
    localStorage.removeItem('movableObjectFolderArray');
    localStorage.removeItem('lastSavedClass');
    removeToolbarActionsAndResetHost();
};

const getCachedResponses = (): CachedResponsesInterface | null => {
    const cachedResponsesString = localStorage.getItem('cachedResponses');
    if (!cachedResponsesString) return null;
    const cachedResponses: CachedResponsesInterface = JSON.parse(cachedResponsesString);
    return cachedResponses;
};

const setCachedResponses = (cachedResponses: CachedResponsesInterface) => {
    localStorage.setItem('cachedResponses', JSON.stringify(cachedResponses));
};

const removeCachedResponses = () => {
    localStorage.removeItem('cachedResponses');
};

const getCachedParticipants = (): CachedParticipantsInterface | null => {
    const cachedParticipantsString = localStorage.getItem('cachedParticipants');
    if (!cachedParticipantsString) return null;
    const cachedParticipants: CachedParticipantsInterface = JSON.parse(cachedParticipantsString);
    return cachedParticipants;
};

const setCachedParticipants = (cachedParticipants: CachedParticipantsInterface) => {
    localStorage.setItem('cachedParticipants', JSON.stringify(cachedParticipants));
};

const removeCachedParticipants = () => {
    localStorage.removeItem('cachedParticipants');
};

const getToolbarActions = () => {
    const toolbarActionsString = localStorage.getItem('toolbarActions');
    if (!toolbarActionsString) return null;
    const toolbarActions: ToolbarActionsInterface = JSON.parse(toolbarActionsString);
    return toolbarActions;
};

const syncToolbarActionsFromHost = (hostToolbarActions: SyncToolbarActionsDto) => {
    let existingToolbarActions = getToolbarActions();
    if (!existingToolbarActions) existingToolbarActions = hostToolbarActions;
    else existingToolbarActions = { ...existingToolbarActions, ...hostToolbarActions };
    localStorage.setItem('toolbarActions', JSON.stringify(existingToolbarActions));
};

const updateToolbarActionsFromWebview = (toolbarActionKey: UpdateToolbarActionsType) => {
    let existingToolbarActions = getToolbarActions();
    if (!existingToolbarActions) {
        existingToolbarActions = {};
        existingToolbarActions[toolbarActionKey] = 1;
    } else {
        const oldValue = existingToolbarActions[toolbarActionKey] || 0;
        existingToolbarActions[toolbarActionKey] = oldValue + 1;
    }
    localStorage.setItem('toolbarActions', JSON.stringify(existingToolbarActions));
};

const removeToolbarActionsAndResetHost = () => {
    localStorage.removeItem('toolbarActions');
    webviewMessenger.sendSimpleMessage('resetToolbarActions');
};

const setNewVersionDownloaded = (newVersion: string) => {
    localStorage.setItem('newVersion', newVersion);
};

const getNewVersionDownloaded = () => {
    return localStorage.getItem('newVersion');
};

const removeNewVersionDownloaded = () => {
    localStorage.removeItem('newVersion');
};

const markSignalrRetry = () => {
    localStorage.setItem('signalrRetry', 'signalrRetry');
};

const markSignalrRetryDone = () => {
    localStorage.removeItem('signalrRetry');
};

const checkIfSignalrRetryDone = () => {
    return localStorage.getItem('signalrRetry') === null;
};

const saveGotoStepDto = (dto: GotoStepDto) => {
    localStorage.setItem('gotoStepDto', JSON.stringify(dto));
};

const getGotoStepDto = () => {
    const dtoString = localStorage.getItem('gotoStepDto');
    if (!dtoString) return null;
    const dto: GotoStepDto = JSON.parse(dtoString);
    return dto;
};

const removeGotoStepDto = () => {
    localStorage.removeItem('gotoStepDto');
};

const setSignalrRetryCount = (count: number) => {
    localStorage.setItem('signalrRetryCount', count.toString());
};

const getSignalrRetryCount = (): number => {
    const countString = localStorage.getItem('signalrRetryCount');
    if (!countString) return 0;
    return parseInt(countString);
};

const removeSignalrRetryCount = () => {
    localStorage.removeItem('signalrRetryCount');
};

const setForceTrafficManager = () => {
    localStorage.setItem('forceTrafficManager', 'true');
};

const getForceTrafficManager = () => {
    return localStorage.getItem('forceTrafficManager') === 'true';
};

const removeForceTrafficManager = () => {
    localStorage.removeItem('forceTrafficManager');
};

const setSlideIdText = (data: SlideIdAndTextInterface) => {
    localStorage.setItem('slideIdAndText', JSON.stringify(data));
};

const getSlideIdText = (): SlideIdAndTextInterface => {
    const currentSlideText = localStorage.getItem('slideIdAndText');
    if (!currentSlideText)
        return {
            currentSlideId: 0,
            currentSlideText: '',
            currentSlideNote: '',
        };
    return JSON.parse(currentSlideText);
};

const removeSlideIdText = () => {
    localStorage.removeItem('slideIdAndText');
};

const incrementCurrentDayAiConsumption = (email: string) => {
    let count = 0;
    const currentDayAiConsumption = localStorage.getItem('currentDayAiConsumption');
    if (!currentDayAiConsumption) {
        localStorage.setItem(
            'currentDayAiConsumption',
            JSON.stringify({ [email]: { date: new Date().getDate(), count: 1 } }),
        );
        return;
    }
    const currentDayAiConsumptionObj = JSON.parse(currentDayAiConsumption);
    if (!currentDayAiConsumptionObj[email] || currentDayAiConsumptionObj[email].date !== new Date().getDate())
        count = 1;
    else count = currentDayAiConsumptionObj[email].count + 1;
    currentDayAiConsumptionObj[email] = { date: new Date().getDate(), count };
    localStorage.setItem('currentDayAiConsumption', JSON.stringify(currentDayAiConsumptionObj));
    incrementCurrentMonthAiConsumption(email);
};

const getCurrentDayAiConsumption = (email: string): number => {
    const currentDayAiConsumption = localStorage.getItem('currentDayAiConsumption');
    if (!currentDayAiConsumption) return 0;
    const currentDayAiConsumptionObj = JSON.parse(currentDayAiConsumption);
    if (!currentDayAiConsumptionObj[email] || currentDayAiConsumptionObj[email].date !== new Date().getDate()) return 0;
    return currentDayAiConsumptionObj[email].count;
};

const incrementCurrentMonthAiConsumption = (email: string) => {
    let count = 0;
    const currentMonthAiConsumption = localStorage.getItem('currentMonthAiConsumption');
    if (!currentMonthAiConsumption) {
        localStorage.setItem(
            'currentMonthAiConsumption',
            JSON.stringify({ [email]: { month: new Date().getMonth(), count: 1 } }),
        );
        return;
    }
    const currentMonthAiConsumptionObj = JSON.parse(currentMonthAiConsumption);
    if (!currentMonthAiConsumptionObj[email] || currentMonthAiConsumptionObj[email].month !== new Date().getMonth())
        count = 1;
    else count = currentMonthAiConsumptionObj[email].count + 1;
    currentMonthAiConsumptionObj[email] = { month: new Date().getMonth(), count };
    localStorage.setItem('currentMonthAiConsumption', JSON.stringify(currentMonthAiConsumptionObj));
};

const getCurrentMonthAiConsumption = (email: string): number => {
    const currentMonthAiConsumption = localStorage.getItem('currentMonthAiConsumption');
    if (!currentMonthAiConsumption) return 0;
    const currentMonthAiConsumptionObj = JSON.parse(currentMonthAiConsumption);
    if (!currentMonthAiConsumptionObj[email] || currentMonthAiConsumptionObj[email].month !== new Date().getMonth())
        return 0;
    return currentMonthAiConsumptionObj[email].count;
};

export const localService = {
    getStatsFromHost,
    setStatsFromHost,
    getStoreData,
    setStoreData,
    getTempAddedParticipants,
    setTempAddedParticipants,
    removeTempAddedParticipants,
    getQuizActivityIds,
    setQuizActivityIds,
    getQuizSummary,
    setQuizSummary,
    getTempWhiteboard,
    setTempWhiteboard,
    removeTempWhiteboard,
    getTempMovableObject,
    setTempMovableObject,
    removeTempMovableObject,
    getLanguage,
    setLanguage,
    getExitSlideshowTime,
    setExitSlideshowTime,
    removeExitSlideshowTime,
    getBrowserTabs,
    setBrowserTabs,
    removeBrowserTabs,
    setPickedNamesInModal,
    getPickedNamesInModal,
    removePickedNamesInModal,
    setLastSavedClass,
    getLastSavedClass,
    removeLastSavedClass,
    cleanUp,
    getCachedResponses,
    setCachedResponses,
    removeCachedResponses,
    getCachedParticipants,
    setCachedParticipants,
    removeCachedParticipants,
    getToolbarActions,
    syncToolbarActionsFromHost,
    updateToolbarActionsFromWebview,
    removeToolbarActionsAndResetHost,
    setNewVersionDownloaded,
    getNewVersionDownloaded,
    removeNewVersionDownloaded,
    markSignalrRetry,
    markSignalrRetryDone,
    checkIfSignalrRetryDone,
    saveGotoStepDto,
    getGotoStepDto,
    removeGotoStepDto,
    setSignalrRetryCount,
    getSignalrRetryCount,
    removeSignalrRetryCount,
    setForceTrafficManager,
    getForceTrafficManager,
    removeForceTrafficManager,
    setSlideIdText,
    getSlideIdText,
    removeSlideIdText,
    incrementCurrentDayAiConsumption,
    getCurrentDayAiConsumption,
    incrementCurrentMonthAiConsumption,
    getCurrentMonthAiConsumption,
};
