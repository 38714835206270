import { Fragment, useState, forwardRef } from 'react';
import { FitToViewport } from 'react-fit-to-viewport';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

import { userActions } from '../../../../actions/user.actions';
import apiSavedClasses from '../../../../services/apiSavedClasses';
import { calculateParticipantLevelAndBadge, getLevelsArray } from '../../../../helpers/userhelper';
import { getValidUserFromStore } from '../../../../helpers/storeHelpers';

import SavedClassParticipantInterface from '../../../../interfaces/saved-class-participant.interface';
import SavedClassForUserInterface from '../../../../interfaces/saved-class-for-user.interface';
import { SavedClassGroupInterface } from '../../../../interfaces/savedClassGroup.interface';
import { ViewportSavedClasses } from '../../../../constants/viewport-constants';

import CustomAvatar, { AvatarType } from '../../../../components/Common/CustomAvatar';
import CommonButton from '../../../../components/Common/CommonButton';

interface AdjustStarParticipantModalInterface {
    closeAlert: any;
    savedClass: SavedClassForUserInterface;
    selectedParticipant: SavedClassParticipantInterface;
    onParticipantsUpdated: any;
    savedClassGroups: SavedClassGroupInterface[];
}

const AdjustStarParticipantModal = (
    {
        closeAlert,
        savedClass,
        selectedParticipant,
        onParticipantsUpdated,
        savedClassGroups,
    }: AdjustStarParticipantModalInterface,
    ref: any,
) => {
    const dispatch = useDispatch();
    const user = getValidUserFromStore('AdjustStarParticipantModal');
    const [newPoint, setNewPoint] = useState(selectedParticipant.participantTotalPoints);
    const [isProcessing, setIsProcessing] = useState(false);
    const { t } = useTranslation();

    const changePoint = (buttonFlag: number) => {
        if (buttonFlag === 1) {
            setNewPoint((prev) => prev + 1);
            return;
        }
        if (newPoint < 1) {
            setNewPoint(0);
            return;
        }
        setNewPoint((prev) => prev - 1);
    };

    const submitAdjustStarParticipant = async () => {
        setIsProcessing(true);

        const editSavedClassReply = await apiSavedClasses.adjustParticipantPoints(
            savedClass.savedClassId,
            selectedParticipant.participantId,
            newPoint - selectedParticipant.participantTotalPoints,
        );

        setIsProcessing(false);
        closeAlert();

        if (!editSavedClassReply) return dispatch(userActions.showApiError());

        const participantsWithLevel = editSavedClassReply.participants.map((p) => ({
            ...p,
            participantLevelAndBadge: calculateParticipantLevelAndBadge(p.participantTotalPoints, getLevelsArray(user)),
        }));

        onParticipantsUpdated(participantsWithLevel);
    };

    return (
        <Fragment>
            <FitToViewport
                className="modal_viewport"
                width={ViewportSavedClasses.width}
                height={ViewportSavedClasses.height}
                minZoom={ViewportSavedClasses.minZoom}
                maxZoom={ViewportSavedClasses.maxZoom}
            >
                <div className="close_modal" onClick={closeAlert}></div>
                <Box className="csvmodal_box wdth_400">
                    <div className="modal_head" onClick={closeAlert}>
                        <CloseIcon />
                    </div>

                    <div className="addparticipent_modal_sec">
                        <Typography variant="body1">{t('lang_saved_classes.txt_adjust_participant_star')}</Typography>
                        <div className="participent_table_box">
                            <div className="left">
                                <div className="profile_img">
                                    <CustomAvatar
                                        avatarName={selectedParticipant.participantName}
                                        avatarUrl={selectedParticipant.participantAvatar}
                                        type={AvatarType.PARTICIPANT}
                                        savedClassGroups={savedClassGroups}
                                        groupId={selectedParticipant.groupId}
                                    />
                                </div>
                                <div className="profile_name">{selectedParticipant.participantName}</div>
                            </div>
                            <div className="right">
                                <div className="adjust_point">
                                    <IconButton onClick={() => changePoint(2)} disabled={newPoint < 1 ? true : false}>
                                        <RemoveIcon />
                                    </IconButton>
                                    <Typography variant="caption" className="new_adjust_point">
                                        {newPoint}
                                    </Typography>
                                    <IconButton onClick={() => changePoint(1)}>
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                        <div className="participent_table_error"></div>
                    </div>
                    <div className="button_sec justify_center">
                        {isProcessing ? (
                            <CommonButton
                                isLoading={true}
                                variant="contained"
                                type="button"
                                text={t('lang_common.btn_updating')}
                            />
                        ) : (
                            <CommonButton
                                isLoading={false}
                                variant="contained"
                                type="button"
                                text={t('lang_common.btn_save')}
                                clickFunction={() => submitAdjustStarParticipant()}
                            />
                        )}
                    </div>
                </Box>
            </FitToViewport>
        </Fragment>
    );
};

export default forwardRef(AdjustStarParticipantModal);
