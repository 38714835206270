import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import styles from './QuestionModalCard.module.scss';

import { calculateQnaModalTextSize } from '../../../../helpers/qnaHelpers';
import { getValidClassSessionFromStore } from '../../../../helpers/storeHelpers';

import StoreInterface from '../../../../interfaces/store.interface';

import QuestionCardFooter from '../QuestionCardFooter/QuestionCardFooter';
import CustomAvatar, { AvatarType } from '../../../../components/Common/CustomAvatar';
// import CommonButton from '../../../../components/Common/CommonButton';

import { THUMBS_UP_OUTLINED, THUMBS_UP_SOLID } from '../../../../Icon';

const QuestionModalCard = () => {
    const { t } = useTranslation();

    const { activeTab, newQnas, answeredQnas, selectedQuestionIndex, savedClassGroups } = useSelector(
        (state: StoreInterface) => state.qna,
    );

    const [isShowingVoters, setIsShowingVoters] = useState(false);

    const activeTabData = activeTab === 'new' ? newQnas : answeredQnas;
    const qna = activeTabData[selectedQuestionIndex];

    const getParticipantDetails = (participantId: string, index: number) => {
        const participantList = getValidClassSessionFromStore('qna')?.participantList || [];
        const selectedParticipant = participantList.find((participant) => participant.participantId === participantId);
        if (!selectedParticipant) return;

        return (
            <div key={index} className={`${styles.details}`}>
                <CustomAvatar
                    avatarName={selectedParticipant.participantName}
                    avatarUrl={selectedParticipant.participantAvatar}
                    type={AvatarType.PARTICIPANT}
                    savedClassGroups={savedClassGroups}
                    groupId={qna.groupId}
                />
                <p className={`${styles.participantName}`}>{selectedParticipant.participantName}</p>
            </div>
        );
    };

    useEffect(() => {
        isShowingVoters && setIsShowingVoters(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedQuestionIndex]);

    if (!qna) return null;
    return (
        <div className={styles.questionCard}>
            {isShowingVoters ? (
                <div className={`${styles.voters}`}>
                    <div className={`${styles.header}`}>
                        <IconButton onClick={() => setIsShowingVoters(false)} className={`${styles.backBtn}`}>
                            <ArrowBackIcon className={`${styles.icon}`} />
                        </IconButton>
                        <p className={`${styles.votersCount}`}>
                            {qna.votes} {qna.votes > 1 ? t(`lang_qna.votes_plural`) : t(`lang_qna.votes_singular`)}
                        </p>
                    </div>
                    <div className={`${styles.voterContainerWrapper}`}>
                        <div className={`${styles.voterContainer}`}>
                            {qna.voters?.map((voter, index) => getParticipantDetails(voter, index))}
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styles.inner_questionCard}>
                    <div className={styles.top_content}>
                        <Button
                            onClick={() => qna.votes > 0 && setIsShowingVoters(true)}
                            className={`${styles.upgrade_ans} ${qna.votes < 1 && styles.disabled}`}
                        >
                            <i className={styles.icon}>{qna.votes ? <THUMBS_UP_SOLID /> : <THUMBS_UP_OUTLINED />}</i>
                            {qna.votes} {qna.votes > 1 ? t(`lang_qna.votes_plural`) : t(`lang_qna.votes_singular`)}
                        </Button>

                        <div className={`${styles.question_area}`}>
                            <p
                                style={{
                                    fontSize: `${calculateQnaModalTextSize(qna.question)}px`,
                                    lineHeight: `${calculateQnaModalTextSize(qna.question) * 1.44}px`,
                                }}
                                className={`${styles.text}`}
                            >
                                {qna.question}
                            </p>
                        </div>

                        <div className={styles.profile_foot}>
                            <div className={styles.avater_box}>
                                <CustomAvatar
                                    avatarName={qna.participantName}
                                    avatarUrl={qna.participantAvatar}
                                    type={AvatarType.PARTICIPANT}
                                    savedClassGroups={savedClassGroups}
                                    groupId={qna.groupId}
                                />
                            </div>
                            <Typography className={styles.text} variant="body1">
                                {qna.participantName}
                            </Typography>
                        </div>
                    </div>

                    <QuestionCardFooter qna={qna} isModalView={true} />
                </div>
            )}
        </div>
    );
};

export default QuestionModalCard;
