import { Typography } from '@mui/material';
import loaderAnimation from '../../../assets/animation/87884-loader.json';
import Lottie from 'react-lottie-player';

export default function LazyLoadingInProgress() {
    return (
        <div className="loading_text">
            <Typography variant="body1">
                <Lottie
                    loop={true}
                    animationData={loaderAnimation}
                    play
                    speed={1}
                />
            </Typography>
        </div>
    );
}
