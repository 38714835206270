import { Fragment } from 'react';
import { FitToViewport } from 'react-fit-to-viewport';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@mui/material';

import './FreeCredit.scss';

import { getValidUserFromStore } from '../../../helpers/storeHelpers';
import webviewMessenger from '../../../services/webviewMessenger';
import { localService } from '../../../services/localStorageService';

import { ViewportConstants } from '../../../constants/viewport-constants';
import { QuotaConstants } from '../../../constants/quota-constants';
import { WEBSITE_URL } from '../../../constants/utils.constants';

interface MonthlyQuotaModalInterface {
    isOpen: boolean;
    handleClose: () => void;
}

const MonthlyQuotaModal = ({ isOpen, handleClose }: MonthlyQuotaModalInterface) => {
    const { t } = useTranslation();
    const user = getValidUserFromStore('MonthlyQuotaModal');
    const aiQuotaMonthlyCount = Math.min(
        QuotaConstants.PRO_USER_AI_MONTHLY_LIMIT,
        localService.getCurrentMonthAiConsumption(user.email),
    );

    return (
        <Fragment>
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="dailog_modal"
            >
                <div>
                    <FitToViewport
                        className="modal_viewport"
                        width={ViewportConstants.width}
                        height={ViewportConstants.height}
                        minZoom={ViewportConstants.minZoom}
                        maxZoom={ViewportConstants.maxZoom}
                    >
                        <Fragment>
                            <div className="close_modal" onClick={() => handleClose()}></div>

                            <div className="free_cr_modal_box">
                                <Box className="free_cr_modal">
                                    <div className="free_cr_heading">
                                        <h4 className="head">
                                            {t(`lang_ai_quiz.welcome.monthly_quota_title`).toUpperCase()}
                                        </h4>
                                    </div>

                                    <div className="free_cr_body">
                                        <div className="number_of_credit">
                                            <div className="number">
                                                <p className="big">{aiQuotaMonthlyCount}</p>
                                            </div>

                                            <CircularProgress
                                                variant="determinate"
                                                className="circle"
                                                value={
                                                    (aiQuotaMonthlyCount * 100) /
                                                    QuotaConstants.PRO_USER_AI_MONTHLY_LIMIT
                                                }
                                            />
                                        </div>

                                        <div className="free_cr_content">
                                            <p className="details">
                                                {aiQuotaMonthlyCount < QuotaConstants.PRO_USER_AI_MONTHLY_LIMIT
                                                    ? t(`lang_ai_quiz.welcome.monthly_quota_description`, {
                                                          monthlyQuota: QuotaConstants.PRO_USER_AI_MONTHLY_LIMIT,
                                                      })
                                                    : t(`lang_ai_quiz.welcome.monthly_quota_used_out`, {
                                                          monthlyQuota: QuotaConstants.PRO_USER_AI_MONTHLY_LIMIT,
                                                      })}
                                            </p>

                                            <div className="free_btn_group">
                                                <Button
                                                    onClick={() =>
                                                        webviewMessenger.sendOpenBrowser(WEBSITE_URL.PRICING)
                                                    }
                                                    className={`trialActivationButton new_video_btn`}
                                                >
                                                    {t('lang_ai_quiz.welcome.check_out_school_plan')}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                            </div>
                        </Fragment>
                    </FitToViewport>
                </div>
            </Modal>
        </Fragment>
    );
};

export default MonthlyQuotaModal;
