import { Typography } from '@mui/material';
import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { userActions } from '../../../../actions/user.actions';
import CommonButton from '../../../../components/Common/CommonButton';
import { UserActionTypes } from '../../../../constants/user-action-types';
import { utilConstants } from '../../../../constants/utils.constants';
import { getValidUserFromStore } from '../../../../helpers/storeHelpers';
import { getRemainingProDays, validateDelay } from '../../../../helpers/userhelper';
import apiUser from '../../../../services/apiUser';
import SubscriptionBackButton from './SubscriptionBackButton';
import { displayLocaleDate } from '../../../../helpers/languageHelpers';

const ConfirmChangeProductKey = ({ verifiedKey, setCurrentPage, setVerifiedKey }: any) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const user = getValidUserFromStore('ConfirmChangeProductKey');
    const [isProcessing, setIsProcessing] = useState(false);
    const [isKeyChanged, setIsKeyChanged] = useState(false);

    const confirmVerifyKey = async () => {
        setIsProcessing(true);
        const applyKeyReply = await apiUser.applyKey(user.email, verifiedKey.key);
        if (!applyKeyReply) {
            setIsProcessing(false);
            setVerifiedKey(null);
            dispatch(userActions.showApiError());
            return;
        }
        setTimeout(() => {
            setIsProcessing(false);
            setIsKeyChanged(true);
            dispatch(userActions.updateUser(UserActionTypes.PRODUCT_KEY_APPLIED, applyKeyReply.updatedUser));
        }, validateDelay(applyKeyReply.duration));
    };

    return (
        <Fragment>
            <SubscriptionBackButton
                setCurrentPage={setCurrentPage}
                previousPage={
                    isKeyChanged
                        ? utilConstants.SUBSCRIPTION_PAGE_TYPE.Landing_Page
                        : utilConstants.SUBSCRIPTION_PAGE_TYPE.Change_Key
                }
            />
            <div className="change_product_key_sec">
                <div className="product_key_text">
                    <Typography variant="h4">{t('lang_subscription.txt_change_product_key')}</Typography>
                    {!isKeyChanged ? (
                        <Typography variant="body1">
                            {verifiedKey.organization ? (
                                <Fragment>
                                    {verifiedKey.isTrial
                                        ? t('lang_subscription.txt_valid_trial_organization_key_msg', {
                                              organization: verifiedKey.organization,
                                              planExpiryDate: displayLocaleDate(
                                                  new Date(verifiedKey.planExpiryDate || '0000-01-01'),
                                              ),
                                          })
                                        : t('lang_subscription.txt_valid_organization_key_msg', {
                                              organization: verifiedKey.organization,
                                              planExpiryDate: displayLocaleDate(
                                                  new Date(verifiedKey.planExpiryDate || '0000-01-01'),
                                              ),
                                          })}
                                </Fragment>
                            ) : (
                                <Fragment>
                                    {verifiedKey.isTrial
                                        ? t('lang_subscription.txt_valid_standard_trial_key_msg', {
                                              validity: getRemainingProDays(verifiedKey.planExpiryDate),
                                          })
                                        : t('lang_subscription.txt_valid_standard_key_msg', {
                                              validity: getRemainingProDays(verifiedKey.planExpiryDate),
                                          })}
                                </Fragment>
                            )}
                        </Typography>
                    ) : (
                        <Fragment>
                            <Typography variant="body1">
                                {t('lang_subscription.txt_product_key_changed', {
                                    planExpiryDate: displayLocaleDate(
                                        new Date(verifiedKey.planExpiryDate || '0000-01-01'),
                                    ),
                                })}
                            </Typography>
                        </Fragment>
                    )}
                </div>
                {!isKeyChanged ? (
                    <div className="profile_update_btn">
                        <CommonButton
                            isLoading={isProcessing}
                            variant="contained"
                            type="button"
                            color="primary"
                            text={isProcessing ? t('lang_subscription.btn_activating') : t('lang_common.btn_confirm')}
                            clickFunction={confirmVerifyKey}
                        />
                    </div>
                ) : (
                    <div className="profile_update_btn">
                        <CommonButton
                            isLoading={false}
                            variant="contained"
                            type="button"
                            color="primary"
                            text={t('lang_subscription.btn_go_to_subscription')}
                            clickFunction={() => {
                                setCurrentPage(utilConstants.SUBSCRIPTION_PAGE_TYPE.Landing_Page);
                                setVerifiedKey(null);
                            }}
                        />
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default ConfirmChangeProductKey;
