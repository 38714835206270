import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import webviewMessenger from '../../../services/webviewMessenger';
import { QuizSummaryInterface } from '../../../helpers/quizSummaryHelper';
import { localService } from '../../../services/localStorageService';
import { useSelector } from 'react-redux';
import StoreInterface from '../../../interfaces/store.interface';

const ExcelSheet = ({ quizSummary }: { quizSummary: QuizSummaryInterface }) => {
    const { t } = useTranslation();
    const classSession = useSelector((state: StoreInterface) => state.classSession);

    const exportReport = () => {
        webviewMessenger.sendUsageLog(`${classSession ? '[S] ' : ''}Exported Quiz summary report`);
        const activityIds = quizSummary.quizPropsArray.map((quizProp) => {
            const activityItem = _.pick(quizProp, ['activityId']);
            const activityId = _.values(activityItem);
            return activityId[0];
        });
        const payload = JSON.stringify({
            activityIds: activityIds.join(','),
            lang: localService.getLanguage(),
        });
        // console.log('payload', payload);
        const encodeValue = window.btoa(encodeURIComponent(payload));
        const downloadReportUrl = `${process.env.REACT_APP_CPDOWNLOAD}/quiz-summary/${encodeValue}`;
        webviewMessenger.sendOpenBrowser(downloadReportUrl);
    };

    return (
        <h2 onClick={() => exportReport()}>
            <b>{t('lang_activity.txt_download_excel_report')} </b>
        </h2>
    );
};

export default ExcelSheet;
