import { Fragment, useState, useEffect, SyntheticEvent } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { CircularProgress, Typography } from '@mui/material';

import { userActions } from '../../../actions/user.actions';
import { getValidUserFromStore } from '../../../helpers/storeHelpers';
import { calculateParticipantLevelAndBadge, getLevelsArray } from '../../../helpers/userhelper';

import SavedClassForUserInterface from '../../../interfaces/saved-class-for-user.interface';
import SavedClassParticipantInterface, {
    OrderProperties,
    Orders,
} from '../../../interfaces/saved-class-participant.interface';
import { SavedClassGroupInterface } from '../../../interfaces/savedClassGroup.interface';

import apiSavedClasses from '../../../services/apiSavedClasses';
import { processParticipantNamesForSorting } from '../savedClassHelpers';
import SavedClassParticipantsHeader from './SavedClassParticipantsHeader';
import SavedClassParticipantsTable from './savedClassParticipantsTable/SavedClassParticipantsTable';

interface SavedClassParticipantsInterface {
    isParticipantUpdated: boolean;
    setIsParticipantUpdated: (arg: boolean) => void;
    savedClass: SavedClassForUserInterface;
    setSavedClass: (arg: SavedClassForUserInterface) => void;
    onDeleteSavedClass: () => void;
}

const SavedClassParticipants = ({
    isParticipantUpdated,
    setIsParticipantUpdated,
    savedClass,
    setSavedClass,
    onDeleteSavedClass,
}: SavedClassParticipantsInterface) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = getValidUserFromStore('SavedClassParticipants');

    const [isLoading, setIsLoading] = useState(true);
    const [sortedParticipants, setSortedParticipants] = useState<SavedClassParticipantInterface[]>([]);
    const [savedClassGroups, setSavedClassGroups] = useState<SavedClassGroupInterface[]>([]);
    const [orderProperty, setOrderProperty] = useState(OrderProperties.ParticipantName);
    const [order, setOrder] = useState(Orders.Asc);
    const [activeTabIndex, setActiveTabIndex] = useState('1');

    const getAndSortParticipantList = async () => {
        const savedClassDetails = await apiSavedClasses.getSavedClass(savedClass.savedClassId, user.email);
        setIsLoading(false);
        if (!savedClassDetails) return dispatch(userActions.showApiError());

        const participants = savedClassDetails.participants;

        const participantsWithLevel = participants.map((p) => ({
            ...p,
            participantLevelAndBadge: calculateParticipantLevelAndBadge(p.participantTotalPoints, getLevelsArray(user)),
        }));

        const participantsWithGroup = participantsWithLevel.map((participant) => {
            const group = savedClassDetails.groups?.find((group) => group.groupId === participant.groupId);
            return { ...participant, group: group?.groupName };
        });

        handleSortParticipants(participantsWithGroup);
        setSavedClassGroups(savedClassDetails.groups || []);
    };

    const handleSortParticipants = (participants: SavedClassParticipantInterface[]) => {
        let sortedParticipants: SavedClassParticipantInterface[];
        if (orderProperty === OrderProperties.ParticipantName) {
            sortedParticipants = _.orderBy(
                participants,
                [(participant) => processParticipantNamesForSorting(participant.participantName)],
                [order],
            );
        } else {
            sortedParticipants = _.orderBy(
                participants,
                [
                    orderProperty === OrderProperties.ParticipantLevel
                        ? OrderProperties.ParticipantTotalPoints
                        : orderProperty,
                    OrderProperties.ParticipantTotalPoints,
                ],
                [order, Orders.Asc],
            );
        }
        setSortedParticipants(sortedParticipants);
    };

    const handleActiveTab = (event: SyntheticEvent, newValue: string) => {
        setActiveTabIndex(newValue);
    };

    const refreshSavedClass = (actionType?: string, groupId?: number) => {
        if (actionType === 'delete' && groupId) {
            const updatedGroups = savedClassGroups.filter((group) => group.groupId !== groupId);
            setSavedClassGroups(updatedGroups);
        }
        getAndSortParticipantList();
    };

    useEffect(() => {
        getAndSortParticipantList();
        setIsParticipantUpdated(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isParticipantUpdated]);

    useEffect(() => {
        handleSortParticipants(sortedParticipants);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderProperty, order]);

    return (
        <Fragment>
            <div className="view_edit_main savedClass">
                {isLoading ? (
                    <div className="loader">
                        <CircularProgress />
                    </div>
                ) : (
                    <Fragment>
                        <SavedClassParticipantsHeader
                            savedClass={savedClass}
                            setSavedClass={setSavedClass}
                            sortedParticipants={sortedParticipants}
                            onParticipantsUpdated={handleSortParticipants}
                            savedClassGroupsLength={savedClassGroups.length}
                            activeTabIndex={activeTabIndex}
                            handleActiveTab={handleActiveTab}
                            showOptions={activeTabIndex === '1'}
                        />
                        {sortedParticipants.length > 0 ? (
                            <SavedClassParticipantsTable
                                savedClass={savedClass}
                                setSavedClass={setSavedClass}
                                sortedParticipants={sortedParticipants}
                                savedClassGroups={savedClassGroups}
                                refreshSavedClass={refreshSavedClass}
                                orderBy={orderProperty}
                                setOrderBy={setOrderProperty}
                                order={order}
                                setOrder={setOrder}
                                onParticipantsUpdated={handleSortParticipants}
                                onDeleteSavedClass={onDeleteSavedClass}
                                activeTabIndex={activeTabIndex}
                            />
                        ) : (
                            <div className="noParticipantSection">
                                <div className="noParticipantInnerBox">
                                    <Typography variant="body1">
                                        {t('lang_saved_classes.txt_no_participant_yet')}
                                    </Typography>
                                </div>
                            </div>
                        )}
                    </Fragment>
                )}
            </div>
        </Fragment>
    );
};

export default SavedClassParticipants;
