import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Button, MenuItem, Modal, Select } from '@mui/material';

import { activityActions } from '../../../actions/activity.action';

import CommonCheckBox from '../../../components/Common/CommonCheckBox';

function VotingModal({
    selectedResponses,
    showAlert,
    closeAlert,
    onStartVoting,
    votingNumber,
    setVotingNumber,
    isVotingNamesHidden,
    setIsVotingNamesHidden,
}: any) {
    const dispatch = useDispatch();

    const [selectVoting, setSelectVoting] = useState<boolean>(true);
    const [selectCheckBox, setSelectCheckBox] = useState<boolean>(false);
    const [openSelectBox, setOpenSelectBox] = useState<boolean>(false);

    useEffect(() => {
        setDefaultCheck();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedResponses]);

    const setDefaultCheck = () => {
        const votingNumber = selectedResponses.length > 5 ? 5 : selectedResponses.length;
        setVotingNumber(votingNumber);
        setSelectCheckBox(false);
    };

    const startvoting = () => {
        dispatch(activityActions.startVoting(votingNumber, selectedResponses, isVotingNamesHidden));
        onStartVoting();
    };

    return (
        <Fragment>
            <Modal
                open={showAlert}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="mymodal"
                onClose={() => closeAlert()}
            >
                <Box className="modal_box voting_modal">
                    <div className="sky-slider-controls">
                        <div className="media">
                            <div className="media_body">
                                <ul>
                                    <li className="sky-slider__prev">
                                        <CommonCheckBox
                                            isHookRegistered={false}
                                            label="Limit votes from each participant to"
                                            defaultChecked={selectCheckBox}
                                            // value={selectVoting}
                                            changeFunction={(e: any) => {
                                                if (e.target.checked) {
                                                    setSelectVoting(!selectVoting);
                                                    setSelectCheckBox(true);
                                                    setOpenSelectBox(!openSelectBox);
                                                } else {
                                                    setDefaultCheck();
                                                }
                                            }}
                                        />
                                        <Select
                                            variant="standard"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Age"
                                            value={votingNumber}
                                            onChange={(e) => setVotingNumber(e.target.value)}
                                            disabled={!selectCheckBox}
                                            open={openSelectBox}
                                            onClick={() => {
                                                if (selectCheckBox) setOpenSelectBox(!openSelectBox);
                                            }}
                                        >
                                            {selectedResponses.map((item: any, index: any) => {
                                                if (index <= 4) {
                                                    return (
                                                        <MenuItem
                                                            value={index + 1}
                                                            key={index}
                                                            onClick={() => {
                                                                setSelectVoting(!selectVoting);
                                                                setOpenSelectBox(!openSelectBox);
                                                            }}
                                                        >
                                                            {index + 1}
                                                        </MenuItem>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Select>
                                    </li>

                                    <li className="sky-slider__next">
                                        <CommonCheckBox
                                            isHookRegistered={false}
                                            label="Hide pariticipant names when voting"
                                            changeFunction={(e: any) => {
                                                setIsVotingNamesHidden(e.target.checked);
                                            }}
                                            defaultChecked={isVotingNamesHidden}
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="button_sec">
                            <Button onClick={() => startvoting()} variant="contained">
                                Start voting
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </Fragment>
    );
}

export default VotingModal;
