import { Fragment } from 'react';
import Lottie from 'react-lottie-player';
import { Typography } from '@mui/material';
import noSearchResult from '../../../assets/animation/no-search-result.json';
import { useTranslation } from 'react-i18next';
function NoResponse() {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div className="waiting_animation2">
                <Lottie loop={true} animationData={noSearchResult} play speed={1} />
                <Typography variant="h5">{t('lang_activity.no_responses_collected')}</Typography>
            </div>
        </Fragment>
    );
}

export default NoResponse;
