import axios, { AxiosRequestConfig } from 'axios';
import webviewMessenger from './webviewMessenger';

import { HostLogType } from '../constants/host-log-types.enum';

const axiosWithLog = axios.create();

axiosWithLog.interceptors.request.use((config: AxiosRequestConfig) => {
    (config as any).requestStartTime = new Date();
    return config;
});

axiosWithLog.interceptors.response.use((response) => {
    const duration = new Date().getTime() - (response.config as any).requestStartTime.getTime();
    webviewMessenger.sendHostLog(
        HostLogType.API,
        `[${duration} ms][${response.status}][${response.config.method?.toUpperCase()} ${
            response.config.url
        }] successful`,
    );
    // webviewMessenger.sendUsageLog(
    //     `API call: ${response.config.method?.toUpperCase()} ${response.config.url} took ${duration} ms`,
    // );
    // webviewMessenger.sendHostLog(
    //     HostLogType.API,
    //     `[${duration} ms][${response.status}][${response.config.method?.toUpperCase()} ${
    //         response.config.url
    //     }] successful. Details: ${createLogMessage({
    //         requestParams: response.config.params,
    //         requestBody: response.config.data,
    //         responseData: response.data,
    //     })}`,
    // );
    (response as any).duration = duration;
    return response;
});

export default axiosWithLog;
