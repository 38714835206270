import { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { FitToViewport } from 'react-fit-to-viewport';

import { Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import './Auth.scss';

import { localService } from '../../services/localStorageService';
import { authPagesConstants } from '../../constants/viewport-constants';
import { utilConstants } from '../../constants/utils.constants';
import { CurrentPageType } from '../../interfaces/signInSignUp.interface';
import { InterfaceLanguages } from '../../constants/languages';

import { SignIn } from './SignIn';
import { SignUp } from './SignUp';
import { ForgotPassword } from './ForgotPassword';
import webviewMessenger from '../../services/webviewMessenger';
import EmptyModal from '../EmptyModal/EmptyModal';
import { getCpVer } from '../../helpers/utils';
import { SsoSignUpInterface } from '../../interfaces/sso-signup.interface';

export const Auth = () => {
    const { t } = useTranslation();

    const currentLanguage = localService.getLanguage();

    const [currentPage, setCurrentPage] = useState(CurrentPageType.SignIn);
    const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);
    const [openChangeLanguage, setOpenChangeLanguage] = useState(false);
    const [showFullPageInterest, setShowFullPageInterest] = useState(false);
    const [showEmptyModal, setShowEmptyModal] = useState(false);
    const [ssoData, setSsoData] = useState<SsoSignUpInterface>();

    useEffect(() => {
        setShowEmptyModal(true);
        setTimeout(() => {
            setShowEmptyModal(false);
        }, 50);
    }, []);

    const InterfaceLanguagesSorted = InterfaceLanguages.sort(function (a, b) {
        if (a.nameInEnglish < b.nameInEnglish) return -1;
        if (a.nameInEnglish > b.nameInEnglish) return 1;
        return 0;
    });

    const updateCurrentLanguage = (selectedLanguageCode: string) => {
        i18next.changeLanguage(selectedLanguageCode);
        localService.setLanguage(selectedLanguageCode);
        webviewMessenger.sendUpdateLanguage(selectedLanguageCode);
        setSelectedLanguage(selectedLanguageCode);
        setOpenChangeLanguage(!openChangeLanguage);
        let root = document.getElementsByTagName('html')[0];
        if (selectedLanguageCode === 'ar') {
            root.setAttribute('class', 'rtl');
            root.setAttribute('dir', 'rtl');
        } else {
            root.setAttribute('class', 'ltr');
            root.setAttribute('dir', 'ltr');
        }
    };

    const activeLanguageFlag = () => {
        const activeFlag = InterfaceLanguages.find((language) => language.code === selectedLanguage)?.country_flag;
        return activeFlag || '';
    };

    return (
        <FitToViewport
            className="viewport"
            width={authPagesConstants.width}
            height={authPagesConstants.height}
            minZoom={authPagesConstants.minZoom}
            maxZoom={authPagesConstants.maxZoom}
        >
            {showEmptyModal && <EmptyModal viewportConfig={authPagesConstants} />}

            <div className={`joining_sec ${showFullPageInterest && 'fullView'}`}>
                <div className="left_side">
                    <div className="cp_logo">
                        <img src={utilConstants.LOGIN.Login_logo} alt="logo" />
                    </div>

                    <div className="welcome_content">
                        <h2 className="title">{t(`lang_auth.txt_welcome`)}</h2>
                        <p className="subtext">{t(`lang_auth.txt_welcome_subtext`)}</p>
                    </div>
                    <div className="square1">
                        <img src={utilConstants.LOGIN.SQUARE_1} alt="logo" />
                    </div>
                    <div className="square2">
                        <img src={utilConstants.LOGIN.SQUARE_2} alt="logo" />
                    </div>
                    <div className="shape2">
                        <img src={utilConstants.LOGIN.SHAPE_2} alt="logo" />
                    </div>
                    <div className="shapeRound">
                        <img src={utilConstants.LOGIN.SHAPE_ROUND} alt="logo" />
                    </div>
                    <div className="version">
                        <Typography variant="body1" display="block" className="">
                            {t('lang_about.txt_version')} {getCpVer()}
                        </Typography>
                    </div>
                </div>

                <div className="right_side">
                    <div className="language_change_sec">
                        <div onClick={() => setOpenChangeLanguage(!openChangeLanguage)} className="language_change_btn">
                            <img src={activeLanguageFlag()} className={`flagIcon`} alt="ClassPoint" />
                            <Typography variant="h3">
                                {InterfaceLanguages.find((language) => language.code === selectedLanguage)?.name}{' '}
                                <KeyboardArrowDownIcon />
                            </Typography>
                        </div>

                        <div className={`dropdown_menu ${openChangeLanguage ? 'show' : ''}`}>
                            <div
                                className={`overlay ${openChangeLanguage ? 'show' : ''}`}
                                onClick={() => setOpenChangeLanguage(!openChangeLanguage)}
                            ></div>
                            <div className={`content_menu ${openChangeLanguage ? 'show' : ''}`}>
                                <ul>
                                    {InterfaceLanguagesSorted.map((language, index) => (
                                        <li key={index}>
                                            <p
                                                className={`${selectedLanguage === language.code ? 'active' : ''}`}
                                                onClick={() => updateCurrentLanguage(language.code)}
                                            >
                                                <img src={language.country_flag} alt={language.code} />
                                                {language.name}
                                            </p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>

                    {currentPage === CurrentPageType.SignIn && (
                        <SignIn setCurrentPage={setCurrentPage} setSsoData={setSsoData} />
                    )}
                    {currentPage === CurrentPageType.SignUp && (
                        <SignUp
                            setCurrentPage={setCurrentPage}
                            setShowFullPageInterest={setShowFullPageInterest}
                            ssoData={ssoData}
                        />
                    )}
                    {currentPage === CurrentPageType.ForgotPassword && (
                        <ForgotPassword setCurrentPage={setCurrentPage} />
                    )}
                </div>
            </div>
        </FitToViewport>
    );
};

export default Auth;
