import responseloader01 from '../assets/animation/responseloader01.json';
import responseloader02 from '../assets/animation/responseloader02.json';
import responseloader03 from '../assets/animation/responseloader03.json';
import responseloader04 from '../assets/animation/responseloader04.json';
import responseloader05 from '../assets/animation/responseloader05.json';

export const activityResponseLoaders = [
    responseloader01,
    responseloader02,
    responseloader03,
    responseloader04,
    responseloader05,
];
