import { type ClassValue } from 'clsx';
import clsx from 'clsx';
import { AES } from 'crypto-js';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export const encryptObjectToString = (object: object): string => {
    const objectString = JSON.stringify(object);
    return encryptString(objectString);
};

const encryptString = (value: string) => {
    const encodeDecodeKey = 'cp2';
    const encryptedValue = AES.encrypt(value, encodeDecodeKey);
    return encryptedValue.toString();
};

export const isJson = (jsonString: string) => {
    try {
        JSON.parse(jsonString);
    } catch (error) {
        return false;
    }
    return true;
};
