import { Box } from '@mui/material';
import LazyLoadingInProgress from '../components/LazyLoadingInProgress';
import ActivityResponseInterface from '../../../interfaces/activity-response.interface';
import ImageUploadSingleResponse from './ImageUploadSingleResponse';
import Masonry from 'react-responsive-masonry';

interface ImageUploadContentInterface {
    selectedResponses: ActivityResponseInterface[];
    searchKeyword: string;
    isVoting: boolean;
    onThumbClicked: any;
    sortSelectedResponses: (arg: ActivityResponseInterface[]) => ActivityResponseInterface[];
    isFetchingMore: boolean;
}

function ImageUploadContent({
    selectedResponses,
    searchKeyword,
    isVoting,
    onThumbClicked,
    sortSelectedResponses,
    isFetchingMore,
}: ImageUploadContentInterface) {
    return (
        <Box sx={{ width: 942, minHeight: 300 }}>
            {sortSelectedResponses(selectedResponses).length > 0 && (
                <Masonry columnsCount={4} gutter={'16px'}>
                    {sortSelectedResponses(selectedResponses).map(
                        (response: ActivityResponseInterface, index: number) => (
                            <ImageUploadSingleResponse
                                key={response.responseId}
                                index={index}
                                response={response}
                                searchKeyword={searchKeyword}
                                isVoting={isVoting}
                                onThumbClicked={onThumbClicked}
                            />
                        ),
                    )}
                </Masonry>
            )}
            {isFetchingMore ? <LazyLoadingInProgress /> : null}
        </Box>
    );
}

export default ImageUploadContent;
