import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';
import { Typography } from '@mui/material';

import error from '../../assets/animation/error.json';
import CommonButton from '../../components/Common/CommonButton';

const HelpdeskError = ({ onComponentChange }: any) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div className="helpDeskMessage">
                <Lottie loop={false} animationData={error} play speed={2} />
                <Typography variant="body1">{t('lang_help_desk.txt_send_helpdesk_error')}</Typography>
            </div>
            <div className="helper_btn_group">
                <div className="imagesbtn">
                    <CommonButton
                        isLoading={false}
                        variant="contained"
                        className="rotateleft"
                        type="button"
                        color="primary"
                        text={t('lang_common.btn_back')}
                        clickFunction={() => onComponentChange('form')}
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default HelpdeskError;
