import { useState } from 'react';

import { Modal } from '@mui/material';
// import { Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import './ProfileSummary.scss';

import { WEBSITE_URL, utilConstants } from '../../../constants/utils.constants';

import { BootstrapTooltip } from '../../../helpers/mui/CustomToolTip';
import { getValidUserFromStore } from '../../../helpers/storeHelpers';
import webviewMessenger from '../../../services/webviewMessenger';

import ProfileImage from '../Profile/Components/ProfileImage';
import ProfileDetails from './ProfileDetails';
import { determineChipClassName, determinePlanDisplay } from '../../../helpers/userhelper';
import PlanTypeBadge from '../subscriptions/components/PlanTypeBadge';

export default function ProfileSummary() {
    const user = getValidUserFromStore('Profile');
    const userProfile = user.userProfile;

    const today = new Date().getTime();
    const isCCT = userProfile.cctExpiryDate && new Date(userProfile.cctExpiryDate).getTime() > today ? true : false;
    const isCCE = userProfile.cceExpiryDate && new Date(userProfile.cceExpiryDate).getTime() > today ? true : false;
    const isCSC = userProfile.cscExpiryDate && new Date(userProfile.cscExpiryDate).getTime() > today ? true : false;
    // const hasMultipleBadge = [isCCT, isCCE, isCSC].filter((data) => data).length > 1;

    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

    return (
        <div className={`profileSummary`}>
            {/* <div onClick={() => setIsDetailsModalOpen(true)} className="changes_profile_images"> */}
            <div
                onClick={() => webviewMessenger.sendOpenBrowser(`${process.env.REACT_APP_WEBAPP}/cp/account/profile`)}
                className="changes_profile_images"
            >
                <EditIcon />
            </div>

            <Modal
                open={isDetailsModalOpen}
                onClose={() => setIsDetailsModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ProfileDetails />
            </Modal>

            <div className="profile_right">
                <div className="profile_top">
                    <ProfileImage />
                    <p className={`name`}>{`${user.userProfile.firstName} ${user.userProfile.lastName}`}</p>
                    <p className={`email`}>{user.email}</p>
                    {user.userProfile.organization && <p className={`organization`}>{user.userProfile.organization}</p>}
                </div>

                <div className="profileSummaryBadgeContainer">
                    <PlanTypeBadge user={user} />

                    {(isCCE || isCSC || isCCT) && (
                        <div className="certified_tainner_badge">
                            {isCCE && (
                                <BootstrapTooltip title="ClassPoint Certified Educator" placement="top">
                                    <img
                                        onClick={() => webviewMessenger.sendOpenBrowser(WEBSITE_URL.CCE)}
                                        src={utilConstants.IMAGE_URLS.cce_badge}
                                        alt="cce_badge"
                                    />
                                </BootstrapTooltip>
                            )}
                            {isCSC && (
                                <BootstrapTooltip title="ClassPoint School Coach" placement="top">
                                    <img
                                        onClick={() => webviewMessenger.sendOpenBrowser(WEBSITE_URL.CSC)}
                                        src={utilConstants.IMAGE_URLS.csc_badge}
                                        alt="csc_badge"
                                    />
                                </BootstrapTooltip>
                            )}
                            {isCCT && (
                                <BootstrapTooltip title="ClassPoint Certified Trainer" placement="top">
                                    <img
                                        onClick={() => webviewMessenger.sendOpenBrowser(WEBSITE_URL.CCT)}
                                        src={utilConstants.IMAGE_URLS.cct_badge}
                                        alt="cct_badge"
                                    />
                                </BootstrapTooltip>
                            )}
                        </div>
                    )}
                </div>

                {/* ) : (
                    <>
                        {isCCE && (
                            <div
                                onClick={() => webviewMessenger.sendOpenBrowser(WEBSITE_URL.CCE)}
                                className="certified_tainner"
                            >
                                <img src={utilConstants.IMAGE_URLS.cce_badge} alt="cce_badge" />
                                <Typography variant="h4">Certified Educator</Typography>
                            </div>
                        )}
                        {isCSC && (
                            <div
                                onClick={() => webviewMessenger.sendOpenBrowser(WEBSITE_URL.CSC)}
                                className="certified_tainner"
                            >
                                <img src={utilConstants.IMAGE_URLS.csc_badge} alt="csc_badge" />
                                <Typography variant="h4">School Coach</Typography>
                            </div>
                        )}
                        {isCCT && (
                            <div
                                onClick={() => webviewMessenger.sendOpenBrowser(WEBSITE_URL.CCT)}
                                className="certified_tainner"
                            >
                                <img src={utilConstants.IMAGE_URLS.cct_badge} alt="cct_badge" />
                                <Typography variant="h4">Certified Trainer</Typography>
                            </div>
                        )}
                    </>
                )} */}
            </div>
        </div>
    );
}
