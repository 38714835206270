import React, { Fragment } from 'react';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    className : string;
}

const TabPanal = (props: TabPanelProps) => {
    return (
        <Fragment>{props.value === props.index && <div className={props.className}>{props.children}</div>}</Fragment>
    );
};

export default TabPanal;
