import { Fragment, useState } from 'react';
import { FitToViewport } from 'react-fit-to-viewport';
import { Box, Modal } from '@mui/material';

import ImageFileTypeWrongAlert from '../../../components/Common/ImageFileTypeWrongAlert';
import { whiteBoardConstants } from '../../../constants/viewport-constants';
import WhiteboardPreviewModal from './WhiteboardPreviewModal';
import WhiteboardSelectFrom from './WhiteboardSelectFrom';

const ImageUpdateModal = ({ imageUpdateOpen, imageUpdateCloseHandler, selectedImage }: any) => {
    const [isShowImageTypeWrongModal, setIsShowImageTypeWrongModal] = useState(false);
    const [isWhiteboardPreviewOpen, setIsWhiteboardPreviewOpen] = useState(false);
    const [imageValue, setImageValue] = useState({
        imageData: '',
        blobData: '',
        imageName: '',
    });

    return (
        <Fragment>
            <WhiteboardPreviewModal
                isThisModalOpen={isWhiteboardPreviewOpen}
                onCloseThisModal={() => setIsWhiteboardPreviewOpen(false)}
                imageValue={imageValue}
                isUpdating={true}
                selectedImage={selectedImage}
            />

            <Modal
                open={imageUpdateOpen}
                onClose={imageUpdateCloseHandler}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Fragment>
                    <FitToViewport
                        className="modal_viewport"
                        width={whiteBoardConstants.width}
                        height={whiteBoardConstants.height}
                        minZoom={whiteBoardConstants.minZoom}
                        maxZoom={whiteBoardConstants.maxZoom}
                    >
                        <div className="close_modal" onClick={imageUpdateCloseHandler}></div>
                        <Box className="whiteboardupload_modal wdth_400">
                            <WhiteboardSelectFrom
                                imageDataCallBack={setImageValue}
                                onOpenPreviewModal={() => setIsWhiteboardPreviewOpen(true)}
                                onCloseThisModal={imageUpdateCloseHandler}
                                onImageTypeError={() => setIsShowImageTypeWrongModal(true)}
                            />
                        </Box>
                    </FitToViewport>
                </Fragment>
            </Modal>

            <ImageFileTypeWrongAlert
                isAlertOpen={isShowImageTypeWrongModal}
                onCloseAlert={() => setIsShowImageTypeWrongModal(false)}
            />
        </Fragment>
    );
};

export default ImageUpdateModal;
