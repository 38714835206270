import { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, InputAdornment, TextField, Typography } from '@mui/material';
import StarBorderPurple500RoundedIcon from '@mui/icons-material/StarBorderPurple500Rounded';
import BookmarkBorderTwoToneIcon from '@mui/icons-material/BookmarkBorderTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { Avatar } from '@material-ui/core';

import { getBrowserTabsFromStore, getValidUserFromStore } from '../../../../helpers/storeHelpers';
import useAddressBar from '../Common/useAdressBar';
import { BrowserTabInterface } from '../../../../interfaces/browser-tabs-interface';
import { userActions } from '../../../../actions/user.actions';
import { UserActionTypes } from '../../../../constants/user-action-types';
import apiPreferences from '../../../../services/apiPreferences';
import webviewMessenger from '../../../../services/webviewMessenger';

function AddressBar({ openNewTab }: any) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const browserTabs = getBrowserTabsFromStore();
    const user = getValidUserFromStore('AddressBar');
    const browserBookmarks = user.userPreferences?.browserBookmarks;
    const [isBookmarked, setIsBookmarked] = useState(false);
    const [activeTab, setActiveTab] = useState<BrowserTabInterface>(browserTabs[0]);
    const [bookmarkSiteName, setBookmarkSiteName] = useState(activeTab?.title || '');
    const bookMarkRef: any = useRef<any>();
    const [searchKeyword, setSearchInput, searchFieldRef] = useAddressBar();
    const [menuOpen, setMenuopen] = useState(false);

    useEffect(() => {
        const tabs = browserTabs.filter((tabItem) => tabItem.active);
        if (activeTab && tabs[0] && tabs[0].tabId !== activeTab.tabId) {
            setSearchInput('');
            searchFieldRef.current.focus();
            searchFieldRef.current.value = '';
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [browserTabs, activeTab]);

    useEffect(() => {
        const activeTab = browserTabs.find((tab) => tab.active);
        // console.log(activeTab, searchKeyword);
        if (activeTab) {
            if (activeTab.uriToShow !== null) {
                searchFieldRef.current.value = activeTab.uriToShow || '';
            } else if (searchKeyword) searchFieldRef.current.value = searchKeyword;
            else if (activeTab.uri) searchFieldRef.current.value = activeTab.uri;
            showBookmarkIndicator(activeTab);
            setActiveTab(activeTab);
            setBookmarkSiteName(activeTab.title);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [browserTabs]);

    const showBookmarkIndicator = (activeTab: BrowserTabInterface) => {
        if (browserBookmarks) {
            const isMatching = browserBookmarks.some((bookmark) => bookmark.pageUrl === activeTab.uri);
            setIsBookmarked(isMatching);
        }
    };

    const addBookMark = async () => {
        setMenuopen(false);
        const updatedUser = await apiPreferences.addBrowserBookmark(
            user.email,
            bookmarkSiteName,
            activeTab.uri,
            activeTab.favicon,
        );
        if (!updatedUser) return dispatch(userActions.showApiError());
        dispatch(userActions.updateUser(UserActionTypes.ADDED_BOOKMARK, updatedUser));
    };

    const toggleBookmark = async () => {
        if (isBookmarked && browserBookmarks) {
            setIsBookmarked(false);
            setMenuopen(false);
            const bookmark = browserBookmarks.find((bookmark) => bookmark.pageUrl === activeTab.uri);
            if (bookmark) {
                const updatedUser = await apiPreferences.deleteBrowserBookmark(user.email, bookmark.id);
                if (!updatedUser) return dispatch(userActions.showApiError());
                dispatch(userActions.updateUser(UserActionTypes.REMOVED_BOOKMARK, updatedUser));
            }
        } else {
            setIsBookmarked(true);
        }
    };

    return (
        <div className="address-bar-container">
            <div className="address-bar address-bar-container-bookmark">
                <BookmarkBorderTwoToneIcon
                    onClick={() => {
                        webviewMessenger.sendUsageLog(`[S] Browser bookmark button clicked`);
                        openNewTab('/browser/bookmarks');
                    }}
                />
            </div>
            <div className="urlsearch">
                <TextField
                    fullWidth
                    placeholder={t('lang_browser.search_google_placeholder')}
                    id="fullWidth"
                    onChange={(e: any) => {
                        setSearchInput(e.target.value);
                    }}
                    inputRef={searchFieldRef}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                {activeTab && activeTab.favicon && <Avatar alt={'favicon'} src={activeTab.favicon} />}
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <Fragment>
                                {activeTab?.uri && (
                                    <InputAdornment position="end" className="favIcon">
                                        {
                                            <Fragment>
                                                {isBookmarked ? (
                                                    <StarRoundedIcon
                                                        style={{ color: '#6378ff' }}
                                                        onClick={() => {
                                                            toggleBookmark();
                                                        }}
                                                    />
                                                ) : (
                                                    <Fragment>
                                                        <StarBorderPurple500RoundedIcon
                                                            onClick={() => {
                                                                setMenuopen(true);
                                                                toggleBookmark();
                                                            }}
                                                        />
                                                    </Fragment>
                                                )}
                                                {menuOpen && (
                                                    <div id="basic-menu" className="boorkmarkmenu">
                                                        <div className="inner_bookmarkbox">
                                                            <div className="rename_folder">
                                                                <Typography variant="body1">
                                                                    {t('lang_browser.address_bar_save_bookmark_name')}
                                                                </Typography>
                                                                <TextField
                                                                    fullWidth
                                                                    id="fullWidth"
                                                                    defaultValue={activeTab.title}
                                                                    ref={bookMarkRef}
                                                                    onChange={(e: any) => {
                                                                        const value = e.target.value.trim();
                                                                        if (value)
                                                                            setBookmarkSiteName(e.target.value.trim());
                                                                        else setBookmarkSiteName(activeTab.title);
                                                                    }}
                                                                />
                                                                <Button variant="outlined" onClick={addBookMark}>
                                                                    {t('lang_common.btn_ok')}
                                                                </Button>
                                                            </div>
                                                            <div className="close_book_modal">
                                                                <CloseIcon
                                                                    onClick={() => {
                                                                        setMenuopen(false);
                                                                        showBookmarkIndicator(activeTab);
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Fragment>
                                        }
                                    </InputAdornment>
                                )}
                            </Fragment>
                        ),
                    }}
                />
            </div>
        </div>
    );
}

export default AddressBar;
