import { useEffect } from 'react';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';

interface LevelUpAnimationInterface {
    lastLevel: number;
    currentLevel: number;
    isLevellingUp: boolean;
}

export const LevelUpAnimation = ({ lastLevel, currentLevel, isLevellingUp }: LevelUpAnimationInterface) => {
    const moveBadgeAnimation_1 = useAnimation();
    const moveBadgeAnimation_2 = useAnimation();

    const animationTransition = {
        duration: 2,
        type: 'spring',
        stiffness: 800,
        damping: 12,
    };

    const animationHandler = () => {
        moveBadgeAnimation_1.start({
            opacity: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0],
            x: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -20, -20, 0, 0, 0, 0, 0, 0, 0, 0],
            scale: [1, 1, 1, 1, 1, 1, 2.4, 2.4, 2.4, 2.4, 2.4, 2.4, 2.4, 2.4, 2.4, 2.4, 2.4, 1, 1, 1],
            transition: animationTransition,
        });
        moveBadgeAnimation_2.start({
            opacity: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
            x: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 20, 20, 0, 0, 0, 0, 0, 0, 0, 0],
            scale: [1, 1, 1, 1, 1, 1, 2.4, 2.4, 2.4, 2.4, 2.4, 2.4, 2.4, 2.4, 2.4, 2.4, 2.4, 1, 1, 1],
            transition: animationTransition,
        });
    };

    useEffect(() => {
        isLevellingUp && animationHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLevellingUp]);

    return (
        <div className="levelUpAnimation ">
            <AnimatePresence>
                <>
                    <motion.img
                        initial={{ opacity: 1, x: 0, scale: 1 }}
                        animate={moveBadgeAnimation_1}
                        src={`/assets/levels/level_${lastLevel}.svg`}
                        className="levelBadge  levelImage"
                        alt="Level Up"
                    />
                    <motion.img
                        initial={{ opacity: 0, x: 0, scale: 1 }}
                        animate={moveBadgeAnimation_2}
                        src={`/assets/levels/level_${currentLevel}.svg`}
                        className="levelBadge levelImage"
                        alt="Level Up"
                    />
                </>
            </AnimatePresence>
        </div>
    );
};
