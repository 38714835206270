import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FitToViewport } from 'react-fit-to-viewport';

import './AiQuiz.scss';

import { aiQuizActions } from '../../actions/aiQuiz.action';
import { getValidUserFromStore } from '../../helpers/storeHelpers';
import { BT, QT, initializeQuestionLanguage } from '../../helpers/aiQuiz.helper';
import { localService } from '../../services/localStorageService';

import StoreInterface from '../../interfaces/store.interface';
import { ViewportConstants } from '../../constants/viewport-constants';
import { utilConstants } from '../../constants/utils.constants';

import Welcome from './components/Welcome';
import QuestionFlow from './components/QuestionFlow';
import EmptyModal from '../EmptyModal/EmptyModal';
import AiBasicUser from './components/AiBasicUser';

export default function AiQuiz() {
    const dispatch = useDispatch();
    const user = getValidUserFromStore('AiQuiz');
    const aiQuizState = useSelector((state: StoreInterface) => state.aiQuiz);
    const slideIdAndText = localService.getSlideIdText();
    const showAiQuiz = true;

    const [readyToRender, setReadyToRender] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showEmptyModal, setShowEmptyModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(
        aiQuizState[slideIdAndText.currentSlideId]
            ? utilConstants.AI_QUIZ_PAGE_TYPE.QUESTION
            : utilConstants.AI_QUIZ_PAGE_TYPE.WELCOME,
    );
    const [questionTypes, setQuestionTypes] = useState(
        (user.userPreferences?.aiQuizSettings?.questionTypes as QT[]) || [QT.MC, QT.FB, QT.SA],
    );
    const [btLevels, setBtLevels] = useState(
        (user.userPreferences?.aiQuizSettings?.btLevels as BT[]) || [
            BT.REMEMBER,
            BT.UNDERSTAND,
            BT.APPLY,
            BT.ANALYZE,
            BT.EVALUATE,
            BT.CREATE,
        ],
    );
    const [questionLanguage, setQuestionLanguage] = useState(initializeQuestionLanguage(user));

    useEffect(() => {
        dispatch(aiQuizActions.updateActiveSlideIdAndText(slideIdAndText));
        setShowEmptyModal(true);
        setTimeout(() => setShowEmptyModal(false), 50);
        setReadyToRender(true);
        if ((aiQuizState[aiQuizState.props.activeSlideId]?.questionList?.length || 0) <= 0) {
            setCurrentPage(utilConstants.AI_QUIZ_PAGE_TYPE.WELCOME);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!aiQuizState.props || aiQuizState.props.activeSlideId === 0) {
            setCurrentPage(utilConstants.AI_QUIZ_PAGE_TYPE.WELCOME);
        }
    }, [aiQuizState]);

    let activeComponent: any;
    switch (currentPage) {
        case utilConstants.AI_QUIZ_PAGE_TYPE.WELCOME:
            activeComponent = (
                <Welcome
                    setCurrentPage={setCurrentPage}
                    questionTypes={questionTypes}
                    setQuestionTypes={setQuestionTypes}
                    btLevels={btLevels}
                    setBtLevels={setBtLevels}
                    questionLanguage={questionLanguage}
                    setQuestionLanguage={setQuestionLanguage}
                    setErrorMessage={setErrorMessage}
                />
            );
            break;
        case utilConstants.AI_QUIZ_PAGE_TYPE.QUESTION:
            activeComponent = (
                <QuestionFlow
                    questionTypes={questionTypes}
                    btLevels={btLevels}
                    questionLanguage={questionLanguage}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                />
            );
            break;
        default:
            activeComponent = null;
            break;
    }

    return (
        <FitToViewport
            className="viewport"
            width={ViewportConstants.width}
            height={ViewportConstants.height}
            minZoom={ViewportConstants.minZoom}
            maxZoom={ViewportConstants.maxZoom}
        >
            {(showEmptyModal || !readyToRender) && <EmptyModal viewportConfig={ViewportConstants} />}

            {readyToRender && (
                <>{showAiQuiz ? <div className="chatBot_sec">{activeComponent}</div> : <AiBasicUser />}</>
            )}
        </FitToViewport>
    );
}
