import { TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import CommonButton from '../../../../components/Common/CommonButton';
import apiUser from '../../../../services/apiUser';
import { getValidUserFromStore } from '../../../../helpers/storeHelpers';
import { useDispatch } from 'react-redux';
import { utilConstants } from '../../../../constants/utils.constants';
import SubscriptionBackButton from './SubscriptionBackButton';
import { userActions } from '../../../../actions/user.actions';
import CommonAlert from '../../../../components/Common/CommonAlert';
import { ViewportSavedClasses } from '../../../../constants/viewport-constants';
import { TrialFlow } from '../../../activateTrial/TrialFlow';
import { QuotaConstants } from '../../../../constants/quota-constants';

type FormValues = {
    key: string;
};

const KeySchema = yup
    .object({
        key: yup.string().required().min(2).max(20),
    })
    .required();

const EnterProductKey = ({ setCurrentPage, setVerifiedKey }: any) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const user = getValidUserFromStore('EnterProductKey');
    const [productKey, setProductKey] = useState('');
    const [errMsgAPI, setErrMsgAPI] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [isTrialFlowModalOpen, setIsTrialFlowModalOpen] = useState(false);
    const [isActivatingTrial, setIsActivatingTrial] = useState(false);

    const { register, handleSubmit, formState, setError } = useForm<FormValues>({ resolver: yupResolver(KeySchema) });
    const { errors }: any = formState;

    const onSubmit: SubmitHandler<FormValues> = (data) => {
        if (data.key.toUpperCase() === QuotaConstants.TRIAL_KEY) {
            const isBasicUser = user.userSubscription.planType === utilConstants.PLAN_TYPE.BASIC;
            const hasTrialStarted = !!user.userSubscription.trialStarted;
            const hasGonePro = !!user.userSubscription.hasGonePro;
            let error = '';
            if (!isBasicUser) error = 'err_key_is_trial';
            else {
                if (hasTrialStarted) error = 'err_trial_before';
                else if (hasGonePro) error = 'err_gone_pro_before';
            }
            if (error) {
                setError('key', { type: 'errorFromAPI' }, { shouldFocus: true });
                setErrMsgAPI(error);
                return;
            }
            // use this special "key" to activate trial
            setIsTrialFlowModalOpen(true);
            setIsActivatingTrial(true);
        } else verifyKey(data.key);
    };

    const verifyKey = async (userProductKey: string) => {
        setIsProcessing(true);
        const verifyKeyReply = await apiUser.verifyKey(user.email, userProductKey);
        setIsProcessing(false);
        if (!verifyKeyReply) {
            return dispatch(userActions.showApiError());
        }
        if (verifyKeyReply.errorMessage) {
            setError('key', { type: 'errorFromAPI' }, { shouldFocus: true });
            setErrMsgAPI(verifyKeyReply.errorMessage);
            return;
        }
        // reject Standard trial key (do this here as backend is consumed by cp1 frontend which allows trial key)
        if (verifyKeyReply.type === 'Standard' && verifyKeyReply.isTrial) {
            setError('key', { type: 'errorFromAPI' }, { shouldFocus: true });
            setErrMsgAPI('err_key_not_found');
            return;
        }
        setVerifiedKey(verifyKeyReply);
        setCurrentPage(utilConstants.SUBSCRIPTION_PAGE_TYPE.Confirm_Key);
    };

    return (
        <>
            <CommonAlert
                showAlert={isTrialFlowModalOpen}
                closeAlert={() => {
                    if (!isActivatingTrial) setIsTrialFlowModalOpen(false);
                }}
                content={
                    <TrialFlow
                        isShowMultipleSlides={true}
                        setIsTrialFlowModalOpen={setIsTrialFlowModalOpen}
                        isActivatingTrial={isActivatingTrial}
                        setIsActivatingTrial={setIsActivatingTrial}
                        viewport={ViewportSavedClasses}
                        onTrialActivated={() => {
                            setCurrentPage(utilConstants.SUBSCRIPTION_PAGE_TYPE.Landing_Page);
                        }}
                        customClassNames={`default`}
                    />
                }
                viewportConfig={ViewportSavedClasses}
                customModalClassNames="small"
                customBoxClassNames={isActivatingTrial ? '' : `subscriptionTrialFlowModal`}
            />
            <SubscriptionBackButton
                setCurrentPage={setCurrentPage}
                previousPage={utilConstants.SUBSCRIPTION_PAGE_TYPE.Landing_Page}
            />

            <div className="change_product_key_sec">
                <div className="product_key_text">
                    <Typography variant="h4">{t('lang_subscription.txt_enter_product_key')}</Typography>
                    <Typography variant="body1">{t('lang_subscription.txt_product_key_sample')}</Typography>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="profile_form">
                        <div className="row">
                            <div className="col12">
                                <div className="pf_form">
                                    <TextField
                                        {...register('key')}
                                        id="standard-basic"
                                        label={t('lang_subscription.field_label_product_key')}
                                        variant="standard"
                                        autoComplete="none"
                                        error={errors.key && Object.keys(errors.key).length > 0 ? true : false}
                                        onChange={(e) => {
                                            errors.key = {};
                                            setProductKey(e.target.value.trim().toUpperCase());
                                        }}
                                        value={productKey}
                                        helperText={
                                            errors?.key?.type === 'required'
                                                ? t('lang_subscription.err_key_required')
                                                : errors?.key?.type === 'min'
                                                ? t('lang_subscription.err_key_too_short')
                                                : errors?.key?.type === 'max'
                                                ? t('lang_subscription.err_key_too_long')
                                                : errors?.key?.type === 'invalid'
                                                ? t('lang_subscription.err_key_required')
                                                : errors?.key?.type === 'errorFromAPI'
                                                ? t(`lang_subscription.${errMsgAPI}`)
                                                : ''
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="profile_update_btn">
                        <CommonButton
                            isLoading={isProcessing}
                            variant="contained"
                            type="submit"
                            color="primary"
                            text={t('lang_subscription.btn_verify')}
                        />
                    </div>
                </form>
            </div>
        </>
    );
};

export default EnterProductKey;
