import SignupOrganization from '../interfaces/signup-organization.interface';

export const SingaporeOrgList: SignupOrganization[] = [
    { value: 'Anglo-Chinese School (Junior)', abbr: '' },
    { value: 'Anglo-Chinese School (Primary)' },
    { value: 'Canossa Catholic Primary School' },
    { value: 'CHIJ (Katong) Primary' },
    { value: 'CHIJ (Kellock)' },
    { value: 'CHIJ Our Lady of Good Counsel' },
    { value: 'CHIJ Our Lady of the Nativity' },
    { value: 'CHIJ Our Lady Queen of Peace' },
    { value: 'CHIJ Primary (Toa Payoh)' },
    { value: 'Chongfu School' },
    { value: 'De La Salle School' },
    { value: 'Fairfield Methodist School (Primary)' },
    { value: 'Geylang Methodist School (Primary)' },
    { value: 'Kheng Cheng School' },
    { value: 'Kuo Chuan Presbyterian Primary School' },
    { value: 'Marymount Convent School' },
    { value: 'Mee Toh School' },
    { value: "Methodist Girls' School (Primary)" },
    { value: 'Montfort Junior School' },
    { value: 'Nan Chiau Primary School' },
    { value: 'Ngee Ann Primary School' },
    { value: "Paya Lebar Methodist Girls' School (Primary)" },
    { value: "Singapore Chinese Girls' Primary School" },
    { value: "St. Andrew's Junior School" },
    { value: "St. Anthony's Canossian Primary School" },
    { value: "St. Anthony's Primary School" },
    { value: "St. Gabriel's Primary School" },
    { value: "St. Hilda's Primary School" },
    { value: "St. Joseph's Institution Junior" },
    { value: "St. Margaret's Primary School" },
    { value: "St. Stephen's School" },
    { value: 'Ai Tong School' },
    { value: 'Catholic High School (Primary Section)' },
    { value: "CHIJ St. Nicholas Girls' School (Primary Section)" },
    { value: "Holy Innocents' Primary School" },
    { value: 'Hong Wen School' },
    { value: 'Kong Hwa School' },
    { value: 'Maha Bodhi School' },
    { value: 'Maris Stella High School' },
    { value: 'Nan Hua Primary School' },
    { value: 'Nanyang Primary School' },
    { value: 'Pei Chun Public School' },
    { value: 'Pei Hwa Presbyterian Primary School' },
    { value: 'Poi Ching School' },
    { value: 'Red Swastika School' },
    { value: 'Tao Nan School' },
    { value: 'Admiralty Primary School' },
    { value: 'Ahmad Ibrahim Primary School' },
    { value: 'Alexandra Primary School' },
    { value: 'Anchor Green Primary School' },
    { value: 'Anderson Primary School' },
    { value: 'Angsana Primary School' },
    { value: 'Ang Mo Kio Primary School' },
    { value: 'Beacon Primary School' },
    { value: 'Bedok Green Primary School' },
    { value: 'Bendemeer Primary School' },
    { value: 'Blangah Rise Primary School' },
    { value: 'Boon Lay Garden Primary School' },
    { value: 'Bukit Panjang Primary School' },
    { value: 'Bukit Timah Primary School' },
    { value: 'Bukit View Primary School' },
    { value: 'Canberra Primary School' },
    { value: 'Cantonment Primary School' },
    { value: 'Casuarina Primary School' },
    { value: 'Cedar Primary School' },
    { value: 'Changkat Primary School' },
    { value: 'Chongzheng Primary School' },
    { value: 'Chua Chu Kang Primary School' },
    { value: 'Clementi Primary School' },
    { value: 'Compassvale Primary School' },
    { value: 'Concord Primary School' },
    { value: 'Corporation Primary School' },
    { value: 'Damai Primary School' },
    { value: 'Dazhong Primary School' },
    { value: 'East Spring Primary School' },
    { value: 'Edgefield Primary School' },
    { value: 'Elias Park Primary School' },
    { value: 'Endeavour Primary School' },
    { value: 'Telok Kurau Primary School' },
    { value: 'Evergreen Primary School' },
    { value: 'Farrer Park Primary School' },
    { value: 'Fengshan Primary School' },
    { value: 'Fernvale Primary School' },
    { value: 'First Toa Payoh Primary School' },
    { value: 'Frontier Primary School' },
    { value: 'Fuchun Primary School' },
    { value: 'Fuhua Primary School' },
    { value: 'Gan Eng Seng Primary School' },
    { value: 'Gongshang Primary School' },
    { value: 'Greendale Primary School' },
    { value: 'Greenridge Primary School' },
    { value: 'Greenwood Primary School' },
    { value: 'Townsville Primary School' },
    { value: "Haig Girls' School" },
    { value: 'Henry Park Primary School' },
    { value: 'Horizon Primary School' },
    { value: 'Hougang Primary School' },
    { value: 'Huamin Primary School' },
    { value: 'Innova Primary School' },
    { value: 'Jiemin Primary School' },
    { value: 'Jing Shan Primary School' },
    { value: 'Junyuan Primary School' },
    { value: 'Keming Primary School' },
    { value: 'Jurong West Primary School' },
    { value: 'Kranji Primary School' },
    { value: 'Lakeside Primary School' },
    { value: 'Lianhua Primary School' },
    { value: 'Marsiling Primary School' },
    { value: 'Mayflower Primary School' },
    { value: 'Meridian Primary School' },
    { value: 'Naval Base Primary School' },
    { value: 'New Town Primary School' },
    { value: 'Northland Primary School' },
    { value: 'Northoaks Primary School' },
    { value: 'North Spring Primary School' },
    { value: 'North View Primary School' },
    { value: 'North Vista Primary School' },
    { value: 'Oasis Primary School' },
    { value: 'Opera Estate Primary School' },
    { value: 'Palm View Primary School' },
    { value: 'Park View Primary School' },
    { value: 'Pasir Ris Primary School' },
    { value: 'Pei Tong Primary School' },
    { value: 'Peiying Primary School' },
    { value: 'Pioneer Primary School' },
    { value: 'Princess Elizabeth Primary School' },
    { value: 'Punggol Cove Primary School' },
    { value: 'Punggol Green Primary School' },
    { value: 'Punggol Primary School' },
    { value: 'Punggol View Primary School' },
    { value: 'Qifa Primary School' },
    { value: 'Qihua Primary School' },
    { value: 'Queenstown Primary School' },
    { value: 'Radin Mas Primary School' },
    { value: "Raffles Girls' Primary School" },
    { value: 'Riverside Primary School' },
    { value: 'River Valley Primary School' },
    { value: 'Rivervale Primary School' },
    { value: 'Rosyth School' },
    { value: 'Rulang Primary School' },
    { value: 'Sembawang Primary School' },
    { value: 'Sengkang Green Primary School' },
    { value: 'Seng Kang Primary School' },
    { value: 'Shuqun Primary School' },
    { value: 'Si Ling Primary School' },
    { value: 'South View Primary School' },
    { value: 'Springdale Primary School' },
    { value: 'Tampines North Primary School' },
    { value: 'Tanjong Katong Primary School' },
    { value: 'Teck Ghee Primary School' },
    { value: 'Teck Whye Primary School' },
    { value: 'Temasek Primary School' },
    { value: 'Unity Primary School' },
    { value: 'Waterway Primary School' },
    { value: 'Wellington Primary School' },
    { value: 'West Grove Primary School' },
    { value: 'West Spring Primary School' },
    { value: 'Westwood Primary School' },
    { value: 'West View Primary School' },
    { value: 'White Sands Primary School' },
    { value: 'Woodgrove Primary School' },
    { value: 'Woodlands Primary School' },
    { value: 'Woodlands Ring Primary School' },
    { value: 'Xinghua Primary School' },
    { value: 'Xingnan Primary School' },
    { value: 'Xinmin Primary School' },
    { value: 'Xishan Primary School' },
    { value: 'Yangzheng Primary School' },
    { value: 'Yew Tee Primary School' },
    { value: 'Yio Chu Kang Primary School' },
    { value: 'Yishun Primary School' },
    { value: 'Yu Neng Primary School' },
    { value: 'Yuhua Primary School' },
    { value: 'Yumin Primary School' },
    { value: 'Zhangde Primary School' },
    { value: 'Zhenghua Primary School' },
    { value: 'Zhonghua Primary School' },
    { value: 'Admiralty Secondary School' },
    { value: 'Ahmad Ibrahim Secondary School' },
    { value: 'Anderson Secondary School' },
    { value: 'Anglican High School' },
    { value: 'Anglo-Chinese School (Barker Road)' },
    { value: 'Anglo-Chinese School (Independent)' },
    { value: 'Ang Mo Kio Secondary School' },
    { value: 'Assumption English School' },
    { value: 'Bartley Secondary School' },
    { value: 'Beatty Secondary School' },
    { value: 'Bedok Green Secondary School' },
    { value: 'Bedok South Secondary School' },
    { value: 'Bedok View Secondary School' },
    { value: 'Bendemeer Secondary School' },
    { value: 'Boon Lay Secondary School' },
    { value: 'Bowen Secondary School' },
    { value: 'Broadrick Secondary School' },
    { value: 'Bukit Batok Secondary School' },
    { value: 'Bukit Merah Secondary School' },
    { value: 'Bukit Panjang Government High School' },
    { value: 'Bukit View Secondary School' },
    { value: 'Catholic High School' },
    { value: 'Canberra Secondary School' },
    { value: "Cedar Girls' Secondary School" },
    { value: 'Changkat Changi Secondary School' },
    { value: 'CHIJ Katong Convent (Secondary)' },
    { value: 'CHIJ Secondary (Toa Payoh)' },
    { value: "CHIJ St. Joseph's Convent" },
    { value: "CHIJ St. Nicholas Girls' School" },
    { value: "CHIJ St. Theresa's Convent" },
    { value: 'Chua Chu Kang Secondary School' },
    { value: 'Christ Church Secondary School' },
    { value: 'Chung Cheng High School (Main)' },
    { value: 'Chung Cheng High School (Yishun)' },
    { value: 'Clementi Town Secondary School' },
    { value: 'Commonwealth Secondary School' },
    { value: 'Compassvale Secondary School' },
    { value: "Crescent Girls' School" },
    { value: 'Damai Secondary School' },
    { value: 'Deyi Secondary School' },
    { value: 'Dunearn Secondary School' },
    { value: 'Dunman High School' },
    { value: 'East Spring Secondary School' },
    { value: 'Edgefield Secondary School' },
    { value: 'Evergreen Secondary School' },
    { value: 'Fairfield Methodist Secondary School' },
    { value: 'Fuchun Secondary School' },
    { value: 'Fuhua Secondary School' },
    { value: 'Gan Eng Seng School' },
    { value: 'Geylang Methodist School (Secondary)' },
    { value: 'Greendale Secondary School' },
    { value: 'Greenridge Secondary School' },
    { value: 'Guangyang Secondary School' },
    { value: 'Hai Sing Catholic School' },
    { value: 'Hillgrove Secondary School' },
    { value: "Holy Innocents' High School" },
    { value: 'Hougang Secondary School' },
    { value: 'Hua Yi Secondary School' },
    { value: 'Hwa Chong Institution' },
    { value: 'Junyuan Secondary School' },
    { value: 'Jurong Secondary School' },
    { value: 'Jurong West Secondary School' },
    { value: 'Jurongville Secondary School' },
    { value: 'Juying Secondary School' },
    { value: 'Kent Ridge Secondary School' },
    { value: 'Kranji Secondary School' },
    { value: 'Kuo Chuan Presbyterian Secondary School' },
    { value: 'Loyang View Secondary School' },
    { value: 'Manjusri Secondary School' },
    { value: 'Maris Stella High School' },
    { value: 'Marsiling Secondary School' },
    { value: 'Mayflower Secondary School' },
    { value: 'Meridian Secondary School' },
    { value: "Methodist Girls' School (Secondary)" },
    { value: 'Montfort Secondary School' },
    { value: 'Nan Chiau High School' },
    { value: 'Nan Hua High School' },
    { value: "Nanyang Girls' High School" },
    { value: 'National Junior College' },
    { value: 'Naval Base Secondary School' },
    { value: 'New Town Secondary School' },
    { value: 'Ngee Ann Secondary School' },
    { value: 'New Town Secondary School' },
    { value: 'North Vista Secondary School' },
    { value: 'Northbrooks Secondary School' },
    { value: 'Northland Secondary School' },
    { value: 'Orchid Park Secondary School' },
    { value: 'Outram Secondary School' },
    { value: 'Pasir Ris Crest Secondary School' },
    { value: 'Pasir Ris Secondary School' },
    { value: "Paya Lebar Methodist Girls' School (Secondary)" },
    { value: 'Pei Hwa Secondary School' },
    { value: 'Peicai Secondary School' },
    { value: 'Peirce Secondary School' },
    { value: 'Presbyterian High School' },
    { value: 'Punggol Secondary School' },
    { value: 'Queenstown Secondary School' },
    { value: 'Queensway Secondary School' },
    { value: "Raffles Girls' School (Secondary)" },
    { value: 'Raffles Institution' },
    { value: 'Regent Secondary School' },
    { value: 'Riverside Secondary School' },
    { value: 'River Valley High School' },
    { value: "St. Andrew's Secondary School" },
    { value: "St. Patrick's School" },
    { value: 'School of Science and Technology' },
    { value: 'School of the Arts' },
    { value: 'Sembawang Secondary School' },
    { value: 'Seng Kang Secondary School' },
    { value: 'Serangoon Garden Secondary School' },
    { value: 'Serangoon Secondary School' },
    { value: 'Shuqun Secondary School' },
    { value: "Singapore Chinese Girls' School" },
    { value: 'Springfield Secondary School' },
    { value: "St. Anthony's Canossian Secondary School" },
    { value: "St. Gabriel's Secondary School" },
    { value: "St. Hilda's Secondary School" },
    { value: "St. Margaret's Secondary School" },
    { value: "St. Joseph's Institution" },
    { value: 'Swiss Cottage Secondary School' },
    { value: 'Tampines Secondary School' },
    { value: "Tanjong Katong Girls' School" },
    { value: 'Tanjong Katong Secondary School' },
    { value: 'Temasek Junior College' },
    { value: 'Temasek Secondary School' },
    { value: 'Unity Secondary School' },
    { value: 'Victoria School' },
    { value: 'West Spring Secondary School' },
    { value: 'Westwood Secondary School' },
    { value: 'Whitley Secondary School' },
    { value: 'Woodgrove Secondary School' },
    { value: 'Woodlands Ring Secondary School' },
    { value: 'Xinmin Secondary School' },
    { value: 'Yio Chu Kang Secondary School' },
    { value: 'Yishun Secondary School' },
    { value: 'Yishun Town Secondary School' },
    { value: 'Yuan Ching Secondary School' },
    { value: 'Yuhua Secondary School' },
    { value: 'Yusof Ishak Secondary School' },
    { value: 'Yuying Secondary School' },
    { value: 'Zhenghua Secondary School' },
    { value: 'Zhonghua Secondary School' },
    { value: 'Madrasah Aljunied Al-Islamiah' },
    { value: 'Madrasah Al-Maarif Al-Islamiah' },
    { value: 'Madrasah Alsagoff Al-Arabiah' },
    { value: 'Madrasah Wak Tanjong Al-Islamiah' },
    { value: 'Crest Secondary School' },
    { value: 'Spectra Secondary School' },
    { value: 'Anderson Serangoon Junior College' },
    { value: 'Anglo-Chinese Junior College' },
    { value: 'Anglo-Chinese School (Independent) (Junior College)' },
    { value: 'Catholic Junior College' },
    { value: 'Dunman High School (Junior College)' },
    { value: 'Eunoia Junior College' },
    { value: 'Hwa Chong Institution - Junior College Singapore' },
    { value: 'Jurong Pioneer Junior College' },
    { value: 'Millenia Institute' },
    { value: 'Nanyang Junior College' },
    { value: 'National Junior College' },
    { value: 'NUS High School of Mathematics and Science' },
    { value: 'River Valley High School (Junior College)' },
    { value: 'School of the Arts Singapore - Junior College Singapore' },
    { value: 'Singapore Sports School', abbr: 'SSP' },
    { value: 'St Andrew Junior College', abbr: 'SAJC' },
    { value: "St. Joseph's Institution (Junior College)", abbr: 'SJIJC' },
    { value: 'Tampines Meridian Junior College', abbr: 'TMJC' },
    { value: 'Temasek Junior College', abbr: 'TJC' },
    { value: 'Victoria Junior College', abbr: 'VJC' },
    { value: 'Yishun Innova Junior College', abbr: 'YIJC' },
    { value: 'Institute of Technical Education', abbr: 'ITE' },
    { value: 'Nanyang Polytechnic', abbr: 'NYP' },
    { value: 'Ngee Ann Polytechnic', abbr: 'NP' },
    { value: 'Republic Polytechnic', abbr: 'RP' },
    { value: 'Singapore Polytechnic', abbr: 'SP' },
    { value: 'Temasek Polytechnic', abbr: 'TP' },
    { value: 'National University of Singapore', abbr: 'NUS' },
    { value: 'Nanyang Technological University', abbr: 'NTU' },
    { value: 'Singapore Management University', abbr: 'SMU' },
    { value: 'Singapore University of Technology and Design', abbr: 'SUTD' },
    { value: 'Singapore Institute of Technology', abbr: 'SIT' },
    { value: 'Singapore University of Social Sciences', abbr: 'SUSS' },
];
