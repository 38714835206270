import { Fragment, useEffect, useState } from 'react';
import Pages from './Router/Pages';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from './actions/user.actions';
import { localService } from './services/localStorageService';
import * as Sentry from '@sentry/react';
import { utilConstants } from './constants/utils.constants';
import withHostEvent from './withHostEvent';
import webviewMessenger from './services/webviewMessenger';
import StoreInterface from './interfaces/store.interface';
import ApiErrorAlert from './components/Common/ApiErrorAlert';
import { activityActions } from './actions/activity.action';
import Music from './pages/activity/Music';
import { checkIfCanInspect } from './helpers/userhelper';
import { useTranslation } from 'react-i18next';
import { logger } from './services/logger';
import { cacheReducer } from './helpers/storeHelpers';
import { ReducerName } from './constants/reducer-name.enum';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { HostLogType } from './constants/host-log-types.enum';
import { isCpVerAtLeast, isNewVerAtLeast } from './helpers/utils';
import { GtmService } from './services/googleTagManager.service';

declare global {
    interface Window {
        chrome: any;
        timeHandler: any;
    }
}

window.chrome = window.chrome || {};
window.timeHandler = window.timeHandler || null;

function App({ history }: any) {
    const { ready } = useTranslation();
    const state = useSelector((state: StoreInterface) => state);
    const dispatch = useDispatch();
    const user = state.user;
    const hasApiError = state.errors.apiError;

    const [shouldRenderMusic, setShouldRenderMusic] = useState(false);
    const [loadedTime, setLoadedTime] = useState('');

    useEffect(() => {
        // check whether webview is exists or not
        if (window.chrome.webview) {
            // tell Host webview is ready
            webviewMessenger.sendSimpleMessage('webviewOnline');
            if (localStorage.getItem('nullUser')) {
                webviewMessenger.sendSimpleMessage('signInOnCrash');
                localStorage.removeItem('nullUser');
            }
            if (state.classSession) {
                webviewMessenger.sendSimpleMessage('startSlideshowAgain');
            }
        }
        window.onload = function () {
            var loadTime =
                window.performance.timing.domContentLoadedEventEnd - window.performance.timing.navigationStart;
            setLoadedTime(millisToMinutesAndSeconds(loadTime));
            if (process.env.REACT_APP_ENV === 'production') {
                const loadTimeMsg = `DOMContentLoaded, took ${loadTime} ms`;
                webviewMessenger.sendHostLog(HostLogType.DEBUG, loadTimeMsg);
                webviewMessenger.sendUsageLog(loadTimeMsg);
            }
        };

        GtmService.initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function millisToMinutesAndSeconds(duration: number) {
        const time = (duration / 1000).toFixed(2) + ' s';
        return time;
    }

    useEffect(() => {
        if (user) localStorage.removeItem('nullUser');
    }, [user]);

    useEffect(() => {
        document.addEventListener('contextmenu', preventInspectionForNonAdmin);
        return () => {
            document.removeEventListener('contextmenu', preventInspectionForNonAdmin);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const preventInspectionForNonAdmin = (event: any) => {
        const canInspect = checkIfCanInspect(user);
        if (!canInspect) event.preventDefault();
    };

    useEffect(() => {
        const cachedStore = localService.getStoreData();
        if (!cachedStore || !cachedStore.user) return;
        const cachedUser = cachedStore.user;
        if (cachedUser?.email !== user?.email) {
            logger.warn('clean up local storage');
            localService.cleanUp();
        }
    }, [user?.email]);

    useEffect(() => {
        if (user) Sentry.setUser({ email: user.email, id: user.userId, ip_address: '{{auto}}' });
        else Sentry.setUser({ ip_address: '{{auto}}' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.email]);

    useEffect(() => {
        if (user) {
            const advancedMatching = {
                em: user.email,
                fn: user.userProfile.firstName?.toLowerCase(),
                ln: user.userProfile.lastName?.toLowerCase(),
                country: user.userProfile.countryCode?.toLowerCase() || '',
                ct: user.userProfile.city?.toLowerCase() || '',
                st: '',
                zp: '',
                ph: user.userProfile.phone?.toLowerCase() || '',
                ge: '',
                db: '',
            }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
            (window as any).fbq('init', '160345806307107', advancedMatching);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.email]);

    useEffect(() => {
        cacheReducer(ReducerName.user);
    }, [state.user]);

    useEffect(() => {
        cacheReducer(ReducerName.browserTabs);
    }, [state.browserTabs]);

    useEffect(() => {
        cacheReducer(ReducerName.connection);
    }, [state.connection]);

    useEffect(() => {
        cacheReducer(ReducerName.classSession);
    }, [state.classSession]);

    useEffect(() => {
        cacheReducer(ReducerName.activity);
    }, [state.activity]);

    useEffect(() => {
        cacheReducer(ReducerName.pickNamesList);
    }, [state.pickNamesList]);

    useEffect(() => {
        cacheReducer(ReducerName.leaderboard);
    }, [state.leaderboard]);

    useEffect(() => {
        cacheReducer(ReducerName.exportPdf);
    }, [state.exportPdf]);

    useEffect(() => {
        cacheReducer(ReducerName.timerStopwatch);
    }, [state.timerStopwatch]);

    useEffect(() => {
        cacheReducer(ReducerName.tutorialSettings);
    }, [state.tutorialSettings]);

    useEffect(() => {
        cacheReducer(ReducerName.webviewSettingsEditMode);
    }, [state.webviewSettingsEditMode]);

    useEffect(() => {
        cacheReducer(ReducerName.webviewSettingsSlideshow);
    }, [state.webviewSettingsSlideshow]);

    useEffect(() => {
        cacheReducer(ReducerName.errors);
    }, [state.errors]);

    useEffect(() => {
        cacheReducer(ReducerName.aiQuiz);
    }, [state.aiQuiz]);

    useEffect(() => {
        cacheReducer(ReducerName.qna);
    }, [state.qna]);

    useEffect(() => {
        let root = document.getElementsByTagName('html')[0];
        const rtlValue = localStorage.getItem('rtl');
        if (state.user?.userPreferences?.language === 'ar' || rtlValue === 'true') {
            root.setAttribute('class', 'rtl');
            root.setAttribute('dir', 'rtl');
        } else {
            root.setAttribute('class', 'ltr');
            root.setAttribute('dir', 'ltr');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.userPreferences?.language, user?.email]);

    useEffect(() => {
        let root = document.getElementsByTagName('html')[0];
        root.setAttribute('id', 'content_select_off');
    }, []);

    useEffect(() => {
        const updateCheckForHost = setInterval(async () => {
            const latestVer = '2.0.39';
            if (!isCpVerAtLeast(latestVer) && !isNewVerAtLeast(latestVer)) webviewMessenger.sendCheckForUpdate();
        }, 1000 * 60);
        return () => clearInterval(updateCheckForHost);
    }, []);

    useEffect(() => {
        const timer = setInterval(async () => {
            if (user) dispatch(userActions.checkAndUpdateUser());
            else clearInterval(timer);
        }, parseInt(process.env.REACT_APP_PERIODIC_USER_CHECK_INTERVAL || '10000'));
        return () => clearInterval(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (
            state.connection &&
            state.classSession &&
            state.activity.activityMode === utilConstants.ACTIVITY_MODE.START_ACTIVITY
        ) {
            setShouldRenderMusic(true);
        } else {
            setShouldRenderMusic(false);
        }
    }, [state]);

    const handleDismissApiError = () => {
        dispatch(userActions.dismissApiError());
        const activity = state.activity;
        if (!activity.activityProps) return;
        if (activity.activityMode === utilConstants.ACTIVITY_MODE.START_ACTIVITY)
            dispatch(activityActions.endActivity());
    };

    if (ready)
        return (
            <Fragment>
                <Pages history={history} />
                <ApiErrorAlert
                    history={history}
                    isShowApiError={hasApiError}
                    onDismissApiError={handleDismissApiError}
                />
                {shouldRenderMusic && <Music />}
                {process.env.REACT_APP_ENV !== 'production' && !window.chrome.webview && (
                    <h1 style={{ textAlign: 'center' }}>
                        DOMContentLoaded: <span style={{ color: 'blue' }}>{loadedTime}</span>
                    </h1>
                )}
            </Fragment>
        );
    else return null;
}

export default withHostEvent(App);
