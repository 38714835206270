import { useRef } from 'react';
import { useChat } from 'ai/react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

import './AskCpAi.scss';

import { ChatList } from '../app-ui/ChatList';
import { ChatScrollAnchor } from '../app-ui/ChatScrollAnchor';
import { PromptForm } from '../app-ui/PromptForm';
import { encryptObjectToString } from '../utils/common.utils';
import useInitAiChat from '../hooks/useInitAiChat';
import { getActivityFromStore, getValidUserFromStore } from '../../../helpers/storeHelpers';

const AskCpAi = () => {
    const { t } = useTranslation();

    const autoScrollRef = useRef<HTMLDivElement>(null);
    const bottomDivRef = useRef<HTMLDivElement>(null);

    const user = getValidUserFromStore('activity');
    const activity = getActivityFromStore();

    const firstName = user.userProfile.firstName;
    const language = user.userPreferences?.language || 'English';

    const processedActivity = activity.activityProps && {
        activityCreatedOn: activity.activityProps.activityStartTime,
        activityResponses: activity.activityResponses.map((response) => {
            return {
                participantName: response.participantName,
                responseData: response.responseData,
                starsAwardedToResponse: response.responsePoints,
                responseSubmittedOn: response.responseSubmittedOn,
            };
        }),
        activityResponsesTotalCount: activity.activityResponses.length,
        activityType: activity.activityProps.activityType,
        submissionDuration:
            activity.activityProps.activityEndTime &&
            new Date(
                new Date(activity.activityProps.activityStartTime).getTime() -
                    new Date(activity.activityProps.activityEndTime).getTime(),
            ).toISOString(),
    };

    const { messages, append, isLoading, input, setInput } = useChat({
        api: `${process.env.REACT_APP_WEBAPP}/api/cp-apps/short-answer`,
        body: { context: encryptObjectToString({ firstName, processedActivity, language }) },
        onResponse(response) {
            if (response.status === 401) console.log('error', response.statusText);
        },
    });

    useInitAiChat(append);

    return (
        <div>
            <div className="modal_head">
                <Typography id="modal-modal-title" variant="h6" className="title" component="h2">
                    {t('lang_cp_ai.cp_ai_header')}
                </Typography>
            </div>
            <div className="px-4 pb-4">
                <div className="relative h-[400px]">
                    <div ref={autoScrollRef} className="max-h-[336px] w-full overflow-auto pb-[200px]">
                        {messages.length ? (
                            <div>
                                <ChatList messages={messages} isLoading={isLoading} />
                                <ChatScrollAnchor trackVisibility={isLoading} autoScrollRef={autoScrollRef} />
                                <div ref={bottomDivRef} />
                            </div>
                        ) : null}
                    </div>
                    <div className="absolute bottom-0 flex h-16 w-full flex-col justify-end">
                        <PromptForm
                            onSubmit={async (value) => {
                                bottomDivRef.current?.scrollIntoView({ behavior: 'smooth' });
                                await append({ content: value, role: 'user' });
                            }}
                            input={input}
                            setInput={setInput}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AskCpAi;
