import { Fragment, useState } from 'react';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import Rating from '@mui/material/Rating';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CustomAvatar, { AvatarType } from '../../../components/Common/CustomAvatar';
import { QuizSummaryInterface } from '../../../helpers/quizSummaryHelper';
import webviewMessenger from '../../../services/webviewMessenger';
import { useTranslation } from 'react-i18next';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';

const ShowquizResponses = ({ quizSummary }: { quizSummary: QuizSummaryInterface }) => {
    const perPage = 5;
    const quizPropsArray = quizSummary.quizPropsArray;
    const rankedParticipants = quizSummary.rankedParticipants;
    const [startingIndex, setStartingIndex] = useState(0);
    const [endingIndex, setEndingIndex] = useState(Math.min(quizPropsArray.length, 5));
    const { t } = useTranslation();
    const goToNext = () => {
        setStartingIndex((startingIndex) => startingIndex + perPage);
        setEndingIndex((endingIndex) => endingIndex + 5);
    };

    const goToPrev = () => {
        const currentInView = endingIndex - startingIndex;
        setEndingIndex((endingIndex) => endingIndex - Math.min(perPage, currentInView));
        setStartingIndex((startingIndex) => Math.max(startingIndex - perPage, 0));
    };

    const handleNavigateToActivity = (index: number) => {
        const quizProps = quizPropsArray[index + startingIndex];
        webviewMessenger.sendNavigateToActivitySlide(quizProps.activityId);
    };

    const dynamic_width: any = () => {
        const questionArray: any = [...quizSummary.quizPropsArray];
        const question_length = questionArray.length;
        let styleObj;
        switch (question_length) {
            case 1:
                styleObj = { '--padding-inline': '260px' };
                break;
            case 2:
                styleObj = { '--padding-inline': '240px' };
                break;
            case 3:
                styleObj = { '--padding-inline': '220px' };
                break;
            case 4:
                styleObj = { '--padding-inline': '200px' };
                break;
            default:
                styleObj = { '--padding-inline': '170px' };
        }
        return styleObj;
    };
    return (
        <Fragment>
            <div className="participent_points_table">
                <div className="quizResponseContainer">
                    <div className="table-container">
                        <div className="ans_table_inner showresponce" style={dynamic_width()}>
                            {/* <p>
                                startingIndex:{startingIndex}, endingIndex:
                                {endingIndex}
                            </p> */}
                            <TableContainer component={Paper} sx={{ maxHeight: 449 }} style={dynamic_width()}>
                                <Table sx={{ minWidth: '100%' }} aria-label=" sticky table" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">{t('lang_common.txt_rank')}</TableCell>
                                            <TableCell align="left">{t('lang_myclass.txt_name')}</TableCell>
                                            {quizSummary.quizPropsArray
                                                .slice(startingIndex, endingIndex)
                                                .map((quizProps, index) => {
                                                    return (
                                                        <TableCell
                                                            align="center"
                                                            key={quizProps.activityId}
                                                            onClick={() => handleNavigateToActivity(index)}
                                                        >
                                                            <div className="questionGroup">
                                                                <div className="questionLink">
                                                                    Q{index + startingIndex + 1}
                                                                </div>
                                                                <span className="percentage">
                                                                    ({quizProps.correctPercentage}%)
                                                                </span>
                                                            </div>
                                                            <Rating
                                                                name="simple-controlled"
                                                                readOnly
                                                                defaultValue={quizProps.correctPoints}
                                                                max={3}
                                                                icon={<StarRoundedIcon />}
                                                                emptyIcon={<StarOutlineRoundedIcon />}
                                                            />
                                                        </TableCell>
                                                    );
                                                })}
                                            {endingIndex > quizPropsArray.length &&
                                                Array(endingIndex - quizPropsArray.length)
                                                    .fill(null)
                                                    .map((item, index) => (
                                                        <TableCell align="center" key={index}></TableCell>
                                                    ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rankedParticipants.map((participant) => {
                                            return (
                                                <TableRow key={participant.participantId}>
                                                    <TableCell
                                                        key={participant.participantId}
                                                        align="center"
                                                        scope="row"
                                                    >
                                                        <Typography variant="caption" className="number">
                                                            {participant.rank}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <div className="profile">
                                                            <CustomAvatar
                                                                avatarName={participant.participantName}
                                                                avatarUrl={participant.participantAvatar}
                                                                type={AvatarType.PARTICIPANT}
                                                            />
                                                            <Typography variant="body1">
                                                                {participant.participantName}
                                                            </Typography>
                                                        </div>
                                                    </TableCell>
                                                    {participant.responses
                                                        .slice(startingIndex, endingIndex)
                                                        .map((response, responseIndex) => {
                                                            if (!response)
                                                                return (
                                                                    <TableCell align="center" key={responseIndex}>
                                                                        {null}
                                                                    </TableCell>
                                                                );
                                                            const mcCorrectAnswers = quizSummary.quizPropsArray[
                                                                responseIndex + startingIndex
                                                            ]?.mcCorrectAnswers || [''];
                                                            const submittedAnswers: string[] =
                                                                response.answer.split(',');
                                                            return (
                                                                <TableCell align="center" key={responseIndex}>
                                                                    <div className="flex_align_center">
                                                                        {submittedAnswers.map((answer, index) => {
                                                                            return (
                                                                                <Typography
                                                                                    key={index}
                                                                                    variant="body1"
                                                                                    className={
                                                                                        mcCorrectAnswers.join('') ===
                                                                                        submittedAnswers.join('')
                                                                                            ? 'high'
                                                                                            : 'low'
                                                                                    }
                                                                                >
                                                                                    {answer}
                                                                                </Typography>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </TableCell>
                                                            );
                                                        })}
                                                    {endingIndex > quizPropsArray.length &&
                                                        Array(endingIndex - quizPropsArray.length)
                                                            .fill(null)
                                                            .map((item, index) => (
                                                                <TableCell align="center" key={index}></TableCell>
                                                            ))}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
                {startingIndex > 0 && (
                    <Button className="arrow_left" onClick={goToPrev}>
                        <ArrowBackIosNewIcon />
                    </Button>
                )}
                {endingIndex < quizPropsArray.length && (
                    <Button className="arrow_right" onClick={goToNext}>
                        <ArrowForwardIosIcon />
                    </Button>
                )}
            </div>
        </Fragment>
    );
};

export default ShowquizResponses;
