import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { FitToViewport } from 'react-fit-to-viewport';
// import Lottie from 'react-lottie-player';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { CircularProgress } from '@mui/material';

import './FreeCredit.scss';

import { getValidUserFromStore } from '../../../helpers/storeHelpers';
import { calculateTotalAiQuestionsGenerated } from '../../../helpers/aiQuiz.helper';

import { ViewportConstants } from '../../../constants/viewport-constants';
import { utilConstants } from '../../../constants/utils.constants';
import { QuotaConstants } from '../../../constants/quota-constants';

import { UpgradeToProButton } from '../../../components/Common/UpgradeToProButton';

// import ConfettiAI from '../../../../src/assets/animation/ConfettiAI.json';

interface FreeCreditsModalInterface {
    isOpen: boolean;
    handleClose: () => void;
}

const FreeCreditsModal = ({ isOpen, handleClose }: FreeCreditsModalInterface) => {
    const { t } = useTranslation();
    const user = getValidUserFromStore('FreeCreditModal');
    const freeCreditsUsed = calculateTotalAiQuestionsGenerated(user);

    return (
        <Fragment>
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="dailog_modal"
            >
                <div>
                    <FitToViewport
                        className="modal_viewport"
                        width={ViewportConstants.width}
                        height={ViewportConstants.height}
                        minZoom={ViewportConstants.minZoom}
                        maxZoom={ViewportConstants.maxZoom}
                    >
                        <Fragment>
                            <div className="close_modal" onClick={() => handleClose()}></div>

                            <div className="free_cr_modal_box">
                                <Box className="free_cr_modal">
                                    <div className="free_cr_heading">
                                        <h4 className="head">
                                            <img
                                                src={utilConstants.CHATBOT.BIRTHDAY_CONFETTI}
                                                alt="BIRTHDAY_CONFETTI"
                                            />
                                            {t(`lang_ai_quiz.welcome.free_credits_title`).toUpperCase()}
                                        </h4>
                                    </div>
                                    <div className="free_cr_body">
                                        <div className="number_of_credit">
                                            {/* <Lottie
                                                animationData={ConfettiAI}
                                                className="confettiAI"
                                                play
                                                loop={false}
                                                speed={1}
                                            /> */}
                                            <div className="number">
                                                <p className="big">{freeCreditsUsed}</p>
                                                {/* <p className="sub_text">{t(`lang_ai_quiz.welcome.credits_left`)}</p> */}
                                            </div>
                                            <CircularProgress
                                                variant="determinate"
                                                className="circle"
                                                value={(freeCreditsUsed * 100) / QuotaConstants.BASIC_USER_AI_CREDITS}
                                            />
                                        </div>
                                        <div className="free_cr_content">
                                            <p className="details">
                                                {freeCreditsUsed < QuotaConstants.BASIC_USER_AI_CREDITS
                                                    ? t(`lang_ai_quiz.welcome.free_credits_description`, {
                                                          totalCredits: QuotaConstants.BASIC_USER_AI_CREDITS,
                                                      })
                                                    : t(`lang_ai_quiz.welcome.free_credits_used_out`)}
                                            </p>
                                            <div className="free_btn_group">
                                                <UpgradeToProButton caller={`FreeCreditModal`} email={user.email} />
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                            </div>
                        </Fragment>
                    </FitToViewport>
                </div>
            </Modal>
        </Fragment>
    );
};

export default FreeCreditsModal;
