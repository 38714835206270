import { Fragment, useEffect, useState, useRef } from 'react';
import { useAnimation, motion } from 'framer-motion';
import useSound from 'use-sound';

import { Typography } from '@mui/material';
import Button from '@mui/material/Button';

import webviewMessenger from '../../services/webviewMessenger';

import { SoundLibrary } from '../../constants/sound-library';
import { AnimationConfig } from '../../constants/animation-configs';
import { utilConstants } from '../../constants/utils.constants';

import { ButtonLottie } from '../../components/Common/AnimationModules';

import { OutlineArrowDown } from '../../Icon';

type StarProps = {
    number?: number | null;
    label?: string;
    onClick?: any;
    activity?: string;
};

function AddDeductStarButton(props: StarProps) {
    const starLottieRef = useRef<any>();

    const pointBoxAnimation = useAnimation();
    const startImageAnimate = useAnimation();
    const [playAddPointSound] = useSound(SoundLibrary.GIVE_POINT);
    const [playDeductPointSound] = useSound(SoundLibrary.DEDUCT_POINT);

    const [point, setPoint] = useState(1);
    const [totalPoint, setTotalPoint] = useState(0);
    const [startFromOrigin, setStartFromOrigin] = useState(true);
    const [lastClicked, setLastClicked] = useState(0);
    const [timeNow, setTimeNow] = useState(0);

    useEffect(() => {
        if (props.number && props.number > 0) setTotalPoint(props.number);
        else setTotalPoint(0);
    }, [props]);

    useEffect(() => {
        if (timeNow - lastClicked > 1500) {
            setPoint(1);
            setStartFromOrigin(true);
        }
        setLastClicked(timeNow);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeNow]);

    const startPositionCalculator = () => {
        if (startFromOrigin) {
            return 0;
        } else {
            return -30;
        }
    };

    const addPointHandler = () => {
        setTimeNow(new Date().getTime());

        playAddPointSound();
        starLottieRef.current.playLottieAnimation();

        setPoint(point + 1);
        if (new Date().getTime() - lastClicked < 1000) {
            pointBoxAnimation.start({
                y: [startPositionCalculator(), -30, -30, -30, -30, -30, 0],
                x: [-5, -5, -5, -5, -5, -5, -5],
                opacity: [1, 1, 1, 1, 1, 1, 0],
                transition: { duration: 1.75, times: [0, 0.2, 0.2, 0.6, 0.6, 0.9, 1] },
            });
            setStartFromOrigin(false);
        }

        startImageAnimate.start(AnimationConfig.BUTTON_STAR_CLICK);
    };

    const deductPointHandler = () => {
        playDeductPointSound();
    };

    return (
        <>
            <div
                className={`button_wraper ${'myclassBtn'} ${totalPoint > 0 && 'border_r_none'} ${
                    props.activity === 'modifyButton' && 'givePointButtonContainer'
                }`}
            >
                {props.activity === 'modifyButton' && <div className="buttonBackground"></div>}
                <Button
                    component={motion.div}
                    onHoverStart={() => {
                        startImageAnimate.start(AnimationConfig.BUTTON_STAR_HOVER_START);
                    }}
                    onHoverEnd={() => {
                        startImageAnimate.start(AnimationConfig.BUTTON_STAR_HOVER_END);
                    }}
                    className={'givePointButton starbtn big'}
                    onClick={() => {
                        webviewMessenger.sendUsageLog(`[S] Awarded star to participant`);
                        addPointHandler();
                        props.onClick(true);
                    }}
                >
                    <Fragment>
                        <div className="staricon_box">
                            <motion.img
                                src={utilConstants.IMAGE_URLS.quizSummaryIconStar}
                                alt="staricon"
                                animate={startImageAnimate}
                            />
                            <ButtonLottie ref={starLottieRef} />
                        </div>
                    </Fragment>
                    <Typography variant="body1">{props.number}</Typography>
                </Button>

                <div
                    className={totalPoint > 0 ? 'decrement' : 'decrement disable'}
                    onClick={() => {
                        webviewMessenger.sendUsageLog(`[S] Deducted star from participant`);
                        deductPointHandler();
                        props.onClick(false);
                    }}
                >
                    <OutlineArrowDown />
                </div>
            </div>
        </>
    );
}

export default AddDeductStarButton;
