import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputAdornment, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import './BookMarks.scss';
import { BookMarkItem } from './BookMarkItem';
import { getValidUserFromStore } from '../../../../../helpers/storeHelpers';
import BrowserBookmarkInterface from '../../../../../interfaces/browser-bookmark.interface';

function BookMarks() {
    const { t } = useTranslation();
    const user = getValidUserFromStore('BookMarks');
    const browserBookmarks = user.userPreferences?.browserBookmarks;

    const [searchKeyword, setSearchKeyword] = useState('');
    const [BookMarks, setBookMarks] = useState<BrowserBookmarkInterface[]>([]);
    const [pinnedItems, setPinnedItems] = useState<BrowserBookmarkInterface[]>([]);
    const [unpinnedItems, setUnpinnedItems] = useState<BrowserBookmarkInterface[]>([]);
    const [hasPinLimitReached, setHasPinLimitReached] = useState(false);
    const maxPinLimit = 10;

    useEffect(() => {
        if (browserBookmarks) {
            setBookMarks(browserBookmarks);
        }
    }, [browserBookmarks]);

    useEffect(() => {
        splitPinUnpinBookmarks(BookMarks);
    }, [BookMarks]);

    useEffect(() => {
        if (!browserBookmarks || !searchKeyword.trim()) {
            if (browserBookmarks) setBookMarks(browserBookmarks);
        } else {
            var keyWord = searchKeyword.replace(/[^\w\s]/gi, '');
            const newBookmark = browserBookmarks.filter(function (bookmark) {
                return (
                    bookmark.name.toLocaleLowerCase().search(keyWord.trim().toLocaleLowerCase()) > -1 ||
                    bookmark.pageUrl.toLocaleLowerCase().search(keyWord.trim().toLocaleLowerCase()) > -1
                );
            });
            setBookMarks(newBookmark);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchKeyword]);

    const splitPinUnpinBookmarks = (bookmarks: BrowserBookmarkInterface[]) => {
        const pinitem = bookmarks.filter((item) => item.isPinned);
        setPinnedItems(pinitem);
        setUnpinnedItems(bookmarks.filter((item) => !item.isPinned));
        if (pinitem.length >= maxPinLimit) setHasPinLimitReached(true);
        else setHasPinLimitReached(false);
    };

    // const reorderTab = (updatedBookmarks: BrowserBookmarkInterface[]) => {
    //     setBookMarks(updatedBookmarks);
    //     apiBrowserBookmarks.updateBrowserBookmarks(
    //         user.email,
    //         updatedBookmarks.map((bookmark) => bookmark.id).join(','),
    //     );
    // };

    return (
        <Fragment>
            <div className="bookmark_sec">
                <div className="inner_bookmark_sec">
                    <div className="header_title_area">
                        <Typography variant="body1">{t('lang_browser.heading_bookmarks')}</Typography>
                        <div className="urlsearch">
                            <TextField
                                fullWidth
                                autoComplete="none"
                                placeholder={t('lang_browser.bookmarks_searchbox_placeholder')}
                                id="fullWidth"
                                onChange={(e: any) => {
                                    setSearchKeyword(e.target.value);
                                }}
                                // inputRef={searchFieldRef}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    </div>
                    <div className="bookmark_area">
                        <ul>
                            {pinnedItems.map((bookmark, index: number) => (
                                <BookMarkItem
                                    key={bookmark.id}
                                    bookmark={bookmark}
                                    bookMarks={BookMarks}
                                    setBookMarks={setBookMarks}
                                    dataIndex={index}
                                    hasPinLimitReached={false}
                                />
                            ))}
                        </ul>

                        <div className={hasPinLimitReached ? 'alreadypin' : 'alreadypin notreachedlimit'}>
                            {hasPinLimitReached && unpinnedItems.length > 0 && (
                                <Typography variant="body1">
                                    {t('lang_browser.warning_max_bookmarks_pinned')}
                                </Typography>
                            )}
                        </div>

                        <ul>
                            {unpinnedItems.map((bookmark, index: number) => (
                                <BookMarkItem
                                    key={bookmark.id}
                                    bookmark={bookmark}
                                    bookMarks={BookMarks}
                                    setBookMarks={setBookMarks}
                                    dataIndex={index}
                                    hasPinLimitReached={hasPinLimitReached}
                                />
                            ))}
                        </ul>

                        {browserBookmarks && browserBookmarks.length === 0 && (
                            <Typography variant="body1" className="bookmarkEmptyText">
                                {t('lang_browser.info_no_bookmarks')}
                            </Typography>
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default BookMarks;
