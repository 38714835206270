export const MusicLibrary = [
    'assets/music/music_01.mp3',
    'assets/music/music_02.mp3',
    'assets/music/music_03.mp3',
    'assets/music/music_04.mp3',
    'assets/music/music_05.mp3',
    'assets/music/music_06.mp3',
    'assets/music/music_07.mp3',
    'assets/music/music_08.mp3',
    'assets/music/music_09.mp3',
    'assets/music/music_10.mp3',
    'assets/music/music_11.mp3',
    'assets/music/music_12.mp3',
    'assets/music/music_13.mp3',
    'assets/music/music_14.mp3',
    'assets/music/music_15.mp3',
    'assets/music/music_16.mp3',
    'assets/music/music_17.mp3',
    'assets/music/music_18.mp3',
    'assets/music/music_19.mp3',
    'assets/music/music_20.mp3',
    'assets/music/music_21.mp3',
    'assets/music/music_22.mp3',
    'assets/music/music_23.mp3',
    'assets/music/music_24.mp3',
    'assets/music/music_25.mp3',
    'assets/music/music_26.mp3',
    'assets/music/music_27.mp3',
    'assets/music/music_28.mp3',
    'assets/music/music_29.mp3',
    'assets/music/music_30.mp3',
    'assets/music/music_31.mp3',
    'assets/music/music_32.mp3',
    'assets/music/music_33.mp3',
    'assets/music/music_34.mp3',
];
