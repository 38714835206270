import { Typography } from '@mui/material';
import { Fragment } from 'react';
import Lottie from 'react-lottie-player';
import { useTranslation } from 'react-i18next';
import congratsAnimation from '../../../../assets/animation/8193-unlocked2.json';
import CommonButton from '../../../../components/Common/CommonButton';
import { utilConstants } from '../../../../constants/utils.constants';

const KeyActivated = ({ setCurrentPage, verifiedKey, setVerifiedKey }: any) => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <div className="unlockpro">
                <div className="unlockpro_content">
                    <Lottie loop={false} animationData={congratsAnimation} play speed={2} />
                    <Typography variant="body1">
                        {verifiedKey.isPremium
                            ? 'Congrats! You have unlocked ClassPoint Premium'
                            : verifiedKey.isTrial
                            ? t('lang_subscription.txt_standard_trial_congrats_msg')
                            : t('lang_subscription.txt_standard_congrats_msg')}
                    </Typography>
                    <div className="profile_update_btn">
                        <CommonButton
                            isLoading={false}
                            variant="contained"
                            type="button"
                            color="primary"
                            text={t('lang_subscription.btn_go_to_subscription')}
                            clickFunction={() => {
                                setCurrentPage(utilConstants.SUBSCRIPTION_PAGE_TYPE.Landing_Page);
                                setVerifiedKey(null);
                            }}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default KeyActivated;
