import React from 'react';
import ReactDOM from 'react-dom/client';
import './Index.scss';
import './Style.scss';
import './components/Common/AnimationModules.scss';
import './Rtl.scss';
import './globals.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './helpers/store';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ErrorBoundary from './ErrorBoundary';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18next from 'i18next';
import { logger } from './services/logger';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID } from './constants/sso-constants';
// import ReactGA from 'react-ga4';

i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        load: 'languageOnly',
        fallbackLng: 'en',
        debug: false,
        detection: {
            caches: ['localStorage'],
        },
        backend: {
            loadPath: '/locales/{{lng}}/translation.json',
        },
        react: {
            useSuspense: false,
        },
        interpolation: { escapeValue: false },
    });

//Sentry configuration
Sentry.init({
    release: process.env.REACT_APP_SENTRY_RELEASE_VERSION,
    dsn: 'https://9d4499a26d3f4197bb8496f1d5c5d405@o1095904.ingest.sentry.io/6115920',
    // dsn: 'https://73c92ba953a0470aaa833d285a5af3d2@o4504586508763136.ingest.sentry.io/4504586514726912',
    integrations: [new BrowserTracing(), new Sentry.Integrations.Breadcrumbs({ console: false }), new Sentry.Replay()],
    tracesSampleRate: process.env.REACT_APP_ENV === 'production' ? 0.05 : 1.0, //lower the value in production
    environment: window.location.hostname === 'localhost' ? 'local' : process.env.REACT_APP_ENV,
    normalizeDepth: 10,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend: (event) => {
        // if (window.location.hostname === 'localhost') {
        //     return null;
        // }
        return event;
    },
    ignoreErrors: ['ResizeObserver loop limit exceeded'], // we can ignore errors
    autoSessionTracking: true, // for health monitoring
});
// const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
// if (GA_MEASUREMENT_ID) ReactGA.initialize(GA_MEASUREMENT_ID);

logger.warn('REACT_APP_ENV', process.env.REACT_APP_ENV);

// ReactDOM.render(
//     <React.StrictMode>
//         <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
//             <Provider store={store}>
//                 <BrowserRouter>
//                     <ErrorBoundary>
//                         <App />
//                     </ErrorBoundary>
//                 </BrowserRouter>
//             </Provider>
//         </GoogleOAuthProvider>
//     </React.StrictMode>,
//     document.getElementById('root'),
// );

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <Provider store={store}>
                <BrowserRouter>
                    <ErrorBoundary>
                        <App />
                    </ErrorBoundary>
                </BrowserRouter>
            </Provider>
        </GoogleOAuthProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
