import React, { Fragment } from 'react';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Typography } from '@mui/material';
import CommonButton from '../../../../components/Common/CommonButton';
import { useTranslation } from 'react-i18next';

const ResetStarsAlert = ({ content, isResetting, onConfirmResetStars, onCloseAlert }: any) => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <div className="media">
                <div className="media_icon">
                    <ReportProblemIcon className="warning" />
                </div>
                <div className="media_body">
                    <div className="alert_content">
                        <Typography component={'span'} variant="body1">
                            {content}
                        </Typography>
                    </div>
                </div>
            </div>

            <div className="button_sec">
                {isResetting ? (
                    <CommonButton
                        className="solid"
                        isLoading={true}
                        variant="contained"
                        type="button"
                        text={t('lang_common.btn_Resetting')}
                    />
                ) : (
                    <CommonButton
                        isLoading={false}
                        variant="contained"
                        type="button"
                        text={t('lang_common.btn_yes')}
                        clickFunction={onConfirmResetStars}
                    />
                )}
                <CommonButton
                    isLoading={false}
                    variant="text"
                    type="button"
                    text={t('lang_common.btn_no')}
                    clickFunction={onCloseAlert}
                />
            </div>
        </Fragment>
    );
};

export default ResetStarsAlert;
