import { Box, Modal } from '@mui/material';
import { Fragment } from 'react';
import { FitToViewport } from 'react-fit-to-viewport';
import { motion } from 'framer-motion';
import { SimpleFadeAnimation } from '../../constants/animation-configs';

interface viewportConfigInterface {
    width: number;
    height: number;
    minZoom: number;
    maxZoom: number;
}

const EmptyModal = ({ viewportConfig }: { viewportConfig: viewportConfigInterface }) => {
    return (
        <Fragment>
            <Modal
                open={true}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={`empty_modal `}
            >
                <Fragment>
                    <FitToViewport
                        className="modal_viewport"
                        width={viewportConfig.width}
                        height={viewportConfig.height}
                        minZoom={viewportConfig.minZoom}
                        maxZoom={viewportConfig.maxZoom}
                    >
                        <Fragment>
                            <Box
                                component={motion.div}
                                variants={SimpleFadeAnimation}
                                initial="initial"
                                animate="animate"
                                className="empty_box"
                            ></Box>
                        </Fragment>
                    </FitToViewport>
                </Fragment>
            </Modal>
        </Fragment>
    );
};

export default EmptyModal;
