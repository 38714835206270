import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Typography } from '@mui/material';
import TextField from '@material-ui/core/TextField';

import { INPUT_LIMITS } from '../../../constants/utils.constants';

import SignupOrganization from '../../../interfaces/signup-organization.interface';

interface DropdownAutoCompleteInterface {
    orgList: SignupOrganization[];
    organization: string;
    organizationError: string;
    onChangeHandler: (fieldName: string, value: string) => void;
    dropDownClickHandler: (arg?: boolean) => void;
}

function DropdownAutoComplete({
    orgList,
    organization,
    organizationError,
    onChangeHandler,
    dropDownClickHandler,
}: DropdownAutoCompleteInterface) {
    const { t } = useTranslation();

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [filteredOrg, setFilteredOrg] = useState<string[]>([]);
    const [dictionaryOfOrgs, setDictionaryOfOrgs] = useState<{ name: string; search: string[]; abbr: string[] }[]>([]);

    const removeAnythingInParentheses = () => {
        const formatOrgsContent = orgList.map((item) => {
            const name = item.value;
            const search = item.value
                .replace('(', ' ')
                .replace(')', ' ')
                .toLowerCase()
                .split(/[\s-]/)
                .filter((data) => data);
            // (/ *\([^)]*\) */g, ' ')
            const generatedAbbr = search.map((data) => data[0]).join('');
            return { name, search, abbr: item.abbr ? [item.abbr.toLowerCase(), generatedAbbr] : [generatedAbbr] };
        });
        setDictionaryOfOrgs(formatOrgsContent);
    };

    const filterOrganization = (value: string) => {
        if (!value || value.length <= 2) return setFilteredOrg([]);
        const valueArray = value
            .toLowerCase()
            .split(/[\s-]/)
            .filter((data) => data);

        const matchingOrgs: { name: string; search: string[]; abbr: string[] }[] = [];
        dictionaryOfOrgs.forEach((org) => {
            const matchSearch = valueArray.map((value) =>
                org.search.toString().includes(value) || org.abbr.toString().includes(value) ? true : false,
            );
            if (matchSearch.every((data) => data)) matchingOrgs.push(org);
        });

        const exactMatchWithAbbr = matchingOrgs.filter((item) => item.abbr.includes(value.toLowerCase()));
        const exactMatchWithName = matchingOrgs.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));

        const uniqueList = [...new Set([...exactMatchWithAbbr, ...exactMatchWithName, ...matchingOrgs])].map(
            (data) => data.name,
        );
        if (uniqueList.length <= 10) setFilteredOrg(uniqueList);

        // const matchingValues = orgList.filter((item) => item.value.toLowerCase().includes(value.toLowerCase()));
        // const matchInitials = orgList.filter((item) => item.abbr?.toLowerCase()?.includes(value.toLowerCase()));
        // const uniqueList = [...new Set([...matchingValues, ...matchInitials].map((data) => data.value))];
        // if (uniqueList.length < 6) setFilteredOrg(uniqueList);
    };

    useEffect(() => {
        filterOrganization(organization);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organization]);

    useEffect(() => {
        removeAnythingInParentheses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div
                onClick={() => setIsDropdownOpen(false)}
                className={`${isDropdownOpen && 'show'} filteredOrgOverlay`}
            ></div>
            <div className={`pf_form compact`}>
                <div className="pf_form autoCompleteOrganization">
                    <TextField
                        onFocus={() => setIsDropdownOpen(true)}
                        onClick={() => setIsDropdownOpen(true)}
                        onChange={(e) => {
                            onChangeHandler('organization', e.target.value);
                        }}
                        value={organization}
                        error={organizationError ? true : false}
                        helperText={t(`${organizationError}`)}
                        inputProps={{ maxLength: INPUT_LIMITS.ORGANIZATION_MAX }}
                        label={t(`lang_auth.txt_schoolOrganization`)}
                        onKeyDown={(event) => event.key === 'Tab' && !isDropdownOpen && dropDownClickHandler()}
                        autoComplete="none"
                        variant="standard"
                    />

                    {filteredOrg.length > 0 && (
                        <div className={isDropdownOpen ? 'listItemContainer show' : 'listItemContainer'}>
                            {filteredOrg.map((value, index) => (
                                <Button
                                    key={index}
                                    variant="text"
                                    className={`${index === filteredOrg.length - 1 && 'lastElement'}`}
                                    onClick={() => {
                                        onChangeHandler('organization', value);
                                        setIsDropdownOpen(false);
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            onChangeHandler('organization', value);
                                            setIsDropdownOpen(false);
                                        }
                                        if (event.key === 'Tab' && index === filteredOrg.length - 1) {
                                            setIsDropdownOpen(false);
                                            dropDownClickHandler();
                                        }
                                    }}
                                >
                                    <Typography variant="body1">{value}</Typography>
                                </Button>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default DropdownAutoComplete;
