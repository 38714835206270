import H5AudioPlayer from 'react-h5-audio-player';
import { forwardRef } from 'react';

import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

import 'react-h5-audio-player/lib/styles.css';
import './audio-player-h5.css';

interface AudioPlayerH5Props {
    id: number;
    src: string;
    color: string;
    onPlay?: () => void;
    onPause?: () => void;
    className?: string;
}

const AudioPlayerH5 = forwardRef<H5AudioPlayer, AudioPlayerH5Props>(
    ({ id, src, color, onPlay, onPause, className }, ref) => {
        return (
            <>
                {/* <style>{`
                .player-${id} .rhap_progress-filled {
                    background: ${color};
                }
                `}</style> */}
                <H5AudioPlayer
                    ref={ref}
                    src={src}
                    autoPlay={false}
                    autoPlayAfterSrcChange={false}
                    layout="horizontal-reverse"
                    showJumpControls={false}
                    showDownloadProgress={false}
                    customVolumeControls={[]}
                    customAdditionalControls={[]}
                    customIcons={{
                        play: (
                            <IconButton className="iconButton">
                                <PlayArrowIcon />
                            </IconButton>
                        ),
                        pause: (
                            <IconButton className="iconButton">
                                <PauseIcon />
                            </IconButton>
                        ),
                    }}
                    // onPlay={onPlay}
                    // onPause={onPause}
                    // onEnded={onPause}
                    // className={className}
                    className={`player-${id}`}
                />
            </>
        );
    },
);
AudioPlayerH5.displayName = 'AudioPlayerH5';

export default AudioPlayerH5;
