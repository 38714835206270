import { Typography } from '@mui/material';
import { Fragment } from 'react';

import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CommonButton from '../../../../components/Common/CommonButton';
import webviewMessenger from '../../../../services/webviewMessenger';
import { utilConstants } from '../../../../constants/utils.constants';
import UserInterface from '../../../../interfaces/user-interface';
import { findUserClassLimit } from '../../../../helpers/userhelper';

const AddParticipantsLimitInfoModal = ({ closeAlert, type }: any) => {
    const { t } = useTranslation();
    const user: UserInterface = useSelector((state: any) => state.user);
    const userClassLimit = findUserClassLimit(user);
    const planType = user.userSubscription.planType;
    const message =
        planType === utilConstants.PLAN_TYPE.BASIC
            ? t('lang_saved_classes.txt_basic_plan_details', {
                  classLimit: userClassLimit,
              })
            : type === 'AddParticipantInSavedClass'
            ? t('lang_saved_classes.txt_max_participant_reached', {
                  classLimit: userClassLimit,
              })
            : t('lang_saved_classes.txt_plan_allow_to_add', {
                  classLimit: userClassLimit,
              });
    return (
        <Fragment>
            <div className="media">
                <div className="media_icon">
                    <ReportProblemIcon className="warning" />
                </div>
                <div className="media_body">
                    <div className="alert_content">
                        <Typography variant="body1">{message}</Typography>
                    </div>
                </div>
            </div>

            <div className="button_sec">
                <CommonButton
                    isLoading={false}
                    variant="contained"
                    type="button"
                    text={t('lang_common.btn_ok')}
                    clickFunction={() => closeAlert()}
                />
                <CommonButton
                    isLoading={false}
                    variant="text"
                    type="button"
                    text={t('lang_common.btn_learn_more')}
                    clickFunction={() => {
                        webviewMessenger.sendOpenBrowser(utilConstants.ADVANCED_FEATURE_URL);
                    }}
                />
            </div>
        </Fragment>
    );
};

export default AddParticipantsLimitInfoModal;
