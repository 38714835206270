import { FormControl, FormGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CommonCheckBox from '../../../../components/Common/CommonCheckBox';
import { utilConstants } from '../../../../constants/utils.constants';

interface SubjectListTypeInterface {
    isViewMode: boolean;
    selectedSubjects: string[];
    onCheckUncheckSubject: (arg1: string, arg2: any) => void;
}

function SubjectListType({ isViewMode, selectedSubjects, onCheckUncheckSubject }: SubjectListTypeInterface) {
    const { t } = useTranslation();

    return (
        <>
            <div className="subject_sec">
                <div className="subject_head">
                    <Typography variant="h5">{t('lang_profile.field_label_subjects')}</Typography>
                </div>
                <div className="subject_checkbox">
                    <FormControl
                        sx={{
                            m: 2,
                        }}
                        component="fieldset"
                        variant="standard"
                    >
                        <FormGroup>
                            {utilConstants.ALL_SUBJECTS.map((subject, index) => (
                                <div className="checkbox" key={index}>
                                    <CommonCheckBox
                                        isHookRegistered={false}
                                        label={t(`lang_profile.txt_subject_${subject}`)}
                                        disabled={isViewMode}
                                        value={subject}
                                        defaultChecked={selectedSubjects.includes(subject)}
                                        changeFunction={(e: any) => onCheckUncheckSubject(subject, e)}
                                    />
                                </div>
                            ))}
                        </FormGroup>
                    </FormControl>
                </div>
            </div>
        </>
    );
}

export default SubjectListType;
