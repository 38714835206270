import { ReducerName } from '../constants/reducer-name.enum';
import { WebviewSettingsActionTypes } from '../constants/webview-settings-action-types';
import { getCachedReducer } from '../helpers/storeHelpers';
import WebviewSettingsEditModeInterface from '../interfaces/webview-settings-edit-mode.interface';

export default function webviewSettingsEditModeReducer(
    state = getCachedWebviewSettingsEditModeState(),
    action: { type: string; payload: Partial<WebviewSettingsEditModeInterface> },
) {
    switch (action.type) {
        case WebviewSettingsActionTypes.HOST_FEED_PREFERENCES:
        case WebviewSettingsActionTypes.WHITE_BOARD_DELETE_ALERT:
        case WebviewSettingsActionTypes.TOGGLE_SHOW_TOOLBAR_IN_SLIDESHOW:
        case WebviewSettingsActionTypes.TOGGLE_SHOW_CLASS_CODE_LABEL_IN_SLIDESHOW:
        case WebviewSettingsActionTypes.TOGGLE_AUDIENCE_SLIDE_VIEWER:
        case WebviewSettingsActionTypes.UPDATE_SHOW_DELETE_RESPONSE_ALERT:
        case WebviewSettingsActionTypes.UPDATE_PARTICIPANT_NAMES_HIDDEN:
        case WebviewSettingsActionTypes.TOGGLE_SHOW_TOOLBAR_LABELS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

const getCachedWebviewSettingsEditModeState = (): WebviewSettingsEditModeInterface => {
    const cachedWebviewSettingsEditMode: WebviewSettingsEditModeInterface | null = getCachedReducer(
        ReducerName.webviewSettingsEditMode,
    );
    if (cachedWebviewSettingsEditMode) return { ...defaultWebviewSettingsEditMode, ...cachedWebviewSettingsEditMode };

    return defaultWebviewSettingsEditMode;
};

const defaultWebviewSettingsEditMode: WebviewSettingsEditModeInterface = {
    isShowToolbarInSlideshow: true,
    isShowClassCodeLabelInSlideshow: true,
    isShowAudienceSlideViewer: true,
    isWhiteBoardDeleteAlert: true,
    isShowDeleteResponsesAlert: true,
    isParticipantNamesHidden: false,
    showSlideShowToolbarLabels: false,
};
