import { useState, useEffect, Fragment, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Link, Modal, Tab, Typography } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
// import AddIcon from '@mui/icons-material/Add';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';

import { localDateForFileName } from '../../../helpers/utils';
import { findUserClassLimit, isUserOnPremium } from '../../../helpers/userhelper';
import { getValidUserFromStore } from '../../../helpers/storeHelpers';
import { localService } from '../../../services/localStorageService';
import webviewMessenger from '../../../services/webviewMessenger';

import SavedClassParticipantInterface from '../../../interfaces/saved-class-participant.interface';
import SavedClassForUserInterface from '../../../interfaces/saved-class-for-user.interface';
import { ViewportSavedClasses } from '../../../constants/viewport-constants';

import CommonAlert from '../../../components/Common/CommonAlert';
import AddParticipantsLimitInfoModal from './Modal/AddParticipantsLimitInfoModal';
import SavedClassAddParticipantModal from './Modal/SavedClassAddParticipantModal';
import IconButton from '@material-ui/core/IconButton';

interface SavedClassParticipantsHeaderInterface {
    savedClass: SavedClassForUserInterface;
    setSavedClass: (arg: SavedClassForUserInterface) => void;
    sortedParticipants: SavedClassParticipantInterface[];
    onParticipantsUpdated: (arg: SavedClassParticipantInterface[]) => void;
    savedClassGroupsLength: number;
    activeTabIndex: string;
    handleActiveTab: (event: SyntheticEvent, newValue: string) => void;
    showOptions?: boolean;
}

const SavedClassParticipantsHeader = ({
    savedClass,
    setSavedClass,
    sortedParticipants,
    onParticipantsUpdated,
    savedClassGroupsLength,
    activeTabIndex,
    handleActiveTab,
    showOptions,
}: SavedClassParticipantsHeaderInterface) => {
    const { t } = useTranslation();
    const user = getValidUserFromStore('SavedClassParticipants');
    // const isPremiumUser = isUserOnPremium(user);

    const [isAddParticipantModalOpen, setIsAddParticipantModalOpen] = useState(false);
    const [openClassLimitModal, setOpenClassLimitModal] = useState(false);
    const [isOptionDropDownOpen, setIsOptionDropDownOpen] = useState(false);

    const handleOpen = () => {
        if (sortedParticipants.length >= findUserClassLimit(user)) {
            setOpenClassLimitModal(true);
        } else {
            setIsAddParticipantModalOpen(true);
        }
    };

    const handleExport = () => {
        let csvContent = 'Name,Recently earned,Stars,Level';
        sortedParticipants.forEach((participant: SavedClassParticipantInterface) => {
            csvContent += `\n"${participant.participantName}",${participant.recentlyEarned},${participant.participantTotalPoints},${participant.participantLevelAndBadge?.level}`;
        });
        const fileName = `${localDateForFileName()}-${savedClass.savedClassName}.csv`;
        webviewMessenger.sendCsv(fileName, csvContent);
    };

    useEffect(() => {
        localService.removeTempAddedParticipants();
    }, [isAddParticipantModalOpen]);

    useEffect(() => {
        !showOptions && setIsOptionDropDownOpen(false);
    }, [showOptions]);

    const dropdownOptions = [
        {
            label: t('lang_saved_classes.add_participants'),
            icon: <PersonAddAltIcon />,
            onClick: () => {
                webviewMessenger.sendUsageLog(`Clicked "Add (participants)"`);
                handleOpen();
            },
        },
        {
            label: t('lang_saved_classes.export_csv'),
            icon: <ExitToAppIcon />,
            onClick: () => {
                webviewMessenger.sendUsageLog(`Clicked "Export CSV"`);
                handleExport();
            },
        },
    ];

    return (
        <Fragment>
            {isOptionDropDownOpen && (
                <div
                    onClick={() => setIsOptionDropDownOpen(false)}
                    className={`savedClassOptionsBackgroundOverlay`}
                ></div>
            )}

            {/* {isPremiumUser  ? ( */}
            <div className="addparticipent_head">
                <TabContext value={activeTabIndex}>
                    <Box className="savedClasses tabContainer">
                        <TabList onChange={handleActiveTab}>
                            <Tab
                                className="tabButton"
                                label={
                                    <p className={`participantPanel`}>
                                        {t('lang_grouping.toggle_individual')}{' '}
                                        <span className={`count`}>{sortedParticipants.length}</span>
                                    </p>
                                }
                                value="1"
                            />
                            <Tab
                                className="tabButton"
                                label={
                                    <p className={`participantPanel`}>
                                        {t('lang_grouping.toggle_groups')}{' '}
                                        <span className={`count`}>{savedClassGroupsLength}</span>
                                    </p>
                                }
                                value="2"
                            />
                        </TabList>
                    </Box>
                </TabContext>

                <div className={`optionContainer`}>
                    {activeTabIndex === '1' && (
                        <>
                            <IconButton
                                className="optionsIcon"
                                onClick={() => setIsOptionDropDownOpen((prev) => !prev)}
                            >
                                <MenuIcon />
                            </IconButton>
                            <div className={isOptionDropDownOpen ? 'savedClassOptions show' : 'savedClassOptions'}>
                                {dropdownOptions.map((data, index) => {
                                    return (
                                        <Button
                                            key={index}
                                            onClick={() => {
                                                data.onClick();
                                                setIsOptionDropDownOpen(false);
                                            }}
                                            className={`button`}
                                            variant="text"
                                        >
                                            <span className="icon">{data.icon}</span>
                                            <Typography className={`text`}>{data.label}</Typography>
                                        </Button>
                                    );
                                })}
                            </div>
                        </>
                    )}
                </div>
            </div>
            {/* ) : ( */}
            {/* <div className="addparticipent_head">
                    <div className="leftAddparticipent">
                        <div className="participant_joined_wrapper">
                            <Typography variant="caption" className="">
                                {sortedParticipants.length}
                            </Typography>
                            <Typography variant="h2">
                                {sortedParticipants.length > 1
                                    ? t('lang_saved_classes.txt_participants')
                                    : t('lang_saved_classes.txt_participant')}
                            </Typography>
                        </div>
                        <div
                            className="addbtn"
                            onClick={() => {
                                webviewMessenger.sendUsageLog(`Clicked "Add (participants)"`);
                                handleOpen();
                            }}
                        >
                            <Button variant="contained" startIcon={<AddIcon />}>
                                {t('lang_common.btn_add')}
                            </Button>
                        </div>
                    </div>
                    {sortedParticipants.length > 0 && (
                        <div className="rightAddparticipent">
                            <Link
                                onClick={() => {
                                    webviewMessenger.sendUsageLog(`Clicked "Export CSV"`);
                                    handleExport();
                                }}
                            >
                                {t('lang_saved_classes.export_csv')}
                            </Link>
                        </div>
                    )}
                </div> */}
            {/* )} */}

            <Modal
                open={isAddParticipantModalOpen}
                onClose={() => setIsAddParticipantModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="csv_upload_modal"
            >
                <SavedClassAddParticipantModal
                    onCloseAlert={() => setIsAddParticipantModalOpen(false)}
                    savedClass={savedClass}
                    setSavedClass={setSavedClass}
                    sortedParticipants={sortedParticipants}
                    onParticipantsUpdated={onParticipantsUpdated}
                />
            </Modal>

            <CommonAlert
                showAlert={openClassLimitModal}
                closeAlert={() => setOpenClassLimitModal(false)}
                content={
                    <AddParticipantsLimitInfoModal
                        closeAlert={() => setOpenClassLimitModal(false)}
                        type="AddParticipantInSavedClass"
                    />
                }
                viewportConfig={ViewportSavedClasses}
                customModalClassNames="small"
            />
        </Fragment>
    );
};

export default SavedClassParticipantsHeader;
