import { getCachedReducer } from './../helpers/storeHelpers';
import { ClassSessionActionTypes } from './../constants/class-session-action-types';
import { LeaderboardActionTypes } from '../constants/leaderboard-action-types';
import { localService } from '../services/localStorageService';
import LeaderboardInterface from '../interfaces/leaderboard.interface';
import LeaderboardParticipantInterface from '../interfaces/leaderboard-participant.interface';
import { UserActionTypes } from '../constants/user-action-types';
import { ReducerName } from '../constants/reducer-name.enum';

export default function LeaderboardReducer(
    state = getCachedLeaderboardState(),
    action: { type: string; payload: LeaderboardParticipantInterface[] },
): LeaderboardInterface {
    switch (action.type) {
        case LeaderboardActionTypes.REFRESH_LEADERS_CURRENT_CLASS:
            return {
                ...state,
                leadersCurrentClass: action.payload,
            };
        case LeaderboardActionTypes.REFRESH_LEADERS_OVERALL:
            return {
                ...state,
                leadersOverall: action.payload,
            };
        case ClassSessionActionTypes.START_SLIDESHOW:
        case ClassSessionActionTypes.END_SLIDESHOW:
        case ClassSessionActionTypes.TRIGGER_RESTART_CLASS:
        case UserActionTypes.HOST_AUTO_UNLOAD_USER:
        case UserActionTypes.AUTO_SIGN_IN_WITH_PASSWORD_FAILED:
        case UserActionTypes.USER_MANUALLY_SIGNED_OUT:
        case UserActionTypes.DEVICE_ID_CHANGED:
        case UserActionTypes.SIGN_OUT_ON_ERROR_BOUNDARY:
            return noLeaderboardState;
        default:
            return state;
    }
}

const getCachedLeaderboardState = (): LeaderboardInterface => {
    const leaderboardCached: LeaderboardInterface | null = getCachedReducer(ReducerName.leaderboard);
    if (leaderboardCached) return leaderboardCached;

    const storeCached = localService.getStoreData();
    if (storeCached && storeCached.leaderboard) return storeCached.leaderboard;

    return noLeaderboardState;
};

const noLeaderboardState = {
    leadersCurrentClass: [],
    leadersOverall: [],
};
