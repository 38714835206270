export enum ReducerName {
    user = 'user',
    browserTabs = 'browserTabs',
    connection = 'connection',
    classSession = 'classSession',
    activity = 'activity',
    pickNamesList = 'pickNamesList',
    aiQuiz = 'aiQuiz',
    leaderboard = 'leaderboard',
    exportPdf = 'exportPdf',
    timerStopwatch = 'timerStopwatch',
    tutorialSettings = 'tutorialSettings',
    webviewSettingsEditMode = 'webviewSettingsEditMode',
    webviewSettingsSlideshow = 'webviewSettingsSlideshow',
    qna = 'qna',
    errors = 'errors',
}
