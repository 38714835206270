import { Typography } from '@mui/material';
import { useAnimation, motion } from 'framer-motion';
import { Fragment, useEffect, useState, useRef } from 'react';
import { utilConstants } from '../../constants/utils.constants';
import Button from '@mui/material/Button';
import useSound from 'use-sound';
import { ButtonLottie } from './AnimationModules';
import { AnimationConfig } from '../../constants/animation-configs';
import { SoundLibrary } from '../../constants/sound-library';

type StarProps = {
    number?: number | null;
    label?: string;
    onClick?: any;
    activity?: string;
};

function GivePointButton(props: StarProps) {
    const starLottieRef = useRef<any>();

    const pointBoxAnimation = useAnimation();
    const startImageAnimate = useAnimation();
    const [playSound] = useSound(SoundLibrary.GIVE_POINT);

    const [point, setPoint] = useState(1);
    const [startFromOrigin, setStartFromOrigin] = useState(true);
    const [lastClicked, setLastClicked] = useState(0);
    const [timeNow, setTimeNow] = useState(0);

    useEffect(() => {
        if (timeNow - lastClicked > 1500) {
            setPoint(1);
            setStartFromOrigin(true);
        }
        setLastClicked(timeNow);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeNow]);

    const startPositionCalculator = () => {
        if (startFromOrigin) {
            return 0;
        } else {
            return -30;
        }
    };

    const animationHandler = () => {
        setTimeNow(new Date().getTime());

        playSound();
        starLottieRef.current.playLottieAnimation();

        setPoint(point + 1);
        if (new Date().getTime() - lastClicked < 1000) {
            pointBoxAnimation.start({
                y: [startPositionCalculator(), -30, -30, -30, -30, -30, 0],
                x: [-5, -5, -5, -5, -5, -5, -5],
                opacity: [1, 1, 1, 1, 1, 1, 0],
                transition: { duration: 1.75, times: [0, 0.2, 0.2, 0.6, 0.6, 0.9, 1] },
            });
            setStartFromOrigin(false);
        }

        startImageAnimate.start(AnimationConfig.BUTTON_STAR_CLICK);
    };

    let starContent;
    if (typeof props.number == 'number') starContent = <Typography variant="body1">x {props.number}</Typography>;
    else if (props.label) starContent = <Typography variant="body1">{props.label}</Typography>;

    return (
        <>
            <div className={`button_wraper  ${props.activity === 'modifyButton' && 'givePointButtonContainer'}`}>
                {props.activity === 'modifyButton' && <div className="buttonBackground"></div>}
                <Button
                    component={motion.div}
                    onHoverStart={() => {
                        startImageAnimate.start(AnimationConfig.BUTTON_STAR_HOVER_START);
                    }}
                    onHoverEnd={() => {
                        startImageAnimate.start(AnimationConfig.BUTTON_STAR_HOVER_END);
                    }}
                    className={starContent ? 'givePointButton starbtn big' : 'givePointButton starbtn'}
                    onClick={() => {
                        animationHandler();
                        props.onClick();
                    }}
                >
                    {props.activity === 'myClass' ? (
                        <Fragment>
                            <div className="staricon_box">
                                <motion.img
                                    src={utilConstants.IMAGE_URLS.quizSummaryIconStar}
                                    alt="staricon"
                                    animate={startImageAnimate}
                                    className="staricon"
                                />
                                <ButtonLottie ref={starLottieRef} />
                            </div>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className="staricon_box">
                                <motion.img
                                    src={utilConstants.IMAGE_URLS.quizSummaryIconStar}
                                    alt="staricon"
                                    animate={startImageAnimate}
                                    className="staricon"
                                />
                                <ButtonLottie ref={starLottieRef} />
                            </div>
                        </Fragment>
                    )}
                    {starContent}
                </Button>

                {props.activity !== 'myClass' && (
                    <Fragment>
                        <motion.div
                            className={`point_box2 ${point > 9 && 'double'}`}
                            initial={{ opacity: 0, scale: props.activity === 'pickNames' ? 0.5 : 1 }}
                            animate={pointBoxAnimation}
                        >
                            <p>{point}</p>
                        </motion.div>
                    </Fragment>
                )}
            </div>
        </>
    );
}

export default GivePointButton;
