import { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Highlighter from 'react-highlight-words';
import { AnimatePresence } from 'framer-motion';

import { Stack, Typography } from '@mui/material';

import { searchKeywordToArray } from '../../../helpers/activityHelpers';
import { base64TextToImage, stringToNumber } from '../../../helpers/utils';
import { findCenter } from '../../../helpers/animationHelpers';
import { activityActions } from '../../../actions/activity.action';

import ActivityResponseInterface from '../../../interfaces/activity-response.interface';
import { AnimationConfig } from '../../../constants/animation-configs';

import VotingCount from '../../../components/Common/VotingCount';
import MultiMediaActivityResponseFooter from '../MultiMediaActivityResponseFooter';
import { TickLottie } from '../../../components/Common/AnimationModules';
import Loading from '../Loading/Loading';

interface ImageUploadSingleResponseInterface {
    index: number;
    response: ActivityResponseInterface;
    searchKeyword: string;
    isVoting: boolean;
    onThumbClicked: any;
}

function ImageUploadSingleResponse({
    index,
    response,
    searchKeyword,
    isVoting,
    onThumbClicked,
}: ImageUploadSingleResponseInterface) {
    const dispatch = useDispatch();

    const [imageSrc, setImageSrc] = useState(JSON.parse(response.responseData)[0]);

    useEffect(() => {
        getImageUrlWithLegacyCompatible(JSON.parse(response.responseData)[0]);
    }, [response.responseData]);

    const getImageUrlWithLegacyCompatible = async (url: string) => {
        if (url.endsWith('txt')) {
            const base64 = (await base64TextToImage(url)) as string;
            setImageSrc(`data:image/png;base64, ${base64}`);
        }
    };

    const timeOutPointUpdate = useRef<any>();
    const boxSizeRef = useRef<any>();
    const [pointsAdded, setPointsAdded] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const givePoint = (response: ActivityResponseInterface, points: number) => {
        dispatch(activityActions.handleGivePointsToResponses([{ response, points }]));
    };

    const handleGivePointButtonClick = () => {
        clearTimeout(timeOutPointUpdate.current);
        setIsAnimating(true);
        setPointsAdded(pointsAdded + 1);
    };

    useEffect(() => {
        if (pointsAdded > 0) {
            timeOutPointUpdate.current = setTimeout(() => {
                givePoint(response, pointsAdded);
                setPointsAdded(0);
                setTimeout(() => {
                    setIsAnimating(false);
                }, AnimationConfig.TICK_LOTTIE_DURATION - AnimationConfig.GIVE_POINT_DELAY);
            }, AnimationConfig.GIVE_POINT_DELAY);
        }
        return () => clearTimeout(timeOutPointUpdate.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pointsAdded]);

    return (
        <Stack className={'images_boxs shadow'}>
            <div className="overflow_hidden">
                {isProcessing && (
                    <div onClick={() => onThumbClicked(response, index)}>
                        <Loading />{' '}
                    </div>
                )}
                <div className="big_sh_head">
                    {!isVoting && response.voterParticipantIds && <VotingCount response={response} />}
                </div>
                <div ref={boxSizeRef} className="big_sh_body" onClick={() => onThumbClicked(response, index)} />
                {isAnimating && <div className="overlayAnimationGlobal show imageUpload"></div>}
                <AnimatePresence>
                    {isAnimating && (
                        <TickLottie
                            key={response.responseId}
                            index={stringToNumber(response.responseId)}
                            xPosition={() => findCenter(boxSizeRef).x}
                            yPosition={() => findCenter(boxSizeRef).y}
                            scale="1.2"
                        />
                    )}
                </AnimatePresence>
                <img alt={response.participantName} className={'thumbnail_images'} src={imageSrc} />
                {JSON.parse(response.responseData)[1] ? (
                    <div className="images_content">
                        <Typography variant="body1">
                            <Highlighter
                                highlightClassName="YourHighlightClass"
                                searchWords={searchKeywordToArray(searchKeyword)}
                                autoEscape={true}
                                textToHighlight={JSON.parse(response.responseData)[1]}
                            />
                        </Typography>
                    </div>
                ) : null}
            </div>

            <MultiMediaActivityResponseFooter
                response={response}
                responseIndex={index}
                onResponseIndexDeleted={() => {}}
                searchKeyword={searchKeyword}
                isVoting={isVoting}
                pointsAdded={pointsAdded}
                onGivePointButtonClick={handleGivePointButtonClick}
                setIsProcessing={setIsProcessing}
            />
        </Stack>
    );
}

export default ImageUploadSingleResponse;
