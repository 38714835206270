import { Fragment, useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import { FitToViewport } from 'react-fit-to-viewport';

import CustomWhiteboardList from './CustomWhiteboardList';
import WhiteboardLanding from './WhiteboardLanding';
import WhiteboardSelectFrom from './components/WhiteboardSelectFrom';
import WhiteboardPreviewModal from './components/WhiteboardPreviewModal';
import ImageFileTypeWrongAlert from '../../components/Common/ImageFileTypeWrongAlert';
import { whiteBoardConstants } from '../../constants/viewport-constants';
import CommonAlert from '../../components/Common/CommonAlert';
import EmptyModal from '../EmptyModal/EmptyModal';
import BasicUserCreateWhiteboardLimitAlert from './components/BasicUserCreateWhiteboardLimitAlert';
import './WhiteBoardSetting.scss';
import { getValidUserFromStore } from '../../helpers/storeHelpers';
import WatchTutorialButton from '../savedClass/components/WatchTutorialButton';
import VideoModal from '../VideoTutorial/Components/VideoModal';
import webviewMessenger from '../../services/webviewMessenger';

const WhiteBoardSetting = () => {
    const user = getValidUserFromStore('WhiteBoardSetting');
    const { t } = useTranslation();
    const whiteboardCount = user.userPreferences?.whiteboardBackgrounds?.length || 0;

    const [addShadow, setAddShadow] = useState(false);
    const [isWhiteboardSelectFromOpen, setIsWhiteboardSelectFromOpen] = useState(false);
    const [isWhiteboardPreviewOpen, setIsWhiteboardPreviewOpen] = useState(false);
    const [showProVsBasic, setShowProVsBasic] = useState(false);
    const [showEmptyModal, setShowEmptyModal] = useState(false);
    const [isShowImageTypeWrongModal, setIsShowImageTypeWrongModal] = useState(false);
    const [imageValue, setImageValue] = useState({
        imageData: '',
        blobData: '',
        imageName: '',
    });
    const [tutorialVideoId, setTutorialVideoId] = useState('');

    const shadowHandler = (e: any) => {
        e.target.scrollTop > 0 ? setAddShadow(true) : setAddShadow(false);
    };

    const addWhiteBoardHandler = () => {
        // if (!isUserOnPro(user) && whiteboardCount >= QuotaConstants.BASIC_USER_WHITEBOARD_BACKGROUND_QUOTA)
        //     return setShowProVsBasic(true);
        // else return setIsWhiteboardSelectFromOpen(true);
        return setIsWhiteboardSelectFromOpen(true);
    };

    useEffect(() => {
        setShowEmptyModal(true);
        setTimeout(() => {
            setShowEmptyModal(false);
        }, 1);
    }, []);

    return (
        <Fragment>
            <FitToViewport
                className="viewport"
                width={whiteBoardConstants.width}
                height={whiteBoardConstants.height}
                minZoom={whiteBoardConstants.minZoom}
                maxZoom={whiteBoardConstants.maxZoom}
            >
                {showEmptyModal && <EmptyModal viewportConfig={whiteBoardConstants} />}
                {tutorialVideoId && (
                    <VideoModal
                        tutorialVideoId={tutorialVideoId}
                        setTutorialVideoId={setTutorialVideoId}
                        viewportConstant={whiteBoardConstants}
                    />
                )}

                <CommonAlert
                    showAlert={showProVsBasic}
                    closeAlert={() => {
                        setShowProVsBasic(false);
                    }}
                    content={<BasicUserCreateWhiteboardLimitAlert onConfirm={() => setShowProVsBasic(false)} />}
                    viewportConfig={whiteBoardConstants}
                    customModalClassNames="small"
                />

                <div className="whiteBoard_setting_sec">
                    {whiteboardCount > 0 && (
                        <div className={addShadow ? 'body_head box_shadow' : 'body_head'}>
                            <Typography variant="h2">{t('lang_my_whiteboard.title')}</Typography>
                            <div className="addbtn">
                                <Button
                                    variant="contained"
                                    onClick={() => addWhiteBoardHandler()}
                                    startIcon={<AddIcon />}
                                >
                                    {t('lang_common.btn_add')}
                                </Button>
                            </div>
                        </div>
                    )}

                    <Fragment>
                        {user.userPreferences?.whiteboardBackgrounds &&
                        user.userPreferences?.whiteboardBackgrounds.length > 0 ? (
                            <CustomWhiteboardList
                                imageData={user.userPreferences.whiteboardBackgrounds}
                                shadowHandler={shadowHandler}
                            />
                        ) : (
                            <>
                                <div className="inner_whiteboard_langing">
                                    <WhiteboardLanding />
                                    <div className="watch_tt_btn">
                                        <WatchTutorialButton
                                            isWhiteIcon={true}
                                            className="blue"
                                            onClickHandler={() => {
                                                webviewMessenger.sendUsageLog(`Clicked "Watch tutorial" (whiteboard)`);
                                                setTutorialVideoId('whiteboard');
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </Fragment>

                    <WhiteboardPreviewModal
                        isThisModalOpen={isWhiteboardPreviewOpen}
                        onCloseThisModal={() => {
                            setIsWhiteboardPreviewOpen(false);
                        }}
                        imageValue={imageValue}
                    />

                    <Modal
                        open={isWhiteboardSelectFromOpen}
                        onClose={() => setIsWhiteboardSelectFromOpen(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Fragment>
                            <FitToViewport
                                className="modal_viewport"
                                width={whiteBoardConstants.width}
                                height={whiteBoardConstants.height}
                                minZoom={whiteBoardConstants.minZoom}
                                maxZoom={whiteBoardConstants.maxZoom}
                            >
                                <div className="close_modal" onClick={() => setIsWhiteboardSelectFromOpen(false)}></div>
                                <Box className="whiteboardupload_modal wdth_400">
                                    <WhiteboardSelectFrom
                                        onOpenPreviewModal={() => setIsWhiteboardPreviewOpen(true)}
                                        imageDataCallBack={setImageValue}
                                        onCloseThisModal={() => setIsWhiteboardSelectFromOpen(false)}
                                        onImageTypeError={() => setIsShowImageTypeWrongModal(true)}
                                    />
                                </Box>
                            </FitToViewport>
                        </Fragment>
                    </Modal>

                    <ImageFileTypeWrongAlert
                        isAlertOpen={isShowImageTypeWrongModal}
                        onCloseAlert={() => setIsShowImageTypeWrongModal(false)}
                    />
                </div>
            </FitToViewport>
        </Fragment>
    );
};

export default WhiteBoardSetting;
