import { Avatar, TextField, Typography } from '@mui/material';
import { Fragment, useRef, useState } from 'react';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import CloseIcon from '@mui/icons-material/Close';
import CommonButton from '../../components/Common/CommonButton';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import imageCompression from 'browser-image-compression';
import apiUser from '../../services/apiUser';
import { useTranslation } from 'react-i18next';
import { getValidUserFromStore } from '../../helpers/storeHelpers';
import { v4 } from 'uuid';
import { AttachedImageInterface } from './Helpdesk';
import { generatefileName } from '../../helpers/utils';

const HelpdeskForm = ({
    onComponentChange,
    inputHandler,
    validateForm,
    subject,
    setSubject,
    message,
    error,
    attachedImages,
    onUpdateAttachedImages,
    onImageValidationError,
}: any) => {
    const user = getValidUserFromStore('HelpdeskForm');
    const [isAttaching, setIsAttaching] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const imageRef = useRef<any>(null);

    const { t } = useTranslation();

    const SendHandler = async () => {
        setIsSending(true);
        const error = validateForm();
        if (error) return setIsSending(false);

        const sendHelpDeskSuccess = await apiUser.sendHelpDesk(
            user.email,
            `${user.userProfile.firstName} ${user.userProfile.lastName}`,
            message,
            attachedImages.map((image: AttachedImageInterface) => image.imageUrl),
            subject.trim(),
        );
        if (sendHelpDeskSuccess) {
            setIsSending(false);
            return onComponentChange('success');
        } else {
            setIsSending(false);
            return onComponentChange('error');
        }
    };

    const attachImageHandler = () => {
        imageRef.current.value = '';
        imageRef.current.disabled = false;
        imageRef.current.click();
    };

    const onChangeHandler = async (event: any) => {
        const imageFile = event.target.files;
        if (imageFile.length + attachedImages.length > 3)
            return onImageValidationError(t('lang_help_desk.err_only_up_to_3_images'));

        imageFile.forEach((element: any, index: number) => {
            fileToBlob(imageFile[index]);
        });
    };

    const fileToBlob = async (imageFile: any) => {
        let imageName = v4();
        switch (imageFile.type) {
            case 'image/jpeg':
                imageName += '.jpg';
                break;
            case 'image/png':
                imageName += '.png';
                break;
            default:
                return onImageValidationError(t('lang_help_desk.err_only_jpg_png'));
        }
        const reader = new FileReader();
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        };
        try {
            setIsAttaching(true);

            const compressedFile: any = await imageCompression(imageFile, options);

            let img = document.createElement('img');
            img.crossOrigin = 'anonymous';
            reader.onloadend = async () => {
                compressedFile.lastModifiedDate = new Date();
                const convertedBlobFile = new File([compressedFile], imageFile.name, {
                    type: imageFile.type,
                    lastModified: Date.now(),
                });
                const compressedFileData = await imageCompression.getDataUrlFromFile(convertedBlobFile);
                const fileUrl = await uploadOriginalImage(compressedFile, imageName);
                if (!fileUrl) {
                    setIsAttaching(false);
                    return onImageValidationError(t('lang_help_desk.err_image_upload_failed'));
                }
                setIsAttaching(false);
                onImageValidationError('');
                onUpdateAttachedImages([
                    ...attachedImages,
                    {
                        imageData: compressedFileData,
                        imageUrl: fileUrl,
                    },
                ]);
            };
            reader.readAsDataURL(compressedFile);
        } catch (error) {
            console.log(error);
        }
    };

    const imageDeleteHandler = (id: number) => {
        const filteredImages = attachedImages.filter((item: AttachedImageInterface, index: number) => index !== id);
        onUpdateAttachedImages(filteredImages);
    };

    async function uploadOriginalImage(file: any, imageName: string) {
        const email = user.email;
        const fileName = generatefileName(email, file, imageName);
        return await apiUser.uploadToStorage(file, fileName, user.apiServer, false, 'user');
    }

    return (
        <Fragment>
            <div className="helpdeskForm_sec">
                <div className="helpdeskfrom_inner">
                    <div className="helpFromLable">
                        <Typography variant="h4">{t('lang_help_desk.txt_form_label')}</Typography>
                    </div>
                    <div className="pf_form">
                        <TextField
                            autoComplete="none"
                            id="standard-basic"
                            placeholder={t('lang_new.subject')}
                            variant="outlined"
                            value={subject}
                            onChange={(e: any) => setSubject(e.target.value)}
                        />
                    </div>
                    <div className="testField">
                        <TextareaAutosize
                            aria-label="empty textarea"
                            placeholder={t('lang_help_desk.txt_form_placeholder')}
                            onChange={inputHandler}
                            value={message}
                        />
                        {attachedImages.length > 0 && (
                            <div className="images_upload_area">
                                {attachedImages.map((item: AttachedImageInterface, index: number) => (
                                    <div className="upload_imagesbox" key={index}>
                                        <Avatar alt="Sharp" variant="rounded" src={item.imageData} />
                                        <div className="cross">
                                            <CloseIcon onClick={() => imageDeleteHandler(index)} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="error">
                        <Typography variant="body1">{error}</Typography>
                    </div>
                </div>
            </div>
            <div className="helper_btn_group">
                <div className="imagesbtn">
                    <input type="file" accept="image/*" ref={imageRef} onChange={onChangeHandler}></input>
                    <CommonButton
                        isLoading={isAttaching}
                        variant="contained"
                        className="rotateleft "
                        type="button"
                        color="primary"
                        text={t('lang_help_desk.btn_attach_image')}
                        startIcon={<AttachFileOutlinedIcon />}
                        clickFunction={attachImageHandler}
                    />
                </div>
                <CommonButton
                    isLoading={isSending}
                    variant="contained"
                    className="rotateright"
                    type="button"
                    color="primary"
                    text={t('lang_help_desk.btn_send')}
                    startIcon={<SendOutlinedIcon />}
                    clickFunction={SendHandler}
                />
            </div>
        </Fragment>
    );
};

export default HelpdeskForm;
