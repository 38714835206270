import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import './GroupModalFooter.scss';

import webviewMessenger from '../../../../services/webviewMessenger';

import {
    CreateOrEditGroupInterface,
    GroupStageType,
    SavedClassGroupInterface,
} from '../../../../interfaces/savedClassGroup.interface';

import CommonButton from '../../../../components/Common/CommonButton';
import GivePointButton from '../../../../components/Common/GivePointButton';

interface IGroupModalFooter {
    isLoading: boolean;
    groupStage: GroupStageType;
    groupStageHandler: (value: GroupStageType) => void;
    isEditMode: boolean;
    toggleEditMode: (value: boolean) => void;
    savedClassGroups: SavedClassGroupInterface[];
    currentGroupDetails: CreateOrEditGroupInterface;
    setCurrentGroupDetails: Dispatch<SetStateAction<CreateOrEditGroupInterface>>;
    createGroupHandler: () => void;
    updateGroupDetails: () => void;
    updateGroupMembers: () => void;
    errorHandler: (value: string) => void;
    toggleDeleteWarningModal: (value: boolean) => void;
    givePointHandler: () => void;
}

export default function GroupModalFooter({
    isLoading,
    groupStage,
    groupStageHandler,
    isEditMode,
    toggleEditMode,
    savedClassGroups,
    currentGroupDetails,
    setCurrentGroupDetails,
    createGroupHandler,
    updateGroupDetails,
    updateGroupMembers,
    errorHandler,
    toggleDeleteWarningModal,
    givePointHandler,
}: IGroupModalFooter) {
    const { t } = useTranslation();

    const [editModeReservedName, setEditModeReservedName] = useState('');
    const [isOptionDropDownOpen, setIsOptionDropDownOpen] = useState(false);

    const alreadyTakenGroupNames = savedClassGroups.map((group) => group.groupName);
    const finalCheckNames = isEditMode
        ? alreadyTakenGroupNames?.filter((name) => name !== editModeReservedName)
        : alreadyTakenGroupNames;

    const nextStepHandler = () => {
        const checkingName = isEditMode ? currentGroupDetails.groupNameEdited : currentGroupDetails.groupName;
        const checkingColor = isEditMode ? currentGroupDetails.groupColorEdited : currentGroupDetails.groupColor;

        if (groupStage === 'group') {
            if (!checkingName) return errorHandler(t('lang_grouping.error_group_name_required'));
            if (checkingName.trim().length > 20) {
                return errorHandler(t('lang_grouping.error_group_name_char_limit'));
            }
            if (finalCheckNames?.includes(checkingName)) {
                return errorHandler(t('lang_grouping.error_group_name_exists'));
            }
            if (!checkingColor) return errorHandler(t('lang_grouping.error_group_color_required'));
        }

        if (groupStage === 'member') {
            if (currentGroupDetails.participantIds.length < 1)
                return errorHandler(t('lang_grouping.error_group_member_required'));
        }

        if (isEditMode) groupStage === 'group' ? updateGroupDetails() : updateGroupMembers();
        else groupStage === 'group' ? groupStageHandler('member') : createGroupHandler();
    };

    const groupOptions = [
        {
            onClickHandler: () => {
                groupStageHandler('group');
                toggleEditMode(true);
                setIsOptionDropDownOpen(false);
            },
            text: t('lang_grouping.edit_group'),
            icon: (
                <div className={`iconContainer`}>
                    <EditOutlinedIcon className={`icon`} />
                </div>
            ),
        },
        {
            onClickHandler: () => {
                groupStageHandler('member');
                toggleEditMode(true);
                setCurrentGroupDetails((prev) => ({
                    ...prev,
                    initialParticipantIds: prev.participantIds,
                }));
                setIsOptionDropDownOpen(false);
            },
            text: t('lang_grouping.edit_members'),
            icon: (
                <div className={`iconContainer`}>
                    <GroupOutlinedIcon className={`icon`} />
                </div>
            ),
        },
        {
            onClickHandler: () => {
                toggleDeleteWarningModal(true);
                setIsOptionDropDownOpen(false);
            },
            text: t('lang_grouping.delete_group'),
            icon: (
                <div className={`iconContainer`}>
                    <DeleteOutlineOutlinedIcon className={`icon`} />
                </div>
            ),
            type: 'delete',
        },
    ];

    useEffect(() => {
        if (isEditMode) setEditModeReservedName(currentGroupDetails.groupName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditMode, groupStage]);

    return (
        <>
            {isOptionDropDownOpen && <div onClick={() => setIsOptionDropDownOpen(false)} className={`bgOverlay`}></div>}
            <div className={`groupModalFooter`}>
                {groupStage === 'point' && (
                    <>
                        <GivePointButton
                            activity="myClass"
                            onClick={() => {
                                webviewMessenger.sendUsageLog(
                                    `[S] Clicked "Award stars to group" on my class group view`,
                                );
                                givePointHandler();
                            }}
                            label={t('lang_grouping.award_stars_to_group')}
                        />
                        <div className={`editGroup optionContainer`}>
                            <IconButton
                                className="optionsIcon"
                                onClick={() => setIsOptionDropDownOpen((prev) => !prev)}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <div className={isOptionDropDownOpen ? 'savedClassOptions show' : 'savedClassOptions'}>
                                {groupOptions.map((data) => (
                                    <Button
                                        onClick={data.onClickHandler}
                                        className={`button ${data.type === 'delete' && 'delete'}`}
                                        variant="text"
                                    >
                                        {data.icon}
                                        <p className={`text`}>{data.text}</p>
                                    </Button>
                                ))}
                            </div>
                        </div>
                    </>
                )}

                {groupStage === 'view' && (
                    <>
                        <CommonButton
                            clickFunction={() => {
                                groupStageHandler('member');
                                toggleEditMode(true);
                                setCurrentGroupDetails((prev) => ({
                                    ...prev,
                                    initialParticipantIds: prev.participantIds,
                                }));
                            }}
                            text={t('lang_grouping.edit_members')}
                            className="editMembersBtn"
                            variant="contained"
                        />
                        <CommonButton
                            isLoading={isLoading}
                            clickFunction={() => toggleDeleteWarningModal(true)}
                            text={isLoading ? '' : t('lang_grouping.delete_group')}
                            className="deleteGroupBtn"
                            variant="contained"
                        />
                    </>
                )}

                {groupStage !== 'view' && groupStage !== 'point' && (
                    <CommonButton
                        isLoading={isLoading}
                        clickFunction={() => nextStepHandler()}
                        text={
                            isLoading
                                ? ''
                                : groupStage === 'group' && !isEditMode
                                ? t('lang_common.btn_next')
                                : t('lang_common.btn_save')
                        }
                        className="nextStepBtn"
                        variant="contained"
                    />
                )}
            </div>
        </>
    );
}
