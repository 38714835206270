import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

import CloseButton from '../../activity/activityButtons/CloseButton';
import webviewMessenger from '../../../services/webviewMessenger';
import StoreInterface from '../../../interfaces/store.interface';
import PickNamesParticipantInterface from '../../../interfaces/pick-names-participant.interface';
import BackUi from '../../../components/Common/BackUi';

interface PickNamesFooterInterface {
    pickNamesListWithOnlineStatus: PickNamesParticipantInterface[];
    handleStartAutoPick: () => void;
    setIsResetModalOpen: (arg: boolean) => void;
    handleResetPickNames: () => void;
}

const PickNamesFooter = ({
    pickNamesListWithOnlineStatus,
    handleStartAutoPick,
    setIsResetModalOpen,
    handleResetPickNames,
}: PickNamesFooterInterface) => {
    const { t } = useTranslation();
    const webviewSettingsSlideshow = useSelector((state: StoreInterface) => state.webviewSettingsSlideshow);
    const location = useLocation();
    const navigate = useNavigate();
    const showBackToMyClass = location.state ? true : false;

    const backToMyClass = () => {
        navigate('/myclass');
    };

    return (
        <footer className="footer_sec participant_sec_foot">
            {showBackToMyClass && (
                <div className="back" onClick={() => backToMyClass()}>
                    <BackUi />
                </div>
            )}
            <div className="footer_middle gap">
                {pickNamesListWithOnlineStatus.length > 0 ? (
                    <>
                        <Button type="submit" className="default_btn" onClick={handleStartAutoPick}>
                            {t('lang_pick_names.btn_auto_pick')}
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                            className="foot_btn"
                            onClick={() => {
                                webviewSettingsSlideshow.isShowPickNamesResetReminder
                                    ? setIsResetModalOpen(true)
                                    : handleResetPickNames();
                            }}
                        >
                            {t('lang_pick_names.btn_reset')}
                        </Button>
                    </>
                ) : (
                    <CloseButton onClose={() => webviewMessenger.sendHideWebview()} />
                )}
            </div>
        </footer>
    );
};

export default PickNamesFooter;
