import { Switch } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const WhiteBoardHeader = ({ onToggleCustomizedWhiteBoards, isAddWhiteboardOnPresetView }: any) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div className="class_code">
                <Switch
                    {...label}
                    disabled={false}
                    checked={!isAddWhiteboardOnPresetView}
                    onChange={onToggleCustomizedWhiteBoards}
                />{' '}
                <span className="switchtext" onClick={onToggleCustomizedWhiteBoards}>
                    {t('lang_insert_whiteboard.toggle_show_custom_whiteboards')}
                </span>
            </div>

            <div className="live_status"></div>
        </Fragment>
    );
};

export default WhiteBoardHeader;
