import { Fragment, useEffect, useState } from 'react';
import { FitToViewport } from 'react-fit-to-viewport';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { userActions } from '../../actions/user.actions';
import Footer from '../../components/layout/Footer';
import { ViewportConstants } from '../../constants/viewport-constants';
import { populateRankedParticipants, QuizSummaryInterface } from '../../helpers/quizSummaryHelper';
import QuizActivityDataInterface from '../../interfaces/quiz-activity-data.interface';
import apiActivities from '../../services/apiActivities';
import { localService } from '../../services/localStorageService';
import Loading from '../activity/Loading/Loading';
import NoParticipantInClass from '../../components/Common/NoParticipantInClass';
import ParticipentPointTable from './Components/ParticipentPointTable';
import ShowquizResponses from './Components/ShowquizResponses';
import './QuizSummary.scss';
import EmptyModal from '../EmptyModal/EmptyModal';
import NoResponse from '../activity/NoResponse';
import QuizSummaryHeader from './Components/header';
import Header from '../../components/layout/Header/Header';
import { getValidUserFromStore } from '../../helpers/storeHelpers';
import { isUserOnTrialOrPro } from '../../helpers/userhelper';
import CommonAlert from '../../components/Common/CommonAlert';
import ProPitchSlides from '../activateTrial/ProPitchSlides';
import WatchTutorialButton from '../savedClass/components/WatchTutorialButton';
import webviewMessenger from '../../services/webviewMessenger';
import VideoModal from '../VideoTutorial/Components/VideoModal';

const QuizSummary = () => {
    const isQuizModeProFeature = process.env.REACT_APP_IS_QUIZ_MODE_PRO === 'true';
    const dispatch = useDispatch();
    const user = getValidUserFromStore('QuizSummary');
    const shouldShowQuizAsPro = !isUserOnTrialOrPro(user) && isQuizModeProFeature;
    const [quizSummary, setQuizSummary] = useState<QuizSummaryInterface | null>();
    const [showEmptyModal, setShowEmptyModal] = useState(false);
    const [isShowQuizeResponse, setIsShowQuizeResponse] = useState(false);
    const [tutorialVideoId, setTutorialVideoId] = useState('');

    const { t } = useTranslation();
    const historyState = useLocation().state;
    const isFromRibbon = historyState?.isFromRibbon || false;

    useEffect(() => {
        setShowEmptyModal(true);
        setTimeout(() => {
            setShowEmptyModal(false);
        }, 1);
    }, []);

    useEffect(() => {
        loadQuizSummary();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadQuizSummary = async () => {
        const quizActivityIds = localService.getQuizActivityIds();
        if (quizActivityIds.length === 0) {
            setQuizSummary(null);
            return;
        }
        const cachedQuizSummary = localService.getQuizSummary();
        if (cachedQuizSummary) {
            if (JSON.stringify(cachedQuizSummary.quizPropsArray) === JSON.stringify(quizActivityIds))
                return setQuizSummary(cachedQuizSummary);
        }

        const rawData = await apiActivities.getMultipleActivities(quizActivityIds.join(','), user.email);
        // console.log('rawData', rawData);
        if (!rawData) return dispatch(userActions.showApiError());
        const quizDataArray: QuizActivityDataInterface[] = rawData.map((item: any) => ({
            activityId: item.activityId,
            activityCreatedOn: item.activityCreatedOn,
            activityResponses: item.activityResponses,
            activitySlideSavedUrl: item.activitySlideSavedUrl,
            mcCorrectAnswers: item.mcCorrectAnswers,
            correctPoints: item.correctPoints,
        }));

        const quizSummary = populateRankedParticipants(quizDataArray);
        // console.log('quizSummary', quizSummary);

        setQuizSummary(quizSummary);
        localService.setQuizSummary(quizSummary);
    };

    return (
        <Fragment>
            <div className="black">
                <FitToViewport
                    className="viewport"
                    width={ViewportConstants.width}
                    height={ViewportConstants.height}
                    minZoom={ViewportConstants.minZoom}
                    maxZoom={ViewportConstants.maxZoom}
                >
                    {showEmptyModal && <EmptyModal viewportConfig={ViewportConstants} />}
                    {tutorialVideoId && (
                        <VideoModal
                            tutorialVideoId={tutorialVideoId}
                            setTutorialVideoId={setTutorialVideoId}
                            viewportConstant={ViewportConstants}
                        />
                    )}
                    {quizSummary && (
                        <>
                            <Header appBarTitle={t('lang_activity.quiz_summary')}>
                                <Fragment>
                                    <QuizSummaryHeader
                                        setIsShowQuizeResponse={setIsShowQuizeResponse}
                                        isShowQuizeResponse={isShowQuizeResponse}
                                        quizSummary={quizSummary}
                                    />
                                </Fragment>
                            </Header>
                            <div className="Quizesummary_main">
                                {quizSummary.rankedParticipants && quizSummary.rankedParticipants.length > 0 ? (
                                    <Fragment>
                                        {isShowQuizeResponse ? (
                                            <ShowquizResponses quizSummary={quizSummary} />
                                        ) : (
                                            <ParticipentPointTable
                                                rankedParticipants={quizSummary.rankedParticipants}
                                            />
                                        )}
                                    </Fragment>
                                ) : (
                                    <NoResponse />
                                )}
                            </div>
                            <Footer layout="quizsummary" isFromRibbon={isFromRibbon} />
                        </>
                    )}
                    {quizSummary === null ? (
                        <>
                            <Header appBarTitle={t('lang_activity.quiz_summary')}>
                                <Fragment>
                                    <QuizSummaryHeader
                                        setIsShowQuizeResponse={setIsShowQuizeResponse}
                                        isShowQuizeResponse={isShowQuizeResponse}
                                        quizSummary={quizSummary}
                                    />
                                </Fragment>
                            </Header>
                            <NoParticipantInClass content="quizSummary">
                                <WatchTutorialButton
                                    onClickHandler={() => {
                                        webviewMessenger.sendUsageLog(`Clicked "Watch tutorial" (quiz_mode)`);
                                        setTutorialVideoId('quiz_mode');
                                    }}
                                    isWhiteIcon={true}
                                    className="savedClassWelcome"
                                />
                            </NoParticipantInClass>
                            <Footer layout="quizsummary" isFromRibbon={true} />
                        </>
                    ) : (
                        <Loading />
                    )}
                    {shouldShowQuizAsPro && (
                        <CommonAlert
                            showAlert={true}
                            content={
                                <div className={`trialFlowContentContainer`}>
                                    <ProPitchSlides
                                        isShowMultipleSlides={false}
                                        startingSlideId={'pro_activity_quiz_mode'}
                                        customClassNames={'default '}
                                    />
                                </div>
                            }
                            viewportConfig={ViewportConstants}
                            customBoxClassNames={'quizModePro'}
                        />
                    )}
                </FitToViewport>
            </div>
        </Fragment>
    );
};

export default QuizSummary;
