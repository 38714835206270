import { Button, Link, Typography } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { utilConstants } from '../../../constants/utils.constants';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getValidUserFromStore } from '../../../helpers/storeHelpers';
import apiUser from '../../../services/apiUser';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';
import purlePapperPlan from '../../../../src/assets/animation/PurplePaperPlane.json';
import DoneIcon from '@mui/icons-material/Done';
import shareClassPointAnimation from '../../../assets/animation/shareClassPointAnimation.json';
import { EmailShareButton } from 'react-share';
import CustomAvatar, { AvatarType } from '../../../components/Common/CustomAvatar';
import { FacebookColor, LinkedinColor, TwitterColor, WhatsAppShareIcon } from '../../../Icon';
import { SOCIAL_MEDIA_Types, getEncodedData } from '../../activity/AlertComponents/shareActivityHepler';
import webviewMessenger from '../../../services/webviewMessenger';

function ShareClassPoint() {
    const { t } = useTranslation();
    const subscribeAppBaseUrl = `${process.env.REACT_APP_WEBAPP}/cp/checkout`;
    const [referralCode, setReferralCode] = useState('');
    const user = getValidUserFromStore('ShareClassPoint');
    const referees = user.userStats?.referees || [];
    const [showRefferalModal, setShowRefferalModal] = useState(false);
    const [toggleCopiedBtn, setToggleCopiedBtn] = useState(false);
    const title = t('lang_share_classpoint.share_title');
    const message = t('lang_share_classpoint.share_message');
    const hashtag = 'ClassPointMagic';
    const hashtags = ['ClassPointMagic', 'ClassPoint'];
    const source = 'Classpoint';

    useEffect(() => {
        getReferralLinks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getReferralLinks = async () => {
        const referralCode = await apiUser.getReferralCode(user.userId);
        setReferralCode(referralCode);
    };

    const copyUrl = (url: string) => {
        navigator.clipboard.writeText(url);
    };

    const copiedUrlLink = () => {
        copyUrl(`${subscribeAppBaseUrl}?s=${referralCode}`);
        setToggleCopiedBtn(true);
        setTimeout(() => {
            setToggleCopiedBtn(false);
        }, 1000);
    };

    const openLinkInBrowser = (type: SOCIAL_MEDIA_Types) => {
        const shareUrl = `${subscribeAppBaseUrl}?s=${referralCode}`;
        const encodedDataObject = { shareUrl, title, message, hashtag, hashtags, source };
        webviewMessenger.sendOpenBrowser(getEncodedData(encodedDataObject, type));
    };

    return (
        <Fragment>
            <div className="sharing_sec">
                <div className="sharing_box">
                    <div className="content">
                        <div className="lottie_icon">
                            <Lottie loop={true} animationData={shareClassPointAnimation} play speed={0.8} />
                        </div>
                        <h2 className="title">{t('lang_share_classpoint.get_extra_months_pro')}</h2>
                        <span className="content_text">{t('lang_share_classpoint.txt_classpoint_description')}</span>
                    </div>
                    <div className="copy_url">
                        <input type="text" value={`${subscribeAppBaseUrl}?s=${referralCode}`} readOnly />

                        <Button
                            className="btn"
                            variant="contained"
                            onClick={copiedUrlLink}
                            endIcon={<ContentCopyIcon />}
                        >
                            {t('lang_share_classpoint.btn_copy')}
                            {toggleCopiedBtn && (
                                <span className="copyurl_tooltip">
                                    {t('lang_share_classpoint.tooltip_copied')} <DoneIcon />
                                </span>
                            )}
                        </Button>
                    </div>
                    <div className="social_media">
                        <div className="icon_list">
                            <EmailShareButton
                                url={`${subscribeAppBaseUrl}?s=${referralCode}`}
                                subject={title}
                                body={message}
                                separator={'\n'}
                            >
                                <img src={utilConstants.IMAGE_URLS.email_share_icon} alt={'emailShareIcon'} />
                            </EmailShareButton>

                            <i className="icon" onClick={() => openLinkInBrowser(SOCIAL_MEDIA_Types.WHATSAPP)}>
                                <WhatsAppShareIcon />
                            </i>

                            <i className="icon" onClick={() => openLinkInBrowser(SOCIAL_MEDIA_Types.FACEBOOK)}>
                                <FacebookColor />
                            </i>

                            <i className="icon" onClick={() => openLinkInBrowser(SOCIAL_MEDIA_Types.TWITTER)}>
                                <TwitterColor />
                            </i>

                            <i className="icon" onClick={() => openLinkInBrowser(SOCIAL_MEDIA_Types.LINKEDIN)}>
                                <LinkedinColor />
                            </i>
                        </div>
                    </div>
                    <div className="total_download">
                        <div className={`number ${referees.length > 0 && 'green'}`}>
                            <p>{referees.length}</p>
                        </div>
                        <div className="text">
                            <p>{t('lang_share_classpoint.txt_classpoint_month_subscription')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom_fixed">
                <div className="content_bottom sh_ClassPoint">
                    <Typography variant="body1" className="d_flex">
                        🎉{' '}
                        <Link className="link" onClick={() => setShowRefferalModal(true)}>
                            {t('lang_share_classpoint.see_who_subscribed_using_my_link')}
                        </Link>
                    </Typography>
                </div>
            </div>
            {showRefferalModal && (
                <div className={`modal ${referees.length > 0 ? 'referral_modal' : 'referral_link_modal'} `}>
                    <div className="back_drop" onClick={() => setShowRefferalModal(false)}></div>
                    <div className="modal_box">
                        {referees.length > 0 && (
                            <div className="modal_title">
                                <Typography variant="h4">
                                    {t('lang_share_classpoint.x_free_months_earned', { freeMonths: referees.length })}
                                </Typography>
                            </div>
                        )}

                        <div className="modal_body">
                            {referees.length > 0 ? (
                                <div className="referral_name">
                                    <div className="inner_referral">
                                        {referees.map((referee) => {
                                            return (
                                                <div className="referral_card_name">
                                                    <p className="avater_box">
                                                        <CustomAvatar
                                                            avatarUrl={referee.avatarUrl}
                                                            avatarName={referee.displayName}
                                                            type={AvatarType.USER}
                                                        />
                                                    </p>
                                                    <div className="content_box">
                                                        <h4 className="name">{referee.displayName}</h4>
                                                        <p className="email">{referee.email}</p>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            ) : (
                                <div className="referral_link">
                                    <Lottie
                                        loop={true}
                                        className="purlePapperPlan"
                                        animationData={purlePapperPlan}
                                        play={true}
                                        speed={1}
                                    />
                                    <div className="inner_content">
                                        <p className="info">{t('lang_share_classpoint.nothing_yet')}</p>
                                        <div className="copy_url_modal">
                                            <input
                                                type="text"
                                                value={`${subscribeAppBaseUrl}?s=${referralCode}`}
                                                readOnly
                                            />
                                        </div>
                                        <Button className="btn_copy_url" variant="contained" onClick={copiedUrlLink}>
                                            {t('lang_share_classpoint.btn_copy_link')}
                                            {toggleCopiedBtn && (
                                                <span className="copyurl_tooltip">
                                                    {t('lang_share_classpoint.tooltip_copied')}
                                                    <DoneIcon />
                                                </span>
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
}

export default ShareClassPoint;
