import { getCachedReducer } from './../helpers/storeHelpers';
import { ExportPdfActionTypes } from '../constants/export-pdf-action-types';
import ExportPdfInterface from '../interfaces/export-pdf.interface';
import { localService } from '../services/localStorageService';
import { ReducerName } from '../constants/reducer-name.enum';

export default function ExportPdfReducer(
    state = getInitialState(),
    action: { type: string; payload?: Partial<ExportPdfInterface> },
): ExportPdfInterface {
    switch (action.type) {
        case ExportPdfActionTypes.OPEN_EXPORT_PDF:
        case ExportPdfActionTypes.UPDATE_SELECTED_SLIDES_COUNT:
        case ExportPdfActionTypes.UPDATE_INCLUDE_ANNOTATIONS_WHITEBOARD:
        case ExportPdfActionTypes.START_EXPORT_PDF:
        case ExportPdfActionTypes.PDF_EXPORTED:
            return { ...state, ...action.payload };
        case ExportPdfActionTypes.CANCEL_EXPORT_PDF:
            return {
                ...emptyState,
                isIncludeAnnotation: state.isIncludeAnnotation,
                isIncludeWhiteboard: state.isIncludeWhiteboard,
            };
        default:
            return state;
    }
}

const getInitialState = (): ExportPdfInterface => {
    const exportPdfCached: ExportPdfInterface | null = getCachedReducer(ReducerName.exportPdf);
    if (exportPdfCached) return exportPdfCached;

    const storeCached = localService.getStoreData();
    if (storeCached && storeCached.exportPdf) return storeCached.exportPdf;

    return emptyState;
};

const emptyState: ExportPdfInterface = {
    selectedSlidesCount: 0,
    isExporting: false,
    pdfUrl: '',
    isIncludeAnnotation: true,
    isIncludeWhiteboard: true,
};
