import CommonButton from '../../../components/Common/CommonButton';
import { useTranslation } from 'react-i18next';

export default function CloseButton({ onClose }: any) {
    const { t } = useTranslation();
    return (
        <CommonButton
            isLoading={false}
            className="default_btn"
            type="button"
            clickFunction={onClose}
            text={t('lang_common.btn_close')}
        />
    );
}
