import { Fragment, useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import imageCompression from 'browser-image-compression';

import webviewMessenger from '../../../services/webviewMessenger';
import { localService } from '../../../services/localStorageService';
import { dataURLtoFile } from '../../../helpers/utils';

const WhiteboardSelectFrom = ({ onOpenPreviewModal, imageDataCallBack, onCloseThisModal, onImageTypeError }: any) => {
    const { t } = useTranslation();
    const [buttonDisable, setButtenDisable] = useState(false);
    const [isCheckingCurrentSlide, setIsCheckingCurrentSlide] = useState(false);
    const imageRef = useRef<any>(null);

    useEffect(() => {
        const checkCurrentSlide = setInterval(() => {
            if (isCheckingCurrentSlide) {
                const currentSlide = localService.getTempWhiteboard();
                if (currentSlide) {
                    clearInterval(checkCurrentSlide);
                    setIsCheckingCurrentSlide(false);
                    const name = `whiteboard-from-currentslide-${v4()}.jpg`;
                    const base64 = `data:image/jpeg;base64,${currentSlide}`;
                    const file = dataURLtoFile(base64, name);
                    localService.removeTempWhiteboard();
                    fileToBlob(file);
                } else {
                    console.log('Error');
                }
            } else clearInterval(checkCurrentSlide);
        }, 10);
        return () => clearInterval(checkCurrentSlide);
    }, [isCheckingCurrentSlide]);

    const chooseImage = () => {
        imageRef.current.value = '';
        imageRef.current.disabled = false;
        imageRef.current.click();
    };

    const handleImageChange = async (event: any) => {
        setButtenDisable(true);
        const imageFile = event.target.files;
        fileToBlob(imageFile[0]);
    };

    const currentSlideHandler = () => {
        setButtenDisable(true);
        setIsCheckingCurrentSlide(true);
        webviewMessenger.sendSimpleMessage('useCurrentSlide');
    };

    const fileToBlob = async (imageFile: any) => {
        let imageName = 'whiteboard-' + v4();
        switch (imageFile.type) {
            case 'image/jpeg':
            case 'image/png':
                imageName += '.jpg';
                break;
            default:
                setButtenDisable(false);
                return onImageTypeError();
        }
        const reader = new FileReader();
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        };
        try {
            const compressedFile = await imageCompression(imageFile, options);
            let img = document.createElement('img');
            img.crossOrigin = 'anonymous';
            reader.onloadend = async () => {
                const convertedBlobFile = new File([compressedFile], imageFile.name);
                const compressedFileData = await imageCompression.getDataUrlFromFile(convertedBlobFile);
                imageDataCallBack({
                    imageData: compressedFileData,
                    blobData: compressedFile,
                    imageName: imageName,
                });
                onCloseThisModal();
                onOpenPreviewModal();
            };
            reader.readAsDataURL(compressedFile);
            setButtenDisable(false);
        } catch (error) {
            console.log(error);
            setButtenDisable(false);
        }
    };

    return (
        <Fragment>
            <div className="whiteBoard_landingpage_btn_group ">
                <div className="imagesbtn">
                    <input
                        type="file"
                        accept="image/jpeg, image/png"
                        ref={imageRef}
                        onChange={handleImageChange}
                    ></input>
                    <Button disabled={buttonDisable} size="large" onClick={chooseImage}>
                        <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M21.9984 16.5V21H3.99841V16.5H0.998413V21C0.998413 22.65 2.34841 24 3.99841 24H21.9984C23.6484 24 24.9984 22.65 24.9984 21V16.5H21.9984ZM5.49841 7.5L7.61341 9.615L11.4984 5.745V18H14.4984V5.745L18.3834 9.615L20.4984 7.5L12.9984 0L5.49841 7.5Z"
                                fill="#FF9264"
                            />
                        </svg>
                        {t('lang_my_whiteboard.btn_upload_image')}
                    </Button>
                </div>

                <Button disabled={buttonDisable} size="large" onClick={() => currentSlideHandler()}>
                    <svg viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24.9984 6.5H17.4984V9.5H24.9984V6.5Z" fill="#FF9264" />
                        <path d="M24.9984 12.5H17.4984V15.5H24.9984V12.5Z" fill="#FF9264" />
                        <path
                            d="M11.4984 11V6.5C10.6084 6.5 9.7384 6.76367 8.99838 7.25806C8.25836 7.75317 7.68158 8.45557 7.34091 9.27808C7.00034 10.0999 6.91125 11.0051 7.08484 11.8782C7.25851 12.7505 7.68707 13.5525 8.31641 14.1816C8.94574 14.8115 9.74756 15.24 10.6205 15.4136C11.4934 15.5872 12.3982 15.4978 13.2205 15.1572C14.0428 14.8167 14.7455 14.2402 15.24 13.4998C15.7345 12.76 15.9984 11.8899 15.9984 11H11.4984Z"
                            fill="#FF9264"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.49841 23H29.4984C29.8962 23 30.2778 22.8418 30.5591 22.5605C30.8404 22.2793 30.9984 21.8977 30.9984 21.5V2C30.9984 1.17157 30.3268 0.5 29.4984 0.5H2.49841C1.66999 0.5 0.998413 1.17157 0.998413 2V21.5C0.998413 21.8977 1.15643 22.2793 1.43777 22.5605C1.71902 22.8418 2.10062 23 2.49841 23ZM27.9984 20H3.99841V3.5H27.9984V20Z"
                            fill="#FF9264"
                        />
                    </svg>
                    {t('lang_my_whiteboard.btn_use_current_slide')}
                </Button>
            </div>
        </Fragment>
    );
};

export default WhiteboardSelectFrom;
