import { useTranslation } from 'react-i18next';

import { TableHead, TableRow, TableCell, TableSortLabel, Box } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { OrderProperties } from '../../../../interfaces/saved-class-participant.interface';

interface HeadCell {
    disablePadding: boolean;
    id: OrderProperties;
    label: string;
    numeric: boolean;
}

interface EnhancedTableProps {
    order: 'asc' | 'desc';
    orderBy: string;
    onRequestSort: (property: OrderProperties) => void;
    isPremiumUser: boolean;
    hideGroupColumn?: boolean;
    isViewingGroup?: boolean;
}

function SavedClassParticipantsTableHead({
    order,
    orderBy,
    onRequestSort,
    isPremiumUser,
    hideGroupColumn,
    isViewingGroup,
}: EnhancedTableProps) {
    const { t } = useTranslation();

    const headCells: readonly HeadCell[] = [
        {
            id: OrderProperties.ParticipantName,
            numeric: false,
            disablePadding: true,
            label: t('lang_myclass.txt_name'),
        },
        // {
        //     id: OrderProperties.RecentlyEarned,
        //     numeric: true,
        //     disablePadding: false,
        //     label: t('lang_common.txt_recently_earned'),
        // },
        {
            id: OrderProperties.Group,
            numeric: false,
            disablePadding: false,
            label: t('lang_grouping.table_header_group'),
        },
        {
            id: OrderProperties.ParticipantTotalPoints,
            numeric: false,
            disablePadding: false,
            label: t('lang_myclass.txt_stars'),
        },
        {
            id: OrderProperties.ParticipantLevel,
            numeric: false,
            disablePadding: false,
            label: t('lang_common.txt_level'),
        },
    ];

    return (
        <TableHead className={`savedClassParticipantTableHead`}>
            <TableRow className={`savedClassParticipantTableRow`}>
                <TableCell padding="checkbox"></TableCell>
                {headCells.map((headCell) => {
                    if (
                        (!isPremiumUser || isViewingGroup || hideGroupColumn) &&
                        headCell.id === OrderProperties.Group
                    ) {
                        return null;
                    }

                    return (
                        <TableCell
                            key={headCell.id}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : 'asc'}
                            className={headCell.label.toLowerCase()}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={() => onRequestSort(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    );
                })}
                {!isViewingGroup && <TableCell padding="checkbox"></TableCell>}
            </TableRow>
        </TableHead>
    );
}

export default SavedClassParticipantsTableHead;
