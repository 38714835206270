import axios from 'axios';

import { logger } from './logger';
import { sentryLogApiError } from './sentryService';
import webviewMessenger from './webviewMessenger';

import { EditTemplateItemDto, TemplateItemInterface } from '../interfaces/templateLibrary.interface';

const apiTemplateLibrary = {
    getCategories: async (): Promise<string[] | null> => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/slideslib/categories`);
            return response.data;
        } catch (error: any) {
            logger.error('apiTemplateLibrary.getCategory() error: ', error.response || error);
            sentryLogApiError(error, 'getCategory');
            return null;
        }
    },

    getTags: async (): Promise<string[] | null> => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/slideslib/tags`);
            return response.data;
        } catch (error: any) {
            logger.error('apiTemplateLibrary.getTags() error: ', error.response || error);
            sentryLogApiError(error, 'getTags');
            return null;
        }
    },

    getUnpublishedTemplates: async (): Promise<TemplateItemInterface[] | null> => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/slideslib/unpublished`);
            return response.data;
        } catch (error: any) {
            logger.error('apiTemplateLibrary.getUnpublishedTemplates() error: ', error.response || error);
            sentryLogApiError(error, 'getUnpublishedTemplates');
            return null;
        }
    },

    getTemplatesByCategory: async (category: string, skipCache: boolean): Promise<TemplateItemInterface[] | null> => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API}/slideslib/by-category?categoryName=${category}&skipCache=${skipCache}`,
            );
            return response.data;
        } catch (error: any) {
            logger.error('apiTemplateLibrary.getTemplateByCategory() error: ', error.response || error);
            sentryLogApiError(error, 'getTemplateByCategory');
            return null;
        }
    },

    downloadTemplate: async (templateId: string): Promise<void> => {
        try {
            await axios.patch(`${process.env.REACT_APP_API}/slideslib/download?id=${templateId}`);
        } catch (error: any) {
            logger.error('apiTemplateLibrary.downloadTemplate() error: ', error.response || error);
        }
    },

    updateTemplateDetails: async (
        templateId: string,
        details: EditTemplateItemDto,
    ): Promise<TemplateItemInterface | null> => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API}/slideslib/edit?id=${templateId}`, details);
            return response.data;
        } catch (error: any) {
            logger.error('apiTemplateLibrary.updateTemplateDetails() error: ', error.response || error);
            sentryLogApiError(error, 'updateTemplateDetails');
            return null;
        }
    },

    publishUnpublishTemplate: async (templateId: string, isPublish: boolean): Promise<TemplateItemInterface | null> => {
        try {
            const response = await axios.patch(
                `${process.env.REACT_APP_API}/slideslib/publish-unpublish?id=${templateId}&isPublish=${isPublish}`,
            );
            return response.data;
        } catch (error: any) {
            logger.error('apiTemplateLibrary.publishUnpublishTemplate() error: ', error.response || error);
            sentryLogApiError(error, 'publishUnpublishTemplate');
            return null;
        }
    },

    deleteTemplate: async (templateId: string): Promise<boolean | null> => {
        try {
            await axios.delete(`${process.env.REACT_APP_API}/slideslib/delete?id=${templateId}`);
            return true;
        } catch (error: any) {
            logger.error('apiTemplateLibrary.deleteTemplate() error: ', error.response || error);
            sentryLogApiError(error, 'deleteTemplate');
            return null;
        }
    },

    uploadTemplate: async (pptUrl: string, imageUrls: string[]): Promise<TemplateItemInterface | null> => {
        try {
            const url = `${process.env.REACT_APP_API}/slideslib/upload`;
            const response = await axios.post(url, { pptUrl, imageUrls });
            webviewMessenger.sendSimpleMessage('uploadSlideTemplateDone');
            return response.data;
        } catch (error: any) {
            logger.error('apiTemplateLibrary.uploadTemplate() error: ', error.response || error);
            sentryLogApiError(error, 'uploadTemplate');
            webviewMessenger.sendSimpleMessage('uploadSlideTemplateFailed');
            return null;
        }
    },
};

export default apiTemplateLibrary;
