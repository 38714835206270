import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function ShowingResponsesCount({ count }: { count: number }) {
    const { t } = useTranslation();

    const generateCountText = (count: number) => {
        if (count <= 0) return t('lang_activity.txt_no_matching_responses');
        if (count === 1) return t('lang_activity.txt_showing_one_response');
        else
            return t('lang_activity.txt_showing_responses', {
                responseslength: count,
            });
    };

    return (
        <div className="show_response">
            <Typography variant="body1">{generateCountText(count)}</Typography>
        </div>
    );
}
