import { useState, forwardRef, useImperativeHandle } from 'react';
import { motion, useAnimation } from 'framer-motion';
import Lottie from 'react-lottie-player';

import { utilConstants } from '../../constants/utils.constants';
import { AnimationConfig } from '../../constants/animation-configs';

import starLottie from '../../assets/animation/Stars.json';
import StarAnimation from '../../assets/animation/starAnimation.json';
import sparkleLottie from '../../assets/animation/sparkle.json';
import myClassGivePoints from '../../assets/animation/award-star-07.json';
import fullScreenPointLottie from '../../assets/animation/fullScreenPoint.json';

// import greenTick from '../../assets/animation/green_tick.json';
// import pinkTick from '../../assets/animation/pink_tick.json';
// import blueTick from '../../assets/animation/blue_tick.json';
// import yellowTick from '../../assets/animation/yellow_tick.json';
// import purpleTick from '../../assets/animation/violet_tick.json';
// import orangeTick from '../../assets/animation/orange_tick.json';

export const ButtonStar = forwardRef((props, ref) => {
    const startAnimate = useAnimation();

    useImperativeHandle(ref, () => ({
        playStarAnimation() {
            startAnimate.start(AnimationConfig.BUTTON_STAR_CLICK);
        },
    }));

    return (
        <motion.img
            src={utilConstants.IMAGE_URLS.starIcon}
            alt="starIcon"
            onHoverStart={() => {
                startAnimate.start(AnimationConfig.BUTTON_STAR_HOVER_START);
            }}
            onHoverEnd={() => {
                startAnimate.start(AnimationConfig.BUTTON_STAR_HOVER_END);
            }}
            animate={startAnimate}
        />
    );
});

export const ButtonLottie = forwardRef((props, ref) => {
    const [isAnimating, setIsAnimating] = useState(false);

    useImperativeHandle(ref, () => ({
        playLottieAnimation() {
            setIsAnimating(true);

            setTimeout(() => {
                setIsAnimating(false);
            }, AnimationConfig.BUTTON_STAR_LOTTIE_DURATION);
        },
    }));

    return (
        <div className="starLottieGlobal">
            {isAnimating && <Lottie animationData={starLottie} play loop={false} speed={1.1} />}
        </div>
    );
});

export const TickLottie = ({
    index,
    xPosition,
    yPosition,
    scale,
}: {
    index: number;
    xPosition: () => number;
    yPosition: () => number;
    scale?: string;
}) => {
    // const tickVariants = [greenTick, pinkTick, blueTick, yellowTick, purpleTick, orangeTick];

    return (
        <motion.span
            className="lottieAnimationGlobal"
            initial={{
                x: xPosition(),
                y: yPosition(),
                scale: 0.3,
                opacity: 0,
            }}
            whileInView={{
                y: yPosition(),
                scale: 1,
                opacity: 1,
                transition: {
                    type: 'spring',
                    stiffness: 500,
                    mass: 2.8,
                    damping: 40,
                },
            }}
            exit={{
                scale: 0.5,
                opacity: 0,
                transition: { duration: 0.3 },
            }}
        >
            <Lottie
                // animationData={tickVariants[index % tickVariants.length]}
                animationData={StarAnimation}
                play
                loop={false}
                speed={2}
                style={{ scale: scale ? scale : '1' }}
            />
        </motion.span>
    );
};

export const SparkleLottie = forwardRef((props, ref) => {
    const [isAnimating, setIsAnimating] = useState(false);

    useImperativeHandle(ref, () => ({
        playLottieAnimation(value: boolean) {
            value ? setIsAnimating(true) : setIsAnimating(false);
        },
    }));

    return (
        <div className="sparkleLottieGlobal">
            {isAnimating && (
                <>
                    <div className="sparkle">
                        {isAnimating && <Lottie loop={false} animationData={sparkleLottie} play speed={0.85} />}
                    </div>
                    <div className="sparkle">
                        {isAnimating && <Lottie loop={false} animationData={sparkleLottie} play speed={0.85} />}
                    </div>
                    <div className="sparkle">
                        {isAnimating && <Lottie loop={false} animationData={sparkleLottie} play speed={0.85} />}
                    </div>
                    <div className="sparkle">
                        {isAnimating && <Lottie loop={false} animationData={sparkleLottie} play speed={0.85} />}
                    </div>
                </>
            )}
        </div>
    );
});

export const MyClassGivePointsLottie = forwardRef((props, ref) => {
    const [isAnimating, setIsAnimating] = useState(false);

    useImperativeHandle(ref, () => ({
        playLottieAnimation(value: boolean) {
            value ? setIsAnimating(true) : setIsAnimating(false);
        },
    }));

    return (
        <div className="myClassGivePointsLottie">
            {isAnimating && (
                <>
                    <div className="lottie">
                        {isAnimating && <Lottie loop={false} animationData={myClassGivePoints} play speed={3} />}
                    </div>
                </>
            )}
        </div>
    );
});

export const FullScreenPointLottie = forwardRef((props, ref) => {
    const [isAnimating, setIsAnimating] = useState(false);

    useImperativeHandle(ref, () => ({
        playLottieAnimation(value: boolean) {
            value ? setIsAnimating(true) : setIsAnimating(false);
        },
    }));

    return isAnimating ? (
        <div className="fullScreenPointGlobal">
            <Lottie animationData={fullScreenPointLottie} play loop={false} speed={2} />
        </div>
    ) : null;
});
