import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { utilConstants } from '../../constants/utils.constants';
import { getValidUserFromStore, getValidClassSessionFromStore } from '../../helpers/storeHelpers';
import { QrIcon } from '../../Icon';
import webviewMessenger from '../../services/webviewMessenger';

interface MyClassHeaderInterface {
    openQRModal: () => void;
    openQRCodeModal: () => void;
    isMyClassSidebarShown: boolean;
}

const MyClassHeader = ({ openQRModal, openQRCodeModal, isMyClassSidebarShown }: MyClassHeaderInterface) => {
    const user = getValidUserFromStore('MyClassHeader');
    const classSession = getValidClassSessionFromStore('MyClassHeader');
    const classCode = classSession.classCode;

    const { t } = useTranslation();

    return (
        <div className="top_header">
            <AppBar position="static">
                <Toolbar>
                    <Box
                        className={`box_align gap_10 myclass_head ${isMyClassSidebarShown && 'centerAlign'}`}
                        sx={{ display: 'flex', width: '100%' }}
                    >
                        <div className="header_logo">
                            <img src={utilConstants.IMAGE_URLS.brand_logo} alt="brandlogo" />
                            <Typography variant="h3">
                                {classSession.classSessionName ||
                                    t('lang_myclass.txt_default_class_title', {
                                        user_first_name: user.userProfile.firstName,
                                    })}
                            </Typography>
                        </div>
                        {!isMyClassSidebarShown && (
                            <div className="live_status underline flex_end">
                                <h2
                                    onClick={() => {
                                        webviewMessenger.sendUsageLog(`[S] Clicked join class instruction`);
                                        openQRModal();
                                    }}
                                >
                                    <span>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: t('lang_common.txt_visit_classpoint_use_code', {
                                                    url: process.env.REACT_APP_PARTICIPANT,
                                                    classCode,
                                                }),
                                            }}
                                        />
                                    </span>
                                </h2>
                                <div
                                    className="qr_pop"
                                    onClick={() => {
                                        webviewMessenger.sendUsageLog(`[S] Clicked QR code icon`);
                                        openQRCodeModal();
                                    }}
                                >
                                    <QrIcon />
                                </div>
                            </div>
                        )}
                    </Box>
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default MyClassHeader;
