export const QuotaConstants = {
    BASIC_USER_CLASS_LIMIT: 25,
    PRO_USER_CLASS_LIMIT: 200,
    BASIC_USER_SAVED_CLASS_QUOTA: 3,
    BASIC_USER_WHITEBOARD_BACKGROUND_QUOTA: 5,
    BASIC_USER_MOVABLE_OBJECTS_QUOTA: 5,
    TRIAL_DURATION: 7,
    TRIAL_KEY: '7LGZZEPLGL',
    BASIC_USER_AI_CREDITS: 20,
    PRO_USER_AI_DAILY_LIMIT: 500, // not in use
    PRO_USER_AI_MONTHLY_LIMIT: 500,
};
