import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import _ from 'lodash';

import ControlGroup from './ControlGroup';
import AddressBar from './AddressBar';
import { BrowserTabsActionTypes } from '../../../../constants/browser-tabs-action-types';
import { browserTabsActions } from '../../../../actions/browser-tabs.actions';
import TabReorder from './TabReorder';
import { getBrowserTabsFromStore } from '../../../../helpers/storeHelpers';
import { BrowserTabInterface } from '../../../../interfaces/browser-tabs-interface';
import webviewBrowserCommands from '../../../../services/webviewBrowserCommands';

import './ControlUi.scss';
import webviewMessenger from '../../../../services/webviewMessenger';

function ControlUi() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const browserTabs = getBrowserTabsFromStore();

    useEffect(() => {
        initializeTabs(browserTabs);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initializeTabs = (tabs: BrowserTabInterface[]) => {
        const preloadTabs = tabs.filter((tab) => tab.uri);
        dispatch({
            type: BrowserTabsActionTypes.REMOVE_START_PAGES_ON_INITIALIZATION,
            payload: preloadTabs,
        });
        if (preloadTabs.length <= 0) return openNewTab('/browser/start', []);

        const maxTabId = Math.max(...preloadTabs.map((tab) => tab.tabId));
        const updatedTabs = preloadTabs.map((tab) => {
            if (tab.tabId === maxTabId) tab.active = true;
            else tab.active = false;
            return tab;
        });
        updatedTabs.forEach((tab) => {
            webviewBrowserCommands.createTab(tab.tabId, tab.uri || '', tab.active);
        });
    };

    const openNewTab = (url: string, existingTabs: BrowserTabInterface[] | null = null) => {
        if (!existingTabs) existingTabs = browserTabs;
        let newTabId = 1;
        if (existingTabs.length > 0) {
            const maxTabId = Math.max(...existingTabs.map((tab) => tab.tabId));
            newTabId = maxTabId + 1;
        }
        webviewBrowserCommands.createTab(newTabId, url, true);
        dispatch(
            browserTabsActions.openNewTab({
                tabId: newTabId,
                active: true,
                isLoading: true,
            }),
        );
    };

    const switchToTab = (tab: BrowserTabInterface) => {
        // Instruct host app to switch tab
        if (!tab.active) {
            webviewBrowserCommands.switchTab(tab.tabId, tab.uri);
            dispatch(
                browserTabsActions.switchBrowserTabs(BrowserTabsActionTypes.SWITCH_BROWSER_TAB, {
                    tabId: tab.tabId,
                }),
            );
        }
    };

    const closeTab = (id: number) => {
        // If closing tab was active, switch tab or close window

        const selectedTab = browserTabs.filter((tabItem) => tabItem.tabId !== id);
        if (selectedTab.length === 0) return webviewBrowserCommands.closeWindow();

        const latestTab = _.maxBy(selectedTab, 'tabId') as BrowserTabInterface;
        // console.log(latestTab);
        const updatedTabs = selectedTab.map((tab) => {
            if (tab.tabId === latestTab.tabId) tab.active = true;
            else tab.active = false;
            return tab;
        });

        webviewBrowserCommands.switchTab(latestTab.tabId, latestTab.uri);
        dispatch(
            browserTabsActions.switchBrowserTabs(BrowserTabsActionTypes.SWITCH_BROWSER_TAB, {
                tabId: latestTab.tabId,
            }),
        );

        dispatch({
            type: BrowserTabsActionTypes.CLOSE_BROWSER_TAB,
            payload: updatedTabs,
        });

        webviewBrowserCommands.closeTab(id);
    };

    return (
        <div className="browserSection">
            <div className="controlUiSection">
                <div className="controlUiSection_top">
                    <div className="controlUiSection_top_left">
                        <ControlGroup />
                    </div>
                    <div className="controlUiSection_top_middle">
                        <AddressBar openNewTab={openNewTab} />
                    </div>
                    <div className="controlUiSection_top_right">
                        <Button
                            variant="outlined"
                            onClick={() => {
                                webviewMessenger.sendUsageLog(`[S] Inserted web page as slide`);
                                webviewBrowserCommands.insertAsSlide();
                            }}
                        >
                            {t('lang_common.btn_insert_as_slide')}
                        </Button>
                    </div>
                </div>
                <div className="controlUiSection_bottom">
                    <TabReorder
                        browserTabs={browserTabs}
                        closeTab={closeTab}
                        openNewTab={openNewTab}
                        switchToTab={switchToTab}
                    />
                </div>
            </div>
        </div>
    );
}

export default ControlUi;
