import { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion, useAnimation } from 'framer-motion';

import MenuIcon from '@mui/icons-material/Menu';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button, Divider, Link, Switch } from '@mui/material';

import './MyClassFooter.scss';

import { localService } from '../../services/localStorageService';
import webviewMessenger from '../../services/webviewMessenger';
import { getValidClassSessionFromStore, getValidUserFromStore } from '../../helpers/storeHelpers';
import { classSessionActions } from '../../actions/class-session.action';

import ParticipantAndPointsDto from '../../dtos/participant-and-points.dto';
import ParticipantInterface from '../../interfaces/participant.interface';
import { utilConstants } from '../../constants/utils.constants';

import { UpdateToolbarActionsType } from '../../dtos/update-toolbar-actions.dto';
import { AnimationConfig } from '../../constants/animation-configs';
import CommonButton from '../../components/Common/CommonButton';
import GivePointButton from '../../components/Common/GivePointButton';
import { leaderBoardCupAnimationConfig } from './FooterLeaderBoardAnimation';

import { Dbpickname, Dbquickpoll } from '../../Icon';

interface MyClassFooterInterface {
    sortedParticipants: ParticipantInterface[];
    restartClass: () => void;
    givePointsConfettiRef: any;
    setIsAnimating: (arg: boolean) => void;
}

const MyClassFooter = ({
    sortedParticipants,
    restartClass,
    givePointsConfettiRef,
    setIsAnimating,
}: MyClassFooterInterface) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classSession = getValidClassSessionFromStore('MyClassFooter');
    const user = getValidUserFromStore('MyClassFooter');
    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
    const timeOutPointUpdate = useRef<any>();
    const [pointsAdded, setPointsAdded] = useState(0);
    const userProfile = user.userProfile;
    const savedClasses = userProfile?.savedClasses || [];
    const isNotPublicClass = !!classSession.classSessionName;

    const leaderBoardCupAnimation = useAnimation();
    const leaderBoardStarAnimation = useAnimation();
    const leaderBoardAnimationConfig = leaderBoardCupAnimationConfig(leaderBoardCupAnimation, leaderBoardStarAnimation);

    const toggleLockUnlockClass = () => {
        dispatch(classSessionActions.lockUnlockClass(!classSession.isLocked));
    };

    const toggleAllowSaveGuests = () => {
        if (isNotPublicClass) {
            dispatch(classSessionActions.toggleAllowGuests(!classSession.isAllowGuests));
            dispatch(classSessionActions.toggleSaveGuests(!classSession.isAllowGuests));
        }
        // if (classSession.isAllowGuests) dispatch(classSessionActions.toggleSaveGuests(false));
    };

    // const toggleSaveGuests = () => {
    //     if (isNotPublicClass) {
    //         dispatch(classSessionActions.toggleSaveGuests(!classSession.isSaveGuests));
    //     }
    // };

    const givePointToAll = (points: number) => {
        const participantsAndPoints: ParticipantAndPointsDto[] = sortedParticipants.map(
            (participant: ParticipantInterface) => {
                return {
                    participantId: participant.participantId,
                    points: points,
                };
            },
        );
        localService.updateToolbarActionsFromWebview(UpdateToolbarActionsType.awardStar);
        dispatch(classSessionActions.givePointsToParticipants(participantsAndPoints));
    };

    const givePointHandler = () => {
        clearTimeout(timeOutPointUpdate.current);
        setIsAnimating(true);
        givePointsConfettiRef.current.playLottieAnimation(true);
        setPointsAdded(pointsAdded + 1);
    };

    useEffect(() => {
        if (pointsAdded > 0) {
            timeOutPointUpdate.current = setTimeout(() => {
                givePointToAll(pointsAdded);
                setPointsAdded(0);
                setTimeout(() => {
                    setIsAnimating(false);
                    if (givePointsConfettiRef.current) givePointsConfettiRef.current.playLottieAnimation(false);
                }, AnimationConfig.CONFETTI_LOTTIE_DURATION - AnimationConfig.GIVE_POINT_DELAY);
            }, AnimationConfig.GIVE_POINT_DELAY);
        }
        return () => clearTimeout(timeOutPointUpdate.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pointsAdded]);

    useEffect(() => {
        return () => setIsAnimating(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <footer className="footer_sec participant_sec_foot">
                <div className="footer_middle">
                    <CommonButton
                        isLoading={false}
                        variant="contained"
                        className="foot_btn"
                        type="button"
                        color="primary"
                        clickFunction={() => {
                            webviewMessenger.sendUsageLog(
                                `[S] Clicked ${
                                    savedClasses.length > 0 ? '"Change class"' : '"Start new class"'
                                } button on footer`,
                            );
                            restartClass();
                        }}
                        text={
                            savedClasses.length > 0
                                ? t('lang_myclass.btn_change_class')
                                : t('lang_myclass.btn_start_new_class')
                        }
                    />
                </div>
                {sortedParticipants && sortedParticipants.length > 0 ? (
                    <>
                        <div className="footer_middle">
                            <CommonButton
                                onMouseEnter={() => leaderBoardAnimationConfig.mouseEnterAnimation()}
                                onMouseLeave={() => leaderBoardAnimationConfig.mouseLeaveAnimation()}
                                isLoading={false}
                                variant="contained"
                                className="leaderBoard"
                                type="button"
                                color="primary"
                                clickFunction={() => {
                                    webviewMessenger.sendUsageLog(`[S] Clicked "Leader Board" center icon`);
                                    navigate('/leaderboard', { state: { params: 'fromMyClass' } });
                                }}
                                text={
                                    <div className="animationContainer">
                                        <div className="cup_container">
                                            <motion.img
                                                initial={leaderBoardAnimationConfig.animationConstants.cupInitial}
                                                animate={leaderBoardCupAnimation}
                                                src={utilConstants.LEADER_BOARD_IMAGE_URLS.LEAEDER_BOARD_CUP}
                                                alt="leaderboard_cup"
                                            />
                                            <motion.img
                                                initial={leaderBoardAnimationConfig.animationConstants.starInitial}
                                                animate={leaderBoardStarAnimation}
                                                src={utilConstants.LEADER_BOARD_IMAGE_URLS.ANIMATION_STAR}
                                                className="starImage"
                                                alt="leaderBoard"
                                            />
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                        <div className="footer_left myClassFooter">
                            <GivePointButton
                                activity="myClass"
                                onClick={() => {
                                    webviewMessenger.sendUsageLog(`[S] Clicked "Award stars to all" on footer`);
                                    givePointHandler();
                                }}
                                label={t('lang_common.btn_give_points_to_all')}
                            />
                        </div>
                    </>
                ) : null}
                <div className="foot_menu">
                    <Button
                        id="basic-button"
                        aria-controls="basic-menu"
                        aria-haspopup="true"
                        aria-expanded="true"
                        onClick={() => {
                            webviewMessenger.sendUsageLog(
                                `[S] ${!isContextMenuOpen ? 'Opened' : 'Closed'} hamburger menu`,
                            );
                            setIsContextMenuOpen(!isContextMenuOpen);
                        }}
                    >
                        <MenuIcon />
                    </Button>
                    <div className="menu_modal">
                        <div
                            className={`menu_overlay ${isContextMenuOpen ? 'active' : ''}`}
                            onClick={() => {
                                webviewMessenger.sendUsageLog(
                                    `[S] ${!isContextMenuOpen ? 'Opened' : 'Closed'} hamburger menu`,
                                );
                                setIsContextMenuOpen(!isContextMenuOpen);
                            }}
                        ></div>
                        <div className={`menu ${isContextMenuOpen ? 'active' : ''}`} style={{ bottom: '44px' }}>
                            <ul id="listId">
                                <li>
                                    <Button>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={classSession.isLocked}
                                                    onClick={() => {
                                                        webviewMessenger.sendUsageLog(`[S] Toggled lock/unlock class`);
                                                        toggleLockUnlockClass();
                                                    }}
                                                />
                                            }
                                            label={t('lang_myclass.btn_lock_class')}
                                        />
                                    </Button>
                                </li>
                                {classSession.classSessionName && (
                                    <Fragment>
                                        <li>
                                            <Button>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={classSession.isAllowGuests}
                                                            onClick={() => {
                                                                webviewMessenger.sendUsageLog(
                                                                    `[S] Toggled allow/disallow guests`,
                                                                );
                                                                toggleAllowSaveGuests();
                                                            }}
                                                        />
                                                    }
                                                    label={t('lang_common.txt_allowGuest')}
                                                />
                                            </Button>
                                        </li>
                                        {/* <li>
                                            <Button>
                                                <FormControlLabel
                                                    disabled={!classSession.isAllowGuests}
                                                    control={
                                                        <Switch
                                                            checked={
                                                                classSession.isAllowGuests && classSession.isSaveGuests
                                                            }
                                                            onClick={() => {
                                                                webviewMessenger.sendUsageLog(
                                                                    `[S] Toggled save/unsave guests`,
                                                                );
                                                                toggleSaveGuests();
                                                            }}
                                                        />
                                                    }
                                                    label={t('lang_myclass.toggle_save_guests')}
                                                />
                                            </Button>
                                        </li> */}
                                    </Fragment>
                                )}
                                <Divider className="menu_border" />
                            </ul>
                            <div className="menu_grid_btn">
                                <Link
                                    className="grid_box"
                                    onClick={() => {
                                        webviewMessenger.sendUsageLog(`[S] Clicked "Quick Poll" in hamburger`);
                                        navigate('/qpselect', { state: { params: 'fromMyClass' } });
                                    }}
                                >
                                    <div className="icon">
                                        <Dbquickpoll />
                                    </div>
                                    {t('lang_activity.Quick Poll')}
                                </Link>

                                <Link
                                    className="grid_box"
                                    onClick={() => {
                                        webviewMessenger.sendUsageLog(`[S] Clicked "Name Picker" in hamburger`);
                                        navigate('/picknames', { state: { params: 'fromMyClass' } });
                                    }}
                                >
                                    <div className="icon">
                                        <Dbpickname />
                                    </div>
                                    {t('lang_pick_names.title')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </Fragment>
    );
};

export default MyClassFooter;
